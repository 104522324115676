import { Typography } from "@mui/material";
import MapModal from "../device/MapModal";
import DivInline from "components/DivInline";
import { AddToPhotos } from "@mui/icons-material";
import DivExist from "components/DivExist";
import AddLightToMapPanel from "./AddLightToMapPanel";
import AddSensorToMapPanel from "./AddSensorToMapPanel";
import AddDaliCtlToMapPanel from "./AddDaliCtlToMapPanel";
import AddGatewayToMapPanel from "./AddGatewayToMapPanel";
import AddZcToMapPanel from "./AddZcToMapPanel";

export default function AddDeviceDeviceInMapModal({ open, onClose, x, y, layerProps, addDeviceType, modalWidth, modalHeight }) {

    const { mapRightClickPos, mapScale } = layerProps
    if (!open) return null;
    return (
        <>
            <MapModal
                x={x}
                y={y}
                open={open}
                handleCloseModal={onClose}
                width={modalWidth}
                height={modalHeight}
                layerProps={layerProps}
            >
                <DivInline>
                    <AddToPhotos />
                    <Typography>Add {addDeviceType} </Typography>
                </DivInline>
                <DivExist show={addDeviceType === "light"}>
                    <AddLightToMapPanel
                        handleClose={onClose}
                        targetX={mapRightClickPos.x / mapScale.x}
                        targetY={mapRightClickPos.y / mapScale.y}
                        state={layerProps}
                        dispatch={layerProps.dispatch}
                        modalSize="small"
                    />
                </DivExist>
                <DivExist show={addDeviceType === "sensor"}>
                    <AddSensorToMapPanel
                        handleClose={onClose}
                        targetX={mapRightClickPos.x / mapScale.x}
                        targetY={mapRightClickPos.y / mapScale.y}
                        state={layerProps}
                        dispatch={layerProps.dispatch}
                        modalSize="small"
                    />
                </DivExist>
                <DivExist show={addDeviceType === "daliCtl"}>
                    <AddDaliCtlToMapPanel
                        handleClose={onClose}
                        targetX={mapRightClickPos.x / mapScale.x}
                        targetY={mapRightClickPos.y / mapScale.y}
                        state={layerProps}
                        dispatch={layerProps.dispatch}
                        modalSize="small"
                    />
                </DivExist>
                <DivExist show={addDeviceType === "gateway"}>
                    <AddGatewayToMapPanel
                        handleClose={onClose}
                        targetX={mapRightClickPos.x / mapScale.x}
                        targetY={mapRightClickPos.y / mapScale.y}
                        state={layerProps}
                        dispatch={layerProps.dispatch}
                        modalSize="small"
                    />
                </DivExist>
                <DivExist show={addDeviceType === "zoneControl"}>
                    <AddZcToMapPanel
                        handleClose={onClose}
                        targetX={mapRightClickPos.x / mapScale.x}
                        targetY={mapRightClickPos.y / mapScale.y}
                        state={layerProps}
                        dispatch={layerProps.dispatch}
                        modalSize="small"
                    />
                </DivExist>
            </MapModal>
        </>
    )
}