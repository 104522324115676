import { Typography, Box } from "@mui/material";

export default function SmallButton({
  width = 60,
  height = 40,
  borderColor = "darkGrey",
  textColor = "",
  btnContent,
  onClick,
  margin = "0.2vw",
}) {
  return (
    <Box
      onClick={onClick}
      sx={{
        width,
        height,
        cursor: "pointer",
        border: `1px solid ${borderColor}`,
        display: "flex",
        justifyContent: "center",
        alignItem: "center",
        borderRadius: 1,
        margin: margin,
        "&:hover": { background: "rgba(72,122,180,0.7)" },
      }}
    >
      <Typography variant="caption" align="center" sx={{ color: textColor }}>
        {btnContent}
      </Typography>
    </Box>
  );
}
