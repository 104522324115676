import { useContext, useState, useEffect } from "react";
import { SMContext } from "../context/smContext";
import { Typography, TextField } from "@mui/material";
import ModalSM from "./ModalSM";
import IconButtonClose from "./IconButtonClose";
import IconButtonDone from "./IconButtonDone";
import DivInline from "./DivInline";
import Divider10 from "./Divider10";
export default function WindowPrompt() {
  const [state, dispatch] = useContext(SMContext);
  const {
    windowPrompt,
    windowPromptTitle,
    onConfirm,
    windowPromptDefaultValue,
  } = state;
  const [input, setInput] = useState(windowPromptDefaultValue || "");
  useEffect(() => {
    if (!windowPrompt) {
      setInput();
    } else {
      setInput(windowPromptDefaultValue);
    }
  }, [windowPrompt]);
  const handleClose = () => {
    dispatch({
      type: "WINDOW_PROMPT",
      payload: { status: false, windowPromptTitle: "" },
    });
  };

  return (
    <ModalSM
      open={windowPrompt}
      onClose={handleClose}
      disableBottomClose
      minWidth="40vw"
    >
      <Typography sx={{ marginTop: 3 }} align="left">
        {windowPromptTitle}
      </Typography>
      <TextField
        fullWidth
        value={input || ""}
        onChange={(e) => setInput(e.target.value)}
        sx={{ margin: "2vh", width: "35vw" }}
        autoFocus
      />
      <Divider10 />
      <DivInline justifyContent="space-around">
        <IconButtonDone
          onBtnClick={() => {
            onConfirm(input);
            handleClose();
          }}
        />
        <IconButtonClose onBtnClick={handleClose} />
      </DivInline>
    </ModalSM>
  );
}
