import React, { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, IconButton } from "@mui/material";
import { Refresh } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import { fetchDaliCtlHistory } from "actions/daliCtlActions";
import DaliCtlDetailHistoryModal from "./DaliCtlDetailHistoryModal";
import DaliCtlHistoryItem from "./DaliCtlHistoryItem";

export default function DaliCtlDataLogPanel() {
    const [state] = useContext(SMContext);
    const [dataLog, setDataLog] = useState([]);
    const { activeDaliCtlID, daliCtlObjAll } = state;
    const daliCtlObj = daliCtlObjAll[activeDaliCtlID];

    useEffect(() => {
        if (activeDaliCtlID) fetchLogs();
    }, [activeDaliCtlID]);

    const fetchLogs = async () => {
        let arr = await fetchDaliCtlHistory(activeDaliCtlID, moment().subtract(1, "hours").valueOf(), moment().valueOf());
        arr = arr
            // .filter((obj) => obj.uploadProtocol !== "gw_zigbeeConnected")
            .sort((a, b) => b.timeStamp - a.timeStamp)
            .filter((obj, key) => key < 1000);
        console.log(arr);
        setDataLog(arr);
    };

    return (
        <div>

            <Divider10 />
            <DivInline justifyContent="center">
                <Typography variant="h6">History</Typography>
                <IconButton onClick={fetchLogs}>
                    <Refresh />
                </IconButton>
                <DaliCtlDetailHistoryModal />
            </DivInline>
            <Divider10 />
            <Timeline>
                {dataLog.map((obj, key) => {
                    return (
                        <DaliCtlHistoryItem key={key} data={obj} daliCtlObj={daliCtlObj} />
                    );
                })}
            </Timeline>
        </div>
    );
}
