import {
    switchLight,
    switchLightDali,
    setPower,
    deleteSlaveLight,
    requestLightCurrentData,
    airconSetTemp,
    createSlaveLight,
    airconSwitchOn,
    airconSwitchOff
} from "./lightActions";
import { mapRemoveDevice } from "./mapActions";
import { daliCtlCheckConnection, daliQueryLightLevel } from "./daliCtlActions";
import {
    flashSensorHeadLED,
    requestSensorCurrentData,
    transferSettingToSensor
} from "./sensorActions";
import { queryGatewayOneSetting } from "actions/gatewayActions";
import {
    deleteZoneControl,
    transferSettingToZoneControl
} from "actions/zcActions";
import LightBulbSvg from "asset/svgComp/LightBulb";
import LightBulb from "asset/mapComp/LightBulb";
import PowerPlugSvg from "asset/svgComp/PowerPlug";
import PowerPlug from "asset/mapComp/PowerPlug";
import Gateway from "asset/mapComp/Gateway";
import GatewaySvg from "asset/svgComp/Gateway";
import RubbishBin from "asset/mapComp/RubbishBin";
import RubbishBinSvg from "asset/svgComp/RubbishBin";
import Document from "asset/mapComp/Document";
import DocumentSvg from "asset/svgComp/Document";
import DegreeCelsius from "asset/mapComp/DegreeCelsius";
import DegreeCelsiusSvg from "asset/svgComp/DegreeCelsius";
import { confirmWindow, promptWindow, openSnackbar, hideBigProgress, showBigProgress } from "actions/screenActions";
import QuestionMark from "asset/mapComp/QuestionMark";
import QuestionMarkSvg from "asset/svgComp/QuestionMark";
import ZcSceneButton from "asset/mapComp/ZcSceneButton";
import ZcSceneButtonSvg from "asset/svgComp/ZcSceneButton";
import ControlPointDuplicate from "asset/mapComp/ControlPointDuplicate";
import ControlPointDuplicateSvg from "asset/svgComp/ControlPointDuplicate";
import CopyIcon from "asset/mapComp/CopyIcon";
import CopyIconSvg from "asset/svgComp/CopyIcon";
import PasteIcon from "asset/mapComp/PasteIcon";
import PasteIconSvg from "asset/svgComp/PasteIcon";
import Magnifier from "asset/mapComp/Magnifier";
import MagnifierSvg from "asset/svgComp/Magnifier";
import { blue } from "@mui/material/colors";
import General from "@ecoenghk/general";
const gs = new General();

////LIGHT ACTIONS//////////LIGHT ACTIONS//////////LIGHT ACTIONS//////////LIGHT ACTIONS/////////
export const handleSwitchLight = async (status, lightObj, state) => {
    const lockLv = status === 0 ? 1 : 0;
    const pwm = status === 0 ? 100 : 0;
    const { serial, gatewayID, daliCtlID, shortAdd } = lightObj;
    if (!daliCtlID) {
        const gatewayObj = state.gatewayObjAll[gatewayID];
        await switchLight(
            serial,
            lightObj,
            status,
            pwm,
            lockLv,
            gatewayObj
        );
    } else {
        const daliCtlObj = state.daliCtlObjAll[daliCtlID];
        await switchLightDali(serial, status, pwm, shortAdd, daliCtlObj);
    }
};
export const handleSetPower = async (command, lightObj) => {
    const { serial } = lightObj;
    await setPower(serial, lightObj, command);
};
export const handleMapRemoveLight = async (lightObj, mapObj, dispatch) => {
    const { mapID } = mapObj;
    const { serial, masterSerial } = lightObj;
    if (!masterSerial) {
        confirmWindow(
            dispatch,
            `Confirm removing light ${lightObj.description} [${serial}] from map ${mapID}?`,
            async () => {
                await mapRemoveDevice(mapID, serial, "light", lightObj);
                dispatch({ type: "ALL_UNSELECTED" });
            }
        );
    } else {
        confirmWindow(dispatch,
            `Confirm delete slave light ${lightObj.description} [${serial}]?`,
            async () => {
                await deleteSlaveLight(lightObj, mapObj);
                dispatch({ type: "ALL_UNSELECTED", });
            }
        );
    }
};
export const handleOpenLightDetail = (serial, dispatch, navigate) => {
    dispatch({
        type: "OPENED_LIGHT_DETAIL_MODAL",
        payload: serial,
    });
    navigate("/LightDetailPage");
};
export const handleLightChangeGateway = (dispatch) => {
    const layerObj = {
        normal: false,
        light: true,
        gatewaySelect: true,
    };
    dispatch({
        type: "SET_MAP_LAYER",
        payload: layerObj,
    });
};
export const handleCheckLightConnection = async (lightObj, state) => {
    const { serial, daliCtlID, shortAdd } = lightObj;
    if (daliCtlID) {
        const daliCtlObj = state.daliCtlObjAll[daliCtlID];
        daliQueryLightLevel(daliCtlObj, shortAdd);
    } else {
        await requestLightCurrentData(serial, lightObj, true);
    }
};
export const handleAirconSetTemp = (dispatch, lightObj) => {
    promptWindow(
        dispatch,
        "Set temperature",
        (val) => {
            airconSetTemp(lightObj, Number(val));
        },
        24
    );
};
export const handleCreateSlaveLight = (lightObj, mapObj, dispatch) => {
    confirmWindow(dispatch, "Create slave light?", async () => {
        const { x, y } = mapObj?.lightObj[lightObj.serial] || {};
        const deltaX = gs.randomInteger(20, 80);
        const deltaY = gs.randomInteger(20, 80);
        const lightX = x < 500 ? x + deltaX : x - deltaX;
        const lightY = y < 300 ? y + deltaY : y - deltaY;
        createSlaveLight(lightObj, lightX, lightY, mapObj);
    });
}
let tid1, tid2;
export const handleShortDischarge = async (lightObj) => {
    const { serial, shortDischargeSec = 12 } = lightObj || {};
    await setPower(serial, lightObj, 1);
    setTimeout(() => {
        setPower(serial, lightObj, 1);
    }, 1000);
    // tv = setInterval(() => {
    //     setShortDischargeCount(t => t + 1)
    // }, 1000)
    tid1 = setTimeout(() => {
        setPower(serial, lightObj, 0);
        // clearInterval(tv);
    }, shortDischargeSec * 1000);
    tid2 = setTimeout(() => {
        setPower(serial, lightObj, 0);
        // clearInterval(tv);
    }, (shortDischargeSec + 1) * 1000)
}
export const handleStopShortDischarge = (lightObj) => {
    const { serial } = lightObj || {};
    setPower(serial, lightObj, 0);
    clearTimeout(tid1);
    clearTimeout(tid2);
    // clearInterval(tv);
}
export const getLightButtonContent = (lightObj, state, dispatch, btnSize, svgIconSize = 4, navigate) => {
    const { serial, masterSerial, type, daliCtlID, dtkAdd, enableAirconControl } = lightObj;
    const { activeMapID, mapObjAll, userObj } = state;
    const activeMapObj = mapObjAll[activeMapID];
    let btnContent = [];
    if (type?.A && !masterSerial) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleSwitchLight(0, lightObj, state),
                icon: <LightBulb x={0} y={0} size={btnSize} onoff="on" />,
                svgIcon: <LightBulbSvg width={svgIconSize} sizeUnit="vw" onoff="on" />,
                tooltip: "Switch ON",
            },
            {
                onClick: () => handleSwitchLight(1, lightObj, state),
                icon: <LightBulb x={0} y={0} size={btnSize} onoff="off" />,
                svgIcon: <LightBulbSvg width={svgIconSize} sizeUnit="vw" onoff="off" />,
                tooltip: "Switch OFF",
            },
        ];
    }
    if (type?.L) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleShortDischarge(lightObj),
                icon: <PowerPlug x={0} y={0} size={btnSize} cutResume="cut" />,
                svgIcon: <PowerPlugSvg width={svgIconSize} sizeUnit="vw" cutResume="cut" />,
                tooltip: `Short discharge ${lightObj.shortDischargeSec || 12} seconds`,
            },

        ];
        if (userObj?.level === 0) {
            btnContent = [
                ...btnContent,
                {
                    onClick: () => handleStopShortDischarge(lightObj),
                    icon: <PowerPlug x={0} y={0} size={btnSize} cutResume="resume" />,
                    svgIcon: <PowerPlugSvg width={svgIconSize} sizeUnit="vw" cutResume="resume" />,
                    tooltip: "Stop discharge",
                }
            ]
        }
    }
    if (!daliCtlID && !masterSerial) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleLightChangeGateway(dispatch),
                icon: <Gateway x={0} y={0} size={btnSize} disabledConnectStatus shadEnabled={false} />,
                svgIcon: <GatewaySvg width={svgIconSize} sizeUnit="vw" disabledConnectStatus />,
                tooltip: "Change gateway",
            },
        ];
    }
    if (enableAirconControl === 1) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => airconSwitchOn(lightObj),
                icon: <ZcSceneButton x={0} y={0} size={btnSize} status={1} />,
                svgIcon: <ZcSceneButton width={svgIconSize} sizeUnit="vw" />,
                tooltip: "AC ON",
            },
            {
                onClick: () => airconSwitchOff(lightObj),
                icon: <ZcSceneButton x={0} y={0} size={btnSize} status={0} />,
                svgIcon: <ZcSceneButton width={svgIconSize} sizeUnit="vw" />,
                tooltip: "AC OFF",
            },
            {
                onClick: () => handleAirconSetTemp(dispatch, lightObj),
                icon: <DegreeCelsius x={0} y={0} color={blue[700]} size={btnSize} />,
                svgIcon: <DegreeCelsiusSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Set Temp",
            },
        ];
    }

    btnContent = [
        ...btnContent,
        {
            onClick: () => handleOpenLightDetail(serial, dispatch, navigate),
            icon: <Document x={0} y={0} size={btnSize} />,
            svgIcon: <DocumentSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: `Details`,
        },
        {
            onClick: () => handleMapRemoveLight(lightObj, activeMapObj, dispatch),
            icon: <RubbishBin x={0} y={0} size={btnSize} />,
            svgIcon: <RubbishBinSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Remove from map",
        }
    ];
    if (!masterSerial) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleCheckLightConnection(lightObj, state),
                icon: <QuestionMark x={0} y={0} size={btnSize} />,
                svgIcon: <QuestionMarkSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: daliCtlID ? "Check light level" : "Check connection",
            }
        ];
    }

    if (!daliCtlID && dtkAdd && userObj?.level === 0) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleCreateSlaveLight(lightObj, activeMapObj, dispatch),
                icon: <ControlPointDuplicate x={0} y={0} size={btnSize} />,
                svgIcon: <ControlPointDuplicateSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Create slave light"
            }
        ];
    }
    return btnContent;
};

//////SENSOR ACTIONS//////SENSOR ACTIONS//////SENSOR ACTIONS//////SENSOR ACTIONS//////
export const handleOpenSensorDetail = (sensorID, dispatch, navigate) => {
    dispatch({
        type: "OPENED_SENSOR_DETAIL_MODAL",
        payload: sensorID,
    });
    navigate("/SensorDetailPage");
};
export const handleSensorChangeGateway = (dispatch) => {
    const layerObj = {
        normal: false,
        sensor: true,
        gatewaySelect: true,
    };
    dispatch({
        type: "SET_MAP_LAYER",
        payload: layerObj,
    });
};
export const handleMapRemoveSensor = (sensorObj, mapObj, dispatch) => {
    const { sensorID, gatewayID, daliCtlID } = sensorObj;
    const { mapID } = mapObj;
    confirmWindow(
        dispatch,
        `Confirm removing sensor ${sensorID} from map ${mapID}?`,
        async () => {
            await mapRemoveDevice(mapID, sensorID, "sensor", sensorObj);
            // await gs.waitFor(300);
            // if (!daliCtlID && gatewayID) {
            //     confirmWindow(
            //         dispatch,
            //         "Disconnect sensor from gateway?",
            //         async () => {
            //             await gatewayDisconnectSensor(sensorObj);
            //             await gs.waitFor(300);
            //         },
            //         async () => { },
            //         async () => await handleDisconnectAllLights()
            //     );
            // } else {
            // await handleDisconnectAllLights();
            // }
        }
    );
};
export const handleFlashSensorHeadLED = async (sensorObj) => {
    await flashSensorHeadLED(sensorObj, 10);
};
export const handleCheckSensorConnection = async (sensorObj) => {
    await requestSensorCurrentData(sensorObj, true);
};
export const handleCopySensorSetting = (sensorObj, dispatch) => {
    dispatch({
        type: "SET_COPIED_DEVICE_OBJ",
        payload: sensorObj,
    });
    openSnackbar(dispatch, "Sensor settings copied");
};
export const handlePasteSensorSetting = async (copiedDeviceObj, targetSensorID, sensorObjAll, dispatch) => {
    confirmWindow(
        dispatch,
        `Confirm paste settings from sensor ID ${copiedDeviceObj.sensorID} to sensor ID ${targetSensorID}?`,
        async () => {
            await transferSettingToSensor(
                copiedDeviceObj.sensorID,
                targetSensorID,
                sensorObjAll
            );
            openSnackbar(dispatch, "Setting pasted");
        }
    );
};
export const getSensorButtonContent = (sensorObj, state, dispatch, btnSize, svgIconSize = 4, navigate) => {
    const { sensorID, daliCtlID, type, gang } = sensorObj;
    const { userObj, mapObjAll, activeMapID, copiedDeviceObj, sensorObjAll } = state;
    const mapObj = mapObjAll[activeMapID];
    let enablePasteSetting =
        copiedDeviceObj.sensorID &&
            copiedDeviceObj.type === type &&
            copiedDeviceObj !== sensorID
            ? true
            : false;
    if (type === "scenebutton" || type === "lightswitch") {
        if (copiedDeviceObj.gang !== gang) enablePasteSetting = false;
    }
    let btnContent = [
        {
            onClick: () => handleOpenSensorDetail(sensorID, dispatch, navigate),
            icon: <Document x={0} y={0} size={btnSize} />,
            svgIcon: <DocumentSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Sensor detail",
        },
    ];
    if (!daliCtlID) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleSensorChangeGateway(dispatch),
                icon: <Gateway x={0} y={0} size={btnSize} disabledConnectStatus shadEnabled={false} />,
                svgIcon: <GatewaySvg width={svgIconSize} sizeUnit="vw" disabledConnectStatus />,
                tooltip: "Change gateway",
                // stroke: mapLayer.gatewaySelect ? "red" : "",
            },
        ];
    }
    btnContent = [
        ...btnContent,
        {
            onClick: () => handleMapRemoveSensor(sensorObj, mapObj, dispatch),
            icon: <RubbishBin x={0} y={0} size={btnSize} />,
            svgIcon: <RubbishBinSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Remove from map",
        },
    ];
    if (!daliCtlID) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleCheckSensorConnection(sensorObj),
                icon: <QuestionMark x={0} y={0} size={btnSize} />,
                svgIcon: <QuestionMarkSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Check connection",
            },
        ];
    }
    if (userObj.level < 1) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleCopySensorSetting(sensorObj, dispatch),
                icon: <CopyIcon x={0} y={0} size={btnSize} />,
                svgIcon: <CopyIconSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Copy setting",
            },
        ];
    }
    if (enablePasteSetting) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handlePasteSensorSetting(copiedDeviceObj, sensorID, sensorObjAll, dispatch),
                icon: <PasteIcon x={0} y={0} size={btnSize} />,
                svgIcon: <PasteIconSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Paste setting",
            },
        ];
    }
    if (type !== "scenebutton" && type !== "lightswitch") {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleFlashSensorHeadLED(sensorObj),
                icon: <ZcSceneButton x={0} y={0} size={btnSize} />,
                svgIcon: <ZcSceneButtonSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Flash sensor head LED 10 seconds",
            },
        ];
    }
    return btnContent;
}

/////GATEWAY ACTIONS/////GATEWAY ACTIONS/////GATEWAY ACTIONS/////GATEWAY ACTIONS/////
export const handleMapRemoveGateway = async (gatewayObj, mapID, dispatch) => {
    const { gatewayID } = gatewayObj;
    confirmWindow(
        dispatch,
        `Confirm removing gateway ${gatewayID} from map ${mapID}?`,
        async () => {
            await mapRemoveDevice(mapID, gatewayID, "gateway", gatewayObj);
            dispatch({
                type: "ALL_UNSELECTED",
            });
        }
    );
};
export const handleCheckGatewayConnection = async (gatewayID) => {
    await queryGatewayOneSetting(gatewayID, 0x000c, "queryDeviceSurvival");
};
export const handleShowGatewayEspWeb = async (dispatch) => {
    dispatch({
        type: "SET_MAP_LAYER",
        payload: {
            normal: false,
            espWeb: true,
        },
    })
}
export const handleOpenGatewayDetail = async (gatewayID, dispatch, navigate) => {
    dispatch({
        type: "OPENED_GATEWAY_DETAIL_MODAL",
        payload: gatewayID,
    });
    navigate("/GatewayDetailPage");
    await queryGatewayOneSetting(gatewayID, 0x0015, "queryTimeTable");
    await gs.waitFor(1000);
    await queryGatewayOneSetting(gatewayID, 0x0016, "queryBindedDevice");
    await gs.waitFor(1000);
    await queryGatewayOneSetting(gatewayID, 0x000d, "queryGwSensorSetting");
};
export const getGatewayButtonContent = (gatewayObj, state, dispatch, btnSize, svgIconSize = 4, navigate) => {
    const { gatewayID } = gatewayObj;
    const { activeMapID } = state;
    let btnContent = [
        {
            onClick: () => handleOpenGatewayDetail(gatewayID, dispatch, navigate),
            icon: <Document x={0} y={0} size={btnSize} />,
            svgIcon: <DocumentSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Gateway detail",
        },
        {
            onClick: () => handleMapRemoveGateway(gatewayObj, activeMapID, dispatch),
            icon: <RubbishBin x={0} y={0} size={btnSize} />,
            svgIcon: <RubbishBinSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Remove from map",
        },
        {
            onClick: () => handleCheckGatewayConnection(gatewayID),
            icon: <QuestionMark x={0} y={0} size={btnSize} />,
            svgIcon: <QuestionMarkSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Check connection",
        },
        {
            onClick: () => handleShowGatewayEspWeb(dispatch),
            icon: <Magnifier x={0} y={0} size={btnSize} />,
            svgIcon: <MagnifierSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "ESP Web",
        }
    ];
    return btnContent;
}

/////DALICTL ACTIONS/////DALICTL ACTIONS/////DALICTL ACTIONS/////DALICTL ACTIONS/////
export const handleOpenDaliCtlDetail = (daliCtlID, dispatch, navigate) => {
    dispatch({
        type: "OPENED_DALICTL_DETAIL_MODAL",
        payload: daliCtlID,
    });
    navigate("/DaliCtlDetailPage");
};
export const handleDaliCtlChangeGateway = (dispatch) => {
    const layerObj = {
        normal: false,
        daliCtl: true,
        gatewaySelect: true,
    };
    dispatch({
        type: "SET_MAP_LAYER",
        payload: layerObj,
    });
};
export const handleMapRemoveDaliCtl = async (daliCtlObj, mapID, dispatch) => {
    const { daliCtlID } = daliCtlObj;
    confirmWindow(
        dispatch,
        `Confirm removing dali controller ${daliCtlID} from map ${mapID}?`,
        async () => {
            await mapRemoveDevice(mapID, daliCtlID, "daliCtl", daliCtlObj);
            dispatch({ type: "ALL_UNSELECTED" });
        }
    );
};
export const handleCheckDaliCtlConnection = async (daliCtlObj) => {
    await daliCtlCheckConnection(daliCtlObj)
}
export const getDaliCtlButtonContent = (daliCtlObj, state, dispatch, btnSize, svgIconSize = 4, navigate) => {
    const { daliCtlID, daliType } = daliCtlObj;
    const { activeMapID } = state;
    let btnContent = [
        {
            onClick: () => handleOpenDaliCtlDetail(daliCtlID, dispatch, navigate),
            icon: <Document x={0} y={0} size={btnSize} />,
            svgIcon: <DocumentSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Dali controller detail",
        },
        {
            onClick: () => handleDaliCtlChangeGateway(dispatch),
            icon: <Gateway x={0} y={0} size={btnSize} disabledConnectStatus shadEnabled={false} />,
            svgIcon: <GatewaySvg width={svgIconSize} sizeUnit="vw" disabledConnectStatus />,
            tooltip: "Change gateway",
        },
    ];
    if (daliType === "dalidtk") {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleCheckDaliCtlConnection(daliCtlObj),
                icon: <QuestionMark x={0} y={0} size={btnSize} />,
                svgIcon: <QuestionMarkSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Check connection",
            },
        ];
    }
    btnContent = [
        ...btnContent,
        {
            onClick: () => handleMapRemoveDaliCtl(daliCtlObj, activeMapID, dispatch),
            icon: <RubbishBin x={0} y={0} size={btnSize} />,
            svgIcon: <RubbishBinSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Remove from map",
        },
    ];
    return btnContent;
}

/////ZONECONTROL ACTIONS/////ZONECONTROL ACTIONS/////ZONECONTROL ACTIONS/////ZONECONTROL ACTIONS/////
export const handleOpenZoneControlDetail = (zoneControlID, dispatch, navigate) => {
    dispatch({
        type: "OPENED_ZC_DETAIL_MODAL",
        payload: zoneControlID,
    });
    navigate("/ZoneControlDetailPage");
};
export const hanldeMapRemoveZoneControl = (zoneControlObj, mapID, dispatch) => {
    const { zoneControlID, zoneControlName } = zoneControlObj;
    let deleteZc = true;
    if (Array.isArray(zoneControlObj.mapID)) {
        let newMapIdArray = zoneControlObj.mapID;
        newMapIdArray = newMapIdArray.filter((id) => id !== mapID);
        deleteZc = newMapIdArray.length === 0 ? true : false;
    } else {
        deleteZc = true;
    }
    if (deleteZc) {
        confirmWindow(
            dispatch,
            `Confirm to delete Zone Control ${zoneControlName} [${zoneControlID}]?`,
            async () => {
                showBigProgress(dispatch);
                await deleteZoneControl(mapID, zoneControlID, zoneControlObj);
                dispatch({
                    type: "ALL_UNSELECTED",
                });
                hideBigProgress(dispatch);
            }
        );
    } else {
        confirmWindow(
            dispatch,
            `Confirm removing zone control [${zoneControlID}] from map ${mapID}`,
            async () => {
                await mapRemoveDevice(mapID, zoneControlID, "zoneControl", zoneControlObj);
                dispatch({ type: "ALL_UNSELECTED" });
            }
        )

    }
}
export const handleCopyZoneControlSetting = (zoneControlObj, dispatch) => {
    dispatch({
        type: "SET_COPIED_DEVICE_OBJ",
        payload: zoneControlObj,
    });
    openSnackbar(dispatch, "Zone control settings copied");
};
export const handlePasteZoneControlSetting = async (copiedDeviceObj, targetZoneControlID, zoneControlObjAll, dispatch) => {
    confirmWindow(
        dispatch,
        `Confirm paste settings from zone control ID ${copiedDeviceObj.zoneControlID}?`,
        async () => {
            await transferSettingToZoneControl(
                copiedDeviceObj.zoneControlID,
                targetZoneControlID,
                zoneControlObjAll
            );
            openSnackbar(dispatch, "Setting pasted");
        }
    );
};
export const getZoneControlButtonContent = (zoneControlObj, state, dispatch, btnSize, svgIconSize = 4, navigate) => {
    const { copiedDeviceObj, activeMapID, userObj, zoneControlObjAll } = state;
    const { zoneControlID, type } = zoneControlObj;
    const enablePasteSetting =
        copiedDeviceObj.zoneControlID &&
            copiedDeviceObj.type === type &&
            copiedDeviceObj.zoneControlID !== zoneControlID
            ? true
            : false;
    let btnContent = [
        {
            onClick: () => handleOpenZoneControlDetail(zoneControlID, dispatch, navigate),
            icon: <Document x={0} y={0} size={btnSize} />,
            svgIcon: <DocumentSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Zone control detail",
        },
        {
            onClick: () => hanldeMapRemoveZoneControl(zoneControlObj, activeMapID, dispatch),
            icon: <RubbishBin x={0} y={0} size={btnSize} />,
            svgIcon: <RubbishBinSvg width={svgIconSize} sizeUnit="vw" />,
            tooltip: "Remove from map",
        }
    ];
    if (userObj.level < 1) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handleCopyZoneControlSetting(zoneControlObj, dispatch),
                icon: <CopyIcon x={0} y={0} size={btnSize} />,
                svgIcon: <CopyIconSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Copy setting",
            },
        ];
    }
    if (enablePasteSetting) {
        btnContent = [
            ...btnContent,
            {
                onClick: () => handlePasteZoneControlSetting(copiedDeviceObj, zoneControlID, zoneControlObjAll, dispatch),
                icon: <PasteIcon x={0} y={0} size={btnSize} />,
                svgIcon: <PasteIconSvg width={svgIconSize} sizeUnit="vw" />,
                tooltip: "Paste setting",
            },
        ];
    }
    return btnContent;
}
