import DivInline from "components/DivInline";
import { useState } from "react";
import { Box, Typography } from "@mui/material";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import { deviceInfoOfObj } from "actions/generalActions";
import AdminContainer from "components/AdminContainer";
const gs = new General();
export default function DeviceMongoModal({ children, deviceObj }) {
  const [open, setOpen] = useState(false);
  const deviceInfo = deviceInfoOfObj(deviceObj);
  const deviceType = deviceInfo.deviceType;
  const deviceID = deviceInfo.deviceID;
  return (
    <>
      <div onClick={() => 
        setOpen(true)
      }>{children}</div>
      <AdminContainer>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        width="93vw"
        height="96vh"
        modalTitle={`Device Mongo -  ${deviceType}  [${deviceID}]`}
      >
        {Object.keys(deviceObj || {})
          .sort((a, b) => a.localeCompare(b))
          .map((key) => {
            let valueType = "";
            let value = deviceObj[key];
            let valueDisplay = value;
            if (typeof deviceObj[key] === "boolean") {
              valueDisplay = deviceObj[key].toString();
            }

            if (typeof deviceObj[key] === "object") {
              valueDisplay = JSON.stringify(deviceObj[key]);
              valueType = "object";
            }


            return (
              <DivInline key={key} style={{ borderBottom: "1px solid #fff" }}>
                <Typography sx={{ width: "18vw", color: "#fff" }} variant="body2">{key}</Typography>
                <Box sx={{ width: "75vw", borderLeft: "1px solid #fff" }}>
                  {
                    valueType === "object" ? <JsonTree data={value} /> : <Typography variant="body2" sx={{ padding: "5px", color: "#fff" }}>{valueDisplay}</Typography>
                  }

                </Box>
              </DivInline>
            );
          })}
      </ModalSM>
      </AdminContainer>
    </>
  );
}

function JsonTree({ data }) {
  return (
    <div>
      {Object.keys(data || {}).map((key) => {
        const value = data[key];
        let valueDisplay = value;
        if (typeof value === "object") {
          valueDisplay = JSON.stringify(value);

        }
        if (typeof value === "boolean") {
          valueDisplay = value.toString();
        }
        if (Array.isArray(value)) {
          valueDisplay = JSON.stringify(value);
        }

        return (
          <DivInline key={key} style={{ borderBottom: '0.2px solid grey' }}>
            <Typography variant="body2" sx={{ width: "17vw", padding: "2px", color: "#fff" }}>{key}</Typography>
            <Typography variant='body2' sx={{ width: "58vw", borderLeft: "0.2px solid grey", padding: "2px", color: "#fff" }}>{valueDisplay}</Typography>
            {/* <div style={{ borderLeft: "0.2px solid grey", padding: "2px" }}>{valueDisplay}</div> */}
          </DivInline>
        );
      })}
    </div>
  );
}