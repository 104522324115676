export default function DaylightMotion({
  width,
  height,
  sizeUnit,
  luxLv,
  motionStatus = 0,
  connectStatus,
  disabledConnectStatus,
  disabledSensor,
}) {
  const opacity = luxLv / 10 || 0;
  const w = width || height || 20;
  const h = height || width || 20;
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <svg
      viewBox="0 0 79.375 79.375"
      width={w + (sizeUnit || "px")}
      height={h + (sizeUnit || "px")}
    >
      <rect x={0} y={0} width={79.375} height={79.375} fill="#fff" />
      {!disabledConnectStatus && (
        <circle r="6" cx="7" cy="7" fill={connectColor} />
      )}
      <circle
        cx="62.207"
        cy="17.972"
        r="15.378"
        fill="#918a6f"
        strokeWidth="1.089"
      ></circle>
      <circle
        cx="62.207"
        cy="17.972"
        r="15.378"
        fill="#ffcc00"
        strokeWidth="1.089"
        opacity={opacity}
      ></circle>
      <g
        strokeLinecap="round"
        transform="translate(-8.707 -.291) scale(1.1122)"
      >
        <path
          stroke="#918a6f"
          strokeWidth="1.633"
          d="M62.904 37.221v-2.35M43.019 18.788h2.35M47.646 31.006l1.937-1.33M76.392 31.006l-1.937-1.33M47.646 4.907l1.937 1.331M76.392 4.907l-1.937 1.331"
        ></path>
        <path
          stroke="#ffcc00"
          strokeWidth="1.633"
          opacity={opacity}
          d="M62.904 37.221v-2.35M43.019 18.788h2.35M47.646 31.006l1.937-1.33M76.392 31.006l-1.937-1.33M47.646 4.907l1.937 1.331M76.392 4.907l-1.937 1.331"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#502d16"
        strokeLinecap="round"
        strokeWidth="3.44"
        d="M23.18 1.524H77.947V60.393H23.18z"
      ></path>
      {motionStatus === 0 && (
        <g transform="translate(19 -29.127) scale(1.1059)">
          <path
            fill="none"
            stroke="#00f"
            strokeWidth="3.02"
            d="M-12.037 59.429c-2.34 3.846-6.76 7.887-.744 16.621M21.287 58.745c2.34 3.846 6.76 7.887.744 16.621"
          ></path>
          <circle
            cx="5.008"
            cy="50.955"
            r="4.994"
            fill="#00f"
            fillOpacity="0.996"
          ></circle>
          <path
            fill="#00f"
            d="M-5.004 76.863V62.732c2.472-6.872 17.15-7.814 19.992 0l.127 14.418-3.686.026-.273-10.168c-.28-.29-.557-.624-.933 0l.03 21.582c-.183 1.218-.753 2.264-2.308 2.872l-5.348-.057C.19 91.082.37 89.896-.105 88.928V67.141c-.26-.412-.529-.767-.957-.056l-.057 9.908z"
          ></path>
          <path
            fill="none"
            stroke="#666"
            strokeWidth="2.797"
            d="M-1.371 82.384c-9.963 1.507-11.548 3.613-12.677 5.75 4.916 10.072 35.493 7.207 38.07-.006-1.483-4.146-7.283-4.718-12.318-5.924"
          ></path>
        </g>
      )}
      {motionStatus === 1 && (
        <g transform="translate(-74.777 -24.926)">
          <path
            fill="none"
            stroke="red"
            strokeWidth="4"
            d="M82.833 58.536c-3.099 5.095-8.955 10.446-.986 22.015M126.97 57.63c3.099 5.095 8.955 10.446.986 22.015"
          ></path>
          <circle
            cx="109.85"
            cy="62.343"
            r="5.75"
            fill="red"
            fillOpacity="0.996"
          ></circle>
          <path
            fill="red"
            d="M105.02 65.718s-9.906-.113-10.723.212c-1.025.406-5.374 6.862-5.374 6.862-.496 4.065 1.094 5.089 4.203 3.897l3.794-4.307h3.383L96.92 78.33l-2.564 9.843H84.72c-2.83 2.649-2.414 4.877.615 6.766h14.354c.863-2.398 1.408-5.307 2.666-7.074l6.767 12.713c3.64 2.71 6.678.991 6.766-3.28l-8.407-15.995c.583-1.719 1.603-3.394 3.076-5.024 1.334.675 2.565 1.868 3.793 3.076h6.767c1.921-1.47 3.434-3.005 0-5.331l-3.69-.103c-2.513-1.562-4.165-3.862-5.947-6.049z"
          ></path>
        </g>
      )}
    </svg>
  );
}
