import { mgFindOne, mgFindAll, mgFindByArray } from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchOneMap = async (dispatch, mapID) => {
  const obj = await mgFindOne("map", "mapID", mapID);
  dispatch({
    type: "UPDATED_MAP_OBJ",
    payload: obj,
  });
};
export const fetchAllMap = async () => {
  const data = await mgFindAll("map");
  console.log("fetchAllMap", data);
  let objAll = {};
  data.forEach((obj) => {
    const mapID = obj.mapID;
    objAll[mapID] = obj;
  });
  return objAll;
};
export const createMap = async (userObj, sizeX, sizeY, mapName) => {
  const mapID = "map_" + gs.getRandomHexID(4);
  const jsonData = {
    type: "createMap",
    userObj,
    sizeX,
    sizeY,
    mapID,
    mapName,
  };
  console.log(
    `[SOCKET OUT] createMap ${mapID} ${mapName} at user uid ${userObj.uid}`
  );
  global.socket.emit("fromWeb", jsonData);
  return mapID;
};
export const importMap = async (userObj, mapID, mapObj) => {
  const jsonData = {
    type: "importMap",
    userObj,
    mapID,
    mapObj,
  };
  console.log(`[SOCKET OUT] import map ${mapID} to user uid ${userObj.uid}`);
  global.socket.emit("fromWeb", jsonData);
};
export const removeMap = async (userObj, mapObj) => {
  const jsonData = {
    type: "removeMap",
    userObj,
    mapID: mapObj.mapID,
    mapObj,
  };
  console.log(`[SOCKET OUT] removeMap ${mapObj.mapID} from user uid ${userObj.uid}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateMapProperty = async (mapID, updateObj) => {
  if (!mapID || !updateObj || gs.isEmptyJson(updateObj)) return;
  const jsonData = {
    type: "mapUpdateProperty",
    mapID,
    updateObj,
  };
  console.log(`[SOCKET OUT] mapUpdateProperty ${mapID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateDevicePos = async (
  socket,
  mapID,
  deviceType,
  deviceID,
  x,
  y
) => {
  const jsonData = {
    type: "mapUpdateDevicePos",
    mapID,
    deviceType,
    deviceID,
    x,
    y,
  };
  console.log(
    `[SOCKET OUT] ${mapID} ${deviceType} ${deviceID} move to [${x},${y}]`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const mapAddManyDevices = async (mapID, deviceArrayObj, currentMapObj) => {
  if (!mapID || !deviceArrayObj) return;
  const jsonData = {
    mapID,
    deviceArrayObj, //{light:[xxx,xxx], sensor:[xxx,xxx], gateway:[], daliCtl:[]}
    type: "mapAddManyDevices",
    currentMapObj,
  };
  console.log(`[COMMAND OUT] map ${mapID} AddManyDevices ~ `, jsonData);
  global.socket.emit("fromWeb", jsonData);
}
export const mapRemoveManyDevices = async (mapObj, deviceArrayObj) => {
  if (!mapObj || !deviceArrayObj) return;
  const jsonData = {
    mapObj,
    deviceArrayObj, //{lightArray:[xxx,xxx], sensorArray:[xxx,xxx], gatewayArray:[], daliCtlArray:[], zoneControlArray:[]}
    type: "mapRemoveManyDevices",
  };
  console.log(`[COMMAND OUT] map ${mapObj.mapID} RemoveManyDevices ~ `, jsonData);
  global.socket.emit("fromWeb", jsonData);

}
export const mapAddDevice = async (mapID, deviceID, deviceType, deviceObj, x, y) => {
  const jsonData = {
    type: "mapAddDevice",
    mapID,
    deviceID,
    deviceType,
    deviceObj,
    x: x ?? gs.randomInteger(140, 220),
    y: y ?? gs.randomInteger(140, 220),
  };
  console.log(`[COMMAND OUT] map ${mapID} add ${deviceType} [${deviceID}]`, jsonData);
  global.socket.emit("fromWeb", jsonData);
}
export const mapRemoveDevice = async (mapID, deviceID, deviceType, deviceObj) => {
  const jsonData = {
    type: "mapRemoveDevice",
    mapID,
    deviceID,
    deviceType,
    deviceObj,
  };
  console.log(`[COMMAND OUT] map ${mapID} remove ${deviceType} [${deviceID}]`, jsonData);
  global.socket.emit("fromWeb", jsonData);
}
export const updateMapsFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "map",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleMapFsToLs = async (mapID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleMap",
    mapID: mapID,
  };
  await global.socket.emit("fromWeb", payload);
};
export const deviceToolbarPos = (deviceX, deviceY, btnQty, btnSpace, layerProps) => {
  const { iconSize, mapScale } = layerProps;
  const x =
    deviceX < 500
      ? deviceX * mapScale.x
      : deviceX * mapScale.x - (btnQty - 1) * btnSpace * 1.2;
  const y =
    deviceY < 300 ?
      deviceY * mapScale.y + iconSize * 1.1
      : deviceY * mapScale.y - btnSpace * 1.5;
  return { x, y, btnQty, }
}
export const deviceModalPos = (deviceX, deviceY, modalW, modalH, layerProps = {}) => {
  const { iconSize, mapScale, layerScale } = layerProps;
  const x = deviceX < 500 ? deviceX * mapScale.x : deviceX * mapScale.x - modalW / layerScale;
  const y = deviceY < 300 ? deviceY * mapScale.y + iconSize : deviceY * mapScale.y - modalH / layerScale - iconSize;
  return { x, y }
}