import { deviceInfoOfObj } from "./generalActions";

export const deviceUpdateFirmwareFromIP = async (deviceObj) => {
  const deviceInfo = deviceInfoOfObj(deviceObj);
  const { deviceType, deviceID } = deviceInfo || {};
  const serverIpStr = deviceObj.updateFirmwareServerIP;
  let payload = {
    gatewayID: deviceObj.gatewayID,
    dtkAdd: deviceObj.dtkAdd,
    updateSpiffs: 0,
    serverIP: serverIpStr.split(".").map((str) => parseInt(str)),
    disconnectIcon: true
  }
  if (deviceType === "light") {
    payload.serial = deviceID;
    payload.commandCode = 0x0230;
    payload.type = "control_single_light";
  } else if (deviceType === "sensor") {
    payload.sensorID = deviceID;
    payload.commandCode = 0x062f;
    payload.type = "control_single_sensor";
  } else if (deviceType === "daliCtl") {
    payload.daliCtlID = deviceID;
    payload.commandCode = 0x0a2c;
    payload.type = "control_dali_ctl";
  } else {
    alert("Invalid device type");
    return;
  }
  console.log(`[COMMAND OUT] deviceUpdateFirmwareFromIP ${deviceType} ${deviceID} server IP ${serverIpStr}`);
  global.socket.emit("fromWeb", payload);
}
export const queryDeviceCurrentFirmwareVersion = async (deviceObj) => {
  const deviceInfo = deviceInfoOfObj(deviceObj);
  const { deviceType, deviceID } = deviceInfo || {};
  let payload = {
    gatewayID: deviceObj.gatewayID,
    dtkAdd: deviceObj.dtkAdd,
  }
  if (deviceType === "light") {
    payload.serial = deviceID;
    payload.commandCode = 0x0314;
    payload.type = "control_single_light";
  } else if (deviceType === "sensor") {
    payload.sensorID = deviceID;
    payload.commandCode = 0x0711;
    payload.type = "control_single_sensor";
  } else if (deviceType === "daliCtl") {
    payload.daliCtlID = deviceID;
    payload.commandCode = 0x0b1c;
    payload.type = "control_dali_ctl";
  } else {
    alert("Invalid device type");
    return;
  }
  console.log(`[COMMAND OUT] queryDeviceCurrentFirmwareVersion ${deviceType} ${deviceID}`);
  global.socket.emit("fromWeb", payload);
}
export const deviceErrorMsg = (deviceObj, deviceType, state) => {
  if (!deviceObj) return {};
  const { gatewayObjAll, daliCtlObjAll, mapObjAll, activeMapID } = state;
  const mapObj = mapObjAll[activeMapID];
  if (mapObj?.disableAlert) return {};
  const { gatewayID, daliCtlID, dtkChannel } = deviceObj || {};
  let meshChannel = deviceObj?.meshChannel;
  let wifiApMacAddress = deviceObj?.wifiApMacAddress;
  let enableTtyS7 = deviceObj?.enableTtyS7;
  let disableEspNow = deviceObj?.disableEspNow;
  if (deviceType === "light" && daliCtlID) {
    const daliCtlObj = daliCtlObjAll[daliCtlID];
    meshChannel = daliCtlObj?.meshChannel;
    wifiApMacAddress = daliCtlObj?.wifiApMacAddress;
    enableTtyS7 = daliCtlObj?.enableTtyS7;
    disableEspNow = daliCtlObj?.disableEspNow;
  }
  const gatewayObj = gatewayObjAll[gatewayID];
  const { ttyS3, ttyS2 } = gatewayObj || {};
  let errorMsgObj = {};
  if (gatewayID && !gatewayObj?.gatewayConnected && gatewayObj) {
    errorMsgObj[0] = "Gateway is not connected";
  }
  if (gatewayID && gatewayObj && !dtkChannel && meshChannel >= 0 && Number(meshChannel) !== Number(ttyS3?.meshChannel) && Number(meshChannel) !== Number(ttyS2?.meshChannel)) {
    errorMsgObj[1] = `Mesh channel ${meshChannel} mismatch with gateway ${gatewayObj?.ttyS3?.meshChannel} / ${gatewayObj?.ttyS2?.meshChannel}`;
  }
  if (!wifiApMacAddress && !disableEspNow) {
    errorMsgObj[2] = "ESP enabled but wifiApMacAddress missing";
  }
  if (enableTtyS7 === disableEspNow) {
    errorMsgObj[3] = "enableTtyS7 and disableESPNow not match";
  }
  if (!gatewayID) {
    errorMsgObj[4] = "Not connect to any Gateway";
  }
  if (deviceObj.ramFault) {
    errorMsgObj[5] = "Ram exceed 70%";
  }
  if (deviceObj.pcfFault) {
    errorMsgObj[6] = "Motion/Daylight sensor head comm fault";
  }
  if (deviceObj.luxFault) {
    errorMsgObj[7] = "Daylight sensor read data fault";
  }
  if (deviceObj.touchPanelFault) {
    errorMsgObj[8] = "Touch panel fault";
  }
  if (deviceObj.coreTempFault) {
    errorMsgObj[9] = "Core temperature fault";
  }
  if (deviceObj.lowMemory) {
    errorMsgObj[10] = "RAM low";
  }
  if (deviceObj.i2cError) {
    errorMsgObj[11] = "I2C error";
  }
  if (deviceObj.batLowVolt) {
    errorMsgObj[12] = "Battery low voltage";
  }
  if (deviceObj.batHighVolt) {
    errorMsgObj[13] = "Battery high voltage";
  }
  if (deviceObj.mcpFail) {
    errorMsgObj[14] = "MCP fail";
  }
  if (!gatewayObj && gatewayID) {
    errorMsgObj[15] = "Gateway not in this map";
  }
  if (gatewayID && gatewayObj && dtkChannel && Number(dtkChannel) !== Number(ttyS3?.dtkChannel) && Number(dtkChannel) !== Number(ttyS2?.dtkChannel)) {
    errorMsgObj[1] = `Dtk channel ${dtkChannel} mismatch with gateway ${gatewayObj?.ttyS3?.dtkChannel} / ${gatewayObj?.ttyS2?.dtkChannel}`;
  }
  return errorMsgObj;
}