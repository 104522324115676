import { mgPost } from "./mongoApiActions";

export const getDocsWhereFS = async (
  fsCollPath,
  resultType,
  whereField,
  compareOperator,
  compareVal,
  orderByField,
  order,
  qty,
  rangeField,
  upperRange,
  lowerRange
) => {
  const body = {
    fsCollPath,
    resultType,
    whereField,
    compareOperator,
    compareVal,
    orderByField,
    order,
    qty,
    rangeField,
    upperRange,
    lowerRange,
  };
  const res = await mgPost("getDocsWhereFS", body);
  return res;
};
export const updateDocFS = async (docPath, updateObj) => {
  const body = {
    docPath,
    updateObj,
  };
  const res = await mgPost("updateDocFS", body);
  return res;
};
export const getDocFS = async (docPath) => {
  const body = {
    docPath,
  };
  const res = await mgPost("getDocFS", body);
  return res;
};
