import RefreshIcon from '@mui/icons-material/Refresh';
import TimerOffIcon from '@mui/icons-material/TimerOff';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, CircularProgress, Typography, Tooltip } from '@mui/material';
import { useState, useEffect, useContext, useRef } from 'react';
import { SMContext } from 'context/smContext';

/**
 * A button component that handles asynchronous queries with loading, success, and timeout states.
 * 
 * @component
 * @param {Object} props - Component props
 * @param {Function} [props.onClick] - Callback function to execute when the button is clicked
 * @param {string} props.listenStateKey - Key to identify and listen for state changes in the SMContext
 * @param {number} [props.timeOut=3000] - Timeout duration in milliseconds before showing timeout state
 * @param {string} [props.label] - Optional text label to display on the button
 * 
 * @returns {JSX.Element} A Material-UI IconButton with different icons based on the query state:
 *   - RefreshIcon: Initial/ready state
 *   - CircularProgress: Loading state
 *   - CheckIcon: Success state
 *   - TimerOffIcon: Timeout state
 * 
 * @example
 * <QueryFuncIcon 
 *   onClick={() => fetchData()} 
 *   listenStateKey="myQuery"
 *   timeOut={5000}
 *   label="Refresh"
 * />
 */
export const QueryFuncIcon = ({onClick, listenStateKey, timeOut=3000, label}) => {
  const [state, dispatch] = useContext(SMContext);
  const listenState = state[listenStateKey];
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [timeOuted, setTimeOuted] = useState(false);
  const timerRef = useRef(null);
  useEffect(() => {
    clearTimeout(timerRef.current);
    setTimeOuted(false);
    if (listenState) {
      setIsLoading(false);
      setResponse(listenState);
    }
  }, [listenState]);
  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    }
  }, []);
  const handleClick = () => {
    dispatch({
      type: "RESET_RESPONSE",
      payload: {responseKey: listenStateKey}
    })
    setResponse(null);
    setIsLoading(true);
    if (onClick) {
      onClick();
    }
    clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsLoading(false);
      setTimeOuted(true);
    }, timeOut);
  }

  return (
    <Tooltip 
      title={response ? JSON.stringify(response) : ''}
    >
      <IconButton onClick={handleClick}>
        {label && <Typography variant="h6">{label}</Typography>}
        {isLoading ? <CircularProgress size={20} /> : timeOuted ? <TimeOutIcon /> : !timeOuted && response ? <SuccessIcon /> : <RefreshIcon />}
      </IconButton>
    </Tooltip>
  );
}

function TimeOutIcon() {
  return <TimerOffIcon color="error" />
}

function SuccessIcon() {
  return <CheckIcon color="success" />
}