import { Typography } from "@mui/material";
import DivInline from "./DivInline";
import { LockOpen, Lock } from "@mui/icons-material";

export default function LockIcon({ lockLevel, size }) {
  return (
    <DivInline>
      {lockLevel === 5 ? (
        <LockOpen sx={{ fontSize: size || "1rem" }} />
      ) : (
        <Lock sx={{ fontSize: size || "1rem" }} />
      )}
      <Typography variant="caption">{lockLevel}</Typography>
    </DivInline>
  );
}
