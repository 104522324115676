import { cloneElement, useState } from "react";
import { Typography, Tooltip, InputAdornment, TextField } from "@mui/material";
import { cursor } from "asset/string/string";

export default function MapTopBtn({
  btnClick,
  children,
  iconColor = "#000",
  tooltip,
  disabled,
  btnSize = 25,
}) {
  const [hover, setHover] = useState(false);
  const btnIconStyle = {
    margin: 5,
    width: btnSize,
    height: btnSize,
    cursor: disabled ? cursor.notAllowed : cursor.pointer,
    color: disabled ? "grey" : iconColor,
    background: hover ? "lightgrey" : "transparent",
  };
  return (
    <div
      onClick={() => {
        if (!disabled) {
          btnClick();
        }
      }}
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
    >
      <Tooltip title={<Typography>{tooltip}</Typography>}>
        {cloneElement(children, {
          style: { ...btnIconStyle },
        })}
      </Tooltip>
    </div>
  );
}
