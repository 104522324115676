import { useContext, useRef } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { useState } from "react";
import { Typography, Tooltip, Button, Box } from "@mui/material";
import { Details } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import moment from "moment";
import { fetchSensorHistory } from "actions/sensorActions";
import { ViewportList } from "react-viewport-list";
import IconButtonBack from "components/IconButtonBack";
import { hideBigProgress, showBigProgress } from "actions/screenActions";
import SensorHistoryItem from "./SensorHistoryItem";
import AdminContainer from "components/AdminContainer";

const w = window.innerWidth;
const h = window.innerHeight;
export default function SensorDetailHistoryModal() {
  const [state, dispatch] = useContext(SMContext);
  const ref = useRef(null);
  const { activeSensorID, sensorObjAll } = state;
  const [open, setOpen] = useState(false);
  const [openJson, setOpenJson] = useState(false);
  const [data, setData] = useState([]);
  const [showRegularUpload, setShowRegularUpload] = useState(false);
  const [json, setJson] = useState({});
  const fetchData = async (hr) => {
    setData([]);
    showBigProgress(dispatch);
    const endTsp = moment().valueOf();
    const startTsp = moment().subtract(hr, "hours").valueOf();
    let arr = await fetchSensorHistory(activeSensorID, startTsp, endTsp);
    arr = arr.sort((a, b) => b.timeStamp - a.timeStamp);
    if (!showRegularUpload)
      arr = arr.filter((obj) => obj.uploadProtocol !== "regularSensor");
    setData(arr);
    console.log(arr);
    hideBigProgress(dispatch);
  }
  let tableData = [...data];
  const sensorObj = sensorObjAll[activeSensorID] || {};
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Tooltip title="More history">
          <Details />
        </Tooltip>
      </Button>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        width="80vw"
        height="95vh"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography sx={{ marginRight: "2vw" }}>History</Typography>
          <Button size="small" variant="outlined" onClick={() => fetchData(1)}>
            1 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(2)}>
            2 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(4)}>
            4 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(8)}>
            8 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(12)}>
            12 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(24)}>
            24 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => setData([])}>
            Clear
          </Button>
          <AdminContainer>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setShowRegularUpload((s) => !s)}
            >
              {showRegularUpload ? "Hide" : "Show"} regular
            </Button>
          </AdminContainer>
        </DivInline>
        <Divider10 />
        <div className="scroll-container" ref={ref}>
          <ViewportList viewportRef={ref} items={tableData}>
            {(d, index) => {
              const m = moment(d.timeStamp);
              const dataDate = m.format("YYYY-MM-DD");
              const dataTime = m.format("HH:mm:ss");
              let uploadProtocolStr = d.uploadProtocol;
              if (typeof d.uploadProtocol === 'number') {
                uploadProtocolStr = d.uploadProtocol.toString(16).padStart(4, "0");
              }
              return (
                <Box key={index} sx={{ display: "flex" }}>
                  <Typography variant="caption" sx={{ width: 0.1 * w }}>
                    {index}
                  </Typography>
                  <Typography variant="caption" sx={{ width: 0.15 * w }}>
                    {dataDate}
                  </Typography>
                  <Typography variant="caption" sx={{ width: 0.15 * w }}>
                    {dataTime}
                  </Typography>
                  <Typography variant="caption" sx={{ width: 0.1 * w }}>
                    {uploadProtocolStr}
                  </Typography>
                  <Box
                    sx={{ width: 0.55 * w, cursor: "pointer", display: "flex" }}
                    onClick={() => {
                      if (state.userObj.level < 1) {
                        setOpenJson(true);
                        setJson(d);
                      }
                    }}
                  >
                    <SensorHistoryItem data={d} iconOnly sensorObj={sensorObj} />
                    <Typography sx={{ marginLeft: "1vw" }} variant="caption">
                      <SensorHistoryItem data={d} messageOnly sensorObj={sensorObj} />
                    </Typography>
                  </Box>
                </Box>
              );
            }}
          </ViewportList>
        </div>

      </ModalSM>

    </>
  );
}
