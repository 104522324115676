import DivExist from "components/DivExist";
import DeviceGateway from "../device/DeviceGateway";
import DeviceUnknown from "../device/DeviceUnknown";
import LinesGatewaySelected from "../device/LinesGatewaySelected";
import { Text } from "react-konva";

export default function LayerGateway({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    gatewayObjAll,
    mapScale,
    selectedID,
    selectedDeviceType,
  } = layerProps;
  const mapGatewayObj = mapObjAll[activeMapID]?.gatewayObj;
  const gwArray = Object.keys(mapGatewayObj || {});
  return (
    <DivExist show={gwArray.length > 0}>
      {gwArray.map((g, key) => {
        const x = mapGatewayObj?.[g]?.x * mapScale.x || 0;
        const y = mapGatewayObj?.[g]?.y * mapScale.y || 0;
        if (gatewayObjAll?.[g]) {
          return (
            <DeviceGateway
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              gatewayID={g}
              gatewayObj={gatewayObjAll?.[g]}
            />
          );
        } else {
          return (
            <DeviceUnknown
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              deviceID={g}
              deviceType="gateway"
              text="G"
            />
          );
        }
      })}
      <DivExist
        show={selectedID && selectedDeviceType === "gateway" ? true : false}
      >
        <LinesGatewaySelected layerProps={layerProps} />
      </DivExist>
    </DivExist>
  );
}
