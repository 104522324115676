import { useContext, useRef, useState, useMemo, useEffect } from "react";
import { SMContext } from "context/smContext";
import {
    Button,
    Tooltip,
    Typography,
    ButtonGroup,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TextField,
    Box,
} from "@mui/material";
import DivInline from "components/DivInline";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import InputField from "components/InputField";
import Divider10 from "components/Divider10";
import { mgFindAll, mgFindOne } from "actions/mongoApiActions";
import moment from "moment";
import {
    updateMapsFsToLs,
    updateSingleMapFsToLs,
} from "actions/mapActions";
import { ViewportList } from "react-viewport-list";

import { mgPost } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import { alertWindow, confirmWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import DivExist from "components/DivExist";
import { mgUpdateOneUpsert } from "actions/mongoApiActions";
import ServerLog from "./ServerLog";
import ServerConsoleLog from "./ServerConsoleLog";
import IconButtonDelete from "components/IconButtonDelete";
import { isMatch } from "actions/generalActions";
import DivCol from "components/DivCol";
import ServerMonitorModal from "./ServerMonitorModal";
import TransferMapDevicesFsToLs from "./TransferMapDevicesFsToLs";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import JsonModalContainer from "components/JsonModalContainer";
const gs = new General();

export default function ServerSettingMapPanel({ open }) {
    const [state, dispatch] = useContext(SMContext);
    const [searchText, setSearchText] = useState("");
    const [importID, setImportID] = useState("");
    const [allMapsLs, setAllMapsLs] = useState([]);
    const [allMapsFs, setAllMapsFs] = useState([]);
    const [allMapIDs, setAllMapIDs] = useState([]);
    const { serverObj } = state;
    const serverID = serverObj.serverID;
    const handleFetchAllMaps = async () => {
        // const objAll = await fetchAllMap();
        // setAllMapsLs(objAll);
        let arrLs = await mgFindAll("map");
        let arrFs = await getDocsWhereFS("SM_map", "array");
        setAllMapsLs(arrLs);
        setAllMapsFs(arrFs);
        const midArrLs = arrLs.map(obj => obj.mapID);
        const midArrFs = arrFs.map(obj => obj.mapID);
        setAllMapIDs([...midArrLs, ...midArrFs]);
    };
    useEffect(() => {
        if (open) {
            handleFetchAllMaps();
        }
    }, [open]);

    const allMapsLsUse = useMemo(() => {
        let arr = (allMapsLs || [])
            .sort((a, b) => a.mapID.localeCompare(b.mapID));
        if (searchText !== "" && arr.length > 0) {
            arr = arr.filter(
                (obj) =>
                    isMatch(obj.mapID, searchText) || isMatch(obj.mapName, searchText)
            );
        }
        return arr;
    }, [allMapsLs, searchText]);
    const allMapsFsUse = useMemo(() => {
        let arr = (allMapsFs || [])
            .sort((a, b) => a.mapID.localeCompare(b.mapID));
        if (searchText !== "" && arr.length > 0) {
            arr = arr.filter(
                (obj) =>
                    isMatch(obj.mapID, searchText) || isMatch(obj.mapName, searchText)
            );
        }
        return arr;
    }, [allMapsFs, searchText]);
    const importMapFromFs = async () => {
        let fsObj = await mgPost("getDocFS", { docPath: `SM_map/${importID}` });
        console.log("fsObj", fsObj);
        if (fsObj.result !== "fail") {
            fsObj = { ...fsObj, serverID: serverID };
            await mgUpdateOneUpsert("map", { mapID: importID }, fsObj);
            await mgPost("updateDocFS", {
                docPath: `SM_map/${importID}`,
                updateObj: fsObj,
            });
        } else {
            alertWindow(dispatch, "No such map in FS");
        }
    }
    return <>
        <DivInline justifyContent="space-between">
            <DivInline>
                <Typography variant="h6">Maps</Typography>
                <InputField
                    sx={{ width: "15vw", marginLeft: "1vw" }}
                    label="Search"
                    value={searchText}
                    onInput={(v) => setSearchText(v)}
                    keyPressEnter
                />
                <IconButtonDelete onBtnClick={() => setSearchText("")} />
            </DivInline>

            <DivInline>
                <TextField
                    label="import one map from FS (mapID)"
                    value={importID}
                    onChange={(e) => setImportID(e.target.value)}
                    size="small"
                    sx={{ minWidth: "30vw" }}
                />
                <Button
                    variant="outlined"
                    size="small"
                    onClick={importMapFromFs}
                >
                    import
                </Button>
            </DivInline>
        </DivInline>
        <Divider10 />
        <Table size="small" >
            <TableBody>
                <TableRow>
                    <TableCell>mapID</TableCell>
                    <TableCell>name-Server</TableCell>
                    <TableCell>mapBg-Server</TableCell>
                    <TableCell>name-FS</TableCell>
                    <TableCell>magBg-FS</TableCell>
                    <TableCell>{`FS\u{27fa}Server`}</TableCell>
                </TableRow>

                {(allMapIDs || []).map((mapID, key) => {
                    const mapObjLs = (allMapsLsUse || []).find(obj => obj.mapID === mapID);
                    const bgUrlLs = `${global.ip}/img/${mapObjLs?.mapFileName}`;
                    const deviceNoLs = `${Object.keys(mapObjLs?.lightObj || {}).length} light ${Object.keys(mapObjLs?.sensorObj || {}).length} sensor ${Object.keys(mapObjLs?.daliCtlObj || {}).length} dali ${Object.keys(mapObjLs?.gatewayObj || {}).length} gateway ${Object.keys(mapObjLs?.zoneControlObj || {}).length} zc`;
                    const mapObjFs = (allMapsFsUse || []).find(obj => obj.mapID === mapID);
                    const deviceNoFs = `${Object.keys(mapObjFs?.lightObj || {}).length} light ${Object.keys(mapObjFs?.sensorObj || {}).length} sensor ${Object.keys(mapObjFs?.daliCtlObj || {}).length} dali ${Object.keys(mapObjFs?.gatewayObj || {}).length} gateway ${Object.keys(mapObjFs?.zoneControlObj || {}).length} zc`;
                    return (
                        <TableRow key={key}>
                            <TableCell>{mapID}</TableCell>
                            <TableCell>
                                {mapObjLs && <img src={bgUrlLs} style={{ width: "8vw", height: "7vh" }} alt="" />}
                            </TableCell>
                            <TableCell>
                                <JsonModalContainer jsonData={mapObjLs}>
                                    <Typography variant="caption" display="block">{mapObjLs?.mapName}</Typography>
                                    <Typography variant="caption" display="block">{mapObjLs?.serverID}</Typography>
                                    <Typography variant="caption" display="block">{deviceNoLs}</Typography>
                                </JsonModalContainer>
                            </TableCell>
                            <TableCell>
                                {mapObjFs && <img src={mapObjFs?.mapUrl} style={{ width: "8vw", height: "7vh" }} alt="" />}
                            </TableCell>
                            <TableCell>
                                <JsonModalContainer jsonData={mapObjFs}>
                                    <Typography variant="caption" display="block">{mapObjFs?.mapName}</Typography>
                                    <Typography variant="caption" display="block">{mapObjFs?.serverID}</Typography>
                                    <Typography variant="caption" display="block">{deviceNoFs}</Typography>
                                </JsonModalContainer>
                            </TableCell>
                            <TableCell><TransferMapDevicesFsToLs mapObjLs={mapObjLs} mapObjFs={mapObjFs} /> </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    </>
}