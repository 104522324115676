import { mgFindInArray, mgFindManyInArray } from "actions/mongoApiActions";
import { useState, useEffect, useContext } from "react";
import { SMContext } from "../context/smContext";

export const useFetchMapsInUser = async () => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, userObj, fs } = state;
  const { uid } = userObj;
  useEffect(() => {
    if (enableFS) {
      console.log(`Listen to map in user ${uid} from firebase`);
      const q = fs.query(
        fs.collection(fs.fsdb, "SM_map"),
        fs.where("inUser", "array-contains", uid)
      );
      const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
        let objAll = {};
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          objAll[doc.id] = obj;
        });
        console.log("mapObjAll from FS: ", objAll);
        dispatch({
          type: "FETCHED_MAP_OBJ_ALL",
          payload: objAll,
        });
      });

      return () => unsubscribe();
    } else {
      const fetchMgData = async () => {
        console.log(`Fetch maps in user ${uid} from server`);
        const data = await mgFindInArray("map", "inUser", uid);
        if (data.result === "fail") return;
        let objAll = {};
        data.forEach((obj) => {
          const mapID = obj.mapID;
          objAll[mapID] = obj;
        });
        dispatch({
          type: "FETCHED_MAP_OBJ_ALL",
          payload: objAll,
        });
      };
      fetchMgData();
    }
  }, [uid]);
};

export const useFetchGatewayInMap = async (mapID) => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, fs } = state;
  useEffect(() => {
    if (enableFS) {
      console.log(`Listen to gateways in map ${mapID} from firebase`);
      const q = fs.query(
        fs.collection(fs.fsdb, "SM_gateway"),
        fs.where("mapID", "array-contains", mapID)
      );
      const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
        let objAll = {};
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          objAll[doc.id] = obj;
        });
        console.log("gatewayObjAll from FS: ", objAll);
        dispatch({
          type: "FETCHED_GATEWAY_OBJ_ALL",
          payload: objAll,
        });
      });
      return () => unsubscribe();
    } else {
      const fetchData = async () => {
        const data = await mgFindInArray("gateway", "mapID", mapID);
        if (data.result === "fail") return;
        let objAll = {};
        (data || []).forEach((obj) => {
          const gatewayID = obj.gatewayID;
          objAll[gatewayID] = obj;
        });
        dispatch({
          type: "FETCHED_GATEWAY_OBJ_ALL",
          payload: objAll,
        });
      };
      fetchData();
    }
  }, [mapID]);
};
export const useFetchSerialInMap = async (mapID) => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, fs } = state;
  useEffect(() => {
    if (enableFS) {
      const q = fs.query(
        fs.collection(fs.fsdb, "SM_serial"),
        fs.where("mapID", "array-contains", mapID)
      );
      const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
        let objAll = {};
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          objAll[doc.id] = obj;
        });
        console.log("lightObjAll from FS: ", objAll);
        dispatch({
          type: "FETCHED_LIGHT_OBJ_ALL",
          payload: objAll,
        });
      });
      return () => unsubscribe();
    } else {
      const fetchData = async () => {
        const data = await mgFindInArray("serial", "mapID", mapID);
        if (data.result === "fail") return;
        let objAll = {};
        (data || []).forEach((obj) => {
          const serial = obj.serial;
          objAll[serial] = obj;
        });
        dispatch({
          type: "FETCHED_LIGHT_OBJ_ALL",
          payload: objAll,
        });
      };
      fetchData();
    }
  }, [mapID]);
};
export const useFetchSensorInMap = async (mapID) => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, fs } = state;
  useEffect(() => {
    if (enableFS) {
      const q = fs.query(
        fs.collection(fs.fsdb, "SM_sensor"),
        fs.where("mapID", "array-contains", mapID)
      );
      const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
        let objAll = {};
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          objAll[doc.id] = obj;
        });
        console.log("sensorObjAll from FS: ", objAll);
        dispatch({
          type: "FETCHED_SENSOR_OBJ_ALL",
          payload: objAll,
        });
      });
      return () => unsubscribe();
    } else {
      const fetchData = async () => {
        const data = await mgFindInArray("sensor", "mapID", mapID);
        if (data.result === "fail") return;
        let objAll = {};
        (data || []).forEach((obj) => {
          const sensorID = obj.sensorID;
          objAll[sensorID] = obj;
        });
        dispatch({
          type: "FETCHED_SENSOR_OBJ_ALL",
          payload: objAll,
        });
      };
      fetchData();
    }
  }, [mapID]);
};
export const useFetchDaliCtlInMap = async (mapID) => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, fs } = state;
  useEffect(() => {
    if (enableFS) {
      const q = fs.query(
        fs.collection(fs.fsdb, "SM_daliCtl"),
        fs.where("mapID", "array-contains", mapID)
      );
      const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
        let objAll = {};
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          objAll[doc.id] = obj;
        });
        console.log("daliCtlObjAll from FS: ", objAll);
        dispatch({
          type: "FETCHED_DALICTL_OBJ_ALL",
          payload: objAll,
        });
      });
      return () => unsubscribe();
    } else {
      const fetchData = async () => {
        const data = await mgFindInArray("daliCtl", "mapID", mapID);
        if (data.result === "fail") return;
        let objAll = {};
        (data || []).forEach((obj) => {
          const daliCtlID = obj.daliCtlID;
          objAll[daliCtlID] = obj;
        });
        dispatch({
          type: "FETCHED_DALICTL_OBJ_ALL",
          payload: objAll,
        });
      };
      fetchData();
    }
  }, [mapID]);
};
export const useFetchZoneControlInMap = async (mapID) => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, fs } = state;
  useEffect(() => {
    if (enableFS) {
      const q = fs.query(
        fs.collection(fs.fsdb, "SM_zoneControl"),
        fs.where("mapID", "array-contains", mapID)
      );
      const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
        let objAll = {};
        querySnapshot.forEach((doc) => {
          let obj = doc.data();
          objAll[doc.id] = obj;
        });
        console.log("zoneControlObjAll from FS: ", objAll);
        dispatch({
          type: "FETCHED_ZONECONTROL_OBJ_ALL",
          payload: objAll,
        });
      });
      return () => unsubscribe();
    } else {
      const fetchData = async () => {
        const data = await mgFindInArray("zoneControl", "mapID", mapID);
        if (data.result === "fail") return;
        let objAll = {};
        (data || []).forEach((obj) => {
          const zoneControlID = obj.zoneControlID;
          objAll[zoneControlID] = obj;
        });
        dispatch({
          type: "FETCHED_ZONECONTROL_OBJ_ALL",
          payload: objAll,
        });
      };
      fetchData();
    }
  }, [mapID]);
};
export const useFetchTimeTableInMap = async (mapID) => {
  const [state, dispatch] = useContext(SMContext);
  const { enableFS, fs } = state;
  useEffect(() => {
    const serialArr = Object.keys(state.lightObjAll || {});
    const zcArr = Object.keys(state.zoneControlObjAll || {});
    if (serialArr.length > 0) {
      if (enableFS) {
        const q = fs.query(
          fs.collection(fs.fsdb, "SM_timeTable"),
          fs.where("mapID", "array-contains", mapID)
        );
        const unsubscribe = fs.onSnapshot(q, (querySnapshot) => {
          let objAll = {};
          let arrAll = [];
          querySnapshot.forEach((doc) => {
            let obj = doc.data();
            objAll[doc.id] = obj;
            arrAll.push(obj);
          });
          console.log("timeTableObjAll from FS: ", objAll);
          dispatch({
            type: "FETCHED_TIMETABLE_OBJ_ALL",
            payload: { objAll, arrAll },
          });
        });
        return () => unsubscribe();
      } else {
        const fetchMgTimeTable = async () => {
          const dataInSerial = await mgFindManyInArray(
            "timeTable",
            "serial",
            serialArr
          );
          const dataInZoneControl = await mgFindManyInArray(
            "timeTable",
            "zoneControlID",
            zcArr
          );
          let objAll = {};
          let arrAll = [];
          if (dataInSerial.result === "fail" || dataInZoneControl.result === "fail") return;
          dataInSerial.forEach((obj) => {
            const timeTableID = obj.timeTableID;
            objAll[timeTableID] = obj;
            arrAll.push(obj);
          });
          dataInZoneControl.forEach((obj) => {
            const timeTableID = obj.timeTableID;
            objAll[timeTableID] = obj;
            arrAll.push(obj);
          });
          dispatch({
            type: "FETCHED_TIMETABLE_OBJ_ALL",
            payload: { objAll, arrAll },
          });
        };
        fetchMgTimeTable();
      }
    }
  }, [mapID, state.lightObjAll, state.zoneControlObjAll]);
};
export const useListenWindowResize = (vw, vh) => {
  const [, dispatch] = useContext(SMContext);
  useEffect(() => {
    const resizeFunction = () => {
      const sw = window.innerWidth;
      const sh = window.innerHeight;
      const scaleX = ((sw / 1000) * vw) / 100;
      const scaleY = ((sh / 600) * vh) / 100;
      // setMapSize({ x: (sw * vw) / 100, y: (sh * vh) / 100 });
      // setMapScale({ x: scaleX, y: scaleY });
      dispatch({
        type: "SET_MAP_SIZE_SCALE",
        payload: {
          size: { x: (sw * vw) / 100, y: (sh * vh) / 100 },
          scale: { x: scaleX, y: scaleY },
        },
      });
    };
    resizeFunction();
    window.addEventListener("resize", resizeFunction);
    return () => {
      window.removeEventListener("resize", resizeFunction);
    };
  }, []);
};
export const useListenMapKeyPress = (layerRef) => {
  const [, dispatch] = useContext(SMContext);
  useEffect(() => {
    const handleKeyDown = (e) => {
      const key = e.key;
      // console.log("key press:", key);
      if (key === "p") dispatch({ type: "SET_ENABLE_PAN", payload: true });
      if (key === "m") dispatch({ type: "SET_ENABLE_PAN", payload: false });
      if (key === "i") dispatch({ type: "SET_ENABLE_ZOOM", payload: "in" });
      if (key === "o") dispatch({ type: "SET_ENABLE_ZOOM", payload: "out" });
      if (key === "n") dispatch({ type: "SET_ENABLE_ZOOM", payload: "" });
      if (key === "r") {
        const layer = layerRef.current;
        layer.position({ x: 0, y: 0 });
        layer.scale({ x: 1, y: 1 });
        layer.batchDraw();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [layerRef]);
};

export const useListenKey = (key, setState) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === key) {
        setState(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === key) {
        setState(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
}