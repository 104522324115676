import React from "react";

export default function ZcSwitch({ width, height, sizeUnit, status }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const knobPos = status === 1 ? "-66.729" : "-96";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g strokeLinejoin="miter" strokeOpacity="1">
        <path
          fill={status === 1 ? "#ffaaaa" : "#5D6D7E"}
          stroke="#ff6600"
          strokeDasharray="5,5"
          strokeWidth={6.635}
          d="M19.548 21.022l41.188-.282c18.684 2.092 21.208 31.574.448 35.252H19.399c-18.896-5.071-17.977-31.253.15-34.97z"
        ></path>
        <path
          fill="#803300"
          stroke="none"
          strokeLinecap="butt"
          strokeWidth={0.271}
          d="M10.325 32.288c2.867-4.513 7.316-6.344 9.198-6.387l41.392-.492c3.524.244 7.126 2.885 10.181 6.793-1.04-2.007-2.003-4.052-3.983-5.612-1.819-1.474-3.944-2.4-6.381-2.765l-40.801.153c-2.656.705-4.63 1.767-6.005 3.143-1.87 1.512-2.782 3.325-3.601 5.167z"
        ></path>
        {/* <g transform="matrix(1.06743 0 0 1.0525 -66.729 12.49)"> */}
        <g transform={`matrix(1.06743 0 0 1.0525 ${knobPos} 12.49)`}>
          <path
            fill="#e6e6e6"
            stroke="none"
            strokeLinecap="butt"
            strokeWidth={0.296}
            d="M107.263 35.521h13.204c4.265-1.878 10.387-5.39 10.466-13.841-.533-7.524-6.718-12.07-10.661-12.184l-12.618.097c-5.94.398-11.62 4.956-11.542 13.646.4 6.962 6.354 12.19 11.15 12.282z"
          ></path>
          <path
            fill="#666"
            stroke="none"
            strokeLinecap="butt"
            strokeWidth={0.296}
            d="M96.144 23.666c1.258 6.429 4.551 10.798 11.119 11.855h13.204c6.612-3.03 10.306-7.196 10.421-12.755-.283 3.622-.798 8.567-3.938 11.84-2.333 2.056-6.393 4.45-8.91 4.74l-10.554-.258c-2.816-.437-5.722-1.546-7.919-3.777-4.32-4.01-3.41-7.855-3.423-11.645z"
          ></path>
          <path
            fill="none"
            stroke="#b3b3b3"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth={3.8}
            d="M106.234 16.756v10.925"
          ></path>
          <path
            fill="none"
            stroke="#b3b3b3"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth={3.8}
            d="M113.802 16.412v10.925"
          ></path>
          <path
            fill="none"
            stroke="#b3b3b3"
            strokeDasharray="none"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth={3.8}
            d="M121.485 16.412v10.925"
          ></path>
        </g>
      </g>
    </svg>
  );
}
