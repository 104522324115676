import { useState, useEffect, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Slider, Tooltip, IconButton } from "@mui/material";
import { LockOpen } from "@mui/icons-material";
import LightIcon from "components/LightIcon";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import ManualCheckStartModal from "./ManualCheckStartModal";
import DivCol from "components/DivCol";
import {
  setPower,
  switchLight,
  switchLightDali,
  queryOneLightSetting,
  changeOneLightSetting,
} from "actions/lightActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import { changeDaliCtlOneSetting, queryDaliCtlOneLightSetting } from "actions/daliCtlActions";
import General from "@ecoenghk/general";
import EditTestTimeTableModal from "containers/testReport/EditTestTimeTableModal";
import Add12MonthBatTestModal from "containers/testReport/Add12MonthBatTestModal";
import ThemeContainer from "components/ThemeContainer";
import { batTestTimeInfo } from "actions/timetableActions";
import AdminContainer from "components/AdminContainer";
import { deviceConnected } from "actions/generalActions";
const gs = new General();
export default function LightControlPanel() {
  const [state, dispatch] = useContext(SMContext);
  const [slidePwm, setSlidePwm] = useState(100);
  const [type, setType] = useState({});
  const [releaseButton, setReleaseButton] = useState(10);
  const [shortDischargeCount, setShortDischargeCount] = useState(0);
  const [timeoutId1, setTimeoutId1] = useState(null);
  const [timeoutId2, setTimeoutId2] = useState(null);
  const [dischargeCountIntervalId, setDischargeCountIntervalId] = useState(null);
  const {
    activeSerial,
    lightObjAll,
    userObj,
    gatewayObjAll,
    daliCtlObjAll,
    timeTableArrAll,
  } = state;
  const lightObj = lightObjAll?.[activeSerial];
  const batTestTime = useMemo(() => {
    return batTestTimeInfo(timeTableArrAll, activeSerial);
  }, [timeTableArrAll, activeSerial]);
  const {
    lockLevel,
    zigbeeConnected,
    daliCtlID,
    shortAdd,
    gatewayID,
    lightLv,
    current,
    onoffstatusL,
    shortDischargeSec = 10
  } = lightObj || {};
  useEffect(() => {
    setSlidePwm(lightObj?.lightLv);
    setType(lightObj?.type);
  }, [lightObj]);
  const daliCtlObj = daliCtlObjAll[daliCtlID];
  const lightOffBtnColor = lightLv === 0 ? "error" : "primary";
  const lightOnBtnColor = lightLv > 0 ? "error" : "primary";
  const cutPowerBtnColor = current > 0 ? "error" : "primary";
  const resumePowerBtnColor = current <= 0 ? "error" : "primary";
  const gatewayConnected = gatewayObjAll[gatewayID]?.gatewayConnected;
  const lightConnected = deviceConnected(zigbeeConnected, lightObj?.timeStamp, gatewayConnected);
  let pwmMarks = [];
  const startMark = daliCtlID ? 0 : 10;
  for (let i = startMark; i <= 100; i += 10) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  const pwmDisable = onoffstatusL === 1 ? true : false;
  async function handleSwitchLight(command, pwm) {
    const lockLv = command === 0 ? 1 : 0;
    setSlidePwm(pwm);
    if (!daliCtlID) {
      const gatewayObj = gatewayObjAll[gatewayID];
      await switchLight(activeSerial, lightObj, command, pwm, lockLv, gatewayObj);
    } else {
      if (command === 1) setSlidePwm(0);
      await switchLightDali(activeSerial, command, pwm, shortAdd, daliCtlObj);
    }
  }
  // async function handleSetPower(command) {
  //   await setPower(activeSerial, lightObj, command);
  // }
  const handleShortDischarge = async () => {
    await setPower(activeSerial, lightObj, 1);
    setTimeout(() => {
      setPower(activeSerial, lightObj, 1);
    }, 1000);
    setShortDischargeCount(1);
    const tv = setInterval(() => {
      setShortDischargeCount(t => t + 1)
    }, 1000)
    const tid1 = setTimeout(() => {
      setPower(activeSerial, lightObj, 0);
      clearInterval(tv);
    }, shortDischargeSec * 1000);
    const tid2 = setTimeout(() => {
      setPower(activeSerial, lightObj, 0);
      clearInterval(tv);
      setShortDischargeCount(0);
    }, (shortDischargeSec + 1) * 1000)

    setTimeoutId1(tid1);
    setTimeoutId2(tid2);
    setDischargeCountIntervalId(tv);
  }
  const handleStopDischarge = () => {
    setPower(activeSerial, lightObj, 0);
    clearTimeout(timeoutId1);
    clearTimeout(timeoutId2);
    clearInterval(dischargeCountIntervalId);
    setShortDischargeCount(0);
  }
  return (
    <div
      style={{
        height: "90%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <ThemeContainer background="dark">
        <DivInline>
          <div
            onClick={() => {
              setReleaseButton((b) => b + 1);
              console.log(releaseButton);
            }}
          >
            <LightIcon
              lightObj={lightObj}
              daliCtlObj={daliCtlObj}
              width={8}
              sizeUnit="vw"
              gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
            />
          </div>
        </DivInline>
        <DivExist show={daliCtlID && !daliCtlObj?.powerOnOffStatus}>
          <Typography color="secondary">Dali master cut off light power</Typography>
        </DivExist>
        <DivExist show={type?.A}>
          <DivInline style={{ height: "4vh" }}>
            <Typography>
              Priority level: {lockLevel === 100 ? 5 : lockLevel}
            </Typography>
            <IconButtonRefresh
              tooltip="Check light priority level"
              onBtnClick={() => {
                if (daliCtlID) {
                  queryDaliCtlOneLightSetting(daliCtlObjAll[daliCtlID], shortAdd, 0x0b05);
                } else {
                  queryOneLightSetting(lightObj, 0x0301);
                }

              }}
            />
            <DivExist show={lockLevel < 5}>
              <Tooltip title="Unlock light to lowest priority (5)">
                <IconButton
                  onClick={async () => {
                    if (daliCtlID) {
                      await changeDaliCtlOneSetting(daliCtlObjAll[daliCtlID], 0x0a0d, { lockLevel: 5 })
                    } else {
                      await changeOneLightSetting(lightObj, 0x020c, { lockLevel: 5 });
                    }
                  }}
                >
                  <LockOpen />
                </IconButton>
              </Tooltip>
            </DivExist>
          </DivInline>
        </DivExist>
        <DivExist show={type?.A}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Button
              style={{ width: "40%", height: "10vh" }}
              onClick={() => handleSwitchLight(0, 100)}
              variant="outlined"
              color={lightOnBtnColor}
              disabled={!zigbeeConnected && releaseButton < 5}
              size="large"
            >
              ON
            </Button>

            <Button
              style={{ width: "40%", height: "10vh" }}
              onClick={() => handleSwitchLight(1, 0)}
              color={lightOffBtnColor}
              variant="outlined"
              disabled={!zigbeeConnected && releaseButton < 5}
              size="large"
            >
              OFF
            </Button>
          </div>
        </DivExist>
        <DivExist show={type?.pwm}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Typography style={{ color: pwmDisable ? "darkgrey" : "white" }}>
              Light level {slidePwm}%
            </Typography>
            <Slider
              style={{
                width: "70%",
                color: pwmDisable ? "darkgrey" : "",
              }}
              value={slidePwm || 0}
              onChange={(evt, newVal) => {
                setSlidePwm(newVal);
              }}
              onChangeCommitted={(e, newVal) => {
                const cmd = newVal > 0 ? 0 : 1;
                handleSwitchLight(cmd, newVal);
              }}
              aria-labelledby="continuous-slider"
              marks={pwmMarks}
              max={100}
              min={daliCtlID ? 0 : 10}
              step={10}
              disabled={(!zigbeeConnected || pwmDisable) && releaseButton < 5}
            />
          </div>
        </DivExist>
        <DivExist show={type?.L}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Button sx={{ width: "12vw", height: "10vh" }}
              onClick={handleShortDischarge}
              // color={cutPowerBtnColor}
              size="large"
              variant="outlined"
              disabled={shortDischargeCount > 0}
            >
              Short discharge {shortDischargeCount > 0 ? (shortDischargeSec - shortDischargeCount) + "sec" : ""}
            </Button>
            {/* <Button
              style={{ width: "40%", height: "10vh" }}
              onClick={() => handleSetPower(1)}
              color={cutPowerBtnColor}
              size="large"
              variant="outlined"
              disabled={!zigbeeConnected && releaseButton < 5}
            >
              Power Cut
            </Button>*/}
            <AdminContainer>
              <Button
                style={{ width: "10vw", height: "10vh" }}
                onClick={handleStopDischarge}
                // color={resumePowerBtnColor}
                size="large"
                variant="outlined"
              // disabled={!zigbeeConnected}
              >
                Stop discharge
              </Button>
            </AdminContainer>
          </div>
        </DivExist>
        <DivExist show={type?.batVI && userObj.level < 2}>
          <ManualCheckStartModal disableManualCheck={(!lightConnected || shortDischargeCount > 0) ? true : false} />
        </DivExist>
        <DivCol hide={!lightObj?.type?.batVI}>
          <DivExist show={batTestTime?.hasTestSchedule}>
            <Typography>{`Month test: ${batTestTime?.monthTestDescription}`}</Typography>
            <Typography>{`Annual test: ${batTestTime?.annualTestDescription}`}</Typography>
          </DivExist>
          <DivExist show={!batTestTime?.hasTestSchedule}>
            <Typography>Set up battery test schedule</Typography>
          </DivExist>
          {
            batTestTime?.hasTestSchedule ?
              <EditTestTimeTableModal
                serial={activeSerial}
                annualTimeTableObj={batTestTime?.annualTimeTableObj}
                monthTimeTableObj={batTestTime?.monthTimeTableObj}
              />

              :
              <Add12MonthBatTestModal serial={activeSerial} />
          }

        </DivCol>
      </ThemeContainer>
    </div>
  );
}
