import DivCol from "./DivCol";
import moment from "moment";
import { Typography, Tooltip } from "@mui/material";
import Divider10 from "./Divider10";
import { AlternateEmail } from "@mui/icons-material";
import DivInline from "./DivInline";
import ButtonSM from "./ButtonSM";
import DaliCtlIcon from "./DaliCtlIcon";
export default function CardDaliCtl({ daliCtlObj, handleSave, disableClick, iconSize = 7, iconSizeUnit = "vh" }) {
    const { daliCtlID, description, dtkAdd, wifiApMacAddress, createTimeString, timeStamp } = daliCtlObj || {};
    const defaultClick = () => { }
    return (
        <ButtonSM tooltip="Add to map" onClick={handleSave || defaultClick} disabled={disableClick}>
            <DivCol sx={{ outline: "1px solid #fff", padding: "5px", margin: "5px" }}>
                <Typography variant="body2">{`\u{01f194}${daliCtlID}`}</Typography>
                <DivInline>
                    <AlternateEmail sx={{ fontSize: "1rem" }} />
                    <Tooltip title="Address">
                        <Typography variant="caption">{dtkAdd || "-"}</Typography>
                    </Tooltip>
                </DivInline>
                <DaliCtlIcon
                    width={iconSize}
                    sizeUnit={iconSizeUnit}
                    disabledConnectStatus
                />
                <Typography variant="body2">{description || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Wifi Ap Mac</Typography>
                <Typography variant="body2">{wifiApMacAddress || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Create time</Typography>
                <Typography variant="body2">{createTimeString || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Last update time</Typography>
                <Typography variant="body2">{moment(timeStamp).format("YYYY-MM-DD HH:mm:ss") || "-"}</Typography>
            </DivCol>
        </ButtonSM>
    )
}