import { useContext, useState } from "react";
import { Button, TextField } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import ModalSM from "components/ModalSM";
import Divider10 from "components/Divider10";
import { mgUpdateOneUpsert } from "actions/mongoApiActions";

export default function ChangePasswordModal({ open, setOpen }) {
    const [state, dispatch] = useContext(SMContext);
    const { userObj, serverID, socket } = state;
    const [newPw, setNewPw] = useState("");
    const [confirmPw, setConfirmPw] = useState("");
    const { email, uid, level } = userObj;
    const handleChange = async () => {
        if (newPw.length < 6) {
            alert("Password must be at least 6 characters");
            return;
        }
        if (newPw !== confirmPw) {
            alert("Passwords do not match");
            return;
        }
        const res = await mgUpdateOneUpsert("user", { email: email }, { password: newPw });
        if (res.result === "fail") {
            alert("Update password to database fail");
            return;
        }
        alert("Password updated");
        setOpen(false);
    }
    return (
        <>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle="Change Password"
                width="60vw"
                height="60vh"
            >

                <SpaceBetweenDiv title="User" data={email} />
                <SpaceBetweenDiv title="uid" data={uid} />
                <SpaceBetweenDiv title="Level" data={level} />
                <Divider10 />
                <TextField value={newPw} onChange={(e) => setNewPw(e.target.value)} label="New Password" variant="outlined" fullWidth />
                <Divider10 />
                <TextField value={confirmPw} onChange={(e) => setConfirmPw(e.target.value)} label="Confirm Password" variant="outlined" fullWidth />
                <Divider10 />
                <Button variant="contained" color="primary" onClick={handleChange}>Change Password</Button>
                <Divider10 />


            </ModalSM>
        </>
    );
}