import { useState, useRef, useEffect } from "react";
import { Group, Rect, Text } from "react-konva";
import KonvaLabel from "./KonvaLabel";

export default function DeviceUnknown({
  layerProps,
  x,
  y,
  deviceID,
  deviceType,
  text,
}) {
  const [scale, setScale] = useState(1);
  const [toRender, setToRender] = useState(true);
  const { iconSize, mapScale, dispatch, selectedID, layerRef, mapSize, layerScale, layerPos } = layerProps;
  const deviceRef = useRef(null);
  useEffect(() => {
    if (deviceRef.current) {
      const p = deviceRef.current.absolutePosition();
      if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
      else setToRender(true);
    }
  }, [layerScale, layerPos]);
  const handleClickUnknownGateway = () => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
    dispatch({
      type: "DEVICE_SELECTED",
      payload: {
        deviceID,
        deviceType,
        deviceObj: {},
        mapRightMode: deviceType,
        x,
        y,
        layerScale,
      },
    });
  };
  return (
    <Group x={x} y={y} draggable={false} ref={deviceRef} >
      {
        toRender &&
        <>
          <Rect
            width={iconSize * mapScale.x * 1.3}
            height={iconSize * mapScale.y * 0.9}
            fill="#FDEDEC"
            stroke={selectedID === deviceID ? "red" : ""}
            strokeWidth={2 / scale}
            perfectDrawEnabled={false}
          />
          <Text
            text={`${text}?`}
            onClick={handleClickUnknownGateway}
            fontSize={iconSize * mapScale.x * 0.9}
            perfectDrawEnabled={false}
          />
        </>
      }
    </Group>
  );
}
