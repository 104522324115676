import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import {
  Typography,
  Button,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
  Box,
} from "@mui/material";
import { Settings, Delete, CompareArrows, Edit, Done, Map } from "@mui/icons-material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivSpaceBetween from "components/DivSpaceBetween";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import Tube from "asset/svgComp/Tube";
import { useNavigate } from "react-router-dom";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import ModalSM from "components/ModalSM";
import { mapAddDevice, mapRemoveDevice, removeMap, updateMapProperty } from "actions/mapActions";
import DivCol from "components/DivCol";
import DivExist from "components/DivExist";
import DeviceMongoModal from "components/DeviceMongoModal";
import {
  alertWindow,
  confirmWindow,
  openSnackbar,
  promptWindow,
} from "actions/screenActions";
import { mgFindByMatch, mgUpdateOneUpsert } from "actions/mongoApiActions";
import UploadImageModal from "./UploadImageModal";
import AdminContainer from "components/AdminContainer";
import MapTopBtn from "containers/home/MapTopBtn";
import { saveAs } from "file-saver";
import JsonModalContainer from "components/JsonModalContainer";
import General from "@ecoenghk/general";
import { deviceInfoOfObj } from "actions/generalActions";
import DeviceIcon from "components/DeviceIcon";
const gs = new General();

export default function MapSettingModal({ btnSize }) {
  const [state, dispatch] = useContext(SMContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const {
    activeMapID,
    mapObjAll,
    userObj,
    lightObjAll,
    gatewayObjAll,
    sensorObjAll,
    daliCtlObjAll,
    zoneControlObjAll,
  } = state;
  const { email, level } = userObj;
  const activeMapObj = mapObjAll[activeMapID] || {};
  const {
    lightObj,
    gatewayObj,
    sensorObj,
    daliCtlObj,
    zoneControlObj,
    mapBgNotUploaded,
    disableAlert,
    mapName,
    mapSizeInM,
    iconSize,
    iconBackground,
    buttonSize,
    mapFileName,
    serverID,
  } = activeMapObj;
  const localMapUrl = `${global.ip}/img/${mapFileName || "samplePlan.jpg"}`;

  const sw = window.innerWidth;
  const scaleX = (sw / 1000) * 0.8;
  const handleClose = () => {
    setOpen(false);
  };
  const handleRemoveMap = async () => {
    confirmWindow(
      dispatch,
      `Confirm to remove map [${mapName}] from ${email}`,
      async () => {
        await removeMap(userObj, activeMapObj);
        handleClose();
        navigate("/HomePage");
      }
    );
  };
  const handleTransferMap = async (otherUserEmail) => {
    if (otherUserEmail === email || !otherUserEmail) {
      alert("Invalid email");
    } else {
      const arr = await mgFindByMatch("user", "email", otherUserEmail);
      console.log(arr);
      if (arr?.length > 0) {
        const otherUserObj = arr[0];
        const otherUid = otherUserObj.uid;
        let newInUser = [...activeMapObj.inUser];
        if (!newInUser.includes(otherUid)) newInUser.push(otherUid);
        await mgUpdateOneUpsert(
          "map",
          { mapID: activeMapID },
          { inUser: newInUser }
        );
        let otherUserMapArray = otherUserObj.mapArray || [];
        if (!otherUserMapArray.includes(activeMapID)) otherUserMapArray.push(activeMapID);
        await mgUpdateOneUpsert("user", { uid: otherUid }, { mapArray: otherUserMapArray });
        openSnackbar(
          dispatch,
          `${mapName} transferred to ${otherUserEmail}`,
          "info"
        );
      } else {
        alertWindow(dispatch, `${otherUserEmail} not exist in database`);
      }
    }
  };
  const handleUpdateMapProperty = async (field, value) => {
    const updateObj = { [field]: value };
    await updateMapProperty(activeMapID, updateObj);
  };
  const mapLightArrLength = lightObj ? Object.keys(lightObj).length : 0;
  const mapSensorArrLength = sensorObj ? Object.keys(sensorObj).length : 0;
  const mapGatewayArrLength = gatewayObj ? Object.keys(gatewayObj).length : 0;
  const mapDaliCtlArrLength = daliCtlObj ? Object.keys(daliCtlObj).length : 0;
  const mapZoneControlArrLength = zoneControlObj ? Object.keys(zoneControlObj).length : 0;
  // const lightAllArrLength = lightObjAll ? Object.keys(lightObjAll).length : 0;
  // const sensorAllArrLength = sensorObjAll
  //   ? Object.keys(sensorObjAll).length
  //   : 0;
  // const gatewayAllArrLength = gatewayObjAll
  //   ? Object.keys(gatewayObjAll).length
  //   : 0;
  // const daliCtlAllArrLength = daliCtlObjAll
  //   ? Object.keys(daliCtlObjAll).length
  //   : 0;
  const handleTransferMapAndDeviceToCloud = async () => {
    //   confirmWindow(dispatch, "Transfer map and all devices data to cloud server?", async () => {


    //   });
  }
  return (
    <>
      <MapTopBtn
        tooltip="Map Setting"
        btnSize={btnSize}
        btnClick={() => {
          setOpen(true);
        }}
      >
        <Settings />
      </MapTopBtn>

      <ModalSM
        modalTitle={`Map Setting - [${mapName}]`}
        modalIcon={
          <DeviceMongoModal deviceObj={activeMapObj}>
            <Map />
          </DeviceMongoModal>}
        open={open}
        onClose={handleClose}
        height="98vh"
        width="95vw"
        disableBottomClose

      >
        <DivCol justifyContent="center">
          <img src={localMapUrl} alt="" style={{ width: "20vw" }} />
          <Typography variant="caption">{mapFileName}</Typography>
        </DivCol>
        <SpaceBetweenDiv title="Map ID" data={activeMapID} />
        <SpaceBetweenDialogInput
          title="Map name"
          dialogTitle="Map name"
          data={mapName}
          handleSave={(val) => handleUpdateMapProperty("mapName", val)}
        />
        <SpaceBetweenDiv title="Map server ID" data={serverID} />
        <Divider10 />

        <AdminContainer>
          <SpaceBetweenDiv
            title="Map size"
            data={
              <MapSizeModal
                mapSize={mapSizeInM}
                handleSave={(s) => handleUpdateMapProperty("mapSizeInM", s)}
              />
            }
          />
        </AdminContainer>
        <SpaceBetweenDiv
          title="Icon size"
          data={
            <IconSizeModal
              iconSize={iconSize}
              scaleX={scaleX}
              handleSave={(s) =>
                handleUpdateMapProperty("iconSize", s, "number")
              }
            />
          }
        />
        <SpaceBetweenDiv
          title="Icon background color"
          data={
            <DivInline>
              <input
                type="color"
                value={iconBackground || "#000000"}
                onChange={(e) => {
                  handleUpdateMapProperty("iconBackground", e.target.value);
                }}
              />
              <Typography sx={{ margin: "1vw" }}>or</Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => {
                  handleUpdateMapProperty("iconBackground", "");
                }}
              >
                Transparent
              </Button>
            </DivInline>
          }
        />
        <SpaceBetweenDiv
          title="Map button size"
          data={
            <RadioGroup
              row
              value={buttonSize}
              onChange={(e) => {
                const newSize = e.target.value;
                handleUpdateMapProperty("buttonSize", newSize, "number");
              }}
            >
              <ButtonSizeRadio size={16} name="S" />
              <ButtonSizeRadio size={20} name="M" />
              <ButtonSizeRadio size={24} name="L" />
              <ButtonSizeRadio size={30} name="XL" />
              <ButtonSizeRadio size={36} name="XXL" />
            </RadioGroup>
          }
        />
        {mapBgNotUploaded && (
          <Typography align="right" color="secondary">
            Please upload your map image
          </Typography>
        )}
        <SpaceBetweenDiv
          title="Upload map image(JPEG format)"
          data={<UploadImageModal />}
        />
        <SpaceBetweenDiv
          title="Download map image"
          data={
            <Button variant="outlined"
              onClick={async () => {
                let blob = await fetch(localMapUrl).then(r => r.blob());
                saveAs(blob, `${activeMapID}.jpg`);
              }}>download</Button>
          }
          hideComponent={!localMapUrl}
        />
        <AdminContainer>
          <SpaceBetweenCheckbox
            title="Error alert signage / message"
            data={!disableAlert}
            onCheck={(evt) => {
              console.log("disableAlert", !evt.target.checked);
              handleUpdateMapProperty("disableAlert", !evt.target.checked);
            }}
          />
        </AdminContainer>
        <DivSpaceBetween>
          <Typography>Transfer map to other user</Typography>
          <Button
            onClick={async () => {
              promptWindow(
                dispatch,
                "Email of user to receive this map?",
                async (em) => {
                  await handleTransferMap(em);
                }
              );
            }}
          >
            <CompareArrows />
          </Button>
        </DivSpaceBetween>
        <DivSpaceBetween>
          <Typography>Remove map from user</Typography>
          <Button onClick={handleRemoveMap}>
            <Delete style={{ color: "red" }} />
          </Button>
        </DivSpaceBetween>

        <Divider10 />
        <DivInline justifyContent="space-around">
          <div>
            <Typography variant="caption" align="center">Number of Lights</Typography>
            <Typography align="center" variant="h2">{mapLightArrLength}</Typography>
            <AdminContainer>
              <MapDeviceNosModal mapObj={activeMapObj} deviceType="light" mapDeviceObj={lightObj} objAll={lightObjAll} />
            </AdminContainer>
          </div>
          <div>
            <Typography variant="caption" align="center">Number of Gateways</Typography>
            <Typography align="center" variant="h2">{mapGatewayArrLength}</Typography>
            <AdminContainer>
              <MapDeviceNosModal mapObj={activeMapObj} deviceType="gateway" mapDeviceObj={gatewayObj} objAll={gatewayObjAll} />
            </AdminContainer>
          </div>
          <div>
            <Typography variant="caption" align="center">
              Number of Sensors
            </Typography>
            <Typography align="center" variant="h2">
              {mapSensorArrLength}
            </Typography>
            <AdminContainer>
              <MapDeviceNosModal mapObj={activeMapObj} deviceType="sensor" mapDeviceObj={sensorObj} objAll={sensorObjAll} />
            </AdminContainer>
          </div>
          <div>
            <Typography variant="caption" align="center">
              Number of Dali Controllers
            </Typography>
            <Typography align="center" variant="h2">
              {mapDaliCtlArrLength}
            </Typography>
            <AdminContainer>
              <MapDeviceNosModal mapObj={activeMapObj} deviceType="daliCtl" mapDeviceObj={daliCtlObj} objAll={daliCtlObjAll} />
            </AdminContainer>
          </div>
          <div>
            <Typography variant="caption" align="center">Number of ZoneControls</Typography>
            <Typography align="center" variant="h2">{mapZoneControlArrLength}</Typography>
            <AdminContainer>
              <MapDeviceNosModal mapObj={activeMapObj} deviceType="zoneControl" mapDeviceObj={zoneControlObj} objAll={zoneControlObjAll} />
            </AdminContainer>
          </div>
        </DivInline>
        <Divider10 />
      </ModalSM>
    </>
  );
}

function IconSizeModal({ iconSize, scaleX, handleSave }) {
  const [open, setOpen] = useState(false);
  const [s, setS] = useState(0);
  return (
    <>
      <DivInline>
        <Typography>{iconSize}</Typography>
        <Tube width={iconSize * scaleX} sizeUnit="px" disabledConnectStatus disabledStatus />
        <IconButton
          onClick={() => {
            setOpen(true);
            setS(iconSize);
          }}
        >
          <Edit style={{ fontSize: "large", color: "grey" }} />
        </IconButton>
      </DivInline>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle="Icon Size"
        onSave={() => {
          handleSave(s);
          setOpen(false);
        }}
      >
        <DivInline justifyContent="center">
          <Button variant="outlined" size="large" onClick={() => setS((size) => size + 2)}>+</Button>
          <Typography sx={{ margin: "2vw" }}>{s}</Typography>
          <Button variant="outlined" size="large" onClick={() => setS((size) => size - 2)}>-</Button>

        </DivInline>
        <Divider10 />
        <DivInline justifyContent="center">
          <Tube width={s * scaleX} sizeUnit="px" disabledConnectStatus disabledStatus />
        </DivInline>
      </ModalSM>
    </>
  );
}

function MapSizeModal({ mapSize, handleSave }) {
  const [open, setOpen] = useState(false);
  const [w, setW] = useState(0);
  const [h, setH] = useState(0);
  return (
    <>
      <DivInline>
        <Typography>
          {mapSize?.x}m x {mapSize?.y}m
        </Typography>
        <IconButton
          onClick={() => {
            setOpen(true);
            setW(mapSize.x);
            setH(mapSize.y);
          }}
        >
          <Edit style={{ fontSize: "large", color: "grey" }} />
        </IconButton>
      </DivInline>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle="Map Size"
        onSave={() => {
          const obj = { x: Number(w), y: Number(h) };
          handleSave(obj);
          setOpen(false);
        }}
      >
        <div>
          <SpaceBetweenInput
            title="Width"
            data={w}
            onInput={(evt) => setW(evt.target.value)}
            unit="m"
          />
          <SpaceBetweenInput
            title="Height"
            data={h}
            onInput={(evt) => setH(evt.target.value)}
            unit="m"
          />
        </div>
      </ModalSM>
    </>
  );
}

function ButtonSizeRadio({ size, name }) {
  return (
    <FormControlLabel
      value={size}
      control={<Radio />}
      label={
        <div style={{ display: "flex" }}>
          <div
            style={{
              width: size,
              height: size,
              background: "lightgrey",
              marginRight: 5,
            }}
          />
          <Typography>{name}</Typography>
        </div>
      }
    />
  );
}
function MapDeviceNosModal({ mapObj, deviceType, mapDeviceObj, objAll }) {
  const [open, setOpen] = useState(false);
  const { mapID } = mapObj || {};
  const objAllArr = Object.keys(objAll || {});
  const objAllLen = objAllArr.length;
  const mapObjArr = Object.keys(mapDeviceObj || {});
  const combine = [...objAllArr, ...mapObjArr];
  const arrAll = Array.from(new Set(combine));

  return (
    <>
      <Typography variant="caption" align="center" onClick={() => setOpen(true)} style={{ cursor: "pointer" }}>{`In ${deviceType}ObjAll:${objAllLen}`}</Typography>
      <ModalSM width="90vw" height="90vh" modalTitle={`${deviceType} numbers in map [${mapID}]`} open={open} onClose={() => setOpen(false)}>
        <DivInline>
          <Typography sx={{ width: "15vw" }}>ID</Typography>
          <JsonModalContainer jsonData={mapDeviceObj}>
            <Box sx={{ width: "15vw" }}>
              <Typography>{`In mapObj.${deviceType}Obj`}</Typography>
              <Typography variant="h6">{mapObjArr.length}</Typography>
            </Box>
          </JsonModalContainer>
          <Box sx={{ width: "15vw" }}>
            <Typography>{`In ${deviceType}ObjAll`}</Typography>
            <Typography variant="h6">{objAllLen}</Typography>
          </Box>
        </DivInline>
        <Divider10 />
        {
          arrAll.sort((a, b) => a.localeCompare(b)).map((s, key) => {
            const deviceObj = objAll[s];
            const deviceInfo = deviceInfoOfObj(deviceObj);
            return (
              <DivInline key={key}>
                <JsonModalContainer jsonData={deviceObj}>
                  <DivInline sx={{ width: "15vw" }}>
                    <DeviceIcon deviceType={deviceType} width={1.5} sizeUnit="vw" deviceStyle={deviceInfo.deviceStyle} />
                    <Typography>{s}</Typography>
                  </DivInline>
                </JsonModalContainer>
                <Typography sx={{ width: "15vw" }}>{mapObjArr.includes(s) ? <Done /> : ""}</Typography>
                <Typography sx={{ width: "15vw" }}>{objAllArr.includes(s) ? <Done /> : ""}</Typography>
                <Typography sx={{ width: "10vw" }}>{deviceInfo.deviceName}</Typography>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={async () => {
                    await mapRemoveDevice(mapID, s, deviceType, deviceObj);

                  }}>
                  Remove from map
                </Button>
                <Button variant="outlined" size="small"
                  onClick={() => {
                    const pos = mapObj[`${deviceType}Obj`]?.[s] || {};
                    mapAddDevice(mapID, s, deviceType, deviceObj, pos.x, pos.y)
                  }}>
                  Add to map
                </Button>
              </DivInline>
            )
          })
        }
      </ModalSM>
    </>
  )
}
