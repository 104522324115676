import { useState, useRef, useEffect } from "react";
import { Group, Rect, Circle, Text } from "react-konva";
import Gateway from "asset/mapComp/Gateway";
import KonvaLabel from "./KonvaLabel";
import {
  daliCtlColorDark,
  gatewayColorDark,
  lightColorDark,
  sensorColorDark,
} from "actions/generalActions";
import { konvaShrinkAndBack } from "actions/konvaActions";
import { updateDevicePos } from "actions/mapActions";
import ShiftSelectCircle from "./ShiftSelectCircle";
export default function DeviceGateway({
  layerProps,
  x,
  y,
  gatewayID,
  gatewayObj,
}) {
  const {
    activeMapID,
    mapObjAll,
    enablePan,
    iconSize,
    mapScale,
    dispatch,
    layerRef,
    selectedID,
    mapSize,
    socket,
    shiftSelectedArray,
    layerScale,
    layerPos
  } = layerProps;
  const [showID, setShowID] = useState(false);
  const [scale, setScale] = useState(1);
  const [shadEnabled, setShadEnabled] = useState(false);
  const [toRender, setToRender] = useState(true);
  // const [abPos, setAbPos] = useState(0);
  const deviceRef = useRef(null);
  const activeMapObj = mapObjAll[activeMapID];
  const selected = selectedID === gatewayID ? true : false;
  useEffect(() => {
    if (deviceRef.current) {
      const p = deviceRef.current.absolutePosition();
      // setAbPos(p);
      if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
      else setToRender(true);
    }
  }, [layerScale, layerPos]);
  const shiftSelected = (shiftSelectedArray || [])
    .map((obj) => obj.deviceID)
    .includes(gatewayID);
  const { gatewayConnected, description } = gatewayObj || {};
  const handleDragStart = () => {
    setShadEnabled(true);
  };
  const handleDragEnd = async (e) => {
    const unScaleX = e.target.x() / mapScale.x;
    const unScaleY = e.target.y() / mapScale.y;
    setShadEnabled(false);
    const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
    const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
    await updateDevicePos(
      socket,
      activeMapID,
      "gateway",
      gatewayID,
      newX,
      newY
    );
  };
  const iconBackground = activeMapObj?.iconBackground || "";


  function handleClickDevice(e) {
    e.evt.preventDefault();
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);

    if (e.evt.shiftKey) {
      if (!shiftSelected) {
        dispatch({
          type: "SHIFT_SELECTED_DEVICE",
          payload: {
            deviceID: gatewayID,
            deviceType: "gateway",
            deviceObj: gatewayObj,
          },
        });
      } else {
        dispatch({
          type: "SHIFT_UNSELECTED_DEVICE",
          payload: {
            deviceID: gatewayID,
          },
        });
      }
    } else {
      dispatch({
        type: "DEVICE_SELECTED",
        payload: {
          deviceID: gatewayID,
          deviceType: "gateway",
          deviceObj: gatewayObj,
          mapRightMode: "gateway",
          x,
          y,
          layerScale,
        },
      });
    }
    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  }
  return (
    <Group
      x={x}
      y={y}
      draggable={!enablePan}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = enablePan ? "pointer" : "move";
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = enablePan ? "grab" : "default";
      }}
    >
      <Group ref={deviceRef}>
        {
          toRender &&
          <>
            <ShiftSelectCircle
              shiftSelected={shiftSelected}
              iconSize={iconSize}
              scale={scale}
            />
            <Gateway
              size={
                shadEnabled ? iconSize * mapScale.x * 1.2 : iconSize * mapScale.x
              }
              connectStatus={gatewayConnected}
              shadEnabled={shadEnabled}
              outline={selected}
            />
            <Rect
              width={iconSize * mapScale.x}
              height={iconSize * mapScale.y}
              onClick={handleClickDevice}
              onTap={handleClickDevice}
              onMouseOver={() => {
                const layer = layerRef.current;
                const layerScale = layer.scaleX();
                setScale(layerScale);
                setShowID(true);
              }}
              onMouseLeave={() => {
                setShowID(false);
              }}
            />
            {/* <Text text={`${abPos.x?.toFixed(0)} / ${mapSize.x}`} fill="blue" x={0} y={50} />
            <Text text={`${abPos.y?.toFixed(0)} / ${mapSize.y}`} fill="blue" x={0} y={60} /> */}
          </>
        }

      </Group>

      {showID && !shadEnabled && (
        <KonvaLabel
          x={0}
          y={iconSize * mapScale.y}
          text={description}
          fontSize={12}
          tagColor={gatewayColorDark}
          scale={scale}
        />
      )}
    </Group>
  );
}
