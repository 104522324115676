import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Tooltip, LinearProgress } from "@mui/material";
import { SyncDisabled } from "@mui/icons-material";
import GearChecking from "asset/svgComp/GearChecking";
import LightIcon from "components/LightIcon";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import TestViewModal from "../testReport/TestViewModal";
import { confirmWindow, openSnackbar } from "actions/screenActions";
import { fetchOneLight, terminateTest_A7 } from "actions/lightActions";
import { mgUpdateOneUpsert } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

export default function TestInProgressPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll, activeSerial, gatewayObjAll } = state;
  const lightObj = lightObjAll[activeSerial];
  const { reportObj, result, progress, gatewayID, voltage, current } = lightObj || {};
  const { reportID } = reportObj || {};

  const handleTerminateTest = async () => {
    await terminateTest_A7(activeSerial, lightObj);
    openSnackbar(dispatch, `Test ${reportID} terminated`);

    const newReportObj = {
      ...lightObj?.reportObj,
      result: "FAIL",
      resultData: {
        faultArray: ["E17"],
        newResult: "FAIL",
        resultComment: ["E17"],
      },
    };
    await mgUpdateOneUpsert("report", { reportID: reportID }, newReportObj);
    await mgUpdateOneUpsert(
      "serial",
      { serial: activeSerial },
      { reportObj: newReportObj }
    );
    await gs.waitFor(1000);
    await fetchOneLight(dispatch, activeSerial);
  };
  return (
    <div
      style={{
        height: "90%",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <LightIcon
        width={7}
        sizeUnit="vw"
        lightObj={lightObj}
        gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
      />
      <Typography>Report ID {reportID}</Typography>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GearChecking width={5} sizeUnit="vw" />
        <Typography>Under battery testing...{progress}%</Typography>
      </div>
      <LinearProgress
        variant="determinate"
        value={progress || 0}
        color="secondary"
      />
      <Divider10 />
      {/* <Typography display="block">Test type: {reportType}</Typography> */}
      {/* <Typography>Test no.: {no}</Typography> */}
      {/* <Typography>Start time:{schedule_timeString}</Typography> */}
      <Typography>Status: {result}</Typography>
      <Divider10 />
      <DivInline>
        <TestViewModal reportID={reportID} />
        <Tooltip title="Terminate test">
          <Button
            variant="outlined"
            onClick={async () => {
              confirmWindow(
                dispatch,
                "Confirm to terminate test?",
                async () => {
                  await handleTerminateTest();
                }
              );
            }}
          >
            <SyncDisabled />
          </Button>
        </Tooltip>
      </DivInline>
      {/* <Typography>Voltage: {voltage}</Typography>
      <Typography>Current: {current}</Typography>
      <Typography>ReportObj: V:{reportObj?.voltage} I:{reportObj?.current}</Typography>
      <Typography>progress:{reportObj?.progress} result:{reportObj?.result}</Typography> */}
    </div>
  );
}
