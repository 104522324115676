import { Group, Path, Rect, Ellipse, Shape } from "react-konva";
// import { lightColorDark } from "actions/generalActions";

export default function AcRemote({
  x,
  y,
  size,
  shadEnabled,
  // connectStatus,
  // outline,
}) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      {/* <Rect
        x={-10}
        y={-10}
        width={100}
        height={100}
        fill="#fff"
        opacity={0.5}
        cornerRadius={5}
        stroke={outline ? lightColorDark : ""}
      /> */}
      {/* <Circle radius={7} fill={connectStatus ? "green" : "red"} /> */}
      <Rect
        width={58}
        height={76}
        x={11}
        y={2}
        stroke="purple"
        strokeWidth={2.117}
        cornerRadius={4}
        fill="#fff"
        shadowEnabled={shadEnabled}
      />
      <Rect
        width={45.849}
        height={32.115}
        x={17.114}
        y={7.184}
        stroke="purple"
        strokeWidth={2.117}
        cornerRadius={2}
        fill="lightgrey"
      />

      <Shape
        stroke="#000"
        strokeWidth={2.1}
        y={42}
        x={20}
        sceneFunc={(context, shape) => {
          context.beginPath();
          context.moveTo(0, 15);
          context.lineTo(15, 6);
          context.lineTo(15, 24);
          context.closePath();
          context.fillStrokeShape(shape);
        }}
        fill="grey"
      />
      <Shape
        stroke="#000"
        strokeWidth={2.1}
        fill="grey"
        y={42}
        x={45}
        sceneFunc={(context, shape) => {
          context.beginPath();
          context.moveTo(15, 15);
          context.lineTo(0, 6);
          context.lineTo(0, 24);
          context.closePath();
          context.fillStrokeShape(shape);
        }}
      />
      <Ellipse
        x={29.342}
        y={15.039}
        stroke="maroon"
        strokeWidth={2.117}
        radiusX={3.841}
        radiusY={3.76}
      ></Ellipse>
      <Path
        y={-10}
        stroke="#ff2a2a"
        strokeWidth={2.117}
        data="M52.992 31.091a9.072 9.645 0 01-12.431-.77 9.072 9.645 0 010-13.237 9.072 9.645 0 0112.431-.77"
      ></Path>
    </Group>
  );
}
