import { Ellipse, Group, Path } from "react-konva";

export default function WarningSign({ x, y, size, shadEnabled }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill="#fc0"
        stroke="#1a1a1a"
        strokeWidth={4}
        data="M32.345 8.045C19.014 23.4 3.989 62.056 3.989 62.056c-.433 1.82-.873 3.635-.386 5.98.46 1.805 1.32 3.108 2.315 4.244 1.28 1.624 3.181 2.537 5.401 3.086l56.711-.193c1.512-.448 2.94-1.064 4.05-2.314 1.742-1.277 2.55-3.07 3.087-5.016.258-1.35.34-3.05.193-5.208-8.074-19.239-16.813-40.696-28.934-55.361-1.098-1.24-1.96-1.878-3.463-2.66-1.644-.855-3.539-.902-6.067.152-2.102.877-3.325 1.867-4.551 3.28z"
      />
      <Ellipse
        fill="#000"
        stroke="#000"
        strokeWidth={1}
        x={41}
        y={65}
        radiusX={7}
        radiusY={7}
      ></Ellipse>
      <Path
        fill="#000"
        scaleY={0.9}
        stroke="#000"
        strokeWidth={1}
        data="M34.092 19.389c-.365.74-.449 1.59-.502 2.407-.297 4.495.431 9.007 1.004 13.477.494 3.853 1.308 7.663 2.094 11.472.376 1.827.593 3.704 1.256 5.455.289.765.601 1.564 1.172 2.166.38.4.86.93 1.423.882.61-.052.963-.74 1.34-1.203.353-.434.625-.932.837-1.444.916-2.205 1.388-4.566 1.842-6.9.65-3.335 1.256-10.107 1.256-10.107l.67-8.183s.349-4.222.419-6.337c.014-.428.125-.873 0-1.284-.233-.762-.801-1.402-1.34-2.005-.63-.706-1.304-1.444-2.177-1.846-.74-.34-1.607-.379-2.428-.4-.73-.02-1.47.064-2.177.24-.848.21-1.725.462-2.428.963a7.22 7.22 0 00-2.261 2.647z"
      />
    </Group>
  );
}
