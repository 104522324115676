import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import { Info, Lock, QueryStats } from "@mui/icons-material";
import moment from "moment";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SystemStart from "asset/svgComp/SystemStart";
import TestStart from "asset/svgComp/TestStart";
import TestFinish from "asset/svgComp/TestFinish";
import DataUpload from "asset/svgComp/DataUpload";
import QuestionMark from "asset/svgComp/QuestionMark";
import LightBulb from "asset/svgComp/LightBulb";
import PowerPlug from "asset/svgComp/PowerPlug";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import Gateway from "asset/svgComp/Gateway";
import General from "@ecoenghk/general";
import TimeHistoryDataModal from "components/TimeHistoryDataModal";
import AdminContainer from "components/AdminContainer";

const gs = new General();

export function LightHistoryItem({ data, lightObj, iconOnly, messageOnly }) {
  const [state] = useContext(SMContext);
  const [openJson, setOpenJson] = useState(false);
  let up = data.uploadProtocol;
  let uploadProtocolStr = up.toString(16);
  if (typeof data.uploadProtocol === 'string') {
    up = parseInt(up, 16);
    uploadProtocolStr = data.uploadProtocol;
  }
  let timeImg = <QuestionMark />;
  let timeMessage = gs.dtkActionCmdToMsg(data);
  if ("senderMacAddress" in data) timeMessage += ` (esp)`;
  // timeMessage += ` ${uploadProtocolStr}`;
  //general command to light
  if (up >= 0x0201 && up <= 0x02ff) timeImg = <ZcGeneral />;
  if (up >= 0x0a01 && up <= 0x0aff) timeImg = <ZcGeneral />;
  //general query to light
  if (up >= 0x0301 && up <= 0x03bf) timeImg = <QueryStats sx={{ color: "#117A65" }} />;
  //general light report properties
  if (up >= 0x0501 && up <= 0x05ff) timeImg = <Info sx={{ color: "#2E86C1" }} />;
  if ((up >= 0x0c09 && up === 0x0cff) || up === 0x0c03) timeImg = <Info sx={{ color: "#2E86C1" }} />;
  switch (up) {
    case 0x0109:
      timeMessage = "Gateway acknowledged command";
      timeImg = <Gateway disabledConnectStatus />;
      break;
    case 0x0403:
      timeImg = <PowerPlug cutResume={data.onoffstatusL === 0 ? "resume" : "cut"} />
      break;
    case 0x0404:
      timeImg = <LightBulb onoff={data.onoffstatusA === 0 ? "on" : "off"} />;
      break;
    case 0x0406:
    case 0x040b:
      timeImg = <SystemStart />;
      break;
    case 0x0408:
      timeImg = <TestStart />;
      break;
    case 0x0409:
      timeImg = <DataUpload color="red" backgroundColor="#fff" />;
      break;
    case 0x040a:
      timeImg = <TestFinish />;
      break;
    case 0x0c06:
      timeImg = <LightBulb onoff={data.lightLv === 0 ? "off" : "on"} />;
      break;
    case 0x0c08:
      const lightLv = data.lightLvArray[lightObj.daliIndex];
      timeImg = <LightBulb onoff={lightLv === 0 ? "off" : "on"} />;
      timeMessage = `report light status - lv:${lightLv}%`;
      break;

    case 0x0c0b:
      timeImg = <Lock sx={{ color: "red" }} />;
      break;
    case 0x0c0c:
      timeImg = <Lock />;
      const lockLv = data.lockLvArray[lightObj.daliIndex];
      timeMessage = `report light lock level -${lockLv}`;
      break;
    case 0x0c0f:
      const minLv = data.minLvArray[lightObj.daliIndex];
      timeMessage = `report light min level -${minLv}`;
      break;
    case 0x0c10:
      const maxLv = data.maxLvArray[lightObj.daliIndex];
      timeMessage = `report light max level -${maxLv}`;
      break;
    case 0x0c17:
      const fadeTime = data.fadeTimeArray[lightObj.daliIndex];
      timeMessage = `report light fade time -${fadeTime}`;
      break;
  }

  const m = moment(data.timeStamp);
  const dataDate = m.format("YYYY-MM-DD");
  const dataTime = m.format("HH:mm:ss");
  if (iconOnly) return <div>{timeImg}</div>;
  else if (messageOnly) return <div>{timeMessage}</div>;
  else
    return (
      <>
        <TimelineItem onClick={() => {
          if (state.userObj.level < 1) setOpenJson(true)
        }}>
          <TimelineOppositeContent>
            <Typography variant="caption" color="textSecondary">
              {dataDate}
              <br />
              {dataTime}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>{timeImg}</TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="caption">{timeMessage}</Typography>
          </TimelineContent>
        </TimelineItem>
        <AdminContainer>
          <TimeHistoryDataModal open={openJson} json={data} handleClose={() => setOpenJson(false)} timeImg={timeImg} />
        </AdminContainer>
      </>
    );
}

