import { useState, useContext, useMemo, Fragment, useEffect } from "react";
import {
    Typography,
    Tooltip,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    IconButton,
} from "@mui/material";
import { SMContext } from "context/smContext";
import { ArrowDownward, ArrowUpward, CalendarMonth, ContentCopy, ContentPasteGo, CopyAll } from "@mui/icons-material";
import DivCol from "components/DivCol";
import Space10 from "components/Space10";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import IconButtonBack from "components/IconButtonBack";
import ThemeContainer from "components/ThemeContainer";
import { batTestTimeInfo, saveTestTimeTable, fetchTimeTableBySerial } from "actions/timetableActions";
import EditTestTimeTableModal from "./EditTestTimeTableModal";
import { useNavigate } from "react-router-dom";
import IconButtonClose from "components/IconButtonClose";
import General from "@ecoenghk/general";
import DivExist from "components/DivExist";
import { deviceConnected } from "actions/generalActions";
import Add12MonthBatTestModal from "./Add12MonthBatTestModal";
import { openSnackbar } from "actions/screenActions";
import { updateSerialProperty } from "actions/lightActions";
import numeral from "numeral";
import JsonModalContainer from "components/JsonModalContainer";
import IconButtonRefresh from "components/IconButtonRefresh";
const gs = new General();

export default function BatTestTimeTablePage() {
    const [state, dispatch] = useContext(SMContext);
    const navigate = useNavigate();
    const [sortBy, setSortBy] = useState("nextMonthTestTsp");
    const [sortOrder, setSortOrder] = useState("asc");
    const [copyLightScheduleObj, setCopyLightScheduleObj] = useState(null);
    const [copyMonthTimeTableObj, setCopyMonthTimeTableObj] = useState(null);
    const [copyAnnualTimeTableObj, setCopyAnnualTimeTableObj] = useState(null);
    const [copyBatteryInfo, setCopyBatteryInfo] = useState(null);
    const {
        activeMapID,
        lightObjAll,
        mapObjAll,
        timeTableArrAll,
        gatewayObjAll,
        userObj,
    } = state;
    const mapObj = mapObjAll[activeMapID];
    useEffect(() => {
        setCopyLightScheduleObj(null);
        setCopyMonthTimeTableObj(null);
        setCopyAnnualTimeTableObj(null);
    }, [])
    let lightObjArr = Object.keys(mapObj?.lightObj || {}).map((s) => {
        let lightObj = { ...lightObjAll[s] };
        if (lightObj?.type?.batVI) {
            const batTestTime = batTestTimeInfo(timeTableArrAll, s);
            lightObj.batTestTime = batTestTime;
            lightObj.nextMonthTestTsp = batTestTime?.nextMonthTestTsp;
            lightObj.nextAnnualTestTsp = batTestTime?.nextAnnualTestTsp;
            return lightObj;
        }
    });
    lightObjArr = lightObjArr.filter((obj) => obj);

    const handleClose = async () => {
        navigate("/MapPage");
    };
    function SortBtn({ title, sortByField }) {
        return (
            <DivInline>
                <Typography>{title}</Typography>
                <ArrowUpward
                    sx={{
                        "&:hover": { color: "red" },
                        color:
                            sortOrder === "asc" && sortByField === sortBy ? "orange" : "",
                    }}
                    onClick={() => {
                        setSortBy(sortByField);
                        setSortOrder("asc");
                    }}
                />
                <ArrowDownward
                    sx={{
                        "&:hover": { color: "red" },
                        color:
                            sortOrder === "desc" && sortByField === sortBy ? "orange" : "",
                    }}
                    onClick={() => {
                        setSortBy(sortByField);
                        setSortOrder("desc");
                    }}
                />
            </DivInline>
        );
    }
    const handlePasteSchedule = async (targetLightObj) => {
        const monthTimeTableObj = {
            reportType: "check_1month",
            serial: targetLightObj.serial,
            year: copyMonthTimeTableObj.year,
            month: copyMonthTimeTableObj.month,
            date: copyMonthTimeTableObj.date,
            hour: copyMonthTimeTableObj.hour,
            minute: copyMonthTimeTableObj.minute,
            effectiveAfterTsp: copyMonthTimeTableObj.effectiveAfterTsp,
        }
        const annualTimeTableObj = {
            reportType: "check_12month",
            serial: targetLightObj.serial,
            year: copyAnnualTimeTableObj.year,
            month: copyAnnualTimeTableObj.month,
            date: copyAnnualTimeTableObj.date,
            hour: copyAnnualTimeTableObj.hour,
            minute: copyAnnualTimeTableObj.minute,
            effectiveAfterTsp: copyAnnualTimeTableObj.effectiveAfterTsp,
        }
        await saveTestTimeTable(targetLightObj.serial, targetLightObj.batteryHour, monthTimeTableObj, annualTimeTableObj);
        await gs.waitFor(1000);
        const arr = await fetchTimeTableBySerial(targetLightObj.serial);
        if (arr.length > 0) {
            dispatch({
                type: "UPDATE_MULTI_TIMETABLE_OBJ",
                payload: {
                    timeTableObjArray: arr,
                },
            });
        }
        openSnackbar(dispatch, `Schedule pasted to ${targetLightObj.serial}`, "success");
    }
    const handlePasteBatteryInfo = async (targetLightObj) => {
        let updateObj = {
            batteryHour: copyBatteryInfo.batteryHour || 2,
            lightBatteryCapacity: copyBatteryInfo.lightBatteryCapacity || 1800,
            lightBatteryType: copyBatteryInfo.lightBatteryType || "nimh",
            lightModel: copyBatteryInfo.lightModel || "",
            lightPower: copyBatteryInfo.lightPower || 6,
            maxVRequired: copyBatteryInfo.maxVRequired || 18,
            minVRequired: copyBatteryInfo.minVRequired || 11,
            minIRequired: copyBatteryInfo.minIRequired || 0.15,
            nomBatI: copyBatteryInfo.nomBatI || 0.2,
            nomBatV: copyBatteryInfo.nomBatV || 12,
            reversePolarityI: copyBatteryInfo.reversePolarityI || false,
            shortDischargeSec: copyBatteryInfo.shortDischargeSec || 12,
            warningI: copyBatteryInfo.warningI || 0.16,
            warningV: copyBatteryInfo.warningV || 11.5,
        }
        await updateSerialProperty(targetLightObj.serial, targetLightObj, updateObj);
        dispatch({
            type: "UPDATE_LIGHT_OBJ",
            payload: { ...targetLightObj, ...updateObj },
        });
    }
    const handleRefreshTimeTable = async (serial) => {
        const arr = await fetchTimeTableBySerial(serial);
        if (arr.length > 0) {
            dispatch({ type: "UPDATE_MULTI_TIMETABLE_OBJ", payload: { timeTableObjArray: arr } });
        }
    }
    return (
        <ThemeContainer background="dark">
            <Paper>
                <DivInline>
                    <IconButtonBack onBtnClick={handleClose} />
                    <CalendarMonth />
                    <Typography>{`Light Test Time Table - ${mapObj?.mapName}`}</Typography>
                </DivInline>
                <Table size="small">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ width: "4vw" }}>
                                <SortBtn title="Type" sortByField="style" />
                            </TableCell>
                            <TableCell sx={{ width: "6vw" }}>
                                <SortBtn title="Serial no" sortByField="serial" />
                            </TableCell>
                            <TableCell sx={{ width: "15vw" }}>
                                <SortBtn title="Description" sortByField="description" />
                            </TableCell>
                            <TableCell sx={{ width: "5vw" }}> Copy/Paste battery info</TableCell>
                            <TableCell sx={{ width: "17vw" }}>
                                <SortBtn title="Monthly test" sortByField="nextMonthTestTsp" />
                            </TableCell>
                            <TableCell sx={{ width: "17vw" }}>
                                <SortBtn title="Annual test" sortByField="nextAnnualTestTsp" />
                            </TableCell>
                            <TableCell sx={{ width: "6vw" }}> Edit/Add schedule</TableCell>
                            <TableCell sx={{ width: "4vw" }}> Copy/Paste schedule</TableCell>

                        </TableRow>
                        {lightObjArr
                            .sort((a, b) => {
                                if (sortOrder === "asc")
                                    return String(a[sortBy]).localeCompare(String(b[sortBy]));
                                if (sortOrder === "desc")
                                    return String(b[sortBy]).localeCompare(String(a[sortBy]));
                            })
                            .map((lightObj, key) => {
                                const { zigbeeConnected, description, serial, timeStamp, gatewayID, batTestTime, style } = lightObj;
                                const gatewayObj = gatewayObjAll[gatewayID];
                                const gatewayConnected = gatewayObj?.gatewayConnected;
                                const connected = deviceConnected(zigbeeConnected, timeStamp, gatewayConnected)
                                const { monthTimeTableObj, annualTimeTableObj } = batTestTime;
                                const hasSchedule = monthTimeTableObj || annualTimeTableObj;
                                const lightScheduleCopied = copyLightScheduleObj?.serial === serial;
                                const batteryInfoCopied = copyBatteryInfo?.serial === serial;
                                const outline = lightScheduleCopied ? "1px solid orange" : batteryInfoCopied ? "1px solid lightgreen" : "";
                                const nomBatI = lightObj.nomBatI ? numeral(lightObj.nomBatI).format("0.00") : "-";
                                const nomBatV = lightObj.nomBatV ? numeral(lightObj.nomBatV).format("0.0") : "-";
                                const minIRequired = lightObj.minIRequired ? numeral(lightObj.minIRequired).format("0.00") : "-";
                                const minVRequired = lightObj.minVRequired ? numeral(lightObj.minVRequired).format("0.0") : "-";
                                return (
                                    <Fragment key={key}>
                                        <TableRow sx={{ outlined: "1px solid green" }}>
                                            <TableCell sx={{ outline }}>
                                                <LightIcon
                                                    lightStyle={style}
                                                    disabledStatus
                                                    width={2.2}
                                                    sizeUnit="vw"
                                                    connectStatus={connected}
                                                    typeBatVI={true}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ outline }}>
                                                <JsonModalContainer jsonData={lightObj}><Typography variant="caption">{serial}</Typography></JsonModalContainer>
                                            </TableCell>
                                            <TableCell sx={{ outline }} onClick={() => console.log(lightObjAll)}>
                                                <Typography variant="caption" display="block">{description}</Typography>
                                                <Typography variant="caption" display="block">{`${lightObj.lightBatteryCapacity || "-"} mAh ${lightObj.lightBatteryType || "-"} ${lightObj.batteryHour || "-"}Hr`}</Typography>
                                                <Typography variant="caption" display="block">{`Nominal ${nomBatV}V ${nomBatI}A`}</Typography>
                                                <Typography variant="caption" display="block">{`Pass ${minVRequired}V ${minIRequired}A`}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ outline }}>
                                                <DivExist show={!copyLightScheduleObj ? true : false}>
                                                    <DivExist show={!copyBatteryInfo}>
                                                        <Tooltip title="Copy battery info">
                                                            <IconButton onClick={() => setCopyBatteryInfo(lightObj)}>
                                                                <ContentCopy />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </DivExist>
                                                    <DivExist show={(copyBatteryInfo && !batteryInfoCopied) ? true : false}>
                                                        <Tooltip title={`Paste battery info of ${copyBatteryInfo?.serial}`}>
                                                            <IconButton onClick={() => handlePasteBatteryInfo(lightObj)}>
                                                                <ContentPasteGo />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </DivExist>
                                                    <DivExist show={copyBatteryInfo && batteryInfoCopied}>
                                                        <DivInline>
                                                            <Typography variant="body2" sx={{ color: "lightgreen" }}>Copied</Typography>
                                                            <IconButtonClose
                                                                tooltip="cancel copied"
                                                                color="warning"
                                                                onBtnClick={() => setCopyBatteryInfo(null)} />
                                                        </DivInline>
                                                    </DivExist>
                                                </DivExist>
                                            </TableCell>
                                            <TableCell sx={{ outline }}>
                                                <DivCol alignItems="flex-start">
                                                    <DivExist show={userObj.level === 0 && monthTimeTableObj}>
                                                        <Typography variant="caption" display="block">
                                                            timeTableID: {monthTimeTableObj?.timeTableID}
                                                        </Typography>
                                                    </DivExist>
                                                    <Typography >
                                                        {monthTimeTableObj && `Next monthly test: ${batTestTime?.nextMonthTestTsr}`}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {batTestTime?.monthTestDescription}
                                                    </Typography>

                                                </DivCol>
                                            </TableCell>
                                            <TableCell sx={{ outline }}>
                                                <DivCol alignItems="flex-start">
                                                    <DivExist show={userObj.level === 0 && annualTimeTableObj}>
                                                        <Typography variant="caption" display="block">
                                                            timeTableID: {annualTimeTableObj?.timeTableID}
                                                        </Typography>
                                                    </DivExist>
                                                    <Typography>
                                                        {annualTimeTableObj && `Next annual test: ${batTestTime?.nextAnnualTestTsr}`}
                                                    </Typography>
                                                    <Typography variant="caption">
                                                        {batTestTime?.annualTestDescription}
                                                    </Typography>

                                                </DivCol>
                                            </TableCell>
                                            <TableCell sx={{ outline }}>
                                                <DivExist show={!copyBatteryInfo}>
                                                    <DivInline hide={copyLightScheduleObj ? true : false}>
                                                        {
                                                            hasSchedule ?
                                                                <EditTestTimeTableModal
                                                                    serial={serial}
                                                                    monthTimeTableObj={monthTimeTableObj}
                                                                    annualTimeTableObj={annualTimeTableObj}
                                                                />
                                                                :
                                                                <Add12MonthBatTestModal serial={serial} />

                                                        }
                                                        {/* <IconButtonRefresh onBtnClick={() => handleRefreshTimeTable(serial)} /> */}
                                                    </DivInline>
                                                </DivExist>
                                            </TableCell>
                                            <TableCell sx={{ outline }}>
                                                <DivExist show={!copyBatteryInfo ? true : false}>
                                                    <DivExist show={copyLightScheduleObj && !lightScheduleCopied ? true : false}>
                                                        <Tooltip title={`Paste schedule of ${copyLightScheduleObj?.serial}`}>
                                                            <IconButton onClick={() => handlePasteSchedule(lightObj)}>
                                                                <ContentPasteGo />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </DivExist>
                                                    <DivExist show={hasSchedule && !copyLightScheduleObj}>
                                                        <Tooltip title="Copy schedule">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setCopyLightScheduleObj(lightObj);
                                                                    setCopyMonthTimeTableObj(monthTimeTableObj);
                                                                    setCopyAnnualTimeTableObj(annualTimeTableObj);
                                                                }}>
                                                                <ContentCopy />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </DivExist>
                                                    <DivExist show={lightScheduleCopied}>
                                                        <DivInline>
                                                            <Typography variant="body2" sx={{ color: "orange" }}>Copied</Typography>
                                                            <IconButtonClose
                                                                tooltip="cancel copied"
                                                                color="warning"
                                                                onBtnClick={() => {
                                                                    setCopyLightScheduleObj(null);
                                                                    setCopyMonthTimeTableObj(null);
                                                                    setCopyAnnualTimeTableObj(null);
                                                                }} />
                                                        </DivInline>
                                                    </DivExist>
                                                </DivExist>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}
                    </TableBody>
                </Table>
            </Paper>
        </ThemeContainer >
    )
}


