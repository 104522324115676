import { useContext } from "react";
import { SMContext } from "../context/smContext";
import { Typography } from "@mui/material";
import ModalSM from "./ModalSM";
import DivInline from "./DivInline";
import IconButtonClose from "./IconButtonClose";
import Divider10 from "./Divider10";

export default function WindowAlert() {
  const [state, dispatch] = useContext(SMContext);
  const { windowAlert, windowAlertTitle } = state;
  const handleClose = () => {
    dispatch({
      type: "WINDOW_ALERT",
      payload: { status: false, windowAlertTitle: "" },
    });
  };

  return (
    <ModalSM open={windowAlert} onClose={handleClose} disableBottomClose>
      <Typography sx={{ marginTop: 3 }} align="center">
        {windowAlertTitle}
      </Typography>
      <Divider10 />
      <DivInline justifyContent="center">
        <IconButtonClose onBtnClick={handleClose} />
      </DivInline>
    </ModalSM>
  );
}
