import DivCol from "./DivCol";
import IconButtonSave from "./IconButtonSave";
import LightIcon from "./LightIcon";
import moment from "moment";
import { Typography, Tooltip } from "@mui/material";
import DivExist from "./DivExist";
import Divider10 from "./Divider10";
import { AlternateEmail } from "@mui/icons-material";
import DivInline from "./DivInline";
import ButtonSM from "./ButtonSM";
export default function CardLight({ lightObj, handleSave, iconSize = 7, iconSizeUnit = "vh", disableClick = false, tooltip = "Add to map" }) {
    const { serial, description, dtkAdd, daliCtlID, wifiApMacAddress, createTimeString, timeStamp } = lightObj || {};
    const defaultClick = () => { }
    return (
        <ButtonSM tooltip={tooltip} onClick={handleSave || defaultClick} disabled={disableClick}>
            <DivCol sx={{ outline: "1px solid #fff", padding: "5px", margin: "5px" }}>
                <Typography variant="body2">{`\u{01f194}${serial}`}</Typography>
                <DivInline>
                    <AlternateEmail sx={{ fontSize: "1rem" }} />
                    <Tooltip title="Address">
                        <Typography variant="caption">{dtkAdd || "-"}</Typography>
                    </Tooltip>
                </DivInline>
                <LightIcon
                    lightStyle={lightObj?.style}
                    width={iconSize}
                    sizeUnit={iconSizeUnit}
                    disabledConnectStatus
                />
                <Typography variant="body2">{description || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Wifi Ap Mac</Typography>
                <Typography variant="body2">{wifiApMacAddress || "-"}</Typography>
                <Divider10 space={5} />
                <DivExist show={daliCtlID}>
                    <Typography variant="caption">Under Dali controller</Typography>
                    <Typography variant="subtitle1">{daliCtlID}</Typography>
                    <Divider10 space={5} />
                </DivExist>
                <Typography variant="caption">Create time</Typography>
                <Typography variant="body2">{createTimeString || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Last update time</Typography>
                <Typography variant="body2">{moment(timeStamp).format("YYYY-MM-DD HH:mm:ss") || "-"}</Typography>
            </DivCol>
        </ButtonSM>
    )
}
