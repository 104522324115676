import { useState, useRef, useEffect } from "react";
import { Group, Circle } from "react-konva";
import ZcDeviceSwitch from "./ZcDeviceSwitch";
import ZcDeviceTimer from "./ZcDeviceTimer";
// import ZcDeviceDim from "./ZcDeviceDim";
import MapIconZoneControl from "containers/map/device/MapIconZoneControl";
import KonvaLabel from "containers/map/device/KonvaLabel";
import {
  zoneControlColorDark,
  gatewayColorLight,
} from "actions/generalActions";
import { konvaShrinkAndBack } from "actions/konvaActions";
import { updateDevicePos } from "actions/mapActions";
import ZcDeviceSceneButton from "./ZcDeviceSceneButton";
import ShiftSelectCircle from "./ShiftSelectCircle";
const screenW = window.innerWidth;
const screenH = window.innerHeight;
export default function DeviceZoneControl({
  layerProps,
  x,
  y,
  zoneControlID,
  zoneControlObj,
}) {
  const [showID, setShowID] = useState(false);
  const [scale, setScale] = useState(1);
  const [shadEnabled, setShadEnabled] = useState(false);
  const [toRender, setToRender] = useState(true);
  const {
    mapScale,
    mapObjAll,
    activeMapID,
    selectedID,
    enablePan,
    dispatch,
    layerRef,
    iconSize,
    mapLayer,
    socket,
    shiftSelectedArray,
    mapSize,
    layerScale,
    layerPos
  } = layerProps;
  const deviceRef = useRef(null);
  useEffect(() => {
    if (deviceRef.current) {
      const p = deviceRef.current.absolutePosition();
      if (p.x < 0 || p.x > mapSize.x || p.y < 0 || p.y > mapSize.y) setToRender(false);
      else setToRender(true);
    }
  }, [layerScale, layerPos]);

  const activeMapObj = mapObjAll[activeMapID];

  const { zoneControlName, type } = zoneControlObj || {};
  const selected = selectedID === zoneControlID ? true : false;
  const shiftSelected = (shiftSelectedArray || [])
    .map((obj) => obj.deviceID)
    .includes(zoneControlID);
  const iconBackground = activeMapObj?.iconBackground || "";

  const handleDragStart = () => {
    setShadEnabled(true);
  };
  const handleDragEnd = async (e) => {
    const unScaleX = e.target.x() / mapScale.x;
    const unScaleY = e.target.y() / mapScale.y;
    setShadEnabled(false);
    const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
    const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
    await updateDevicePos(
      socket,
      activeMapID,
      "zoneControl",
      zoneControlID,
      newX,
      newY
    );
  };
  const handleShiftSelectDevice = (e) => {
    if (!shiftSelected) {
      e.evt.preventDefault();
      const layer = layerRef.current;
      const layerScale = layer.scaleX();
      setScale(layerScale);
      dispatch({
        type: "SHIFT_SELECTED_DEVICE",
        payload: {
          deviceID: zoneControlID,
          deviceType: "zoneControl",
          deviceObj: zoneControlObj,
        },
      });
    } else {
      dispatch({
        type: "SHIFT_UNSELECTED_DEVICE",
        payload: {
          deviceID: zoneControlID,
        },
      });
    }
    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  };
  const handleClickDevice = (e) => {
    e.evt.preventDefault();
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);

    dispatch({
      type: "DEVICE_SELECTED",
      payload: {
        deviceID: zoneControlID,
        deviceType: "zoneControl",
        deviceObj: zoneControlObj,
        mapRightMode: "zoneControl",
        x,
        y,
        layerScale,
      },
    });

    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  };

  const onMouseOver = (e) => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
    setShowID(true);
    const container = e.target.getStage().container();
    container.style.cursor = enablePan ? "pointer" : "move";
  };
  const onMouseLeave = (e) => {
    setShowID(false);
    const container = e.target.getStage().container();
    container.style.cursor = enablePan ? "grab" : "default";
  };
  const pw = screenW / 2 < x ? "right" : "left";
  const ph = screenH / 2 < y ? "bottom" : "top";
  const p = ph + pw;
  return (
    <>
      <Group
        x={x}
        y={y}
        draggable={!enablePan}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        ref={deviceRef}
      >
        {
          toRender &&
          <>
            {showID && !shadEnabled && (
              <KonvaLabel
                x={0}
                y={iconSize * mapScale.y}
                text={zoneControlName}
                fontSize={12}
                tagColor={zoneControlColorDark}
                scale={scale}
              />
            )}
            <ShiftSelectCircle
              shiftSelected={shiftSelected}
              iconSize={iconSize}
              scale={scale}
            />
            <Group>
              <MapIconZoneControl
                x={0}
                y={0}
                shadEnabled={shadEnabled}
                type={type}
                mapScale={mapScale}
                iconSize={iconSize}
                scale={scale}
                iconBackground={iconBackground}
                strokeColor={selected ? zoneControlColorDark : ""}
                zoneControlObj={zoneControlObj}
              />

            </Group>
            {type === "zcSwitch" && !mapLayer.gatewaySelect && (
              <ZcDeviceSwitch
                mapLocation={p}
                layerProps={layerProps}
                zoneControlObj={zoneControlObj}
                handleSelectDevice={handleClickDevice}
                handleShiftSelectDevice={handleShiftSelectDevice}
                selected={selected}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              />
            )}
            {type === "timer" && !mapLayer.gatewaySelect && (
              <ZcDeviceTimer
                mapLocation={p}
                layerProps={layerProps}
                zoneControlObj={zoneControlObj}
                handleSelectDevice={handleClickDevice}
                handleShiftSelectDevice={handleShiftSelectDevice}
                selected={selected}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              />
            )}

            {type === "zcSceneButton" && !mapLayer.gatewaySelect && (
              <ZcDeviceSceneButton
                x={x}
                y={y}
                layerProps={layerProps}
                zoneControlObj={zoneControlObj}
                handleSelectDevice={handleClickDevice}
                handleShiftSelectDevice={handleShiftSelectDevice}
                selected={selected}
                onMouseOver={onMouseOver}
                onMouseLeave={onMouseLeave}
              />
            )}
          </>
        }
      </Group>
    </>
  );
}
