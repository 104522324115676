import { useState, useRef } from "react";
import { Group, Rect, Line, Text } from "react-konva";
import KonvaLabel from "./KonvaLabel";
import DivExist from "components/DivExist";
import {
  sensorColorDark,
  gatewayColorLight,
  serverColorDark,
} from "actions/generalActions";
import WarningSign from "asset/mapComp/WarningSign";
import { konvaShrinkAndBack } from "actions/konvaActions";
import Server from "asset/mapComp/Server";
import { updateDevicePos } from "actions/mapActions";
import moment from "moment";

export default function DeviceServer({ layerProps, x, y }) {
  const {
    layerRef,
    activeMapID,
    mapScale,
    iconSize,
    enablePan,
    selectedID,
    dispatch,
    serverObj,
    socket,
  } = layerProps;
  const [showID, setShowID] = useState(false);
  const [scale, setScale] = useState(1);
  const [shadEnabled, setShadEnabled] = useState(false);
  const deviceRef = useRef(null);
  const { description, timeStamp, serverID } = serverObj || {};
  const selected = selectedID === serverID ? true : false;
  const connected =
    moment().diff(moment(timeStamp), "minutes") < 10 ? true : false;
  const handleDragStart = () => {
    setShadEnabled(true);
  };
  const handleDragEnd = async (e) => {
    e.evt.preventDefault();
    const unScaleX = e.target.x() / mapScale.x;
    const unScaleY = e.target.y() / mapScale.y;
    setShadEnabled(false);
    const newX = unScaleX > 1000 ? 970 : unScaleX < 0 ? 30 : unScaleX;
    const newY = unScaleY > 600 ? 570 : unScaleY < 0 ? 30 : unScaleY;
    await updateDevicePos(
      socket,
      activeMapID,
      "server",
      serverObj.serverID,
      newX,
      newY
    );
  };
  const handleClickDevice = (e) => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
    dispatch({
      type: "DEVICE_SELECTED",
      payload: {
        deviceID: serverID,
        deviceType: "server",
        deviceObj: serverObj,
        mapRightMode: "server",
        x,
        y,
        layerScale,
      },
    });

    konvaShrinkAndBack(deviceRef, 0.8, 0.15);
  };

  return (
    <Group
      x={x}
      y={y}
      ref={deviceRef}
      draggable={!enablePan}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onClick={handleClickDevice}
      onMouseEnter={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = enablePan ? "pointer" : "move";
      }}
      onMouseLeave={(e) => {
        const container = e.target.getStage().container();
        container.style.cursor = enablePan ? "grab" : "default";
      }}
    >
      <Server size={iconSize} disableConnectStatus />

      <Rect
        width={iconSize * mapScale.x}
        height={iconSize * mapScale.y}
        onMouseOver={() => {
          const layer = layerRef.current;
          const layerScale = layer.scaleX();
          setScale(layerScale);
          setShowID(true);
        }}
        onMouseOut={() => {
          setShowID(false);
        }}
        onClick={handleClickDevice}
        onTap={handleClickDevice}
        strokeWidth={2 / scale}
        stroke={selected ? serverColorDark : ""}
      />

      {showID && !shadEnabled && description && (
        <KonvaLabel
          x={0}
          y={iconSize * mapScale.y}
          text={description}
          fontSize={12}
          tagColor={serverColorDark}
          scale={scale}
        />
      )}
    </Group>
  );
}
