import React from "react";

export default function Clock({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <circle
        cx="40.157"
        cy="39.811"
        r="36.123"
        stroke="#fc5717"
        strokeLinecap="round"
        strokeWidth={5}
        fill="#fff"
      ></circle>
      <path
        fill="#1e3a5a"
        d="M39.16 62.85c.62-1.314 1.812-.975 2.198 0v5.676c-.502.883-1.51 1.167-2.198 0zM63.243 40.913c-1.315-.615-.98-1.81-.007-2.198l5.676-.018c.885.5 1.172 1.506.007 2.198zM11.698 40.933c-1.315-.615-.98-1.81-.007-2.198l5.676-.018c.885.5 1.172 1.506.007 2.198zM39.12 11.204c.62-1.314 1.812-.975 2.198 0v5.676c-.502.883-1.51 1.167-2.198 0z"
      ></path>
      <path
        fill="#668000"
        d="M37.136 41.087L22.794 55.502c-.44 1.877.52 2.002 1.72 1.83l14.379-14.416c-.97-.61-1.408-1.22-1.757-1.829z"
      ></path>
      <path
        fill="#5a96ce"
        d="M36.805 39.889l-11.2-11.119 3.571-3.571 11.2 11.281c-1.47.24-2.8.929-3.571 3.409z"
      ></path>
      <path
        fill="#89d3cc"
        d="M43.622 39.889l16.666-16.444-3.58-3.696-16.706 16.498c1.495.395 2.954.894 3.62 3.641z"
      ></path>
      <circle cx="40.145" cy="39.667" r="3.4" fill="#febf63"></circle>
    </svg>
  );
}
