// import Portal from "../Portal";
import React from "react";
import { dialogBg } from "asset/string/color";
import BackgroundPaper from "components/BackgroundPaper";
import Divider10 from "components/Divider10";
import { Clear, Save } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Html } from "react-konva-utils";

export default function MapModal({
  x,
  y,
  children,
  background,
  open,
  handleCloseModal,
  handleSaveModal,
  disableCloseButton,
  disableBottomAction,
  width,
  height,
  layerProps,
}) {
  const layer = layerProps?.layerRef?.current;
  const scaleX = 1 / layer?.scaleX();
  const scaleY = 1 / layer?.scaleY();
  return (
    <>
      {open && (
        <Html>
          <BackgroundPaper
            style={{
              position: "absolute",
              top: y ?? window.innerHeight / 2,
              left: x ?? window.innerWidth / 2,
              width: width || 300,
              height: height || 250,
              background: background || dialogBg,
              border: "2px solid darkslategrey",
              padding: "5px 15px 5px 5px",
              overflow: "auto",
              transform: `scale(${scaleX},${scaleY})`,
              transformOrigin: `top left`,
            }}
          >
            {children}
            {!disableBottomAction && (
              <>
                <Divider10 />
                {handleSaveModal && (
                  <Button size="small" onClick={handleSaveModal}>
                    <Save />
                  </Button>
                )}
                {!disableCloseButton && (
                  <Button size="small" onClick={handleCloseModal}>
                    <Clear />
                  </Button>
                )}
              </>
            )}
          </BackgroundPaper>
        </Html>
      )}
    </>
  );
}
