import { useContext, useMemo, useState, useRef } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Box, Tooltip, TextField } from "@mui/material";
import { Search } from "@mui/icons-material";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import { ViewportList } from "react-viewport-list";

import {
  fetchServerConsoleLog,
  searchServerConsoleLog,
} from "actions/serverActions";
import IconButtonDelete from "components/IconButtonDelete";
import InputField from "components/InputField";

const w = window.innerWidth;
const h = window.innerHeight;
export default function ServerLog() {
  const [state, dispatch] = useContext(SMContext);
  const ref = useRef(null);
  const { serverID, serverObj } = state;
  const [consoleLogLines, setConsoleLogLines] = useState(50);
  const [consoleLogArr, setConsoleLogArr] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [searchText, setSearchText] = useState("");
  const handleSearchConsoleLog = async () => {
    const res = await searchServerConsoleLog(serverObj.localserverUrl, keyword);
    let arr = [...res.result];
    arr = arr.map((s) => {
      var t = s.replace(
        /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
        ""
      );
      return t;
    });
    setConsoleLogArr(arr);
  };
  const handleFetchConsoleLog = async () => {
    const res = await fetchServerConsoleLog(
      serverObj.localserverUrl,
      consoleLogLines
    );
    let arr = [...res.result];
    arr = arr.map((s) => {
      var t = s.replace(
        /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
        ""
      );
      return t;
    });
    setConsoleLogArr(arr);
  };
  const consoleLogArrUse = useMemo(() => {
    if (searchText) {
      const arr = consoleLogArr.filter((s) => s.includes(searchText));
      return arr;
    } else {
      return consoleLogArr;
    }
  }, [consoleLogArr, searchText]);
  const tagColorOf = (tag) => {
    if (!tag) return "#fff";
    if (tag.includes("ERROR")) return "red";
    if (tag.includes("ACTION")) return "cyan";
    if (tag.includes("IN")) return "lightgreen";
    if (tag.includes("OUT")) return "yellow";
    if (tag.includes("CRON")) return "orange";
  };
  return (
    <div>
      <DivInline>
        <Button onClick={handleFetchConsoleLog} size="small" variant="outlined">
          Get server latest console log
        </Button>
        <TextField
          size="small"
          label="Lines"
          value={consoleLogLines || 0}
          onChange={(e) => setConsoleLogLines(e.target.value)}
        />
        <Button
          onClick={handleSearchConsoleLog}
          size="small"
          variant="outlined"
          sx={{ marginLeft: "4vw" }}
        >
          Search server console log
        </Button>
        <TextField
          size="small"
          label="Keyword"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
        <Typography variant="caption">
          e.g. 09-16/05 search lines at 16 Sep 05hour
        </Typography>
        <Button
          onClick={() => setConsoleLogArr([])}
          size="small"
          variant="outlined"
          sx={{ marginLeft: "4vw" }}
        >
          Clear console log
        </Button>
      </DivInline>
      <DivExist show={consoleLogArrUse.length > 0}>
        <DivInline>
          <Search />
          <InputField
            value={searchText}
            onInput={(val) => setSearchText(val)}
            autoFocus
            keyPressEnter
            label="搜尋"
            sx={{ width: "25vw" }}
          />
          <IconButtonDelete onBtnClick={() => setSearchText("")} />
        </DivInline>
      </DivExist>
      <div className="scroll-container" ref={ref}>
        <ViewportList viewportRef={ref} items={consoleLogArrUse}>
          {(line, key) => {
            let message = line.substring(15);
            const messageArr = message.split("~");
            const primaryTag = messageArr[1];
            let secondaryTag, description;
            if (messageArr[2]?.length > 12) {
              secondaryTag = "-";
              description = messageArr[2];
            } else {
              secondaryTag = messageArr[2];
              description = messageArr[3];
            }

            return (
              <div
                key={key}
                style={{
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid grey",
                }}
              >
                <Typography variant="caption" sx={{ width: "8vw" }}>
                  {line.substring(0, 5)}
                </Typography>
                <Typography variant="caption" sx={{ width: "8vw" }}>
                  {line.substring(6, 14)}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ width: "8vw", color: tagColorOf(primaryTag) }}
                >
                  {primaryTag}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{ width: "8vw", color: tagColorOf(secondaryTag) }}
                >
                  {secondaryTag}
                </Typography>
                <Typography variant="caption" sx={{ width: "65vw" }}>
                  {description}
                </Typography>
              </div>
            );
          }}
        </ViewportList>
      </div>
    </div>
  );
}
