import React from 'react';
import DivInline from 'components/DivInline';
import { SwipeRightAlt } from '@mui/icons-material';
import DivCol from 'components/DivCol';
import DeviceIcon from 'components/DeviceIcon';
import SenLightSettingCheckModal from 'containers/map/modal/SenLightSettingCheckModal';
import { Typography } from '@mui/material';
import IconButtonBack from 'components/IconButtonBack';
import ZoneControlIcon from 'components/ZoneControlIcon';


export default function ZcMapModalHeader({ zoneControlObj, controlledDeviceObj, handleClose, layerProps }) {
    const deviceID = controlledDeviceObj.serial || controlledDeviceObj.sensorID || controlledDeviceObj.daliCtlID;
    const deviceType = controlledDeviceObj.serial ? "light" : controlledDeviceObj.sensorID ? "sensor" : controlledDeviceObj.daliCtlID ? "daliCtl" : "";
    return (
        <DivInline>
            <IconButtonBack
                tooltip="Close dialog without saving"
                onBtnClick={handleClose}
            />
            <DivCol>
                <ZoneControlIcon
                    type={zoneControlObj.type}
                    width={2.5}
                    sizeUnit="vw"
                />
                <Typography variant="caption">{zoneControlObj.zoneControlID}</Typography>
            </DivCol>
            <SwipeRightAlt sx={{ fontSize: "2rem", margin: "0 2vw" }} />
            {/* <SenLightSettingCheckModal sensorObj={sensorObj} lightObj={deviceObj} settingNumArray={[1]} layerProps={layerProps}> */}
            <DivCol>
                <DeviceIcon
                    deviceType={deviceType}
                    deviceStyle={controlledDeviceObj.style || controlledDeviceObj.type}
                    width={2.5}
                    sizeUnit="vw"
                    gang={controlledDeviceObj.gang}
                />
                <Typography variant="caption">{deviceID}</Typography>
            </DivCol>
            {/* </SenLightSettingCheckModal> */}
        </DivInline>
    )
}