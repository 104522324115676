import { useContext } from "react";
import { SMContext } from "../context/smContext";
import { Typography, Button } from "@mui/material";
import ModalSM from "./ModalSM";
import IconButtonClose from "./IconButtonClose";
import IconButtonDone from "./IconButtonDone";
import DivInline from "./DivInline";
import Divider10 from "./Divider10";
export default function WindowConfirm() {
  const [state, dispatch] = useContext(SMContext);
  const { windowConfirm, windowConfirmTitle, onConfirm, onCancel, callback } =
    state;
  const handleClose = () => {
    dispatch({
      type: "WINDOW_CONFIRM",
      payload: { status: false, windowConfirmTitle: "" },
    });
  };

  return (
    <ModalSM open={windowConfirm} onClose={handleClose} disableBottomClose>
      <Typography sx={{ marginTop: 3 }} align="center">
        {windowConfirmTitle}
      </Typography>
      <Divider10 />
      <DivInline justifyContent="space-around">
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            onConfirm();
            handleClose();
            if (callback) callback();
          }}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            if (onCancel) onCancel();
            handleClose();
            if (callback) callback();
          }}
        >
          No
        </Button>
      </DivInline>
    </ModalSM>
  );
}
