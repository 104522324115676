import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import { Typography, Radio } from "@mui/material";
import DivInline from "components/DivInline";
import { FastForward, Block, CheckCircleOutline } from "@mui/icons-material";
import { purple, green } from "@mui/material/colors";
import {
    sensorDisconnectSensor,
    updateSensorProperty,
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import {
    confirmWindow,
    hideBigProgress,
    showBigProgress,
} from "actions/screenActions";
import { deviceModalPos } from "actions/mapActions";
import General from "@ecoenghk/general";
import SensorIcon from "components/SensorIcon";
import SensorMapModalHeader from "./SensorMapModalHeader";
import { transferAllSettingsToGateway } from "actions/gatewayActions";
const gs = new General();

export default function SenDaylightSensorModal({
    x,
    y,
    open,
    handleClose,
    layerProps,
    selectedSensor,
    sensorID,
    sensorObj,
}) {
    const [disabledArray, setDisabledArray] = useState([]);
    const { gatewayObjAll, dispatch, sensorObjAll } = layerProps;
    const controlledSensorObj = sensorObjAll[selectedSensor];
    useEffect(() => {
        let arr = [];
        for (let i = 1; i <= 11; i++) {
            const deviceSetting = sensorObj?.[`setting${i}`]?.[selectedSensor];
            arr = [...arr, deviceSetting?.disabledSensor ? 1 : 0];
        }
        setDisabledArray(arr);
    }, [open, selectedSensor]);


    const updateDisabledArray = async (index, newVal) => {
        console.log(index, newVal)
        let newArr = [...disabledArray];
        newArr[index] = Number(newVal);
        setDisabledArray(newArr);
    };
    const handleSave = async () => {
        let updateObj = {};
        for (let i = 1; i <= 11; i++) {
            let setting = sensorObj?.[`setting${i}`] || {};
            let deviceSetting = setting?.[selectedSensor] || {};
            const d = disabledArray[i - 1] === 1 ? true : false
            deviceSetting = { ...deviceSetting, disabledSensor: d }
            setting = { ...setting, [selectedSensor]: deviceSetting };
            updateObj[`setting${i}`] = setting;
        }
        console.log(updateObj);
        await updateSensorProperty(sensorID, sensorObj, sensorObj.gatewayID, updateObj);
        await gs.waitFor(2000);
        const gatewayObj = gatewayObjAll[sensorObj.gatewayID];
        if (gatewayObj) await transferAllSettingsToGateway(gatewayObj);
        handleClose();
    };
    const handleDisconnect = async () => {
        confirmWindow(
            dispatch,
            `Confirm disconnect sensor [${selectedSensor}]?`,
            async () => {
                showBigProgress(dispatch);
                await sensorDisconnectSensor(sensorObj, controlledSensorObj);
                hideBigProgress(dispatch);
                handleClose();
            }
        );
    };
    // const handleChangeWorkMode = (newMode) => {
    //     // let newArrA = [],
    //     let newArrPwm = [];
    //     if (newMode === "near") {
    //         // newArrA = [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1];
    //         if (isDim) newArrPwm = [90, 90, 80, 70, 60, 40, 30, 10, 0, 0, 0];
    //         else newArrPwm = [100, 100, 100, 100, 100, 100, 0, 0, 0, 0, 0];
    //     } else if (newMode === "middle") {
    //         // newArrA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //         if (isDim) newArrPwm = [90, 90, 90, 90, 90, 80, 70, 60, 50, 40, 30];
    //         else newArrPwm = [100, 100, 100, 100, 100, 100, 100, 100, 0, 0, 0];
    //     } else if (newMode === "far") {
    //         // newArrA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    //         if (isDim) newArrPwm = [90, 90, 90, 80, 80, 80, 80, 70, 70, 70, 70];
    //         else newArrPwm = [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 0];
    //     }
    //     // setCtlA_arr(newArrA);
    //     setPwmArr(newArrPwm);
    // };
    const modalWidth = window.innerWidth * 0.48;
    const modalHeight = window.innerHeight * 0.52;
    const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
    return (
        <MapModal
            x={pos.x}
            y={pos.y}
            open={open}
            handleCloseModal={handleClose}
            disableCloseButton
            width={modalWidth}
            height={modalHeight}
            layerProps={layerProps}
        >
            <SensorMapModalHeader sensorObj={sensorObj} deviceObj={controlledSensorObj} layerProps={layerProps} handleClose={handleClose} />
            {/* <Typo graphy variant="caption">Default settings:</Typography> */}
            {/* <ButtonGroup variant="outlined" size="small">
                    <Button onClick={() => handleChangeWorkMode("near")}>
                        Near window
                    </Button>
                    <Button onClick={() => handleChangeWorkMode("middle")}>
                        4-8 from window
                    </Button>
                    <Button onClick={() => handleChangeWorkMode("far")}>
                        {`>8m from window`}
                    </Button>
                </ButtonGroup> */}
            <Divider10 />
            {gs.newArrayBetween(0, 10).map((n) => {
                return (
                    <DivInline
                        key={n}
                        style={{ width: "42vw", height: "6vh" }}
                        justifyContent="space-between"
                    >
                        <DivInline>
                            {n === 10 ? (
                                <Typography
                                    sx={{ width: "11vw" }}
                                    variant="caption"
                                >{`Lv10. >1000lux`}</Typography>
                            ) : (
                                <Typography sx={{ width: "11vw" }} variant="caption">
                                    {`Lv${n}.  ${n * 100}-${n * 100 + 99}`}lux
                                </Typography>
                            )}
                            <SensorIcon type="daylight" luxLv={n} disabledConnectStatus />
                        </DivInline>
                        <FastForward style={{ fontSize: "0.8rem" }} />

                        <CheckCircleOutline sx={{ color: green[300] }} />
                        <Typography variant="caption">Enable</Typography>
                        <Radio checked={!disabledArray[n] ? true : false} onChange={e => updateDisabledArray(n, e.target.value)} value={0} />
                        <Typography variant="caption" sx={{ marginLeft: "3vw" }}>Disable</Typography>
                        <Block sx={{ color: purple[200] }} />
                        <Radio checked={disabledArray[n] ? true : false} label="Disable" onChange={e => updateDisabledArray(n, e.target.value)} value={1} />

                    </DivInline>
                );
            })}

            <Divider10 />

            <IconButtonClose
                tooltip="Close dialog without saving"
                onBtnClick={handleClose}
            />
            <IconButtonDone
                tooltip="Save settings and close dialog"
                onBtnClick={handleSave}
            />
            <IconButtonDelete
                tooltip="Disconnect light from sensor"
                onBtnClick={handleDisconnect}
            />
        </MapModal>
    );
}
