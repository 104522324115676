import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
    Typography,
    Button,
    Paper,
    Box,
    TextField,
    MenuItem,
    Divider,
} from "@mui/material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import Divider10 from "components/Divider10";

export default function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [superPw, setSuperPw] = useState("");

    let curUrl = window.location.href;
    const user = searchParams.get("user");
    const ip = searchParams.get("ip");
    const handleSuperResetPassword = async () => {
        if (!superPw) {
            alert("Please enter super password");
            return;
        }
        const url = `${ip}/api/serverResetUserPasswordSuper?user=${user}&superPw=${superPw}`;
        console.log(url)
        const res = await fetch(url);
        console.log(res.status);
        if (res.status !== 200) {
            alert("Connect error");
            return;
        }
        const resJson = await res.json();
        console.log(resJson);
        if (resJson.result === "fail") {
            alert(resJson.message);
            return;
        }
        if (resJson.result === "OK" && resJson.user === user) {
            alert(`User password of ${user} reset to [123456]`);
            window.open(ip, "_self");
        } else {
            alert(`Reset password fail, server response not recognized - ${JSON.stringify(resJson || {})}`);
        }
    }
    return (
        <Paper sx={{ background: "transparent" }}>
            <DivInline justifyContent="center">
                <Box sx={{ marginTop: "4vh", width: "50vw", height: "82vh" }}>
                    <DivCol>
                        <SkylightLogo width={30} height={10} sizeUnit="vw" />
                        <Typography variant="body2" sx={{ margin: "3vh" }}>
                            Computerized Lighting Management System
                        </Typography>
                        <Typography variant="h6" sx={{ margin: "3vh" }}>
                            Reset Password By Admin
                        </Typography>
                        <Divider10 />
                        <Typography>{user}</Typography>
                        <Divider10 />
                        <TextField
                            label="Super Password"
                            type="password"
                            variant="outlined"
                            value={superPw}
                            onChange={(e) => setSuperPw(e.target.value)}
                        />
                        <Divider10 />

                        <Button variant="contained" color="primary"
                            onClick={handleSuperResetPassword}
                        >
                            Reset user password to [123456]
                        </Button>
                        <Divider10 />
                        <Typography variant="caption">Please contact Skymon to get a super password</Typography>
                        {/* <Typography variant="caption">Server url: {curUrl}</Typography> */}
                        <Divider10 />
                        <Typography variant="caption">Server ip: {ip}</Typography>
                        <Divider10 />
                    </DivCol>
                </Box>
            </DivInline>

        </Paper>
    )
}