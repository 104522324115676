import { useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box, Button } from "@mui/material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import SmallLightCard from "components/SmallLightCard";
import SmallSensorCard from "components/SmallSensorCard";
import SmallDaliCtlCard from "components/SmallDaliCtlCard";
import BindedSensorSettingPanel from "./BindedSensorSettingPanel";
import IconButtonRefresh from "components/IconButtonRefresh";
import {
  gatewayConnectDaliCtl,
  gatewayConnectLight,
  gatewayConnectSensor,
  gatewayDisconnectLight,
  gatewayDisconnectSensor,
  gatewayDisconnectDaliCtl,
  queryGatewayOneSetting,
} from "actions/gatewayActions";
import ModalSM from "components/ModalSM";
import { confirmWindow } from "actions/screenActions";
import { mgFindOne, mgUpdateOneUpsert } from "actions/mongoApiActions";
import { updateDocFS } from "actions/firebaseMgActions";
import DeviceCard from "components/DeviceCard";
import { Router } from "@mui/icons-material";
import { deviceInfoOfObj } from "actions/generalActions";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import { changeOneLightSetting, queryOneLightSetting } from "actions/lightActions";
import { changeSensorOneSetting, queryOneSensorSetting } from "actions/sensorActions";
import { changeDaliCtlOneSetting, queryDaliCtlOneSetting } from "actions/daliCtlActions";
export default function ConnectDevicesPanel() {
  const [state] = useContext(SMContext);
  const { activeMapID, mapObjAll, gatewayObjAll, activeGatewayID } = state;
  const activeMapObj = mapObjAll[activeMapID];
  const gatewayObj = gatewayObjAll[activeGatewayID];
  const { serialMap, sensorMap, daliCtlMap, unknownDevice, deviceBindMap } =
    gatewayObj || {};
  const lightArrInMapAll = Object.keys(activeMapObj?.lightObj);
  const lightArrInMap = Object.values(serialMap || {}).filter((s) =>
    lightArrInMapAll?.includes(s)
  );
  const lightArrNotInMap = Object.values(serialMap || {}).filter(
    (s) => !lightArrInMapAll?.includes(s)
  );
  const sensorArrInMapAll = Object.keys(activeMapObj?.sensorObj);
  const sensorArrInMap = Object.values(sensorMap || {}).filter((s) =>
    sensorArrInMapAll?.includes(s)
  );
  const sensorArrNotInMap = Object.values(sensorMap || {}).filter(
    (s) => !sensorArrInMapAll?.includes(s)
  );
  const daliCtlArrInMapAll = Object.keys(activeMapObj?.daliCtlObj || {});
  const daliCtlArrInMap = Object.values(daliCtlMap || {}).filter((s) =>
    daliCtlArrInMapAll?.includes(s)
  );
  const daliCtlArrNotInMap = Object.values(daliCtlMap || {}).filter(
    (s) => !daliCtlArrInMapAll?.includes(s)
  );
  let gwBindSerialMap = {},
    gwBindSensorMap = {},
    gwBindDaliCtlMap = {};
  Object.keys(deviceBindMap || {}).forEach((key) => {
    const obj = deviceBindMap[key];
    const { dtkAdd, type, sensorID, serial, daliCtlID } = obj || {};
    if (type === "light") {
      gwBindSerialMap[dtkAdd] = serial;
    } else if (type === "sensor") {
      gwBindSensorMap[dtkAdd] = sensorID;
    } else if (type === "daliCtl") {
      gwBindDaliCtlMap[dtkAdd] = daliCtlID;
    }
  });
  const serialArrBindedInGwNotInServer = Object.values(gwBindSerialMap).filter(
    (s) => !Object.values(serialMap || {})?.includes(s)
  );
  const sensorArrBindedInGwNotInServer = Object.values(gwBindSensorMap).filter(
    (s) => !Object.values(sensorMap || {})?.includes(s)
  );
  const daliCtlArrBindedInGwNotInServer = Object.values(
    gwBindDaliCtlMap
  ).filter((s) => !Object.values(daliCtlMap || {})?.includes(s));
  return (
    <div>
      <DivInline justifyContent="center">
        <Typography variant="h6" align="center">
          Connect Devices
        </Typography>
        <IconButtonRefresh
          onBtnClick={() => queryGatewayOneSetting(activeGatewayID, 0x0016, "queryBindedDevice")}
        />
      </DivInline>
      <Divider10 />
      <DivExist show={lightArrInMap.length > 0}>
        <Typography>Lights in this map</Typography>

        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {lightArrInMap.map((s, k) => (
            <InMapDeviceModal
              key={k}
              deviceID={s}
              deviceType="serial"
              gwBindDeviceMap={gwBindSerialMap}
              gwBindDaliCtlMap={gwBindDaliCtlMap}
              gatewayObj={gatewayObj}
            />
          ))}
        </div>

        <Divider10 />
      </DivExist>
      <DivExist show={sensorArrInMap.length > 0}>
        <Typography>Sensors in this map</Typography>
        <DivInline style={{ flexWrap: "wrap", width: "90%" }}>
          {sensorArrInMap.map((s, k) => (
            <InMapDeviceModal
              key={k}
              deviceID={s}
              deviceType="sensor"
              gwBindDeviceMap={gwBindSensorMap}
              gwBindDaliCtlMap={gwBindDaliCtlMap}
              gatewayObj={gatewayObj}
            />
          ))}
        </DivInline>
        <Divider10 />
      </DivExist>
      <DivExist show={daliCtlArrInMap.length > 0}>
        <Typography>Dali controllers in this map</Typography>
        <DivInline style={{ flexWrap: "wrap", width: "90%" }}>
          {daliCtlArrInMap.map((s, k) => (
            <InMapDeviceModal
              key={k}
              deviceID={s}
              deviceType="daliCtl"
              gwBindDeviceMap={gwBindDaliCtlMap}
              gatewayObj={gatewayObj}
            />
          ))}
        </DivInline>
        <Divider10 />
      </DivExist>
      <DivExist show={lightArrNotInMap.length > 0}>
        <Typography>Lights NOT in this map</Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {lightArrNotInMap.map((s, k) => (
            <NotInMapDeviceCard deviceID={s} key={k} />
          ))}
        </div>
      </DivExist>

      <DivExist show={sensorArrNotInMap.length > 0}>
        <Typography>Sensors NOT in this map</Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {sensorArrNotInMap.map((s, k) => (
            <NotInMapDeviceCard deviceID={s} key={k} />
          ))}
        </div>
        <Divider10 />
      </DivExist>
      <DivExist show={daliCtlArrNotInMap.length > 0}>
        <Typography>Dali controllers NOT in this map</Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {daliCtlArrNotInMap.map((s, k) => (
            <NotInMapDeviceCard deviceID={s} key={k} />
          ))}
        </div>
        <Divider10 />
      </DivExist>
      <DivExist show={Object.keys(unknownDevice || {})?.length > 0}>
        <Divider10 />
        <Typography>Unknown devices</Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {Object.keys(unknownDevice || {})?.map((add, k) => (
            <Box
              key={k}
              sx={{ background: "#fff", padding: "0.2vw", margin: "0.2vw" }}
            >
              <Typography variant="caption" sx={{ color: "#000" }}>
                {add}
              </Typography>
            </Box>
          ))}
        </div>
      </DivExist>
      <DivExist show={serialArrBindedInGwNotInServer.length > 0}>
        <Typography>Lights binded in gateway not in server</Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {serialArrBindedInGwNotInServer.map((s, k) => (
            <NotInServerDeviceCard deviceID={s} key={k} deviceType="serial" />
          ))}
        </div>
        <Divider10 />
      </DivExist>
      <DivExist show={sensorArrBindedInGwNotInServer.length > 0}>
        <Typography>Sensors binded in gateway not in server</Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {sensorArrBindedInGwNotInServer.map((s, k) => (
            <NotInServerDeviceCard deviceID={s} key={k} deviceType="sensor" />
          ))}
        </div>
        <Divider10 />
      </DivExist>
      <DivExist show={daliCtlArrBindedInGwNotInServer.length > 0}>
        <Typography>
          Dali controllers binded in gateway not in server
        </Typography>
        <div style={{ display: "flex", flexWrap: "wrap", width: "90%" }}>
          {daliCtlArrBindedInGwNotInServer.map((s, k) => (
            <NotInServerDeviceCard deviceID={s} key={k} deviceType="daliCtl" />
          ))}
        </div>
        <Divider10 />
      </DivExist>
      <Divider10 />

      <BindedSensorSettingPanel />
    </div>
  );
}

function NotInMapDeviceCard({ deviceID, backgroundColor = "#fff" }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          backgroundColor,
          padding: "0.2vw",
          margin: "0.3vw",
          width: "5vw",
        }}
      >
        <Typography variant="caption" sx={{ color: "#000" }}>
          {deviceID}
        </Typography>
      </Box>
    </div>
  );
}
function InMapDeviceModal({ deviceID, deviceType, gwBindDeviceMap, gwBindDaliCtlMap, gatewayObj }) {
  const [open, setOpen] = useState(false);
  const [state, dispatch] = useContext(SMContext);
  const { ttyS2, ttyS3 } = gatewayObj || {};

  const {
    lightObjAll,
    activeGatewayID,
    gatewayObjAll,
    sensorObjAll,
    daliCtlObjAll,
  } = state;
  let deviceObj;
  if (deviceType === "serial") deviceObj = lightObjAll[deviceID];
  else if (deviceType === "sensor") deviceObj = sensorObjAll[deviceID];
  else if (deviceType === "daliCtl") deviceObj = daliCtlObjAll[deviceID];
  let deviceMeshChannel = Number(deviceObj?.meshChannel) ?? "unknown";
  const gwInterface = Number(ttyS2?.meshChannel) === deviceMeshChannel ? "ttyS2" : Number(ttyS3?.meshChannel) === deviceMeshChannel ? "ttyS3" : "unknown";
  const deviceInfo = deviceInfoOfObj(deviceObj);
  const bindDeviceIDArr = Object.values(gwBindDeviceMap);
  const isDaliLight = (deviceType === "serial" && deviceObj?.daliCtlID) ? true : false;
  const isDaliSensor = (deviceType === "sensor" && deviceObj?.daliCtlID) ? true : false;
  if (isDaliLight) {
    deviceMeshChannel = daliCtlObjAll[deviceObj?.daliCtlID]?.meshChannel;
  }
  let bindedInGw = false;
  if (bindDeviceIDArr.includes(deviceID)) bindedInGw = true;
  if (isDaliLight && Object.values(gwBindDaliCtlMap)?.includes(deviceObj?.daliCtlID)) bindedInGw = true;
  if (isDaliSensor && Object.values(gwBindDaliCtlMap)?.includes(deviceObj?.daliCtlID)) bindedInGw = true;
  const handleClose = () => {
    setOpen(false);
  };
  function TtyBox({ ttyNo, ttyObj }) {
    const isCurrent = gwInterface === ttyNo;
    return (
      <Box sx={{ width: "6vw", height: "6vh", outline: `${isCurrent ? "3px" : "1px"} solid ${isCurrent ? "red" : "#fff"}`, padding: "0.5vh", margin: "0 0.5vw", cursor: "pointer" }}
        onClick={() => {
          if (!isCurrent && ttyObj?.meshChannel != undefined)
            if (deviceType === "serial")
              changeOneLightSetting(deviceObj, 0x02a2, {
                meshChannel: parseInt(ttyObj?.meshChannel)
              })
          if (deviceType === "sensor")
            changeSensorOneSetting(deviceObj, 0x06a2, {
              meshChannel: parseInt(ttyObj?.meshChannel)
            })
          if (deviceType === "daliCtl")
            changeDaliCtlOneSetting(deviceObj, 0x0aa2, {
              meshChannel: parseInt(ttyObj?.meshChannel)
            })
        }}
      >
        <Typography variant="caption" display="block" align="center">{ttyNo}</Typography>
        <Typography variant="caption" display="block" align="center">{`Channel ${ttyObj?.meshChannel || "unknown"}`}</Typography>
      </Box>
    )
  }
  return (
    <>
      <DeviceCard
        deviceID={deviceID}
        deviceType={deviceType}
        onClick={() => {
          setOpen(true);
        }}
        backgroundColor={bindedInGw ? "#D7DBDD" : "#E6B0AA"}
        tooltip={bindedInGw ? "" : "Not binded in gateway"}
      />
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle={`${deviceInfo.deviceType.toUpperCase()} [${deviceID}] in map`}
      >
        <DeviceIdAddGwLine deviceID={deviceID} gatewayID={deviceObj?.gatewayID} address={deviceObj?.dtkAdd} deviceObj={deviceObj} />
        <SpaceBetweenDiv title="Device Name" data={deviceInfo.deviceName} />
        <DivExist show={isDaliLight}>
          <SpaceBetweenDiv
            title="Under Dali controller"
            data={deviceObj?.daliCtlID}
          />
        </DivExist>
        <Divider10 />
        <SpaceBetweenDiv title={
          <DivInline>
            <Typography variant="body2">interface</Typography>
            <IconButtonRefresh onBtnClick={() => {
              if (deviceType === "serial")
                queryOneLightSetting(deviceObj, 0x03a2)
              if (deviceType === "sensor")
                queryOneSensorSetting(deviceObj, 0x07a2)
              if (deviceType === "daliCtl")
                queryDaliCtlOneSetting(deviceObj, 0x0ba2)
            }} />
          </DivInline>
        }
          data={
            <DivInline>
              <TtyBox ttyNo="ttyS3" ttyObj={ttyS3} />
              <TtyBox ttyNo="ttyS2" ttyObj={ttyS2} />
            </DivInline>
          }
        />
        <Divider10 />
        <DivExist show={!bindedInGw}>
          <Typography>Not binded in gateway</Typography>
          <Button
            variant="outlined"
            onClick={() => {
              confirmWindow(
                dispatch,
                `Connect device ${deviceID} to gateway ${activeGatewayID}`,
                () => {
                  let emitObj = {
                    type: "toGW_socket",
                    gatewayID: activeGatewayID,
                  };
                  if (deviceType === "serial") {
                    emitObj.topic = "gatewayConnectLight";
                    emitObj.payload = { lightObj: deviceObj };
                  } else if (deviceType === "sensor") {
                    emitObj.topic = "gatewayConnectSensor";
                    emitObj.payload = { sensorObj: deviceObj };
                  } else if (deviceType === "daliCtl") {
                    emitObj.topic = "gatewayConnectDaliCtl";
                    emitObj.payload = { daliCtlObj: deviceObj };
                  }
                  console.log(
                    `[COMMAND OUT] toGW_socket ${activeGatewayID} - topic: ${emitObj.topic}`
                  );
                  global.socket.emit("fromWeb", emitObj);
                  handleClose();
                }
              );
            }}
          >
            Connect to gateway
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              confirmWindow(
                dispatch,
                `Remove device ${deviceID} from gateway in server database`,
                async () => {
                  if (deviceType === "serial")
                    gatewayDisconnectLight(deviceObj);
                  if (deviceType === "sensor")
                    gatewayDisconnectSensor(deviceObj);
                  if (deviceType === "daliCtl")
                    gatewayDisconnectDaliCtl(deviceObj);
                  handleClose();
                }
              );
            }}
          >
            Remove from gateway in server database
          </Button>
        </DivExist>

      </ModalSM>
    </>
  );
}
function NotInServerDeviceCard({ deviceID, deviceType }) {
  const [open, setOpen] = useState(false);
  const [deviceObj, setDeviceObj] = useState({});
  const [state, dispatch] = useContext(SMContext);
  const { activeGatewayID, gatewayObjAll } = state;
  const gatewayObj = gatewayObjAll[activeGatewayID];
  const handleClose = () => {
    setDeviceObj({});
    setOpen(false);
  };
  const handleOpen = async () => {
    setOpen(true);
    const field = deviceType === "serial" ? "serial" : deviceType + "ID";
    let obj = await mgFindOne(deviceType, field, deviceID);
    setDeviceObj(obj);
  };
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#E6B0AA",
          padding: "0.2vw",
          margin: "0.2vw",
          width: "5vw",
        }}
        onClick={handleOpen}
      >
        <Typography variant="caption" sx={{ color: "#000" }}>
          {deviceID}
        </Typography>
      </Box>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle={`${deviceID} not under gateway in server database`}
      >
        <Divider10 />
        <Typography variant="body2">Server database record:</Typography>
        <Typography>{`${deviceType.toUpperCase()} ${deviceObj.description || deviceObj.sensorName
          }`}</Typography>
        <Typography>
          {`\u{01f194}${deviceID} @${deviceObj?.dtkAdd || deviceObj?.zigbeeAdd
            }`}
        </Typography>
        <DivInline>
          <Router />
          <Typography>{deviceObj.gatewayID}</Typography>
          <DivExist show={deviceObj.gatewayID !== activeGatewayID}>
            <Typography color="red" sx={{ marginLeft: "2vw" }}>
              Not current gateway {activeGatewayID}!
            </Typography>
          </DivExist>
        </DivInline>

        <Divider10 />
        <DivInline justifyContent="space-around">
          <Button
            variant="outlined"
            onClick={() => {
              confirmWindow(
                dispatch,
                `Add ${deviceID} under gateway in database`,
                async () => {
                  let obj = deviceObj;
                  obj.gatewayID = "";
                  if (deviceType === "serial") {
                    await gatewayConnectLight(activeGatewayID, gatewayObj, obj);
                  }
                  if (deviceType === "sensor") {
                    await gatewayConnectSensor(
                      activeGatewayID,
                      gatewayObj,
                      obj
                    );
                  }
                  if (deviceType === "daliCtl") {
                    await gatewayConnectDaliCtl(
                      activeGatewayID,
                      gatewayObj,
                      obj
                    );
                  }
                  handleClose();
                }
              );
            }}
          >
            Add under gateway in database
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              confirmWindow(
                dispatch,
                `Remove ${deviceID} in gateway`,
                async () => {
                  if (deviceType === "serial") {
                    const lightObj = await mgFindOne(
                      "serial",
                      "serial",
                      deviceID
                    );
                    const payload = {
                      type: "toGW_socket",
                      topic: "gatewayDisconnectLight",
                      gatewayID: activeGatewayID,
                      payload: { lightObj: lightObj },
                    };
                    console.log(
                      `[COMMAND OUT] toGW_socket ${activeGatewayID} - topic: gatewayDisconnectLight`
                    );
                    global.socket.emit("fromWeb", payload);
                  }
                }
              );
            }}
          >
            Remove from gateway
          </Button>
        </DivInline>
      </ModalSM>
    </>
  );
}
