import { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Edit, Done, Close } from "@mui/icons-material";
import DivInline from "./DivInline";
import { cursor } from "asset/string/string";
import { alertWindow } from "actions/screenActions";
import { secToMin } from "actions/generalActions";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string|number} props.initialSec
 * @param {boolean} [props.disabled]
 * @param {function(number)} props.onInput
 * @param {boolean} [props.autoSelect]
 * @param {string} [props.iconSize]
 * @param {boolean} [props.disableAutoFocus]
 * @param {boolean} [props.showValue]
 * @param {"body1"|"body2"|"caption"|"subtitle1"} [props.showValueVariant]
 * @param {boolean} [props.disableEditIcon]
 *
 */
export default function DialogInputMinSec({
  title,
  initialSec,
  disabled,
  onInput,
  autoSelect,
  iconSize,
  disableAutoFocus,
  showValue,
  showValueVariant,
  disableEditIcon,
  minSec,
  dispatch,
}) {
  const [open, setOpen] = useState(false);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const closeDialog = () => setOpen(false);
  useEffect(() => {
    const m = Math.floor(initialSec / 60);
    const s = initialSec % 60;
    setMin(m || 0);
    setSec(s || 0);
  }, [initialSec]);

  const handleFocus = (e) => {
    if (autoSelect) {
      e.target.select();
    }
  };
  const handleConfirmInput = () => {
    const totalSec = Number(min) * 60 + Number(sec);
    if (minSec && totalSec <= minSec) {
      alertWindow(
        dispatch,
        `Minimum input must be larger than ${minSec} seconds`
      );
    } else {
      onInput(totalSec);
      closeDialog();
    }
  };
  const keyPressed = (evt) => {
    if (evt.key === "Enter" || evt.which === "13") {
      handleConfirmInput();
    }
  };

  return (
    <>
      {showValue && (
        <Typography
          variant={showValueVariant || "body1"}
          onClick={() => setOpen(!open)}
          style={{
            cursor: "pointer",
          }}
        >
          {/* {initialSec < 60
            ? `${initialSec}sec`
            : `${Math.floor(initialSec / 60)}min ${initialSec % 60}sec`} */}
          {secToMin(initialSec)}
        </Typography>
      )}
      {!disableEditIcon && (
        <Edit
          onClick={() => {
            if (!disabled) setOpen(!open);
          }}
          style={{
            cursor: disabled ? cursor.notAllowed : cursor.pointer,
            color: "grey",
            fontSize: iconSize || "1.2em",
          }}
        />
      )}
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DivInline justifyContent="space-between">
            <DivInline>
              <TextField
                variant="outlined"
                value={min || ""}
                size="small"
                onChange={(evt) => setMin(Number(evt.target.value))}
                onFocus={handleFocus}
                onKeyPress={keyPressed}
                inputProps={{ autoFocus: !disableAutoFocus }}
              />
              <Typography>min</Typography>
            </DivInline>
            <DivInline>
              <TextField
                variant="outlined"
                value={sec || ""}
                onChange={(evt) => setSec(Number(evt.target.value))}
                onFocus={handleFocus}
                onKeyPress={keyPressed}
                inputProps={{ autoFocus: !disableAutoFocus }}
              />
              <Typography>sec</Typography>
            </DivInline>
          </DivInline>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
          <IconButton onClick={handleConfirmInput} size="large">
            <Done />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
