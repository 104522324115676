import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { ArrowDownward, ArrowForwardIos } from "@mui/icons-material";
import { Typography, Button, Checkbox } from "@mui/material";
import ModalSM from "components/ModalSM";
import LightIcon from "components/LightIcon";
import GatewayIcon from "components/GatewayIcon";
import SensorIcon from "components/SensorIcon";
import { dialogBg } from "asset/string/color";
import DivCol from "components/DivCol";
import { mapAddDevice, mapRemoveDevice } from "actions/mapActions";
import DivInline from "components/DivInline";
import {
  openSnackbar,
  showBigProgress,
  hideBigProgress,
  confirmWindow,
} from "actions/screenActions";
import MapThumbnail from "components/MapThumbnail";
import DaliCtl from "asset/svgComp/DaliCtl";

export default function CopyDeviceToMapModal({
  deviceID,
  deviceType,
  deviceName,
}) {
  const [state, dispatch] = useContext(SMContext);
  const {
    mapObjAll,
    activeMapID,
    lightObjAll,
    sensorObjAll,
    gatewayObjAll,
    daliCtlObjAll,
  } = state;
  const [open, setOpen] = useState(false);
  const [action, setAction] = useState("copy");

  let mapIDArray = Object.keys(mapObjAll || {})
    .filter((mid) => mid !== activeMapID)
    .sort((a, b) =>
      mapObjAll[a]?.mapName?.localeCompare(mapObjAll[b]?.mapName)
    );
  let deviceIcon;
  if (deviceType === "light") {
    deviceIcon = (
      <LightIcon
        width={3}
        sizeUnit="vw"
        statusA={1}
        lightStyle={lightObjAll[deviceID]?.style}
      />
    );
  } else if (deviceType === "gateway") {
    deviceIcon = <GatewayIcon width={3} sizeUnit="vw" />;
  } else if (deviceType === "sensor") {
    deviceIcon = (
      <SensorIcon
        width={3}
        sizeUnit="vw"
        status={0}
        type={sensorObjAll[deviceID]?.type}
        backgroundColor="#fff"
        disabledConnectStatus
      />
    );
  } else if (deviceType === "daliCtl") {
    deviceIcon = <DaliCtl width={3} sizeUnit="vw" disabledConnectStatus />;
  }
  const copyMoveDeviceToMap = async (mapID, mapName) => {
    showBigProgress(dispatch);
    const lightObj = lightObjAll[deviceID]
    if (deviceType === "light") {
      await mapAddDevice(mapID, deviceID, "light", lightObj);
      if (action === "move") {
        await mapRemoveDevice(activeMapID, deviceID, "light", lightObj);
        dispatch({
          type: "CLOSED_LIGHT_DETAIL_MODAL",
        });
      }
    } else if (deviceType === "sensor") {
      const sensorObj = sensorObjAll[deviceID];
      await mapAddDevice(mapID, deviceID, "sensor", sensorObj);
      if (action === "move") {
        await mapRemoveDevice(activeMapID, deviceID, "sensor", sensorObj);
        dispatch({
          type: "CLOSED_SENSOR_DETAIL_MODAL",
        });
      }
    } else if (deviceType === "gateway") {
      const gatewayObj = gatewayObjAll[deviceID];
      await mapAddDevice(mapID, deviceID, "gateway", gatewayObj);
      if (action === "move") {
        await mapRemoveDevice(activeMapID, deviceID, "gateway", gatewayObj);
        dispatch({
          type: "CLOSED_GATEWAY_DETAIL_MODAL",
        });
      }
    } else if (deviceType === "daliCtl") {
      const daliCtlObj = daliCtlObjAll[deviceID];
      await mapAddDevice(mapID, deviceID, "daliCtl", daliCtlObj);
      if (action === "move") {
        await mapRemoveDevice(activeMapID, deviceID, "daliCtl", daliCtlObj);
        dispatch({
          type: "CLOSED_DALICTL_DETAIL_MODAL",
        });
      }
    }
    openSnackbar(
      dispatch,
      `${deviceType} ${deviceName}[${deviceID}] ${action} to map ${mapName}[${mapID}]`
    );
    hideBigProgress(dispatch);
    setOpen(false);
    if (action === "move") dispatch({ type: "ALL_UNSELECTED" });
  };
  const modalWidth = "90vw";
  return (
    <>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
        variant="outlined"
      >
        <ArrowForwardIos />
      </Button>
      <ModalSM
        open={open}
        modalTitle="Copy/Move Device to other map"
        onClose={() => {
          setOpen(false);
        }}
        backgroundColor={dialogBg}
        width={modalWidth}
        height="80vh"
      >
        <DivCol>
          <DivCol
            style={{ border: "1px solid #fff", borderRadius: 8, padding: 3 }}
          >
            {deviceIcon}
            <Typography>{deviceName}</Typography>
            <Typography variant="caption">{deviceID}</Typography>
          </DivCol>
          <ArrowDownward style={{ fontSize: "4em" }} />
          <DivInline>
            <Typography>COPY</Typography>
            <Checkbox
              checked={action === "copy"}
              onChange={(e) => {
                if (e.target.checked) {
                  setAction("copy");
                }
              }}
              value="copy"
            />
            <Typography style={{ marginLeft: 50 }}>MOVE</Typography>
            <Checkbox
              checked={action === "move"}
              onChange={(e) => {
                if (e.target.checked) {
                  setAction("move");
                }
              }}
              value="move"
            />
          </DivInline>
          <div style={{ width: modalWidth, display: "flex", flexWrap: "wrap" }}>
            {mapIDArray.map((mid) => {
              return (
                <MapThumbnail
                  key={mid}
                  mapID={mid}
                  mapObj={mapObjAll[mid]}
                  chooseMap={async (m) => {
                    const mapName = mapObjAll[m].mapName;
                    confirmWindow(
                      dispatch,
                      `${action} ${deviceName} to ${mapName}?`,
                      async () => {
                        await copyMoveDeviceToMap(m, mapName);
                      }
                    );
                  }}
                />
              );
            })}
          </div>
        </DivCol>
      </ModalSM>
    </>
  );
}
