import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
} from "@mui/material";
import DivInline from "components/DivInline";
import { CopyAll, FastForward, ArrowDownward } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import { confirmWindow } from "actions/screenActions";
import {
  zoneControlDisconnectSensor,
  updateZcTimerDeviceSetting,
} from "actions/zcActions";
import { deviceModalPos } from "actions/mapActions";
import JsonModalContainer from "components/JsonModalContainer";
import ZcMapModalHeader from "./ZcMapModalHeader";


export default function ZcTimerSensorModal({
  x,
  y,
  open,
  handleClose,
  layerProps,
  selectedSensor,
  zoneControlObj,
}) {
  const [deviceSettingS, setDeviceSettingS] = useState({});
  const [deviceSettingI, setDeviceSettingI] = useState({});
  const [deviceSettingE, setDeviceSettingE] = useState({});
  // const [ableMode, setAbleMode] = useState("startEnable-endDisable"); //startEnable-endDisable, startDisable-endEnable, noAction
  const { dispatch, sensorObjAll, iconSize, layerScale, timeTableObjAll, userObj } = layerProps;
  const sensorObj = sensorObjAll[selectedSensor];
  useEffect(() => {
    const { setting1, setting2, setting3 } = zoneControlObj || {};
    setDeviceSettingS(setting2?.[selectedSensor] || {});
    setDeviceSettingI(setting3?.[selectedSensor] || {});
    setDeviceSettingE(setting1?.[selectedSensor] || {});

    // const objStart = setting2?.[selectedSensor] || {};
    // if (objStart.disabledSensor && !objEnd.disabledSensor)
    //   setAbleMode("startDisable-endEnable");
    // if (!objStart.disabledSensor && objEnd.disabledSensor)
    //   setAbleMode("startEnable-endDisable");
  }, [selectedSensor, open, zoneControlObj]);
  const { zoneControlName, type, enableSetting3 } = zoneControlObj || {};
  const updateSetting = async (settingNum, updateObj) => {
    if (settingNum === 2) {
      const newSet = { ...deviceSettingS, ...updateObj };
      setDeviceSettingS(newSet);
    }
    if (settingNum === 3) {
      const newSet = { ...deviceSettingI, ...updateObj };
      setDeviceSettingI(newSet);
    }
    if (settingNum === 1) {
      const newSet = { ...deviceSettingE, ...updateObj };
      setDeviceSettingE(newSet);
    }
  };

  const handleSave = async () => {
    // let sensorSetting1 = setting1?.[selectedSensor] || {};
    // let sensorSetting2 = setting2?.[selectedSensor] || {};
    // sensorSetting1 = {
    //   ...deviceSettingE,
    //   disabledSensor: ableMode === "startEnable-endDisable" ? 1 : 0,
    //   dtkAdd: sensorObj.dtkAdd,
    //   commandCode: 0x0611,
    // };
    // sensorSetting2 = {
    //   ...sensorSetting2,
    //   disabledSensor: ableMode === "startEnable-endDisable" ? 0 : 1,
    //   dtkAdd: sensorObj.dtkAdd,
    //   commandCode: 0x0611,
    // };
    await updateZcTimerDeviceSetting(zoneControlObj, selectedSensor, sensorObj.gatewayID, deviceSettingE, deviceSettingS, deviceSettingI);
    handleClose();
  };
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect sensor [${selectedSensor}]?`,
      async () => {
        await zoneControlDisconnectSensor(zoneControlObj, sensorObj);
        handleClose();
      }
    );
  };
  // const sensorSettingObj = {
  //   setting1: setting1?.[selectedSensor] || {},
  //   setting2: setting2?.[selectedSensor] || {},
  // };
  const modalWidth = window.innerWidth * 0.33;
  const modalHeight = window.innerHeight * 0.33;
  const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);


  return (
    <MapModal
      x={pos.x}
      y={pos.y}
      open={open}
      handleCloseModal={handleClose}
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
      disableBottomAction
    >
      <ZcMapModalHeader zoneControlObj={zoneControlObj} controlledDeviceObj={sensorObj} handleClose={handleClose} layerProps={layerProps} />
      <Divider10 />

      <SensorSettingRow title="Start" settingNum={2} updateSetting={updateSetting} deviceSetting={deviceSettingS} />

      {
        zoneControlObj?.enableSetting3 ?
          <>
            <Divider10 />
            <SensorSettingRow title="Inter" settingNum={3} updateSetting={updateSetting} deviceSetting={deviceSettingI} />
          </>
          : null
      }
      <Divider10 />

      <SensorSettingRow title="End" settingNum={1} updateSetting={updateSetting} deviceSetting={deviceSettingE} />

      <Divider10 />
      <IconButtonClose
        tooltip="Close dialog without saving"
        onBtnClick={handleClose}
      />
      <IconButtonDone
        tooltip="Save settings and close dialog"
        onBtnClick={handleSave}
      />
      <IconButtonDelete
        tooltip={`Disconnect sensor from timer ${zoneControlName}`}
        onBtnClick={handleDisconnect}
      />
    </MapModal>
  );
}
function SensorSettingRow({ title, settingNum, updateSetting, deviceSetting }) {
  return (
    <DivInline>
      <Typography sx={{ width: "6vw" }}>{title}</Typography>
      <Typography variant="caption">Disable</Typography>
      <Switch checked={deviceSetting?.disabledSensor === 0} onChange={(e) => {
        const en = e.target.checked;
        updateSetting(settingNum, { disabledSensor: en ? 0 : 1 });
      }} />
      <Typography variant="caption">Enable</Typography>
    </DivInline>
  )
}
function ButtonSizeRadio({ mode, name }) {
  return (
    <FormControlLabel
      value={mode}
      control={<Radio />}
      label={
        <div style={{ display: "flex" }}>
          <div
            style={{
              background: "lightgrey",
              marginRight: 5,
            }}
          />
          <Typography variant="body2">{name}</Typography>
        </div>
      }
    />
  );
}
