import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "./DivInline";
import IconButtonRefresh from "./IconButtonRefresh";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {("body1"|"body2"|"caption"|"subtitle1")} props.titleVariant
 * @param {*} props.data
 * @param {string} props.width
 * @param {function(event)} props.onChange
 * @param {function(string)} props.onChangeValue
 * @param {object} props.menuObj
 * @param {("standard"|"outlined"|"filled")} props.variant
 * @param {("left"|"right")} props.menuItemAlign
 * @param {("body1"|"body2"|"caption"|"subtitle1")} props.menuItemVariant
 * @param {boolean} props.disabled
 * @param {boolean} props.menuItemWithData
 * @param {function} props.handleRefresh
 * @returns
 */
export default function SpaceBetweenSelect({
  title,
  titleVariant,
  data,
  width,
  onChange,
  onChangeValue,
  menuObj,
  variant,
  menuItemAlign,
  menuItemVariant,
  disabled,
  menuItemWithData,
  handleRefresh,
}) {
  const handleChange = async (event) => {
    if (onChange) await onChange(event);
    if (onChangeValue) await onChangeValue(event.target.value);
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <DivInline>
        <Typography variant={titleVariant ? titleVariant : "body1"}>
          {title}
        </Typography>
        {handleRefresh && (
          <IconButtonRefresh onBtnClick={handleRefresh} />

        )}
      </DivInline>
      <TextField
        select
        value={data}
        onChange={handleChange}
        style={{ width: width ? width : "50%" }}
        variant={variant ? variant : "standard"}
        disabled={disabled}
      >
        {Object.keys(menuObj).map((item) => {
          const content = menuItemWithData
            ? `${menuObj[item]}[${item}]`
            : menuObj[item];
          return (
            <MenuItem key={item} value={item}>
              <Typography
                variant={menuItemVariant ? menuItemVariant : "body2"}
                align={menuItemAlign || "right"}
              >
                {content}
              </Typography>
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
}
