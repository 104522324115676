import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import {
  gatewayQueryMeshParameter,
  gatewayChangeMeshParameter
} from "actions/gatewayActions";
import {
  meshFreqMap,
  meshSpeedMap,
  meshBaudrateMap,
  meshPowerDbmMap,
  meshChannelMap,
} from "actions/generalActions";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import TestByteModal from "./TestByteModal";
import { confirmWindow } from "actions/screenActions";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import DivExist from "components/DivExist";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import { ArrowForwardIos } from "@mui/icons-material";
import { queryOneLightSetting, changeOneLightSetting } from "actions/lightActions";
import { queryOneSensorSetting, changeSensorOneSetting } from "actions/sensorActions";
import { queryDaliCtlOneSetting, changeDaliCtlOneSetting } from "actions/daliCtlActions";


export default function InterfaceMesh255Panel({ ttyS_no, deviceID, deviceObj, deviceType }) {
  const [state, dispatch] = useContext(SMContext);

  let ttyObj;
  if (deviceType === "gateway") ttyObj = deviceObj?.[ttyS_no];
  else ttyObj = deviceObj;
  return (
    <>
      <SpaceBetweenDiv title="type" data="Mesh 255" hideComponent={deviceType !== "gateway"} />
      <SpaceBetweenDiv
        title="Mesh Mac Address"
        data={ttyObj?.meshMacAddress}
        handleRefresh={async () => {
          if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshMacAddress", ttyS_no)
          else if (deviceType === "light") queryOneLightSetting(deviceObj, 0x03a0);
          else if (deviceType === "sensor") queryOneSensorSetting(deviceObj, 0x07a0);
          else if (deviceType === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0ba0);
        }}
      />
      <SpaceBetweenDiv
        title="Mesh defined address"
        data={deviceObj?.meshDefinedAdd?.toString(16).padStart(4, "0")}
        handleRefresh={async () => {
          if (deviceType === "light") await queryOneLightSetting(deviceObj, 0x03a7);
          else if (deviceType === "sensor") await queryOneSensorSetting(deviceObj, 0x07a7);
          else if (deviceType === "daliCtl") await queryDaliCtlOneSetting(deviceObj, 0x0ba7);
        }}
        hideComponent={deviceType === "gateway"}
      />
      <SpaceBetweenSelect
        title="Mesh channel"
        data={Number(ttyObj?.meshChannel ?? 99) ?? 99}
        menuObj={meshChannelMap}
        handleRefresh={async () => {
          if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshChannel", ttyS_no);
          else if (deviceType === "light") await queryOneLightSetting(deviceObj, 0x03a2);
          else if (deviceType === "sensor") await queryOneSensorSetting(deviceObj, 0x07a2);
          else if (deviceType === "daliCtl") await queryDaliCtlOneSetting(deviceObj, 0x0ba2);
        }}
        onChange={async (e) => {
          const newCh = Number(e.target.value);
          if (newCh === Number(ttyObj?.meshChannel)) return;
          confirmWindow(dispatch, `Confirm change mesh channel from ${ttyObj?.meshChannel} to ${newCh}`, async () => {
            if (deviceType === "gateway") gatewayChangeMeshParameter(deviceID, "meshChannel", newCh, ttyS_no);
            else if (deviceType === "light") await changeOneLightSetting(deviceObj, 0x02a2, { meshChannel: newCh });
            else if (deviceType === "sensor") await changeSensorOneSetting(deviceObj, 0x06a2, { meshChannel: newCh });
            else if (deviceType === "daliCtl") await changeDaliCtlOneSetting(deviceObj, 0x0aa2, { meshChannel: newCh });
          });

        }}
      />
      <SpaceBetweenSelect
        title="Mesh frequency"
        data={Number(ttyObj?.meshFreq ?? 99) ?? 99}
        handleRefresh={async () => {
          if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshFreq", ttyS_no);
          else if (deviceType === "light") queryOneLightSetting(deviceObj, 0x03a3)
          else if (deviceType === "sensor") queryOneSensorSetting(deviceObj, 0x07a3)
          else if (deviceType === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0ba3)
        }}
        menuObj={meshFreqMap}
        onChange={async (e) => {
          const code = Number(e.target.value);
          if (code === Number(ttyObj?.meshFreq)) return;
          confirmWindow(dispatch, `Confirm change mesh frequency from ${ttyObj?.meshFreq} to ${code}`, async () => {
            if (deviceType === "gateway") gatewayChangeMeshParameter(deviceID, "meshFreq", code, ttyS_no);
            else if (deviceType === "light") await changeOneLightSetting(deviceObj, 0x02a3, { meshFreq: code });
            else if (deviceType === "sensor") await changeSensorOneSetting(deviceObj, 0x06a3, { meshFreq: code });
            else if (deviceType === "daliCtl") await changeDaliCtlOneSetting(deviceObj, 0x0aa3, { meshFreq: code });
          });
        }}
      />
      <SpaceBetweenSelect
        title="Mesh speed"
        data={Number(ttyObj?.meshSpeed ?? 99) ?? 99}
        handleRefresh={async () => {
          if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshSpeed", ttyS_no);
          else if (deviceType === "light") queryOneLightSetting(deviceObj, 0x03a4)
          else if (deviceType === "sensor") queryOneSensorSetting(deviceObj, 0x07a4)
          else if (deviceType === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0ba4)
        }}
        menuObj={meshSpeedMap}
        onChange={async (e) => {
          const code = Number(e.target.value);
          if (code === Number(ttyObj?.meshSpeed)) return;
          confirmWindow(dispatch, `Confirm change mesh speed from ${ttyObj?.meshSpeed} to ${code}`, async () => {
            if (deviceType === "gateway") await gatewayChangeMeshParameter(deviceID, "meshSpeed", code, ttyS_no);
            else if (deviceType === "light") await changeOneLightSetting(deviceObj, 0x02a4, { meshSpeed: code });
            else if (deviceType === "sensor") await changeSensorOneSetting(deviceObj, 0x06a4, { meshSpeed: code });
            else if (deviceType === "daliCtl") await changeDaliCtlOneSetting(deviceObj, 0x0aa4, { meshSpeed: code });
          });
        }}
      />
      {/* <DivExist show={deviceType === "gateway"}>
        <SpaceBetweenSelect
          title="Mesh baudrate"
          data={Number(ttyObj?.meshBaudrate ?? 99) ?? 99}
          handleRefresh={async () => {
            if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshBaudrate", ttyS_no)
          }}
          menuObj={meshBaudrateMap}
          onChange={async (e) => {
            const code = Number(e.target.value);
            if (code === Number(ttyObj?.meshBaudrate)) return;
            confirmWindow(dispatch, `Confirm change mesh baudrate from ${ttyObj?.meshBaudrate} to ${code}`, async () => {
              if (deviceType === "gateway") gatewayChangeMeshParameter(deviceID, "meshBaudrate", code, ttyS_no);
            });
          }}
        />
      </DivExist> */}
      <SpaceBetweenSelect
        title="Mesh power"
        data={Number(ttyObj?.meshPower) ?? 99}
        handleRefresh={async () => {
          if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshPower", ttyS_no);
          if (deviceType === "light") queryOneLightSetting(deviceObj, 0x03a5);
          else if (deviceType === "sensor") queryOneSensorSetting(deviceObj, 0x07a5);
          else if (deviceType === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0ba5);
        }}
        menuObj={meshPowerDbmMap}
        onChange={async (e) => {
          const code = Number(e.target.value);
          if (code === Number(ttyObj?.meshPower)) return;
          confirmWindow(dispatch, `Confirm change mesh power from ${ttyObj?.meshPower} to ${code}`, async () => {
            if (deviceType === "gateway") gatewayChangeMeshParameter(deviceID, "meshPower", code, ttyS_no);
            else if (deviceType === "light") await changeOneLightSetting(deviceObj, 0x02a5, { meshPower: code });
            else if (deviceType === "sensor") await changeSensorOneSetting(deviceObj, 0x06a5, { meshPower: code });
            else if (deviceType === "daliCtl") await changeDaliCtlOneSetting(deviceObj, 0x0aa5, { meshPower: code });
          });
        }}
      />
      <SpaceBetweenDiv
        title="Mesh network ID"
        data={ttyObj?.meshNetworkID}
        handleRefresh={() => {
          if (deviceType === "light") queryOneLightSetting(deviceObj, 0x03a6)
          else if (deviceType === "sensor") queryOneSensorSetting(deviceObj, 0x07a6)
          else if (deviceType === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0ba6)
          else if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshNetworkID", ttyS_no);
        }}
      />
      <DivExist show={deviceType === "gateway"}>
        <SpaceBetweenSwitch
          title="Mesh extended head enable"
          data={ttyObj.meshExtended_head_enable == "1" ? true : false}
          handleRefresh={async () => {
            if (deviceType === "gateway") gatewayQueryMeshParameter(deviceID, "meshExtendedHeadEnable", ttyS_no);
          }}
          onChange={async (e) => {
            let val = e.target.checked ? 1 : 0;
            if (deviceType === "gateway") await gatewayChangeMeshParameter(deviceID, "meshExtendedHeadEnable", val, ttyS_no);
          }}
        />
      </DivExist>
      <SpaceBetweenButton
        title="Restart mesh module"
        btnContent={<ArrowForwardIos />}
        btnSize="small"
        onBtnClick={async () => {
          if (deviceType === "light") {
            await queryOneLightSetting(deviceObj, 0x2aa);
          } else if (deviceType === "sensor") {
            await queryOneSensorSetting(deviceObj, 0x06aa);
          } else if (deviceType === "daliCtl") {
            await queryDaliCtlOneSetting(deviceObj, 0x0aaa);
          } else if (deviceType === "gateway") {
            await gatewayQueryMeshParameter(deviceID, "restartMeshModule", ttyS_no);
          }
        }}
      />
      <DivExist show={deviceType === "gateway"}>
        <TestByteModal gatewayID={deviceID} ttyS_no={ttyS_no} type={ttyObj.ttyS_type} />
      </DivExist>
    </>
  );
}
