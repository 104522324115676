import DaliCtl from "asset/svgComp/DaliCtl";
import { deviceConnected } from "actions/generalActions";
import Bypass from "asset/svgComp/Bypass";
import OnOffIcon from "asset/svgComp/OnOffIcon";

/**
 *
 * @param {number} param0.width
 * @param {"vw"|"px"} param0.sizeUnit
 * @param {boolean} param0.connectStatus use this if daliCtlObj is not provided
 * @param {boolean} param0.disabledConnectStatus
 * @param {Object} param0.daliCtlObj use together with gatewayConnected
 * @param {boolean} param0.gatewayConnected
 * @param {boolean} param0.powerOnOffStatus
 * @param {boolean} param0.disabledPowerOnOffStatus
 * @returns
 */
export default function DaliCtlIcon({
  width = 3,
  connectStatus,
  sizeUnit = "vw",
  disabledConnectStatus,
  daliCtlObj,
  gatewayConnected,
  powerOnOffStatus,
  disabledPowerOnOffStatus,
  bypass
}) {
  let connectStatusUse;
  let powerOnOffStatusUse;
  let bypassUse;
  if (daliCtlObj) {
    connectStatusUse = deviceConnected(daliCtlObj?.zigbeeConnected, daliCtlObj?.timeStamp, gatewayConnected);
    powerOnOffStatusUse = daliCtlObj?.powerOnOffStatus;
    bypassUse = daliCtlObj?.bypass;
  } else {
    connectStatusUse = connectStatus;
    powerOnOffStatusUse = powerOnOffStatus;
    bypassUse = bypass;
  }

  return (
    <div style={{ position: "relative" }}>
      <DaliCtl
        width={width}
        sizeUnit={sizeUnit}
        connectStatus={connectStatusUse}
        disabledConnectStatus={disabledConnectStatus}
      />
      {
        !powerOnOffStatusUse && !disabledPowerOnOffStatus && (
          <div style={{ position: "absolute", top: 0, left: `${width * 0.8}${sizeUnit}` }}>
            {/* <Bypass width={width / 2} sizeUnit={sizeUnit} /> */}
            <OnOffIcon width={width * 0.3} sizeUnit={sizeUnit} onoff="off" />
          </div>
        )
      }
      {
        bypassUse ? (
          <div style={{ position: "absolute", top: `${width * 0.6}${sizeUnit}`, left: `${width * 0.8}${sizeUnit}` }}>
            <Bypass width={width * 0.5} sizeUnit={sizeUnit} />
          </div>)
          : null
      }
    </div>
  );
}
