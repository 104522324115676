import { useContext, Fragment } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, Box } from "@mui/material";
import {
  ArrowBack,
  ArrowForward,
  Lock,
  LockOpen,
  Router,
} from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import Space from "components/Space";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import DivCol from "components/DivCol";
import DivSpaceBetween from "components/DivSpaceBetween";
import AdminContainer from "components/AdminContainer";
import ZoneControlIcon from "components/ZoneControlIcon";
import { updateZcProperty } from "actions/zcActions";
import ChangeLockLvLightSwModal from "./ChangeLockLvLightSwModal";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
export default function ControlDevicesZcSwitchPanel() {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeZoneControlID,
    mapObjAll,
    lightObjAll,
    activeMapID,
    zoneControlObjAll,
  } = state;
  const zoneControlObj = zoneControlObjAll[activeZoneControlID];
  const activeMapObj = mapObjAll[activeMapID];
  const { setting1, setting2, type } = zoneControlObj || {};
  const lightArrayAll = Object.keys(setting1 || {});
  const lightArrayInMap = lightArrayAll.filter((s) =>
    Object.keys(activeMapObj.lightObj || {}).includes(s)
  );
  return (
    <>
      <DivInline justifyContent="center" alignItems="center">
        <Typography variant="h6">Control devices</Typography>
        <GroupActionConfirmOneGroup groupDeviceObj={zoneControlObj} settingNumArray={[1, 2]} />
      </DivInline>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>
          <Lock />
          Priority Level
        </Typography>
        <ChangeLockLvLightSwModal zoneControlObj={zoneControlObj} />
      </DivSpaceBetween>
      <Divider10 />
      <DivCol style={{ width: "100%" }} alignItems="flex-start">
        {lightArrayInMap.map((s, key) => {
          const lightObj = lightObjAll[s];
          return (
            <Fragment key={key}>
              <LightRow
                zoneControlObj={zoneControlObj}
                serial={s}
                lightObj={lightObj}
                set1={setting1?.[s]}
                set2={setting2?.[s]}
              />
            </Fragment>
          );
        })}
      </DivCol>
      <Divider10 />
    </>
  );
}

function LightRow({ zoneControlObj, serial, lightObj, set1, set2 }) {
  const [state] = useContext(SMContext);
  const zoneControlID = zoneControlObj?.zoneControlID;
  const lightStyle = lightObj?.style;
  const { description, dtkAdd } = lightObj || {};
  let gatewayMsg = ` - ${set1.gatewayID}`;
  if (lightObj.gatewayID !== set1.gatewayID) {
    gatewayMsg += ` (light gateway ${lightObj.gatewayID}, not match!)`;
  }
  const isDim = lightObj?.type?.pwm;
  const serialSetting = { off: set1, on: set2 }
  return (
    <>
      <DivInline>
        <Typography sx={{ marginRight: "1vw" }} onClick={() => {
          if (state.userObj.level > 0) return;
          alert(JSON.stringify(serialSetting));
        }}>{description}</Typography>
        <GroupActionConfirmOneDevice deviceObj={zoneControlObj} controlledDeviceObj={lightObj} settingNumArray={[1, 2]} />
      </DivInline>
      <DivInline alignItems="center">
        <Typography variant="caption">{`\u{01f194}${serial}`} </Typography>
        <Space width={10} />
        <Typography variant="caption">@{dtkAdd}</Typography>
        <Space width={10} />
        <Router sx={{ fontSize: "1rem" }} />
        <Typography variant="caption">{set1?.gatewayID}</Typography>
      </DivInline>
      <AdminContainer>
        <DivInline>
          <DivExist show={lightObj.gatewayID !== set1.gatewayID}>
            <Typography variant="caption">{gatewayMsg}</Typography>
            <Typography
              variant="caption"
              sx={{
                color: "cyan",
                cursor: "pointer",
                outline: "1px solid cyan",
                marginLeft: "1vw",
                padding: "0 0.6vw",
              }}
              onClick={() => {
                updateZcProperty(zoneControlID, {
                  [`setting1.${serial}.gatewayID`]: lightObj.gatewayID,
                  [`setting2.${serial}.gatewayID`]: lightObj.gatewayID,
                });
              }}
            >
              fix
            </Typography>
          </DivExist>
        </DivInline>
      </AdminContainer>
      <DivInline style={{ width: "100%" }} justifyContent="space-around">
        <ZoneControlIcon width={2} sizeUnit="vw" type="zcSwitch" status={1} />
        <LightIcon
          statusA={set1?.onoffcontrolA}
          lightStyle={lightStyle}
          width={2.5}
          sizeUnit="vw"
          lightLv={set2?.pwm}
          disabledConnectStatus
        />
        <DivExist show={isDim && set2?.onoffcontrolA === 0}>
          <Typography style={{ width: "3vw" }}>{set2?.pwm}%</Typography>
        </DivExist>
        <Lock fontSize="small" />
        <Typography>{set2?.lockLevel}</Typography>
        <Space width={10} />
        <ArrowBack />
        <ArrowForward />
        <Space width={10} />

        <ZoneControlIcon width={2} sizeUnit="vw" type="zcSwitch" status={0} />
        <LightIcon
          statusA={set1?.onoffcontrolA}
          lightStyle={lightStyle}
          width={2.5}
          lightLv={set1?.pwm}
          sizeUnit="vw"
          disabledConnectStatus
        />
        <DivExist show={isDim && set1?.onoffcontrolA === 0}>
          <Typography style={{ width: "3vw" }}>{set1?.pwm}%</Typography>
        </DivExist>
        {set1?.releaseOnDelayEnd === 1 ? (
          <LockOpen fontSize="small" />
        ) : (
          <>
            <Lock fontSize="small" />
            <Typography>{set1?.lockLevel}</Typography>
          </>
        )}
      </DivInline>
      <Divider10 space={3} light />
    </>
  );
}
