import { Fragment, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Select, MenuItem, Box } from "@mui/material";
import { titleText, dataText, data2Text } from "asset/string/color";
import GatewayIcon from "components/GatewayIcon";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import DeviceMongoModal from "components/DeviceMongoModal";
import { espCheckUnicast, queryEspNowRoute, setEspNowRoute, updateGatewayProperty } from "actions/gatewayActions";
import EspNowRouteModal from "containers/gateway/EspNowRouteModal";
import DeviceIcon from "components/DeviceIcon";
import { deviceInfoOfObj } from "actions/generalActions";
import IconButtonDelete from "components/IconButtonDelete";
import Divider10 from "components/Divider10";
import ThemeContainer from "components/ThemeContainer";
import { ArrowDownward } from "@mui/icons-material";
import IconButtonDone from "components/IconButtonDone";
import IconButtonClose from "components/IconButtonClose";
import DivCol from "components/DivCol";
const gs = new General();
export default function EspWebInfo() {
    const [state, dispatch] = useContext(SMContext);
    const {
        gatewayObjAll,
        espWebGatewayID,
        espWebDeviceID,
        lightObjAll,
        sensorObjAll,
        daliCtlObjAll,
        espWebMapMode,
        espCheckUnicastSender,
        espCheckUnicastReceiver,
        espWebPathTargetDevice,
        espWebPathArray,
    } = state;
    const gatewayObj = gatewayObjAll[espWebGatewayID] || {};
    const gatewayMac = gatewayObj?.ttyS7?.macAddress || "";
    const deviceObjAll = { ...lightObjAll, ...sensorObjAll, ...daliCtlObjAll, ...gatewayObjAll };
    const deviceObj = lightObjAll[espWebDeviceID] || sensorObjAll[espWebDeviceID] || daliCtlObjAll[espWebDeviceID] || {};
    const deviceInfo = deviceInfoOfObj(deviceObj);
    const deviceMac = deviceObj?.wifiApMacAddress || "";
    const routeObj = gatewayObj?.espNowRoute?.[deviceMac]?.['1'] || {};
    const distance = routeObj?.distance || 0;
    const path = routeObj?.path || [];
    const length = routeObj?.length || 0;
    const macDeviceIdMap = useMemo(() => {
        const macDeviceIdMap = {}
        for (const [deviceId, deviceObj] of Object.entries(lightObjAll)) {
            const mac = deviceObj?.wifiApMacAddress;
            if (mac) macDeviceIdMap[mac] = deviceId;
        }
        for (const [deviceId, deviceObj] of Object.entries(sensorObjAll)) {
            const mac = deviceObj?.wifiApMacAddress;
            if (mac) macDeviceIdMap[mac] = deviceId;
        }
        for (const [deviceId, deviceObj] of Object.entries(daliCtlObjAll)) {
            const mac = deviceObj?.wifiApMacAddress;
            if (mac) macDeviceIdMap[mac] = deviceId;
        }
        return macDeviceIdMap
    }, [lightObjAll, sensorObjAll, daliCtlObjAll])
    const devicePath = path.map(m => macDeviceIdMap[m] || "unknown")
    return (
        <>
            <Typography align="center" style={{ color: titleText }}>
                ESP Web
            </Typography>
            <hr color="lightgrey" size={1} width="90%" />
            <DivInline justifyContent="center">
                <DeviceMongoModal deviceObj={gatewayObj} >
                    <GatewayIcon width={8} sizeUnit="vw" disabledConnectStatus />
                </DeviceMongoModal>
            </DivInline>
            <DataSet title="Gateway ID" data={espWebGatewayID || "-"} />
            <DataSet title="Gateway mac address" data={gatewayMac || "-"} />
            <ThemeContainer background="light">
                <Select
                    variant="outlined"
                    disabled={!espWebGatewayID}
                    value={espWebMapMode}
                    size="small"
                    onChange={(e) => {
                        const value = e.target.value;
                        if (value === "check") {
                            dispatch({
                                type: "SET_GENERAL_MANY_STATE",
                                payload: { espCheckUnicastSender: {}, espCheckUnicastReceiver: {}, espWebDeviceID: "", espWebMapMode: value }
                            });
                            updateGatewayProperty(gatewayObj, { espUsability: {} }, false);
                        } else {
                            dispatch({
                                type: "SET_GENERAL_STATE",
                                payload: { key: "espWebMapMode", value }
                            });
                        }
                    }}
                >

                    <MenuItem value="route">Routes</MenuItem>
                    <MenuItem value="check">Check Unicast</MenuItem>
                    <MenuItem value="setPath">Set path</MenuItem>
                </Select >
                <Divider10 />
            </ThemeContainer>
            <DivExist show={espWebMapMode === "route" && espWebGatewayID}>
                <DivExist show={espWebDeviceID ? true : false}>
                    <hr color="lightgrey" size={1} width="90%" />
                    <DivInline>
                        <DeviceIcon width={2.5} sizeUnit="vw" deviceType={deviceInfo.deviceType} deviceStyle={deviceObj.style || deviceObj.type} gang={deviceObj.gang} />
                        <IconButtonDelete color="warning" onBtnClick={() => dispatch({ type: "SET_GENERAL_STATE", payload: { key: "espWebDeviceID", value: "" } })} />
                    </DivInline>
                    <DataSet title="Device ID" data={espWebDeviceID} />
                    <DivExist show={length > 1}>
                        <DataSet title="Device mac address" data={deviceMac} />
                        <DataSet title="Distance" data={distance} />
                        <DataSet title="Jump" data={length - 1} />
                        {/* <DataSet title="Mac Path" data={path.join(' >')} /> */}
                        <DataSet title="ID Path" data={"GW >" + devicePath.join(' >')} />
                    </DivExist>
                    <DivExist show={length <= 1 || !length}>
                        <Typography sx={{ color: titleText }}>No route info</Typography>
                        <hr color="lightgrey" size={1} width="90%" />
                    </DivExist>
                </DivExist>
                <Button variant="outlined" onClick={() => queryEspNowRoute(espWebGatewayID)}>Refresh route data</Button>
                <EspNowRouteModal gatewayObj={gatewayObj} btnContent="Route Table" />
            </DivExist>
            <hr color="lightgrey" size={1} width="90%" />

            <DivExist show={espWebMapMode === "check" && espWebGatewayID}>
                <DataSet2 title="Sender" data={espCheckUnicastSender?.deviceID} data2={deviceObjAll[espCheckUnicastSender?.deviceID]?.wifiApMacAddress} />
                <DataSet2 title="Receiver" data={espCheckUnicastReceiver?.deviceID} data2={deviceObjAll[espCheckUnicastReceiver?.deviceID]?.wifiApMacAddress} />
                <IconButtonDelete
                    hide={gs.isEmptyJson(espCheckUnicastSender) && gs.isEmptyJson(espCheckUnicastReceiver)}
                    color="warning"
                    onBtnClick={() => {
                        dispatch({
                            type: "SET_GENERAL_MANY_STATE",
                            payload: { espCheckUnicastSender: {}, espCheckUnicastReceiver: {} }
                        });
                        updateGatewayProperty(gatewayObj, { espUsability: {} }, false);
                    }}
                />
                <Button variant="outlined" disabled={!espCheckUnicastSender?.deviceID || !espCheckUnicastReceiver?.deviceID} onClick={() => {
                    espCheckUnicast(espWebGatewayID, espCheckUnicastSender, espCheckUnicastReceiver, dispatch);
                }}>Check</Button>
            </DivExist>
            <DivExist show={espWebMapMode === "setPath" && espWebGatewayID}>
                <DivExist show={gs.isEmptyJson(espWebPathTargetDevice) || !espWebPathTargetDevice}>
                    <Typography variant="caption">Choose target device</Typography>
                </DivExist>
                <DivExist show={!gs.isEmptyJson(espWebPathTargetDevice)}>
                    <DataSet2 title="Target Device" data={`\u{01f194}${espWebPathTargetDevice?.deviceID}`} data2={`mac ${espWebPathTargetDevice.wifiApMacAddress}`} />
                    {espWebPathArray.length === 0 ?

                        <Typography sx={{ color: titleText }} variant="caption">Choose path</Typography>
                        :
                        <div>
                            <Typography sx={{ color: titleText }} variant="caption">Path</Typography>
                            <GatewayIcon width={2} sizeUnit="vw" disabledConnectStatus />
                            <ArrowDownward sx={{ fontSize: "small" }} />

                        </div>
                    }

                    {
                        (espWebPathArray || []).map((obj, key) => {
                            return (
                                <DivCol key={key} alignItems="center">
                                    <DivInline>
                                        <Typography sx={{ color: dataText }} variant="caption" >{`\u{01f194}${obj?.deviceID}`}</Typography>
                                        <IconButtonDelete size="small" color="warning" onBtnClick={() => {
                                            let arr = espWebPathArray.filter(item => item.deviceID !== obj.deviceID);
                                            dispatch({ type: "SET_GENERAL_STATE", payload: { key: "espWebPathArray", value: arr } })
                                        }} />
                                    </DivInline>
                                    <Typography display="block" sx={{ color: data2Text }} variant="caption" >{`mac ${obj.wifiApMacAddress || "-"}`}</Typography>
                                    <ArrowDownward sx={{ fontSize: "small" }} />
                                </DivCol>
                            )
                        })
                    }
                    <Typography variant="caption">Target device</Typography>
                    <ThemeContainer background="light">
                        <Divider10 />
                        <DivInline>
                            <IconButtonDone onBtnClick={() => {
                                setEspNowRoute(espWebGatewayID, espWebPathTargetDevice, espWebPathArray);
                                dispatch({ type: "SET_GENERAL_MANY_STATE", payload: { espWebMapMode: "route", espWebPathTargetDevice: {}, espWebPathArray: [] } })
                            }} />
                            <IconButtonClose onBtnClick={() => {
                                dispatch({ type: "SET_GENERAL_MANY_STATE", payload: { espWebPathTargetDevice: {}, espWebPathArray: [] } })
                            }} />
                        </DivInline>
                    </ThemeContainer>
                </DivExist>
            </DivExist>
        </>
    )
}


function DataSet({ title, data }) {
    return (
        <>
            <Typography
                variant="caption"
                display="block"
                align="center"
                sx={{ color: titleText }}
            >
                {title}
            </Typography>
            <Typography
                sx={{ color: dataText }}
                display="block"
                align="center"
                variant="caption"
            >
                {data}
            </Typography>
            <hr color="lightgrey" size={1} width="90%" />
        </>
    );
}
function DataSet2({ title, data, data2 }) {
    return (
        <>
            <Typography variant="caption" display="block" align="center" sx={{ color: titleText }} >
                {title}
            </Typography>
            <Typography sx={{ color: dataText }} display="block" align="center" variant="caption"  >
                {data}
            </Typography>
            <Typography sx={{ color: data2Text }} display="block" align="center" variant="caption"  >
                {data2}
            </Typography>
            <hr color="lightgrey" size={1} width="90%" />
        </>
    )
}

