import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import DivExist from "./DivExist";
import IconButtonRefresh from "./IconButtonRefresh";
import DivInline from "./DivInline";

export default function SpaceBetweenDiv({
  title,
  data,
  titleColor,
  dataColor,
  dataVariant,
  titleVariant,
  marginBottom,
  hideComponent,
  handleRefresh,
}) {
  return (
    <DivExist show={!hideComponent}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: marginBottom || 5,
        }}
      >
        <DivInline>
          {typeof title === "string" ? (
            <Typography
              variant={titleVariant || "body1"}
              color={titleColor || "inherit"}
            >
              {title}
            </Typography>
          ) : (
            <span style={{ color: dataColor || "white" }}>{title}</span>
          )}
          <DivExist show={handleRefresh ? true : false}>
            <IconButtonRefresh onBtnClick={handleRefresh} />
          </DivExist>
        </DivInline>
        {typeof data === "string" ? (
          <Typography
            variant={dataVariant || "body1"}
            color={dataColor || "inherit"}
          >
            {data}
          </Typography>
        ) : (
          <span style={{ color: dataColor || "white" }}>{data}</span>
        )}
      </div>
    </DivExist>
  );
}

SpaceBetweenDiv.propTypes = {
  title: PropTypes.any.isRequired,
  data: PropTypes.any,
  titleColor: PropTypes.string,
  dataColor: PropTypes.string,
  titleVariant: PropTypes.string,
  dataVariant: PropTypes.string,
  marginBottom: PropTypes.any,
  hideComponent: PropTypes.bool,
};
