import { Box, Typography } from "@mui/material";

export default function MapThumbnail({
  mapID,
  mapObj,
  chooseMap,
  currentMapID,
  height = "11vh",
  minWidth = "12vw",
  margin = "0.5vw 0.5vw 0 0.5vw",
}) {
  const bgUrl = mapObj.mapFileName
    ? `${global.ip}/img/${mapObj.mapFileName}`
    : mapObj.mapUrl;

  return (
    <Box
      sx={{
        margin,
        cursor: "pointer",
        flexShrink: 0,
        flexBasis: minWidth,
        height,
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.7) ), url(${bgUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        outline: currentMapID === mapID ? "1.5px solid #fff" : "",
        "&:hover": {
          outline: "1.5px solid #fff",
          outlineOffset: "-1px",
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4) ), url(${bgUrl})`,
        },
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
        padding: "0.5vw",
      }}
      onClick={() => chooseMap(mapID)}
    >
      <Typography variant="caption">{mapObj?.mapName}</Typography>
    </Box>
  );
}
