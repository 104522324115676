export const mapModeStr = {
  normal: "normal",
  sceneEdit: "sceneEdit",
  sensorEdit: "sensorEdit",
  luxProfile: "luxProfile",
  lightConnect: "lightConnect",
  areaConnect: "areaConnect",
  multiSelect: "multiSelect",
  rssiLine: "rssiLine",
  rssiWeb: "rssiWeb",
  shiftSelect: "shiftSelect",
};
export const mapRightStr = {
  normal: "normal",
  light: "light",
  sensor: "sensor",
  gateway: "gateway",
  zoneControl: "zoneControl",
  repeater: "repeater",
  daliCtl: "daliCtl",
  shiftSelected: "shiftSelected",
  rssiWeb: "rssiWeb",
  server: "server",
};
let pwmSliderMarks = [];
for (let i = 0; i <= 100; i += 20) {
  const obj = { value: i, label: `${i}%` };
  pwmSliderMarks = [...pwmSliderMarks, obj];
}

let cursor = {
  pointer: "pointer",
  move: "move",
  default: "default",
  grab: "grab",
  notAllowed: "not-allowed",
};

export const lightStyleMenu = {
  tube: "tube fixture",
  owl: "emergency battery light",
  exit: "exit sign",
  // indicator: "indicator light",
  downlight: "downlight",
  panellight: "panel light",
  tubeHalf: "tube fixture (half icon)",
};

export const sensorStyleMenu = {
  motion: "motion",
  daylight: "day light harvest sensor",
  daylightmotion: "day light motion sensor",
  // photo: "light",
  // fire: "fire",
  // breakglass: "breakglass",
  // smoke: "smoke",
  // nursecall: "nursecall",
  lightswitch: "light switch",
  scenebutton: "scene button",
  dimcontroller: "dim controller",
};
export const sensorTypeOf = {
  motion: 0,
  daylight: 1,
  lightswitch: 2,
  daylightmotion: 3,
  scenebutton: 4,
  dimcontroller: 5,
};

const userLevel = {
  0: "superAdmin",
  1: "admin",
  2: "notify",
  3: "viewer",
};

const testType = {
  check_1month: "Monthly test",
  check_6month: "6-Month test",
  check_12month: "Yearly test",
  check_36month: "3-Year test",
  check_manual: "Manual test",
};

export { pwmSliderMarks, cursor, userLevel, testType };

export const ppa104aEd4 = [
  ["Test type", "Battery discharge"],
  ["Monthly", "Less than 1/4 rated duration"],
  ["6-monthly", "1/4 rated duration"],
  ["3-yearly", "Fully discharge"],
];
export const ppa104aEd5 = [
  ["Test type", "Battery discharge"],
  ["Monthly", "Less than 10% rated duration"],
  ["Yearly", "Fully discharge"],
];

export const weekDayIsoMenu = {
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
  7: "Sun",
};
export const dayMenu = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31,
];
export const hourMenu = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];
export const minuteMenu = {
  0: 0,
  5: 5,
  10: 10,
  15: 15,
  20: 20,
  25: 25,
  30: 30,
  35: 35,
  40: 40,
  45: 45,
  50: 50,
  55: 55,
};
// export const zoneControlIconMenu = {
//   default: "default",
//   greenPwr: "green power",
//   redPwr: "red power",
//   greyPwr: "grey power",
//   clock: "clock",
// };

export const batteryTypeMenu = {
  nimh: "Ni-Mh",
  nicd: "Ni-Cd",
  lipolymer: "Lithium polymer",
  liion: "Lithium ion",
  pbacid: "Lead-acid",
};
export const zoneCtlDefault = {
  zcSwitch: {
    icon1: "zcSwitch",
    icon2: "redPwrSq",
    typeName: "vitural light switch",
  },
  timer: { icon1: "clock", icon2: "-", typeName: "vitural time switch" },
  dimming: { icon1: "dimColor", icon2: "-", typeName: "group dimming" },
  zcSceneButton: {
    icon1: "scenebutton",
    icon2: "-",
    typeName: "vitural scene button",
  },
};
export const daliCmdJsonArray = [
  { cmd: 0, name: "Off" },
  { cmd: 1, name: "Up (fade up for 200ms)" },
  { cmd: 2, name: "Down (fade down for 200ms)" },
  { cmd: 3, name: "Step Up (+1 dali lv, cannot turn on)" },
  { cmd: 4, name: "Step Down (-1 dali lv, cannot turn off" },
  { cmd: 5, name: "Set to max lv" },
  { cmd: 6, name: "Set to min lv" },
  { cmd: 7, name: "Step Down 1 dali lv and off" },
  { cmd: 8, name: "On and step up 1 dali lv" },
  { cmd: 144, name: "Query status" },
  { cmd: 145, name: "Query control gear" },
  { cmd: 146, name: "Query lamp failure" },
  { cmd: 147, name: "Query lamp power on " },
  { cmd: 150, name: "Query missing short add" },
  { cmd: 151, name: "Query version number (Res:1)" },
  { cmd: 152, name: "Query content dtr" },
  { cmd: 153, name: "Query device type (Res:6)" },
  { cmd: 160, name: "Query actual level" },
  { cmd: 161, name: "Query max lv" },
  { cmd: 162, name: "Query min lv" },
  { cmd: 165, name: "Query fade time/fade rate" },
  { cmd: 237, name: "Query gear type" },
  { cmd: 238, name: "Query dimming curve (0:log, 1:linear)" },
];
export const daliFadeTimeMenu = {
  0: "0s",
  1: "0.7s",
  2: "1.0s",
  3: "1.4s",
  4: "2s",
  5: "2.8s",
  6: "4s",
  7: "5.7s",
  8: "8s",
  9: "11.3s",
  10: "16s",
  11: "22.6s",
  12: "32s",
  13: "45.3s",
  14: "64s",
  15: "90.5s",
};
export const dtkLoraParameterMenu = {
  0: "-",
  3: "0x03 [3] SF=12 slowest longest distance",
  4: "0x04 [4] SF=11",
  5: "0x05 [5] SF=10",
  6: "0x06 [6] SF=9",
  7: "0x07 [7] SF=8",
  8: "0x08 [8] SF=7",
  9: "0x09 [9] SF=6",
  10: "0x0A [10] SF=5",
  30: "0x1E [30] FSK fastest shortest distance",
};
export const dtkChannelMenu = {
  0: "-",
  20: "0x14 [20] 433M",
  21: "0x15 [21] 433M",
  22: "0x16 [22] 433M",
  23: "0x17 [23] 433M",
  24: "0x18 [24] 433M",
  25: "0x19 [25] 433M",
  26: "0x1A [26] 433M",
  27: "0x1B [27] 433M",
  28: "0x1C [28] 433M",
  29: "0x1D [29] 433M",
  30: "0x1E [30] 470M",
  31: "0x1F [31] 470M",
  32: "0x20 [32] 470M",
  33: "0x21 [33] 470M",
  34: "0x22 [34] 470M",
  35: "0x23 [35] 470M",
  36: "0x24 [36] 470M",
  37: "0x25 [37] 470M",
  38: "0x26 [38] 470M",
  39: "0x27 [39] 470M",
  40: "0x28 [40] 470M",
  41: "0x29 [41] 470M",
  42: "0x2A [42] 470M",
  43: "0x2B [43] 470M",
  44: "0x2C [44] 470M",
  45: "0x2D [45] 470M",
  46: "0x2E [46] 470M",
  47: "0x2F [47] 470M",
  48: "0x30 [48] 470M",
  49: "0x31 [49] 470M",
  50: "0x32 [50] 470M",
  51: "0x33 [51] 470M",
  52: "0x34 [52] 470M",
  53: "0x35 [53] 470M",
  54: "0x36 [54] 470M",
  55: "0x37 [55] 470M",
  56: "0x38 [56] 470M",
  57: "0x39 [57] 470M",
  58: "0x3A [58] 470M",
  59: "0x3B [59] 470M",
  60: "0x3C [60] 470M",
  61: "0x3D [61] 470M",
  62: "0x3E [62] 470M",
  63: "0x3F [63] 470M",
  64: "0x40 [64] 470M",
  65: "0x41 [65] 470M",
  66: "0x42 [66] 470M",
  67: "0x43 [67] 470M",
  68: "0x44 [68] 470M",
  69: "0x45 [69] 470M",
  70: "0x46 [70] 470M",
};
export const dtkTypeMenu = {
  0: "-",
  1: "1: coordinator",
  2: "2: router",
  3: "3: endpoint",
};
