import React from "react";
// import { SMContext } from "../context/smContext";
import { Tooltip } from "@mui/material";
import {
  Done,
  Clear,
  DoneAll,
  Sync,
  SyncProblem,
  Warning,
  AlarmOff,
} from "@mui/icons-material";

const ResultIcon = ({ result, size }) => {
  const styleOK = { color: "green", fontSize: size || "1em" };
  const styleFail = { color: "red", fontSize: size || "1em" };
  const styleWarn = { color: "gold", fontSize: size || "1em" };
  const styleTimeout = { color: "purple", fontSize: size || "1em" };
  return (
    <Tooltip title={result ? result : "no result"}>
      <span>
        {!result ? (
          <span>no result</span>
        ) : result === "OK" ? (
          <DoneAll style={styleOK} />
        ) : result.includes("FAIL") ? (
          <Clear style={styleFail} />
        ) : result === "ended" ? (
          <Done style={styleOK} />
        ) : result === "WARNING" ? (
          <Warning style={styleWarn} />
        ) : result === "TIMEOUT" ? (
          <AlarmOff style={styleTimeout} />
        ) : result === "started" ? (
          <Sync style={styleWarn} />
        ) : (
          <SyncProblem style={styleWarn} />
        )}
      </span>
    </Tooltip>
  );
};

export default ResultIcon;
