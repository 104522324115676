import { Typography } from "@mui/material";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export default function ChartLine({
    chartTitle = "",
    chartDataArray,
    chartLabel,
    lineTitleArray = ["Line1", "Line2"],
    yAxisIDArray = ["y", "y1"],
    background = "dark",
    chartWidth,
    chartHeight,
    hasY2Axis = false,
    xAxisTitle = "",
    yAxisTitleArray = ["", ""],
    // voltageAxesColor,
    legendPosition = "top",
    displayLegend = true,
}) {
    const textColor = background === "dark" ? "white" : "darkgrey";
    const lineColor = background === "dark" ? ["#a3e4d7", "#fad7a0", "a9dfbf", "#e6b0aa"] : ["#2874a6", "#b7950b", "#117a65", "#b03a2e"];
    let options = {
        responsive: true,
        stacked: false,
        plugins: {
            title: {
                display: chartTitle ? true : false,
                text: chartTitle,
                color: textColor,
            },
            legend: {
                display: displayLegend,
                position: legendPosition,
                labels: {
                    color: textColor,
                },
            },
        },

        scales: {
            x: {
                type: "linear",
                border: {
                    color: textColor,
                },
                ticks: {
                    color: textColor,

                },

                title: {
                    display: xAxisTitle ? true : false,
                    text: xAxisTitle,
                    color: textColor,
                }

            },
            y: {
                type: "linear",
                display: true,
                position: "left",
                border: {
                    color: textColor,
                },
                ticks: {
                    color: textColor,
                },
                title: {
                    display: yAxisTitleArray[0] ? true : false,
                    text: yAxisTitleArray[0],
                    color: textColor,
                }
            },
        },
    };
    if (hasY2Axis) {
        options.scales.y1 = {
            type: "linear",
            display: true,
            position: "right",
            grid: {
                drawOnChartArea: false,
            },
            border: {
                color: textColor,
            },
            ticks: {
                color: textColor,
            },
            title: {
                display: yAxisTitleArray[1] ? true : false,
                text: yAxisTitleArray[1],
                color: textColor,
            }
        }
    }
    const dataObj = {
        labels: chartLabel,
        datasets: chartDataArray.map((data, index) => ({
            label: lineTitleArray[index],
            data,
            yAxisID: yAxisIDArray[index],
            borderColor: lineColor[index],
        })),
    };
    if (chartDataArray.length >= 1) {
        return <Line options={options} data={dataObj} />
    } else {
        return <Typography variant="h4">No chart data</Typography>
    }
}