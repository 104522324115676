import { Group, Path } from "react-konva";
export default function DataUpload({
  x,
  y,
  size,
  backgroundColor = "",
  color = "#0b0",
}) {
  const scale = size / 320;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill={color}
        fillOpacity={1}
        data="M6.951 202.797s57.535 65.579 69.042 92.367h49.48c20.713-64.059 101.262-216.99 170.304-270.567 14.305-18.616-21.631-26.3-50.63-13.976C201.438 29.194 119.006 171 103.61 205.126c-21.863 5.824-44.877-37.27-44.877-37.27z"
      />
    </Group>
  );
}
