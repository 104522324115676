import { IconButton, Tooltip } from "@mui/material";
import { Add } from "@mui/icons-material";

export default function IconButtonAdd({ onBtnClick, tooltip, size, color }) {
  return (
    <IconButton onClick={onBtnClick} size={size || "large"} color={color}>
      <Tooltip title={tooltip || ""}>
        <Add />
      </Tooltip>
    </IconButton>
  );
}
