import { useState, useContext } from "react"
import { SMContext } from "context/smContext";
import { Button } from "@mui/material"
export default function ServerMonitorPanel() {
    const [state, dispatch] = useContext(SMContext);
    const { userObj } = state;
    const startMonitor = async () => {
        const payload = {
            type: "serverMonitorStart",
            topic: "disk_monitor",
            userRandomID: userObj.userRandomID
        };
        global.socket.emit("fromWeb", payload);
    }
    const stopMonitor = async () => {
        const payload = {
            type: "serverMonitorStop",
            topic: "disk_monitor",
            userRandomID: userObj.userRandomID
        };
        global.socket.emit("fromWeb", payload);
    }
    return (
        <>
            <Button variant="outlined" size='small' onClick={startMonitor}>Start</Button>
            <Button variant="outlined" size='small' onClick={stopMonitor}>Stop</Button>
        </>
    )
}