import { useState, Fragment } from "react";
import { Line, Rect } from "react-konva";
import DivExist from "components/DivExist";
import { confirmWindow } from "actions/screenActions";
import ZcTimerLightModal from "../modal/ZcTimerLightModal";
import ZcTimerAirconModal from "../modal/ZcTimerAirconModal";
import {
  zoneControlConnectLight,
  zoneControlConnectAircon,
} from "actions/zcActions";
import ZcSceneButtonLightModal from "../modal/ZcSceneButtonLightModal";
import ZcSwitchLightModal from "../modal/ZcSwitchLightModal";
export default function MapZcLightGroup({
  layerProps,
  zoneControlID,
  zoneControlObj,
}) {
  const {
    iconSize,
    mapScale,
    lightObjAll,
    dispatch,
    mapObjAll,
    activeMapID,
    enablePan,
    layerScale,
  } = layerProps;
  const lightMapObj = mapObjAll[activeMapID]?.lightObj;
  const [selectedLight, setSelectedLight] = useState("");
  const [deviceX, setDeviceX] = useState(0);
  const [deviceY, setDeviceY] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const { type, serialMap, zoneControlName } = zoneControlObj || {};
  const selectedLightObj = lightObjAll[selectedLight];
  const handleCloseModal = () => {
    setShowEditModal(false);
    setSelectedLight("");
  };
  const handleZcConnectLight = async (s) => {
    confirmWindow(dispatch, `Add ${s} to [${zoneControlName}]?`, async () => {
      const lightObj = lightObjAll[s];
      if (!lightObj.daliCtlID && !lightObj.dtkAdd) {
        alert("Missing dtkAdd, connect fail");
        return;
      }
      if (!lightObj.gatewayID) {
        alert("Missing gatewayID, connect fail");
        return;
      }
      if (lightObj.style === "airconControl") await zoneControlConnectAircon(zoneControlObj, s, lightObj);
      else await zoneControlConnectLight(zoneControlObj, s, lightObj);
    });
  };
  return (
    <>
      {Object.keys(lightMapObj || {}).map((s) => {
        const netX = lightMapObj?.[s]?.x * mapScale.x;
        const netY = lightMapObj?.[s]?.y * mapScale.y;
        const serialArray = Object.values(serialMap || {}) || [];
        const isUnderZc = serialArray?.includes(s);
        let iconColor = isUnderZc ? "#F1948A" : "#52BE80";
        const lightObj = lightObjAll[s];
        const lightType = lightObj?.type;
        return (
          <Fragment key={s}>
            <>
              <DivExist
                show={
                  (type === "zcSwitch" && lightType?.A) ||
                  (type === "dimming" && lightType?.pwm) ||
                  type === "timer" ||
                  type === "zcSceneButton"
                }
              >
                <Rect
                  x={netX}
                  y={netY}
                  width={iconSize * mapScale.x}
                  height={iconSize * mapScale.y}
                  fill={iconColor}
                  opacity={0.5}
                  onMouseOver={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "pointer" : "move";
                  }}
                  onMouseLeave={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "grab" : "default";
                  }}
                  onClick={async (e) => {
                    e.evt.preventDefault();
                    setDeviceX(lightMapObj[s]?.x);
                    setDeviceY(lightMapObj[s]?.y);
                    if (isUnderZc) {
                      setSelectedLight(s);
                      setShowEditModal(true);
                    } else {
                      await handleZcConnectLight(s);
                    }
                  }}
                  stroke={selectedLight === s ? "#2C3E50" : ""}
                  strokeWidth={6 / layerScale}
                />
              </DivExist>
              <DivExist
                show={lightObj?.masterSerial}
              >
                <Rect
                  x={netX}
                  y={netY}
                  width={iconSize * mapScale.x}
                  height={iconSize * mapScale.y}
                  fill="grey"
                  opacity={0.3}
                />
                <Line
                  x={netX}
                  y={netY}
                  points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
                  stroke="red"
                  strokeWidth={2 / layerScale}
                />
              </DivExist>
            </>
          </Fragment>
        );
      })}
      <DivExist show={type === "zcSceneButton"}>
        <ZcSceneButtonLightModal
          x={deviceX}
          y={deviceY}
          open={showEditModal && type === "zcSceneButton"}
          handleClose={handleCloseModal}
          layerProps={layerProps}
          selectedLight={selectedLight}
          zoneControlObj={zoneControlObj}
        />
      </DivExist>
      <DivExist
        show={type === "timer" && selectedLightObj?.style !== "airconControl"}
      >
        <ZcTimerLightModal
          x={deviceX}
          y={deviceY}
          open={showEditModal && type === "timer"}
          handleClose={handleCloseModal}
          layerProps={layerProps}
          selectedLight={selectedLight}
          zoneControlID={zoneControlID}
          zoneControlObj={zoneControlObj}
        />
      </DivExist>
      <DivExist
        show={type === "timer" && selectedLightObj?.style === "airconControl"}
      >
        <ZcTimerAirconModal
          x={deviceX}
          y={deviceY}
          open={showEditModal && type === "timer"}
          handleClose={handleCloseModal}
          layerProps={layerProps}
          selectedLight={selectedLight}
          zoneControlID={zoneControlID}
          zoneControlObj={zoneControlObj}
        />
      </DivExist>
      <DivExist show={type === "zcSwitch"}>
        <ZcSwitchLightModal
          x={deviceX}
          y={deviceY}
          open={showEditModal && type === "zcSwitch"}
          handleClose={handleCloseModal}
          layerProps={layerProps}
          selectedLight={selectedLight}
          zoneControlID={zoneControlID}
          zoneControlObj={zoneControlObj}
        />
      </DivExist>
    </>
  );
}
