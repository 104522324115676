import { useContext } from "react";
import { SMContext } from "context/smContext";
import { ButtonGroup, Typography, Button } from "@mui/material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import { setServerConsoleLogFilter, clearServerConsoleLogFilter } from "actions/serverActions";
import DivSpaceBetween from "components/DivSpaceBetween";

export default function ServerConsoleLogFilter({ filterString }) {
    const [state, dispatch] = useContext(SMContext);
    const localserverUrl = state?.serverObj?.localserverUrl
    return (
        <DivSpaceBetween>
            <Typography>Filter console log in this device</Typography>
            <ButtonGroup variant="outlined" size="small">
                <Button onClick={() =>
                    setServerConsoleLogFilter(localserverUrl, filterString)
                }
                >
                    on
                </Button>
                <Button onClick={() =>
                    clearServerConsoleLogFilter(localserverUrl)
                }
                >
                    off
                </Button>
            </ButtonGroup>
        </DivSpaceBetween>
    )
}