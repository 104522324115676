import { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  Tooltip,
  Box,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Button,
  ButtonGroup,
} from "@mui/material";
import { SettingsRemote } from "@mui/icons-material";
import General from "@ecoenghk/general";
import DivInline from "components/DivInline";
import ModalSM from "components/ModalSM";
import Divider10 from "components/Divider10";
import IconButtonDelete from "components/IconButtonDelete";
const gs = new General();

export default function CommandConvertModal({ btnSize }) {
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("cmd2json"); // cmd2json, json2cmd
  const [inputString, setInputString] = useState("");
  const [outputString, setOutputString] = useState("");
  const [outputJson, setOutputJson] = useState({});
  const handleConvert = () => {
    if (mode === "cmd2json") {
      const json = gs.dtKStrToJson(inputString);
      console.log(json);
      if (json) {
        setOutputJson(json);
      } else {
        setOutputJson({
          dtkAdd: inputString.substring(4, 8),
          commandCode: inputString.substring(8, 12),
          msg: "Unknown command"
        });
      }
    } else {
      try {
        const json = JSON.parse(inputString);
        const str = gs.jsonCommandToDtk(json);
        setOutputString(str);
      } catch (e) {
        console.log(e);
        setOutputString("Invalid JSON string");
      }
    }
  }
  return (
    <>
      <Tooltip title={<Typography>Translate command to/from json</Typography>}>
        <SettingsRemote
          sx={{
            margin: "5px",
            width: btnSize,
            height: btnSize,
            cursor: "pointer",
          }}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      <ModalSM
        modalTitle="Translate command to/from json"
        modalIcon={<SettingsRemote />}
        open={open}
        onClose={() => setOpen(false)}
        height="80vh"
        width="95vw"
        disableBottomClose
      >
        <ButtonGroup aria-label="outlined primary button group">
          <Button onClick={() => setMode("cmd2json")} variant={mode === "cmd2json" ? "contained" : "outlined"}>Command to JSON</Button>
          <Button onClick={() => setMode("json2cmd")} variant={mode === "json2cmd" ? "contained" : "outlined"}>JSON to Command</Button>
        </ButtonGroup>
        <Button sx={{ marginLeft: "4vw" }} variant="contained" onClick={handleConvert}>Convert</Button>
        <IconButtonDelete onBtnClick={() => {
          setInputString("");
          setOutputString("");
          setOutputJson({});
        }} />
        <Divider10 />
        <TextField
          fullWidth
          label={mode === "cmd2json" ? "Command string" : "JSON string"}
          value={inputString}
          onChange={(e) => setInputString(e.target.value)}
          sx={{ width: "90vw" }}
        />
        <Divider10 />
        <Typography variant="caption">Result:</Typography>
        <Box sx={{ width: "90vw", outline: "0.5px solid lightgrey", padding: "5px", }}>
          {
            mode === "cmd2json" ?

              <Table size="small" >
                <TableBody>
                  {Object.keys(outputJson || {})
                    .sort((a, b) => a.localeCompare(b))
                    .map((key) => {
                      const value = outputJson[key];
                      return (
                        <TableRow justifyContent="space-between" key={key}>
                          <TableCell>{key}</TableCell>
                          {
                            key === "commandCode" ?
                              <TableCell>{value.toString(16).padStart(4, "0")}</TableCell>
                              :
                              <TableCell>{JSON.stringify(value)}</TableCell>
                          }

                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
              :
              <Typography variant="body1">{outputString}</Typography>

          }
        </Box>
      </ModalSM>
    </>
  );
}

//ed167a0b0a3a0301003c6565656565646465656565650000c6ff
//ed06d09f062d003cd1ff
//ed076dab0a39000065b4ff

//{"serial":"166f2cbf_6_LG","gatewayID":"GW51fb40","dtkAdd":"7a0b","daliCtlID":"166f2cbf","shortAdd":6,"commandCode":2569,"daliAddListIndex":6,"lockLevel":3,"onoffcontrolA":0,"pwm":100,"enable_delayed_action":1,"delayedOnOff":1,"delayedPwm":0,"enable_delayed_action2":0,"delayedOnOff2":1,"delayedPwm2":0,"releaseOnDelayEnd":1,"delaySec":60,"delaySec2":0}
//{"serial":"4f9061f8_0_LG","gatewayID":"GW51fb40","dtkAdd":"6dab","daliCtlID":"4f9061f8","shortAdd":0,"commandCode":2569,"daliAddListIndex":0,"onoffcontrolA":0,"enable_delayed_action":1,"delayedOnOff":1,"enable_delayed_action2":0,"delayedOnOff2":1,"pwm":101,"lockLevel":0,"releaseOnDelayEnd":0}
//{"sensorID":"4075fdb1","gatewayID":"GW51fb40","dtkAdd":"f67a","delaySec":60,"commandCode":1580,"lockLevel":3,"releaseOnDelayEnd":1}



