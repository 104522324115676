import { useContext } from "react";
import { SMContext } from "context/smContext";
import {
  AvTimer,
  TextFields,
  Contactless,
  Share,
  BatteryUnknown,
  Lock,
  Numbers,
  WifiChannel,
} from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import AdminContainer from "components/AdminContainer";

export default function MapLeftToolbar() {
  const [state] = useContext(SMContext);
  return (
    <>
      <LayerButton icon={<Numbers />} title="Device serial number" layerName="serialNo" />
      <LayerButton icon={<TextFields />} title="Device name" layerName="deviceName" />
      <LayerButton icon={<Lock />} title="Light priority level" layerName="lockLevel" />
      <LayerButton icon={<AvTimer />} title="Device latest alive time" layerName="dataTime" />
      <AdminContainer>
        <LayerButton icon={<Contactless />} title="Mesh255 strength info" layerName="rssi255Web" toggleNormalLayer />
        <LayerButton icon={<Share />} title="ESP now route info" layerName="espWeb" toggleNormalLayer />
      </AdminContainer>
      <LayerButton icon={<BatteryUnknown />} title="Battery test info" layerName="batTest" />
      <LayerButton icon={<WifiChannel />} title="433 channel info" layerName="channel" toggleNormalLayer />
    </>
  );
}

function LayerButton({ icon, title, layerName, toggleNormalLayer }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapLayer } = state;
  return (
    <Tooltip title={title}>
      <IconButton
        color={mapLayer[layerName] ? "warning" : "inherit"}
        onClick={() => {
          dispatch({
            type: "ALL_UNSELECTED"
          });
          let payload = {
            [layerName]: !mapLayer[layerName]
          };
          if (toggleNormalLayer) {
            payload.normal = mapLayer[layerName];
          }
          dispatch({
            type: "SET_MAP_LAYER",
            payload
          });
        }}
        size="large"
      >
        {icon}
      </IconButton>
    </Tooltip>
  )
}