import { forwardRef } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const ChartVI2 = forwardRef((props, ref) => {

  const voltageAxesColor = props.voltageAxesColor || "lawngreen";
  const currentAxesColor = props.currentAxesColor || "red";
  const hAxisColor = props.hAxisColor || "white";
  const titleTextColor = props.titleTextColor || "white";
  const options = {
    responsive: true,
    stacked: false,
    plugins: {
      legend: {
        position: "top",
        labels: {
          color: props.legendTextColor ? props.legendTextColor : "#000",
        },
      },
      title: {
        display: true,
        text: props.chartTitle,
      },
    },
    scales: {
      y: {
        type: "linear",
        display: true,
        position: "left",
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        grid: {
          drawOnChartArea: false,
        },
      },
    },
  };

  const data = {
    labels: props.chartLabel,
    datasets: [
      {
        label: "Voltage(V)",
        data: props.chartDataArray[0],
        borderColor: "rgb(255, 99, 132)",
        yAxisID: "y",
      },
      {
        label: "Current(A)",
        data: props.chartDataArray[1],
        borderColor: "rgb(53, 162, 235)",
        yAxisID: "y1",
      },
      {
        label: "min Voltage(V)",
        data: props.chartDataArray[2],
        borderColor: "rgb(245, 183, 177)",
        yAxisID: "y",
      },
      {
        label: "min discharge current(A)",
        data: props.chartDataArray[3],
        borderColor: "rgb(133, 193, 233)",
        yAxisID: "y1",
      },
    ],
  };
  return (
    // <>
    //   {chartDataArray.length > 1 ? (
    <Line data={data} options={options} ref={ref} />
    //   ) : (
    //     <Typography variant="h4">No chart data</Typography>
    //   )}
    // </>
  );
});
export default ChartVI2;
