export default function DaliCtl({
  width,
  height,
  sizeUnit,
  connectStatus,
  disabledConnectStatus,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      {/* <rect
        x={4}
        y={8}
        width={70}
        height={66}
        stroke="#A569BD"
        strokeWidth={5}
        fill="white"
      /> */}
      <ellipse
        cx={40}
        cy={42}
        rx={38}
        ry={25}
        stroke="#A569BD"
        strokeWidth={5}
        fill="white"
      />
      <text
        x={10}
        y={51}
        fill="green"
        fillOpacity="1"
        stroke="none"
        strokeWidth={1}
        fontFamily="Apple SD Gothic Neo"
        fontSize="28"
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="bold"
      >
        DALI
      </text>
      {!disabledConnectStatus && (
        <circle r={7} cx="10" cy="12" fill={connectStatus ? "green" : "red"} />
      )}
    </svg>
  );
}
