import React, { Fragment } from "react";
import { Line, Rect, Text } from "react-konva";

export default function MapSlaveLightGroup({ serial, layerProps }) {
    const { iconSize, mapScale, mapObjAll, activeMapID, lightObjAll, layerScale } = layerProps;
    const masterLightObj = lightObjAll?.[serial];
    const slaveLightObjArray = (masterLightObj?.slaveLightArray || []).map(s => lightObjAll[s]);
    const mapLightObj = mapObjAll[activeMapID]?.lightObj;
    const masterX = (mapLightObj?.[serial]?.x + iconSize) * mapScale.x;
    const masterY = (mapLightObj?.[serial]?.y - 0.4 * iconSize) * mapScale.y;
    return (
        <>
            {
                slaveLightObjArray.length > 0 && <Text x={masterX} y={masterY} text={'M'} fontSize={iconSize * mapScale.x * 0.5} fill="#B9770E" />
            }
            {
                slaveLightObjArray.map((slaveLightObj, key) => {
                    const slaveSerial = slaveLightObj?.serial;
                    const { x, y } = mapLightObj?.[slaveSerial] || {};
                    const netX = x * mapScale.x;
                    const netY = y * mapScale.y;
                    const textX = (x - 0.3 * iconSize) * mapScale.x;
                    const textY = (y - 0.3 * iconSize) * mapScale.y;
                    return (
                        <Fragment key={key}>
                            <Rect
                                x={netX}
                                y={netY}
                                width={iconSize * mapScale.x}
                                height={iconSize * mapScale.y}
                                fill="lightgrey"
                                opacity={0.2}
                                stroke="#935116"
                                strokeWidth={2 / layerScale}
                            />
                            <Text x={textX} y={textY} text={'S'} fontSize={iconSize * mapScale.x * 0.5} fill="#B9770E" />

                        </Fragment>
                    );
                })
            }
        </>
    )
}