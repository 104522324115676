import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import DivInline from "components/DivInline";
import SensorIcon from "components/SensorIcon";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import { tspToDateTime } from "actions/generalActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryOneSensorSetting } from "actions/sensorActions";
import { deviceErrorMsg } from "actions/generalDeviceActions";
import WarningSign from "asset/svgComp/WarningSign";
import DivCol from "components/DivCol";
export default function SensorStatusPanel() {
  const [state] = useContext(SMContext);
  const { activeSensorID, sensorObjAll, gatewayObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { type, lux, luxLv, gatewayID, generalFault } = sensorObj || {};
  const bg = generalFault ? "yellow" : "#fff";
  const errorMsgObj = deviceErrorMsg(sensorObj, "sensor", state);
  return (
    <div>
      <Typography variant="h6" display="block" align="center">
        Status
      </Typography>
      <Divider10 />
      <DivInline justifyContent="center">
        <SensorIcon
          sensorObj={sensorObj}
          width={7}
          sizeUnit="vh"
          backgroundColor={bg}
          gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
        />
      </DivInline>
      <DivExist show={type === "daylight" || type === "daylightmotion"}>
        <DivInline justifyContent="space-between" sx={{ marginBottom: "2vh" }}>
          <Typography>Level: {luxLv ?? "-"}</Typography>
          <Typography>Lux: {lux ?? "-"}lux</Typography>
          <DivExist show={sensorObj.luxFault ? true : false}>
            <Typography variant="caption">Light sensor fault</Typography>
          </DivExist>
        </DivInline>
      </DivExist>
      <DivExist show={generalFault ? true : false}>
        <DivInline>
          <Typography variant="caption">Warning:</Typography>
          <IconButtonRefresh onBtnClick={() => queryOneSensorSetting(sensorObj, 0x071d)} />
        </DivInline>
      </DivExist>
      <Typography variant="caption" align="right" display="block">
        {tspToDateTime(sensorObj?.timeStamp)}
      </Typography>
      <DivExist show={Object.keys(errorMsgObj || {}).length > 0}>
        <DivInline>
          <WarningSign width={1.5} sizeUnit="vw" />
          <DivCol alignItems="flex-start" sx={{ marginLeft: "0.5vw" }}>
            {(Object.keys(errorMsgObj || {})).map(code => (
              <Typography key={code} color="error" variant="caption" display="block">{errorMsgObj[code]}</Typography>
            ))}
          </DivCol>
        </DivInline>
      </DivExist>
    </div>
  );
}
