import { Circle, Ellipse, Group, Path } from "react-konva";

export default function Server({
  x,
  y,
  size,
  connectStatus,
  disableConnectStatus,
}) {
  const scale = size / 79.375;

  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      {!disableConnectStatus && (
        <Circle radius={7} fill={connectStatus ? "green" : "red"} />
      )}
      <Path
        fill="#e9ddaf"
        stroke="#000"
        strokeWidth={1.7}
        data="M6.304 9.546c.043-1.2.39-2.24 2.341-2.432l63.04.09c1.385.167 2.137.86 2.16 2.162l-.18 12.788c-.172 1.33-.901 1.897-1.98 1.981l-63.04.18c-1.48.298-2.011-.61-2.251-1.891z"
      ></Path>
      <Ellipse
        x={64.788}
        y={15.511}
        fill="#0f0"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={1.7}
        radiusX={2.532}
        radiusY={2.617}
      ></Ellipse>
      <Path
        // fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={2}
        data="M50.554 15.745h6.043"
      ></Path>
      <Group strokeWidth={1.7}>
        <Path
          fill="#fc0"
          stroke="#000"
          data="M14.894 24.256l2.383-5.915c.404-.569.862-1.028 1.404-1.32l12.128-.042c1.534.326 1.763.653 2.085.979l2.511 6.255z"
        ></Path>
        <Path
          fill="#ff8080"
          stroke="#000"
          strokeLinecap="round"
          data="M11.374 24.374H68.865V28.939H11.374z"
        ></Path>
        <Path
          fill="#e9ddaf"
          stroke="#000"
          data="M5.817 31.507c.044-1.2.39-2.24 2.342-2.432l63.039.09c1.385.167 2.137.86 2.161 2.162l-.18 12.788c-.173 1.33-.902 1.897-1.981 1.981l-63.04.18c-1.479.298-2.01-.61-2.25-1.891z"
        ></Path>
        <Ellipse
          x={64.301}
          y={37.472}
          stroke="#000"
          fill="#0f0"
          strokeLinecap="round"
          radiusX={2.532}
          radiusY={2.617}
        ></Ellipse>
      </Group>
      <Path
        // fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={2}
        data="M50.067 37.706h6.043"
      ></Path>
      <Group strokeWidth="1.7">
        <Path
          fill="#fc0"
          stroke="#000"
          data="M14.407 46.217l2.383-5.915c.404-.569.862-1.028 1.404-1.32l12.128-.042c1.534.326 1.763.653 2.085.979l2.511 6.255z"
        ></Path>
        <Path
          fill="#ff8080"
          stroke="#000"
          strokeLinecap="round"
          data="M10.888 46.335H68.379V50.9H10.888z"
        ></Path>
        <Path
          fill="#e9ddaf"
          stroke="#000"
          data="M5.939 53.64c.043-1.2.39-2.24 2.341-2.432l63.04.09c1.385.167 2.137.86 2.16 2.162l-.18 12.788c-.172 1.33-.901 1.897-1.98 1.981l-63.04.18c-1.48.298-2.011-.61-2.251-1.891z"
        ></Path>
        <Ellipse
          x={64.423}
          y={59.604}
          fill="#0f0"
          stroke="#000"
          strokeLinecap="round"
          radiusX={2.532}
          radiusY={2.617}
        ></Ellipse>
      </Group>
      <Path
        // fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={2}
        data="M50.189 59.838h6.043"
      ></Path>
      <Path
        fill="#fc0"
        stroke="#000"
        strokeWidth={1.7}
        data="M14.529 68.349l2.383-5.915c.404-.569.862-1.028 1.404-1.32l12.128-.042c1.534.326 1.763.653 2.085.979l2.511 6.255z"
      ></Path>
      <Path
        fill="#ff8080"
        stroke="#000"
        strokeLinecap="round"
        strokeWidth={1.7}
        data="M11.009 68.467H68.5V73.032H11.009z"
      ></Path>
    </Group>
  );
}
