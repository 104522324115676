export default function DataUpload({
  width,
  height,
  sizeUnit,
  backgroundColor = "",
  color = "teal",
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <rect fill={backgroundColor} width={80} height={80} />
      <g fill="none" stroke={color}>
        <ellipse
          cx="33.71"
          cy="16.002"
          strokeLinecap="round"
          strokeWidth="6.058"
          rx="28.924"
          ry="11.541"
        ></ellipse>
        <path strokeWidth="6" d="M62.383 33.628l.162-18.033"></path>
        <path strokeWidth="6.132" d="M5.036 17.058l-.162 49.549"></path>
        <g strokeWidth="6">
          <path d="M33.466 43.538s-4.667-.218-6.986-.487c-2.343-.272-4.68-.632-6.985-1.138-1.976-.434-3.946-.936-5.849-1.624-1.116-.404-2.172-.962-3.249-1.462-1.361-.633-4.061-1.95-4.061-1.95M33.141 60.109s-4.667-.218-6.986-.487c-2.343-.272-4.68-.632-6.985-1.138-1.976-.434-3.946-.936-5.849-1.624-1.116-.404-2.172-.962-3.249-1.462-1.361-.633-4.061-1.95-4.061-1.95M33.304 74.892s-4.667-.218-6.986-.487c-2.343-.272-4.68-.632-6.985-1.138-1.976-.434-3.946-.936-5.849-1.624-1.116-.404-2.172-.962-3.249-1.462-1.361-.633-6.334-5.534-6.334-5.534"></path>
        </g>
      </g>
      <path
        fill={color}
        d="M49.954 59.74h-7.748l-1.631-2.243.204-2.243L58.72 38.535l1.223-.611 2.04 1.02L78.09 54.438l1.02 2.447-.612 1.835-1.427.612-7.952-.204.408 13.049.204 3.058-19.166.204z"
      ></path>
    </svg>
  );
}
