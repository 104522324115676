import React from "react";

export default function SkymonLogo({
  width,
  height,
  sizeUnit,
  background,
  onClick,
}) {
  const w = width || height * 1.181 || 196;
  const h = height || width / 1.181 || 166;
  sizeUnit = sizeUnit || "px";
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width={w + sizeUnit}
      height={h + sizeUnit}
      version="1.1"
      viewBox="0 0 51.784 43.921"
      onClick={onClick}
    >
      <circle
        cx="21.96"
        cy="21.961"
        r="21.961"
        fill="#4586ba"
        fillRule="evenodd"
      ></circle>
      <g
        style={{
          fontVariantCaps: "normal",
          fontVariantEastAsian: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
        }}
        fill="#fff"
        aria-label="Skym"
      >
        <path
          d="M21.345 27.283v.956q-.246-.208-.502-.369-.246-.16-.492-.265-.568-.265-1.155-.265-.739 0-1.222.37-.492.378-.492.899 0 .757 1.117 1.136.521.18 1.032.35.521.16 1.051.331 1.127.54 1.127 1.61 0 1.041-.805 1.676-.767.596-1.903.596-.757 0-1.458-.293-.407-.161-.966-.53v-.966q.73.653 1.477.918.246.095.483.133.237.037.473.037.758 0 1.279-.378.577-.417.577-1.089 0-.814-1.098-1.24-.53-.161-1.042-.332t-1.022-.331q-1.108-.511-1.108-1.572 0-.89.7-1.448.72-.578 1.8-.578.663 0 1.325.246.341.114.824.398zM22.707 26.763h.691v4.592l2.33-2.234h.965l-2.13 1.969 2.433 3.096h-.937l-2.008-2.642-.653.606v2.036h-.691zM27.409 29.12h.757l1.743 4.28 1.486-4.28h.748l-2.111 5.652q-.228.606-.587.862-.322.218-.767.218-.227 0-.379-.038-.151-.029-.293-.085v-.654q.132.067.293.114.17.047.303.047.331 0 .511-.17.19-.17.332-.54l.113-.388zM40.386 30.938v3.248h-.691v-3.409q0-.54-.37-.871-.312-.284-.738-.284-.634 0-1.136.587-.492.578-.492 1.43v2.547h-.692v-3.409q0-.511-.35-.823-.331-.332-.757-.332-.616 0-1.099.55-.53.605-.53 1.495v2.519h-.69V29.12h.643l.028.805q.275-.445.786-.7.455-.218.919-.218.72 0 1.211.416.36.313.455.625.19-.35.54-.634.549-.407 1.211-.407.805 0 1.25.483.502.549.502 1.448z"
          style={{
            fontVariantCaps: "normal",
            fontVariantEastAsian: "normal",
            fontVariantLigatures: "normal",
            fontVariantNumeric: "normal",
          }}
          transform="scale(.99399 1.006)"
        ></path>
      </g>
      <g
        style={{
          fontVariantCaps: "normal",
          fontVariantEastAsian: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
        }}
        fill={background === "dark" ? "#fff" : "#000"}
        aria-label="on"
      >
        <path
          d="M45.028 34.241q-.967 0-1.7-.704-.779-.742-.779-1.925 0-1.118.686-1.869.704-.76 1.737-.76 1.023 0 1.727.76.695.751.695 1.86 0 1.164-.751 1.924-.695.714-1.615.714zm-.066-.62q.676 0 1.202-.563.507-.573.507-1.456 0-.91-.544-1.483-.498-.526-1.155-.526-.676 0-1.155.545-.526.6-.526 1.474 0 .883.516 1.474.48.535 1.155.535zM52.531 30.758v3.361h-.676v-3.314q0-.526-.385-.864-.385-.347-.92-.347-.714 0-1.23.591-.517.601-.517 1.352v2.582h-.638v-5.023h.591l.028.939q.254-.385.592-.639.554-.413 1.249-.413.807 0 1.352.489.554.478.554 1.286z"
          style={{
            fontVariantCaps: "normal",
            fontVariantEastAsian: "normal",
            fontVariantLigatures: "normal",
            fontVariantNumeric: "normal",
          }}
          transform="scale(.98579 1.0144)"
        ></path>
      </g>
    </svg>
  );
}
