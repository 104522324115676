import { Line, Text } from "react-konva";
import {
  daliCtlColorDark,
  gatewayColorDark,
  lightColorDark,
  sensorColorDark,
} from "actions/generalActions";
export default function LinesGatewaySelected({ layerProps }) {
  const {
    selectedID,
    gatewayObjAll,
    lightObjAll,
    sensorObjAll,
    daliCtlObjAll,
    layerScale,
    mapObjAll,
    activeMapID,
    selectedDeviceX,
    selectedDeviceY,
    mapScale,
    mapLayer,
  } = layerProps;
  const gatewayObj = gatewayObjAll[selectedID];
  const { serialMap, sensorMap, daliCtlMap, unknownDevice } = gatewayObj || {};
  const lightArray = Object.values(serialMap || []);
  const sensorArray = Object.values(sensorMap || []);
  const daliCtlArray = Object.values(daliCtlMap || []);
  const mapGwObj = mapObjAll[activeMapID]?.gatewayObj;
  const mapLightObj = mapObjAll[activeMapID]?.lightObj;
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj;
  const mapDaliCtlObj = mapObjAll[activeMapID]?.daliCtlObj;
  const unknownDeviceAddArray = Object.keys(unknownDevice || []);

  return (
    <>
      {(lightArray || [])
        ?.filter((s) => lightObjAll[s]?.mapID?.includes(activeMapID))
        ?.map((s, key) => {
          const netX = parseInt(mapLightObj[s]?.x * mapScale.x);
          const netY = parseInt(mapLightObj[s]?.y * mapScale.y);
          return (
            <Line
              key={key}
              points={[selectedDeviceX, selectedDeviceY, netX, netY]}
              stroke={lightColorDark}
              strokeWidth={1.5 / layerScale}
            />
          );
        })}
      {(sensorArray || [])
        ?.filter((s) => sensorObjAll[s]?.mapID?.includes(activeMapID))
        ?.map((s, key) => {
          const netX = parseInt(mapSensorObj[s]?.x * mapScale.x);
          const netY = parseInt(mapSensorObj[s]?.y * mapScale.y);
          return (
            <Line
              key={key}
              points={[selectedDeviceX, selectedDeviceY, netX, netY]}
              stroke={sensorColorDark}
              strokeWidth={1.5 / layerScale}
            />
          );
        })}
      {daliCtlArray
        .filter((d) => daliCtlObjAll[d]?.mapID?.includes(activeMapID))
        .map((d, key) => {
          const netX = parseInt(mapDaliCtlObj[d]?.x * mapScale.x);
          const netY = parseInt(mapDaliCtlObj[d]?.y * mapScale.y);
          return (
            <Line
              key={key}
              points={[selectedDeviceX, selectedDeviceY, netX, netY]}
              stroke={daliCtlColorDark}
              strokeWidth={1.5 / layerScale}
            />
          );
        })}
      {unknownDeviceAddArray.map((add, key) => {
        let serial = Object.keys(lightObjAll || {}).find(
          (s) => lightObjAll[s]?.dtkAdd === add
        );
        let sensorID = Object.keys(sensorObjAll || {}).find(
          (s) => sensorObjAll[s]?.dtkAdd === add
        );
        let daliCtlID = Object.keys(daliCtlObjAll || {}).find(
          (s) => daliCtlObjAll[s]?.dtkAdd === add
        );
        let mapPos =
          mapLightObj[serial] ||
          mapSensorObj[sensorID] ||
          mapDaliCtlObj[daliCtlID] ||
          {};
        const netX = parseInt(mapPos?.x * mapScale.x);
        const netY = parseInt(mapPos?.y * mapScale.y);
        if (mapPos) {
          return (
            <Line
              key={key}
              points={[selectedDeviceX, selectedDeviceY, netX, netY]}
              stroke={lightColorDark}
              strokeWidth={1 / layerScale}
              lineJoin="round"
              dash={[10, 5]}
            />
          );
        } else {
          return null;
        }
      })}
    </>
  );
}
