import React from "react";

export default function NearFieldIcon({
  width,
  height,
  sizeUnit,
  color
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const iconColor = color || "#fff";
  return (
    <svg
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g>

        <rect x="0.179" y="0.179" width="65" height="65" fill="none" fillOpacity="1" stroke={iconColor} strokeWidth={4} />
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "1.25",
            InkscapeFontSpecification: "'Apple SD Gothic Neo, Bold'",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
          }}
          x="10"
          y="48"
          fill={iconColor}
          fillOpacity="1"
          // stroke="none"
          // strokeWidth="0.919"
          fontFamily="Apple SD Gothic Neo"
          fontSize={35}
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="bold"
        >

          NF
        </text>
      </g>

    </svg>
  );
}
