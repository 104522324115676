import {
  deleteFsDocs_arrayContains,
  deleteFsDocs_equal,
  mgDeleteMany,
  mgFindByArray,
  mgFindByMatch,
  mgUpdateOneUpsert,
} from "./mongoApiActions";
import moment from "moment";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchTimeTableByLightArray = async (lightArr, dispatch) => {
  const arr = await mgFindByArray("timeTable", "serial", lightArr || []);
  let objAll = {};
  let arrAll = [];
  lightArr.forEach((s) => {
    objAll[s] = [];
  });
  arr.forEach((obj) => {
    const timeTableID = obj.timeTableID;
    objAll[timeTableID] = obj;
    arrAll.push(obj);
  });
  console.log("fetchTimeTableByLightArray", objAll);
  dispatch({
    type: "FETCHED_TIMETABLE_OBJ_ALL",
    payload: { objAll, arrAll },
  });
};
export const fetchTimeTableBySerial = async (serial) => {
  const arr = await mgFindByMatch("timeTable", "serial", serial);
  return arr;
};
export const saveTestTimeTable = async (
  serial,
  batteryHour,
  monthTimeTableObj,
  annualTimeTableObj
) => {
  const jsonData = {
    type: "updateELightTestTimeTable",
    serial,
    batteryHour,
    monthTimeTableObj,
    annualTimeTableObj,
  };
  console.log(`[SOCKET OUT] ${serial} updateELightTestTimeTable`);
  global.socket.emit("fromWeb", jsonData);
};
// export const deleteAllTestTimeTableOfSerial = async (serial) => {
//   // const mgQuery = {
//   //   $and: [{ serial: serial }, { timeActionType: "scheduledTest_single" }],
//   // };
//   const resultMg = await mgDeleteMany("timeTable", mgQuery);
//   console.log(resultMg);
//   if (enableUploadFS) {
//     await gs.asyncForEach(timeTableObjArr, async (obj) => {
//       const { scheduleID } = obj;
//       await deleteFsDocs_equal(
//         "SM_timeTable",
//         "scheduleID",
//         scheduleID,
//         "scheduleID"
//       );
//       await gs.waitFor(300);
//     });
//   }
// };
export const deleteTestTimeTable = async (
  monthTimeTableID,
  annualTimeTableID
) => {
  const type = "deleteManyTimeTables";
  const jsonData = {
    type,
    timeTableIDArray: [monthTimeTableID, annualTimeTableID],
  };
  console.log(
    `[COMMAND OUT] delete Test TimeTables ${monthTimeTableID} ${annualTimeTableID}`
  );
  global.socket.emit("fromWeb", jsonData);
  // const resultMg1 = await mgDeleteMany("timeTable", {
  //   scheduleID: monthScheduleID,
  // });
  // await gs.waitFor(500);
  // const resultMg2 = await mgDeleteMany("timeTable", {
  //   scheduleID: annualScheduleID,
  // });
  // console.log({ resultMg1, resultMg2 });
  // if (serverObj.enableUploadFS) {
  //   const result1 = await deleteFsDocs_equal(
  //     "SM_timeTable",
  //     "scheduleID",
  //     monthScheduleID,
  //     "scheduleID"
  //   );
  //   const result2 = await deleteFsDocs_equal(
  //     "SM_timeTable",
  //     "scheduleID",
  //     annualScheduleID,
  //     "scheduleID"
  //   );
  //   console.log({ result1, result2 });
  // }
};
export const deleteSingleTimeTable = async (timeTableID, enableUploadFS) => {
  const result = await mgDeleteMany("timeTable", { scheduleID: timeTableID });
  console.log(result);
  if (enableUploadFS) {
    const resultFS = await deleteFsDocs_equal(
      "SM_timeTable",
      "scheduleID",
      timeTableID,
      "scheduleID"
    );
    console.log(resultFS);
  }
};
export const fetchTimeTableByZc = async (zcID) => {
  const arr = await mgFindByMatch("timeTable", "zoneControlID", zcID);
  let objAll = {};
  arr.forEach((obj) => {
    const key = obj.timeTableID;
    objAll[key] = obj;
  });
  console.log("fetchTimeTableByZc", objAll);
  return objAll;
};

export const updateManyTimeTables = async (updateObjArray) => {
  const type = "updateManyTimeTables";
  const jsonData = {
    type,
    updateObjArray,
  };
  let msg = `[COMMAND OUT] updateManyTimeTables - ${updateObjArray.map(obj => obj.timeTableID).join(",")}`;
  console.log(msg);
  global.socket.emit("fromWeb", jsonData);
};
export const manualExecuteTimeTable = async (timeTableObj) => {
  const type = "manualExecuteTimeTable";
  const jsonData = {
    type,
    timeTableObj,
  };
  console.log(
    `[COMMAND OUT] manualExecuteTimeTable ${timeTableObj.timeTableID}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const batTestTimeInfo = (timeTableArrAll, serial, monthFormat = "MMM", yearFormat = "YYYY") => {
  const monthObj = timeTableArrAll.find(
    (obj) => obj.reportType === "check_1month" && obj.serial === serial
      && obj.rescheduleCount === 0
  );
  const annualObj = timeTableArrAll.find(
    (obj) => obj.reportType === "check_12month" && obj.serial === serial
      && obj.rescheduleCount === 0
  );
  if (!monthObj && !annualObj) return { hasTestSchedule: false };
  let monthDate = monthObj.date[0];
  // if (moment().month() === 1) { //February
  //   if (!has29thFebruary(moment().year()) && monthDate >= 29) monthDate = 28;
  //   if (has29thFebruary(moment().year()) && monthDate >= 29) monthDate = 29;
  // }
  // if (moment().month() !== 1 && !has31Days(moment().month() + 1) && monthDate === 31) {
  //   monthDate = 30;
  // }
  // let yearDate = annualObj.date[0];
  // const yearMonth = annualObj.month[0];
  let nextMonthMoment = moment().date(monthDate).hour(monthObj.hour[0]).minute(monthObj.minute[0]);
  if (nextMonthMoment.isBefore(moment())) nextMonthMoment = nextMonthMoment.add(1, "months");
  const nextMonthlyTestMonth = nextMonthMoment.month() + 1;
  if (!monthObj.month.includes(nextMonthlyTestMonth)) {
    nextMonthMoment = nextMonthMoment.add(1, "months");
  }

  let nextAnnualMoment = moment().month(annualObj.month[0] - 1).date(annualObj.date[0]).hour(annualObj.hour[0]).minute(annualObj.minute[0]);
  if (nextAnnualMoment.isBefore(moment())) nextAnnualMoment = nextAnnualMoment.add(1, "years");
  const monthEffectiveAfterTsp = monthObj.effectiveAfterTsp;
  if (moment(monthEffectiveAfterTsp).isAfter(nextMonthMoment)) {
    nextMonthMoment = nextMonthMoment.add(1, "months");
  }
  const annualEffectiveAfterTsp = annualObj.effectiveAfterTsp;
  if (moment(annualEffectiveAfterTsp).isAfter(nextAnnualMoment)) {
    nextAnnualMoment = nextAnnualMoment.add(1, "years");
  }
  const latestTestMoment = nextMonthMoment.isAfter(nextAnnualMoment) ? nextMonthMoment : nextAnnualMoment;
  const monthlyTestDateStr = monthObj.date[0].toString().padStart(2, "0");
  const monthlyTestHourStr = monthObj.hour[0].toString().padStart(2, "0");
  const monthlyTestMinuteStr = monthObj.minute[0].toString().padStart(2, "0");
  const monthlyTestExceptMonth = gs.newArrayBetween(1, 12).find(n => !monthObj.month.includes(n));
  const monthlyTestExceptMonthStr = moment(monthlyTestExceptMonth, "MM").format("MMM");
  const returnObj = {
    hasTestSchedule: true,
    monthTimeTableObj: monthObj,
    annualTimeTableObj: annualObj,
    nextMonthTestMoment: nextMonthMoment,
    nextMonthTestTsp: nextMonthMoment.valueOf(),
    nextMonthTestTsr: nextMonthMoment.format(`DD-${monthFormat}-${yearFormat}, HH:mm`),
    monthTestDescription: `Day ${monthlyTestDateStr}, ${monthlyTestHourStr}:${monthlyTestMinuteStr} every month, except ${monthlyTestExceptMonthStr}`,
    nextAnnualTestMoment: nextAnnualMoment,
    nextAnnualTestTsp: nextAnnualMoment.valueOf(),
    nextAnnualTestTsr: nextAnnualMoment.format(`DD-${monthFormat}-${yearFormat}, HH:mm`),
    annualTestDescription: `${annualObj.date[0].toString().padStart(2, "0")}-${annualObj.month[0].toString().padStart(2, "0")}, ${annualObj.hour[0].toString().padStart(2, "0")}:${annualObj.minute[0].toString().padStart(2, "0")} every year`,
    latestTestTsp: latestTestMoment.valueOf(),
    latestTestTsr: latestTestMoment.format(`DD-${monthFormat}-${yearFormat}, HH:mm`),
  };
  return returnObj;
};

const has29thFebruary = (year) => {
  if ((year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)) {
    return true; // Leap year
  }
  return false; // Not a leap year
};
const has31Days = (month) => {
  if ([1, 3, 5, 7, 8, 10, 12].includes(month)) return true;
  return false;
};