import React from "react";

export default function AcRemote({
  width,
  height,
  sizeUnit,
  connectStatus,
  disabledConnectStatus,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g strokeLinecap="round">
        <rect
          width="58"
          height="76"
          x="11"
          y="2"
          stroke="purple"
          strokeWidth="2.117"
          ry="3"
          fill="#fff"
        ></rect>
        <rect
          width="45.849"
          height="32.115"
          x="17.114"
          y="7.184"
          stroke="purple"
          strokeWidth="2.117"
          ry="3.592"
          fill="lightgrey"
        ></rect>
        <path
          stroke="#000"
          strokeWidth="8"
          d="M108.6 179.67l1.224 82.343-71.923-40.111z"
          transform="matrix(-.20822 0 0 .26458 69.978 -.817)"
          fill="grey"
        ></path>
        <path
          stroke="#000"
          strokeWidth="8"
          d="M108.6 179.67l1.224 82.343-71.923-40.111z"
          transform="matrix(.20822 0 0 .26458 8.728 -.876)"
          fill="grey"
        ></path>
        <ellipse
          cx="29.342"
          cy="15.039"
          stroke="maroon"
          strokeWidth="2.117"
          rx="3.841"
          ry="3.76"
          fill="lightgrey"
        ></ellipse>
        <path
          stroke="#ff2a2a"
          strokeWidth="2.117"
          fill="none"
          d="M52.992 31.091a9.072 9.645 0 01-12.431-.77 9.072 9.645 0 010-13.237 9.072 9.645 0 0112.431-.77"
        ></path>
        {!disabledConnectStatus && (
          <circle
            r="6"
            cx="10"
            cy="12"
            fill={connectStatus ? "green" : "red"}
          />
        )}
      </g>
    </svg>
  );
}
