import { useState, useEffect, useMemo } from "react";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import { Code, Search } from "@mui/icons-material";
import { TextField, IconButton, Typography } from "@mui/material";
const gs = new General();

export default function CommandCodeModal() {
  const [open, setOpen] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [allList, setAllList] = useState([]);
  // const filteredList = useMemo(() => {
  //   let list = [];
  //   if (searchName.length < 3) return list;
  //   for (let i = 0; i < 0xffff; i++) {
  //     const commandCodeStr = gs.intToHexStr(i, 2);
  //     const cmdStr = "F000aaaa" + commandCodeStr + "ff";

  //     let obj = gs.dtKStrToJson(cmdStr);
  //     console.log(obj);
  //     if (!obj) continue;
  //     const commandName = obj.commandName;
  //     if (commandName?.includes(searchName)) {
  //       obj.commandCode = i;
  //       list.push(obj);
  //     }
  //   }
  //   return list;
  // }, [searchName]);
  useEffect(() => {
    // const str = "f003bbbb0401ff";
    // const strc = gs.getChecksum_dtk(str);

    // console.log(strc);
    const cmdObj = gs.dtKStrToJson("f004bbbb04016fff");
    console.log(cmdObj);
    let list = [];
    for (let i = 0x0401; i < 0x040e; i++) {
      const arr = [0xf0, 0x04, 0xbb, 0xbb, i >> 8, i & 0xff, 0x00, 0xff];
      const checksum = arr.reduce((a, b) => a + b, 0) - 0xff;
      const checksumL = checksum & 0xff;
      arr[arr.length - 2] = checksumL;
      let cmdStr = arr
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");
      let obj = gs.dtKStrToJson(cmdStr);
      if (!obj) continue;
      obj.cmdStr = cmdStr;

      list.push(obj);
    }
    setAllList(list);
  }, []);
  return (
    <>
      <IconButton onClick={() => setOpen(true)} variant="outlined">
        <Code />
      </IconButton>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalIcon={<Code />}
        modalTitle="Command Code"
        width="60vw"
        height="50vh"
        titleComponent={
          <>
            <Search />
            <TextField
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              variant="outlined"
            />
          </>
        }
      >
        {allList.map((obj, key) => {
          return (
            <div key={key}>
              {`Code: ${gs.intToHexStr(obj.commandCode, 2)}, commandName: ${
                obj.commandName
              } cmdStr: ${obj.cmdStr}, dtkAddr: ${obj.dtkAdd}`}
            </div>
          );
        })}
      </ModalSM>
    </>
  );
}
