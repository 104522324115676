import moment from "moment";
import { mgFindAll, mgFindOne } from "./mongoApiActions";
import General from "@ecoenghk/general";
import { deviceInfoOfObj } from "./generalActions";
import { alertWindow } from "./screenActions";
const gs = new General();

export const fetchOneGateway = async (dispatch, gatewayID) => {
  const obj = await mgFindOne("gateway", "gatewayID", gatewayID);
  dispatch({
    type: "UPDATE_GATEWAY_OBJ",
    payload: obj,
  });
  return obj;
};

// export const fetchAllGateways = async () => {
//   const data = await mgFindAll("gateway");
//   console.log(data);
//   let objAll = {};
//   data.forEach((obj) => {
//     const g = obj.gatewayID;
//     objAll[g] = obj;
//   });
//   return objAll;
// };
/**
 *
 * @param {*} socket
 * @param {string} gatewayID
 * @param {"all"|"gateway"|"serial"|"sensor"|"zoneControl"|"singleSerial"|"singleSensor"|"singleZoneControl"} updateType
 * @param {string} deviceID
 *
 * @description singleSerial and singleSensor need deviceID
 */
export const serverUpdateToGateway = async (
  socket,
  gatewayID,
  updateType,
  deviceID
) => {
  let payload = {
    type: "updateGatewayData",
    gatewayID: gatewayID,
    updateType: updateType,
  };
  if (updateType === "gateway") payload = { ...payload, gatewayID: gatewayID };
  else if (updateType === "serial")
    payload = { ...payload, gatewayID: gatewayID };
  else if (updateType === "sensor")
    payload = { ...payload, gatewayID: gatewayID };
  else if (updateType === "zoneControl")
    payload = { ...payload, gatewayID: gatewayID };
  else if (updateType === "singleSerial")
    payload = { ...payload, serial: deviceID };
  else if (updateType === "singleSensor")
    payload = { ...payload, sensorID: deviceID };
  else if (updateType === "singleZoneControl")
    payload = { ...payload, zoneControlID: deviceID };
  console.log("updateGatewayData", payload);
  socket.emit("fromWeb", payload);
};
export const updateGatewayProperty = async (
  gatewayObj,
  updateObj,
  updateToGw
) => {
  const jsonData = {
    type: "updateGatewayProperty",
    gatewayObj,
    updateObj,
    updateToGw,
  };
  console.log(
    `[COMMAND OUT] gatewayID ${gatewayObj.gatewayID} updateGatewayProperty`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const gatewayDisconnectLight = async (lightObj) => {
  const jsonData = {
    type: "gatewayDisconnectLight",
    lightObj,
  };
  console.log(
    `[COMMAND OUT] light ${lightObj.serial} disconnect its gateway${lightObj.gatewayID}`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const gatewayDisconnectSensor = async (sensorObj) => {
  const jsonData = {
    type: "gatewayDisconnectSensor",
    sensorObj,
  };
  console.log(
    `[COMMAND OUT] sensor ${sensorObj.sensorID} disconnect its gateway${sensorObj.gatewayID}`
  );
  await global.socket.emit("fromWeb", jsonData);
};
// export const gatewayConnectSensors = async (
//   socket,
//   gatewayID,
//   gatewayObj,
//   sensorObjArray
// ) => {
//   let jsonData = {
//     type: "gatewayConnectSensors",
//     gatewayID,
//     gatewayObj,
//     sensorObjArray,
//   };
//   console.log(`[SOCKET OUT] gateway ${gatewayID} connect sensors`);
//   await socket.emit("fromWeb", jsonData);
//   // await mgUpdateNestedJsonKey(
//   //   "gateway",
//   //   { gatewayID },
//   //   "sensorMap",
//   //   sensorObj.zigbeeAdd,
//   //   sensorID
//   // );
//   // await mgUpdateOneUpsert("sensor", { sensorID }, { gatewayID });
//   // await gs.waitFor(800);
//   // await serverUpdateToGateway(socket, gatewayID, "singleSensor", sensorID);
//   // await fetchOneSensor(dispatch, sensorID);
//   // await fetchOneGateway(dispatch, gatewayID);
//   // await gs.waitFor(500);
//   // if (enableUploadFS) {
//   //   await uploadGatewayFS(gatewayID);
//   //   await uploadSensorFS(sensorID);
//   // }
// };
// export const gatewayConnectLights = async (
//   gatewayID,
//   gatewayObj,
//   lightObjArray
// ) => {
//   let jsonData = {
//     type: "gatewayConnectLights",
//     gatewayID,
//     gatewayObj,
//     lightObjArray,
//   };
//   console.log(`[SOCKET OUT] gateway ${gatewayID} connect lights`);
//   await global.socket.emit("fromWeb", jsonData);
// };
export const gatewayConnectLight = async (gatewayID, gatewayObj, lightObj) => {
  let jsonData = {
    type: "gatewayConnectLight",
    gatewayID,
    gatewayObj,
    serial: lightObj.serial,
    lightObj,
  };
  console.log(
    `[SOCKET OUT] gateway ${gatewayID} connect light ${lightObj.serial}`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const gatewayConnectSensor = async (
  gatewayID,
  gatewayObj,
  sensorObj
) => {
  let jsonData = {
    type: "gatewayConnectSensor",
    gatewayID,
    gatewayObj,
    sensorID: sensorObj.sensorID,
    sensorObj,
  };
  console.log(
    `[SOCKET OUT] gateway ${gatewayID} connect light ${sensorObj.sensorID}`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const gatewayConnectDaliCtl = async (
  gatewayID,
  gatewayObj,
  daliCtlObj
) => {
  let jsonData = {
    type: "gatewayConnectDaliCtl",
    gatewayID,
    gatewayObj,
    daliCtlID: daliCtlObj.daliCtlID,
    daliCtlObj,
  };
  console.log(
    `[COMMAND OUT] gateway ${gatewayID} connect daliCtl ${daliCtlObj.daliCtlID}`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const gatewayDisconnectDaliCtl = async (daliCtlObj) => {
  const jsonData = {
    type: "gatewayDisconnectDaliCtl",
    daliCtlObj,
  };
  console.log(
    `[SOCKET OUT] gateway ${daliCtlObj.gatewayID} disconnect daliCtl ${daliCtlObj.daliCtlID}`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const gatewayDeletePermanently = async (gatewayObj) => {
  const jsonData = {
    type: "gatewayDeletePermanently",
    gatewayObj,
  };
  console.log(
    `[SOCKET OUT] gateway ${gatewayObj.gatewayID} delete permanently`
  );
  await global.socket.emit("fromWeb", jsonData);
};
export const updateGatewaysFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "gateway",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleGatewayFsToLs = async (gatewayID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleGateway",
    gatewayID,
  };
  await global.socket.emit("fromWeb", payload);
};
// export const readRssi = async (gatewayID, testPairObjArray) => {
//   const payload = {
//     type: "readRssi",
//     gatewayID,
//     testPairObjArray,
//   };

//   console.log(`[COMMAND OUT] readRssi gatewayID ${gatewayID}`, payload);
//   await global.socket.emit("fromWeb", payload);
// };
export const rebootGateway = async (gatewayObj) => {
  let payload;
  if (gatewayObj.zigbeeAdd && gatewayObj.gwVersion?.includes("GWV")) {
    //zigbee gateway before 1.13.0
    payload = {
      type: "rebootGateway",
      gatewayID: gatewayObj.gatewayID,
    };
  } else {
    //zigbee gateway on/after 1.13.0 or dtk gateway
    payload = {
      type: "control_single_gateway",
      commandCode: 0x0001,
      gatewayID: gatewayObj.gatewayID,
    };
  }
  console.log(`[COMMAND OUT] rebootGateway  ${gatewayObj.gatewayID}`);
  await global.socket.emit("fromWeb", payload);
};
export const restartGatewayProgram = async (gatewayObj) => {
  let payload;
  if (gatewayObj.zigbeeAdd && gatewayObj.gwVersion?.includes("GWV")) {
    //zigbee gateway before 1.13.0
    payload = {
      type: "restartGatewayProgram",
      gatewayID: gatewayObj.gatewayID,
    };
  } else {
    //zigbee gateway on/after 1.13.0 or dtk gateway
    payload = {
      type: "control_single_gateway",
      commandCode: 0x0002,
      gatewayID: gatewayObj.gatewayID,
    };
  }
  console.log(`[COMMAND OUT] restartGatewayProgram ${gatewayObj.gatewayID}`);
  await global.socket.emit("fromWeb", payload);
};
export const gwRestartDtkModule = async (gatewayObj, ttyS_no) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0011,
    commandName: "gwRestartDtkModule",
    gatewayID: gatewayObj.gatewayID,
    ttyS_no
  };
  console.log(`[COMMAND OUT] gwRestartDtkModule ${gatewayObj.gatewayID} ttyS_no ${ttyS_no}`);
  await global.socket.emit("fromWeb", payload);
};
export const gwQueryRemoteDtkModuleInfo = async (gatewayObj, targetDtkAddStr, ttyS_no) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0012,
    commandName: "gwQueryRemoteDtkModuleInfo",
    gatewayID: gatewayObj.gatewayID,
    targetDtkAddStr,
    ttyS_no
  };
  console.log(
    `[COMMAND OUT] gwQueryRemoteDtkModuleInfo ${gatewayObj.gatewayID} - target ${targetDtkAddStr} ttyS_no ${ttyS_no}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const gwRestartRemoteDtkModule = async (gatewayObj, targetDtkAddStr, ttyS_no) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0013,
    commandName: "gwRestartRemoteDtkModule",
    gatewayID: gatewayObj.gatewayID,
    targetDtkAddStr,
    ttyS_no
  };
  console.log(
    `[COMMAND OUT] gwRestartRemoteDtkModule ${gatewayObj.gatewayID} - target ${targetDtkAddStr} ttyS_no ${ttyS_no}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const updateGatewayFirmware = async (gatewayObj) => {
  let payload = {
    type: "control_single_gateway",
    commandCode: 0x0004,
    gatewayID: gatewayObj.gatewayID,
  };
  console.log(`[COMMAND OUT] updateGatewayFirmware ${gatewayObj.gatewayID}`);
  await global.socket.emit("fromWeb", payload);
};
export const deviceGatewayIsDisconnected = (state, deviceObj) => {
  // const deviceID=deviceObj.serial || deviceObj.sensorID ||  deviceObj.daliCtlID;
  // const deviceType=deviceObj.serial ? "light" : deviceObj.sensorID ? "sensor" : deviceObj.daliCtlID? "daliCtl":"unknown";
  const gatewayID = deviceObj.gatewayID;
  const gatewayObj = state.gatewayObjAll[gatewayID];
  const { gatewayAliveTimeStamp, gatewayConnected } = gatewayObj || {};
  const minDiff = moment().diff(moment(gatewayAliveTimeStamp), "minutes");
  if (minDiff < 5 && gatewayConnected && gatewayAliveTimeStamp) {
    return false;
  }
  else {
    console.log(`[deviceGatewayIsDisconnected] ${deviceObj.serial || deviceObj.sensorID} ${minDiff} ${gatewayConnected} ${gatewayAliveTimeStamp}`)
    return true;
  }
}
export const gatewayTestLED = async (gatewayObj) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x000f,
    gatewayID: gatewayObj.gatewayID,
  };
  console.log(`[COMMAND OUT] gatewayTestLED ${gatewayObj.gatewayID}`);
  await global.socket.emit("fromWeb", payload);
};
export const transferAllSettingsToGateway = async (gatewayObj) => {
  const payload = {
    type: "transferAllSettingsToGateway",
    gatewayID: gatewayObj.gatewayID,
    gatewayObj,
  };
  console.log(`[COMMAND OUT] transferAllSettingsToGateway ${gatewayObj.gatewayID}`);
  await global.socket.emit("fromWeb", payload);
};
export const queryGatewayOneSetting = async (gatewayID, commandCode, commandName) => {
  const payload = {
    type: "control_single_gateway",
    commandCode,
    gatewayID,
    commandName
  };
  console.log(
    `[COMMAND OUT] queryGatewayOneSetting ${gatewayID} - ${commandName} [${commandCode.toString(16).padStart(4, "0")}]`
  );
  await global.socket.emit("fromWeb", payload);
};
export const gatewaySendTestByteArray = async (gatewayID, ttyS_no, byteArray) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0019,
    gatewayID,
    byteArray,
    ttyS_no
  };
  console.log(
    `[COMMAND OUT] gatewaySendTestByteArray ${gatewayID} ${ttyS_no}`
  );
  await global.socket.emit("fromWeb", payload);
}
// export const queryGwSensorSetting = async (gatewayObj) => {
//   const payload = {
//     type: "control_single_gateway",
//     commandCode: 0x000d,
//     commandName: "queryGwSensorSetting",
//     gatewayID: gatewayObj.gatewayID,
//   };
//   console.log(`[COMMAND OUT] queryGwSensorSetting ${gatewayObj.gatewayID}`);
//   await global.socket.emit("fromWeb", payload);
// };
// export const queryGwTimeTable = async (gatewayObj) => {
//   const payload = {
//     type: "control_single_gateway",
//     commandCode: 0x0015,
//     commandName: "queryGwTimeTable",
//     gatewayID: gatewayObj.gatewayID,
//   };
//   console.log(`[COMMAND OUT] queryGwTimeTable ${gatewayObj.gatewayID}`);
//   await global.socket.emit("fromWeb", payload);
// };
export const changeGwEspNowInterval = async (gatewayObj, espNowIn) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0014,
    commandName: "changeEspNowInterval",
    gatewayID: gatewayObj.gatewayID,
    espNowIn,
  };
  console.log(
    `[COMMAND OUT] changeGwEspNowInterval ${gatewayObj.gatewayID} ${espNowIn}`
  );
  await global.socket.emit("fromWeb", payload);
};
// export const serverUpdateToDtkGateway = async (gatewayID) => {
//   const payload = {
//     type: "serverUpdateToDtkGateway",
//     gatewayID,
//   };
//   console.log(`[COMMAND OUT] serverUpdateToDtkGateway ${gatewayID}`);
//   await global.socket.emit("fromWeb", payload);
// };
export const updateGatewayToDeviceComAllow = async (gatewayID, deviceID, deviceType, updateObj) => {
  const payload = {
    type: "updateGatewayToDeviceComAllow",
    gatewayID,
    deviceID,
    deviceType,
    updateObj
  };
  console.log(`[COMMAND OUT] updateGatewayToDeviceComAllow ${gatewayID} ${deviceID}[${deviceType}] ${JSON.stringify(updateObj)}`);
  await global.socket.emit("fromWeb", payload);
}
export const sendStartSettingToDevice = async (deviceType, deviceID, gatewayID) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x001d,
    gatewayID,
    deviceType,
    deviceID,
  };
  console.log(
    `[COMMAND OUT] sendStartSettingToDevice 0x001d gateway:${gatewayID} deviceType:${deviceType} deviceID:${deviceID}`
  );
  await global.socket.emit("fromWeb", payload);
}
export const controlSingleGateway = async (gatewayID, commandCode, payloadObj) => {
  const payload = {
    type: "control_single_gateway",
    commandCode,
    gatewayID,
    ...payloadObj
  };
  console.log(
    `[COMMAND OUT] controlSingleGateway ${gatewayID} ${commandCode.toString(16).padStart(4, "0")} - ${JSON.stringify(payloadObj)}`
  );
  await global.socket.emit("fromWeb", payload);
}


///////DTK//////////////DTK//////////////DTK//////////////DTK//////////////DTK//////////////DTK//////////
export const gatewayQueryDtkInfo = async (gatewayID, ttyS_no) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0080,
    commandName: "gatewayQueryDtkInfo",
    gatewayID,
    ttyS_no,
  };
  await global.socket.emit("fromWeb", payload);
};
export const gatewayChangeDtkInfo = async (
  gatewayObj,
  dtkInfo,
  ttyS_no
) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0081,
    commandName: "gatewayChangeDtkInfo",
    gatewayID: gatewayObj.gatewayID,
    dtkAdd: gatewayObj.dtkAdd,
    dtkType: dtkInfo.dtkType,
    dtkPanID: dtkInfo.dtkPanID,
    dtkChannel: dtkInfo.dtkChannel,
    dtkTransferMode: dtkInfo.dtkTransferMode,
    definedAdd: dtkInfo.definedAdd,
    loraParameter: dtkInfo.loraParameter,
    ttyS_no: ttyS_no || "",
  };
  console.log(
    `[COMMAND OUT] gatewayChangeDtkInfo ${gatewayObj.gatewayID} [${gatewayObj.dtkAdd
    }] ${JSON.stringify(payload)}`
  );
  global.socket.emit("fromWeb", payload);
};

////////Mesh255//////////////Mesh255//////////////Mesh255//////////////Mesh255//////////////Mesh255//////////////Mesh255////////////

export const gatewayQueryMeshParameter = async (gatewayID, parameter, ttyS_no) => {
  const meshQueryCmd = {
    meshMacAddress: { commandCode: 0x008a, commandName: "gatewayQueryMeshMacAddress" },
    meshChannel: { commandCode: 0x0093, commandName: "gatewayChangeMeshChannel" },
    meshFreq: { commandCode: 0x0094, commandName: "gatewayQueryMeshFreq" },
    meshSpeed: { commandCode: 0x0095, commandName: "gatewayQueryMeshSpeed" },
    meshPower: { commandCode: 0x0096, commandName: "gatewayQueryMeshPower" },
    meshBaudrate: { commandCode: 0x0097, commandName: "gatewayQueryMeshBaudrate" },
    meshExtendedHeadEnable: { commandCode: 0x0099, commandName: "gatewayQueryMeshExtendedHeadEnable" },
    restartMeshModule: { commandCode: 0x009b, commandName: "gatewayRestartMeshModule" },
    meshNetworkID: { commandCode: 0x009c, commandName: "gatewayQueryMeshNetworkID" }
  };
  const payload = {
    type: "control_single_gateway",
    commandCode: meshQueryCmd[parameter].commandCode,
    commandName: meshQueryCmd[parameter].commandName,
    gatewayID,
    ttyS_no,
  };
  console.log(`[COMMAND OUT] ${meshQueryCmd[parameter].commandName} code ${meshQueryCmd[parameter].commandCode}`)
  await global.socket.emit("fromWeb", payload);
};
export const gatewayChangeMeshAdd = async (gatewayObj, add, comInterface) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x008c,
    commandName: "gatewayChangeMeshAdd",
    gatewayID: gatewayObj.gatewayID,
    dtkAdd: add,
    comInterface: comInterface || "",
  };
  await global.socket.emit("fromWeb", payload);
};
export const gatewayChangeMeshParameter = async (
  gatewayID,
  parameter,
  value,
  ttyS_no
) => {
  const meshParameterMap = {
    meshAdd: { commandCode: 0x008c, commandName: "gatewayChangeMeshAdd" },
    meshChannel: { commandCode: 0x008d, commandName: "gatewayChangeMeshChannel" },
    meshFreq: { commandCode: 0x008e, commandName: "gatewayChangeMeshFreq" },
    meshSpeed: { commandCode: 0x008f, commandName: "gatewayChangeMeshSpeed" },
    meshPower: { commandCode: 0x0090, commandName: "gatewayChangeMeshPower" },
    meshBaudrate: { commandCode: 0x0091, commandName: "gatewayChangeMeshBaudrate" },
    meshExtendedHeadEnable: { commandCode: 0x009a, commandName: "gatewayChangeMeshExtendedHeadEnable" }

  }
  let payload = {
    type: "control_single_gateway",
    commandCode: meshParameterMap[parameter].commandCode,
    commandName: meshParameterMap[parameter].commandName,
    gatewayID: gatewayID,
    ttyS_no: ttyS_no || "",
  };
  payload[parameter] = value;
  await global.socket.emit("fromWeb", payload);
};
export const gatewayChangeMeshFreq = async (
  gatewayObj,
  meshFreqCode,
  comInterface
) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x008e,
    commandName: "gatewayChangeMeshChannel",
    gatewayID: gatewayObj.gatewayID,
    meshFreqCode,
    comInterface: comInterface || "",
  };
  console.log(`[COMMAND OUT] [008e] gatewayChangeMeshFreq ${gatewayObj.gatewayID} ${meshFreqCode}`)
  await global.socket.emit("fromWeb", payload);
};
export const gatewayChangeMeshSpeed = async (
  gatewayObj,
  meshSpeedCode,
  comInterface
) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x008f,
    commandName: "gatewayChangeMeshSpeed",
    gatewayID: gatewayObj.gatewayID,
    meshSpeedCode,
    comInterface: comInterface || "",
  };
  console.log(`[COMMAND OUT] [008f] gatewayChangeMeshSpeed ${gatewayObj.gatewayID} ${meshSpeedCode}`)
  await global.socket.emit("fromWeb", payload);
};
export const gatewayChangeMeshPower = async (
  gatewayObj,
  meshPowerCode,
  comInterface
) => {
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0090,
    commandName: "gatewayChangeMeshPower",
    gatewayID: gatewayObj.gatewayID,
    meshPowerCode,
    comInterface: comInterface || "",
  };
  console.log(`[COMMAND OUT] [0090] gatewayChangeMeshPower ${gatewayObj.gatewayID} ${meshPowerCode}`)
  await global.socket.emit("fromWeb", payload);
};
export const executeRedisCommand = async (gatewayID, command) => {
  const payload = {
    type: "control_single_gateway",
    commandName: "executeRedisCommand",
    commandCode: 0x0017,
    gatewayID,
    command
  };
  console.log(`[COMMAND OUT] [0017] executeRedisCommand ${gatewayID} command: ${command}`);
  await global.socket.emit("fromWeb", payload);
}
export const queryDeviceTtyInterface = async (gatewayID) => {
  const payload = {
    type: "control_single_gateway",
    gatewayID,
    commandCode: 0x001c
  }
  console.log(`[COMMAND OUT] [001c] queryDeviceTtyInterface ${gatewayID}`);
  await global.socket.emit("fromWeb", payload);
}
export const queryGatewayToDeviceComAllow = async (gatewayID, deviceObj) => {
  const payload = {
    type: "control_single_gateway",
    gatewayID,
    dtkAdd: deviceObj?.dtkAdd || "",
    commandCode: 0x0020
  }
  console.log(`[COMMAND OUT] [0020] queryGatewayToDeviceComAllow ${gatewayID} ${deviceObj?.dtkAdd}`);
  await global.socket.emit("fromWeb", payload);
}
export const clearGatewayUnknownDevice = async (gatewayID) => {
  const payload = {
    type: "clearGatewayUnknownDevice",
    gatewayID
  }
  await global.socket.emit("fromWeb", payload);
}
export const gatewayConnectLightAndChangeChannel = async (gatewayID, oldGatewayID, serial, newChannelDec, fromUnknownDevice = false) => {
  const payload = {
    type: "gatewayConnectLightAndChangeChannel",
    gatewayID,
    oldGatewayID,
    serial,
    newChannel: parseInt(newChannelDec),
    fromUnknownDevice
  }
  console.log(`[COMMAND OUT] gatewayConnectLightAndChangeChannel ${serial} ${oldGatewayID} -> ${gatewayID}`);
  await global.socket.emit("fromWeb", payload);
}
export const gatewayConnectSensorAndChangeChannel = async (gatewayID, oldGatewayID, sensorID, newChannelDec, fromUnknownDevice = false) => {
  const payload = {
    type: "gatewayConnectSensorAndChangeChannel",
    gatewayID,
    oldGatewayID,
    sensorID,
    newChannel: parseInt(newChannelDec),
    fromUnknownDevice
  }
  console.log(`[COMMAND OUT] gatewayConnectSensorAndChangeChannel ${sensorID} ${oldGatewayID} -> ${gatewayID}`);
  await global.socket.emit("fromWeb", payload);
}
export const gatewayConnectDaliCtlAndChangeChannel = async (gatewayID, oldGatewayID, daliCtlID, newChannelDec, fromUnknownDevice = false) => {
  const payload = {
    type: "gatewayConnectDaliCtlAndChangeChannel",
    gatewayID,
    oldGatewayID,
    daliCtlID,
    newChannel: parseInt(newChannelDec),
    fromUnknownDevice
  }
  console.log(`[COMMAND OUT] gatewayConnectDaliCtlAndChangeChannel ${daliCtlID} ${oldGatewayID} -> ${gatewayID}`);
  await global.socket.emit("fromWeb", payload);
}

///////ESP/////////////////ESP////////////////////ESP/////////////////
export const queryEspNowRoute = async (gatewayID) => {
  const payload = {
    type: "control_single_gateway",
    commandName: "queryEspNowRoute",
    gatewayID,
    commandCode: 0x001b
  };
  console.log(`[COMMAND OUT] [001b] queryEspNowRoute ${gatewayID}`);
  await global.socket.emit("fromWeb", payload);
};
export const espCheckUnicast = async (gatewayID, senderObj, receiverObj, dispatch) => {
  if (!senderObj.wifiApMacAddress) {
    alertWindow(dispatch, "Sender device does not have wifiApMacAddress");
    return;
  }
  if (!receiverObj.wifiApMacAddress) {
    alertWindow(dispatch, "Receiver device does not have wifiApMacAddress");
    return;
  }
  const payload = {
    type: "control_single_gateway",
    commandName: "queryEspNowUsability",
    gatewayID,
    startMac: senderObj.wifiApMacAddress,
    endMac: receiverObj.wifiApMacAddress,
    commandCode: 0x001f
  };
  console.log(`[COMMAND OUT] [001f] espCheckUnicast ${gatewayID} - ${senderObj.wifiApMacAddress} -> ${receiverObj.wifiApMacAddress}`);
  await global.socket.emit("fromWeb", payload);
}
export const setEspNowRoute = async (gatewayID, targetDeviceObj, pathDeviceArray) => {
  const payload = {
    type: "control_single_gateway",
    commandName: "setEspNowRoute",
    gatewayID,
    commandCode: 0x0021,
    targetDeviceObj,
    pathDeviceArray,
  };
  console.log(`[COMMAND OUT] [0021] setEspNowRoute ${gatewayID} - target deviceID: ${targetDeviceObj.deviceID} -> path:${pathDeviceArray.map(obj => obj.deviceID).join("->")}`);
  // console.log("setEspNowRoute", payload);
  await global.socket.emit("fromWeb", payload);
}


///////Group Action Handler//////////////Group Action Handler//////////////Group Action Handler//////////////Group Action Handler//////////////Group Action Handler////////////
/**
 * Sends a command to a gateway to upsert a group action handler.
 *
 * @async
 * @param {string} gatewayID - The ID of the gateway to send the command to.
 * @param {Array} commandArray - The array of commands to send to the gateway.
 * @param {'light'|'sensor'|'daliCtl'} commandArray[].deviceType - The type of the device to send the command to .
 * @param {string} commandArray[].deviceID - The ID of the device to send the command to.
 * @param {string} commandArray[].command - The command to send to the device.
 * @param {string} commandArray[].dtkAdd - The DTK address of the device to send the command to.
 * @param {string} commandArray[].sensorID - The sensor ID of the device to send the command to.
 * @returns {Promise<void>} A Promise that resolves when the command has been sent.
 *
 * @example
 * gatewayUpsertGWGroupActionHandler('myGatewayID', ['command1', 'command2'])
 *   .then(() => console.log('Command sent'))
 *   .catch((err) => console.error('Failed to send command:', err));
 */
export const gatewayUpsertGWGroupActionHandler = async (gatewayID, commandArray, zoneControlID) => {
  console.log(`[COMMAND OUT] gatewayUpsertGWGroupActionHandler ${gatewayID} ${JSON.stringify(commandArray)}`);
  if (!commandArray)
    commandArray = [];
  if (!Array.isArray(commandArray))
    commandArray = [commandArray];
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0025,
    commandName: "upsertGWGroupActionHandler",
    gatewayID,
    commandArray,
  };
  if (zoneControlID) payload.zoneControlID = zoneControlID;
  await global.socket.emit("fromWeb", payload);
};
/**
 * 
 * @param {String} lightGatewayID 
 * @param {String} groupID 
 * @param {String} deviceID 
 * @param {"light"|"sensor"|"daliCtl"} deviceType 
 * @param {String} dtkAdd 
 * @param {Number[]} settingNumArray 
 * @param {String[]} commandStrArray 
 */
export const upsertOneDeviceGroupAction = async (lightGatewayID, groupID, deviceID, deviceType, dtkAdd, settingNumArray, commandStrArray) => {
  let msg = `[COMMAND OUT] upsertOneDeviceGroupAction gateway:${lightGatewayID} groupID:${groupID} device:${deviceID}`;
  let commandArray = settingNumArray.map((settingNum, key) => {
    let obj = {
      deviceType,
      deviceID,
      groupID,
      dtkAdd,
      settingNum,
      command: commandStrArray[key]
    }
    msg += ` setting${settingNum}:${commandStrArray[key]}`;
    return obj;
  });
  console.log(msg);
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0025,
    commandName: "upsertGWGroupActionHandler",
    gatewayID: lightGatewayID,
    groupID,
    deviceType,
    deviceID,
    commandArray,
  };
  await global.socket.emit("fromWeb", payload);

}
export const gatewayGroupActionCheck = async (gatewayID, groupID, deviceID, settingNum) => {
  console.log(`[COMMAND OUT] gatewayGroupActionCheck gateway:${gatewayID} groupID:${groupID} device:${deviceID} settingNum:${settingNum}`);
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x0026,
    commandName: "gatewayGroupActionCheck",
    gatewayID,
    groupID: groupID || null,
    deviceID: deviceID || null,
    settingNum: settingNum || null,
  };
  await global.socket.emit("fromWeb", payload);
};
/**
 * 
 * @param {String} lightGatewayID 
 * @param {String} groupID sensorID or zoneControlID without zc_
 * @param {String} deviceID serial sensorID or daliCtlID
 * @param {"light"|"sensor"|"daliCtl"} deviceType 
 * @param {String} dtkAdd
 * @param {Number[]} settingNumArray  99 to delete all settingNum
 */
export const deleteOneDeviceGroupAction = async (lightGatewayID, groupID, deviceID, deviceType, dtkAdd, settingNumArray) => {
  console.log(`[COMMAND OUT] deleteOneDeviceGroupAction gateway:${lightGatewayID} groupID:${groupID} device:${deviceID} settingNum:${settingNumArray.join(",")}`);

  let commandArray = settingNumArray.map(settingNum => {
    let obj = {
      deviceType,
      deviceID,
      groupID,
      dtkAdd,
      settingNum
    }
    return obj;
  });
  let payload = {
    type: "control_single_gateway",
    commandCode: 0x0027,
    commandName: "deleteGWGroupActionHandler",
    gatewayID: lightGatewayID,
    groupID,
    deviceType,
    deviceID,
    commandArray,
  };
  await global.socket.emit("fromWeb", payload);
};
export const queryGatewayGroupActionStatus = async (gatewayID) => {
  console.log(`[COMMAND OUT] queryGatewayGroupActionStatus gateway:${gatewayID}`);
  const payload = {
    type: "control_single_gateway",
    commandCode: 0x002e,
    commandName: "queryGatewayGroupActionStatus",
    gatewayID,
  };
  await global.socket.emit("fromWeb", payload);
}
