import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Tooltip } from "@mui/material";
import { titleText, dataText } from "asset/string/color";
import GatewayIcon from "components/GatewayIcon";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import { tspToDateTime } from "actions/generalActions";
import { mapAddDevice, mapRemoveDevice } from "actions/mapActions";
import { mgFindOne } from "actions/mongoApiActions";
import { getDocFS } from "actions/firebaseMgActions";
import { alertWindow } from "actions/screenActions";
import General from "@ecoenghk/general";
import DeviceMongoModal from "components/DeviceMongoModal";
import AdminContainer from "components/AdminContainer";
const gs = new General();

export default function SelectedGatewayInfo() {
  const [state, dispatch] = useContext(SMContext);
  const {
    selectedID,
    gatewayObjAll,
    activeMapID,
    mapObjAll,
    serverObj,
  } = state;
  const selectedDeviceObj = gatewayObjAll[selectedID] || {};
  const {
    gatewayAliveTimeStamp,
    serialMap,
    sensorMap,
    gatewayConnected,
    description,
    serverID,
    daliCtlMap,
    ttyS2,
    ttyS3
  } = selectedDeviceObj || {};
  const lightConnected = Object.keys(serialMap || {}).length;
  const sensorConnected = Object.keys(sensorMap || {}).length;
  const daliCtlConnected = Object.keys(daliCtlMap || {}).length;
  const unknownDevice =
    Object.keys(selectedDeviceObj || {}).length === 0 ? true : false;
  const handleAddToMap = async () => {
    let gatewayObj = await mgFindOne("gateway", "gatewayID", selectedID);
    if (!gatewayObj && serverObj.enableUploadFS) {
      gatewayObj = await getDocFS(`SM_gateway/${selectedID}`);
    }
    if (!gatewayObj) {
      alertWindow(
        dispatch,
        `Gateway ${selectedID} not found in local db and fs`
      );
      return;
    }
    const { x, y } = mapObjAll[activeMapID]?.gatewayObj || {};
    await mapAddDevice(activeMapID, selectedID, "gateway", gatewayObj, x, y);
  };
  const ttyS3Obj = selectedDeviceObj?.ttyS3 || {};
  const ttyS3Ch = `ch${ttyS3Obj?.meshChannel ?? ttyS3Obj.dtkChannel}`;
  const ttyS2Obj = selectedDeviceObj?.ttyS2 || {};
  const ttyS2Ch = `ch${ttyS2Obj?.meshChannel ?? ttyS2Obj.dtkChannel}`;

  return (
    <>
      <DivInline justifyContent="center">
        <DeviceMongoModal deviceObj={selectedDeviceObj} >
          <GatewayIcon width={8} sizeUnit="vw" connectStatus={gatewayConnected} />
        </DeviceMongoModal>
      </DivInline>
      <DataSet title="Gateway ID" data={selectedID} />
      <DivExist show={!unknownDevice}>
        <Typography style={{ color: dataText }} align="center">
          {description}
        </Typography>
        <InfoDivider />
        <AdminContainer>
          <Typography variant="caption" display="block" align="center" sx={{ color: titleText }}>
            Comm
          </Typography>
          <DivInline justifyContent="center">
            <Tooltip title="ttyS3">
              <Typography sx={{ color: dataText }} variant="caption" >{ttyS3Obj?.ttyS_type}</Typography>
            </Tooltip>
            <Typography sx={{ color: dataText, margin: "0 0.5vw" }}>/</Typography>
            <Tooltip title="ttyS2">
              <Typography sx={{ color: dataText }} variant="caption" >{ttyS2Obj?.ttyS_type} </Typography>
            </Tooltip>
          </DivInline>
          <hr color="lightgrey" size={1} width="90%" />
        </AdminContainer>
        <>
          <Typography variant="caption" display="block" align="center" sx={{ color: titleText }}>
            Channel
          </Typography>
          <DivInline justifyContent="center">
            <Tooltip title="ttyS3">
              <Typography sx={{ color: dataText }} variant="caption" >{ttyS3Ch}</Typography>
            </Tooltip>
            <Typography sx={{ color: dataText, margin: "0 0.5vw" }}>/</Typography>
            <Tooltip title="ttyS2">
              <Typography sx={{ color: dataText }} variant="caption" >{ttyS2Ch} </Typography>
            </Tooltip>
          </DivInline>
          <hr color="lightgrey" size={1} width="90%" />
        </>
        <DataSet title="Lights connected" data={lightConnected} />
        <DataSet title="Sensors connected" data={sensorConnected} />
        <DataSet title="Dali controllers connected" data={daliCtlConnected} />
        <DataSet
          title="Latest alive time"
          data={tspToDateTime(gatewayAliveTimeStamp)}
        />
        <DataSet title="Version" data={selectedDeviceObj.gwVersion} />
      </DivExist>
      <DivExist show={unknownDevice}>
        <Typography sx={{ color: dataText }} align="center">
          Unknown gateway
        </Typography>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={handleAddToMap}
        >
          Add to map
        </Button>
        <InfoDivider />
        <Button
          sx={{ margin: "0.5vh" }}
          size="small"
          variant="outlined"
          onClick={async () => mapRemoveDevice(activeMapID, selectedID, "gateway", selectedDeviceObj)}
        >
          Remove from map
        </Button>
      </DivExist>
    </>
  );
}

function DataSet({ title, data }) {
  return (
    <>
      <Typography
        variant="caption"
        display="block"
        align="center"
        style={{ color: titleText }}
      >
        {title}
      </Typography>
      <Typography
        style={{ color: dataText }}
        display="block"
        align="center"
        variant="caption"
      >
        {data}
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
    </>
  );
}

function InfoDivider() {
  return <hr color="lightgrey" size={1} width="90%" />;
}
