export const konvaShrinkAndBack = (ref, scale, duration = 0.2) => {
  const node = ref.current;
  node.to({
    scaleX: scale,
    scaleY: scale,
    duration: duration,
    onFinish: () => {
      node.to({
        scaleX: 1,
        scaleY: 1,
        duration: duration,
      });
    },
  });
};

export const konvaEnlargeOut = (ref, duration = 0.5) => {
  const node = ref.current;
  node.to({
    scaleX: 1,
    scaleY: 1,
    opacity: 1,
    onFinish: () => {
      node.to({
        scaleX: 2.5,
        scaleY: 2.5,
        opacity: 0,
        duration: duration,
        onFinish: () => {
          node.to({
            scaleX: 1,
            scaleY: 1,
          });
        },
      });
    },
  });
};
