import { SMProvider } from "./context/smProvider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainPage from "./MainPage";
import CoolBlackBackground from "./asset/image/cool-black-background.jpg";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./global";

const App = () => {
  const theme = createTheme({
    palette: { mode: "dark" },
  });
  return (
    <SMProvider>
      <ThemeProvider theme={theme}>
        <div
          style={{
            backgroundImage: `url(${CoolBlackBackground})`,
            backgroundSize: "cover",
            width: "100vw",
            minHeight: "100vh",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <MainPage />
          </LocalizationProvider>
        </div>
      </ThemeProvider>
    </SMProvider>
  );
};

export default App;
