import { useState, useContext, useMemo } from 'react';
import { SMContext } from "context/smContext";
import { Button, ButtonGroup, Typography, Box } from '@mui/material';
import ModalSM from 'components/ModalSM';
import DivCol from 'components/DivCol';
import DivInline from 'components/DivInline';
import Divider10 from 'components/Divider10';
import { confirmWindow, openSnackbar } from 'actions/screenActions';
import { uploadMultiDevicesToFS, downloadMultiDevicesToMg } from 'actions/serverActions';
import { mgFindInArray, mgUpdateOneUpsert } from 'actions/mongoApiActions';
import { getDocsWhereFS, updateDocFS } from 'actions/firebaseMgActions';
import DivExist from 'components/DivExist';
import General from '@ecoenghk/general';
import IconButtonRefresh from 'components/IconButtonRefresh';
const gs = new General();

export default function TransferMapDevicesFsToLs({ mapObjLs, mapObjFs }) {
    const [state, dispatch] = useContext(SMContext);
    const { mapID, mapName } = mapObjLs || {};
    const [open, setOpen] = useState(false);
    const [lightObjArrayFs, setLightObjArrayFs] = useState([]);
    const [lightObjArrayLs, setLightObjArrayLs] = useState([]);
    const [lightArrayAll, setLightArrayAll] = useState([]);
    const [sensorObjArrayFs, setSensorObjArrayFs] = useState([]);
    const [sensorObjArrayLs, setSensorObjArrayLs] = useState([]);
    const [sensorArrayAll, setSensorArrayAll] = useState([]);
    const [gatewayObjArrayFs, setGatewayObjArrayFs] = useState([]);
    const [gatewayObjArrayLs, setGatewayObjArrayLs] = useState([]);
    const [gatewayArrayAll, setGatewayArrayAll] = useState([]);
    const [daliCtlObjArrayFs, setDaliCtlObjArrayFs] = useState([]);
    const [daliCtlObjArrayLs, setDaliCtlObjArrayLs] = useState([]);
    const [daliCtlArrayAll, setDaliCtlArrayAll] = useState([]);
    const handleOpen = async () => {
        setOpen(true);
        await fetchLights();
        await fetchSensors();
        await fetchGateways();
        await fetchDaliCtls();
    }
    const fetchLights = async () => {
        const arrFs = await getDocsWhereFS("SM_serial", "array", "mapID", "array-contains", mapID);
        setLightObjArrayFs(arrFs);
        let arrLs = await mgFindInArray("serial", "mapID", mapID);
        if (arrLs.result === "fail") arrLs = [];
        setLightObjArrayLs(arrLs);
        let arrAll = arrFs.map(obj => obj.serial);
        arrLs.forEach(obj => {
            if (!arrAll.includes(obj.serial) && obj.serial) arrAll.push(obj.serial);
        });
        setLightArrayAll(arrAll);
    }
    const fetchSensors = async () => {
        const arrFs = await getDocsWhereFS("SM_sensor", "array", "mapID", "array-contains", mapID);
        setSensorObjArrayFs(arrFs);
        let arrLs = await mgFindInArray("sensor", "mapID", mapID);
        if (arrLs.result === "fail") arrLs = [];
        setSensorObjArrayLs(arrLs);
        let arrAll = arrFs.map(obj => obj.sensorID);
        arrLs.forEach(obj => {
            if (!arrAll.includes(obj.sensorID) && obj.sensorID) arrAll.push(obj.sensorID);
        });
        setSensorArrayAll(arrAll);
    }
    const fetchGateways = async () => {
        const arrFs = await getDocsWhereFS("SM_gateway", "array", "mapID", "array-contains", mapID);
        setGatewayObjArrayFs(arrFs);
        let arrLs = await mgFindInArray("gateway", "mapID", mapID);
        if (arrLs.result === "fail") arrLs = [];
        setGatewayObjArrayLs(arrLs);
        let arrAll = arrFs.map(obj => obj.gatewayID);
        arrLs.forEach(obj => {
            if (!arrAll.includes(obj.gatewayID) && obj.gatewayID) arrAll.push(obj.gatewayID);
        });
        setGatewayArrayAll(arrAll);
    }
    const fetchDaliCtls = async () => {
        const arrFs = await getDocsWhereFS("SM_daliCtl", "array", "mapID", "array-contains", mapID);
        setDaliCtlObjArrayFs(arrFs);
        let arrLs = await mgFindInArray("daliCtl", "mapID", mapID);
        if (arrLs.result === "fail") arrLs = [];
        setDaliCtlObjArrayLs(arrLs);
        let arrAll = arrFs.map(obj => obj.daliCtlID);
        arrLs.forEach(obj => {
            if (!arrAll.includes(obj.daliCtlID) && obj.daliCtlID) arrAll.push(obj.daliCtlID);
        });
        setDaliCtlArrayAll(arrAll);
    }
    const serialArrLs = useMemo(() => lightObjArrayLs.map(obj => obj.serial), [lightObjArrayLs]);
    const serialArrFs = useMemo(() => lightObjArrayFs.map(obj => obj.serial), [lightObjArrayFs]);
    const sensorIDArrLs = useMemo(() => sensorObjArrayLs.map(obj => obj.sensorID), [sensorObjArrayLs]);
    const sensorIDArrFs = useMemo(() => sensorObjArrayFs.map(obj => obj.sensorID), [sensorObjArrayFs]);
    const gatewayArrLs = useMemo(() => gatewayObjArrayLs.map(obj => obj.gatewayID), [gatewayObjArrayLs]);
    const gatewayArrFs = useMemo(() => gatewayObjArrayFs.map(obj => obj.gatewayID), [gatewayObjArrayFs]);
    const daliCtlIDArrLs = useMemo(() => daliCtlObjArrayLs.map(obj => obj.daliCtlID), [daliCtlObjArrayLs]);
    const daliCtlIDArrFs = useMemo(() => daliCtlObjArrayFs.map(obj => obj.daliCtlID), [daliCtlObjArrayFs]);
    const deviceArrObjFs = { light: serialArrFs, sensor: sensorIDArrFs, gateway: gatewayArrFs, daliCtl: daliCtlIDArrFs };
    const deviceArrObjLs = { light: serialArrLs, sensor: sensorIDArrLs, gateway: gatewayArrLs, daliCtl: daliCtlIDArrLs };
    const handleTransferFsToLs = async (deviceType) => {
        confirmWindow(dispatch, `Confirm transfer ${deviceType} from firestore to server?`, async () => {
            if (deviceType === "light") await downloadMultiDevicesToMg("SM_serial", "serial", "serial", serialArrFs);
            else await downloadMultiDevicesToMg(`SM_${deviceType}`, deviceType, `${deviceType}ID`, deviceArrObjFs[deviceType]);
        });
    }
    const handleTransferLsToFs = async (deviceType) => {
        confirmWindow(dispatch, `Confirm transfer the ${deviceType} from server to firestore?`, async () => {
            if (deviceType === "light") await uploadMultiDevicesToFS("serial", "SM_serial", "serial", serialArrLs);
            else await uploadMultiDevicesToFS(deviceType, `SM_${deviceType}`, `${deviceType}ID`, deviceArrObjLs[deviceType]);
        });
    }
    const handleTransferMapObjFsToLs = async () => {
        confirmWindow(dispatch, `Confirm transfer map object from firestore to server?`, async () => {
            let updateObj = { ...mapObjFs };
            delete updateObj._id;
            const res = await mgUpdateOneUpsert("map", { mapID }, updateObj);
            alert(JSON.stringify(res));
        });
    }
    const handleTransferMapObjLsToFs = async () => {
        confirmWindow(dispatch, `Confirm transfer map object from server to firestore?`, async () => {
            let updateObj = { ...mapObjLs };
            delete updateObj._id;
            const res = await updateDocFS(`SM_map/${mapID}`, updateObj);
            alert(JSON.stringify(res));
        });
    }
    return (
        <>
            <button onClick={handleOpen}>{`FS\u{27fa}Server`}</button>
            {
                open &&
                <ModalSM
                    open={open}
                    onClose={() => setOpen(false)}
                    modalTitle={`Transfer Map ${mapName} [${mapID}] Devices FS \u{27fa} Server`}
                    width="97vw"
                    height="95vh"
                >
                    <Divider10 />
                    <DivInline alignItems='flex-start'>
                        <DivCol justifyContent='flex-start' alignItems='flex-start' sx={{ borderRight: "1px solid grey" }} >
                            <Box sx={{ height: "12vh", borderBottom: "1px solid #fff" }}>
                                <DivInline>
                                    <Typography>Map Object</Typography>
                                    <IconButtonRefresh onBtnClick={() => { }} />
                                </DivInline>
                                < ButtonGroup variant="outlined" size="small">
                                    <Button disabled={!mapObjLs || gs.isEmptyJson(mapObjLs)} onClick={() => handleTransferMapObjLsToFs()}>{`Server\u{0027a1}FS`}</Button>
                                    <Button disabled={!mapObjFs || gs.isEmptyJson(mapObjFs)} onClick={() => handleTransferMapObjFsToLs()}>{`Server\u{002b05}FS`}</Button>
                                </ButtonGroup>
                                <DivInline>
                                    <Typography sx={{ width: "18vw" }}>Server</Typography>
                                    <Typography sx={{ width: "18vw" }}>FS</Typography>
                                </DivInline>
                            </Box>
                            <DivInline alignItems='flex-start'  >
                                <DivCol alignItems='flex-start' justifyContent='flex-start' sx={{ borderRight: "1px solid grey" }} >

                                    <DivExist show={!mapObjLs || gs.isEmptyJson(mapObjLs)}>
                                        <Typography sx={{ width: "18vw" }}>No map object in Server</Typography>
                                    </DivExist>
                                    {
                                        Object.keys(mapObjLs || {})
                                            .sort((a, b) => a.localeCompare(b))
                                            .map(key => {
                                                let val = mapObjLs[key];
                                                if (typeof val === "object") val = JSON.stringify(val);
                                                else if (Array.isArray(val)) val = val.join(", ");
                                                else if (typeof val === "boolean") val = val.toString();
                                                else if (typeof val === "string") val = `"${val}"`;
                                                return (
                                                    <DivInline key={key}>
                                                        <Typography variant="caption" sx={{ width: "6vw", flexWrap: "wrap", wordWrap: "break-word" }}>{key}</Typography>
                                                        <Typography variant="caption" sx={{ width: "12vw", flexWrap: "wrap", wordWrap: "break-word" }}>{val}</Typography>
                                                    </DivInline>
                                                )
                                            }

                                            )
                                    }
                                </DivCol>
                                <DivCol alignItems='flex-start' justifyContent='flex-start' >
                                    <DivExist show={!mapObjFs || gs.isEmptyJson(mapObjFs)}>
                                        <Typography sx={{ width: "18vw" }}>No map object in FS</Typography>
                                    </DivExist>
                                    {
                                        Object.keys(mapObjFs || {})
                                            .sort((a, b) => a.localeCompare(b))
                                            .map(key => {
                                                let val = mapObjFs[key];
                                                if (typeof val === "object") val = JSON.stringify(val);
                                                else if (Array.isArray(val)) val = val.join(", ");
                                                else if (typeof val === "boolean") val = val.toString();
                                                else if (typeof val === "string") val = `"${val}"`;
                                                return (
                                                    <DivInline key={key}>
                                                        <Typography variant="caption" sx={{ width: "6vw", flexWrap: "wrap", wordWrap: "break-word" }}>{key}</Typography>
                                                        <Typography variant="caption" sx={{ width: "12vw", flexWrap: "wrap", wordWrap: "break-word" }}>{val}</Typography>
                                                    </DivInline>
                                                )
                                            }

                                            )
                                    }
                                </DivCol>
                            </DivInline>
                        </DivCol>
                        <DivCol justifyContent='flex-start' sx={{ borderRight: "1px solid grey" }} >
                            <Box sx={{ height: "12vh", borderBottom: "1px solid #fff" }}>
                                <Typography align="center">Lights [{lightObjArrayFs.length}]</Typography>
                                < ButtonGroup variant="outlined" size="small">
                                    <Button onClick={() => handleTransferLsToFs("light")}>{`Server\u{0027a1}FS`}</Button>
                                    <Button onClick={() => handleTransferFsToLs("light")}>{`Server\u{002b05}FS`}</Button>
                                </ButtonGroup>
                                <DivInline>
                                    <Typography sx={{ width: "8vw" }} >serial</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >Server</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >FS</Typography>
                                </DivInline>
                            </Box>
                            {
                                lightArrayAll.map((s, key) => {
                                    return (
                                        <DivInline key={key}>
                                            <Typography sx={{ width: "8vw" }} >{s}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{serialArrLs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{serialArrFs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                        </DivInline>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' sx={{ borderRight: "1px solid grey" }}  >
                            <Box sx={{ height: "12vh", borderBottom: "1px solid #fff" }}>
                                <Typography align="center">Sensors [{sensorObjArrayFs.length}]</Typography>
                                < ButtonGroup variant="outlined" size="small">
                                    <Button onClick={() => handleTransferLsToFs("sensor")}>{`Server\u{0027a1}FS`}</Button>
                                    <Button onClick={() => handleTransferFsToLs("sensor")}>{`Server\u{002b05}FS`}</Button>
                                </ButtonGroup>
                                <DivInline >
                                    <Typography sx={{ width: "6vw" }} >sensorID</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >Server</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >FS</Typography>
                                </DivInline>
                            </Box>
                            {
                                sensorArrayAll.map((s, key) => {
                                    return (
                                        <DivInline key={key}>
                                            <Typography sx={{ width: "6vw" }} >{s}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{sensorIDArrLs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{sensorIDArrFs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                        </DivInline>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' sx={{ borderRight: "1px solid grey" }}  >
                            <Box sx={{ height: "12vh", borderBottom: "1px solid #fff" }}>
                                <Typography align="center">DaliCtls [{daliCtlObjArrayFs.length}]</Typography>
                                < ButtonGroup variant="outlined" size="small">
                                    <Button onClick={() => handleTransferLsToFs("daliCtl")}>{`Server\u{0027a1}FS`}</Button>
                                    <Button onClick={() => handleTransferFsToLs("daliCtl")}>{`Server\u{002b05}FS`}</Button>
                                </ButtonGroup>
                                <DivInline >
                                    <Typography sx={{ width: "6vw" }} >daliCtlID</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >Server</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >FS</Typography>
                                </DivInline>
                            </Box>
                            {
                                daliCtlArrayAll.map((s, key) => {
                                    return (
                                        <DivInline key={key}>
                                            <Typography sx={{ width: "6vw" }} >{s}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{daliCtlIDArrLs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{daliCtlIDArrFs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                        </DivInline>
                                    )
                                })
                            }
                        </DivCol>
                        <DivCol justifyContent='flex-start' sx={{ borderRight: "1px solid grey" }} >
                            <Box sx={{ height: "12vh", borderBottom: "1px solid #fff" }}>
                                <Typography align="center">Gateways [{gatewayObjArrayFs.length}]</Typography>
                                < ButtonGroup variant="outlined" size="small">
                                    <Button onClick={() => handleTransferFsToLs("gateway")}>{`Server\u{0027a1}FS`}</Button>
                                    <Button onClick={() => handleTransferLsToFs("gateway")}>{`Server\u{002b05}FS`}</Button>
                                </ButtonGroup>
                                <DivInline >
                                    <Typography sx={{ width: "6vw" }} >gatewayID</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >Server</Typography>
                                    <Typography align="center" sx={{ width: "4vw" }} >FS</Typography>
                                </DivInline>
                            </Box>
                            {
                                gatewayArrayAll.map((s, key) => {
                                    return (
                                        <DivInline key={key}>
                                            <Typography sx={{ width: "6vw" }} >{s}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{gatewayArrLs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                            <Typography align="center" sx={{ width: "4vw" }} >{gatewayArrFs.includes(s) ? '\u{1f7e2}' : ""}</Typography>
                                        </DivInline>
                                    )
                                })
                            }
                        </DivCol>
                    </DivInline>
                </ModalSM>
            }
        </>
    )
}