import React from "react";
import { Group, Circle, Rect } from "react-konva";
import DaliCtl from "asset/mapComp/DaliCtl";
import OnOffIcon from "asset/mapComp/OnOffIcon";
import Bypass from "asset/mapComp/Bypass";

export default function MapIconDaliCtl({
    x = 0,
    y = 0,
    iconSize,
    mapScale,
    // onClick,
    // onTap,
    // onMouseOver,
    // onMouseLeave,
    connectStatus,
    powerOnOffStatus,
    disabledConnectStatus = false,
    disabledPowerOnOffStatus = false,
    bypass = 0,
    disabledBypass = false,
}) {
    const size = iconSize * mapScale.x;
    return (
        <Group x={x} y={y}>
            <DaliCtl
                size={size}
                connectStatus={connectStatus}
                disabledConnectStatus={disabledConnectStatus}
            />
            {
                !disabledPowerOnOffStatus && !powerOnOffStatus && (
                    <OnOffIcon
                        x={size * 0.8}
                        y={-size * 0.1}
                        size={size * 0.4}
                        onoff="off"
                    />
                )
            }
            {
                !disabledBypass && bypass === 1 && (
                    <Bypass x={size * 0.8} y={size * 0.6} size={size * 0.5} />
                )
            }
        </Group>
    );
}