import React, { useContext } from "react";
import { SMContext } from "context/smContext";
import { Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DivInline from "components/DivInline";
import IconButtonBack from "components/IconButtonBack";
import ThemeContainer from "components/ThemeContainer";
import GatewayIcon from "components/GatewayIcon";
import SuperAdminPanel from "./SuperAdminPanel";
import { gatewayConnected } from "actions/generalActions";
import AdminContainer from "components/AdminContainer";
import UnknwonDevicePanel from "./UnknwonDevicePanel";
import BindedTimeTableSettingPanel from "./BindedTimeTableSettingPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import GatewayInfoPanel from "./GatewayInfoPanel";
import DataHistoryPanel from "./DataHistoryPanel";
import Divider10 from "components/Divider10";
import ConnectDevicesPanel from "./ConnectDevicesPanel";
import { useSocketListenToWeb } from "hooks/socketHooks";
import General from "@ecoenghk/general";
const gs = new General();
const boxH = "88vh";
const boxW = ["36vw", "30vw", "30vw"];
const boxSx = {
    padding: "0.5vw",
    margin: "1vh 0.5vw 1vh 0.5vw",
    border: "0.5px solid grey",
    borderRadius: "1vw",
    overflowY: "scroll",
    height: boxH,
};
export default function GatewayDetailPage() {
    const [state, dispatch] = useContext(SMContext);
    const navigate = useNavigate();
    useSocketListenToWeb(navigate);
    const { activeGatewayID, gatewayObjAll } = state;
    const gatewayObj = gatewayObjAll[activeGatewayID];
    const handleClose = () => {
        dispatch({ type: "CLOSED_GATEWAY_DETAIL_MODAL" });
        dispatch({
            type: "GATEWAY_REPLIED_LATEST_FIRMWARE_VER",
            payload: {},
        });
        navigate("/MapPage")
    };
    const gwConnected = gatewayConnected(gatewayObj);
    return (<>
        <ThemeContainer background="dark">
            <Paper>
                <DivInline>
                    <IconButtonBack onBtnClick={handleClose} />
                    <DeviceMongoModal deviceObj={gatewayObj}>
                        <GatewayIcon width={3} sizeUnit="vw" connectStatus={gwConnected} backgroundColor="#fff" />
                    </DeviceMongoModal>
                    <Typography sx={{ marginLeft: "1vw" }}>{`${gatewayObj?.description} [${activeGatewayID}]`}</Typography>
                </DivInline>
                <DivInline>
                    {/* /////////////Information///////////////////////////////Information///////////////////// */}
                    <div style={{ width: boxW[0], ...boxSx, }}>
                        <GatewayInfoPanel />
                        <AdminContainer>
                            <SuperAdminPanel />
                        </AdminContainer>
                    </div>
                    {/* /////////////Data & History///////////////////////////////Data & History///////////////////// */}
                    <div style={{ width: boxW[1], ...boxSx, }}>
                        <DataHistoryPanel />
                        <Divider10 />
                        <BindedTimeTableSettingPanel />
                        <Divider10 />
                        <UnknwonDevicePanel />
                    </div>
                    {/* /////////////Connect devices///////////////////////////////Connect devices///////////////////// */}
                    <div style={{ width: boxW[2], ...boxSx, }}>
                        <ConnectDevicesPanel />
                    </div>
                </DivInline>
            </Paper>
        </ThemeContainer>
    </>
    );
}
