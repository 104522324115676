import { useState, useEffect, Fragment } from "react";
import { Group, Text, Circle, Line } from "react-konva";
import Gateway from "asset/mapComp/Gateway";
import KonvaFaButton from "../device/KonvaFaButton";
import {
  confirmWindow,
  showBigProgress,
  hideBigProgress,
  alertWindow,
} from "actions/screenActions";
import Tick from "asset/mapComp/Tick";
import DeviceLight from "../device/DeviceLight";
import DeviceSensor from "../device/DeviceSensor";
import RubbishBin from "asset/mapComp/RubbishBin";
import Cross from "asset/mapComp/Cross";
import DivExist from "components/DivExist";
import {
  gatewayDisconnectDaliCtl,
  gatewayConnectDaliCtl,
  gatewayConnectDaliCtlAndChangeChannel,
  gatewayConnectLight,
  gatewayConnectLightAndChangeChannel,
  gatewayDisconnectLight,
  gatewayDisconnectSensor,
  gatewayConnectSensor,
  gatewayConnectSensorAndChangeChannel,
} from "actions/gatewayActions";
import DeviceZoneControl from "../device/DeviceZoneControl";
import DeviceDaliCtl from "../device/DeviceDaliCtl";
import General from "@ecoenghk/general";
import { sensorDisconnectAllLights } from "actions/sensorActions";
const gs = new General();

export default function LayerGatewaySelect({ layerProps }) {
  const {
    layerRef,
    activeMapID,
    mapObjAll,
    dispatch,
    lightObjAll,
    sensorObjAll,
    gatewayObjAll,
    zoneControlObjAll,
    daliCtlObjAll,
    enablePan,
    mapScale,
    iconSize,
    selectedGatewayID,
    selectedID,
    selectedDeviceType,
    selectedDeviceObj,
    shiftSelectedArray,
    selectedDeviceX,
    selectedDeviceY,
    layerScale,
  } = layerProps;
  const activeMapObj = mapObjAll[activeMapID];
  const [scale, setScale] = useState(1);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;

  const mapGatewayObj = activeMapObj?.gatewayObj;
  const gwArray = Object.keys(gatewayObjAll || {}).filter((g) =>
    Object.keys(mapGatewayObj || {}).includes(g)
  );
  const selectedDeviceGateway = selectedDeviceObj.gatewayID;
  const handleSelectGateway = (g) => {
    dispatch({ type: "SET_SELECTED_GATEWAY", payload: g });
  };
  const handleUnSelectGateway = () => {
    dispatch({ type: "SET_SELECTED_GATEWAY", payload: "" });
  };
  const backToNormal = () => {
    hideBigProgress(dispatch);
    handleUnSelectGateway();
    // dispatch({ type: "CLEARED_SHIFT_SELECT" });
    dispatch({ type: "ALL_UNSELECTED" });
    dispatch({
      type: "SET_MAP_LAYER",
      payload: {
        normal: true,
        light: false,
        gatewaySelect: false,
      },
    });
  };
  const handleDisconnectGateway = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect ${selectedID} from ${selectedDeviceGateway}?`,
      async () => {
        showBigProgress(dispatch);
        if (selectedDeviceType === "light") {
          await gatewayDisconnectLight(selectedDeviceObj);
          backToNormal();
        } else if (selectedDeviceType === "sensor") {
          if (Object.keys(selectedDeviceObj?.serialMap || {}).length > 0) {
            await sensorDisconnectAllLights(selectedDeviceObj);
            await gs.waitFor(500);
          }
          await gatewayDisconnectSensor(selectedDeviceObj);
          backToNormal();
        } else if (selectedDeviceType === "daliCtl") {
          await gatewayDisconnectDaliCtl(selectedDeviceObj);
          backToNormal();
        }
      }
    );
  };
  const handleConnect = async (g, isChangeChannel, newChannel) => {
    console.log("handleConnect", g, isChangeChannel, newChannel);
    const selectedGatewayObj = gatewayObjAll[g];
    const selectedDeviceBothTtysIsMesh255 = selectedGatewayObj?.ttyS3?.ttyS_type === "mesh255" && selectedGatewayObj?.ttyS2?.ttyS_type === "mesh255";
    console.log("selectedDeviceBothTtysIsMesh255", selectedDeviceBothTtysIsMesh255);
    if (!selectedGatewayObj.zigbeeAdd && !selectedGatewayObj.dtkAdd) {
      alertWindow(dispatch, `gateway address missing, connect fail`);
      return;
    }
    if (selectedGatewayObj.zigbeeAdd && !selectedDeviceObj.zigbeeAdd) {
      alertWindow(dispatch, `device zigbee address missing, connect fail`);
      return;
    }
    if (selectedGatewayObj.dtkAdd && !selectedDeviceObj.dtkAdd) {
      alertWindow(dispatch, `device dtk address missing, connect fail`);
      return;
    }
    showBigProgress(dispatch);
    if (selectedDeviceType === "light") {
      isChangeChannel && selectedDeviceBothTtysIsMesh255 ? await gatewayConnectLightAndChangeChannel(g, selectedDeviceObj?.gatewayID || null, selectedDeviceObj.serial, newChannel) :
        await gatewayConnectLight(g, selectedGatewayObj, selectedDeviceObj);
      backToNormal();
    } else if (selectedDeviceType === "sensor") {
      isChangeChannel && selectedDeviceBothTtysIsMesh255 ? await gatewayConnectSensorAndChangeChannel(g, selectedDeviceObj?.gatewayID || null, selectedDeviceObj.sensorID, newChannel) :
      await gatewayConnectSensor(g, selectedGatewayObj, selectedDeviceObj);
      backToNormal();
    } else if (selectedDeviceType === "daliCtl") {
      let go = false;
      if (selectedGatewayObj.daliType === "dalimqtt" && selectedDeviceObj.daliType === "dalimqtt") {
        console.log("connect dalimqtt");
        go = true;
      } else if (selectedDeviceObj.daliType === "dalidtk" && selectedDeviceObj.daliType !== "dalimqtt") {
        console.log("connect dalidtk");
        go = true;
      }
      if (go) {
        isChangeChannel && selectedDeviceBothTtysIsMesh255 ? await gatewayConnectDaliCtlAndChangeChannel(g, selectedDeviceObj?.gatewayID || null, selectedDeviceObj.daliCtlID, newChannel) :
        await gatewayConnectDaliCtl(g, selectedGatewayObj, selectedDeviceObj);
        backToNormal();
      } else {
        alertWindow(
          dispatch,
          "Dali controller and gateway type not match, connect fail"
        );
      }
    }
  };
  return (
    <>
      {selectedDeviceType === "light" && (
        <DeviceLight
          layerProps={layerProps}
          x={activeMapObj?.lightObj?.[selectedID]?.x * mapScale.x || 0}
          y={activeMapObj?.lightObj?.[selectedID]?.y * mapScale.y || 0}
          serial={selectedID}
          lightObj={lightObjAll[selectedID]}
        />
      )}
      {selectedDeviceType === "sensor" && (
        <DeviceSensor
          layerProps={layerProps}
          x={activeMapObj?.sensorObj?.[selectedID]?.x * mapScale.x || 0}
          y={activeMapObj?.sensorObj?.[selectedID]?.y * mapScale.y || 0}
          sensorID={selectedID}
          sensorObj={sensorObjAll[selectedID]}
        />
      )}
      {selectedDeviceType === "zoneControl" && (
        <DeviceZoneControl
          layerProps={layerProps}
          x={activeMapObj?.zoneControlObj?.[selectedID]?.x * mapScale.x || 0}
          y={activeMapObj?.zoneControlObj?.[selectedID]?.y * mapScale.y || 0}
          zoneControlID={selectedID}
          zoneControlObj={zoneControlObjAll[selectedID]}
        />
      )}
      {selectedDeviceType === "daliCtl" && (
        <DeviceDaliCtl
          layerProps={layerProps}
          x={activeMapObj?.daliCtlObj?.[selectedID]?.x * mapScale.x || 0}
          y={activeMapObj?.daliCtlObj?.[selectedID]?.y * mapScale.y || 0}
          daliCtlID={selectedID}
          daliCtlObj={daliCtlObjAll[selectedID]}
        />
      )}
      <DivExist show={selectedDeviceGateway ? true : false}>
        <Line
          points={[
            selectedDeviceX,
            selectedDeviceY,
            mapGatewayObj?.[selectedDeviceGateway]?.x * mapScale.x,
            mapGatewayObj?.[selectedDeviceGateway]?.y * mapScale.y,
          ]}
          stroke="pink"
          strokeWidth={2 / layerScale || 1}
        />
      </DivExist>
      {gwArray.map((g) => {
        const { x, y } = activeMapObj?.gatewayObj[g] || {};
        const groupX = x * mapScale.x;
        const groupY = y * mapScale.y;
        const groupBtnX =
          x < 500 ? x * mapScale.x : x * mapScale.x - 1 * btnSpace;
        const groupBtnY =
          y < 300 ? y * mapScale.y + iconSize * 1.4 : y * mapScale.y - btnSpace;

        return (
          <Fragment key={g}>
            <Group
              x={groupX || 0}
              y={groupY || 0}
              onClick={() => handleSelectGateway(g)}
              onMouseEnter={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "pointer";
              }}
              onMouseLeave={(e) => {
                const container = e.target.getStage().container();
                container.style.cursor = "default";
              }}
            >
              <Gateway
                size={iconSize * mapScale.x}
                shadEnabled={false}
                disabledConnectStatus
                outline={selectedDeviceGateway === g}
              />
              <Circle
                x={iconSize * mapScale.x * 0.5}
                y={iconSize * mapScale.y * 0.5}
                radius={iconSize * mapScale.x}
                stroke={selectedGatewayID === g ? "red" : ""}
                strokeWidth={2 / scale}
              />

              <Text
                fontSize={12 / scale}
                text={g}
                y={iconSize * mapScale.y * 1.1}
                fill="green"
              />
            </Group>
            <Group x={groupBtnX || 0} y={groupBtnY || 0}>
              {selectedGatewayID === g && (
                <>
                  {selectedDeviceGateway === selectedGatewayID ? (
                    <KonvaFaButton
                      onBtnClick={handleDisconnectGateway}
                      x={0}
                      y={0}
                      enablePan={enablePan}
                      stageScale={scale}
                      btnSize={btnSize}
                      mapScale={mapScale}
                      // fa_code={`\uf1f8`}
                      iconSvg={<RubbishBin x={0} y={0} size={btnSize} />}
                    />
                  ) : (
                    <>
                      {gatewayObjAll[g]?.ttyS3?.meshChannel && selectedDeviceObj?.gatewayID && gatewayObjAll[selectedDeviceGateway]?.gatewayConnected? 
                      <KonvaFaButton
                        onBtnClick={async () => {
                          if (gatewayObjAll[g]?.ttyS3?.ttyS_type == "mesh255") {
                            handleConnect(g, true, gatewayObjAll[g]?.ttyS3?.meshChannel);
                          }
                        }}
                        x={0}
                        y={0}
                        enablePan={enablePan}
                        stageScale={scale}
                        btnSize={btnSize}
                        mapScale={mapScale}
                        iconSvg={<Tick x={0} y={0} size={btnSize} />}
                        tooltip={`${gatewayObjAll[g]?.ttyS3?.ttyS_type} ch: ${gatewayObjAll[g]?.ttyS3?.meshChannel}`}
                      /> : gatewayObjAll[g]?.ttyS2?.meshChannel && selectedDeviceObj?.gatewayID && gatewayObjAll[selectedDeviceGateway]?.gatewayConnected?
                        <KonvaFaButton
                          onBtnClick={async () => {
                            if (gatewayObjAll[g]?.ttyS2?.ttyS_type == "mesh255") {
                              handleConnect(g, true, gatewayObjAll[g]?.ttyS2?.meshChannel);
                            }
                          }}
                          x={0}
                          y={0}
                          enablePan={enablePan}
                          stageScale={scale}
                          btnSize={btnSize}
                          mapScale={mapScale}
                          iconSvg={<Tick x={0} y={0} size={btnSize} />}
                          tooltip={`${gatewayObjAll[g]?.ttyS2?.ttyS_type} ch: ${gatewayObjAll[g]?.ttyS2?.meshChannel}`}
                        /> :
                        <KonvaFaButton
                          onBtnClick={async () =>
                            await handleConnect(g,true, gatewayObjAll[g]?.ttyS3?.meshChannel)
                          }
                          x={0}
                          y={0}
                          enablePan={enablePan}
                          stageScale={scale}
                          btnSize={btnSize}
                          mapScale={mapScale}
                          iconSvg={<Tick x={0} y={0} size={btnSize} />}
                        />
                      }
                    </>
                  )}

                  <KonvaFaButton
                    onBtnClick={handleUnSelectGateway}
                    x={btnSpace}
                    y={0}
                    enablePan={enablePan}
                    stageScale={scale}
                    btnSize={btnSize}
                    mapScale={mapScale}
                    iconSvg={<Cross x={0} y={0} size={btnSize} />}
                  />
                </>
              )}
            </Group>
          </Fragment>
        );
      })}
    </>
  );
}