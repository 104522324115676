import HomePage from "containers/home/HomePage";
import LoginPage from "containers/login/LoginPage";
import LoginFsPage from "containers/login/LoginFsPage";
import MapPage from "containers/map/MapPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WindowConfirm from "components/WindowConfirm";
import WindowAlert from "components/WindowAlert";
import WindowPrompt from "components/WindowPrompt";
import SnackbarMsg from "components/SnackbarMsg";
import BigProgress from "components/BigProgress";
import Upload from "containers/publicPage/Upload";
import ResetPassword from "containers/publicPage/ResetPassword";
import ResetPasswordSuper from "containers/publicPage/ResetPasswordSuper";
import GatewayDetailPage from "containers/gateway/GatewayDetailPage";
import LightDetailPage from "containers/serial/LightDetailPage";
import SensorDetailPage from "containers/sensor/SensorDetailPage";
import DaliCtlDetailPage from "containers/daliCtl/DaliCtlDetailPage";
import ZoneControlDetailPage from "containers/zoneCtl/ZoneControlDetailPage";
import BatTestTimeTablePage from "containers/testReport/BatTestTimeTablePage";
import ToolboxPage from "containers/server/toolboxPage";
export default function MainPage() {
  let curUrl = window.location.href;

  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/LoginPage" element={<LoginPage />} />
          <Route path="/HomePage" element={<HomePage />} />
          <Route path="/MapPage" element={<MapPage />} />
          <Route path="/LoginFsPage" element={<LoginFsPage />} />
          {/* <Route
            path="/"
            element={
              curUrl.includes("skymon.web.app") ? (
                <LoginFsPage />
              ) : (
                <LoginPage />
              )
            }
          /> */}
          <Route path="/" element={<LoginPage />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/resetPasswordSuper" element={<ResetPasswordSuper />} />
          <Route path="/GatewayDetailPage" element={<GatewayDetailPage />} />
          <Route path="/LightDetailPage" element={<LightDetailPage />} />
          <Route path="/SensorDetailPage" element={<SensorDetailPage />} />
          <Route path="/DaliCtlDetailPage" element={<DaliCtlDetailPage />} />
          <Route path="/ZoneControlDetailPage" element={<ZoneControlDetailPage />} />
          <Route path="/BatTestTimeTablePage" element={<BatTestTimeTablePage />} />
          <Route path="/ToolboxPage" element={<ToolboxPage />} />
        </Routes>
      </BrowserRouter>
      <BigProgress />
      <SnackbarMsg />
      <WindowConfirm />
      <WindowAlert />
      <WindowPrompt />
    </div>
  );
}
