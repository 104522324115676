import { useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import {
  updateSerialProperty,
  enablingAirconControl,
  airconSwitchOn,
  airconSwitchOff,
  airconSetTemp,
} from "actions/lightActions";
import DivExist from "components/DivExist";
import General from "@ecoenghk/general";
import Divider10 from "components/Divider10";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import { ArrowForwardIos } from "@mui/icons-material";
import { promptWindow } from "actions/screenActions";
const gs = new General();
export default function AirconPanel() {
  const [state, dispatch] = useContext(SMContext);
  const {
    userObj,
    activeSerial,
    lightObjAll,
    mapObjAll,
    zoneControlObjAll,
    sensorObjAll,
  } = state;
  const lightObj = lightObjAll[activeSerial];
  const handleUpdateLightProps = async (updateObj) => {
    await updateSerialProperty(activeSerial, lightObj, updateObj);
  };
  return (
    <DivInline>
      <div
        style={{
          width: "38vw",
          height: "82vh",
          margin: 5,
          overflowY: "scroll",
          border: "0.5px solid grey",
          borderRadius: "8px",
          padding: 12,
        }}
      >
        <SpaceBetweenDiv title="Serial no" data={activeSerial} />
        <SpaceBetweenDialogInput
          title="Description"
          data={lightObj.description || ""}
          dialogTitle="Change light description"
          handleSave={async (val) =>
            await handleUpdateLightProps({ description: val })
          }
          showValue
        />
        <SpaceBetweenSelect
          title="Aircon type"
          data={lightObj.acType || 0}
          onChange={(e) => {
            handleUpdateLightProps({ acType: Number(e.target.value) });
          }}
          menuObj={{
            0: "None",
            2: "Daikin128",
            3: "Daikin160",
            4: "Daikin216",
            5: "Toshiba",
          }}
        />
        <SpaceBetweenDiv title="Gateway" data={lightObj.gatewayID} />

        <DivExist show={lightObj.dtkAdd}>
          <SpaceBetweenDiv title="Address" data={lightObj.dtkAdd} />
        </DivExist>
        <Divider10 />
        <SpaceBetweenButton
          title="Change to a Aircon remote control"
          btnContent="Change"
          onBtnClick={async () => {
            await handleUpdateLightProps({
              enableAirconControl: 0,
              style: "tube",
              acType: 0,
            });
            await gs.waitFor(800);
            await enablingAirconControl(lightObj, false);
          }}
        />
      </div>
      <div
        style={{
          width: "35vw",
          height: "82vh",
          margin: 5,
          overflowY: "scroll",
          border: "0.5px solid grey",
          borderRadius: "8px",
          padding: 12,
        }}
      >
        <SpaceBetweenButton
          title="Switch ON"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => airconSwitchOn(lightObj)}
          marginBottom="1vw"
        />

        <SpaceBetweenButton
          title="Switch OFF"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => airconSwitchOff(lightObj)}
          marginBottom="1vw"
        />

        <SpaceBetweenButton
          title="Set temperature"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => {
            promptWindow(
              dispatch,
              "Set temperature",
              (val) => {
                airconSetTemp(lightObj, Number(val));
              },
              24
            );
          }}
          marginBottom={"1vw"}
        />
      </div>
    </DivInline>
  );
}
