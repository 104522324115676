import { Typography, Button } from "@mui/material";
import DivInline from "./DivInline";
import IconButtonRefresh from "./IconButtonRefresh";

export default function SpaceBetweenButton({
  title,
  btnContent,
  onBtnClick,
  variant,
  marginTop,
  marginBottom,
  color,
  disabled,
  btnSize,
  hideComponent,
  handleRefresh,
}) {
  return (
    <>
      {!hideComponent && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: marginTop ?? 0,
            marginBottom: marginBottom ?? 0,
          }}
        >
          <DivInline>
            <Typography>{title}</Typography>
            {handleRefresh && (
              <IconButtonRefresh onBtnClick={handleRefresh} />
            )}
          </DivInline>
          <Button
            variant={variant || "outlined"}
            onClick={onBtnClick}
            color={color ? color : "primary"}
            disabled={disabled}
            size={btnSize || "medium"}
          >
            {btnContent}
          </Button>
        </div>
      )}
    </>
  );
}
