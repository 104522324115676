import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import {
  Typography,
  Box,
  Slider,
  Button,
  ButtonGroup,
  IconButton,
  Tooltip,
} from "@mui/material";
import DivInline from "components/DivInline";
import { FastForward, CopyAll } from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import LightIcon from "components/LightIcon";
import {
  sensorDisconnectLight,
  updateSensorProperty,
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import {
  confirmWindow,
  hideBigProgress,
  openSnackbar,
  showBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import IconButtonDone from "components/IconButtonDone";
import SensorIcon from "components/SensorIcon";
import SmallButton from "components/SmallButton";
import { deviceModalPos } from "actions/mapActions";
const gs = new General();

export default function SenDaylightMotionLightModal({
  x,
  y,
  open,
  handleClose,
  layerProps,
  selectedLight,
  sensorID,
  sensorObj,
}) {
  const [ctlA_arr, setCtlA_arr] = useState([]);
  const [pwmArr, setPwmArr] = useState([]);
  const [delayedOnOff, setDelayedOnOff] = useState(0);
  const [delayedPwm, setDelayedPwm] = useState(10);
  const { iconSize, lightObjAll, dispatch, daliCtlObjAll, socket } = layerProps;
  const lightObj = lightObjAll[selectedLight];
  useEffect(() => {
    let arrayA = [],
      arrayPwm = [],
      donoff = 0,
      dpwm = 10;
    for (let i = 1; i <= 11; i++) {
      const serialSetting = sensorObj?.[`setting${i}`]?.[selectedLight];
      const ctlA = serialSetting?.onoffcontrolA ?? 1;
      const pwm = serialSetting?.pwm || 0;
      arrayA = [...arrayA, ctlA];
      arrayPwm = [...arrayPwm, pwm];
      donoff = serialSetting?.delayedOnOff || 0;
      dpwm = serialSetting?.delayedPwm || 0;
    }
    setDelayedOnOff(donoff);
    setDelayedPwm(dpwm);
    setCtlA_arr(arrayA);
    setPwmArr(arrayPwm);
  }, [open]);

  let pwmMarks = [];
  for (let i = 20; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  const updateSetting = async (index, settingField, value) => {
    if (settingField === "onoffcontrolA") {
      let newA = [...ctlA_arr];
      newA[index] = value;
      setCtlA_arr(newA);
    } else if (settingField === "pwm") {
      let newPwm = [...pwmArr];
      newPwm[index] = value;
      setPwmArr(newPwm);
    }
  };

  const handleSave = async () => {
    let updateObj = {};
    for (let i = 1; i <= 11; i++) {
      let setting = sensorObj?.[`setting${i}`];
      let serialSetting = setting?.[selectedLight];
      if (serialSetting) {
        serialSetting.onoffcontrolA = ctlA_arr[i - 1];
        serialSetting.pwm = pwmArr[i - 1];
        serialSetting.delayedOnOff = delayedOnOff;
        serialSetting.delayedPwm = delayedPwm;
        setting = { ...setting, [selectedLight]: serialSetting };
        updateObj[`setting${i}`] = setting;
      }
    }
    console.log(updateObj);
    const did = sensorObj.daliCtlID;
    const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    await updateSensorProperty(sensorID, sensorObj, gid, updateObj);
    handleClose();
  };
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect light [${selectedLight}]?`,
      async () => {
        showBigProgress(dispatch);
        const did = sensorObj.daliCtlID;
        const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
        await sensorDisconnectLight(sensorObj, gid, lightObj);
        hideBigProgress(dispatch);
        handleClose();
      }
    );
  };
  const handleChangeWorkMode = (newMode) => {
    let newArrA = [],
      newArrPwm = [];
    if (newMode === "near") {
      newArrA = [0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1];
      newArrPwm = [90, 90, 80, 70, 60, 40, 30, 10, 0, 0, 0];
    } else if (newMode === "middle") {
      newArrA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      newArrPwm = [90, 90, 90, 90, 90, 80, 70, 60, 50, 40, 30];
    } else if (newMode === "far") {
      newArrA = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      newArrPwm = [90, 90, 90, 80, 80, 80, 80, 70, 70, 70, 70];
    }
    setCtlA_arr(newArrA);
    setPwmArr(newArrPwm);
  };
  const handleCopyLightSetting = async () => {
    confirmWindow(
      dispatch,
      "Confirm copy setting to other lights?",
      async () => {
        let updateObj = {};
        for (let i = 1; i <= 11; i++) {
          let setting = sensorObj?.[`setting${i}`];
          Object.keys(setting || {}).forEach((s) => {
            let serialSetting = setting?.[s];
            serialSetting.onoffcontrolA = ctlA_arr[i - 1];
            serialSetting.pwm = pwmArr[i - 1];
            setting = { ...setting, [s]: serialSetting };
          });
          updateObj[`setting${i}`] = setting;
        }
        console.log(updateObj);
        const did = sensorObj.daliCtlID;
        const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
        await updateSensorProperty(sensorID, sensorObj, gid, updateObj);
        openSnackbar(dispatch, "Setting copied to all other lights");
      }
    );
  };
  const modalWidth = window.innerWidth * 0.5;
  const modalHeight = window.innerHeight * 0.5;
  const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
  return (
    <MapModal
      x={pos.x}
      y={pos.y}
      open={open}
      handleCloseModal={handleClose}
      disableCloseButton
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <DivInline>
        <IconButtonClose onBtnClick={handleClose} />
        <LightIcon
          lightStyle={lightObj?.style}
          width={3}
          sizeUnit="vw"
          disabledConnectStatus
          disabledStatus
        />
        <Typography
          variant="caption"
          sx={{ width: "16vw", marginRight: "0.5vw" }}
        >
          {`${lightObj?.description} [${selectedLight}]`}
        </Typography>

        <Typography variant="caption" sx={{ width: "5vw" }}>
          Default settings:
        </Typography>
        <SmallButton
          btnContent="Near window"
          onClick={() => handleChangeWorkMode("near")}
        />
        <SmallButton
          btnContent="4-8 from window"
          onClick={() => handleChangeWorkMode("middle")}
        />
        <SmallButton
          btnContent={`>8m from window`}
          onClick={() => handleChangeWorkMode("far")}
        />
      </DivInline>
      <Divider10 />
      <DivInline>
        <SensorIcon
          type="motion"
          status={1}
          width={3}
          sizeUnit="vw"
          disabledConnectStatus
        />
        <Typography sx={{ marginLeft: "0.5vw" }}>Motion ON</Typography>
      </DivInline>
      <div>
        {gs.newArrayBetween(0, 10).map((n) => {
          return (
            <DivInline
              key={n}
              style={{ width: "44vw", height: "5vh" }}
              justifyContent="space-between"
            >
              <DivInline>
                {n === 10 ? (
                  <Typography
                    sx={{ width: "10vw" }}
                    variant="caption"
                  >{`11.  >1000lux`}</Typography>
                ) : (
                  <Typography sx={{ width: "10vw" }} variant="caption">
                    {`${n + 1}.  ${n * 100}-${n * 100 + 99}`}lux
                  </Typography>
                )}
                <SensorIcon type="daylight" luxLv={n} disabledConnectStatus />
              </DivInline>
              <FastForward style={{ fontSize: "0.8rem" }} />
              <Box
                sx={{
                  outline: ctlA_arr[n] === 0 ? "1px solid red" : "",
                  cursor: "pointer",
                }}
                onClick={() => updateSetting(n, "onoffcontrolA", 0)}
              >
                <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
              </Box>
              <Box
                sx={{
                  outline: ctlA_arr[n] === 1 ? "1px solid red" : "",
                  cursor: "pointer",
                }}
                onClick={() => updateSetting(n, "onoffcontrolA", 1)}
              >
                <LightBulb onoff="off" width={2.5} sizeUnit="vw" />
              </Box>
              <Box>
                <DivExist show={lightObj?.type?.pwm && ctlA_arr[n] === 0}>
                  <Slider
                    size="small"
                    sx={{ width: "16vw" }}
                    value={pwmArr[n] || 0}
                    onChangeCommitted={(e, val) =>
                      updateSetting(n, "pwm", Number(val))
                    }
                    marks={pwmMarks}
                    max={100}
                    min={0}
                    step={10}
                  />
                </DivExist>
              </Box>
            </DivInline>
          );
        })}
        <Divider10 />
        <DivInline>
          <SensorIcon
            type="motion"
            status={0}
            width={3}
            sizeUnit="vw"
            disabledConnectStatus
          />
          <Typography sx={{ marginLeft: "0.5vw" }}>Motion OFF</Typography>
        </DivInline>
        <DivInline
          justifyContent="space-between"
          style={{ width: "44vw", height: "5vh" }}
        >
          <Typography variant="caption">Any light level</Typography>
          <FastForward style={{ fontSize: "0.8rem" }} />
          <Box
            sx={{
              outline: delayedOnOff === 0 ? "1px solid red" : "",
              cursor: "pointer",
            }}
            onClick={() => setDelayedOnOff(0)}
          >
            <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
          </Box>
          <Box
            sx={{
              outline: delayedOnOff === 1 ? "1px solid red" : "",
              cursor: "pointer",
            }}
            onClick={() => setDelayedOnOff(1)}
          >
            <LightBulb onoff="off" width={2.5} sizeUnit="vw" />
          </Box>
          <Box>
            <DivExist show={lightObj?.type?.pwm && delayedOnOff === 0}>
              <Slider
                size="small"
                sx={{ width: "16vw" }}
                value={delayedPwm || 0}
                onChangeCommitted={(e, val) => {
                  setDelayedPwm(Number(val));
                }}
                marks={pwmMarks}
                max={100}
                min={0}
                step={10}
              />
            </DivExist>
          </Box>
        </DivInline>
        <Divider10 />
      </div>

      <IconButtonClose
        tooltip="Close dialog without saving"
        onBtnClick={handleClose}
      />
      <IconButtonDone
        tooltip="Save settings and close dialog"
        onBtnClick={handleSave}
      />
      <IconButtonDelete
        tooltip="Disconnect light from sensor"
        onBtnClick={handleDisconnect}
      />
      <IconButton onClick={handleCopyLightSetting} size="small">
        <Tooltip title="Copy setting to other lights">
          <CopyAll />
        </Tooltip>
      </IconButton>
    </MapModal>
  );
}
