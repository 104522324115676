import React from "react";
import { Typography, Checkbox } from "@mui/material";
import DivInline from "./DivInline";
import IconButtonRefresh from "./IconButtonRefresh";

/**
 * 
 * @param {object} props
 * @param {string} props.title 
 * @param {"body1"|"body2"|"caption"|"subtitle1"|"h6"} [props.titleVariant] 
 * @param {boolean} props.data 
 * @param {function(object)} props.onCheck  function argument- event
 * @param {"medium"| "small"} [props.checkboxSize]
 * @param {"primary"|"secondary"|"inherit"} [props.checkboxColor]
 * @param {boolean} props.hide
 * @param {function} props.handleRefresh
 * @returns 
 * @example
 * <SpaceBetweenCheckbox
      title={<Typography>Enable xxx</Typography>}
      data={xxxStatus}
      onCheck={(evt) => setStatus(evt.target.checked)}
    />
 */
export default function SpaceBetweenCheckbox({
  title,
  titleVariant = "body1",
  data,
  onCheck,
  checkboxSize,
  checkboxColor,
  hide,
  handleRefresh,
}) {
  return (
    <React.Fragment>
      {!hide ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <DivInline>
            <Typography variant={titleVariant}>{title}</Typography>
            {handleRefresh && (
              <IconButtonRefresh onBtnClick={handleRefresh} />
            )}
          </DivInline>
          <Checkbox
            checked={data || false}
            onChange={onCheck}
            size={checkboxSize ? checkboxSize : "medium"}
            color={checkboxColor ? checkboxColor : "primary"}
          />
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
}
// export default SpaceBetweenCheckbox;

// SpaceBetweenCheckbox.protoTypes = {
//   title: PropTypes.any.isRequired,
//   data: PropTypes.bool.isRequired,
//   onCheck: PropTypes.func.isRequired,
//   checkboxSize: PropTypes.oneOf(["medium", "small"]),
//   checkboxColor: PropTypes.oneOf(["primary", "secondary", "inherit"]),
// };
