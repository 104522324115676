import { useState, useContext, useMemo, useEffect } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { Typography, Box, Tooltip, IconButton, ButtonGroup, Button, Menu, MenuItem } from "@mui/material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import { Map, Storage } from "@mui/icons-material";
import moment from "moment";
import PodcastsIcon from '@mui/icons-material/Podcasts';
import DeviceIcon from "components/DeviceIcon";
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { clearGatewayUnknownDevice, gatewaySendTestByteArray } from "actions/gatewayActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import { mgFindByMatch, mgFindManyInArray, mgUpdateOneUpsert } from "actions/mongoApiActions";
import { CircularProgress } from "@mui/material";
import { gatewayConnectLight, gatewayConnectSensor, gatewayConnectDaliCtl, gatewayConnectLightAndChangeChannel, gatewayConnectSensorAndChangeChannel, gatewayConnectDaliCtlAndChangeChannel } from "actions/gatewayActions";
import WifiChannelIcon from '@mui/icons-material/WifiChannel';
import { mapAddDevice } from "actions/mapActions";
import AdminContainer from "components/AdminContainer";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import ModalSM from "components/ModalSM";
import CardLight from "components/CardLight";
import CardSensor from "components/CardSensor";
import CardDaliCtl from "components/CardDaliCtl";
import General from "@ecoenghk/general";
import { confirmWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import { setPower } from "actions/lightActions";
const gs = new General();
export default function UnknwonDevicePanel() {
  const [state, dispatch] = useContext(SMContext);
  const { activeGatewayID, gatewayObjAll, lightObjAll, sensorObjAll, daliCtlObjAll, activeMapID } = state;
  const gatewayObj = gatewayObjAll[activeGatewayID];
  const unknownDtkDevice = gatewayObj.unknownDtkDevice || {};
  const [UnknwonDeviceMenuAnchorEl, setUnknwonDeviceMenuAnchorEl] = useState(null);
  const [unknwonDeviceMenuOpen, setUnknwonDeviceMenuOpen] = useState(false);
  const [unknownDeviceObj, setUnknownDeviceObj] = useState({})
  const [getUnknownDeviceObjLoading, setGetUnknownDeviceObjLoading] = useState(false)
  const [activeMenuDeviceObj, setActiveMenuDeviceObj] = useState({})
  const [enableChangeChannel, setEnableChangeChannel] = useState(gatewayObj?.ttyS3?.ttyS_type == "mesh255" && gatewayObj?.ttyS2?.ttyS_type == "mesh255" && gatewayObj?.ttyS3?.meshChannel != undefined && gatewayObj?.ttyS2?.meshChannel != undefined)
  const [openFirestoreCheck, setOpenFirestoreCheck] = useState(false)
  const handleUnknownDeviceMenuClose = () => {
    setUnknwonDeviceMenuAnchorEl(null);
    setUnknwonDeviceMenuOpen(false);
  };
  const serialDtkAddMap = useMemo(() => {
    const map = {}
    Object.keys(lightObjAll).forEach(serial => {
      map[lightObjAll[serial]?.dtkAdd] = lightObjAll[serial]
    })
    Object.keys(sensorObjAll).forEach(sensorID => {
      map[sensorObjAll[sensorID]?.dtkAdd] = sensorObjAll[sensorID]
    })
    Object.keys(daliCtlObjAll).forEach(daliCtlID => {
      map[daliCtlObjAll[daliCtlID]?.dtkAdd] = daliCtlObjAll[daliCtlID]
    })
    Object.keys(unknownDeviceObj).forEach(deviceID => {
      map[unknownDeviceObj[deviceID]?.dtkAdd] = unknownDeviceObj[deviceID]
    })
    return map
  }, [lightObjAll, sensorObjAll, daliCtlObjAll, unknownDeviceObj]);
  const handleUnknownDeviceMenuClick = (event, dtkAdd) => {
    setUnknwonDeviceMenuAnchorEl(event.currentTarget);
    setUnknwonDeviceMenuOpen(true);
    const deviceObj = serialDtkAddMap[dtkAdd] || {}
    setActiveMenuDeviceObj(deviceObj)
  };
  const handleFlashLight = (dtkAdd, start, type, e) => {
    e.stopPropagation()
    e.preventDefault()
    let payload = {}
    switch (type) {
      case "light": {
        payload = {
          type: "control_single_light",
          gatewayID: activeGatewayID,
          commandCode: start ? 0x0236 : 0x0237,
          dtkAdd,
        }
        break;
      }
      case "sensor": {
        payload = {
          type: "control_single_sensor",
          gatewayID: activeGatewayID,
          commandCode: start ? 0x0636 : 0x0637,
          dtkAdd,
        }
        break;
      }
      case "daliCtl": {
        payload = {
          type: "control_dali_ctl",
          gatewayID: activeGatewayID,
          commandCode: start ? 0x0a37 : 0x0a38,
          dtkAdd,
        }
        break;
      }
    }
    console.log(payload);
    global.socket.emit("fromWeb", payload);
  }

  const fetchGatewayUnknownDevicesObjec = async (dtkAddAry) => {
    if (dtkAddAry.length == 0) return
    setGetUnknownDeviceObjLoading(true)
    const light_res = await mgFindManyInArray("serial", "dtkAdd", dtkAddAry)
    const sensor_res = await mgFindManyInArray("sensor", "dtkAdd", dtkAddAry)
    const daliCtl_res = await mgFindManyInArray("daliCtl", "dtkAdd", dtkAddAry)
    const deviceObj = {}
    light_res.forEach(obj => {
      deviceObj[obj.serial] = obj
    })
    sensor_res.forEach(obj => {
      deviceObj[obj.sensorID] = obj
    })
    daliCtl_res.forEach(obj => {
      deviceObj[obj.daliCtlID] = obj
    })
    setGetUnknownDeviceObjLoading(false)
    setUnknownDeviceObj(deviceObj)
  }

  const FlashLightButtonGroup = ({ dtkAdd, type }) => {
    return (
      <ButtonGroup variant="outlined" size="small">
        <Button
          onClick={(e) => {
            handleFlashLight(dtkAdd, true, type, e)
          }}
        >
          <DeviceIcon deviceType={type} width={1.4} />
          <Typography display="block" variant="caption">Flash</Typography>
        </Button>
        <Button
          onClick={(e) => {
            handleFlashLight(dtkAdd, false, type, e)
          }}
        >
          <DeviceIcon deviceType={type} width={1.4} />
          <Typography display="block" variant="caption">Stop</Typography>
        </Button>
      </ButtonGroup>
    )
  }

  const handleConnectLight = (deviceObj) => {
    const { serial, sensorID, daliCtlID } = deviceObj
    const type = serial ? "light" : sensorID ? "sensor" : daliCtlID ? "daliCtl" : "unknown"
    const deviceID = serial || sensorID || daliCtlID || null
    switch (type) {
      case "light": {
        if (enableChangeChannel && gatewayObj?.ttyS3?.meshChannel != undefined && deviceID)
          gatewayConnectLightAndChangeChannel(activeGatewayID, activeGatewayID, deviceID, gatewayObj?.ttyS3?.meshChannel, true)
        else
          gatewayConnectLight(activeGatewayID, gatewayObj, deviceObj)
        if (!((deviceObj?.mapID || []).includes(activeMapID)))
          mapAddDevice(activeMapID, deviceID, "light", deviceObj);
        break;
      }
      case "sensor": {
        if (enableChangeChannel && gatewayObj?.ttyS3?.meshChannel != undefined && deviceID)
          gatewayConnectSensorAndChangeChannel(activeGatewayID, activeGatewayID, deviceID, gatewayObj?.ttyS3?.meshChannel, true)
        else
          gatewayConnectSensor(activeGatewayID, gatewayObj, deviceObj)
        if (!((deviceObj?.mapID || []).includes(activeMapID)))
          mapAddDevice(activeMapID, deviceID, "sensor", deviceObj);
        break;
      }
      case "daliCtl": {
        if (enableChangeChannel && gatewayObj?.ttyS3?.meshChannel != undefined && deviceID)
          gatewayConnectDaliCtlAndChangeChannel(activeGatewayID, activeGatewayID, deviceID, gatewayObj?.ttyS3?.meshChannel, true)
        else
          gatewayConnectDaliCtl(activeGatewayID, gatewayObj, deviceObj)
        if (!((deviceObj?.mapID || []).includes(activeMapID)))
          mapAddDevice(activeMapID, deviceID, "daliCtl", deviceObj);
        break;
      }
      default: {
        console.log("unknown device type")
      }
    }
  }
  const handleAddToUnknownDevice = async (obj) => {
    console.log(obj);
    const deviceID = obj.serial || obj.sensorID || obj.daliCtlID
    let newUnknownDeviceObj = { ...unknownDtkDevice, [deviceID]: obj };
    setUnknownDeviceObj(newUnknownDeviceObj);
  }
  const handleAddToMap = (mapID, deviceID, type, deviceObj) => {
    confirmWindow(dispatch, `Add ${deviceID} to map?`, () => {
      mapAddDevice(mapID, deviceID, type, deviceObj);
    });
  }
  return (
    <AdminContainer>
      <DivInline justifyContent="center">
        <Typography
          variant="h6"
          align="center"
          onClick={() => console.log(unknownDtkDevice, unknownDeviceObj)}
        >
          Unknwon Device
        </Typography>
        <Tooltip title={"broadcast ping"}>
          <IconButton onClick={() => gatewaySendTestByteArray(activeGatewayID, "ttyS2&3", [0xf0, 0x06, 0x00, 0x00, 0x0e, 0x01, 0x00, 0x00, 0x05, 0xff])} >
            <PodcastsIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={"clear"}>
          <IconButton onClick={() => clearGatewayUnknownDevice(activeGatewayID)} >
            <CleaningServicesIcon />
          </IconButton>
        </Tooltip>
        {
          getUnknownDeviceObjLoading ?
            <CircularProgress size={20} />
            :
            <Tooltip title={"find unknown devices"}>
              <IconButton onClick={() => fetchGatewayUnknownDevicesObjec(Object.keys(unknownDtkDevice || {}))}>
                <FindInPageIcon />
              </IconButton>
            </Tooltip>
        }
        <DivInline>
          <Tooltip title={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="caption">device change channel on connect</Typography>
              <Typography variant="caption">
                {
                  (gatewayObj?.ttyS3?.ttyS_type == "mesh255" && gatewayObj?.ttyS3?.meshChannel != undefined) ? "✅" : "❌"
                }
                ttyS3: ch {gatewayObj?.ttyS3?.meshChannel || "??"}
              </Typography>
              <Typography variant="caption">
                {
                  (gatewayObj?.ttyS2?.ttyS_type == "mesh255" && gatewayObj?.ttyS2?.meshChannel != undefined) ? "✅" : "❌"
                }
                ttyS2: ch {gatewayObj?.ttyS3?.meshChannel || "??"}
              </Typography>
            </div>}>
            <IconButton onClick={() => {
              setEnableChangeChannel(!enableChangeChannel)
            }}>
              <WifiChannelIcon color={enableChangeChannel ? "success" : "disabled"} />
            </IconButton>
          </Tooltip>
          {
            enableChangeChannel && gatewayObj?.ttyS3?.ttyS_type == "mesh255" && gatewayObj?.ttyS2?.ttyS_type == "mesh255" &&
            <Typography variant="caption">ch {gatewayObj?.ttyS3?.meshChannel || "not found"}</Typography>
          }
        </DivInline>
      </DivInline>
      <Divider10 />
      {
        Object.keys(unknownDtkDevice).length > 0 && Object.keys(unknownDtkDevice).map((dtkAdd, index) => {
          const { type, receivedCommandCode, ttyS_no, timeStamp } = unknownDtkDevice[dtkAdd];
          const deviceObj = serialDtkAddMap[dtkAdd] || {}
          const deviceID = deviceObj?.serial || deviceObj?.sensorID || deviceObj?.daliCtlID || "unknown"
          return (
            <Box key={index} sx={{ borderBottom: "0.5px solid grey" }}>
              <DivInline key={dtkAdd} justifyContent="space-between" >
                <DivInline>
                  <DeviceIcon deviceType={type} width={1.4} />
                  <Typography variant="caption">{`${deviceID}@${dtkAdd}`}</Typography>
                  <Typography variant="caption" sx={{ marginLeft: "10px" }}>{gatewayObj[ttyS_no]?.ttyS_type || "???"}({ttyS_no})</Typography>
                  <DivExist show={ttyS_no !== "ttyS7"}>
                    <Typography variant="caption" sx={{ marginLeft: "10px" }}>ch.{gatewayObj[ttyS_no]?.meshChannel || "??"}</Typography>
                  </DivExist>
                  <Typography variant="caption" sx={{ marginLeft: "10px" }}>0x{(receivedCommandCode || "").toString(16).padStart(4, 0)}</Typography>
                  <DivExist show={deviceID === "unknown"}>
                    <DatabaseCheckModal dtkAdd={dtkAdd} deviceType={type} onChecked={handleAddToUnknownDevice} />
                  </DivExist>
                </DivInline>
                <Tooltip title={timeStamp ? moment.unix(timeStamp).format("YYYY-MM-DD HH:mm:ss") : "N/A"}>
                  <Typography display="block" variant="caption">{timeStamp ? moment.unix(timeStamp).fromNow() : "N/A"}</Typography>
                </Tooltip>
              </DivInline>
              <DivInline>
                <ButtonGroup variant="outlined" size="small">
                  <FlashLightButtonGroup dtkAdd={dtkAdd} type={type} />
                  <DivExist show={deviceID !== "unknown"}>
                    <Button variant="outlined" size="small" onClick={() => handleAddToMap(activeMapID, deviceID, type, deviceObj)}>Add to map</Button>
                  </DivExist>
                  {/* <Button
                    aria-controls={unknwonDeviceMenuOpen ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={unknwonDeviceMenuOpen ? 'true' : undefined}
                    onClick={(e) => handleUnknownDeviceMenuClick(e, dtkAdd)}
                  >
                    <MoreHorizIcon />
                  </Button> */}
                  <UnknownDeviceMenu
                    type={type}
                    dtkAdd={dtkAdd}
                    deviceObj={deviceObj}
                    handleFlashLight={handleFlashLight}
                    handleConnectLight={handleConnectLight}
                  />
                </ButtonGroup>
                {/* <Menu
                  anchorEl={UnknwonDeviceMenuAnchorEl}
                  open={unknwonDeviceMenuOpen}
                  onClose={handleUnknownDeviceMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                >
                  {
                    activeMenuDeviceObj?.dtkAdd &&
                    <MenuItem sx={{ display: "flex", flexDirection: "column", '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }} divider disableRipple >
                      <Typography variant="caption">{activeMenuDeviceObj?.dtkAdd || "unknown"}</Typography>
                      {
                        !((activeMenuDeviceObj?.mapID || []).includes(activeMapID)) &&
                        <Typography variant="caption">⚠ not in this map</Typography>
                      }
                      <DivInline>
                        {
                          activeMenuDeviceObj?.gatewayID != activeGatewayID && activeMenuDeviceObj?.gatewayID &&
                          <>
                            <DeviceIcon deviceType="gateway" width={1.4} />
                            {`<--${activeMenuDeviceObj?.zigbeeConnected ? "✅" : "❌"}-->`}
                          </>
                        }
                        <DeviceIcon deviceType={type} width={1.4} />
                        {`${activeMenuDeviceObj?.gatewayID == activeGatewayID ? "<" : ""}---->`}
                        <DeviceIcon deviceType="gateway" width={1.4} />
                      </DivInline>
                      {
                        activeMenuDeviceObj?.gatewayID == activeGatewayID ?
                          <Tooltip title={`${activeMenuDeviceObj?.gatewayID}  ${activeGatewayID}`}>
                            <Typography variant="caption" >already connected</Typography>
                          </Tooltip>
                          : <>
                            <Typography variant="caption" >connect to this GW 👆</Typography>
                            <Button variant="outlined" size="small"
                              onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                if (activeMenuDeviceObj?.gatewayID == activeGatewayID) return
                                handleConnectLight(activeMenuDeviceObj)
                              }}
                            >connect</Button>
                          </>
                      }
                    </MenuItem>
                  }
               
              
                  <MenuItem divider disableRipple sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}>
                    <FlashLightButtonGroup dtkAdd={activeMenuDeviceObj?.dtkAdd} type="light" />
                  </MenuItem>
                  <MenuItem divider disableRipple sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}>
                    <FlashLightButtonGroup dtkAdd={activeMenuDeviceObj?.dtkAdd} type="sensor" />
                  </MenuItem>
                  <MenuItem divider disableRipple sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}>
                    <FlashLightButtonGroup dtkAdd={activeMenuDeviceObj?.dtkAdd} type="daliCtl" />
                  </MenuItem>

                </Menu> */}
              </DivInline>

            </Box>
          )
        })
      }
      <Divider10 />
    </AdminContainer>
  );
}

function UnknownDeviceMenu({ type, dtkAdd, deviceObj, handleFlashLight, handleConnectLight }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, activeGatewayID } = state || {};
  const [open, setOpen] = useState(false);
  const handleBatteryCheck = (dtkAdd, commandL) => {
    setPower(deviceObj?.serial || "", deviceObj, commandL);
  }
  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        <MoreHorizIcon />
      </Button>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        width="20vw"
        disableBottomClose
      >
        <Box>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography variant="caption">ID:{deviceObj?.serial || deviceObj?.sensorID || deviceObj?.daliCtlID || "unknown"}@{dtkAdd}</Typography>
          <Divider10 />
          {/* <Typography variant="caption">{activeMenuDeviceObj?.dtkAdd || "unknown"}</Typography> */}
          {
            !((deviceObj?.mapID || []).includes(activeMapID)) &&
            <Typography variant="caption">⚠ not in this map</Typography>
          }
          <DivInline>
            {
              deviceObj?.gatewayID != activeGatewayID && deviceObj?.gatewayID &&
              <>
                <DeviceIcon deviceType="gateway" width={1.4} />
                {`<--${deviceObj?.zigbeeConnected ? "✅" : "❌"}-->`}
              </>
            }
            <DeviceIcon deviceType={type} width={1.4} />
            {`${deviceObj?.gatewayID == activeGatewayID ? "<" : ""}---->`}
            <DeviceIcon deviceType="gateway" width={1.4} />
          </DivInline>
          {
            deviceObj?.gatewayID == activeGatewayID ?
              <Tooltip title={`${deviceObj?.gatewayID}  ${activeGatewayID}`}>
                <Typography variant="caption" >already connected</Typography>
              </Tooltip>
              : <>
                <Typography variant="caption" >connect to this GW 👆</Typography>
                <Button variant="outlined" size="small"
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    if (deviceObj?.gatewayID == activeGatewayID) return
                    handleConnectLight(deviceObj)
                  }}
                >connect</Button>
              </>
          }
          <Divider10 />
          {
            ["light", "sensor", "daliCtl"].map((type) => {
              return (
                <DivInline key={type}>
                  <Button
                    onClick={(e) => handleFlashLight(dtkAdd, true, type, e)}
                  >
                    <DeviceIcon deviceType={type} width={1.4} />
                    <Typography display="block" variant="caption">Flash</Typography>
                  </Button>
                  <Button
                    onClick={(e) => handleFlashLight(dtkAdd, false, type, e)}
                  >
                    <DeviceIcon deviceType={type} width={1.4} />
                    <Typography display="block" variant="caption">Stop</Typography>
                  </Button>
                </DivInline>
              )
            })
          }
          <Divider10 />
          <DivInline>
            <Button variant="outlined" size="small" onClick={() => handleBatteryCheck(1)}>Elight cut power</Button>
            <Button variant="outlined" size="small" onClick={() => handleBatteryCheck(0)}>Elight resume power</Button>
          </DivInline>
        </Box>
      </ModalSM>
    </>
  )
}
function DatabaseCheckModal({ dtkAdd, deviceType, onChecked }) {
  const [open, setOpen] = useState(false);
  const [deviceObjArray, setDeviceObjArray] = useState(null);
  const [source, setSource] = useState("");
  const checkDb = async (dtkAdd, deviceType) => {
    let array = [];
    let s = "mongo";
    if (deviceType === "light") {
      array = await mgFindByMatch("serial", "dtkAdd", dtkAdd);
      if (array.length === 0) {
        s = "firestore";
        array = await getDocsWhereFS("SM_serial", "array", "dtkAdd", "==", dtkAdd);
      }
    } else if (deviceType == "sensor") {
      array = await mgFindByMatch("sensor", "dtkAdd", dtkAdd);
      if (array.length === 0) {
        s = "firestore";
        array = await getDocsWhereFS("SM_sensor", "array", "dtkAdd", "==", dtkAdd);
      }
    } else if (deviceType == "daliCtl") {
      array = await mgFindByMatch("daliCtl", "dtkAdd", dtkAdd);
      if (array.length === 0) {
        s = "firestore";
        array = await getDocsWhereFS("SM_daliCtl", "array", "dtkAdd", "==", dtkAdd);
      }
    }
    setSource(s);
    setDeviceObjArray(array);
  }
  const handleAddToUnknownDevice = async (obj) => {
    if (source === "firestore") {
      if (deviceType === "light") {
        await mgUpdateOneUpsert("serial", { serial: obj.serial }, obj);
      } else if (deviceType === "sensor") {
        await mgUpdateOneUpsert("sensor", { sensorID: obj.sensorID }, obj);
      } else if (deviceType === "daliCtl") {
        await mgUpdateOneUpsert("daliCtl", { daliCtlID: obj.daliCtlID }, obj);
      }
      await gs.waitFor(2000);

    }
    await onChecked(obj);
    handleClose();
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Tooltip title={"check mongo / firestore"}>
        <Storage
          sx={{ marginLeft: "0.5vw", '&:hover': { cursor: "pointer", color: "red" } }}
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            setOpen(true)
          }}
        />
      </Tooltip>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle={`check firestore: ${deviceType} @${dtkAdd}`}
        width="50vw"
        height="65vh"
      >
        <Box>
          <Button variant="outlined" size="small" onClick={() => checkDb(dtkAdd, deviceType)}>check</Button>
          <Divider10 />
          <DivExist show={deviceObjArray}>
            <Typography >found {deviceObjArray?.length} devices</Typography>
          </DivExist>

          {
            (deviceObjArray || []).map((obj, key) => {

              return (
                <Box key={key}>
                  {
                    deviceType == "light" && <CardLight lightObj={obj} handleSave={async () => await handleAddToUnknownDevice(obj)} tooltip="Add to Unknown Device List" />
                  }
                  {
                    deviceType == "sensor" && <CardSensor sensorObj={obj} handleSave={async () => await handleAddToUnknownDevice(obj)} />
                  }
                  {
                    deviceType == "daliCtl" && <CardDaliCtl daliCtlObj={obj} handleSave={async () => await handleAddToUnknownDevice(obj)} />
                  }
                </Box>
              )
            })
          }
        </Box>
        <Divider10 />
        <DivExist show={deviceObjArray?.length > 0}>
          <Typography variant="caption">source: {source}</Typography>
        </DivExist>
      </ModalSM>
    </>
  )
}