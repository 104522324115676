import { Circle, Group, Path } from "react-konva";

export default function SceneButton({
  x,
  y,
  size,
  status,
  status2,
  status3,
  status4,
  connectStatus,
  shadEnabled,
  disabledConnectStatus,
  gang,
  disabledSensor,
}) {
  const scale = size / 79.375;
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      <Path
        fill="#ccc"
        stroke="#6c5d53"
        strokeWidth={2.646}
        data="M16.135 8.792l55.972.089c3.558.53 5.2 2.797 5.448 6.327v56.637c-.434 2.847-2.235 4.687-5.145 5.71l-57.214-.112c-3.661-.499-4.74-2.95-5.167-5.896L9.962 14c1.005-2.771 2.48-5.08 6.173-5.208z"
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={4}
        shadowOffset={{ x: 8, y: 8 }}
        shadowOpacity={0.3}
        perfectDrawEnabled={false}
      ></Path>
      {gang <= 3 && (
        <>
          <Circle
            x={gang === 1 ? 44 : 23}
            y={42}
            fill="#666"
            fillOpacity={0.996}
            stroke={status === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
            perfectDrawEnabled={false}
          />
          {gang > 1 && (
            <Circle
              x={gang === 2 ? 64 : 44}
              y={42}
              fill="#666"
              fillOpacity={0.996}
              stroke={status2 === 1 ? "red" : "#0ff"}
              strokeLinecap="round"
              strokeWidth={3.5}
              radius={8.58}
              perfectDrawEnabled={false}
            />
          )}

          {gang === 3 && (
            <Circle
              x={64}
              y={42}
              fill="#666"
              fillOpacity={0.996}
              stroke={status3 === 1 ? "red" : "#0ff"}
              strokeLinecap="round"
              strokeWidth={3.5}
              radius={8.58}
              perfectDrawEnabled={false}
            />
          )}
        </>
      )}
      {gang === 4 && (
        <>
          <Circle
            x={28}
            y={28}
            fill="#666"
            fillOpacity={0.996}
            stroke={status === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
            perfectDrawEnabled={false}
          />
          <Circle
            x={59}
            y={28}
            fill="#666"
            fillOpacity={0.996}
            stroke={status2 === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
            perfectDrawEnabled={false}
          />
          <Circle
            x={28}
            y={59}
            fill="#666"
            fillOpacity={0.996}
            stroke={status3 === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
            perfectDrawEnabled={false}
          />
          <Circle
            x={59}
            y={59}
            fill="#666"
            fillOpacity={0.996}
            stroke={status4 === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
            perfectDrawEnabled={false}
          />
        </>
      )}
      {!disabledConnectStatus && (
        <Circle x={-5} radius={7} fill={connectColor} perfectDrawEnabled={false} />
      )}
    </Group>
  );
}
