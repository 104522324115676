import { FormControl } from '@mui/base/FormControl';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Paper from '@mui/material/Paper';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useState, useEffect } from "react";
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import unknownFileType from "asset/iconPng/unknownFileType.png";
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import moment from "moment";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import axios from "axios";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const paperStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  minWidth: "280px",
  width: "50%",
  // margin: "20px auto",
};

const imageGalleryStyle = {
  width: "90%",
  height: "50vh",
  border: "1px solid black",
}

const ImageListItemStyle = {
  display: "flex",
  justifyContent: "center",
  hover: {
    scale: 1.1,
  }
}

const JobInfo = () => {
  const [photo, setPhoto] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [ecoProjectList, setEcoProjectList] = useState([]);
  const [skymonProjectList, setSkymonProjectList] = useState([]);
  const [skymonQuotationList, setSkymonQuotationList] = useState([]);
  const [ecoQuotationList, setEcoQuotationList] = useState([]);
  const [selectedComp, setSelectedComp] = useState("eco");
  const [selectedProjectPath, setSelectedProjectPath] = useState("");
  const [uploadStatus, setUploadStatus] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadDropboxID, setUploadDropboxID] = useState(randomString(20));
  const handleUploadImage = () => {
    if (selectedProjectPath === "") return;
    const data = new FormData();
    if (photo.length === 0) return;
    photo.forEach((item) => {
      data.append('files', item.obj);
    });
    data.append('path', selectedProjectPath);
    data.append('uploadDropboxID', uploadDropboxID)
    setUploading(true);
    setUploadStatus([]);
    setUploadProgress(0);
    const config = {
      onUploadProgress: (progressEvent) => {
        console.log(`${progressEvent.loaded} of ${progressEvent.total}`, progressEvent);
        const p = (progressEvent.loaded / progressEvent.total) * 100;
        setUploadProgress(p);
      },
    };
    axios.post("https://www.skymonhk.com/api/uploadDropbox", data, config).then(async (response) => {
      console.log(response);
      const imageResponse = response.data;
      console.log(imageResponse);
      setUploading(false);
      setUploadSuccess(true);
      setUploadFailed(false);
    }).catch((err) => {
      console.log(err);
      setUploading(false);
      setUploadFailed(true);
      setUploadSuccess(false);
    });
  }

  const fetchEcoProject = async () => {
    const response = await fetch(`https://www.skymonhk.com/api/getDropboxFilesList?path=/eco_engineering/Project files/`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    if (result.result.length <= 0) return
    const ary = result.result
    const newAry = ary.filter((item) => /^P\d{2,4}\s/.test(item))
    const newAry2 = newAry.sort((a, b) => a.localeCompare(b))
    setEcoProjectList(newAry2);
  }

  const fetchSkymonProject = async () => {
    const response = await fetch(`https://www.skymonhk.com/api/getDropboxFilesList?path=/skymon/Project files/`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    if (result.result.length <= 0) return
    const ary = result.result
    const newAry = ary.filter((item) => /^S\d{2,4}\s/.test(item))
    const newAry2 = newAry.sort((a, b) => a.localeCompare(b))
    setSkymonProjectList(newAry2);
  }

  const fetchSkymonQuotationFile = async () => {
    const response = await fetch(`https://www.skymonhk.com/api/getDropboxFilesList?path=/skymon/Quotation files/`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    if (result.result.length <= 0) return
    const ary = result.result
    const newAry = ary.filter((item) => /^\d{2,4}\s/.test(item))
    const newAry2 = newAry.sort((a, b) => a.localeCompare(b))
    setSkymonQuotationList(newAry2);
  }

  const fetchEcoQuotationFile = async () => {
    const response = await fetch(`https://www.skymonhk.com/api/getDropboxFilesList?path=/eco_engineering/Quotation files/`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const result = await response.json();
    if (result.result.length <= 0) return
    const ary = result.result
    const newAry = ary.filter((item) => /^\d{2,4}\s/.test(item))
    const newAry2 = newAry.sort((a, b) => a.localeCompare(b))
    setEcoQuotationList(newAry2);
  }
  const handleSelectProject = (e) => {
    setSelectedProjectPath(`${e.target.value}/photo/${moment().format("YYMMDD")}/`);
  }

  const handleSelectComp = (e) => {
    setSelectedProjectPath("");
    setSelectedComp(e.target.value);
  }

  useEffect(() => {
    fetchEcoProject();
    fetchSkymonProject();
    fetchSkymonQuotationFile();
    fetchEcoQuotationFile();
  }, []);

  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh" }}>
      <Paper elevation={3} sx={paperStyle}>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", width: "100%" }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="grouped-native-select">project</InputLabel>
            <Select native defaultValue="eco" id="grouped-native-select" label="project" onChange={handleSelectComp} >
              <option value={`eco`}>eco</option>
              <option value={`skymon`}>skymon</option>
              <option value={`skymon quotation`}>skymon quotation</option>
              <option value={`eco quotation`}>eco quotation</option>
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel htmlFor="grouped-native-select">project</InputLabel>
            <Select native defaultValue="" id="grouped-native-select" label="project" onChange={handleSelectProject}>
              <option value={""}></option>
              {selectedComp == "eco" && <optgroup label="eco">
                {
                  ecoProjectList.length > 0 && ecoProjectList.map((item) => {
                    return <option value={`/eco_engineering/Project files/${item}`}>{item}</option>
                  })
                }
              </optgroup>}
              {selectedComp == "skymon" && <optgroup label="skymon">
                {
                  skymonProjectList.length > 0 && skymonProjectList.map((item) => {
                    return <option value={`/skymon/Project files/${item}`}>{item}</option>
                  })
                }
              </optgroup>}
              {selectedComp == "skymon quotation" && <optgroup label="skymon quotation">
                {
                  skymonQuotationList.length > 0 && skymonQuotationList.map((item) => {
                    return <option value={`/skymon/Quotation files/${item}`}>{item}</option>
                  })
                }
              </optgroup>}
              {selectedComp == "eco quotation" && <optgroup label="eco quotation">
                {
                  ecoQuotationList.length > 0 && selectedComp == "eco quotation" && ecoQuotationList.map((item) => {
                    return <option value={`/eco_engineering/Quotation files/${item}`}>{item}</option>
                  })
                }
              </optgroup>}
            </Select>
          </FormControl>
        </div>
        <ImageList sx={imageGalleryStyle} cols={3} rowHeight={"auto"}>
          {photo.length > 0 && photo.map((item, index) => (
            <ImageListItem key={item.name} style={ImageListItemStyle}>
              {(uploadStatus[index] === true || uploadStatus[index] === false) &&
                <div style={{ position: "absolute", width: "100%", height: '100%', background: "rgba(0, 0, 0, 0.5)" }}>
                  {uploadStatus[index] === true && <CheckCircleIcon style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", color: "green" }} />}
                  {uploadStatus[index] === false && <PriorityHighIcon style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%,-50%)", color: "red" }} />}
                </div>
              }

              <img
                srcSet={typeof getIconByFileType(item) === "string" ? `${getIconByFileType(item)}#?w=164&h=164&fit=fill&auto=format&dpr=2 2x` : getIconByFileType(item)}
                src={typeof getIconByFileType(item) === "string" ? getIconByFileType(item) : getIconByFileType(item)}
                alt={item.name}
                loading="lazy"
              />
              <Typography variant="caption" component="h2">
                {item.name}
              </Typography>
            </ImageListItem>
          ))}
        </ImageList>
        <Box sx={{ width: '100%' }}>
          <LinearProgressWithLabel value={uploadProgress} />
        </Box>
        <div style={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<InsertPhotoIcon />}
          >
            選擇文件
            <VisuallyHiddenInput type="file" multiple accept="*"
              onChange={(e) => {
                if (e.target.files.length > 0) {
                  const newPhoto = [...photo];
                  Object.keys(e.target.files).forEach((key) => {
                    const url = URL.createObjectURL(e.target.files[key])
                    newPhoto.push({ name: e.target.files[key].name, url, obj: e.target.files[key] });
                  });
                  setPhoto(newPhoto);
                }
              }} />
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<InsertPhotoIcon />}
            color="error"
            onClick={() => {
              setPhoto([])
              setUploadStatus([])
            }}
            disabled={photo.length == 0}
          >
            清除文件
          </Button>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            onClick={handleUploadImage}
          >
            {
              !uploading && !uploadSuccess && !uploadFailed && <span>上傳</span>
            }
            {uploading &&
              <CircularProgress color="inherit" />
            }
            {
              !uploading && uploadSuccess && <span>上傳成功</span>
            }
            {
              !uploading && uploadFailed && <span>上傳失敗</span>
            }
          </Button>
        </div>
      </Paper>
    </div>
  );
};

function getIconByFileType(file) {
  const fileType = file.name.split(".").pop();
  switch (fileType) {
    case "png":
    case "jpg":
    case "JPG":
    case "JPEG":
    case "jpeg":
      return file.url;
    default:
      return unknownFileType;
  }
}

function randomString(length) {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
  return result;
}

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export default JobInfo;
