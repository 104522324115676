import { useContext } from "react";
import MapLeftToolbar from "./toolbar/MapLeftToolbar";

export default function MapContainerLeft({ vw, vh, vhToolbar }) {
  return (
    <div
      style={{
        marginTop: `${vhToolbar}vh`,
        width: `${vw}vw`,
        height: `${vh * 0.9}vh`,
        // backgroundColor: "lightgreen",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <MapLeftToolbar />
    </div>
  );
}
