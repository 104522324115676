import { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, MenuItem } from "@mui/material";
import DivInline from "components/DivInline";
import { batTestTimeInfo, fetchTimeTableBySerial } from "actions/timetableActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import moment from "moment-timezone";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import IconButtonEdit from "components/IconButtonEdit";
import General from "@ecoenghk/general";
import {
  saveTestTimeTable,
  deleteTestTimeTable,
} from "actions/timetableActions";
import DivCol from "components/DivCol";
import TypoCaption from "components/TypoCaption";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import AdminContainer from "components/AdminContainer";
import JsonModal from "components/JsonModal";
import IconButtonDelete from "components/IconButtonDelete";
import BatteryCharge from "asset/svgComp/BatteryCharge";
const gs = new General();

export default function EditTestTimeTableModal({
  serial,
  monthTimeTableObj,
  annualTimeTableObj,
  iconText,
  iconColor,
}) {
  const [state, dispatch] = useContext(SMContext);
  const { lightObjAll } = state;
  const [open, setOpen] = useState(false);
  const [monthTestDate, setMonthTestDate] = useState(1);
  const [monthTestHour, setMonthTestHour] = useState(0);
  const [monthTestMin, setMonthTestMin] = useState(0);
  const [monthEffectiveAfterTsp, setMonthEffectiveAfterTsp] = useState(null);
  const [annualTestMonth, setAnnualTestMonth] = useState(1);
  const [annualTestDate, setAnnualTestDate] = useState(1);
  const [annualTestHour, setAnnualTestHour] = useState(0);
  const [annualTestMin, setAnnualTestMin] = useState(0);
  const [annualEffectiveAfterTsp, setAnnualEffectiveAfterTsp] = useState(null);
  const { batteryHour } = lightObjAll[serial] || {};
  const monthTestDuration = gs.getBatteryTestDuration("check_1month", batteryHour || 2);
  const annualTestDuration = gs.getBatteryTestDuration("check_12month", batteryHour || 2);
  const handleOpen = () => {
    setMonthTestDate(monthTimeTableObj?.date[0]);
    setMonthTestHour(monthTimeTableObj?.hour[0]);
    setMonthTestMin(monthTimeTableObj?.minute[0]);
    setMonthEffectiveAfterTsp(monthTimeTableObj?.effectiveAfterTsp);
    setAnnualTestMonth(annualTimeTableObj?.month[0]);
    setAnnualTestDate(annualTimeTableObj?.date[0]);
    setAnnualTestHour(annualTimeTableObj?.hour[0]);
    setAnnualTestMin(annualTimeTableObj?.minute[0]);
    setAnnualEffectiveAfterTsp(annualTimeTableObj?.effectiveAfterTsp);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const batTestTime = useMemo(() => {
    let newMonthTestMonthArr = [];
    for (let m = 1; m <= 12; m++) {
      if (m !== annualTestMonth) newMonthTestMonthArr.push(m);
    }
    let newMonthTimeTableObj = {
      ...monthTimeTableObj,
      month: newMonthTestMonthArr,
      date: [monthTestDate || 1],
      hour: [monthTestHour || 0],
      minute: [monthTestMin || 0],
    };
    delete newMonthTimeTableObj._id;
    let newAnnualTimeTableObj = {
      ...annualTimeTableObj,
      month: [annualTestMonth || 1],
      date: [annualTestDate || 1],
      hour: [annualTestHour || 0],
      minute: [annualTestMin || 0],
    };
    delete newAnnualTimeTableObj._id;
    return batTestTimeInfo([newMonthTimeTableObj, newAnnualTimeTableObj], serial);
  }, [serial, monthTimeTableObj, annualTimeTableObj, monthTestDate, monthTestHour, monthTestMin, annualTestMonth, annualTestDate, annualTestHour, annualTestMin]);

  const handleSave = async () => {
    showBigProgress(dispatch);
    let newMonthTestMonthArr = [];
    for (let m = 1; m <= 12; m++) {
      if (m !== annualTestMonth) newMonthTestMonthArr.push(m);
    }
    let newMonthTimeTableObj = {
      ...monthTimeTableObj,
      month: newMonthTestMonthArr,
      date: [monthTestDate || 1],
      hour: [monthTestHour || 0],
      minute: [monthTestMin || 0],
      effectiveAfterTsp: monthEffectiveAfterTsp,
    };
    delete newMonthTimeTableObj._id;
    let newAnnualTimeTableObj = {
      ...annualTimeTableObj,
      month: [annualTestMonth || 1],
      date: [annualTestDate || 1],
      hour: [annualTestHour || 0],
      minute: [annualTestMin || 0],
      effectiveAfterTsp: annualEffectiveAfterTsp,
    };
    delete newAnnualTimeTableObj._id;
    delete newMonthTimeTableObj._id;
    await saveTestTimeTable(
      serial,
      batteryHour,
      newMonthTimeTableObj,
      newAnnualTimeTableObj
    );
    await gs.waitFor(800);
    const arr = await fetchTimeTableBySerial(serial);
    if (arr.length > 0) {
      dispatch({
        type: "UPDATE_MULTI_TIMETABLE_OBJ",
        payload: {
          timeTableObjArray: arr,
        },
      });
    }
    hideBigProgress(dispatch);
    handleClose();
  };
  const handleDelete = async () => {
    confirmWindow(dispatch, "Delete this schedued test?", async () => {
      await deleteTestTimeTable(
        monthTimeTableObj?.scheduleID,
        annualTimeTableObj?.scheduleID
      );
      dispatch({
        type: "DELETE_MULTI_TIMETABLE_OBJ",
        payload: { timeTableIDArray: [monthTimeTableObj?.scheduleID, annualTimeTableObj?.scheduleID] },
      });
      handleClose();
    });
  };
  return (
    <>

      <DivInline>
        <Typography variant="caption">{iconText}</Typography>
        <IconButtonEdit tooltip="Edit battery test time" onBtnClick={handleOpen} color={iconColor} />
        <IconButtonDelete tooltip="Delete battery test schedule" onBtnClick={handleDelete} color={iconColor} />
      </DivInline>

      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle={`Edit test time table for [${serial}]`}
        onSave={handleSave}
        onDelete={handleDelete}
        disabledDelete={!monthTimeTableObj && !annualTimeTableObj}
        width="50vw"
        height="90vh"
      >
        <>
          <Typography variant="h6" align="center">Monthly Test</Typography>
          <Divider10 space={5} />
          <SpaceBetweenDiv
            title="Schedule ID"
            data={
              <DivInline>
                <Typography>{monthTimeTableObj?.timeTableID || "-"}</Typography>
                <AdminContainer>
                  <JsonModal show jsonData={monthTimeTableObj} iconButton />
                </AdminContainer>
              </DivInline>
            }
          />
          <DivSpaceBetween>
            <Typography>Date of each month</Typography>
            <TextField
              size="small"
              select
              value={monthTestDate || 1}
              onChange={(e) => {
                setMonthTestDate(e.target.value);
                setMonthEffectiveAfterTsp(null);
              }}
            >
              {gs.newArrayBetween(1, 28).map((d) => (
                <MenuItem key={d} value={d}>
                  {d < 10 ? "0" + d : d}
                </MenuItem>
              ))}
            </TextField>
          </DivSpaceBetween>

          <DivSpaceBetween>
            <Typography>Time</Typography>
            <DivInline>
              <TextField
                size="small"
                select
                value={monthTestHour || 0}
                onChange={(e) => {
                  setMonthTestHour(e.target.value);
                  setMonthEffectiveAfterTsp(null);
                }}
              >
                {gs.newArrayBetween(0, 23).map((h) => (
                  <MenuItem key={h} value={h}>
                    {h < 10 ? "0" + h : h}
                  </MenuItem>
                ))}
              </TextField>
              <Typography sx={{ margin: "1vw" }} variant="h5">
                :
              </Typography>
              <TextField
                size="small"
                select
                value={monthTestMin || 0}
                onChange={(e) => {
                  setMonthTestMin(e.target.value);
                  setMonthEffectiveAfterTsp(null);
                }}
              >
                {gs.newArrayBetween(0, 59).map((m) => (
                  <MenuItem key={m} value={m}>
                    {m < 10 ? "0" + m : m}
                  </MenuItem>
                ))}
              </TextField>
            </DivInline>
          </DivSpaceBetween>
          <DivCol alignItems="flex-end">
            <TypoCaption>Battery hour: {batteryHour || 2 + " hours"}</TypoCaption>
            <TypoCaption>{`Data interval: ${monthTestDuration?.time_interval} second`} </TypoCaption>
            <DivInline>
              <BatteryCharge status="charge" darkLight="dark" width={1.5} sizeUnit="vw" />
              <TypoCaption>{`Time pretest: ${monthTestDuration?.time_pretest} min`}</TypoCaption>
            </DivInline>
            <DivInline>
              <BatteryCharge status="discharge" darkLight="dark" width={1.5} sizeUnit="vw" />
              <TypoCaption>{`Time cut off: ${monthTestDuration?.time_cutOff} min`}</TypoCaption>
            </DivInline>
            <DivInline>
              <BatteryCharge status="charge" darkLight="dark" width={1.5} sizeUnit="vw" />
              <TypoCaption>{`Time recharge: ${monthTestDuration?.time_recharge} min`}</TypoCaption>
            </DivInline>
          </DivCol>
          {
            batTestTime?.nextMonthTestTsr &&
            <Typography display="block" variant="caption">Next monthly test: {batTestTime?.nextMonthTestTsr}</Typography>
          }
          {
            monthEffectiveAfterTsp &&
            <Typography display="block" variant="caption">Effective after: {moment(monthEffectiveAfterTsp).format("YYYY-MM-DD HH:mm")}</Typography>
          }
        </>
        <Divider10 space={5} />
        <>
          <Typography variant="h6" align="center">Annual Test</Typography>
          <Divider10 space={5} />
          <SpaceBetweenDiv
            title="Schedule ID"
            data={
              <DivInline>
                <Typography>{annualTimeTableObj?.timeTableID || "-"}</Typography>
                <AdminContainer>
                  <JsonModal show jsonData={annualTimeTableObj} iconButton />
                </AdminContainer>
              </DivInline>
            }
          />
          <DivSpaceBetween>
            <Typography>Month</Typography>
            <TextField
              size="small"
              select
              value={annualTestMonth || 1}
              onChange={(e) => {
                setAnnualTestMonth(e.target.value);
                setAnnualEffectiveAfterTsp(null);
              }}
            >
              {gs.newArrayBetween(1, 12).map((m) => (
                <MenuItem key={m} value={m}>
                  {moment()
                    .month(m - 1)
                    .format("MMM")}
                </MenuItem>
              ))}
            </TextField>
          </DivSpaceBetween>
          <DivSpaceBetween>
            <Typography>Date</Typography>
            <TextField
              size="small"
              select
              value={annualTestDate || 1}
              onChange={(e) => {
                setAnnualTestDate(e.target.value);
                setAnnualEffectiveAfterTsp(null);
              }}
            >
              {gs.newArrayBetween(1, 28).map((d) => (
                <MenuItem key={d} value={d}>
                  {d < 10 ? "0" + d : d}
                </MenuItem>
              ))}
            </TextField>
          </DivSpaceBetween>

          <DivSpaceBetween>
            <Typography>Time</Typography>
            <DivInline>
              <TextField
                size="small"
                select
                value={annualTestHour || 0}
                onChange={(e) => {
                  setAnnualTestHour(e.target.value);
                  setAnnualEffectiveAfterTsp(null);
                }}
              >
                {gs.newArrayBetween(0, 23).map((h) => (
                  <MenuItem key={h} value={h}>
                    {h < 10 ? "0" + h : h}
                  </MenuItem>
                ))}
              </TextField>
              <Typography sx={{ margin: "1vw" }} variant="h5">
                :
              </Typography>
              <TextField
                size="small"
                select
                value={annualTestMin || 0}
                onChange={(e) => {
                  setAnnualTestMin(e.target.value);
                  setAnnualEffectiveAfterTsp(null);
                }}
              >
                {gs.newArrayBetween(0, 59).map((m) => (
                  <MenuItem key={m} value={m}>
                    {m < 10 ? "0" + m : m}
                  </MenuItem>
                ))}
              </TextField>
            </DivInline>
          </DivSpaceBetween>
          <DivCol alignItems="flex-end">
            <TypoCaption>Battery hour: {batteryHour || 2 + " hours"}</TypoCaption>
            <TypoCaption>
              Data interval: {annualTestDuration?.time_interval} second
            </TypoCaption>
            <DivInline>
              <BatteryCharge status="charge" darkLight="dark" width={1.5} sizeUnit="vw" />
              <TypoCaption>
                Time pretest: {annualTestDuration?.time_pretest} min
              </TypoCaption>
            </DivInline>
            <DivInline>
              <BatteryCharge status="discharge" darkLight="dark" width={1.5} sizeUnit="vw" />
              <TypoCaption>
                Time cut off: {annualTestDuration?.time_cutOff} min
              </TypoCaption>
            </DivInline>
            <DivInline>
              <BatteryCharge status="charge" darkLight="dark" width={1.5} sizeUnit="vw" />
              <TypoCaption>
                Time recharge: {annualTestDuration?.time_recharge} min
              </TypoCaption>
            </DivInline>
          </DivCol>
          {
            batTestTime?.nextAnnualTestTsr &&
            <Typography display="block" variant="caption">Next annual test: {batTestTime?.nextAnnualTestTsr}</Typography>
          }
          {
            annualEffectiveAfterTsp &&
            <Typography display="block" variant="caption">Effective after: {moment(annualEffectiveAfterTsp).format("YYYY-MM-DD HH:mm")}</Typography>
          }
        </>
      </ModalSM>
    </>
  );
}
