import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, ButtonGroup } from "@mui/material";
import { ArrowForwardIos, Refresh } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DialogInput from "components/DialogInput";
import DivInline from "components/DivInline";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import {
  updateSerialProperty,
  terminateTest_A7,
  fetchOneLight,
  queryOneLightSetting,
  checkLightUnderGateway,
  lightDeletePermanently,
  changeOneLightSetting,
  enablingAirconControl,
  lightTestSettingReadWrite,
  lightConvertToDaliCtl,
  queryLightComType,
  requestLightCurrentData
} from "actions/lightActions";
import {
  alertWindow,
  confirmWindow,
  hideBigProgress,
  openSnackbar,
  promptWindow,
  showBigProgress,
} from "actions/screenActions";
import { sendStartSettingToDevice } from "actions/gatewayActions";
import moment from "moment";

import General from "@ecoenghk/general";
import DivSpaceBetween from "components/DivSpaceBetween";
import IconButtonRefresh from "components/IconButtonRefresh";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import ServerConsoleLogFilter from "containers/server/ServerConsoleLogFilter";
import DeviceInterfaceTtyPanel from "containers/gateway/DeviceInterfaceTtyPanel";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
const gs = new General();

export default function LightInfoSuperAdmin({ handleClose }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSerial, lightObjAll, userObj } = state;
  const uid = userObj?.uid;
  const lightObj = lightObjAll[activeSerial];
  const {
    type,
    lastConnectedTimeStamp,
    lastDisconnectedTimeStamp,
    reverseA,
    regular_time_interval,
    survival_time_interval,
    gatewayID,
    serverID,
    daliCtlID,
    // deviceVersion,
    // dtkAdd,
    // dtkChannel,
    // meshChannel,
    sensorArray,
  } = lightObj || {};
  const handleUpdateLightProps = async (updateObj) => {
    await updateSerialProperty(activeSerial, lightObj, updateObj);
  };
  const handleConvertLightToDaliCtl = async () => {
    if (lightObj?.sensorArray?.length > 0) {
      alertWindow(dispatch, "Please remove all sensors before convert to dali controller");
    } else if (lightObj?.zoneControlArray?.length > 0) {
      alertWindow(dispatch, "Please remove all zone control before convert to dali controller");
    } else if (lightObj?.slaveLightArray?.length > 0) {
      alertWindow(dispatch, "Please remove all slave lights before convert to dali controller");
    } else {
      confirmWindow(
        dispatch,
        "Confirm convert this light to dali controller",
        async () => {
          await showBigProgress(dispatch);
          await lightConvertToDaliCtl(lightObj);
          await gs.waitFor(3500);
          await queryOneLightSetting(lightObj, 0x031b);
          await hideBigProgress(dispatch);
          handleClose();
          dispatch({
            type: "ALL_UNSELECTED",
          });
        }
      );
    }
  }

  // const ver = parseFloat(deviceVersion);
  // const interfaceType = dtkChannel ? "DTK" : meshChannel >= 0 ? "Mesh255" : ""

  return (
    <>
      <Divider10 />
      <Typography variant="h6" align="center">
        Super Admin Setting
      </Typography>
      <Divider10 />
      <div style={{ width: "100%" }}>
        <SpaceBetweenDiv title="Server ID" data={serverID} />
        <SpaceBetweenButton
          title="Refresh from Mongo"
          btnContent={<Refresh />}
          variant="outlined"
          btnSize="small"
          onBtnClick={async () => {
            await fetchOneLight(dispatch, activeSerial);
          }}
        />
        <SpaceBetweenDiv
          title="Last Disconnected timeString"
          data={moment(lastDisconnectedTimeStamp).format("YY-MM-DD,HH:mm")}
        />
        <SpaceBetweenDiv
          title="Last Connected timeString"
          data={moment(lastConnectedTimeStamp).format("YY-MM-DD,HH:mm")}
        />
        <SpaceBetweenCheckbox
          title="Enable Control L"
          data={type?.L ? true : false}
          onCheck={async (evt) => {
            await changeOneLightSetting(lightObj, 0x020e, { enableL: evt.target.checked ? 1 : 0 });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x0303);
          }}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x0303, null, uid)}
          hide={daliCtlID ? true : false}
        />
        <SpaceBetweenCheckbox
          title="Enable Control A"
          data={type?.A ? true : false}
          onCheck={async (evt) => {
            await changeOneLightSetting(lightObj, 0x020d, { enableA: evt.target.checked ? 1 : 0 });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x0302);
          }}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x0302, null, uid)}
          hide={daliCtlID ? true : false}
        />
        <SpaceBetweenCheckbox
          title="Enable battery test"
          data={type?.batVI ? true : false}
          onCheck={async (evt) => {
            await changeOneLightSetting(lightObj, 0x020f, { enableBatVI: evt.target.checked ? 1 : 0 });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x0304);
          }}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x0304, null, uid)}
          hide={daliCtlID ? true : false}
        />
        <SpaceBetweenCheckbox
          title="Enable dimming"
          data={type?.pwm ? true : false}
          onCheck={async (evt) => {
            await changeOneLightSetting(lightObj, 0x0210, { enableDim: evt.target.checked ? 1 : 0 });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x0305);
          }}
          hide={!type?.A || daliCtlID}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x0305, null, uid)}
        />
        <SpaceBetweenCheckbox
          title="Enable reverse light A on off"
          data={reverseA ? true : false}
          onCheck={async (evt) => {
            await changeOneLightSetting(lightObj, 0x0215, { reverseA: evt.target.checked ? 1 : 0 });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x030a);
          }}
          hide={!type?.A || daliCtlID}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x030a, null, uid)}
        />

        <SpaceBetweenDialogInput
          title="Regular upload time interval"
          data={regular_time_interval}
          dialogTitle="Change regular upload time interval (min)"
          showValueUnit="min"
          handleSave={async (val) => {
            await changeOneLightSetting(lightObj, 0x0218, { regular_time_interval: Number(val) });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x030d);
          }}
          hideComponent={daliCtlID}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x030d, null, uid)}
        />
        <SpaceBetweenDialogInput
          title="Survival time interval"
          data={survival_time_interval}
          dialogTitle="Change survival time interval (second)"
          showValueUnit="sec"
          handleSave={async (val) => {
            await changeOneLightSetting(lightObj, 0x0219, { survival_time_interval: Number(val) });
            await gs.waitFor(1200);
            await queryOneLightSetting(lightObj, 0x030e);
          }}
          hideComponent={daliCtlID}
          handleRefresh={() => queryOneLightSetting(lightObj, 0x030e, null, uid)}
        />
        <SpaceBetweenButton
          title="Send start setting"
          btnContent={<ArrowForwardIos />}
          variant="outlined"
          marginBottom="1vh"
          onBtnClick={async () => await sendStartSettingToDevice("light", activeSerial, gatewayID)}
          hideComponent={daliCtlID}
        />
        <SpaceBetweenButton
          title="Request feedback (check connection)"
          btnContent={<ArrowForwardIos />}
          variant="outlined"
          marginBottom="1vh"
          onBtnClick={async () => await requestLightCurrentData(activeSerial, lightObj, true)}
        />
        <SpaceBetweenButton
          title={`Terminate test -${lightObj?.reportObj?.reportID || ""}`}
          btnContent={<ArrowForwardIos />}
          variant="outlined"
          marginBottom="1vh"
          onBtnClick={async () => {
            await terminateTest_A7(activeSerial, lightObj);
            openSnackbar(
              dispatch,
              `Test ${lightObj.reportObj.reportID} terminated`
            );
          }}
          hideComponent={type?.batVI ? false : true}
        />
        <DivExist show={!daliCtlID}>
          <DivSpaceBetween>
            <DivInline>
              <Typography>Control A pin</Typography>
              <IconButtonRefresh
                onBtnClick={() => queryOneLightSetting(lightObj, 0x030b)}
              />
            </DivInline>
            <DivInline>
              <Typography>{lightObj?.controlAPin}</Typography>
              <DialogInput
                title="Control A pin"
                initialVal={lightObj?.controlAPin}
                handleSave={async (val) => {
                  await changeOneLightSetting(lightObj, 0x0216, { controlAPin: Number(val) })
                }}
                dataType="number"
              />
            </DivInline>
          </DivSpaceBetween>
          <DivSpaceBetween>
            <DivInline>
              <Typography>Control L pin</Typography>
              <IconButtonRefresh
                onBtnClick={() => queryOneLightSetting(lightObj, 0x030c, null, uid)}
              />
            </DivInline>
            <DivInline>
              <Typography>{lightObj?.controlLPin}</Typography>
              <DialogInput
                title="Control L pin"
                initialVal={lightObj?.controlLPin}
                handleSave={async (val) =>
                  await changeOneLightSetting(lightObj, 0x0217, { controlLPin: Number(val) })
                }
                dataType="number"
              />
            </DivInline>
          </DivSpaceBetween>
          <DivSpaceBetween>
            <DivInline>
              <Typography>Bypass input pin</Typography>
              <IconButtonRefresh
                onBtnClick={() => queryOneLightSetting(lightObj, 0x0311)}
              />
            </DivInline>
            <DivInline>
              <Typography>{lightObj?.inputBypassPin}</Typography>
              <DialogInput
                title="Bypass input pin"
                initialVal={lightObj?.inputBypassPin}
                handleSave={async (val) =>
                  await changeOneLightSetting(lightObj, 0x0223, { inputBypassPin: Number(val) })
                }
                dataType="number"
              />
            </DivInline>
          </DivSpaceBetween>
          <SpaceBetweenDiv
            title="Control relay A"
            data={
              <ButtonGroup size="small" variant="outlined">
                <Button onClick={() => changeOneLightSetting(lightObj, 0x022a, { controlA: reverseA ? 0x66 : 0x33 })}>On</Button>
                <Button onClick={() => changeOneLightSetting(lightObj, 0x022a, { controlA: reverseA ? 0x33 : 0x66 })}>Off</Button>
              </ButtonGroup>
            }
          />
          <SpaceBetweenDiv
            title="Control relay B"
            data={
              <ButtonGroup size="small" variant="outlined">
                <Button onClick={() => changeOneLightSetting(lightObj, 0x022b, { controlB: 0x66 })}>On</Button>
                <Button onClick={() => changeOneLightSetting(lightObj, 0x022b, { controlB: 0x33 })}>Off</Button>
              </ButtonGroup>
            }
          />
          <SpaceBetweenDiv
            title="Control relay D"
            data={
              <ButtonGroup size="small" variant="outlined">
                <Button onClick={() => changeOneLightSetting(lightObj, 0x022c, { controlD: 0x66 })}>On</Button>
                <Button onClick={() => changeOneLightSetting(lightObj, 0x022c, { controlD: 0x33 })}>Off</Button>
              </ButtonGroup>
            }
          />
        </DivExist>
        <SpaceBetweenButton
          title="Query delay count down"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => queryOneLightSetting(lightObj, 0x031a)}
        />
        <SpaceBetweenButton
          hideComponent={daliCtlID ? true : false}
          title="Check under gateways"
          btnContent={<ArrowForwardIos />}
          variant="outlined"
          marginBottom="1vh"
          onBtnClick={async () => {
            const obj = await checkLightUnderGateway(lightObj);
            if (obj) {
              const gwArrMg = obj.mongo;
              const gwArrFs = obj.firebase;
              alertWindow(
                dispatch,
                `Under gateways[mongo]: ${gwArrMg?.join(
                  ","
                )}, [firebase]: ${gwArrFs?.join(",")}`
              );
            }
          }}
        />
        <SpaceBetweenButton
          title="Check number of stored group action"
          btnContent={<ArrowForwardIos />}
          onBtnClick={() => queryOneLightSetting(lightObj, 0x0323)}

        />
        <SpaceBetweenButton
          title="Delete permanently"
          hideComponent={gatewayID || sensorArray?.length > 0 || daliCtlID ? true : false}
          btnContent="Delete"
          variant="outlined"
          marginBottom="1vh"
          onBtnClick={async () => {
            confirmWindow(
              dispatch,
              `Confirm to delete light ${activeSerial} permanently?`,
              async () => {
                await lightDeletePermanently(lightObj);
                dispatch({ type: "CLOSED_LIGHT_DETAIL_MODAL" });
              }
            );
          }}
        />
        <DivExist show={!daliCtlID}>
          <SpaceBetweenDialogInput
            title="PCB version"
            data={lightObj?.pcbVersion}
            dialogTitle="Change PCB version"
            handleSave={async (val) => {
              await handleUpdateLightProps({ pcbVersion: val });
            }}

          />
          <SpaceBetweenButton
            title="Check internal setting read/write"
            onBtnClick={() => lightTestSettingReadWrite(lightObj)}
            btnContent={<ArrowForwardIos />}

          />
          <SpaceBetweenSelect
            title="Turn on light on controller disconnect"
            data={lightObj?.enableTurnOnOnDisconnect ? 1 : 0}
            onChange={async (e) => {
              const val = Number(e.target.value);
              await changeOneLightSetting(lightObj, 0x232, { enableTurnOnOnDisconnect: val })
            }}
            menuObj={{ 0: "Disabled", 1: "Enabled" }}
            handleRefresh={() => queryOneLightSetting(lightObj, 0x031d)}
          />
          <SpaceBetweenSelect
            title="Switching sequence"
            data={lightObj?.switchSequence ?? 0}
            onChange={async (e) => {
              const val = Number(e.target.value);
              await changeOneLightSetting(lightObj, 0x234, { switchSequence: val })
            }}
            menuObj={{ 0: "0:b^->d^->a^->bv->dv", 1: "a^->b^->d^->bv", 2: "a^" }}
            handleRefresh={() => queryOneLightSetting(lightObj, 0x031e)}
          />
          <SpaceBetweenSwitch
            title="Enable acknowledge and resend"
            data={lightObj?.enableAcknowledge || false}
            onChange={async (e) => changeOneLightSetting(lightObj, 0x023a, { enableAcknowledge: e.target.checked ? 1 : 0 })}
            handleRefresh={() => queryOneLightSetting(lightObj, 0x0322)}
          />
          <SpaceBetweenDialogInput
            title="Short discharge time (sec)"
            data={lightObj?.shortDischargeSec || 10}
            dialogTitle="Change short discharge time (sec)"
            handleSave={async (val) => {
              await handleUpdateLightProps({ shortDischargeSec: Number(val) })
            }}
          />
          <Divider10 />
          <Typography>Communication</Typography>
          <Divider10 />
          <DeviceInterfaceTtyPanel deviceObj={lightObj} />
          <Divider10 />
          <ServerConsoleLogFilter filterString={activeSerial} />
          <Divider10 />
          <SpaceBetweenButton
            title="Convert light to dali controller"
            onBtnClick={() => handleConvertLightToDaliCtl()}
            btnContent={<ArrowForwardIos />}
            color="secondary"
            marginBottom="0.5vh"
            handleRefresh={() => queryOneLightSetting(lightObj, 0x031b)} //query enableDali
          />
          <SpaceBetweenButton
            title="Change to a Aircon remote control"
            btnContent={<ArrowForwardIos />}
            color="secondary"
            onBtnClick={async () => {
              const newEnable = lightObj?.enableAirconControl === 1 ? 0 : 1;
              const newStyle = newEnable === 1 ? "airconControl" : "tube";
              const acType = newEnable === 1 ? 3 : 0;
              await handleUpdateLightProps({
                enableAirconControl: newEnable,
                style: newStyle,
                acType,
              });
              await gs.waitFor(800);
              await enablingAirconControl(lightObj, newEnable);
            }}
          />
        </DivExist>
      </div>
    </>
  );
}
