export default function Server({ width, height, sizeUnit, connectStatus }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g stroke="#000">
        <path
          fill="#e9ddaf"
          strokeWidth={1.7}
          d="M6.304 9.546c.043-1.2.39-2.24 2.341-2.432l63.04.09c1.385.167 2.137.86 2.16 2.162l-.18 12.788c-.172 1.33-.901 1.897-1.98 1.981l-63.04.18c-1.48.298-2.011-.61-2.251-1.891z"
        ></path>
        <ellipse
          cx={64.788}
          cy={15.511}
          fill="#0f0"
          strokeLinecap="round"
          strokeWidth={1.7}
          rx={2.532}
          ry={2.617}
        ></ellipse>
        <path
          fill="none"
          strokeLinecap="round"
          strokeWidth={2}
          d="M50.554 15.745h6.043"
        ></path>
        <g strokeWidth={1.7}>
          <path
            fill="#fc0"
            d="M14.894 24.256l2.383-5.915c.404-.569.862-1.028 1.404-1.32l12.128-.042c1.534.326 1.763.653 2.085.979l2.511 6.255z"
          ></path>
          <path
            fill="#ff8080"
            strokeLinecap="round"
            d="M11.374 24.374H68.865V28.939H11.374z"
          ></path>
          <path
            fill="#e9ddaf"
            d="M5.817 31.507c.044-1.2.39-2.24 2.342-2.432l63.039.09c1.385.167 2.137.86 2.161 2.162l-.18 12.788c-.173 1.33-.902 1.897-1.981 1.981l-63.04.18c-1.479.298-2.01-.61-2.25-1.891z"
          ></path>
          <ellipse
            cx={64.301}
            cy={37.472}
            fill="#0f0"
            strokeLinecap="round"
            rx={2.532}
            ry={2.617}
          ></ellipse>
        </g>
        <path
          fill="none"
          strokeLinecap="round"
          strokeWidth={2}
          d="M50.067 37.706h6.043"
        ></path>
        <g strokeWidth={1.7}>
          <path
            fill="#fc0"
            d="M14.407 46.217l2.383-5.915c.404-.569.862-1.028 1.404-1.32l12.128-.042c1.534.326 1.763.653 2.085.979l2.511 6.255z"
          ></path>
          <path
            fill="#ff8080"
            strokeLinecap="round"
            d="M10.888 46.335H68.379V50.9H10.888z"
          ></path>
          <path
            fill="#e9ddaf"
            d="M5.939 53.64c.043-1.2.39-2.24 2.341-2.432l63.04.09c1.385.167 2.137.86 2.16 2.162l-.18 12.788c-.172 1.33-.901 1.897-1.98 1.981l-63.04.18c-1.48.298-2.011-.61-2.251-1.891z"
          ></path>
          <ellipse
            cx={64.423}
            cy={59.604}
            fill="#0f0"
            strokeLinecap="round"
            rx={2.532}
            ry={2.617}
          ></ellipse>
        </g>
        <path
          fill="none"
          strokeLinecap="round"
          strokeWidth={2}
          d="M50.189 59.838h6.043"
        ></path>
        <path
          fill="#fc0"
          strokeWidth={1.7}
          d="M14.529 68.349l2.383-5.915c.404-.569.862-1.028 1.404-1.32l12.128-.042c1.534.326 1.763.653 2.085.979l2.511 6.255z"
        ></path>
        <path
          fill="#ff8080"
          strokeLinecap="round"
          strokeWidth={1.7}
          d="M11.009 68.467H68.5V73.032H11.009z"
        ></path>
      </g>
    </svg>
  );
}
