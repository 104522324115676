import DivCol from "./DivCol";
import { Typography } from "@mui/material";
import Divider10 from "./Divider10";
import ButtonSM from "./ButtonSM";
import ZoneControlIcon from "./ZoneControlIcon";
export default function CardZoneControl({ zoneControlObj, handleSave, disableClick, iconSize = 7, iconSizeUnit = "vh", tooltip = "Add to map" }) {
    const { zoneControlID, zoneControlName, type } = zoneControlObj || {};
    const defaultClick = () => { }
    return (
        <ButtonSM tooltip={tooltip} onClick={handleSave || defaultClick} disabled={disableClick}>
            <DivCol>
                <Typography variant="body2">{`\u{01f194}${zoneControlID}`}</Typography>
                <ZoneControlIcon type={type} width={iconSize} sizeUnit={iconSizeUnit} />
                <Divider10 space={5} />
                <Typography variant="body2">{zoneControlName || "-"}</Typography>
            </DivCol>
        </ButtonSM>
    );
}