export default function MongoLogo({ width, height, sizeUnit }) {
    const w = width || height || 20;
    const h = height || width || 20;
    const unit = sizeUnit || "px";
    return (
        <svg viewBox="0 0 300 300" width={w + unit} height={h + unit}>
            <g strokeWidth="9.265">
                <path
                    fill="#599636"
                    d="M144.61 3.072l7.912 14.861c1.78 2.743 3.706 5.17 5.976 7.43a207.59 207.59 0 0118.567 20.996c13.407 17.604 22.45 37.153 28.907 58.295 3.873 12.915 5.976 26.165 6.134 39.562.649 40.053-13.082 74.444-40.766 103.03a117.67 117.67 0 01-14.546 12.434c-2.743 0-4.04-2.104-5.17-4.04a33.252 33.252 0 01-4.04-11.627c-.973-4.846-1.612-9.692-1.297-14.694v-2.26c-.222-.483-2.64-222.84-1.677-223.98z"
                ></path>
                <path
                    fill="#6cac48"
                    d="M144.61 2.585c-.324-.649-.649-.158-.973.157.157 3.243-.973 6.133-2.742 8.894-1.946 2.743-4.522 4.846-7.107 7.107-14.36 12.434-25.664 27.452-34.716 44.25C87.028 85.6 80.82 109.828 79.06 135.333c-.806 9.2 2.91 41.665 5.81 51.032 7.912 24.867 22.125 45.704 40.534 63.79 4.521 4.354 9.357 8.394 14.36 12.276 1.455 0 1.613-1.297 1.946-2.261a44.287 44.287 0 001.455-6.3l3.243-24.22z"
                ></path>
                <path
                    fill="#c2bfbf"
                    d="M152.53 269.52c.324-3.706 2.103-6.782 4.04-9.849-1.946-.806-3.391-2.409-4.522-4.197a29.972 29.972 0 01-2.409-5.327c-2.26-6.782-2.742-13.898-3.39-20.828v-4.197c-.807.648-.973 6.133-.973 6.948a160.93 160.93 0 01-2.91 21.801c-.481 2.91-.806 5.81-2.594 8.394 0 .325 0 .65.157 1.13 2.91 8.562 3.706 17.28 4.197 26.166v3.243c0 3.872-.157 3.057 3.058 4.354 1.297.482 2.742.649 4.04 1.612.972 0 1.13-.806 1.13-1.454l-.482-5.328v-14.86c-.157-2.595.324-5.17.648-7.598z"
                ></path>
            </g>
        </svg>
    );
}
