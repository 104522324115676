import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, IconButton, Box } from "@mui/material";
import {
    Lock,
    TouchApp,
} from "@mui/icons-material";
import ButtonSM from "components/ButtonSM";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import { sensorManualExecuteSetting } from "actions/sensorActions";
import DivSpaceBetween from "components/DivSpaceBetween";
import SensorChangeLockLvModal from "./SensorChangeLockLvModal";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import DeviceActionLine from "components/DeviceActionLine";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import ModalSM from "components/ModalSM";
// import SensorNearFieldPanel from "./SensorNearFieldPanel";
import General from "@ecoenghk/general";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import AdminContainer from "components/AdminContainer";
const gs = new General();
export default function ControlDevicesDimControllerPanel({ show }) {
    const [state, dispatch] = useContext(SMContext);
    const { activeSensorID, sensorObjAll, lightObjAll, mapObjAll, activeMapID } =
        state;
    const [activeNearFieldDeviceObj, setActiveNearFieldDeviceObj] = useState({});
    const activeMapObj = mapObjAll[activeMapID];
    const sensorObj = sensorObjAll[activeSensorID];
    const { setting1, setting2, serialMap } = sensorObj || {};
    const lightArrayAll = Object.values(serialMap || {});
    const lightArray = (lightArrayAll || []).filter(
        (s) => s in activeMapObj.lightObj
        // Object.keys(activeMapObj.lightObj || {}).includes(s)
    );
    const lightArrayNotInMap = (lightArrayAll || []).filter(
        (s) => !Object.keys(activeMapObj.lightObj || {}).includes(s)
    );

    return (
        <DivExist show={show}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <DivInline justifyContent="center">
                    <Typography variant="h6" align="center">
                        Control Devices
                    </Typography>
                    <Tooltip title="Manual brighten up">
                        <div>
                            <IconButton
                                size="small"
                                disabled={lightArray.length === 0}
                                onClick={() => sensorManualExecuteSetting(sensorObj, 1)}
                            >
                                <TouchApp />
                            </IconButton>
                        </div>
                    </Tooltip>
                    <GroupActionConfirmOneGroup groupDeviceObj={sensorObj} settingNumArray={[1, 2]} />
                </DivInline>
                <Divider10 />
                <DivSpaceBetween>
                    <Typography>
                        <Lock />
                        Priority Level
                    </Typography>
                    <SensorChangeLockLvModal
                        sensorObj={sensorObj}
                        buttonNo={1}
                        existingLv={sensorObj.lockLevelSet1}
                        existingRelease={sensorObj.releaseOnDelayEnd1}
                    />
                </DivSpaceBetween>
                <Divider10 />
                {lightArray
                    .map((s) => {
                        const lightObj = lightObjAll[s] || {};
                        return (
                            <LightRow
                                key={s}
                                serial={s}
                                lightObj={lightObj}
                                serialSetting={setting1 ? setting1[s] : {}}
                                serialSetting2={setting2 ? setting2[s] : {}}
                                sensorObj={sensorObj}
                            />
                        );
                    })}
                <Divider10 />

            </div>
        </DivExist>
    )
}

function LightRow({ serial, lightObj, serialSetting, serialSetting2, sensorObj }) {
    const [state, dispatch] = useContext(SMContext);
    const [openNearField, setOpenNearField] = useState(false);
    const { nearFieldSetting } = sensorObj || {};
    const { dtkAdd, gatewayID } = serialSetting || {};
    const serialSettingAll = { up: serialSetting, down: serialSetting2 }
    return (
        <div>
            <DivInline>
                <Typography sx={{ marginRight: "1vw" }} onClick={() => alert(JSON.stringify(serialSettingAll || {}))} variant="caption">{lightObj?.description}</Typography>
                <GroupActionConfirmOneDevice deviceObj={sensorObj} controlledDeviceObj={lightObj} settingNumArray={[1, 2]} />
            </DivInline>
            <DivInline>
                <div style={{ width: "80%" }}>
                    <DeviceIdAddGwLine deviceID={serial} address={dtkAdd} gatewayID={gatewayID} withIconAndName deviceObj={lightObj} />
                </div>
                {/* <AdminContainer>
                    <ButtonSM
                        tooltip="Near field command setting"
                        margin={0}
                        onClick={() => setOpenNearField(true)}>
                        <NearFieldIcon width={2} sizeUnit={'vw'} color={nearFieldSetting?.[`${serial}_1_${lightObj.shortAdd || 0}`] ? "red" : "#fff"} />
                    </ButtonSM>
                </AdminContainer> */}
            </DivInline>
            <DeviceActionLine deviceSetting={serialSetting} deviceSetting2={serialSetting2} deviceObj={lightObj} mainDeviceObj={sensorObj} buttonNo={1} />
            {/* <AdminContainer>
                <ModalSM open={openNearField} onClose={() => setOpenNearField(false)} disableBottomClose >
                    <SensorNearFieldPanel
                        sensorObj={sensorObj}
                        deviceObj={lightObj}
                        handleClose={() => setOpenNearField(false)}
                        state={state}
                        dispatch={dispatch}
                        buttonNo={0}
                    />
                </ModalSM>
            </AdminContainer> */}
        </div>
    )
}