import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import SensorIcon from "components/SensorIcon";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import { sensorManualExecuteSetting } from "actions/sensorActions";
const gs = new General();

export default function ManualControlPanelLightSwitch() {
  const [state] = useContext(SMContext);
  const { activeSensorID, sensorObjAll } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const { type, gang } = sensorObj || {};
  return (
    <div>
      <Divider10 />
      <Typography variant="h6" display="block" align="center">
        Manual Control
      </Typography>
      <Divider10 />
      {gs.newArrayBetween(1, gang || 1).map((n) => {
        const disabled =
          Object.keys(sensorObj[`setting${n * 2 - 1}`] || {}).length === 0
            ? true
            : false;
        return (
          <DivInline key={n} justifyContent="space-between">
            <Typography>{`Switch ${n}`}</Typography>
            <Button
              variant="outlined"
              disabled={disabled}
              onClick={() => sensorManualExecuteSetting(sensorObj, n * 2)}
            >
              <SensorIcon
                gang={gang}
                type={type}
                width={5.5}
                sizeUnit="vh"
                disabledConnectStatus
                status={n === 1 ? 1 : 0}
                status2={n === 2 ? 1 : 0}
                status3={n === 3 ? 1 : 0}
                status4={n === 4 ? 1 : 0}
              />
              <Typography>ON</Typography>
            </Button>
            <Button
              variant="outlined"
              disabled={disabled}
              onClick={() => sensorManualExecuteSetting(sensorObj, n * 2 - 1)}
            >
              <SensorIcon
                gang={gang}
                type={type}
                width={5.5}
                sizeUnit="vh"
                disabledConnectStatus
                status={0}
                status2={0}
                status3={0}
                status4={0}
              />
              <Typography>OFF</Typography>
            </Button>
          </DivInline>
        );
      })}
    </div>
  );
}
