import { useState, useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import {
  Typography,
  Tooltip,
  Card,
  CardContent,
  IconButton,
  Box,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { Event, FindInPage, Assignment } from "@mui/icons-material";
import moment from "moment-timezone";
import ResultIcon from "components/ResultIcon";
import Divider10 from "components/Divider10";
import Space from "components/Space";
import DivInline from "components/DivInline";
// import { yellow } from "@mui/material/colors";
import DivExist from "components/DivExist";
import ModalSM from "components/ModalSM";
import { mgFindByMatch } from "actions/mongoApiActions";
import IconButtonDelete from "components/IconButtonDelete";
import SingleReportModal from "./SingleReportModal";
import IconButtonBack from "components/IconButtonBack";
import AdminContainer from "components/AdminContainer";
import ImportReportFromFsModal from "./ImportReportFromFsModal";

export default function LightReportListModal() {
  const [state, dispatch] = useContext(SMContext);
  const { activeSerial, serverType, userObj, lightObjAll } = state;
  const [open, setOpen] = useState(false);
  const [openSingleReport, setOpenSingleReport] = useState(false);
  const [serialReportArray, setSerialReportArray] = useState([]);
  const [serialReportArrayF, setSerialReportArrayF] = useState([]);
  const [showWeekReport, setShowWeekReport] = useState(false);
  const [filterMonth, setFilterMonth] = useState("all");
  const [filterType, setFilterType] = useState("all");
  const [monthMenu, setMonthMenu] = useState([]);
  const lightObj = lightObjAll[activeSerial];
  const handleOpen = async () => {
    setOpen(true);
    await fetchReportArray();
  };

  async function fetchReportArray() {
    let arr = await mgFindByMatch("report", "serial", activeSerial);
    let filteredArr = arr
      .filter((r) => r.schedule_timeStamp > 0)
      .sort((a, b) => b.schedule_timeStamp - a.schedule_timeStamp);
    setSerialReportArray(filteredArr);
    const tspArr = arr.map((r) => r.schedule_timeStamp).sort((a, b) => b - a);
    const monthArr = tspArr.map((t) => moment(t).format("YYYY-MM"));
    const uniqueMonthArr = [...new Set(monthArr)]; //remove duplicate value
    setMonthMenu(uniqueMonthArr);
  }
  useEffect(() => {
    let arr = serialReportArray
      .filter((r) => r.schedule_timeStamp > 0)
      .sort((a, b) => b.schedule_timeStamp - a.schedule_timeStamp)
      // .filter((r) => !r.isWeekTest || showWeekReport)
      .filter(
        (r) =>
          moment(r.schedule_timeStamp).format("YYYY-MM") === filterMonth ||
          filterMonth === "all"
      )
      .filter((r) => filterType === "all" || r.reportType === filterType);
    setSerialReportArrayF(arr);
  }, [serialReportArray, filterType, filterMonth]);
  const handleClose = () => {
    setOpen(false);
  };
  const chooseReport = (reportObj) => {
    dispatch({
      type: "SET_ACTIVE_REPORT",
      payload: reportObj,
    });
    setOpenSingleReport(true);
  };

  // async function handleDeleteSingleReport(e, reportID) {
  //   e.stopPropagation();
  //   if (window.confirm(`Confirm delete report ${reportID} and test data?`)) {
  //     // await deleteManyDocsLF(
  //     //   serverType,
  //     //   `SM_report`,
  //     //   "report",
  //     //   "reportID",
  //     //   reportID
  //     // );
  //     // await deleteManyDocsLF(
  //     //   serverType,
  //     //   `SM_logs/${activeSerial}/dataLogs`,
  //     //   "serialLog",
  //     //   "reportID",
  //     //   reportID
  //     // );
  //     const currentReportID = lightObjAll[activeSerial]?.reportObj?.reportID;
  //     if (currentReportID === reportID) {
  //       // await updateDocLF(
  //       //   serverType,
  //       //   `SM_serial/${activeSerial}`,
  //       //   "serial",
  //       //   { serial: activeSerial },
  //       //   { reportObj: {} }
  //       // );
  //     }
  //     await fetchReportArray();
  //   }
  // }
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={handleOpen}
        startIcon={<FindInPage />}
      >
        All reports
      </Button>
      <ModalSM
        modalIcon={<FindInPage />}
        height="94vh"
        width="95vw"
        open={open}
        onClose={handleClose}
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={handleClose} />
          <Typography>{`Report List - ${lightObj?.description} [${activeSerial}]`}</Typography>
          {/* <Tooltip
                title={
                  <Typography>
                    {showWeekReport ? "Hide" : "Show"} weekly function test
                    report
                  </Typography>
                }
              >
                <IconButton
                  onClick={() => setShowWeekReport(!showWeekReport)}
                  color={showWeekReport ? "secondary" : "default"}
                  size="large"
                >
                  <Event />
                </IconButton>
              </Tooltip> */}
          <DivExist show={userObj.level < 2}>
            <Space width={50} />
            <Typography>Month</Typography>
            <Space width={10} />
            <TextField
              select
              value={filterMonth}
              onChange={(e) => setFilterMonth(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {Object.keys(monthMenu || {}).map((m) => (
                <MenuItem key={m} value={monthMenu[m]}>
                  {monthMenu[m]}
                </MenuItem>
              ))}
            </TextField>
            <Space width={30} />
            <Typography>Report type</Typography>
            <Space width={10} />
            <TextField
              select
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="check_manual">manual test</MenuItem>
              <MenuItem value="check_1month">monthly test</MenuItem>
              <MenuItem value="check_12month">annual test</MenuItem>
            </TextField>
          </DivExist>
          <AdminContainer>
            <ImportReportFromFsModal serial={activeSerial} />
          </AdminContainer>
        </DivInline>
        <div>
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(serialReportArrayF || []).map((r, key) => (
              <Card
                key={r.reportID}
                variant="outlined"
                sx={{
                  width: "20vw",
                  margin: "1vw",
                  cursor: "pointer",
                  backgroundColor: "transparent",
                  borderRadius: "0.5vw",
                  border: "1px solid #fff",
                  "&:hover": {
                    border: "1.5px solid #fff",
                    backgroundColor: "rgba(240,240,240,0.15)",
                  },
                }}
                onClick={() => chooseReport(r)}
              >
                <CardContent>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Typography variant="subtitle1">
                        <Assignment />
                        {key + 1}
                      </Typography>
                      <Typography variant="caption" display="block">
                        {moment(r.schedule_timeStamp)
                          .tz("Asia/Hong_Kong")
                          .format("YYYY-MM-DD")}
                      </Typography>
                      <Typography variant="caption" display="block">
                        {moment(r.schedule_timeStamp)
                          .tz("Asia/Hong_Kong")
                          .format("HH:mm:ss")}
                      </Typography>
                      {r.isWeekTest ? (
                        <Typography variant="caption" display="block">
                          week function test
                        </Typography>
                      ) : (
                        <Typography variant="caption" display="block">
                          {r.reportType}
                        </Typography>
                      )}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        border: "1px solid grey",
                        padding: "0.3vw",
                      }}
                    >
                      <ResultIcon result={r.result} size="3em" />
                      <Typography variant="caption" display="block">
                        {r.result}
                      </Typography>
                    </div>
                  </div>
                  <Box sx={{ marginTop: "1vh" }}>
                    <Typography variant="caption">
                      Report ID:{r.reportID}
                    </Typography>
                  </Box>
                  {/* <IconButtonDelete
                      size="small"
                      onBtnClick={(e) =>
                        handleDeleteSingleReport(e, r.reportID)
                      }
                    /> */}
                </CardContent>
              </Card>
            ))}
            <Divider10 />
          </div>
        </div>
      </ModalSM>
      <SingleReportModal
        open={openSingleReport}
        onClose={() => {
          setOpenSingleReport(false);
          dispatch({
            type: "SET_ACTIVE_REPORT",
            payload: {},
          });
        }}
      />
    </>
  );
}
