import { Link } from "@mui/icons-material";

export default function BtnOpenUrlLink({ url, size }) {
  return (
    <Link
      sx={{
        fontSize: size || "medium",
        cursor: "pointer",
        "&:hover": { backgroundColor: "rgba(210, 210, 210, 0.5)" },
      }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        window.open(url, "_BLANK");
      }}
    />
  );
}
