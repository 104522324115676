import IconButton from "@mui/material/IconButton";
import { Refresh } from "@mui/icons-material";
import DivExist from "./DivExist";
import { Tooltip } from "@mui/material";

/**
 *
 * @param {object} param0
 * @param {function} param0.onBtnClick
 * @param {boolean} param0.hide
 * @param {"large"|"medium"|"small"} param0.size
 * @param {boolean} param0.disabled
 * @param {'inherit'|'primary'|'secondary'|'success'|'error'|'info'|'warning'} param0.color
 * @param {string} [param0.tooltip]
 * @returns
 */
export default function IconButtonRefresh({
  onBtnClick,
  hide,
  size = "large",
  disabled,
  color,
  tooltip,
}) {
  return (
    <DivExist show={!hide}>
      <Tooltip title={tooltip || ""}>
        <IconButton
          onClick={onBtnClick}
          size={size}
          disabled={disabled}
          color={color}
        >
          <Refresh />
        </IconButton>
      </Tooltip>
    </DivExist>
  );
}
