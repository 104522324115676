import { useContext, useRef, useState, useMemo, useEffect } from "react";
import {
  Button,
  Tooltip,
  Typography,
  ButtonGroup,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Box,
} from "@mui/material";
import DivInline from "components/DivInline";
import { Storage } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import ModalSM from "components/ModalSM";
import InputField from "components/InputField";
import Divider10 from "components/Divider10";
import { mgFindOne } from "actions/mongoApiActions";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import { changeServerUploadFirestore, updateServerProperty, uploadMultiDevicesToFS } from "actions/serverActions";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import moment from "moment";
import { ViewportList } from "react-viewport-list";
import {
  fetchAllLights,
  updateLightsFsToLs,
  updateSingleLightFsToLs,
} from "actions/lightActions";
import {
  fetchAllSensors,
  updateSensorsFsToLs,
  updateSingleSensorFsToLs,
} from "actions/sensorActions";
import {
  fetchAllZcs,
  updateZcsFsToLs,
  updateSingleZcFsToLs,
} from "actions/zcActions";
import {
  fetchAllDaliCtls,
  updateDaliCtlsFsToLs,
  updateSingleDaliCtlFsToLs,
} from "actions/daliCtlActions";
import { mgPost } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import { alertWindow, confirmWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import DivExist from "components/DivExist";
import { mgUpdateOneUpsert } from "actions/mongoApiActions";
import ServerLog from "./ServerLog";
import ServerConsoleLog from "./ServerConsoleLog";
import IconButtonDelete from "components/IconButtonDelete";
import { isMatch } from "actions/generalActions";
import DivCol from "components/DivCol";
import ServerMonitorModal from "./ServerMonitorModal";
import ServerSettingMapPanel from "./ServerSettingMapPanel";
import ServerSettingGatewayPanel from "./ServerSettingGatewayPanel";
import { useNavigate } from "react-router-dom";
const gs = new General();
export default function ServerSettingModal() {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState("server");
  const [allGateways, setAllGateways] = useState({});
  const [allLights, setAllLights] = useState({});
  const [allSensors, setAllSensors] = useState({});
  const [allZcs, setAllZcs] = useState({});
  const [allDaliCtls, setAllDaliCtls] = useState({});
  const [importID, setImportID] = useState("");
  const [searchText, setSearchText] = useState("");
  const [serverHasInternet, setServerHasInternet] = useState("");
  const [serverOpInfo, setServerOpInfo] = useState({});
  const { serverID, serverObj, socket, userObj, serverSocketGateway, serverSocketWebUser } = state;
  const gatewayRef = useRef(null);
  const lightRef = useRef(null);
  const sensorRef = useRef(null);
  const zcRef = useRef(null);
  const daliRef = useRef(null);
  const navigate = useNavigate();
  const {
    description,
    enableUploadFS,
    serPublicIp,
    serVersion,
    serverStartTimeStamp,
  } = serverObj || {};
  const upTime = moment(serverStartTimeStamp).fromNow();
  const handleOpen = async () => {
    setOpen(true);
    setDisplay("server");
    handleServerHasInternet();
    handleQueryServerOperation();
  };

  const handleFetchAllLights = async () => {
    const objAll = await fetchAllLights();
    setAllLights(objAll);
  };
  const handleFetchAllSensors = async () => {
    const objAll = await fetchAllSensors();
    setAllSensors(objAll);
  };
  const handleFetchAllZcs = async () => {
    const objAll = await fetchAllZcs();
    console.log("Fetched all Zone control", objAll);
    setAllZcs(objAll);
  };
  const handleFetchAllDaliCtls = async () => {
    const objAll = await fetchAllDaliCtls();
    console.log("Fetched all daliCtl", objAll);
    setAllDaliCtls(objAll);
  };

  const allLightsArr = useMemo(() => {
    let arr = Object.keys(allLights || {})
      .sort((a, b) => a.localeCompare(b))
      .map((s) => allLights[s]);
    if (searchText !== "" && arr.length > 0 && display === "light") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.serial, searchText) ||
          isMatch(obj.description, searchText) ||
          isMatch(obj.dtkAdd, searchText) ||
          isMatch(obj.gatewayID, searchText) ||
          isMatch(obj.daliCtlID, searchText)
      );
    }
    return arr;
  }, [allLights, searchText]);

  const allSensorsArr = useMemo(() => {
    let arr = Object.keys(allSensors || {})
      .sort((a, b) => a.localeCompare(b))
      .map((s) => allSensors[s]);
    if (searchText !== "" && arr.length > 0 && display === "sensor") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.sensorID, searchText) ||
          isMatch(obj.sensorName, searchText) ||
          isMatch(obj.dtkAdd, searchText) ||
          isMatch(obj.gatewayID, searchText)
      );
    }
    return arr;
  }, [allSensors, searchText]);
  const allZcsArr = useMemo(() => {
    let arr = Object.keys(allZcs || {})
      .sort((a, b) => a.localeCompare(b))
      .map((s) => allZcs[s]);
    if (searchText !== "" && arr.length > 0 && display === "zoneControl") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.zoneControlID, searchText) ||
          isMatch(obj.zoneControlName, searchText) ||
          isMatch(obj.type, searchText)
      );
    }
    return arr;
  }, [allZcs, searchText]);
  const allDaliCtlsArr = useMemo(() => {
    let arr = Object.keys(allDaliCtls || {})
      .sort((a, b) => a.localeCompare(b))
      .map((s) => allDaliCtls[s]);
    if (searchText !== "" && arr.length > 0 && display === "daliCtl") {
      arr = arr.filter(
        (obj) =>
          isMatch(obj.daliCtlID, searchText) ||
          isMatch(obj.description, searchText) ||
          isMatch(obj.dtkAdd, searchText) ||
          isMatch(obj.gatewayID, searchText)
      );
    }
    return arr;
  }, [allDaliCtls, searchText]);



  const handleUpdateSingleLightFsToLs = async (s) => {
    await updateSingleLightFsToLs(socket, s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("serial", "serial", s);
    console.log("updated light from FS", obj);
    let newAllLight = { ...allLights, [s]: obj };
    setAllLights(newAllLight);
  };
  const handleUpdateSingleSensorFsToLs = async (s) => {
    await updateSingleSensorFsToLs(socket, s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("sensor", "sensorID", s);
    console.log("updated sensor from FS", obj);
    let newAllSensor = { ...allSensors, [s]: obj };
    setAllSensors(newAllSensor);
  };
  const handleUpdateSingleZcFsToLs = async (s) => {
    await updateSingleZcFsToLs(s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("zoneControl", "zoneControlID", s);
    console.log("updated zone control from FS", obj);
    let newAllZc = { ...allZcs, [s]: obj };
    setAllZcs(newAllZc);
  };
  const handleUpdateSingleDaliCtlFsToLs = async (s) => {
    await updateSingleDaliCtlFsToLs(socket, s);
    await gs.waitFor(1200);
    const obj = await mgFindOne("daliCtl", "daliCtlID", s);
    console.log("updated daliCtl from FS", obj);
    let newAllDaliCtl = { ...allDaliCtls, [s]: obj };
    setAllDaliCtls(newAllDaliCtl);
  };
  const handleServerHasInternet = async () => {
    setServerHasInternet("checking...");
    await gs.waitFor(1000);
    const res = await fetch(`${global.ip}/api/serverHasInternet`);
    const hasInternetRes = await res.json();
    const hasInternet = hasInternetRes.result;
    setServerHasInternet(hasInternet ? "yes" : "no");
  }
  const handleQueryServerOperation = async () => {
    dispatch({
      type: "FETCHED_SERVER_OPERATION_INFO",
      payload: { serverSocketGateway: [], serverSocketWebUser: [] }
    });
    const payload = {
      type: "queryServerOperation",
      uid: userObj.uid
    }
    socket.emit("fromWeb", payload)
  }
  const deviceObj = {
    server: "Server",
    // user: "User",
    map: "Map",
    gateway: "Gateway",
    light: "Light",
    sensor: "Sensor",
    zoneControl: "Zone Ctl",
    daliCtl: "DaliCtl",
    serverLog: "Server Log",
    serverConsoleLog: "Console Log",
  };
  useEffect(() => {
    if (socket) {
      socket.on("onServerChange", async (data) => {
        console.log("[SOCKET IN -onServerChange]", data);
        dispatch({
          type: "FETCHED_SERVER_OBJ",
          payload: data,
        });
      });
      return () => {
        console.log("socket off -onServerChange");
        socket.off("onServerChange");
      }
    }
  }, [socket, dispatch]);
  return (
    <>
      <Tooltip
        title={<Typography>Server Settings</Typography>}
        placement="bottom"
      >
        <Button
          size="small"
          variant="outlined"
          color="inherit"
          onClick={handleOpen}
        >
          <Storage />
        </Button>
      </Tooltip>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalIcon={<Storage />}
        width="98vw"
        height="98vh"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography
            sx={{ marginRight: "1vw" }}
          >{`Server Settings [ID: ${serverID}]`}</Typography>
          <ButtonGroup size="small">
            {Object.keys(deviceObj).map((d, key) => {
              return (
                <Button
                  key={key}
                  variant={display === d ? "contained" : "outlined"}
                  onClick={() => {
                    setImportID("");
                    setDisplay(d);
                    if (d === "light") handleFetchAllLights();
                    if (d === "sensor") handleFetchAllSensors();
                    if (d === "zoneControl") handleFetchAllZcs();
                    if (d === "daliCtl") handleFetchAllDaliCtls();
                  }}
                >
                  {deviceObj[d]}
                </Button>
              );
            })}
          </ButtonGroup>
          <InputField
            sx={{ width: "15vw", marginLeft: "1vw" }}
            label="Search"
            value={searchText}
            onInput={(v) => setSearchText(v)}
            keyPressEnter
          />
          <IconButtonDelete onBtnClick={() => setSearchText("")} />
        </DivInline>
        <Divider10 />
        <DivExist show={display === "server"}>
          <SpaceBetweenDialogInput
            title="Description"
            data={description}
            handleSave={(val) => updateServerProperty(serverID, { description: val })}
          />
          <Divider10 />
          <SpaceBetweenDiv title="Public IP" data={serPublicIp} />
          <Divider10 />
          <SpaceBetweenSwitch
            title="Upload changes to cloud"
            data={enableUploadFS}
            onChange={async (e) => {
              const newStatus = e.target.checked;
              confirmWindow(
                dispatch,
                `${newStatus ? "Start" : "Stop"} synchronize changes to cloud`,
                async () => {
                  await changeServerUploadFirestore(serverID, newStatus);
                  alertWindow(
                    dispatch,
                    "Going to restart server, please refresh this webapp"
                  );
                }
              );
            }}
          />
          <Divider10 />
          <SpaceBetweenDiv
            title="Server has internet"
            data={serverHasInternet}
            handleRefresh={handleServerHasInternet}
          />
          <Divider10 />
          <SpaceBetweenDiv title="Up time" data={upTime} />
          <Divider10 />
          <SpaceBetweenDiv title="Version" data={serVersion} />
          <Divider10 />
          <SpaceBetweenDiv title="Client connected"
            data={<SocketClientConnected serverSocketGateway={serverSocketGateway} serverSocketWebUser={serverSocketWebUser} />}
            handleRefresh={handleQueryServerOperation}
          />
          <Divider10 />
          <SpaceBetweenDiv title="Server monitor"
            data={<ServerMonitorModal />}
          />
          <Divider10 />
          <SpaceBetweenDiv title="Toolbox"
            data={<Button onClick={() => navigate("/ToolboxPage")}>open</Button>}
          />
        </DivExist>

        <DivExist show={display === "map"}>
          <ServerSettingMapPanel open={open} />
        </DivExist>
        <DivExist show={display === "gateway"}>
          <ServerSettingGatewayPanel />
        </DivExist>
        <DivExist show={display === "light"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Light</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateLightsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllLights();
              }}
            >
              All lights FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one light from FS (serial)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_serial/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "serial",
                      { serial: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_serial/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such light in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <DivInline>
            <Box sx={{ width: "10vw" }}>serial</Box>
            <Box sx={{ width: "30vw" }}>description</Box>
            <Box sx={{ width: "15vw" }}>dtkAdd</Box>
            <Box sx={{ width: "15vw" }}>Server▶︎FS</Box>
            <Box sx={{ width: "15vw" }}>FS▶︎Server</Box>
          </DivInline>
          <Divider10 />
          <div className="scroll-container" ref={lightRef}>
            <ViewportList viewportRef={lightRef} items={allLightsArr}>
              {(lightObj, index) => {
                const { serial, description, dtkAdd } = lightObj || {};
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: "#1B2631" },
                    }}
                  >
                    <Box sx={{ width: "10vw" }}>{serial}</Box>
                    <Box sx={{ width: "30vw" }}>{description}</Box>
                    <Box sx={{ width: "15vw" }}>{dtkAdd}</Box>
                    <Box sx={{ width: "15vw" }}>
                      <button onClick={() => uploadMultiDevicesToFS("serial", "SM_serial", "serial", [serial])}>
                        Server▶︎FS
                      </button>
                    </Box>
                    <Box sx={{ width: "15vw" }}>
                      <button
                        onClick={async () =>
                          handleUpdateSingleLightFsToLs(serial)
                        }
                      >
                        FS▶︎Server
                      </button>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={display === "sensor"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Sensor</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateSensorsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllSensors();
              }}
            >
              All sensors FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one sensor from FS (sensorID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_sensor/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "sensor",
                      { sensorID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_sensor/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such sensor in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <DivInline>
            <Box sx={{ width: "10vw" }}>sensorID</Box>
            <Box sx={{ width: "30vw" }}>sensorName</Box>
            <Box sx={{ width: "15vw" }}>dtkAdd</Box>
            <Box sx={{ width: "10vw" }}>type</Box>
            <Box sx={{ width: "15vw" }}>Server▶︎FS</Box>
            <Box sx={{ width: "15vw" }}>FS▶︎Server</Box>
          </DivInline>
          <Divider10 />
          <div className="scroll-container" ref={sensorRef}>
            <ViewportList viewportRef={sensorRef} items={allSensorsArr}>
              {(sensorObj, index) => {
                const { sensorID, sensorName, dtkAdd, type } = sensorObj || {};
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: "#1B2631" },
                    }}
                  >
                    <Box sx={{ width: "10vw" }}>{sensorID}</Box>
                    <Box sx={{ width: "30vw" }}>{sensorName}</Box>
                    <Box sx={{ width: "15vw" }}>{dtkAdd}</Box>
                    <Box sx={{ width: "10vw" }}>{type}</Box>
                    <Box sx={{ width: "15vw" }}>
                      <button onClick={() => uploadMultiDevicesToFS("sensor", "SM_sensor", "sensorID", [sensorID])}>
                        Server▶︎FS
                      </button>
                    </Box>
                    <Box sx={{ width: "15vw" }}>
                      <button
                        onClick={async () =>
                          handleUpdateSingleSensorFsToLs(sensorID)
                        }
                      >
                        FS▶︎Server
                      </button>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={display === "zoneControl"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Zone Control</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateZcsFsToLs();
                await gs.waitFor(1200);
                await handleFetchAllZcs();
              }}
            >
              All zone controls FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one zone control from FS (zoneControlID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_zoneControl/${importID}`,
                  });
                  console.log("fsObj", fsObj);
                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "zoneControl",
                      { zoneControlID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_zoneControl/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such zone control in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <DivInline>
            <Box sx={{ width: "10vw" }}>zoneControlID</Box>
            <Box sx={{ width: "35vw" }}>zoneControlName</Box>
            <Box sx={{ width: "15vw" }}>type</Box>
            <Box sx={{ width: "15vw" }}>Server▶︎FS</Box>
            <Box sx={{ width: "15vw" }}>FS▶︎Server</Box>
          </DivInline>
          <Divider10 />
          <div className="scroll-container" ref={zcRef}>
            <ViewportList viewportRef={zcRef} items={allZcsArr}>
              {(zcObj, index) => {
                const { zoneControlID, zoneControlName, type } = zcObj || {};
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: "#1B2631" },
                    }}
                  >
                    <Box sx={{ width: "10vw" }}>{zoneControlID}</Box>
                    <Box sx={{ width: "35vw" }}>{zoneControlName}</Box>
                    <Box sx={{ width: "15vw" }}>{type}</Box>
                    <Box sx={{ width: "15vw" }}>
                      <button onClick={() => uploadMultiDevicesToFS("zoneControl", "SM_zoneControl", "zoneControlID", [zoneControlID])}>Server▶︎FS</button>
                    </Box>
                    <Box sx={{ width: "15vw" }}>
                      <button onClick={async () => handleUpdateSingleZcFsToLs(zoneControlID)} >
                        FS▶︎Server
                      </button>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={display === "daliCtl"}>
          <DivInline justifyContent="space-between">
            <Typography variant="h6">Dali Control</Typography>
            <Button
              variant="outlined"
              onClick={async () => {
                await updateDaliCtlsFsToLs(socket);
                await gs.waitFor(1200);
                await handleFetchAllDaliCtls();
              }}
            >
              All dali controls FS ▶︎ Server
            </Button>
            <DivInline>
              <TextField
                label="import one dali control from FS (daliCtlID)"
                value={importID}
                onChange={(e) => setImportID(e.target.value)}
                size="small"
                sx={{ minWidth: "30vw" }}
              />
              <Button
                variant="outlined"
                size="small"
                onClick={async () => {
                  let fsObj = await mgPost("getDocFS", {
                    docPath: `SM_daliCtl/${importID}`,
                  });
                  console.log("fsObj", fsObj);

                  if (fsObj.result !== "fail") {
                    fsObj = { ...fsObj, serverID };
                    await mgUpdateOneUpsert(
                      "daliCtl",
                      { daliCtlID: importID },
                      fsObj
                    );
                    await mgPost("updateDocFS", {
                      docPath: `SM_daliCtl/${importID}`,
                      updateObj: fsObj,
                    });
                  } else {
                    alertWindow(dispatch, "No such dali control in FS");
                  }
                }}
              >
                import
              </Button>
            </DivInline>
          </DivInline>
          <Divider10 />
          <DivInline>
            <Box sx={{ width: "10vw" }}>daliCtlID</Box>
            <Box sx={{ width: "35vw" }}>description</Box>
            <Box sx={{ width: "8vw" }}>dtkAdd</Box>
            <Box sx={{ width: "22vw" }}>short add list</Box>
            <Box sx={{ width: "8vw" }}>Server▶︎FS</Box>
            <Box sx={{ width: "8vw" }}>FS▶︎Server</Box>
          </DivInline>
          <Divider10 />
          <div className="scroll-container" ref={daliRef}>
            <ViewportList viewportRef={daliRef} items={allDaliCtlsArr}>
              {(daliCtlObj, index) => {
                const { daliCtlID, description, dtkAdd, addList } = daliCtlObj || {};
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      borderBottom: "1px solid grey",
                      "&:hover": { background: "#1B2631" },
                    }}
                  >
                    <Box sx={{ width: "10vw" }}>{daliCtlID}</Box>
                    <Box sx={{ width: "35vw" }}>{description}</Box>
                    <Box sx={{ width: "8vw" }}>{dtkAdd}</Box>
                    <Box sx={{ width: "22vw" }}>{(addList || []).join(", ")}</Box>
                    <Box sx={{ width: "8vw" }}>
                      <button onClick={() => uploadMultiDevicesToFS("daliCtl", "SM_daliCtl", "daliCtlID", [daliCtlID])}>Server▶︎FS</button>
                    </Box>
                    <Box sx={{ width: "8vw" }}>
                      <button onClick={async () => handleUpdateSingleDaliCtlFsToLs(daliCtlID)} >
                        FS▶︎Server
                      </button>
                    </Box>
                  </Box>
                );
              }}
            </ViewportList>
          </div>
        </DivExist>
        <DivExist show={display === "serverLog"}>
          <ServerLog />
        </DivExist>
        <DivExist show={display === "serverConsoleLog"}>
          <ServerConsoleLog />
        </DivExist>


      </ModalSM>
    </>
  );
}

function SocketClientConnected({ serverSocketGateway, serverSocketWebUser }) {
  return (
    <DivCol alignItems="flex-end">
      <Typography variant="caption" display="block">Gateway</Typography>
      <DivInline>
        {
          (serverSocketGateway || []).map((gw, key) => {
            return (
              <Typography variant="caption" key={key}>{`${gw}, `}</Typography>
            )
          })
        }
      </DivInline>
      <Typography variant="caption" display="block">Web user</Typography>
      <DivInline>
        {
          (serverSocketWebUser || []).map((webUser, key) => {
            return (
              <Typography variant="caption" key={key}>{`${webUser}, `}</Typography>
            )
          })
        }
      </DivInline>
    </DivCol>
  )
}