import { IconButton, Tooltip } from "@mui/material";
import { Edit } from "@mui/icons-material";
import DivExist from "./DivExist";

export default function IconButtonEdit({
  onBtnClick,
  hide,
  size = "large",
  disabled,
  tooltip,
  color
}) {
  return (
    <DivExist show={!hide}>
      <IconButton onClick={onBtnClick} size={size} disabled={disabled} color={color}>
        <Tooltip title={tooltip || ""}>
          <Edit />
        </Tooltip>
      </IconButton>
    </DivExist>
  );
}
