import React from "react";

/**
 * Renders an On/Off icon using the react-konva library.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.x - The x-coordinate for the icon.
 * @param {number} props.y - The y-coordinate for the icon.
 * @param {number} props.size - The size of the icon.
 * @param {"on" | "off"} props.onoff - The state of the icon, either "on" or "off".
 * @returns {JSX.Element} The rendered On/Off icon.
 */
export default function OnOffIcon({ width, height, sizeUnit, onoff }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const innerColor = onoff === "on" ? "#1a1a1a" : "#4d4d4d";
  const mediumColor = onoff === "on" ? "#27ae60" : "#ec7063";
  const outerColor = onoff === "on" ? "#a9dfbf" : "#f5b7b1";
  return (
    <svg
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g fill="none" strokeLinecap="round">
        <path
          stroke={outerColor}
          strokeWidth={15}
          d="M55.569 14.059c11.881 6.873 17.829 20.636 14.649 33.898-3.18 13.262-14.738 22.905-28.47 23.749-13.731.845-26.405-7.307-31.215-20.078-4.81-12.771.206-28.134 11.138-36.41"
        ></path>
        <path
          stroke={mediumColor}
          strokeWidth={9}
          d="M55.569 14.059c11.881 6.873 17.829 20.636 14.649 33.898-3.18 13.262-14.738 22.905-28.47 23.749-13.731.845-26.405-7.307-31.215-20.078-4.81-12.771.206-28.134 11.138-36.41"
        ></path>
        <path
          stroke={innerColor}
          strokeWidth={4}
          d="M55.569 14.059c11.881 6.873 17.829 20.636 14.649 33.898-3.18 13.262-14.738 22.905-28.47 23.749-13.731.845-26.405-7.307-31.215-20.078-4.81-12.771.206-28.134 11.138-36.41"
        ></path>
        <path stroke={outerColor} strokeWidth={15} d="M38.569 7.356v34.173"></path>
        <path stroke={mediumColor} strokeWidth={9} d="M38.569 7.356v34.173" ></path>
        <path stroke={innerColor} strokeWidth={4} d="M38.569 7.356v34.173"></path>
      </g>
    </svg>
  );
}
