import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";
import Bypass from "./Bypass";
export default function Panellight({
  width,
  height,
  sizeUnit,
  lightLv,
  disabledStatus,
  statusL,
  connectStatus,
  disabledConnectStatus,
  typeBatVI,
  bypass,
  backgroundColor
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const lightColor = lightColorAt(disabledStatus, statusL, lightLv);
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      {
        backgroundColor && <rect width={79.375} height={79.375} fill={backgroundColor} />
      }
      <path
        fill="#666"
        d="M19.932 2.86l16.988-.085 10.429 8.326-17.409-.252z"
      ></path>
      <path
        fill="gray"
        d="M32.295 11.101H34.986000000000004V43.816H32.295z"
      ></path>
      <path fill="#8080ff" d="M1.424 43.662H48.503V51.92H1.424z"></path>
      <path
        fill="#8080ff"
        d="M76.883 65.019L49.171 44.026l-.028 8.007 27.738 21.8z"
      ></path>
      <path
        fill="#216778"
        d="M8.785 55.639l39.087-.255.462-2.253-46.829.098L29.78 75 31 72.33z"
      ></path>
      <path
        fill="#216778"
        d="M48.334 53.13L76.63 75.25 29.779 75l1.219-2.67 38.691.498-21.818-17.444z"
      ></path>
      <path
        fill={lightColor}
        stroke="#000"
        strokeWidth={0.265}
        d="M8.785 55.639l39.087-.255L69.69 72.828l-38.691-.498z"
      ></path>
      {!disabledConnectStatus && (
        <circle r="6" cx="10" cy="12" fill={connectStatus ? "green" : "red"} />
      )}
      {!disabledStatus && typeBatVI && (
        <ChargeIcon y={22} x={0} statusL={statusL} />
      )}
      {bypass === 1 && <Bypass x={45} y={20} width={0.25 * width} sizeUnit={sizeUnit} />}
    </svg>
  );
}
