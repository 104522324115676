import { Tooltip } from "@mui/material";
import Clock from "asset/svgComp/Clock";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import ZcSceneButton from "asset/svgComp/ZcSceneButton";
import ZcSwitch from "asset/svgComp/ZcSwitch";

export default function ZoneControlIcon({
  type,
  width,
  height,
  sizeUnit,
  tooltip,
  status,
  plateColor,
}) {
  const w = width || height || 20;
  const h = height || width || 20;

  return (
    <div>
      <Tooltip title={tooltip || "-"}>
        <div>
          {type === "timer" ? (
            <Clock width={width} sizeUnit={sizeUnit} />
          ) : type === "zcSwitch" ? (
            <ZcSwitch width={width} sizeUnit={sizeUnit} status={status || 0} />
          ) : type === "zcSceneButton" ? (
            <ZcSceneButton
              width={width}
              sizeUnit={sizeUnit}
              status={status || 0}
              plateColor={plateColor || "#cccccc"}
            />
          ) : (
            <ZcGeneral width={width} sizeUnit={sizeUnit} />
          )}
        </div>
      </Tooltip>
    </div>
  );
}
