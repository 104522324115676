import React from "react";
/**
 *
 * @param {object} param0
 * @param {number} param0.width
 * @param {number} param0.height
 * @param {"px"|"vw"|"vh"} param0.sizeUnit
 * @param {"light"|"dark"} param0.background
 * @param {function} param0.onClick
 * @returns
 */
export default function SkylightLogo({
  width,
  height,
  sizeUnit,
  background,
  onClick,
}) {
  const w = width || 1200;
  const h = height || 400;
  sizeUnit = sizeUnit || "px";
  const color = background === "light" ? "#006680" : "#D6EAF8";
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width={w + sizeUnit}
      height={h + sizeUnit}
      version="1.1"
      viewBox="0 0 317.5 105.83"
      onClick={onClick}
    >
      <g fill="none" stroke={color} strokeWidth="0.794">
        <path d="M16.709 62.695c3.891-8.594 5.298-11.382-.76-14.221-1.148-.538-2.463-1.5-2.552-2.714-.065-.885 1.807-1.957 1.807-1.957"></path>
        <path d="M15.203 43.804s1.13-.51 1.725-.678c.844-.24 1.731-.642 2.592-.467.556.114 1.179.422 1.424.934.138.29.053.672-.094.958a1.647 1.647 0 01-.91.793c-.52.17-1.16.133-1.635-.14-.329-.19-.5-.586-.653-.934-.114-.255-.243-.543-.187-.817.09-.442.493-.76.794-1.097.315-.354.67-.676 1.05-.958.27-.2.563-.366.864-.513.346-.17.746-.22 1.074-.42a3.555 3.555 0 001.004-.888c.267-.357.45-.782.56-1.214a2.89 2.89 0 00.07-1.027 4.445 4.445 0 00-.466-1.565 4.187 4.187 0 00-.957-1.19 4.529 4.529 0 00-1.401-.841c-.815-.299-1.707-.517-2.569-.42-.392.043-.744.267-1.097.443-.406.203-.829.4-1.168.7-.323.288-.615.634-.794 1.028a1.7 1.7 0 00-.14.724c.006.311.033.654.21.91.096.139.268.209.42.28.442.208.918.358 1.402.42.486.064.982-.004 1.47-.046.455-.038.902-.14 1.355-.187a32.742 32.742 0 011.634-.116c.42-.023.841-.026 1.261-.047.717-.037 1.434-.212 2.148-.14.386.038.756.175 1.121.303.401.142.844.238 1.168.514.295.253.422.653.63.98.227.358.599.659.677 1.075.07.366-.062.755-.21 1.097-.11.256-.279.496-.49.677-.206.177-.488.24-.724.374-.374.212-.755.418-1.097.677-.312.236-.64.474-.864.794-.174.249-.371.538-.35.84.017.262.22.485.396.678.2.218.474.356.724.513.271.171.563.308.84.467.252.145.615.187.748.444.136.263.063.622-.07.887-.195.39-.673.556-.98.864-.25.25-.43.567-.701.794-.378.317-.876.463-1.261.77-.288.23-.555.495-.77.795a1.925 1.925 0 00-.304.583c-.138.48-.09.996-.117 1.495-.07 1.29.08 2.598-.117 3.876-.087.567-.382 1.09-.467 1.657-.056.378-.015.763-.023 1.145-.015.669-.055 1.338-.047 2.008.006.49.07 1.47.07 1.47M13.88 63.059l12.612-.07"></path>
        <path d="M13.88 63.059s-.64.408-.904.677c-.139.143-.268.303-.347.486-.07.162-.076.346-.104.52-.03.196-.073.392-.07.59.003.152.012.31.07.451.098.238.242.475.451.625.128.091.378 0 .451.139.074.139-.045.325-.139.45-.15.201-.451.236-.624.417a.844.844 0 00-.208.347c-.056.2 0 .416 0 .624 0 .174-.007.348 0 .52.006.152-.01.308.034.452.048.153.139.294.243.416.107.125.241.227.382.313.075.045.22.019.243.104.053.191-.218.334-.347.485-.127.15-.331.24-.416.417-.046.094-.03.207-.035.312-.017.312-.035.627 0 .937.017.153.055.305.104.45.072.217.12.46.278.625.107.113.274.147.416.209.181.079.363.16.555.208.17.042.346.058.52.07.312.02.673-.167.937 0 .25.156.22.55.382.797.184.281.4.55.66.763.267.222.591.365.901.52.26.131.521.262.798.348.405.126.83.177 1.25.243.38.06.762.175 1.144.139.303-.03.576-.195.868-.278.275-.078.564-.109.832-.208.243-.09.47-.219.694-.347.24-.137.48-.278.694-.451.229-.186.453-.386.625-.625.106-.147.149-.33.243-.486.081-.134.136-.311.277-.381.446-.22 1.01.157 1.492.035.271-.07.5-.255.729-.417.187-.133.37-.278.52-.45.154-.178.287-.376.382-.59.11-.252.226-.524.208-.799a1.404 1.404 0 00-.312-.763c-.115-.143-.308-.198-.451-.312-.147-.118-.42-.194-.417-.382.006-.287.458-.35.66-.555.167-.17.399-.322.45-.555.042-.186-.111-.367-.138-.555-.043-.298.08-.625-.035-.902-.091-.22-.315-.356-.486-.52-.165-.16-.512-.223-.52-.452-.01-.261.367-.373.555-.555.194-.188.478-.31.59-.555.134-.295.083-.651.035-.972a1.822 1.822 0 00-.243-.694c-.07-.11-.177-.194-.278-.277-.307-.255-.987-.678-.987-.678"></path>
      </g>
      <text
        x="80.212"
        y="79.068"
        fill={color}
        strokeWidth="0.296"
        fontFamily="sans-serif"
        fontSize="13.406"
        letterSpacing="2.117"
        transform="scale(.89526 1.117)"
        style={{ lineHeight: "1.75" }}
      >
        <tspan
          x="80.212"
          y="79.068"
          fontFamily="'Apple SD Gothic Neo'"
          fontWeight="800"
        >
          DESIGN AFTER INSTALLATION
        </tspan>
      </text>
      <g fill="none" stroke={color}>
        <g strokeWidth="1.323">
          <path d="M62.914 23.408s-4.906.756-4.699 5.656c.156 3.687 4.525 5.221 4.525 5.221l12.357.261s9.041 1.36 9.137 10.181c.096 8.821-10.181 10.616-10.181 10.616v-5.308s4.681-2.546 4.612-5.83-4.351-4.873-4.351-4.873H62.48s-9.597-.862-9.572-10.529S63.088 18.1 63.088 18.1zM109.56 18.187h7.396L98.77 36.548l17.839 18.535h-6.875L91.112 36.722zM125.48 18.013h6.526l11.312 14.271 10.877-14.01h6.7l-28.976 37.07h-6.614l14.445-18.709zM168.38 18.274h5.134v22.277l-4.873 4.873zM174.47 50.296l-4.612 4.873 22.712-.087v-4.96zM199.88 19.492v34.459l4.873-5.047V24.017zM239.04 26.366s-5.824-3.919-10.094-3.046c-3.85.788-7.954 2.567-10.094 6.092-1.64 2.7-2.865 6.14-2.175 9.224.869 3.884 2.988 6.859 5.83 9.05 2.15 1.657 5.03 2.503 7.744 2.523 2.55.019 5.035-1.11 7.31-2.263.999-.506 2.697-2 2.697-2"></path>
          <path d="M239.04 26.366l3.916-3.394s-4.955-3.586-7.832-4.438c-1.393-.412-2.898-.279-4.35-.26-1.369.016-2.75.071-4.09.347-1.528.315-3.025.82-4.438 1.48-1.605.749-3.196 1.614-4.525 2.784-1.007.887-1.75 2.038-2.524 3.133-.766 1.085-1.534 2.186-2.088 3.394a18.603 18.603 0 00-1.306 4.09c-.326 1.627-.579 3.306-.435 4.96.147 1.675.697 3.305 1.306 4.872.592 1.524 1.277 3.047 2.262 4.351 1.298 1.72 2.919 3.225 4.7 4.438 1.747 1.19 3.697 2.16 5.742 2.698 2.251.591 4.645.741 6.962.522 1.884-.178 5.482-1.48 5.482-1.48l2.958-1.653 2.089-1.827 2.088-2.437-4.699-2.001"></path>
          <path d="M231.03 34.285h13.401l-4.742 4.742h-8.659zM258.7 34.024l-6.178 6.178V55.43h5.134V39.506h20.1v15.837h5.396V18.36h-5.395v15.663zM294.99 23.321h12.618v-5.395h-17.665V55.17h5.308z"></path>
        </g>
        <path
          strokeWidth="0.794"
          d="M13.399 62.961s-.55-.413-.774-.67c-.208-.24-.423-.497-.516-.8-.268-.88.103-1.853-.051-2.76-.102-.598-.367-1.156-.568-1.728-.179-.511-.323-1.04-.567-1.522-1.024-2.022-2.6-3.72-3.73-5.683-.744-1.294-1.497-2.6-2.001-4.004-.387-1.074-.628-2.204-.77-3.336-.178-1.41-.318-2.856-.103-4.26.29-1.894.935-3.757 1.848-5.44 1.108-2.047 2.544-3.978 4.311-5.493 1.192-1.022 2.634-1.764 4.106-2.31 1.227-.454 2.546-.658 3.85-.77 1.245-.105 2.514-.097 3.747.103 1.624.265 3.242.718 4.722 1.437a14.81 14.81 0 013.746 2.618 16.308 16.308 0 012.977 3.9 15.797 15.797 0 011.591 4.312c.275 1.292.225 2.633.257 3.952.026 1.06.144 2.14-.051 3.183-.273 1.452-.865 2.843-1.54 4.157-.642 1.25-1.578 2.324-2.361 3.49-.62.922-1.314 1.798-1.848 2.772-.449.82-.777 1.7-1.13 2.566-.22.542-.51 1.067-.615 1.642-.168.926.254 1.917 0 2.823-.124.444-.427.824-.719 1.18-.206.254-.718.668-.718.668"
        ></path>
      </g>
    </svg>
  );
}
