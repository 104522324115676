import React, { useState } from "react";
import { Typography, Button } from "@mui/material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import { mapAddDevice } from "actions/mapActions";
import DivInline from "components/DivInline";
import CardGateway from "components/CardGateway";
import { alertWindow } from "actions/screenActions";
import DivCol from "components/DivCol";
import DivExist from "components/DivExist";
import { mgFindQuery, mgUpdateOneUpsert } from "actions/mongoApiActions";
import IconButtonBack from "components/IconButtonBack";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import MongoLogo from "asset/svgComp/MongoLogo";
import FirestoreLogo from "asset/iconPng/firestore_logo.png";
import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
const gs = new General();
export default function AddGatewayToMapPanel({ handleClose, targetX, targetY, state, dispatch, modalSize }) {
  const { activeMapID, mapObjAll } = state;
  const [input, setInput] = useState("");
  const [source, setSource] = useState("");
  const [gatewayObjArray, setGatewayObjArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const mapGatewayObj = mapObjAll[activeMapID]?.gatewayObj || {};
  const searchDb = async () => {
    const query = {
      $or: [
        { gatewayID: input },
        { wifiApMacAddress: input },
      ]
    }
    let objArray = await mgFindQuery("gateway", query);
    setSource("mongo");
    if (!objArray || objArray.length === 0) {
      console.log(`Gateway [${input}] not exist in mongo database`);
      objArray = await getDocsWhereFS("SM_gateway", "array", "gatewayID", "==", input);
      setSource("fs");
      if (!objArray || objArray.length === 0) {
        console.log(`Gateway [${input}] not exist in fs gatewayID`);
        objArray = await getDocsWhereFS("SM_gateway", "array", "wifiApMacAddress", "==", input);
        if (!objArray || objArray.length === 0) {
          console.log(`Gateway [${input}] not exist in fs wifiApMacAddress, search fail`);
          setErrorMsg(`Gateway ID ${input} not exist in database`);
          setGatewayObjArray([]);
        }
      }
    }
    setGatewayObjArray(objArray);
  };
  const handleSave = async (gatewayObj) => {
    const { gatewayID } = gatewayObj || {};
    if (!gatewayID) {
      alertWindow(dispatch, "Invalid gateway ID, add fail");
      return;
    }
    if (source === "fs") {
      alertWindow(dispatch, "Gateway is not in local server, download from firestore first");
      await mgUpdateOneUpsert("gatewayID", { gatewayID }, gatewayObj);
      await gs.waitFor(2000);
    }
    await mapAddDevice(activeMapID, gatewayID, "gateway", gatewayObj, targetX, targetY);
    handleClose();
  };
  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add Gateway to map</Typography>
      </DivInline>
      <Divider10 />
      <SpaceBetweenInput
        title="ID / wifiApMacAddress"
        data={input}
        width="40%"
        onInput={(evt) => {
          setInput(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Divider10 />
      <Button fullWidth onClick={searchDb} variant="outlined" sx={{ margin: "1vw 0" }} disabled={!input} size="small"> Search</Button >
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )}
      <Divider10 space={5} />
      <DivInline justifyContent="center">
        {
          gatewayObjArray.map((gatewayObj, key) => {
            const { gatewayID } = gatewayObj || {};
            const isInMap = mapGatewayObj[gatewayID] && gatewayObj?.mapID?.includes(activeMapID);
            return (
              <DivCol key={key}>
                <CardGateway
                  key={key}
                  gatewayObj={gatewayObj}
                  handleSave={() => handleSave(gatewayObj)}
                />
                <DivExist show={isInMap}>
                  <Typography color="error">Already in map</Typography>
                </DivExist>
              </DivCol>
            )
          })
        }
      </DivInline>
      <Divider10 space={5} />
      <DivExist show={gatewayObjArray.length > 0 || errorMsg}>
        <DivInline justifyContent="center">
          <IconButtonDelete
            onBtnClick={() => {
              setGatewayObjArray([]);
              setInput("");
              setErrorMsg("");
              setSource("");
            }} />
        </DivInline>
      </DivExist>
      <DivExist show={gatewayObjArray.length > 0}>
        <Typography variant="body2">Source:</Typography>
        {
          source === "mongo" ?
            <MongoLogo width={1.5} sizeUnit="vw" /> :
            <img src={FirestoreLogo} alt="firestore" style={{ width: "1.5vw" }} />
        }
      </DivExist>
    </>
  );
}
