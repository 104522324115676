import { Group, Rect } from "react-konva";
import Motion from "asset/mapComp/Motion";
import Lightswitch from "asset/mapComp/Lightswitch";
import moment from "moment";
import FireAlarm from "asset/mapComp/FireAlarm";
import Daylight from "asset/mapComp/Daylight";
import SceneButton from "asset/mapComp/SceneButton";
import SensorGeneral from "asset/mapComp/SensorGeneral";
import DaylightMotion from "asset/mapComp/DaylightMotion";
import DimController from "asset/mapComp/DimController";

export default function MapIconSensor({
  x = 0,
  y = 0,
  layerProps,
  type,
  status,
  status2,
  status3,
  status4,
  disabledSensor,
  luxLv,
  strokeColor,
  shadEnabled,
  onClick,
  onTap,
  onMouseOver,
  onMouseLeave,
  connectStatus,
  timeStamp,
  scale = 1,
  iconBackground = "transparent",
  disabledConnectStatus,
  gang,
}) {
  const { mapScale, iconSize } = layerProps;
  let size = iconSize * mapScale.x;
  if (shadEnabled) size = size * 1.1;
  let connectStatusUse = connectStatus;
  if (moment().diff(moment(timeStamp), "minutes") > 30) {
    connectStatusUse = false;
  }
  return (
    <Group
      x={x}
      y={y}
      onClick={onClick}
      onTap={onTap}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <Rect
        stroke={strokeColor}
        strokeWidth={(2 / scale) || 1}
        cornerRadius={iconSize * 0.1}
        width={size * 1.2}
        height={size * 1.2}
        fill={iconBackground}
      />
      {type === "motion" ? (
        <Motion
          size={size}
          status={status}
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledConnectStatus={disabledConnectStatus}
          disabledSensor={disabledSensor}
        />
      ) : type === "lightswitch" ? (
        <Lightswitch
          size={size}
          status={status}
          status2={status2}
          status3={status3}
          status4={status4}
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledConnectStatus={disabledConnectStatus}
          gang={gang}
          disabledSensor={disabledSensor}
        />
      ) : type === "scenebutton" ? (
        <SceneButton
          size={size}
          status={status}
          status2={status2}
          status3={status3}
          status4={status4}
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledConnectStatus={disabledConnectStatus}
          gang={gang}
          disabledSensor={disabledSensor}
        />
      ) : type === "fire" ? (
        <FireAlarm
          size={size}
          status={status}
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledSensor={disabledSensor}
        />
      ) : type === "daylight" ? (
        <Daylight
          size={size}
          luxLv={luxLv} // lux level
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledConnectStatus={disabledConnectStatus}
          disabledSensor={disabledSensor}
        />
      ) : type === "daylightmotion" ? (
        <DaylightMotion
          size={size}
          luxLv={luxLv}
          motionStatus={status2}
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledConnectStatus={disabledConnectStatus}
          disabledSensor={disabledSensor}
        />
      ) : type === "dimcontroller" ? (
        <DimController
          size={size}
          status={status}
          status2={status2}
          status3={status3}
          status4={status4}
          connectStatus={connectStatusUse}
          shadEnabled={shadEnabled}
          disabledConnectStatus={disabledConnectStatus}
          // gang={gang}
          disabledSensor={disabledSensor}
        />
      ) : (
        <SensorGeneral size={size} />
      )}
    </Group>
  );
}
