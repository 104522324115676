import { useEffect, useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, InputAdornment, Tooltip, MenuItem, Select, FormControl, FormHelperText } from "@mui/material";
import { ArrowRight, ArrowLeft } from "@mui/icons-material";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import Tube from "asset/svgComp/Tube";
import {
  changeOneLightSetting,
  queryOneLightSetting,
  updateSerialProperty,
} from "actions/lightActions";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import IconButtonRefresh from "components/IconButtonRefresh";
import General from "@ecoenghk/general";
import IconButtonBack from "components/IconButtonBack";
const gs = new General();

export default function LightInfoDimPanel() {
  const [state] = useContext(SMContext);
  const [openEdit, setOpenEdit] = useState(false);
  const [mouseEnter, setMouseEnter] = useState(false);
  const { activeSerial, lightObjAll } = state;
  const lightObj = lightObjAll[activeSerial];
  const { type } = lightObj || {};
  // const handleSave = async (dimOnT, dimT, dimOffT) => {
  //   const updateObj = {
  //     dimTransition: Number(dimT),
  //     dimTransitON: Number(dimOnT),
  //     dimTransitOFF: Number(dimOffT),
  //   };
  //   console.log(updateObj);
  //   await updateSerialProperty(activeSerial, lightObj, updateObj);
  //   setOpenEdit(false);
  // };

  return (
    <DivExist show={type?.pwm}>
      <Divider10 />
      <DivInline>
        <Typography>Transition time</Typography>
      </DivInline>
      <DivInline
        sx={{
          margin: "1vw",
          cursor: "pointer",
          outline: mouseEnter ? "0.5px solid grey" : "",
        }}
        onMouseEnter={() => setMouseEnter(true)}
        onMouseLeave={() => setMouseEnter(false)}
        onClick={() => setOpenEdit(true)}
      >
        <Tube width={4} sizeUnit="vw" lightLv={0} disabledConnectStatus />
        <ArrowRight />
        <Tooltip title="Swtich on time">
          <Typography>{lightObj.dimTransitON}s</Typography>
        </Tooltip>
        <ArrowRight />
        <Tube width={4} sizeUnit="vw" lightLv={20} disabledConnectStatus />
        <ArrowLeft />
        <Tooltip title="Dimming time">
          <Typography>{lightObj.dimTransition}s</Typography>
        </Tooltip>
        <ArrowRight />

        <Tube width={4} sizeUnit="vw" lightLv={100} disabledConnectStatus />
        <ArrowRight />
        <Tooltip title="Switch off time">
          <Typography>{lightObj.dimTransitOFF}s</Typography>
        </Tooltip>
        <ArrowRight />
        <Tube width={4} sizeUnit="vw" lightLv={0} disabledConnectStatus />
      </DivInline>
      <EditTransitionModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        lightObj={lightObj}
      // handleSave={handleSave}
      // checkCurrentTime={checkCurrentTime}
      />
    </DivExist>
  );
}

function EditTransitionModal({
  open,
  onClose,
  lightObj,
  // handleSave,
  // checkCurrentTime,
}) {
  // const [dimT, setDimT] = useState(4);
  // const [dimOnT, setDimOnT] = useState(2);
  // const [dimOffT, setDimOffT] = useState(3);
  // useEffect(() => {
  //   setDimT(lightObj?.dimTransition || 4);
  //   setDimOnT(lightObj?.dimTransitON || 2);
  //   setDimOffT(lightObj?.dimTransitOFF || 3);
  // }, [lightObj, open]);
  const checkCurrentTime = async () => {
    await queryOneLightSetting(lightObj, 0x0307);
    await gs.waitFor(1000);
    await queryOneLightSetting(lightObj, 0x0306);
    await gs.waitFor(1000);
    await queryOneLightSetting(lightObj, 0x0308);
  };
  return (
    <ModalSM
      open={open}
      onClose={onClose}
      modalTitle="Change Transition Time"
      width="75vw"
      height="50vh"
      // onSave={() => handleSave(dimOnT, dimT, dimOffT)}
      titleComponent={
        <IconButtonRefresh onBtnClick={checkCurrentTime} />
      }
    >
      <Divider10 />
      <DivInline sx={{ marginTop: "2vh" }}>
        <Tube
          width={4}
          sizeUnit="vw"
          lightLv={0}
          disabledConnectStatus
        />
        <ArrowRight fontSize="large" />
        {/* <TextField
          label="Switch On time"
          value={dimOnT}
          onChange={(e) => setDimOnT(e.target.value)}
          onFocus={(e) => e.target.select()}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">s</InputAdornment>,
          }}
        /> */}
        <FormControl sx={{ width: "12vw" }}>
          <Select
            value={lightObj?.dimTransitON || 0}
            onChange={(e) => changeOneLightSetting(lightObj, 0x0212, { dimTransitON: Number(e.target.value) })}
          >
            <MenuItem value={0}>-</MenuItem>
            {
              gs.newArrayBetween(2, 10).map((item) => <MenuItem key={item} value={item}>{item}s</MenuItem>)
            }
          </Select>
          <FormHelperText>Switch On time</FormHelperText>
        </FormControl>
        <ArrowRight fontSize="large" />
        <Tube
          width={5}
          sizeUnit="vw"
          lightLv={20}
          statusL={0}
          disabledConnectStatus
        />
        <ArrowLeft fontSize="large" />
        {/* <TextField
          label="Fading time"
          value={dimT}
          onChange={(e) => setDimT(e.target.value)}
          onFocus={(e) => e.target.select()}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">s</InputAdornment>,
          }}
        /> */}
        <FormControl sx={{ width: "12vw" }}>
          <Select
            value={lightObj?.dimTransition || 0}
            onChange={(e) => changeOneLightSetting(lightObj, 0x0211, { dimTransition: Number(e.target.value) })}
          >
            <MenuItem value={0}>-</MenuItem>
            {
              gs.newArrayBetween(3, 20).map((item) => <MenuItem key={item} value={item}>{item}s</MenuItem>)
            }
          </Select>
          <FormHelperText>Fading time</FormHelperText>
        </FormControl>
        <ArrowRight fontSize="large" />

        <Tube
          width={5}
          sizeUnit="vw"
          lightLv={100}
          statusL={0}
          disabledConnectStatus
        />
        <ArrowRight fontSize="large" />
        {/* <TextField
          label="Switch Off time"
          value={dimOffT}
          onChange={(e) => setDimOffT(e.target.value)}
          onFocus={(e) => e.target.select()}
          variant="outlined"
          InputProps={{
            endAdornment: <InputAdornment position="end">s</InputAdornment>,
          }}
        /> */}
        <FormControl sx={{ width: "12vw" }}>
          <Select
            value={lightObj?.dimTransitOFF || 0}
            onChange={(e) => changeOneLightSetting(lightObj, 0x0213, { dimTransitOFF: Number(e.target.value) })}
          >
            <MenuItem value={0}>-</MenuItem>
            {
              gs.newArrayBetween(3, 20).map((item) => <MenuItem key={item} value={item}>{item}s</MenuItem>)
            }
          </Select>
          <FormHelperText>Switch Off time</FormHelperText>
        </FormControl>
        <ArrowRight fontSize="large" />
        <Tube
          width={5}
          sizeUnit="vw"
          lightLv={0}
          disabledConnectStatus
        />
      </DivInline>
    </ModalSM>
  );
}
