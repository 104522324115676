import MapModal from "../device/MapModal";
import SensorNearFieldPanel from "containers/sensor/SensorNearFieldPanel";

export default function SenNearFieldModal({ x, y, width, height, open, handleClose, layerProps, sensorObj, deviceObj, buttonNo }) {
    return (
        <MapModal
            x={x}
            y={y}
            open={open}
            handleCloseModal={handleClose}
            width={width}
            height={height}
            layerProps={layerProps}
            disableBottomAction
            disableCloseButton
        >
            {
                open &&
                <SensorNearFieldPanel
                    sensorObj={sensorObj}
                    deviceObj={deviceObj}
                    handleClose={handleClose}
                    state={layerProps}
                    dispatch={layerProps.dispatch}
                    buttonNo={buttonNo}
                />
            }

        </MapModal>
    )
}