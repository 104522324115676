import React, { useContext } from "react";
import { SMContext } from "../context/smContext";
import { CircularProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButtonClose from "./IconButtonClose";

export default function BigProgress() {
  const [state, dispatch] = useContext(SMContext);
  const { showBigProgress } = state;
  return (
    <div>
      <Dialog
        fullScreen
        open={showBigProgress}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="secondary" size="10em" />
        </div>
        <div style={{ position: "absolute", right: 5, top: 5 }}>
          <IconButtonClose
            onBtnClick={() => dispatch({ type: "HIDE_BIG_PROGRESS" })}
          />
        </div>
      </Dialog>
    </div>
  );
}
