import { Ellipse, Circle, Group, Path, Rect } from "react-konva";

export default function Gateway({
  x,
  y,
  size,
  connectStatus,
  shadEnabled,
  disabledConnectStatus,
  outline
}) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} perfectDrawEnabled={false} >
      {!disabledConnectStatus && (
        <Circle x={-10} radius={7} fill={connectStatus ? "green" : "red"} perfectDrawEnabled={false} />
      )}
      {(outline ? true : false) && (
        <Rect
          x={-10}
          y={-10}
          width={100}
          height={100}
          stroke="purple"
          strokeWidth={4}
          perfectDrawEnabled={false}
        />
      )}
      <Path
        fill="#ccc"
        stroke="teal"
        strokeWidth={4.671}
        data="M6.334 50.848c.78-2.375 1.498-4.807 4.756-4.796h58.901c2.627-.013 4.562 1.546 5.488 5.165l-.366 17.708c-.318 2.855-1.684 4.409-3.841 4.98l-59.998-.369c-2.96-.1-4.593-1.982-5.122-5.349z"
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={4}
        shadowOffset={{ x: 8, y: 8 }}
        shadowOpacity={0.3}
        perfectDrawEnabled={false}
      />
      <Ellipse
        x={17.267}
        y={59.823}
        fill="#00aad4"
        fillOpacity={0.996}
        radiusX={3.489}
        radiusY={3.518}
        perfectDrawEnabled={false}
      ></Ellipse>
      <Ellipse
        x={26.971}
        y={59.779}
        fill="#5fd35f"
        fillOpacity="0.996"
        radiusX={3.489}
        radiusY={3.518}
        perfectDrawEnabled={false}
      ></Ellipse>
      <Ellipse
        x={36.899}
        y={59.886}
        fill="#d35f5f"
        fillOpacity="0.996"
        radiusX={3.489}
        radiusY={3.518}
        perfectDrawEnabled={false}
      ></Ellipse>
      <Path
        fill="teal"
        data="M45.071 45.236l-.102-15.747c1.672-4.257 5.989-4.37 7.349 0v15.953z"
        perfectDrawEnabled={false}
      ></Path>
      <Path
        fill="#d4aa00"
        data="M38.165 28.714c1.438-8.36 8.673-8.899 10.89-9 2.025.106 9.157 1.258 10.135 9 .29 3.811 5.133 3.967 5.899.227.24-4.935-4.808-15.094-16.35-15.074-11.532.594-16.07 8.51-16.246 14.771.804 5.032 6.244 3.236 5.672.076z"
        perfectDrawEnabled={false}
      ></Path>
      <Path
        fill="#decd87"
        data="M21.491 28.964c1.036 3.573 5.15 3.102 5.873 0 .015-11.2 10.476-19.411 21.463-19.396 10.972.259 20.664 6.802 21.188 19.38.72 3.245 4.975 3.606 5.893-.13-.613-15.343-13.06-24.909-27.233-24.99-9.866.392-25.916 5.558-27.184 25.136z"
        perfectDrawEnabled={false}
      ></Path>

    </Group>
  );
}
