import { Group, Rect, Text, Circle, Ellipse } from "react-konva";

export default function DaliCtl({ x, y, size, connectStatus, disabledConnectStatus, }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} >
      {
        !disabledConnectStatus && <Circle radius={7} fill={connectStatus ? "green" : "red"} perfectDrawEnabled={false} />
      }
      <Ellipse
        x={40}
        y={45}
        radiusX={38}
        radiusY={25}
        stroke="#A569BD"
        strokeWidth={5}
        fill="white"
        perfectDrawEnabled={false}
      />
      <Text
        x={10}
        y={37}
        fill="green"
        stroke="none"
        strokeWidth={1}
        fontFamily="Apple SD Gothic Neo"
        fontSize={28}
        fontStretch="normal"
        fontStyle="normal"
        fontVariant="normal"
        fontWeight="bold"
        text="DALI"
        perfectDrawEnabled={false}
      />
    </Group>
  );
}
