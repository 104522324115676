import { Group, Rect, Circle } from "react-konva";

export default function ShiftSelectCircle({ shiftSelected, iconSize, scale }) {
  if (!shiftSelected) return null;
  else
    return (
      <Circle
        x={iconSize * 0.5}
        y={iconSize * 0.5}
        stroke="red"
        strokeWidth={3 / scale || 3}
        radius={iconSize}
      />
    );
}
