import { useState, useEffect } from "react";
import {
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Edit, Done, Close } from "@mui/icons-material";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string|number} props.initialVal
 * @param {function(string|number)} props.handleSave
 * @param {"string"|"number"} [props.dataType]
 * @param {boolean} [props.autoSelect]
 * @param {string} [props.iconSize]
 * @param {boolean} [props.disableAutoFocus]
 * @param {boolean} [props.showValue]
 * @param {"body1"|"body2"|"caption"|"subtitle1"} [props.showValueVariant]
 * @param {string} [props.showValueUnit]
 * @param {boolean} [props.disableEditIcon]
 *
 */
export default function DialogInput({
  title,
  initialVal,
  handleSave,
  dataType,
  autoSelect,
  iconSize,
  disableAutoFocus,
  showValue,
  showValueVariant,
  showValueUnit,
  disableEditIcon,
}) {
  const [open, setOpen] = useState(false);
  const [tempData, setTempData] = useState("");
  const closeDialog = () => setOpen(false);
  useEffect(() => {
    setTempData(initialVal);
  }, [initialVal]);

  const handleFocus = (e) => {
    if (autoSelect) {
      e.target.select();
    }
  };
  function handleConfirmInput() {
    if (dataType === "number") {
      handleSave(Number(tempData));
    } else {
      handleSave(tempData);
    }
    closeDialog();
  }
  function keyPressed(evt) {
    if (evt.key === "Enter" || evt.which === "13") {
      handleConfirmInput();
    }
  }
  return (
    <>
      {showValue && (
        <Typography
          variant={showValueVariant || "body1"}
          onClick={() => setOpen(!open)}
          style={{
            cursor: "pointer",
          }}
        >
          {initialVal}
          {showValueUnit}
        </Typography>
      )}
      {!disableEditIcon && (
        <Edit
          className="noPrint"
          onClick={() => setOpen(!open)}
          style={{
            cursor: "pointer",
            color: "grey",
            fontSize: iconSize || "1.2em",
          }}
        />
      )}
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            value={tempData}
            onChange={(evt) => setTempData(evt.target.value)}
            onFocus={handleFocus}
            onKeyPress={keyPressed}
            inputProps={{ autoFocus: !disableAutoFocus }}
          />
        </DialogContent>
        <DialogActions>
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
          <IconButton onClick={handleConfirmInput} size="large">
            <Done />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
