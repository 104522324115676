import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Workspaces } from "@mui/icons-material";
import { Typography, Tooltip } from "@mui/material";
import Hover from "./Hover";
import { openSnackbar } from "actions/screenActions";
import { gatewayGroupActionCheck } from "actions/gatewayActions";
import { confirmAllGroupAction } from "actions/generalActions";
import General from "@ecoenghk/general";
const gs = new General();
export default function GroupActionConfirmOneGroup({ groupDeviceObj, settingNumArray, size = "1.2rem" }) {
    const [state, dispatch] = useContext(SMContext);
    const { enableGroupAction } = groupDeviceObj || {};
    let groupID, groupDeviceType;
    if (groupDeviceObj.zoneControlID) {
        groupID = groupDeviceObj.zoneControlID.replace("zc_", "");
        groupDeviceType = "zoneControl";
    } else {
        groupID = groupDeviceObj.sensorID;
        groupDeviceType = "sensor";
    }
    let statusColor = confirmAllGroupAction(groupDeviceObj, settingNumArray)
    let msg = "Group action enabled - Group ID " + groupID;
    if (statusColor === "success") msg += " -All devices confirmed";
    else if (statusColor === "error") msg += " -Some/all devices not confirmed";
    else msg += "No GAS setting";
    const handleCheck = async () => {
        let gatewayIDArray = [];
        settingNumArray.forEach(n => {
            const setting = groupDeviceObj[`setting${n}`];
            if (setting && !gs.isEmptyJson(setting)) {
                Object.keys(setting || {}).forEach(controlledDeviceID => {
                    const gatewayID = setting[controlledDeviceID].gatewayID;
                    if (gatewayID && !gatewayIDArray.includes(gatewayID)) gatewayIDArray.push(gatewayID);
                });
            }
        });
        openSnackbar(dispatch, `Temp delete GAS in gateway ${gatewayIDArray.join(",")}`);
        dispatch({ type: "DELETE_GAS_SETTINGS", payload: { groupID, groupDeviceType } });
        await gs.asyncForEach(gatewayIDArray, async (gatewayID) => {
            await gatewayGroupActionCheck(gatewayID, groupID);
            await gs.waitFor(200);
        });
    }
    if (!enableGroupAction) return null;
    return (
        <>
            <Hover>
                <Tooltip title={msg}>
                    <Workspaces
                        color={statusColor}
                        sx={{ fontSize: size }}
                        onClick={async (e) => {
                            e.stopPropagation();
                            await handleCheck();
                        }}
                    />
                </Tooltip>
            </Hover>
        </>
    )
}