import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";
import Bypass from "./Bypass";

export default function TubeHalf({
    width,
    height,
    sizeUnit,
    lightLv,
    disabledStatus,
    statusL,
    connectStatus,
    disabledConnectStatus,
    typeBatVI,
    bypass,
    backgroundColor
}) {
    const w = width || height || 10;
    const h = height || width * 2 || 20;
    const unit = sizeUnit || "px";
    const lightColor = lightColorAt(disabledStatus, statusL, lightLv);
    return (
        <svg viewBox="0 0 40 80" width={w + unit} height={h + unit}>
            {
                backgroundColor && (
                    <rect width="40" height="80" fill={backgroundColor} />
                )
            }
            <ellipse
                cx={-29.179}
                cy={24.336}
                fill={lightColor}
                rx={39.062}
                ry={13.693}
                transform="matrix(.06842 -.99766 .90306 .42951 0 0)"
            />
            <path
                fill="none"
                stroke="#22b3fd"
                strokeLinejoin="round"
                strokeWidth={2.285}
                d="M14.987 70.672l11.115.243-.218-60.823-11.115-.243z"
            ></path>

            <path
                fill="none"
                stroke="#918a6f"
                strokeLinejoin="round"
                strokeWidth={2.323}
                d="M14.976 75.397l.01-4.725 11.116.243.1 4.948z"
            ></path>
            <path
                fill="none"
                stroke="#918a6f"
                strokeLinecap="round"
                strokeWidth={2.266}
                d="M23.525 78.326l.023-1.792M17.803 78.196l.023-1.792"
            ></path>
            <path
                fill="none"
                stroke="#918a6f"
                strokeLinejoin="round"
                strokeWidth={2.422}
                d="M75.578 22.509l-4.467 2.948-5.956-8.843 4.616-3.175z"
                transform="matrix(.57588 -.71976 .86902 .5122 -37.19 48.235)"
            ></path>
            <path
                fill="none"
                stroke="#918a6f"
                strokeLinecap="round"
                strokeWidth={2.362}
                d="M74.115 13.991l-1.704 1.103M76.647 18.547l-1.704 1.103"
                transform="matrix(.57588 -.71976 .86902 .5122 -37.19 48.235)"
            ></path>
            {!disabledConnectStatus && (
                <circle r={6} cx={6} cy={6} fill={connectStatus ? "green" : "red"} />
            )}
            {!disabledStatus && typeBatVI && (
                <ChargeIcon y={24} x={0} statusL={statusL} />
            )}
            {bypass === 1 && <Bypass x={18} y={50} width={0.3 * w} sizeUnit={unit} />}
        </svg>
    );
}
