import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
    Typography,
    Button,
    Paper,
    Box,
    TextField,
    MenuItem,
    Divider,
} from "@mui/material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import Divider10 from "components/Divider10";
import { mgUpdateOneUpsert } from "actions/mongoApiActions";

export default function ResetPassword() {
    const [searchParams] = useSearchParams();
    const [newPw, setNewPw] = useState("");
    const [confirmNewPw, setConfirmNewPw] = useState("");

    let curUrl = window.location.href;
    const user = searchParams.get("user");
    const ip = searchParams.get("ip");
    const handleResetPassword = async () => {
        if (newPw !== confirmNewPw) {
            alert("Password mismatch");
            return;
        }
        if (newPw.length < 6) {
            alert("Password length must be at least 6 characters");
            return;
        }
        global.ip = ip;
        const res = await mgUpdateOneUpsert("user", { email: user }, { password: newPw });
        if (res.result === "fail") {
            alert("Update password to database fail");
            return;
        }
        alert("Password updated");
        window.open(ip, "_self");
    }
    return (
        <Paper sx={{ background: "transparent" }}>
            <DivInline justifyContent="center">
                <Box sx={{ marginTop: "4vh", width: "50vw", height: "82vh" }}>
                    <DivCol>
                        <SkylightLogo width={30} height={10} sizeUnit="vw" />
                        <Typography variant="body2" sx={{ margin: "3vh" }}>
                            Computerized Lighting Management System
                        </Typography>
                        <Typography variant="h6" sx={{ margin: "3vh" }}>
                            Reset Password
                        </Typography>
                        <Divider10 />
                        <Typography>{user}</Typography>
                        <Divider10 />
                        <TextField
                            label="New Password"
                            type="password"
                            variant="outlined"
                            value={newPw}
                            onChange={(e) => setNewPw(e.target.value)}
                        />
                        <Divider10 />
                        <TextField
                            label="Confirm New Password"
                            type="password"
                            variant="outlined"
                            value={confirmNewPw}
                            onChange={(e) => setConfirmNewPw(e.target.value)}
                        />
                        <Divider10 />
                        <Button variant="contained" color="primary"
                            onClick={handleResetPassword}
                        >
                            Reset Password
                        </Button>
                        <Divider10 />
                        <Typography variant="caption">server ip: {ip}</Typography>
                        <Divider10 />
                    </DivCol>
                </Box>
            </DivInline>

        </Paper>
    )
}