import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Delete } from "@mui/icons-material";
import { Typography, TextField, Tooltip, Box } from "@mui/material";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import ZoneControlIcon from "components/ZoneControlIcon";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DialogInput from "components/DialogInput";
import {
  updateZcProperty,
  deleteZoneControl,
  zoneControlDisconnectAllLights,
} from "actions/zcActions";
import { confirmWindow } from "actions/screenActions";
import ModalRemarks from "components/ModalRemarks";
import DivSpaceBetween from "components/DivSpaceBetween";
import { zoneCtlDefault } from "asset/string/string";
import AdminContainer from "components/AdminContainer";

export default function ZcInfoPanel() {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, activeZoneControlID, zoneControlObjAll, mapObjAll } =
    state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  const { zoneControlName, type, mapID, plateColor, zoneControlShortName } = zcObj || {};
  const mapIDisArray = Array.isArray(mapID);
  const typeName = zoneCtlDefault[type]?.typeName || "";
  const editProperty = async (updateObj) => {
    await updateZcProperty(activeZoneControlID, updateObj);
  };
  const handleDelete = async () => {
    confirmWindow(
      dispatch,
      `Confirm to delete Zone Control ${zoneControlName} [${activeZoneControlID}]?`,
      async () => {
        await deleteZoneControl(activeMapID, activeZoneControlID, zcObj);
        dispatch({
          type: "ALL_UNSELECTED",
        });
      }
    );
  };
  const handleRemoveAllLights = async () => {
    confirmWindow(
      dispatch,
      `Confirm to remove all lights from${zoneControlName} [${activeZoneControlID}]?`,
      async () => {
        await zoneControlDisconnectAllLights(activeZoneControlID, zcObj);
      }
    );
  };
  return (
    <>
      <Typography variant="h6" align="center">
        General
      </Typography>
      <Divider10 />
      <SpaceBetweenDiv title="Zone Control ID" data={activeZoneControlID} />
      <SpaceBetweenDiv
        title="Zone Control Name"
        data={
          <DivInline>
            <Typography>{zoneControlName}</Typography>
            <DialogInput
              title="Zone Control name?"
              initialVal={zoneControlName || ""}
              handleSave={async (newVal) =>
                await editProperty({ zoneControlName: newVal })
              }
            />
          </DivInline>
        }
      />
      <SpaceBetweenDiv
        title="Zone Control Short Name"
        data={
          <DivInline>
            <Typography>{zoneControlShortName}</Typography>
            <DialogInput
              title="Zone Control short name?"
              initialVal={zoneControlShortName || ""}
              handleSave={async (newVal) =>
                await editProperty({ zoneControlShortName: newVal })
              }
            />
          </DivInline>
        }
      />
      <SpaceBetweenDiv title="Zone Control Type" data={typeName} />
      <SpaceBetweenDiv
        title="Icon"
        data={<ZoneControlIcon type={type} zoneControlObj={zcObj} />}
      />
      <SpaceBetweenDiv
        hideComponent={type !== "zcSceneButton"}
        title="Color"
        data={
          <DivInline>
            <TextField
              size="small"
              inputProps={{ style: { width: "3vw" } }}
              type="color"
              // defaultValue="#cccccc"
              value={plateColor || "#cccccc"}
              onChange={async (e) => {
                console.log("change color", e.target.value);
                await editProperty({ plateColor: e.target.value });
              }}
            />
            <Tooltip title="Reset to default color">
              <Box
                onClick={async () =>
                  await editProperty({ plateColor: "#cccccc" })
                }
                sx={{
                  marginLeft: "1vw",
                  width: "1.5vw",
                  height: "1.5vh",
                  backgroundColor: "#cccccc",
                  cursor: "pointer",
                }}
              ></Box>
            </Tooltip>
          </DivInline>
        }
      />
      <Divider10 />
      {
        mapIDisArray ? (
          <SpaceBetweenDiv
            title="In Map"
            data={
              <div>
                {mapID
                  ?.filter((id) => Object.keys(mapObjAll).includes(id))
                  ?.map((id, key) => (
                    <Typography
                      align="right"
                      display="block"
                      variant="caption"
                      key={key}
                    >
                      {mapObjAll[id]?.mapName} [{id}]
                    </Typography>
                  ))}
              </div>
            }
          />
        ) : (
          <SpaceBetweenDiv
            title="In Map"
            data={
              <Typography variant="caption">{`${mapObjAll[mapID]?.mapName} [${mapID}]`}</Typography>
            }
          />
        )
      }

      <Divider10 />
      <Typography variant="h6" align="center">
        Other
      </Typography>
      <Divider10 />
      <DivSpaceBetween style={{ marginBottom: "1vh" }}>
        <Typography>Remarks</Typography>
        <ModalRemarks
          currentRemarks={zcObj?.remarks}
          handleSave={async (r) => {
            await editProperty({ remarks: r });
          }}
          closeOnSave
        />
      </DivSpaceBetween>
      <AdminContainer>
        <SpaceBetweenButton
          btnSize="small"
          title="Disconnect all lights"
          btnContent="Disconnect"
          onBtnClick={handleRemoveAllLights}
          marginBottom="1vh"
        />
      </AdminContainer>
      <SpaceBetweenButton
        btnSize="small"
        title="Delete Zone Control"
        btnContent={<Delete />}
        onBtnClick={handleDelete}
        color="secondary"
      />
    </>
  );
}
