import { useState, useContext, useRef, useMemo } from "react";
import { GroupWork, Search, Sort } from "@mui/icons-material";
import { Typography, Button, Tooltip, Box, Checkbox } from "@mui/material";
import { SMContext } from "context/smContext";
import ModalSM from "components/ModalSM";
import InputField from "components/InputField";
import IconButtonDelete from "components/IconButtonDelete";
import { confirmWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import DivInline from "components/DivInline";
import TimerSettingDisplayModal from "containers/zoneCtl/TimerSettingDisplayModal";
import General from "@ecoenghk/general";
import { ViewportList } from "react-viewport-list";
import { isMatch } from "actions/generalActions";
import ZoneControlIcon from "components/ZoneControlIcon";
import { deleteZoneControl } from "actions/zcActions";
import DeviceMongoModal from "components/DeviceMongoModal";
import Divider10 from "components/Divider10";
const gs = new General();

export default function ZcAdminModal() {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [zcObjArr, setZcObjArr] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortField, setSortField] = useState("");
  const [sortValArr, setSortValArr] = useState([]);
  const { mapObjAll } = state;
  const ref = useRef(null);
  const handleOpen = async () => {
    setOpen(true);
    const res = await fetch(`${global.ip}/api/mgFindAll?col=zoneControl`);
    const arrJson = await res.json();
    console.log(arrJson.data);
    let arr = arrJson.data.map((obj) => {
      let newObj = { ...obj, mapName: mapObjAll[obj.mapID]?.mapName };
      const serialStr = Object.values(obj.serialMap || {})?.join(", ") || "";
      newObj.serialStr = serialStr;
      const sensorStr = Object.values(obj.sensorMap || {})?.join(", ") || "";
      newObj.sensorStr = sensorStr;
      return newObj;
    });
    setZcObjArr(arr);
  };
  const zcObjArrUse = useMemo(() => {
    // let arr = zcObjArr.map((obj) => {
    //   let newObj = { ...obj, mapName: mapObjAll[obj.mapID]?.mapName };
    //   const serialStr = Object.values(obj.serialMap || {})?.join(", ") || "";
    //   newObj.serialStr = serialStr;
    //   const sensorStr = Object.values(obj.sensorMap || {})?.join(", ") || "";
    //   newObj.sensorStr = sensorStr;
    //   return newObj;
    // });
    let arr = zcObjArr;
    if (searchText !== "") {
      arr = arr.filter(
        (p) =>
          isMatch(p.zoneControlID, searchText) ||
          isMatch(p.zoneControlName, searchText) ||
          isMatch(p.type, searchText) ||
          isMatch(p.mapName, searchText) ||
          isMatch(p.serialStr, searchText) ||
          isMatch(p.sensorStr, searchText)
      );
    }
    if (sortField !== "" && sortValArr.length > 0) {
      arr = arr.filter((obj) => (sortValArr || [])?.includes(obj[sortField]));
    }
    return arr;
  }, [zcObjArr, searchText, sortField, sortValArr]);

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleOpen}
        size="small"
        color="inherit"
      >
        <Tooltip
          placement="top"
          title={<Typography>Zone Control Management</Typography>}
        >
          <GroupWork />
        </Tooltip>
      </Button>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        height="95vh"
        width="96vw"
        disableBottomClose
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <GroupWork />
          <Typography sx={{ marginRight: "2vw" }}>
            Zone Control Management
          </Typography>
          <DivInline>
            <Search />
            <Box sx={{ width: "28vw" }}>
              <InputField
                value={searchText}
                onInput={(val) => setSearchText(val)}
                autoFocus
                keyPressEnter
                label="搜尋"
              />
            </Box>
            <IconButtonDelete
              onBtnClick={() => setSearchText("")}
              disabled={searchText ? false : true}
            />
          </DivInline>
        </DivInline>

        <div
          style={{
            display: "flex",
            width: "100%",
            borderBottom: "1px solid grey",
            borderTop: "1px solid grey",
            paddingBottom: "0.5vh",
            fontSize: "small",
          }}
        >
          <div style={{ width: "2%" }}></div>
          <div style={{ width: "9%" }}>zoneControlID</div>
          <div style={{ width: "18%" }}>zoneControlName</div>
          <div style={{ width: "7%" }}>
            <DivInline>
              <Typography sx={{ color: sortField === "type" ? "yellow" : "" }}>
                type
              </Typography>
              <SortModal
                sortField="type"
                objArr={zcObjArr}
                onSorted={(valArr) => {
                  setSortField(valArr.length > 0 ? "type" : "");
                  setSortValArr(valArr);
                }}
                initialSortedArr={sortValArr}
              />
            </DivInline>
          </div>
          <div style={{ width: "15%" }}>
            <DivInline>
              <Typography
                sx={{ color: sortField === "mapName" ? "yellow" : "" }}
              >
                mapName
              </Typography>
              <SortModal
                sortField="mapName"
                objArr={zcObjArr}
                onSorted={(valArr) => {
                  setSortField(valArr.length > 0 ? "mapName" : "");
                  setSortValArr(valArr);
                }}
                initialSortedArr={sortValArr}
              />
            </DivInline>
          </div>
          <div
            style={{
              width: "30%",
            }}
          >
            control lights
          </div>
          <div
            style={{
              width: "20%",
            }}
          >
            control sensors
          </div>
          <div style={{ width: "10%" }}></div>
        </div>
        <div className="scroll-container" ref={ref}>
          <ViewportList viewportRef={ref} items={zcObjArrUse}>
            {(zcObj, key) => (
              <div
                key={key}
                style={{
                  display: "flex",
                  width: "100%",
                  borderBottom: "0.5px solid grey",
                  fontSize: "small",
                }}
              >
                <div style={{ width: "2%" }}>{key}</div>
                <div style={{ width: "9%" }}>
                  <DeviceMongoModal deviceObj={zcObj}>
                    {zcObj.zoneControlID}
                  </DeviceMongoModal>
                </div>
                <div style={{ width: "18%", fontSize: "small" }}>
                  {zcObj.zoneControlName}
                </div>
                <div style={{ width: "4%" }}>
                  {zcObj.type === "timer" ? (
                    <TimerSettingDisplayModal zcObj={zcObj}>
                      <ZoneControlIcon type={zcObj.type} tooltip={zcObj.type} />
                    </TimerSettingDisplayModal>
                  ) : (
                    <ZoneControlIcon type={zcObj.type} tooltip={zcObj.type} />
                  )}
                </div>
                <div style={{ width: "15%", fontSize: "x-small" }}>
                  <div>{zcObj.mapID}</div>
                  <div>{zcObj.mapName}</div>
                </div>
                <div
                  style={{
                    width: "30%",
                    textWrap: "wrap",
                    fontSize: "x-small",
                  }}
                >
                  {zcObj.serialStr}
                </div>
                <div
                  style={{
                    width: "20%",
                    textWrap: "wrap",
                    fontSize: "x-small",
                  }}
                >
                  {zcObj.sensorStr}
                </div>
                <div style={{ width: "10%" }}>
                  <IconButtonDelete
                    size="small"
                    onBtnClick={() => {
                      confirmWindow(
                        dispatch,
                        `確定刪除${zcObj.zoneControlID}?`,
                        () => {
                          deleteZoneControl(
                            zcObj.mapID,
                            zcObj.zoneControlID,
                            zcObj
                          );
                          handleOpen();
                        }
                      );
                    }}
                  />
                </div>
              </div>
            )}
          </ViewportList>
        </div>
      </ModalSM>
    </>
  );
}

function SortModal({ initialSortedArr, sortField, objArr, onSorted }) {
  const [openSort, setOpenSort] = useState(false);
  const [sortedArr, setSortedArr] = useState([]);
  const handleOpenSort = () => {
    setOpenSort(true);
    setSortedArr(initialSortedArr || []);
  };
  let fieldValArr = objArr.map((obj) => obj[sortField]);
  fieldValArr = [...new Set(fieldValArr)];
  return (
    <>
      <Sort onClick={handleOpenSort} sx={{ cursor: "pointer" }} />
      <ModalSM
        open={openSort}
        onClose={() => setOpenSort(false)}
        modalTitle="Sort"
        height="80vh"
        width="70vw"
        onSave={() => {
          onSorted(sortedArr);
          setOpenSort(false);
        }}
      >
        <DivInline>
          <Typography sx={{ width: "50vw" }}>{sortField}</Typography>
          <Checkbox
            checked={sortedArr.length > 0}
            onChange={(e) => {
              if (!e.target.checked) {
                setSortedArr([]);
              } else {
                setSortedArr(fieldValArr);
              }
            }}
          />
        </DivInline>
        <Divider10 />
        {fieldValArr.map((val, key) => {
          return (
            <DivInline key={key}>
              <Typography sx={{ width: "50vw" }}>{val}</Typography>
              <Checkbox
                checked={(sortedArr || [])?.includes(val)}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSortedArr([...sortedArr, val]);
                  } else {
                    setSortedArr(sortedArr.filter((v) => v !== val));
                  }
                }}
              />
            </DivInline>
          );
        })}
      </ModalSM>
    </>
  );
}
