import { ArrowForwardIos } from '@mui/icons-material';
import ModalSM from 'components/ModalSM';
import SpaceBetweenButton from 'components/SpaceBetweenButton';
import React, { useState } from 'react';
import General from '@ecoenghk/general';
import { Button, TextField, Typography } from '@mui/material';
import Divider10 from 'components/Divider10';
import DivInline from 'components/DivInline';
import IconButtonAdd from 'components/IconButtonAdd';
import IconButtonDelete from 'components/IconButtonDelete';
import { gatewaySendTestByteArray } from 'actions/gatewayActions';
const gs = new General();

export default function TestByteModal({ gatewayID, ttyS_no, type }) {
    const [open, setOpen] = useState(false);
    const [byteArrString, setByteArrString] = useState("");
    const [byteArray, setByteArray] = useState([]);
    const handleOpen = () => {
        setOpen(true);
        setByteArray([]);
        setByteArrString("");
    }
    const handleAddChecksum = () => {
        const len = byteArray[1] + 1;
        const lenStr = len.toString(16).padStart(2, "0");
        let newStr = byteArrString.substring(0, 2) + lenStr + byteArrString.substring(4);
        newStr = newStr.slice(0, -2);
        const checksumByteStr = gs.getChecksum_dtk(newStr);
        console.log(newStr, "check sum:", checksumByteStr)
        // let strWithCS = newStr.slice(0, -2) + checksumByteStr + newStr.slice(-2);
        const strWithCS = newStr + checksumByteStr + "ff"
        setByteArrString(strWithCS);
        let byteArr = byteStrToByteArray(strWithCS);
        setByteArray(byteArr);
    }
    const handleRemoveChecksum = () => {
        const len = byteArray[1] - 1;
        const lenStr = len.toString(16).padStart(2, "0");
        let newStr = byteArrString.substring(0, 2) + lenStr + byteArrString.substring(4);
        newStr = newStr.slice(0, -4) + "ff";
        setByteArrString(newStr);
        let byteArr = byteStrToByteArray(newStr);
        setByteArray(byteArr);
    }

    return (
        <>
            <SpaceBetweenButton title="Send Test Byte" btnContent={<ArrowForwardIos />} onBtnClick={handleOpen} />
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle={`Send Test Byte to ${gatewayID} [${ttyS_no}] ${type}`}
                width="95vw"
                height="60vh"
            >
                <DivInline>
                    <Typography>Bytes in hex:</Typography>
                    <IconButtonDelete onBtnClick={() => {
                        setByteArrString("");
                        setByteArray([]);
                    }} />
                </DivInline>
                <DivInline>
                    <TextField sx={{ width: "50vw" }}
                        pattern="[a-fA-F\d]+"
                        value={byteArrString}
                        onChange={e => {
                            const str = e.target.value;
                            if (/[^a-fA-F0-9]/.test(str)) {
                                return;
                            }
                            setByteArrString(str);
                            let byteArr = byteStrToByteArray(str);
                            setByteArray(byteArr);
                        }} />
                    <Button variant={"outlined"} onClick={handleAddChecksum}>Add Checksum</Button>
                    <Button variant={"outlined"} onClick={handleRemoveChecksum}>Remove Checksum</Button>
                </DivInline>
                <Divider10 />
                <Typography>Bytes in hex:</Typography>
                <DivInline>

                    {
                        byteArray.map((byte, i) => {
                            if (isNaN(byte)) return null;
                            return (
                                <Typography key={i} variant="body1">{`${byte.toString(16)?.padStart(2, "0")}, `}</Typography>
                            )
                        })
                    }
                </DivInline>
                <Divider10 />
                <Typography>Bytes in decimal:</Typography>
                <DivInline>
                    {
                        byteArray.map((byte, i) => {
                            if (isNaN(byte)) return null;
                            return (
                                <Typography key={i} variant="body1">{`${byte}, `}</Typography>
                            )
                        })
                    }
                </DivInline>
                <Divider10 />
                <Button variant="contained" onClick={() => {
                    gatewaySendTestByteArray(gatewayID, ttyS_no, byteArray);
                }}>Send</Button>
            </ModalSM>
        </>
    )
}

const byteStrToByteArray = (str) => {
    let byteArr = [];
    for (let i = 0; i < str.length; i += 2) {
        const byteStr = str.slice(i, i + 2);
        const byte = parseInt(byteStr, 16);
        if (isNaN(byte)) continue;
        byteArr.push(byte);

    }
    return byteArr;
}