import { useEffect, useContext, useState } from "react";
import { SMContext } from "context/smContext";
import { Typography, CircularProgress, Tooltip, Button } from "@mui/material";
import { RemoveRedEye } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import ResultIcon from "components/ResultIcon";
import DivExist from "components/DivExist";
import BatteryCharge from "asset/svgComp/BatteryCharge";
import ModalSM from "components/ModalSM";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import { mgFindByMatch } from "actions/mongoApiActions";
import ChartVI2 from "./ChartVI2";

export default function TestViewModal({ reportID }) {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [reportObj, setReportObj] = useState({});
  const [dataTArray, setDataTArray] = useState([]);
  const [dataVArray, setDataVArray] = useState([]);
  const [dataIArray, setDataIArray] = useState([]);
  const [dataVminArray, setDataVminArray] = useState([]);
  const [dataIminArray, setDataIminArray] = useState([]);
  const [energy, setEnergy] = useState(0);
  const { activeSerial, lightObjAll } = state;
  const lightObj = lightObjAll[activeSerial];
  const {
    reversePolarityI,
    timeStamp,
    progress,
    result,
    testTime,
  } = lightObj;
  const statusL = lightObj.onoffstatusL;
  const {
    reportType,
    time_pretest,
    time_cutOff,
    time_recharge,
    time_interval,
    minV,
    minI,
  } = reportObj || {};
  const totalTestTime = Math.round((testTime * 100) / progress);

  useEffect(() => {
    if (reportID) {
      fetchReportObj(reportID);
      fetchReportDataArray(reportID);
    }
  }, [timeStamp, progress]);
  const handleOpen = async () => {
    setOpen(true);
    await fetchReportObj(reportID);
    await fetchReportDataArray(reportID);
  };

  const fetchReportObj = async (rid) => {
    if (rid) {
      const arr = await mgFindByMatch("report", "reportID", rid);
      setReportObj(arr[0]);
    }
  };
  async function fetchReportDataArray(rid) {
    if (rid) {
      const arr = await mgFindByMatch("serialLogs", "reportID", rid);
      console.log(arr);
      let dataArr = [
        ["time", "voltage", "current", "minVoltage", "minCurrent"],
      ];
      let dataVArr = [];
      let dataIArr = [];
      let dataTArr = [];
      let dataVminArr = [];
      let dataIminArr = [];
      let dataIdischargeArr = [];
      arr.forEach((data) => {
        let dataI = reversePolarityI ? data.current * -1 : data.current;
        dataI = Math.round(dataI * 100) / 100;
        const dataMinI = reversePolarityI ? minI * -1 : minI;
        let duration = data.testTime / 60;
        duration = Math.round(duration * 100) / 100;
        const item = [duration, data.voltage, dataI, minV, dataMinI];
        dataArr = [...dataArr, item];
        dataTArr = [...dataTArr, duration];
        dataVArr = [...dataVArr, data.voltage];
        dataIArr = [...dataIArr, dataI];
        dataVminArr = [...dataVminArr, minV || 0];
        dataIminArr = [...dataIminArr, dataMinI || 0];
        if (!reversePolarityI && dataI > 0)
          dataIdischargeArr = [...dataIdischargeArr, dataI];
        if (reversePolarityI && dataI < 0)
          dataIdischargeArr = [...dataIdischargeArr, dataI];
      });
      setDataTArray(dataTArr);
      setDataVArray(dataVArr);
      setDataIArray(dataIArr);
      setDataVminArray(dataVminArr);
      setDataIminArray(dataIminArr);

      const avgI =
        dataIdischargeArr.reduce((acc, item) => {
          return acc + item;
        }, 0) / dataIdischargeArr.length;
      const energyMAH = (avgI * testTime * 1000) / 3600;
      setEnergy(energyMAH.toFixed(2));
      console.log(dataIdischargeArr);
      console.log(energyMAH);
    }
  }

  return (
    <>
      <Tooltip title="View test">
        <Button onClick={handleOpen} variant="outlined">
          <RemoveRedEye />
        </Button>
      </Tooltip>
      <ModalSM
        modalTitle={`View Test of ${activeSerial}`}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        height="95vh"
        width="94vw"
        disableBottomClose
      >
        <DivInline>
          <DivCol style={{ overflowY: "auto", width: "15vw", height: "85vh" }}>
            <Button onClick={() => fetchReportDataArray(reportID)}>
              fetch
            </Button>
            <CircularProgress
              variant="determinate"
              size={100}
              value={progress}
            />
            <Typography variant="body1" display="block" align="center">
              {progress}%
            </Typography>
            <Typography variant="caption">
              Test time:{testTime}s / {totalTestTime}s
            </Typography>
            {statusL === 0 ||
              (statusL === 1 && (
                <BatteryCharge
                  darkLight="dark"
                  status={statusL === 0 ? "charge" : "discharge"}
                  width={4}
                  sizeUnit="vw"
                />
              ))}

            <ResultIcon result={result} size="4em" />

            <Divider10 />
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Report ID:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {reportID}
            </Typography>
            <Divider10 />
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Test type:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {reportType}
            </Typography>
            <Divider10 />
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Min battery volt:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {minV?.toFixed(1)}V
            </Typography>
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Min discharge current:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {minI?.toFixed(2)}A
            </Typography>
            <Divider10 />
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Duration:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {time_pretest}-{time_cutOff}-{time_recharge}min
            </Typography>
            <Typography variant="caption" display="block" align="center">
              Interval:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {time_interval}sec
            </Typography>
            <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Test start:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {reportObj?.schedule_timeString}
            </Typography>
            {/* <Typography
              variant="caption"
              display="block"
              align="center"
              style={{ color: "lightgrey" }}
            >
              Test end:
            </Typography>
            <Typography variant="caption" display="block" align="center">
              {reportObj?.deviceTestEnd_timeString}
            </Typography> */}
            <Divider10 />
            <DivExist show={!isNaN(energy)}>
              <TitleText text="Discharged energy:" />
              <DataText text={`${energy}mAh`} />
            </DivExist>
          </DivCol>

          <div style={{ flexGrow: 1 }}>
            <ChartVI2
              chartDataArray={[
                dataVArray,
                dataIArray,
                dataVminArray,
                dataIminArray,
              ]}
              chartLabel={dataTArray}
              legendTextColor="#fff"
            />
          </div>
        </DivInline>
      </ModalSM>
    </>
  );
}

function TitleText({ text }) {
  return (
    <Typography
      variant="caption"
      display="block"
      align="center"
      style={{ color: "lightgrey" }}
    >
      {text}
    </Typography>
  );
}

function DataText({ text }) {
  return (
    <Typography variant="caption" display="block" align="center">
      {text}
    </Typography>
  );
}
