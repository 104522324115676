import React, { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import ButtonSM from "components/ButtonSM";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import ModalSM from "components/ModalSM";
import SensorNearFieldPanel from "./SensorNearFieldPanel";

export default function ControlDevicesNearFieldModal({ sensorObj, settingNumArray, buttonNo }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const nearFieldSetting = sensorObj.nearFieldSetting;
    const deviceQty = Object.keys(sensorObj[`setting${settingNumArray[0]}`] || {}).length;
    let nearFieldCompleted = useMemo(() => {
        let completed = true;
        settingNumArray.forEach(n => {
            const setting = sensorObj[`setting${n}`];
            if (setting) {
                Object.keys(setting || {}).forEach(s => {
                    const deviceSetting = setting[s] || {};
                    if (deviceSetting) {
                        const { wifiApMacAddress } = deviceSetting || {};
                        if (!(nearFieldSetting?.[`setting${n}`] || []).includes(wifiApMacAddress)) {
                            completed = false;
                            return false;
                        }
                    }
                });
            }
        });
        return completed;
    }, [sensorObj, settingNumArray, nearFieldSetting]);
    const handleOpen = async () => {
        setOpen(true);
    }
    if (!sensorObj.enableGroupAction) return null;
    return (
        <>
            <ButtonSM
                tooltip="Near field command setting"
                margin={0}
                disabled={deviceQty === 0}
                onClick={handleOpen}>
                <NearFieldIcon
                    width={1.2}
                    sizeUnit={'vw'}
                    color={deviceQty === 0 ? "lightgrey" : nearFieldCompleted ? "lightgreen" : "orange"}
                />

            </ButtonSM>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle={`Near field setting - ${sensorObj.sensorID}`}
            >
                <SensorNearFieldPanel sensorObj={sensorObj} settingNumArray={settingNumArray} buttonNo={buttonNo} state={state} />
            </ModalSM>
        </>
    )
}