import DivExist from "components/DivExist";
import { Text } from "react-konva";
import DeviceLight from "../device/DeviceLight";
import DeviceUnknown from "../device/DeviceUnknown";
import LinesLightSelected from "../device/LinesLightSelected";
import DeviceSlaveLight from "../device/DeviceSlaveLight";

export default function LayerLight({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    lightObjAll,
    mapScale,
    selectedDeviceType,
    selectedID,
  } = layerProps;
  const mapLightObj = mapObjAll[activeMapID]?.lightObj;
  const lightArray = Object.keys(mapLightObj || {});

  return (
    <DivExist show={lightArray.length > 0}>
      {lightArray.map((s, key) => {
        const x = mapLightObj[s]?.x * mapScale.x || 0;
        const y = mapLightObj[s]?.y * mapScale.y || 0;
        const lightObj = lightObjAll?.[s];
        if (lightObj && !lightObj?.masterSerial) {
          return (
            <DeviceLight
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              serial={s}
              lightObj={lightObjAll?.[s]}
            />
          );
        }
        if (lightObj && lightObj?.masterSerial) {
          return (
            <DeviceSlaveLight
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              serial={s}
              lightObj={lightObjAll?.[s]}
            />
          )
        }
        return (
          <DeviceUnknown
            key={key}
            layerProps={layerProps}
            x={x}
            y={y}
            deviceID={s}
            deviceType="light"
            text="L"
          />
        );

      })}
      <DivExist
        show={selectedID && selectedDeviceType === "light" ? true : false}
      >
        <LinesLightSelected layerProps={layerProps} />
      </DivExist>
    </DivExist>
  );
}
