import { Line } from "react-konva";
import { lightColorDark, lightColorLight, sensorColorLight } from "actions/generalActions";
export default function LinesZoneControlSelected({ layerProps }) {
  const {
    selectedID,
    zoneControlObjAll,
    layerScale,
    mapObjAll,
    activeMapID,
    selectedDeviceX,
    selectedDeviceY,
    mapScale,
  } = layerProps;
  const zcObj = zoneControlObjAll[selectedID];
  const { serialMap, sensorMap } = zcObj || {};
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj;
  const mapLightObj = mapObjAll[activeMapID]?.lightObj;
  return (
    <>
      {Object.values(serialMap || {}).map((s, key) => (
        <Line
          key={key}
          points={[
            selectedDeviceX,
            selectedDeviceY,
            mapLightObj?.[s]?.x * mapScale.x,
            mapLightObj?.[s]?.y * mapScale.y,
          ]}
          stroke={lightColorDark}
          strokeWidth={1 / layerScale}
        />
      ))}
      {Object.values(sensorMap || {}).map((z, key) => (
        <Line
          key={key}
          points={[
            selectedDeviceX,
            selectedDeviceY,
            mapSensorObj?.[z]?.x * mapScale.x,
            mapSensorObj?.[z]?.y * mapScale.y,
          ]}
          stroke={sensorColorLight}
          strokeWidth={1 / layerScale}
        />
      ))}
    </>
  );
}
