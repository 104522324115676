import React from "react";

export default function DegreeCelsius({ width, height, sizeUnit, color = "grey" }) {
    const w = width || height || 20;
    const h = height || width || 20;
    const unit = sizeUnit || "px";
    return (
        <svg
            viewBox="0 0 80 80"
            width={w + unit}
            height={h + unit}
        >
            <ellipse
                cx={15}
                cy={20}
                stroke={color}
                strokeWidth={5}
                rx={9}
                ry={9}
                fill="none"
            ></ellipse>
            <path
                stroke={color}
                strokeWidth={3}
                fill="none"
                d="M52.992 31.091a9.072 9.645 0 01-12.431-.77 9.072 9.645 0 010-13.237 9.072 9.645 0 0112.431-.77"
                transform="scale(2.5 2.5) translate(-27 -6)"
            ></path>
        </svg>
    );
}
