import { Circle, Group, Path } from "react-konva";

export default function ZcSceneButton({
  x,
  y,
  size,
  status,
  plateColor,
  shadEnabled,
}) {
  const scale = size / 79.375;

  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      <Path
        fill={plateColor || "#ccc"}
        stroke="#6c5d53"
        strokeWidth={5}
        data="M16.135 8.792l55.972.089c3.558.53 5.2 2.797 5.448 6.327v56.637c-.434 2.847-2.235 4.687-5.145 5.71l-57.214-.112c-3.661-.499-4.74-2.95-5.167-5.896L9.962 14c1.005-2.771 2.48-5.08 6.173-5.208z"
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={4}
        shadowOffset={{ x: 8, y: 8 }}
        shadowOpacity={0.3}
        dash={[10, 10]}
        perfectDrawEnabled={false}
      ></Path>
      <Circle
        x={43.737}
        y={42.874}
        fill="#666"
        fillOpacity={0.996}
        stroke={status === 1 ? "red" : "#0ff"}
        strokeLinecap="round"
        strokeWidth={3.704}
        radius={17.859}
        perfectDrawEnabled={false}
      ></Circle>
    </Group>
  );
}
