import { Circle, Rect, Group, Path } from "react-konva";

export default function FireAlarm({
  x,
  y,
  size,
  status,
  connectStatus,
  shadEnabled,
  backgroundColor,
}) {
  const scale = size / 79.375;

  return (
    <Group x={x} y={y} scaleY={scale * 1.3} scaleX={scale * 1.3}>
      <Rect
        width="79.375"
        height="79.375"
        fill={backgroundColor || "#fff"}
        shadEnabled={shadEnabled}
      />
      <Path
        data="m38.053 63.564c-3.7916-0.69411-7.4133-1.7445-10.088-4.7803-1.3542-2.1095-2.8439-4.0355-3.294-7.3696-0.02108-2.4762-0.03145-4.9569 1.2353-7.9671 1.1115-2.1161 1.85-4.1337 4.941-6.772 0.30512 1.811 0.42323 3.7124 1.4411 5.1786 0.73888 1.2105 1.7843 2.1246 3.0881 2.7885-0.49828-2.7926-1.7188-4.9863-1.0294-8.7638 0.77844-2.2046 1.5929-4.4302 3.294-7.1704 1.0194-1.3655 2.3453-3.1018 4.1175-5.3778 0.66102 2.1851 1.0839 4.5238 2.2646 6.3737 0.94708 2.4653 1.9933 4.8667 3.4999 6.9712 1.6145 2.6208 3.1528 5.3152 4.941 7.7679 0.94169 1.5774 1.6811 3.2201 2.0588 4.9794 0.15747 2.2214-0.24584 4.3343-1.0294 6.3737-0.87808 1.6758-2.0935 3.2863-3.9116 4.7803-0.94465 1.1247-2.6518 1.8068-4.5293 2.3901-1.4522 0.62535-4.0507 0.69624-6.9997 0.59754z"
        fill={status === 1 ? "#f00" : "#b3b3b3"}
      />
      <Path
        data="m38.856 63.695c-1.7295-0.31416-3.3815-0.78958-4.6014-2.1636-0.61771-0.95478-1.2972-1.8265-1.5025-3.3356-0.0096-1.1208-0.01435-2.2435 0.56344-3.606 0.507-0.95776 0.84385-1.871 2.2538-3.0651 0.13917 0.81968 0.19305 1.6803 0.65734 2.3439 0.33703 0.54791 0.81386 0.9616 1.4086 1.2621-0.22728-1.264-0.78398-2.2569-0.46953-3.9666 0.35507-0.99785 0.72659-2.0052 1.5025-3.2454 0.46496-0.61806 1.0698-1.4039 1.8781-2.4341 0.30151 0.98901 0.49439 2.0475 1.033 2.8848 0.43199 1.1158 0.90922 2.2028 1.5964 3.1553 0.73641 1.1862 1.4381 2.4058 2.2538 3.5159 0.42954 0.71397 0.7668 1.4575 0.93906 2.2538 0.07183 1.0054-0.11214 1.9618-0.46953 2.8848-0.40052 0.7585-0.95491 1.4874-1.7842 2.1636-0.43089 0.50907-1.2096 0.8178-2.0659 1.0818-0.66242 0.28304-1.8477 0.31513-3.1928 0.27045z"
        fill={status === 1 ? "#ff0" : "#666"}
      />
      <Path
        data="m39.957 2.3223c-21.383 0-39.02 16.808-39.02 37.66s17.636 37.66 39.02 37.66c21.383 0 39.021-16.808 39.021-37.66s-17.638-37.66-39.021-37.66zm0 10c16.195 0 29.021 12.437 29.021 27.66s-12.827 27.66-29.021 27.66-29.02-12.437-29.02-27.66 12.825-27.66 29.02-27.66z"
        color="#000000"
        fill={status === 1 ? "#d40000" : "#808080"}
        stroke-linecap="round"
      />
      <Path
        data="m39.799 2.0938c-13.862 0-26.736 7.0925-33.748 18.752-7.012 11.659-7.012 26.155 0 37.814s19.886 18.754 33.748 18.754v-10c-10.447 0-20.035-5.3575-25.178-13.908-5.1424-8.5507-5.1424-18.955 0-27.506 5.1424-8.5507 14.731-13.906 25.178-13.906z"
        color="#000000"
        fill={status === 1 ? "#d35f5f" : "#e6e6e6"}
      />

      <Circle radius={7} fill={connectStatus ? "green" : "red"} />
    </Group>
  );
}
