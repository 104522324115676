import { useState, useEffect, useMemo, Fragment } from "react";
import KonvaLabel from "../device/KonvaLabel";
import MapDeviceIcon from "../device/MapDeviceIcon";

export default function Layer433Channel({ layerProps }) {
    const { layerRef, activeMapID, lightObjAll, sensorObjAll, gatewayObjAll, daliCtlObjAll, mapObjAll, mapScale, iconSize, timeTableArrAll } = layerProps;
    const [scale, setScale] = useState(2);
    useEffect(() => {
        const layer = layerRef.current;
        const layerScale = layer.scaleX();
        setScale(layerScale);
    }, []);
    const activeMapObj = mapObjAll[activeMapID];
    const mapLightObj = activeMapObj?.lightObj || {};
    const mapLightArray = useMemo(() => Object.keys(mapLightObj)
        .filter(s => {
            const lightObj = lightObjAll[s] || {};
            if (lightObj.daliCtlID) return false;
            if ("masterSerial" in lightObj) return false;
            return true;
        }), [lightObjAll, mapLightObj]);
    const mapSensorObj = activeMapObj?.sensorObj || {};
    const mapSensorArray = useMemo(() => Object.keys(mapSensorObj)
        .filter(s => {
            const sensorObj = sensorObjAll[s] || {};
            if (sensorObj.daliCtlID) return false;
            return true;
        }), [sensorObjAll, mapSensorObj]);
    const mapGatewayArray = Object.keys(activeMapObj?.gatewayObj || {});
    const mapGatewayObj = activeMapObj?.gatewayObj || {};
    const mapDaliCtlArray = Object.keys(activeMapObj?.daliCtlObj || {});
    const mapDaliCtlObj = activeMapObj?.daliCtlObj || {};
    return (
        <>
            {
                mapLightArray.map(s => {
                    const lightObj = lightObjAll[s] || {};
                    const chObj = getChannelMsg(lightObj);
                    const { msg, color } = chObj;
                    return (
                        <Fragment key={s}>
                            <MapDeviceIcon
                                x={mapLightObj[s]?.x * mapScale.x || 0}
                                y={mapLightObj[s]?.y * mapScale.y || 0}
                                deviceObj={lightObj}
                                layerProps={layerProps}
                                deviceType="light"
                            />
                            <KonvaLabel
                                x={mapLightObj[s]?.x * mapScale.x || 0}
                                y={(mapLightObj[s]?.y + (iconSize * 1.05)) * mapScale.y || 0}
                                text={msg}
                                tagColor={color}
                            />
                        </Fragment>
                    )
                })
            }
            {
                mapSensorArray.map(s => {
                    const sensorObj = sensorObjAll[s] || {};
                    const chObj = getChannelMsg(sensorObj);
                    const { msg, color } = chObj;
                    return (
                        <Fragment key={s}>
                            <MapDeviceIcon
                                x={mapSensorObj[s]?.x * mapScale.x || 0}
                                y={mapSensorObj[s]?.y * mapScale.y || 0}
                                deviceObj={sensorObj}
                                layerProps={layerProps}
                                deviceType="sensor"
                            />
                            <KonvaLabel
                                x={mapSensorObj[s]?.x * mapScale.x || 0}
                                y={(mapSensorObj[s]?.y + (iconSize * 1.05)) * mapScale.y || 0}
                                text={msg}
                                tagColor={color}
                            />
                        </Fragment>
                    )
                })
            }
            {
                mapDaliCtlArray.map(s => {
                    const daliCtlObj = daliCtlObjAll[s] || {};
                    const chObj = getChannelMsg(daliCtlObj);
                    const { msg, color } = chObj;
                    return (
                        <Fragment key={s}>
                            <MapDeviceIcon
                                x={mapDaliCtlObj[s]?.x * mapScale.x || 0}
                                y={mapDaliCtlObj[s]?.y * mapScale.y || 0}
                                deviceObj={daliCtlObj}
                                layerProps={layerProps}
                                deviceType="daliCtl"
                            />
                            <KonvaLabel
                                x={mapDaliCtlObj[s]?.x * mapScale.x || 0}
                                y={(mapDaliCtlObj[s]?.y + (iconSize * 1.05)) * mapScale.y || 0}
                                text={msg}
                                tagColor={color}
                            />
                        </Fragment>
                    )
                })
            }
            {
                mapGatewayArray.map(s => {
                    const gatewayObj = gatewayObjAll[s] || {};
                    const { ttyS2, ttyS3 } = gatewayObj || {};
                    const ttyS2Channel = ttyS2?.meshChannel ?? ttyS2?.dtkChannel ?? "-";
                    const ttyS3Channel = ttyS3?.meshChannel ?? ttyS3?.dtkChannel ?? "-";
                    let ttyS2Type = ttyS2?.ttyS_type || "-";
                    let ttyS3Type = ttyS3?.ttyS_type || "-";
                    if (ttyS2Type === "mesh255") ttyS2Type = "mesh";
                    if (ttyS3Type === "mesh255") ttyS3Type = "mesh";
                    const color = getColorFromNumber(ttyS3Channel);
                    let msg = `S3:${ttyS3Type}:${ttyS3Channel}\nS2:${ttyS2Type}:${ttyS2Channel}`;
                    return (
                        <Fragment key={s}>
                            <MapDeviceIcon
                                x={mapGatewayObj[s]?.x * mapScale.x || 0}
                                y={mapGatewayObj[s]?.y * mapScale.y || 0}
                                deviceObj={gatewayObj}
                                layerProps={layerProps}
                                deviceType="gateway"
                            />
                            <KonvaLabel
                                x={mapGatewayObj[s]?.x * mapScale.x || 0}
                                y={(mapGatewayObj[s]?.y + (iconSize * 1.05)) * mapScale.y || 0}
                                text={msg}
                                tagColor={color}
                            />
                        </Fragment>
                    )
                })
            }
        </>
    )

}
const getChannelMsg = (deviceObj) => {
    const { meshChannel, dtkChannel } = deviceObj;
    let msg = "", msg1 = "", msg2 = "", mesh = false, dtk = false;
    if (meshChannel >= 0 && meshChannel <= 31) {
        mesh = true;
        msg1 += `Mesh:${meshChannel}`;
    }
    if (dtkChannel >= 20 && dtkChannel <= 70) {
        dtk = true;
        msg2 += `DTK:${dtkChannel}`;
    }
    if (mesh && dtk) msg = `${msg1}\n${msg2}`;
    else if (mesh) msg = msg1;
    else if (dtk) msg = msg2;
    const color = getColorFromNumber(Number(meshChannel) ?? Number(dtkChannel));
    return { msg, meshChannel, dtkChannel, color };
}
function getColorFromNumber(number) {
    if (number === undefined) return "#000020";
    if (number === "-") return "#000010";
    // Normalize the number to a range between 0 and 1
    const normalized = Math.min(Math.max(number, 0), 100) / 100;

    // Calculate the hue value (0 to 360 degrees)
    const hue = normalized * 360;

    // Convert HSL to RGB with lower lightness for darker colors
    const hslToRgb = (h, s, l) => {
        const c = (1 - Math.abs(2 * l - 1)) * s;
        const x = c * (1 - Math.abs((h / 60) % 2 - 1));
        const m = l - c / 2;
        let r = 0, g = 0, b = 0;

        if (h >= 0 && h < 60) {
            r = c; g = x; b = 0;
        } else if (h >= 60 && h < 120) {
            r = x; g = c; b = 0;
        } else if (h >= 120 && h < 180) {
            r = 0; g = c; b = x;
        } else if (h >= 180 && h < 240) {
            r = 0; g = x; b = c;
        } else if (h >= 240 && h < 300) {
            r = x; g = 0; b = c;
        } else if (h >= 300 && h < 360) {
            r = c; g = 0; b = x;
        }

        const toHex = (value) => Math.round((value + m) * 255).toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    };

    // Use full saturation and lower lightness for darker colors
    return hslToRgb(hue, 1, 0.3); // Lightness set to 0.3 for darker shades
}
