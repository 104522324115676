import IconButton from "@mui/material/IconButton";
import { ArrowBackIos } from "@mui/icons-material";

export default function IconButtonBack({
  onBtnClick,
  disabled,
  size = "large",
}) {
  const handleAction = () => {
    onBtnClick();
  };

  return (
    <IconButton onClick={() => handleAction()} disabled={disabled} size={size}>
      <ArrowBackIos />
    </IconButton>
  );
}
