import { Divider } from "@mui/material";

/**
 *
 * @param {Object} props
 * @param {number} [props.space]
 * @param {("fullWidth"|"inset"|"middle")} [props.variant]
 * @param {boolean} [props.light]
 * @param {string} [props.width]
 * @returns
 */
export default function Divider10({ space, variant, light, width }) {
  return (
    <Divider
      variant={variant || "fullWidth"}
      light={light}
      sx={{
        margin: `${space || 10}px 0px ${space || 10}px 0px`,
        width: width || "100%",
      }}
    />
  );
}
