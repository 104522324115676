import { useState, useContext } from "react"
import { SMContext } from "context/smContext";
import { Button } from "@mui/material"
import ModalSM from "components/ModalSM";
import ServerMonitorPanel from "./ServerMonitorPanel";

export default function ServerMonitorModal() {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const handleOpen = async () => {
        setOpen(true);

    }
    const handleClose = async () => {
        setOpen(false);

    }

    return (
        <>
            <Button size="small" variant="outlined" onClick={handleOpen}>
                open
            </Button>
            <ModalSM title="Server monitor" open={open} onClose={handleClose} width="80vw" height="80vh">
                <ServerMonitorPanel />
            </ModalSM>
        </>
    )
}