import { useState, useEffect } from "react";
import KonvaFaButton from "containers/map/device/KonvaFaButton";
import { Group } from "react-konva";
import { daliCtlColorDark, gatewayColorDark } from "actions/generalActions";
import { deviceToolbarPos } from "actions/mapActions";
import { getDaliCtlButtonContent } from "actions/toolbarActions";

export default function ToolbarDaliCtl({ layerProps }) {
  const {
    layerRef,
    mapObjAll,
    activeMapID,
    mapScale,
    selectedID,
    selectedDeviceObj,
    dispatch,
    enablePan,
    navigate,
  } = layerProps;
  const [scale, setScale] = useState(1);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const activeMapObj = mapObjAll[activeMapID];
  const { x, y } = activeMapObj?.daliCtlObj[selectedID] || {};
  const btnSize = activeMapObj.buttonSize / scale;
  const btnSpace = btnSize * 1.2;
  const btnContent = getDaliCtlButtonContent(selectedDeviceObj, layerProps, dispatch, btnSize, 4, navigate);
  const pos = deviceToolbarPos(x, y, btnContent.length, btnSpace, layerProps);
  return (
    <>
      <Group x={pos.x} y={pos.y}>
        {btnContent.map((obj, key) => (
          <KonvaFaButton
            key={key}
            x={btnSpace * key}
            y={0}
            mapScale={mapScale}
            enablePan={enablePan}
            stageScale={scale}
            btnSize={btnSize}
            onBtnClick={obj.onClick}
            iconSvg={obj.icon}
            tooltip={obj.tooltip}
            stroke={obj.stroke}
            tagColor={gatewayColorDark}
          />
        ))}
      </Group>
    </>
  );
}
