import { useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import Divider10 from "components/Divider10";
import DaliSlavesControlPanel from "./DaliSlavesControlPanel";
import DaliCtlSuperAdminPanel from "./DaliCtlSuperAdminPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import { gatewayConnected, removeDuplicates } from "actions/generalActions";
import DaliCtlDataLogPanel from "./DaliCtlDataLogPanel";
import DaliCtlInfoPanel from "./DaliCtlInfoPanel";
import DaliCtlStatusPanel from "./DaliCtlStatusPanel";
import DaliCtlIcon from "components/DaliCtlIcon";
import { deviceErrorMsg } from "actions/generalDeviceActions";
import { queryDaliCtlOneSetting, updateDaliCtlProperty } from "actions/daliCtlActions";
import { useNavigate } from "react-router-dom";
import { useSocketListenToWeb } from "hooks/socketHooks";
import IconButtonBack from "components/IconButtonBack";
import { Paper, Typography } from "@mui/material";
import ThemeContainer from "components/ThemeContainer";
import DivInline from "components/DivInline";
const boxH = "88vh";
const boxW = ["36vw", "36vw", "26vw"];
const boxSx = {
    padding: "0.5vw",
    margin: "1vh 0.5vw 1vh 0.5vw",
    border: "0.5px solid grey",
    borderRadius: "1vw",
    overflowY: "auto",
    height: boxH,
};

export default function DaliCtlDetailPage() {
    const [state, dispatch] = useContext(SMContext);
    const navigate = useNavigate();
    useSocketListenToWeb(navigate);
    const {
        daliCtlObjAll,
        activeDaliCtlID,
        gatewayObjAll,
    } = state;
    const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
    const { description, gatewayID, mapID } = daliCtlObj || {};
    const handleClose = () => {
        dispatch({ type: "CLOSED_DALICTL_DETAIL_MODAL" });
        navigate("/MapPage");
    };
    const gwConnected = gatewayConnected(gatewayObjAll[gatewayID]);
    useEffect(() => {
        const errorMsgObj = deviceErrorMsg(daliCtlObj, "daliCtl", state);
        if (errorMsgObj[1]) queryDaliCtlOneSetting(daliCtlObj, 0x0ba2);
        const newMapID = removeDuplicates(mapID);
        if (newMapID?.length !== mapID?.length) {
            updateDaliCtlProperty(activeDaliCtlID, { mapID: newMapID }, false);
        }
    }, [])
    return (
        <ThemeContainer background="dark">
            <Paper>
                <DivInline>
                    <IconButtonBack onBtnClick={handleClose} />
                    <DeviceMongoModal deviceObj={daliCtlObj}>
                        <DaliCtlIcon daliCtlObj={daliCtlObj} gatewayConnected={gwConnected} width={3} sizeUnit="vw" />
                    </DeviceMongoModal>
                    <Typography sx={{ marginLeft: "1vw" }}>{`${description} [${activeDaliCtlID}]`}</Typography>
                </DivInline>
                <DivInline>
                    <div style={{ ...boxSx, width: boxW[0] }}>
                        <DaliCtlInfoPanel />
                        <Divider10 />
                        <DaliCtlSuperAdminPanel handleClose={handleClose} />
                    </div>
                    <div style={{ ...boxSx, width: boxW[1] }}>
                        <DaliCtlStatusPanel />
                        <DaliCtlDataLogPanel />
                    </div>
                    <div style={{ ...boxSx, width: boxW[2] }}>
                        <DaliSlavesControlPanel />
                    </div>
                </DivInline>
            </Paper>
        </ThemeContainer>
    )
}