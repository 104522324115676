import { Group, Path } from "react-konva";
export default function DataUpload({
  x,
  y,
  size,
  backgroundColor = "",
  color = "#ff2a2a",
}) {
  const scale = size / 330;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill={color}
        fillOpacity={1}
        data="m79.455 149s-47.838-40.469-65.666-65.819c-5.1887-7.378-10.14-15.557-11.565-24.464-1.1329-7.0821 0.3729-14.555 2.6688-21.35 2.4772-7.3314 6.2251-14.576 11.683-20.062 6.1577-6.1895 14.102-11.157 22.566-13.298 7.7672-1.9646 16.136-0.53991 24.019 0.88959 5.0746 0.92021 10.25 2.2481 14.678 4.8927 2.9716 1.7749 5.0802 4.7035 7.5615 7.1167 21.348 20.761 63.014 63.324 63.014 63.324s39.846-46.939 64.642-64.659c6.5816-4.7036 13.914-8.8487 21.795-10.675 7.7996-1.8075 16.17-1.5791 24.019 0 4.8861 0.98303 9.6507 3.0043 13.789 5.7823 2.7854 1.87 4.7445 4.7445 7.1167 7.1167 2.0757 2.0757 4.5107 3.8457 6.2271 6.2271 2.9077 4.0343 5.3236 8.5572 6.6719 13.344 1.4122 5.0132 1.4438 10.361 1.3344 15.568-0.12243 5.8284-0.31306 11.839-2.224 17.347-1.1335 3.267-3.408 6.0262-5.3375 8.8959-1.241 1.8456-2.5404 3.6623-4.0031 5.3375-20.129 23.053-65.354 64.486-65.354 64.486s45.083 41.395 64.465 64.949c3.5275 4.2868 7.1972 8.6676 9.3407 13.789 2.3352 5.579 3.3713 11.747 3.5584 17.792 0.10182 3.2904-0.58824 6.5791-1.3344 9.7855-1.0627 4.5665-2.5031 9.0777-4.4479 13.344-1.5716 3.4474-3.2424 6.9742-5.7823 9.7855-2.3458 2.5964-5.5152 4.3233-8.4511 6.2271-4.2895 2.7816-8.5246 5.8549-13.344 7.5615-4.2506 1.5053-8.8366 2.0881-13.344 2.224-5.6626 0.1707-11.43-0.31427-16.902-1.7792-2.8212-0.75517-5.4091-2.2228-8.0063-3.5584-2.6005-1.3373-5.2478-2.6597-7.5615-4.4479-24.965-19.295-67.009-66.855-67.009-66.855s-39.871 45.108-63.316 63.741c-2.8668 2.2783-6.1572 3.9726-9.3407 5.7823-3.632 2.0647-7.0857 4.6962-11.12 5.7823-6.1628 1.6592-12.782 1.5822-19.126 0.88959-5.649-0.61673-11.292-2.0786-16.457-4.4479-4.5601-2.0918-8.9393-4.8713-12.454-8.4511-2.8523-2.905-4.6773-6.6811-6.6719-10.23-1.8519-3.2953-3.7554-6.6254-4.8927-10.23-1.3568-4.3003-2.1505-8.8351-2.224-13.344-0.07313-4.4867 0.79214-8.9664 1.7792-13.344 0.95916-4.2538 1.9127-8.6274 4.0031-12.454 1.5653-2.8655 4.0339-5.1426 6.2271-7.5615 20.538-22.652 64.777-64.95 64.777-64.95"
      />
    </Group>
  );
}
