import { useContext, useState, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, Select, MenuItem } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";

import {
    queryOneSensorSetting,
    sensorChangeDimStepSize,
} from "actions/sensorActions";
import General from "@ecoenghk/general";
import IconButtonRefresh from "components/IconButtonRefresh";
const gs = new General();
export default function SensorChangeDimStepPanel() {
    const [state] = useContext(SMContext);

    const { activeSensorID, sensorObjAll } = state;
    const sensorObj = sensorObjAll[activeSensorID];

    return (
        <div style={{ paddingBottom: "2vh" }}>
            <Divider10 />
            <DivInline justifyContent="center">
                <Typography variant="h6" display="block" align="center">
                    Dimming step
                </Typography>
            </DivInline>
            <Divider10 space={5} />

            <DivInline justifyContent="center">
                <Typography>Brighten /  Dim</Typography>
                <Select
                    sx={{ width: "5vw", margin: "0 1vw" }}
                    value={Number(sensorObj.dimStepSize) || "-"}
                    onChange={async (evt) => {
                        await sensorChangeDimStepSize(sensorObj, Number(evt.target.value));
                    }}
                    variant="outlined"
                >
                    <MenuItem value={"-"}>-</MenuItem>
                    <MenuItem value={2}>2%</MenuItem>
                    <MenuItem value={5}>5%</MenuItem>
                    <MenuItem value={8}>8%</MenuItem>
                    <MenuItem value={10}>10%</MenuItem>
                    <MenuItem value={15}>15%</MenuItem>
                    <MenuItem value={20}>20%</MenuItem>
                </Select>

                <Typography>on each press</Typography>
            </DivInline>

        </div>
    );
}

