import { Circle, Group, Path, Ellipse } from "react-konva";

export default function Lightswitch({
  x,
  y,
  size,
  status,
  status2,
  status3,
  status4,
  gang,
  connectStatus,
  shadEnabled,
  background,
  disabledConnectStatus,
  disabledSensor,
}) {
  const scale = size / 79.375;
  const onColor = "#D35400";
  const offColor = "#3498DB";
  const btnColor = (s) => { return (s === 1 ? onColor : offColor); }
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill={background || "white"}
        stroke="#6c5d53"
        strokeWidth={2.6}
        data="M16.135 8.792l55.972.089c3.558.53 5.2 2.797 5.448 6.327v56.637c-.434 2.847-2.235 4.687-5.145 5.71l-57.214-.112c-3.661-.499-4.74-2.95-5.167-5.896L9.962 14c1.005-2.771 2.48-5.08 6.173-5.208z"
      />
      {/* <Path
        fill={background || "white"}
        stroke="#676c53"
        strokeWidth={3.5}
        data="M2.849 8.11C3.869 5.318 5.4 2.986 9.424 2.895l61.332-.046c2.86.317 5.222 1.47 5.554 6.023l.217 62.394c-.628 1.973-.023 4.223-4.832 5.262H8.485c-3.134-.641-5.234-2.228-5.368-5.532z"
        shadowEnabled={shadEnabled || false}
        shadowColoradius="black"
        shadowBluradius={4}
        shadowOffset={{ x: 8, y: 8 }}
        shadowOpacity={0.3}
        perfectDrawEnabled={false}
      /> */}
      {gang <= 3 && (
        <>
          <Circle
            x={gang === 1 ? 44 : 23}
            y={42}
            fill="#b3b3b3"
            fillOpacity={0.996}
            stroke={btnColor(status)}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
          />
          {gang > 1 && (
            <Circle
              x={gang === 2 ? 64 : 44}
              y={42}
              fill="#b3b3b3"
              fillOpacity={0.996}
              stroke={btnColor(status2)}
              strokeLinecap="round"
              strokeWidth={3.5}
              radius={8.58}
            />
          )}
          {gang === 3 && (
            <Circle
              x={64}
              y={42}
              fill="#b3b3b3"
              fillOpacity={0.996}
              stroke={btnColor(status3)}
              strokeLinecap="round"
              strokeWidth={3.5}
              radius={8.58}
            />
          )}
        </>
      )}
      {gang === 4 && (
        <>
          <Circle
            x={28}
            y={28}
            fill="#b3b3b3"
            fillOpacity={0.996}

            stroke={btnColor(status)}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
          />
          <Circle
            x={59}
            y={28}
            fill="#b3b3b3"
            fillOpacity={0.996}
            stroke={btnColor(status2)}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
          />
          <Circle
            x={28}
            y={59}
            fill="#b3b3b3"
            fillOpacity={0.996}
            stroke={btnColor(status3)}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
          />
          <Circle
            x={59}
            y={59}
            fill="#b3b3b3"
            fillOpacity={0.996}
            stroke={btnColor(status4)}
            strokeLinecap="round"
            strokeWidth={3.5}
            radius={8.58}
          />
        </>
      )}
      {/* <Ellipse
        x={gang === 1 ? 39.688 : 19.198}
        y={39.688}
        fill="#b3b3b3"
        stroke={status ? offColor : onColor}
        strokeWidth={3.5}
        radiusX={8.58}
        radiusY={8.58}
        perfectDrawEnabled={false}
      />
      {gang > 1 && (
        <Ellipse
          x={gang == 2 ? 60.177 : 39.688}
          y={39.688}
          fill="#b3b3b3"
          stroke={status2 ? offColor : onColor}
          strokeWidth={3.5}
          radiusX={8.58}
          radiusY={8.58}
          perfectDrawEnabled={false}
        />
      )}
      {gang === 3 && (
        <Ellipse
          x={62.177}
          y={39.688}
          fill="#b3b3b3"
          stroke={status3 ? offColor : onColor}
          strokeWidth={3.5}
          radiusX={8.58}
          radiusY={8.58}
          perfectDrawEnabled={false}
        />
      )} */}

      {!disabledConnectStatus ? (
        <Circle x={-10} radius={7} fill={connectColor} perfectDrawEnabled={false} />
      ) : (
        <></>
      )}
    </Group>
  );
}
