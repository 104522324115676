import React from "react";

export default function DimController({
    width,
    height,
    sizeUnit,
    gang = 2,
    status,
    status2,
    disabledConnectStatus,
    connectStatus,
    disabledSensor
}) {
    const w = width || height || 20;
    const h = height || width || 20;
    const unit = sizeUnit || "px";
    const upBtnX = gang === 3 ? 0 : -23;
    const downBtnX = gang === 3 ? 0 : 35;
    let connectColor = "red";
    if (connectStatus && !disabledSensor) {
        connectColor = "green";
    } else if (connectStatus && disabledSensor) {
        connectColor = "purple";
    }
    return (
        <svg
            viewBox="0 0 79.375 79.375"
            width={w + unit}
            height={h + unit}
        >

            <path
                fill="#fff"
                stroke="#676c53"
                strokeWidth="3.5"
                d="M2.849 8.11C3.869 5.317 5.4 2.986 9.424 2.894l61.332-.046c2.86.317 5.222 1.47 5.554 6.023l.216 62.393c-.628 1.974-.022 4.224-4.831 5.263H8.485c-3.134-.641-5.234-2.229-5.368-5.533z"
            ></path>
            {!disabledConnectStatus && (
                <circle r="6" cx="11" cy="11" fill={connectColor} />
            )}
            {
                gang === 3 && (
                    <ellipse
                        cx="62.177"
                        cy="39.688"
                        stroke="#0f0"
                        strokeWidth="4.007"
                        fill="#b3b3b3"
                        rx="8.58"
                        ry="8.58"
                    ></ellipse>
                )
            }
            <g transform={`translate(${upBtnX} -10) scale(1.2 1.2)`}>
                <path
                    stroke={status === 1 ? "#d40000" : "#2E86C1"}
                    strokeWidth="11.339"
                    fill="#b3b3b3"
                    d="M108.37 231.38l-33.499 60.344-35.51-59.184z"
                    transform="matrix(.11286 .20228 -.20617 .11519 83.267 -1.831) "

                ></path>
            </g>
            <g transform={`translate(${downBtnX} -10) scale(1.2 1.2)`}  >
                <path

                    stroke={status2 === 1 ? "#d40000" : "#2E86C1"}
                    strokeWidth="11.339"
                    fill="#b3b3b3"
                    d="M108.37 231.38l-33.499 60.344-35.51-59.184z"
                    transform="matrix(.23161 .00355 -.00348 .23614 .74 -22.497)"
                ></path>
            </g>


        </svg>
    );
}
