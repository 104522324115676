import { useContext } from "react";
import { SMContext } from "context/smContext";
import DivExist from "components/DivExist";
import { mapRightStr } from "asset/string/string";
import LightStatisticInfo from "./mapRight/LightStatisticInfo";
import SelectedLightInfo from "./mapRight/SelectedLightInfo";
import SelectedGatewayInfo from "./mapRight/SelectedGatewayInfo";
import SelectedServerInfo from "./mapRight/SelectedServerInfo";
import SelectedSensorInfo from "./mapRight/SelectedSensorInfo";
import SelectedZcInfo from "./mapRight/SelectedZcInfo";
import SelectedDaliCtlInfo from "./mapRight/SelectedDaliCtlInfo";
import ShiftSelectedDeviceControl from "./mapRight/ShiftSelectedDeviceControl";
import EspWebInfo from "./mapRight/EspWebInfo";
import Rssi255WebInfo from "./mapRight/Rssi255WebInfo";

export default function MapContainerRight({ vw, vh }) {
  const [state, dispatch] = useContext(SMContext);
  const { selectedDeviceType, mapLayer, shiftSelectedArray } =
    state;
  return (
    <div
      style={{
        display: "flex",
        width: `${vw}vw`,
        height: `${vh}vh`,
        // backgroundColor: "green",
        flexDirection: "column",
        alignItems: "center",
        overflowY: "auto",
      }}
    >
      <DivExist
        show={
          !selectedDeviceType &&
          mapLayer.normal &&
          shiftSelectedArray.length === 0
        }
      >
        <LightStatisticInfo />
      </DivExist>
      <DivExist show={selectedDeviceType === mapRightStr.light}>
        <SelectedLightInfo />
      </DivExist>

      <DivExist show={selectedDeviceType === mapRightStr.gateway}>
        <SelectedGatewayInfo />
      </DivExist>
      <DivExist show={selectedDeviceType === mapRightStr.sensor}>
        <SelectedSensorInfo />
      </DivExist>
      <DivExist show={selectedDeviceType === mapRightStr.daliCtl}>
        <SelectedDaliCtlInfo />
      </DivExist>
      <DivExist show={selectedDeviceType === mapRightStr.zoneControl}>
        <SelectedZcInfo />
      </DivExist>
      <DivExist show={selectedDeviceType === mapRightStr.server}>
        <SelectedServerInfo />
      </DivExist>

      <DivExist show={shiftSelectedArray.length > 0 && mapLayer.normal}>
        <ShiftSelectedDeviceControl />
      </DivExist>
      <DivExist show={mapLayer.espWeb}>
        <EspWebInfo />
      </DivExist>
      <DivExist show={mapLayer.rssi255Web}>
        <Rssi255WebInfo />
      </DivExist>
    </div>
  );
}
