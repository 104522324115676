import { Paper } from "@mui/material";
import ThemeContainer from "components/ThemeContainer";

export default function BackgroundPaper({
  children,
  darkLight,
  backgroundColor,
  style,
}) {
  return (
    <ThemeContainer background={darkLight || "dark"}>
      <Paper style={{ backgroundColor: backgroundColor, ...style }}>
        {children}
      </Paper>
    </ThemeContainer>
  );
}
