import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Box } from "@mui/material";
import { Lock, ArrowForward } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import SensorIcon from "components/SensorIcon";
import ChangeLockLvModal from "components/ChangeLockLvModal";
import DivSpaceBetween from "components/DivSpaceBetween";
import { saveSensorLockLevel } from "actions/sensorActions";
import General from "@ecoenghk/general";
const gs = new General();

export default function ControlDevicesDaylightMotionPanel({ show }) {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeSensorID,
    sensorObjAll,
    lightObjAll,
    activeMapID,
    mapObjAll,
    socket,
  } = state;
  const sensorObj = sensorObjAll[activeSensorID];
  const activeMapObj = mapObjAll[activeMapID] || {};
  const { lockLevelSet1, setting1, releaseOnDelayEnd } = sensorObj || {};
  const lightArrayAll = Object.keys(setting1 || {});
  const lightArray = lightArrayAll.filter((s) =>
    Object.keys(activeMapObj.lightObj || {}).includes(s)
  );
  const lightArrayNotInMap = lightArrayAll.filter(
    (s) => !Object.keys(activeMapObj.lightObj || {}).includes(s)
  );

  return (
    <DivExist show={show}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <DivInline justifyContent="center">
          <Typography variant="h6" align="center">
            Control Devices
          </Typography>
        </DivInline>
        <Divider10 />
        <DivSpaceBetween>
          <Typography>
            <Lock />
            Priority Level
          </Typography>
          <ChangeLockLvModal
            existingLv={lockLevelSet1}
            onChange={async (newLv) => {
              const noArr = gs.newArrayBetween(1, 11);
              const lvArr = noArr.map((n) => newLv);

              await saveSensorLockLevel(
                activeSensorID,
                sensorObj,
                noArr,
                lvArr,
                releaseOnDelayEnd
              );
            }}
          />
        </DivSpaceBetween>
        <Divider10 />
        {lightArray
          .sort((a, b) =>
            lightObjAll[a]?.description?.localeCompare(
              lightObjAll[b]?.description
            )
          )
          .map((s) => {
            const lightStyle = lightObjAll[s]?.style;
            const serialSetting1 = sensorObj.setting1?.[s];
            return (
              <React.Fragment key={s}>
                <div>
                  <DivInline>
                    <LightIcon
                      statusA={0}
                      lightStyle={lightStyle}
                      lightLv={100}
                      width={2.5}
                      sizeUnit="vw"
                      disabledConnectStatus
                    />
                    <Typography>
                      {lightObjAll[s]?.description}[{s}]
                    </Typography>
                  </DivInline>
                  <DivInline>
                    <SensorIcon
                      type="motion"
                      status={1}
                      width={3}
                      sizeUnit="vw"
                      disabledConnectStatus
                    />
                    <Typography sx={{ marginLeft: "0.5vw" }}>
                      Motion ON
                    </Typography>
                  </DivInline>
                  <DivInline>
                    {gs.newArrayBetween(1, 11).map((n) => {
                      const setting = sensorObj[`setting${n}`];
                      const lightSetting = setting?.[s];
                      const { onoffcontrolA, pwm, lockLevel } =
                        lightSetting || {};
                      if (lightSetting) {
                        return (
                          <Box sx={{ width: "3.1vw" }} key={n}>
                            <SensorIcon
                              type="daylight"
                              luxLv={n - 1}
                              disabledConnectStatus
                            />
                            <Typography align="center" variant="caption">
                              {onoffcontrolA === 1 ? "off" : `${pwm}%`}
                            </Typography>
                          </Box>
                        );
                      } else {
                        return <React.Fragment key={n}></React.Fragment>;
                      }
                    })}
                  </DivInline>
                  <Divider10 space={5} />
                  <DivInline>
                    <SensorIcon
                      type="motion"
                      status={0}
                      width={3}
                      sizeUnit="vw"
                      disabledConnectStatus
                    />
                    <Typography sx={{ marginLeft: "0.5vw" }}>
                      Motion OFF
                    </Typography>
                  </DivInline>
                  <DivInline>
                    <Typography variant="caption" sx={{ marginRight: "1vw" }}>
                      Any light level
                    </Typography>
                    <Typography align="center" variant="caption">
                      {serialSetting1?.delayedOnOff === 1
                        ? "off"
                        : `${serialSetting1?.delayedPwm}%`}
                    </Typography>
                  </DivInline>
                </div>
              </React.Fragment>
            );
          })}
        <Divider10 />

        <DivExist show={lightArrayNotInMap.length > 0}>
          <Divider10 />
          <Typography>Connected lights not in this map:</Typography>
          {lightArrayNotInMap.map((s) => (
            <Typography variant="caption" key={s} display="block">
              {s}
            </Typography>
          ))}
        </DivExist>
      </div>
    </DivExist>
  );
}
