import { Group, Path } from "react-konva";

export default function ChargeIcon({ statusL, x, y }) {
  return (
    <Group x={x} y={y} scaleX={1.2} scaleY={1.2} listening={false}>
      <Path
        fill={statusL === 1 ? "red" : "green"}
        fillOpacity={0.996}
        strokeWidth={5.373}
        data="M0.773 6.281H15.802999999999999V36.798H0.773z"
        perfectDrawEnabled={false}
      />
      <Path
        fill="#917c6f"
        fillOpacity={0.996}
        strokeLinecap="round"
        strokeWidth={4.139}
        data="M4.113 3.24H12.463000000000001V6.081H4.113z"
        perfectDrawEnabled={false}
      ></Path>
      <Path
        fill="#fff"
        fillOpacity={0.996}
        strokeLinecap="round"
        strokeWidth={5.373}
        data="M1.916 7.246H14.404V10.41H1.916z"
        perfectDrawEnabled={false}
      ></Path>
      <Path
        fill="#fff"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={0.384}
        data="M9.479 14.579l-1.084 6.43 3.157-.817-4.377 9.466.353-6.937-3.463 1.654c1.822-3.256 3.616-6.527 5.414-9.796z"
        perfectDrawEnabled={false}
      ></Path>
      {statusL === 1 && (
        <>
          <Path
            fill="red"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity={1}
            strokeWidth={0.635}
            data="M20.238 29.097V14.45l6.814 7.061z"
            perfectDrawEnabled={false}
          />
          {/* <Path
            fill="red"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth="0.635"
            data="M30.766 28.255V13.61l6.814 7.06z"
          ></Path> */}
        </>
      )}
    </Group>
  );
}
