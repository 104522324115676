export default function GearChecking({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <g>
        <path
          fill="gray"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.265"
          d="M29.09 25.976l2.525 2.303.594-.817 15.376 13-3.491 3.713-15.302-12.999.669-.594-2.452-2.154z"
        ></path>
        <circle
          cx="16.985"
          cy="17.877"
          r="13.045"
          fill="none"
          fillOpacity="1"
          stroke="#8e4399"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="3.11"
        ></circle>
        <path
          fill="#00aac5"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.265"
          d="M5.453 28.224l-2.487.741-.087 3.316 4.275 1.003c-.049 1.116.788 2.168 1.134 2.835l-2.137 3.36 2.006 2.268 3.883-2.007c1.221.814 2.132 1.007 3.01 1.134l.916 3.926h3.359l.96-3.882c1.118-.263 2.192-.57 2.966-1.178l3.577 1.963 2.268-2.225-2.05-3.446c.542-.902.998-1.934 1.309-3.184l-1.047-.96.567-.785-1.222-1.178c-1.564 1.206-3.309 2.194-5.453 2.705-.928 1.066-2.053 1.739-3.446 1.875-1.499-.058-2.614-.655-3.533-1.526-3.35-.244-6.233-1.954-8.768-4.755z"
        ></path>
        <path
          fill="#00aac5"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.265"
          d="M8.42 19.368l-1.615 1.483c.148 1.686 1.484 3.272 3.01 4.843 1.459 1.303 3.02 2.142 4.711 2.399.806-.698 1.684-1.275 2.836-1.396 1.134.058 2.094.407 2.879 1.047 2.08-.59 3.662-1.752 4.93-3.272.909-1.411 1.876-2.788 2.224-4.537l-.567-.523c-1.442.731-2.862 1.467-3.62 2.312-.792-.6-1.503-1.268-2.967-1.309l-1.047-3.97h-3.272l-.916 4.058c-1.111-.038-1.944.622-2.835 1.134z"
        ></path>
        <path
          fill="#37c8ab"
          fillOpacity="1"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.265"
          d="M27.186 16.046l-1.238.504.138 2.063 1.348.402z"
        ></path>
        <path
          fill="#37c8ab"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity={1}
          strokeWidth={0.265}
          d="M30.07 9.917c1.474 2.602 2.485 5.29 2.513 8.134-.075 2.13-.407 4.285-1.69 6.535l1.507-.96c.67.657 1.34.73 2.011.868l.64 2.651 2.24.046.73-2.697a4.174 4.174 0 001.965-.868l2.422 1.508 1.645-1.554-1.462-2.422c.433-.64.602-1.28.731-1.919l2.88-.731-.092-2.102-2.605-.64c-.24-.754-.493-1.5-.96-2.102l1.417-2.24-1.6-1.507-2.376 1.37c-.646-.401-1.29-.823-2.01-.776l-.732-2.605h-2.239l-.548 2.376c-.996.249-1.513.593-2.148.914z"
        ></path>
        <ellipse
          cx={36.256}
          cy={17.403}
          fill="#fff"
          fillOpacity="1"
          stroke="none"
          strokeDasharray="none"
          strokeMiterlimit="4"
          strokeWidth={5.028}
          rx={2.8}
          ry={2.8}
        ></ellipse>
      </g>
    </svg>
  );
}
