import { Circle, Group, Path } from "react-konva";

export default function CopyIcon({ x, y, size, shadEnabled }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill="#fff"
        stroke="teal"
        strokeWidth={6}
        data="M8.378 22.234l-.249 35.357 43.076.249V3.809H27.302z"
      ></Path>
      <Path
        fill="transparent"
        stroke="teal"
        strokeWidth={6}
        data="M27.301 3.808V22.98l-18.55.093"
      ></Path>
      <Path
        fill="#fff"
        stroke="teal"
        strokeWidth={6}
        data="M26.045 38.058l-.249 35.357 43.076.249V19.633H44.969z"
      ></Path>
      <Path
        stroke="teal"
        fill="transparent"
        strokeWidth={6}
        data="M44.968 19.632v19.172l-18.55.093"
      ></Path>
    </Group>
  );
}
