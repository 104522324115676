import { Circle, Group, Path } from "react-konva";

export default function ControlPointDuplicate({ x, y, size }) {
    const scale = size / 79.375;
    return (
        <Group x={x} y={y} scaleY={scale} scaleX={scale}>
            <Group
                strokeLinecap="round"

            >
                <Circle x={48} y={38.502} radius={28} stroke="#d45500" strokeWidth={6}></Circle>
                <Path
                    stroke="#d45500"
                    data="M 23.789394,66.389154 A 30,30 0 0 1 4.0499992,38.198376 30,30 0 0 1 23.789394,10.007597"
                    strokeWidth={6}
                    lineCap="round"
                ></Path>
                <Path
                    stroke="teal"
                    lineCap="round"
                    data="M48.354 20l 0 38"
                    strokeWidth={8}
                ></Path>
                <Path
                    stroke="teal"
                    lineCap="round"
                    strokeWidth={8}
                    data="M29 38.047l38 0"
                ></Path>
            </Group>
        </Group>
    );
}
