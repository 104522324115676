import { ArrowForwardIos } from '@mui/icons-material';
import ModalSM from 'components/ModalSM';
import SpaceBetweenButton from 'components/SpaceBetweenButton';
import React, { useState, useContext, useMemo } from 'react';
import { SMContext } from "context/smContext";
import IconButtonRefresh from 'components/IconButtonRefresh';
import General from '@ecoenghk/general';
import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Divider10 from 'components/Divider10';
import { queryEspNowRoute } from 'actions/gatewayActions';
const gs = new General();

export default function EspNowRouteModal({ gatewayObj, btnContent }) {
    const [state, dispatch] = useContext(SMContext);
    const { gatewayObjAll, lightObjAll, sensorObjAll, daliCtlObjAll } = state;
    const { gatewayID, espNowRoute } = gatewayObj;
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const data = useMemo(() => {
        let arr = Object.keys(espNowRoute || {}).map(macAdd => {
            let obj = espNowRoute[macAdd]["1"] || {};
            obj.wifiApMacAddress = macAdd;
            const lightObjArr = Object.keys(lightObjAll || {}).map(s => lightObjAll[s]);
            const sensorObjArr = Object.keys(sensorObjAll || {}).map(s => sensorObjAll[s]);
            const gatewayObjArr = Object.keys(gatewayObjAll || {}).map(s => gatewayObjAll[s]);
            const daliCtlObjArr = Object.keys(daliCtlObjAll || {}).map(s => daliCtlObjAll[s]);
            const deviceObjArr = [...lightObjArr, ...sensorObjArr, ...gatewayObjArr, ...daliCtlObjArr];
            let deviceObj = deviceObjArr.find(d => d.wifiApMacAddress === macAdd || d.ttyS7?.macAddress === macAdd);
            obj = { ...obj, deviceObj: deviceObj || {} };
            if (deviceObj) {
                if (deviceObj.serial) {
                    obj = { ...obj, deviceID: deviceObj.serial, deviceType: "light" };
                }
                else if (deviceObj.sensorID) {
                    obj = { ...obj, deviceID: deviceObj.sensorID, deviceType: "sensor" };
                } else if (deviceObj.gatewayID && deviceObj.localserverUrl && deviceObj.gwVersion) {
                    obj = { ...obj, deviceID: deviceObj.gatewayID, deviceType: "gateway" };
                } else if (deviceObj.daliCtlID && deviceObj.daliType && !deviceObj.serial && !deviceObj.sensorID) {
                    obj = { ...obj, deviceID: deviceObj.daliCtlID, deviceType: "daliCtl" };
                } else {
                    obj = { ...obj, deviceID: "unknown", deviceType: "unknown" };
                }
            } else {
                obj = { ...obj, deviceID: "not in map/no mac info", deviceType: "unknown" };
            }
            return obj;
        })
        return arr;
    }, [espNowRoute, open]);
    return (
        <>
            {
                btnContent ?
                    <Button variant='outlined' onClick={handleOpen}>{btnContent}</Button>
                    :
                    <SpaceBetweenButton title="EspNow Route Table" btnContent={<ArrowForwardIos />} onBtnClick={handleOpen} />
            }

            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle={`EspNow Route Table ${gatewayID}`}
                width="95vw"
                height="95vh"
                disableBottomClose
                titleComponent={
                    <IconButtonRefresh onBtnClick={() => queryEspNowRoute(gatewayID)} />
                }
            >
                <Divider10 />
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell >{'macAddress'}</TableCell>
                            <TableCell>{'deviceID'}</TableCell>
                            <TableCell>{'deviceType'}</TableCell>
                            <TableCell>{'distance'}</TableCell>
                            <TableCell>{'path'}</TableCell>
                            <TableCell>{'length'}</TableCell>
                        </TableRow>
                        {
                            (data || []).map((d, i) => {
                                // let chunks = [];
                                // if (d.path?.length > 12) {
                                //     for (let i = 0; i < d.path?.length; i += 12) {
                                //         chunks.push(d.path.slice(i, i + 12));
                                //     }
                                // }
                                return (
                                    <TableRow key={i}>
                                        <TableCell onClick={() => console.log(d.deviceObj)}>{d.wifiApMacAddress}</TableCell>
                                        <TableCell>{d.deviceID}</TableCell>
                                        <TableCell>{d.deviceType}</TableCell>
                                        <TableCell>{d.distance}</TableCell>
                                        {/* <TableCell>{(chunks || []).join(", ")}</TableCell> */}
                                        <TableCell>{d.path}</TableCell>
                                        <TableCell>{d.length}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </ModalSM>
        </>
    )
}

