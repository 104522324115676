import { useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import ZoneControlIcon from "components/ZoneControlIcon";
import ZcInfoPanel from "./ZcInfoPanel";
import ControlDevicesZcTimerPanel from "./ControlDevicesZcTimerPanel";
import ZcPanelTimeSetting from "./ZcPanelTimeSetting";
import DivExist from "components/DivExist";
import ZcDetailSceneBtnDelayPanel from "./ZcDetailSceneBtnDelayPanel";
import ControlDevicesZcSceneButtonPanel from "./ControlDevicesZcSceneButtonPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import ZcSuperAdminPanel from "./ZcSuperAdminPanel";
import Divider10 from "components/Divider10";
import { zoneCtlDefault } from "asset/string/string";
import ControlDevicesZcSwitchPanel from "./ControlDevicesZcSwitchPanel";
import ZcDetailSceneBtnControlPanel from "./ZcDetailSceneBtnControlPanel";
import { useNavigate } from "react-router-dom";
import { useSocketListenToWeb } from "hooks/socketHooks";
import IconButtonBack from "components/IconButtonBack";
import { Paper, Typography } from "@mui/material";
import ThemeContainer from "components/ThemeContainer";
const boxH = "88vh";
const boxW = ["28vw", "40vw", "32vw"];
const boxSx = {
    padding: "0.5vw",
    margin: "1vh 0.5vw 1vh 0.5vw",
    border: "0.5px solid grey",
    borderRadius: "1vw",
    overflowY: "auto",
    height: boxH,
};
export default function ZoneControlDetailPage() {
    const [state, dispatch] = useContext(SMContext);
    const navigate = useNavigate();
    useSocketListenToWeb(navigate);
    const { activeZoneControlID, zoneControlObjAll } = state;
    const zcObj = zoneControlObjAll[activeZoneControlID];
    const { zoneControlName, type } = zcObj || {};
    const handleClose = () => {
        dispatch({ type: "CLOSED_ZC_DETAIL_MODAL" });
        navigate("/MapPage");
    };
    const typeName = zoneCtlDefault[type]?.typeName || "";
    return (
        <ThemeContainer background="dark">
            <Paper>
                <DivInline>
                    <IconButtonBack onBtnClick={handleClose} />
                    <DeviceMongoModal deviceObj={zcObj}>
                        <ZoneControlIcon
                            width={3}
                            sizeUnit="vw"
                            type={type}
                            plateColor={zcObj?.plateColor}
                            status={zcObj?.activeSetting === 2 ? 1 : 0}
                            tooltip={activeZoneControlID}
                        />
                    </DeviceMongoModal>
                    <Typography sx={{ marginLeft: "1vw" }}>{typeName} - {zoneControlName}</Typography>
                </DivInline>
                <DivInline>
                    <div style={{ ...boxSx, width: boxW[0] }}>
                        <ZcInfoPanel />
                        <Divider10 />
                        <ZcSuperAdminPanel />
                    </div>
                    <div style={{ ...boxSx, width: boxW[1] }}>
                        <DivExist show={type === "zcSceneButton"}>
                            <ZcDetailSceneBtnDelayPanel />
                            <ZcDetailSceneBtnControlPanel />
                        </DivExist>
                        {type === "timer" && <ZcPanelTimeSetting />}
                    </div>
                    <div style={{ ...boxSx, width: boxW[2] }}>
                        <DivExist show={type === "timer"}>
                            <ControlDevicesZcTimerPanel />
                        </DivExist>
                        {type === "zcSceneButton" && <ControlDevicesZcSceneButtonPanel />}
                        <DivExist show={type === "zcSwitch"}>
                            <ControlDevicesZcSwitchPanel />
                        </DivExist>
                    </div>
                </DivInline>
            </Paper>
        </ThemeContainer>
    )
}
