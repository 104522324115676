import { Group, Path, Ellipse, Circle } from "react-konva";

export default function DimController({
    x,
    y,
    size,
    gang = 2,
    status,
    status2,
    status3,
    connectStatus,
    disabledConnectStatus,
    disabledSensor
}) {
    const scale = size / 79.375;
    const upBtnX = gang === 3 ? 200 : 160;
    const downBtnX = gang === 3 ? -20 : -110;
    let connectColor = "red";
    if (connectStatus && !disabledSensor) {
        connectColor = "green";
    } else if (connectStatus && disabledSensor) {
        connectColor = "purple";
    }
    return (
        <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
            <Path
                fill="#fff"
                stroke="#676c53"
                strokeWidth={3.5}
                data="M2.849 8.11C3.869 5.317 5.4 2.986 9.424 2.894l61.332-.046c2.86.317 5.222 1.47 5.554 6.023l.216 62.393c-.628 1.974-.022 4.224-4.831 5.263H8.485c-3.134-.641-5.234-2.229-5.368-5.533z"
            ></Path>
            {!disabledConnectStatus ? (
                <Circle x={13} y={13} radius={7} fill={connectColor} perfectDrawEnabled={false} />
            ) : (
                <></>
            )}
            {
                gang === 3 &&
                <Ellipse
                    x={62.177}
                    y={39.688}
                    stroke="#0f0"
                    strokeWidth={4}
                    fill="#b3b3b3"
                    radiusX={8.58}
                    radiusY={8.58}
                ></Ellipse>
            }

            <Path
                stroke={status === 1 ? "#d40000" : "#2E86C1"}
                strokeWidth={11.339}
                fill="#b3b3b3"

                data="M108.37 231.38l-33.499 60.344-35.51-59.184z"
                scaleX={0.3}
                scaleY={0.3}
                offsetX={upBtnX}
                offsetY={390}
                rotationDeg={180}

            ></Path>
            <Path
                stroke={status2 === 1 ? "#d40000" : "#2E86C1"}
                strokeWidth={11.339}
                fill="#b3b3b3"
                data="M108.37 231.38l-33.499 60.344-35.51-59.184z"
                scaleX={0.3}
                scaleY={0.3}
                offsetX={downBtnX}
                offsetY={135}
                rotationDeg={0}
            ></Path>
        </Group>
    )
}