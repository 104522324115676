export default function SystemStart({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <ellipse
        cx={40}
        cy={42}
        rx={38}
        ry={34}
        stroke="yellow"
        strokeWidth={5}
        fill="red"
      />
      <text x={10} y={51} fontSize="28" fill="#fff">
        Start
      </text>
    </svg>
  );
}
