import ToolbarLight from "../toolbar/ToolbarLight";
import DivExist from "components/DivExist";
import ToolbarGateway from "../toolbar/ToolbarGateway";
import ToolbarSensor from "../toolbar/ToolbarSensor";
import ToolbarZoneControl from "../toolbar/ToolbarZoneControl";
import ToolbarDaliCtl from "../toolbar/ToolbarDaliCtl";
import ToolbarMapRightClick from "../toolbar/ToolbarMapRightClick";

export default function LayerDeviceToolbar({ layerProps }) {
  const { selectedDeviceType, selectedDeviceObj, mapRightClickPos } = layerProps;
  const objEmpty = Object.keys(selectedDeviceObj || {}).length === 0;
  return (
    <>
      <DivExist show={selectedDeviceType === "light" && !objEmpty}>
        <ToolbarLight layerProps={layerProps} />
      </DivExist>
      <DivExist show={selectedDeviceType === "gateway" && !objEmpty}>
        <ToolbarGateway layerProps={layerProps} />
      </DivExist>
      <DivExist show={selectedDeviceType === "sensor" && !objEmpty}>
        <ToolbarSensor layerProps={layerProps} />
      </DivExist>
      <DivExist show={selectedDeviceType === "zoneControl" && !objEmpty}>
        <ToolbarZoneControl layerProps={layerProps} />
      </DivExist>
      <DivExist show={selectedDeviceType === "daliCtl" && !objEmpty}>
        <ToolbarDaliCtl layerProps={layerProps} />
      </DivExist>
      <DivExist show={!selectedDeviceType && mapRightClickPos && objEmpty}>
        <ToolbarMapRightClick layerProps={layerProps} />
      </DivExist>
    </>
  );
}
