import React, { useState } from 'react';
import ModalSM from './ModalSM';
import { Typography } from '@mui/material';
import Divider10 from './Divider10';


export default function DeviceCmdMsgModal({ children, cmdObj, deviceType, deviceID, disableOpen }) {
    const [open, setOpen] = useState(false);
    let title = `${deviceType}`;
    if (deviceID) title += `: ${deviceID}`;
    title += ` - Command code:0x${cmdObj?.commandCode?.toString(16)?.padStart(4, "0")}`;
    return (
        <>
            <div onClick={() => { if (!disableOpen) setOpen(true) }}>
                {children}
            </div>
            <ModalSM
                modalTitle={title}
                open={open} onClose={() => setOpen(false)}
            >
                {
                    Object.keys(cmdObj || {}).sort((a, b) => a.localeCompare(b)).map((key, index) => {
                        let value;
                        if (key === "commandCode") value = `0x${cmdObj[key].toString(16).padStart(4, "0")}`;
                        else value = cmdObj[key];
                        return (
                            <Typography key={index}>{`${key}: ${value}`}</Typography>
                        )
                    })
                }
            </ModalSM>
        </>
    )
}