import { useState, Fragment } from "react";
import { Line, Rect } from "react-konva";
export default function MapSensorOtherDeviceGroup({ layerProps }) {
  const { iconSize, mapScale, mapObjAll, activeMapID, serverID, layerScale } =
    layerProps;
  const gatewayMapObj = mapObjAll[activeMapID]?.gatewayObj;
  const daliCtlMapObj = mapObjAll[activeMapID]?.daliCtlObj;
  const zoneControlMapObj = mapObjAll[activeMapID]?.zoneControlObj;
  const serverMapObj = mapObjAll[activeMapID]?.serverObj;

  return (
    <>
      {Object.keys(gatewayMapObj || {}).map((s) => {
        const netX = gatewayMapObj?.[s]?.x * mapScale.x;
        const netY = gatewayMapObj?.[s]?.y * mapScale.y;
        return (
          <Fragment key={s}>
            <Rect
              x={netX}
              y={netY}
              width={iconSize * mapScale.x}
              height={iconSize * mapScale.y}
              fill="grey"
              opacity={0.3}
            />
            <Line
              x={netX}
              y={netY}
              points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
              stroke="red"
              strokeWidth={2 / layerScale}
            />
          </Fragment>
        );
      })}
      {Object.keys(daliCtlMapObj || {}).map((s) => {
        const netX = daliCtlMapObj?.[s]?.x * mapScale.x;
        const netY = daliCtlMapObj?.[s]?.y * mapScale.y;
        return (
          <Fragment key={s}>
            <Rect
              x={netX}
              y={netY}
              width={iconSize * mapScale.x}
              height={iconSize * mapScale.y}
              fill="grey"
              opacity={0.3}
            />
            <Line
              x={netX}
              y={netY}
              points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
              stroke="red"
              strokeWidth={2 / layerScale}
            />
          </Fragment>
        );
      })}
      {Object.keys(zoneControlMapObj || {}).map((s) => {
        const netX = zoneControlMapObj?.[s]?.x * mapScale.x;
        const netY = zoneControlMapObj?.[s]?.y * mapScale.y;
        return (
          <Fragment key={s}>
            <Rect
              x={netX}
              y={netY}
              width={iconSize * mapScale.x}
              height={iconSize * mapScale.y}
              fill="grey"
              opacity={0.3}
            />
            <Line
              x={netX}
              y={netY}
              points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
              stroke="red"
              strokeWidth={2 / layerScale}
            />
          </Fragment>
        );
      })}
      <Fragment>
        <Rect
          x={serverMapObj?.[serverID]?.x * mapScale.x}
          y={serverMapObj?.[serverID]?.y * mapScale.y}
          width={iconSize * mapScale.x}
          height={iconSize * mapScale.y}
          fill="grey"
          opacity={0.3}
        />
        <Line
          x={serverMapObj?.[serverID]?.x * mapScale.x}
          y={serverMapObj?.[serverID]?.y * mapScale.y}
          points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
          stroke="red"
          strokeWidth={2 / layerScale}
        />
      </Fragment>
    </>
  );
}
