import { useContext, useState, useEffect } from "react";
import {
  Typography,
  Button,
  Paper,
  Box,
  TextField,
  MenuItem,
} from "@mui/material";
import { Email, VpnKey, Http } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import { useNavigate } from "react-router-dom";
import DivCol from "components/DivCol";
import DivInline from "components/DivInline";
import SkymonLogo from "asset/svgComp/SkymonLogo";
import { loginServer } from "actions/userActions";
import Footer from "components/Footer";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import DivExist from "components/DivExist";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import { mgPost } from "actions/mongoApiActions";
import packageJson from "../../../package.json";
import { alertWindow } from "actions/screenActions";
import Divider10 from "components/Divider10";
import IconButtonDelete from "components/IconButtonDelete";
import { sendAwsEmail, getLatestServerFirmwareVerByApi, updateServerFirmwareByApi } from "actions/serverActions";
import { toUpdateVer } from "actions/generalActions";

const gs = new General();

const ipArr = [
  "https://www.skymonhk.com", //skymon-localserver
  "http://192.168.192.56:3005", //orange box zerotier
  "http://192.168.31.201:3005", //orange box xiaomai
  "http://192.168.192.3:3005", //shun li
];
const serverArr = [
  "skymon-localserver",
  "orange box zerotier",
  "orange box xiaomai",
];

export default function LoginPage() {
  const [state, dispatch] = useContext(SMContext);
  const { storedEmail } = state;
  const navigate = useNavigate();
  const [email, setEmail] = useState(storedEmail || "");
  const [password, setPassword] = useState("");
  const [ip, setIp] = useState("");
  const [clickCount, setClickCount] = useState(0);
  const [ipCkCount, setIpCkCount] = useState(0);
  const [superPwCkCount, setSuperPwCkCount] = useState(0);
  const [serverName, setServerName] = useState("");
  const [activeServerObj, setActiveServerObj] = useState({});
  const [newServerID, setNewServerID] = useState("");
  const [newWebappVer, setNewWebappVer] = useState("");
  const [newServerFirmwareVer, setNewServerFirmwareVer] = useState("");
  const [logoClickCount, setLogoClickCount] = useState(0);
  const checkLatestWebappVersion = async () => {
    const url = `${global.smCloudServerIP}/api/getLatestWebappVer`;
    try {
      const res = await fetchWithTimeout(url, {
        timeout: 2000,
      });

      const response = await res.json();
      setNewWebappVer(response.result);

    } catch (e) {
      console.log("Fail to check latest webapp version");
      setNewWebappVer("");
    }
  };
  const handleUpdateWebapp = async () => {
    // const res = await fetch(`${global.smCloudServerIP}/api/downloadWebapp`);
    const res = await fetch(`${ip}/api/downloadWebapp`);
    const response = await res.json();
    console.log(response);
    if (response.result === "OK") {
      alertWindow(dispatch, "Webapp to be updated, please refresh webapp");
    }
  };
  const fetchServerObj = async () => {
    if (ip.includes("localhost")) return;
    // console.log(`fetch server obj by ip: ${ip}`);
    try {
      const res = await fetchWithTimeout(`${ip}/api/mgFindAll?col=server`, {
        timeout: 2000,
      });
      const response = await res.json();
      const data = response.data;
      console.log(data[0]);
      setActiveServerObj(data[0]);
    } catch (e) {
      console.log("Fail to fetch server obj");
      setActiveServerObj({});
    }

  };
  const handleCheckLatestServerFirmwareVer = async () => {
    const result = await getLatestServerFirmwareVerByApi(global.smCloudServerIP);
    console.log({ result });
    setNewServerFirmwareVer(result?.latestVer);
  };
  const handleUpdateServerFirmware = async () => {
    const res = await updateServerFirmwareByApi(ip);
    if (res.result === "OK") {
      alertWindow(
        dispatch,
        "Server firmware to be updated, please refresh"
      );
    } else {
      alertWindow(dispatch, "Server firmware update fail");
    }
  };
  const checkServer = async () => {
    await gs.waitFor(500);
    await checkLatestWebappVersion();
    await gs.waitFor(500);
    await handleCheckLatestServerFirmwareVer();
    await fetchServerObj();
  };
  useEffect(() => {
    if (ip) checkServer();
  }, [ip]);
  useEffect(() => {
    if (storedEmail) {
      setEmail(storedEmail);
    }
    let curUrl = window.location.href;
    if (curUrl.includes("https://skymonhk.com")) {
      window.open("https://skymonhk.com", "_self");
    }
    if (curUrl.includes("localhost")) curUrl = "https://www.skymonhk.com";
    if (curUrl[curUrl.length - 1] === "/") curUrl = curUrl.slice(0, -1);
    setIp(curUrl);
  }, [storedEmail]);
  const keyPressed = (evt) => {
    if (evt.key === "Enter" || evt.which === "13") {
      handleLogin();
    }
  };
  useEffect(() => {
    if (clickCount >= 4) {
      setEmail("admin@skymon.com.hk");
      setPassword("123456");
    }
    if (ipCkCount > 0) {
      setIp(ipArr[ipCkCount - 1]);
      setServerName(serverArr[ipCkCount - 1]);
    }
  }, [clickCount, ipCkCount]);
  useEffect(() => {
    if (logoClickCount >= 6) {
      setEmail("kennethscs@gmail.com");
      setPassword("123456");
    }
  }, [logoClickCount]);
  const handleLogin = async () => {
    if (!gs.validateEmail(email)) {
      alert("invalid email!");
    } else if (!password) {
      alert("empty password!");
    } else {
      global.ip = ip;
      if (ip.slice(-1) === "/") {
        global.ip = ip.slice(0, -1);
      }
      await loginServer(dispatch, navigate, email, password);
    }
  };

  const handleOpenSuperResetPassword = async () => {
    if (!ip) {
      alert("Please enter server IP");
      return;
    }
    if (!email) {
      alert("Please enter email");
      return;
    }
    window.open(`${ip}/resetPasswordSuper?user=${email}&ip=${ip}`, "_self");
  }
  const handleUpdateServerID = async () => {
    if (newServerID) {
      let curUrl = window.location.href;
      // curUrl = curUrl.slice(0, -1);
      await mgPost("mgUpdateOneUpsert", {
        col: "server",
        query: { enableUploadFS: true },
        data: { serverID: newServerID, localserverUrl: curUrl },
      });
      alert("Server ID updated!");
    }
  };
  const isNewServer =
    !activeServerObj.serverID && !gs.isEmptyJson(activeServerObj)
      ? true
      : false;
  return (
    <Paper sx={{ background: "transparent" }}>
      <DivExist show={activeServerObj.serverID ? true : false}>
        <div style={{ position: "absolute", top: "1vh", right: "1vw", marginRight: "1vh" }}>
          <ForgetPwInputModal ip={ip} />
        </div>
      </DivExist>
      <DivInline justifyContent="center">
        <Box
          sx={{
            marginTop: "4vh",
            width: "50vw",
            height: "82vh",
          }}
        >
          <DivCol>
            <SkylightLogo width={30} height={10} sizeUnit="vw" />
            <Typography variant="body2" sx={{ margin: "3vh" }}>
              Computerized Lighting Management System
            </Typography>
            <DivInline>
              <TextField
                style={{ width: "40vw" }}
                name="email"
                label="Email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
                margin="normal"
                onFocus={(e) => e.target.select()}
              />
              <Email onClick={() => setClickCount((c) => c + 1)} />
            </DivInline>
            <DivInline>
              <TextField
                style={{ width: "40vw" }}
                label="Password"
                type="password"
                placeholder="password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                margin="normal"
                onKeyDown={keyPressed}
                onFocus={(e) => e.target.select()}
              />
              <VpnKey style={{ color: "grey" }}
                onClick={() => {
                  setSuperPwCkCount(c => c + 1);
                  if (superPwCkCount >= 8) handleOpenSuperResetPassword()
                }}
              />
            </DivInline>
            <DivInline>
              <TextField
                style={{ width: "40vw" }}
                label={serverName ? `server IP [${serverName}]` : "server IP"}
                placeholder="server express IP"
                value={ip}
                onChange={(evt) => setIp(evt.target.value)}
                margin="normal"
                onKeyDown={keyPressed}
                onFocus={(e) => e.target.select()}
              />
              <Http
                onClick={() => {
                  let c = ipCkCount;
                  c = c + 1;
                  if (c > ipArr.length) c = 1;
                  setIpCkCount(c);
                }}
              />
            </DivInline>
            <Button
              variant="outlined"
              onClick={handleLogin}
              style={{ marginTop: "4vh" }}
              color="inherit"
            >
              Log in
            </Button>
            {activeServerObj.serverID ? (
              <Typography variant="caption" sx={{ marginTop: "2vh" }}>
                Server: {activeServerObj.serverID}
              </Typography>
            ) : (
              <Typography variant="caption" sx={{ marginTop: "2vh" }}>
                Server: -
              </Typography>
            )}

            <DivExist show={isNewServer}>
              <DivInline>
                <TextField
                  size="small"
                  label="new server ID"
                  value={newServerID}
                  onChange={(e) => setNewServerID(e.target.value)}
                />
                <Button variant="outlined" onClick={handleUpdateServerID}>
                  Set new server ID
                </Button>
              </DivInline>
            </DivExist>
            <DivInline
              justifyContent="center"
              alignItems="center"
              style={{ margin: "6vh" }}
            >
              <Typography variant="caption" sx={{ marginRight: "1vw" }} onClick={() => setLogoClickCount((c) => c + 1)}>
                Powered by
              </Typography>
              <TestApiModal>
                <SkymonLogo
                  background="dark"
                  width={6}
                  sizeUnit="vw"
                  onClick={() => setClickCount((c) => c - 1)}
                />
              </TestApiModal>
            </DivInline>
          </DivCol>
        </Box>
      </DivInline>
      <div style={{ width: "97%", height: "5vh", textAlign: "right" }}>
        <DivExist show={toUpdateVer(packageJson.version, newWebappVer)}>
          <DivInline justifyContent="flex-end" alignItems="center">
            <Typography variant="caption" sx={{ color: "red" }}>
              New webapp version {newWebappVer} available!
            </Typography>
            <Button
              color="error"
              size="small"
              variant="outlined"
              onClick={handleUpdateWebapp}
              sx={{ marginLeft: "1vw" }}
            >
              Update
            </Button>
          </DivInline>
        </DivExist>
        <DivExist show={toUpdateVer(activeServerObj.serVersion, newServerFirmwareVer)}>
          <DivInline justifyContent="flex-end" alignItems="center">
            <Typography variant="caption" sx={{ color: "red" }}>
              {`New server firmware ${newServerFirmwareVer} available!`}
            </Typography>
            <Button
              color="error"
              size="small"
              variant="outlined"
              onClick={handleUpdateServerFirmware}
              sx={{ marginLeft: "1vw" }}
            >
              Update
            </Button>
          </DivInline>
        </DivExist>
      </div>
      <Footer serverVer={activeServerObj.serVersion} />
    </Paper>
  );
}
function ForgetPwInputModal({ ip }) {
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [serverHasInternet, setServerHasInternet] = useState(false);
  const handleOpen = async () => {
    setOpen(true);
    try {
      console.log(`Check server ${ip} has internet`);
      const res = await fetch(`${ip}/api/serverHasInternet`);
      const hasInternetRes = await res.json();
      console.log(hasInternetRes);
      const hasInternet = hasInternetRes.result;
      setServerHasInternet(hasInternet);
    } catch (e) {
      alert(`Cannot reach server at ${ip}`);
    }
  }
  const handleResetBySuperPassword = () => {
    if (!email) {
      alert("Please enter email");
      return;
    }
    console.log(`Server ${ip} has no internet, open super password reset page`);
    setOpen(false);
    window.open(`${ip}/resetPasswordSuper?user=${email}&ip=${ip}`, "_self");
  }
  const handleSendEmail = async () => {
    if (!email) {
      alert("Please enter email");
      return;
    }
    console.log(`Server ${ip} has internet, send email to user to reset password`);
    await sendAwsEmail(ip, "Skylight Admin Message - Reset password", [email], `<div><p>Skymon Admin Message - Reset password</p><p>Click the link below to reset password</p><a href="${ip}/resetPassword?user=${email}&ip=${ip}">Reset password</a></div>`, `Email to reset password sent, please check your email`, `Email to reset password sent fail`);

  };
  return (
    <>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Typography
          variant='caption'
          onClick={handleOpen}
          sx={{
            marginRight: "1vw",
            color: "#fff",
            cursor: "pointer",
            "&:hover": { backgroundColor: "rgba(210, 210, 210, 0.5)" }
          }}
        >
          Forget password
        </Typography>

      </div>
      <ModalSM open={open} onClose={() => setOpen(false)} modalTitle="Forget password">
        <DivCol alignItems="center">
          <TextField
            label="Email"
            placeholder="Email"
            fullWidth
            value={email || ""}
            onChange={(e) => setEmail(e.target.value)}
            variant="outlined"
          />
          <Divider10 />
          <DivExist show={serverHasInternet}>
            <Button variant="outlined" onClick={handleSendEmail}  >Send email to reset password</Button>
            <Divider10 />
          </DivExist>
          <Button variant="outlined" onClick={handleResetBySuperPassword} >Reset password by admin</Button>
        </DivCol>
        <Divider10 />
        <Typography variant="caption">{`Server ${serverHasInternet ? "" : "not"} connected to internet`}</Typography>
      </ModalSM>
    </>
  )
}
function TestApiModal({ children }) {
  const [clickCount, setClickCount] = useState(0);
  const [openTestApi, setOpenTestApi] = useState(false);
  const [httpType, setHttpType] = useState("http://");
  const [url, setUrl] = useState("123.176.96.226:3005/api/test");
  const [httpMethod, setHttpMethod] = useState("GET");
  const [payloadStr, setPayloadStr] = useState("");
  const [response, setResponse] = useState("");
  return (
    <>
      <div
        onClick={() => {
          setClickCount((c) => c + 1);
          if (clickCount >= 4) setOpenTestApi(true);
        }}
      >
        {children}
      </div>
      <ModalSM
        open={openTestApi}
        onClose={() => {
          setOpenTestApi(false);
          setClickCount(0);
        }}
        modalTitle="Test API"
        width="70vw"
        height="70vh"
      >
        <TextField
          select
          value={httpMethod}
          onChange={(e) => setHttpMethod(e.target.value)}
        >
          <MenuItem value="GET">GET</MenuItem>
          <MenuItem value="POST">POST</MenuItem>
          {/* <MenuItem value="PUT">PUT</MenuItem>
          <MenuItem value="DELETE">DELETE</MenuItem> */}
        </TextField>
        <TextField
          select
          value={httpType}
          onChange={(e) => setHttpType(e.target.value)}
        >
          <MenuItem value="http://">http://</MenuItem>
          <MenuItem value="https://">https://</MenuItem>
        </TextField>

        <TextField
          sx={{ width: "40vw" }}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Divider10 />
        <TextField
          fullWidth
          sx={{ display: httpMethod === "GET" ? "none" : "block" }}
          placeholder="payload"
          label="payload"
          value={payloadStr}
          onChange={(e) => setPayloadStr(e.target.value)}
        />
        <Divider10 />
        <Button
          variant="outlined"
          fullWidth
          onClick={async () => {
            const urlWithHttp = `${httpType}${url}`;
            console.log({ urlWithHttp });
            let res;
            if (httpMethod === "POST") {
              try {
                let fixed_payloadStr = payloadStr.replace(
                  /(['"])?([a-zA-Z0-9_]+)(['"])?:/g,
                  '"$2": '
                );
                const json = JSON.parse(fixed_payloadStr);
                res = await fetch(urlWithHttp, {
                  method: httpMethod,
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(json),
                });
              } catch (e) {
                alert("invalid payload!");
                return;
              }
            } else if (httpMethod === "GET") {
              res = await fetch(urlWithHttp);
            }
            const response = await res.json();
            console.log({ response });
            if (typeof response === "object")
              setResponse(JSON.stringify(response));
            if (typeof response === "string") setResponse(response);
          }}
        >
          Send
        </Button>
        <Divider10 />
        <Typography variant="body2" fullWidth>
          {response}
        </Typography>
        <IconButtonDelete onBtnClick={() => setResponse("")} />
      </ModalSM>
    </>
  );
}
async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 8000 } = options;

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
}
