
export default function ControlPointDuplicate({ width, height, sizeUnit }) {
    const w = width || height || 20;
    const h = height || width || 20;
    const unit = sizeUnit || "px";
    return (
        <svg
            viewBox="0 0 79.375 79.375"
            width={w + unit}
            height={h + unit}
        >
            <g
                fill="none"
                strokeDasharray="none"
                strokeLinecap="round"
                strokeMiterlimit="4"
                strokeWidth={5}
            >
                <circle cx="48" cy="38.502" r="28" stroke="#d45500"></circle>
                <path
                    stroke="#d45500"
                    d="M23.79 66.39A30 30 0 014.05 38.197a30 30 0 0119.74-28.19"
                ></path>
                <path
                    stroke="teal"
                    strokeLinejoin="miter"
                    strokeOpacity="1"
                    d="M48.354 20l 0 38"
                ></path>
                <path
                    stroke="teal"
                    strokeLinejoin="miter"
                    strokeOpacity="1"
                    d="M29 38.047l38 0"
                ></path>
            </g>
        </svg>
    );
}
