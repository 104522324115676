import React from "react";

export default function Motion({
  width,
  height,
  sizeUnit,
  status,
  connectStatus,
  disabledConnectStatus,
  backgroundColor,
  disabledSensor,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
      //   width="4vh"
      //   height="4vh"
    >
      {backgroundColor && (
        <rect fill={backgroundColor} width="79" height="79" />
      )}

      {!disabledConnectStatus && (
        <circle r="6" cx="7" cy="7" fill={connectColor} />
      )}
      {status === 1 ? (
        <g>
          <path
            fill="none"
            stroke="red"
            strokeWidth={4}
            d="M9.893 20.109c-7.107 9.459-8.41 19.194-.811 29.353M18.006 23.986c-3.099 5.095-8.955 10.446-.986 22.015M70.257 19.203c7.106 9.459 8.41 19.194.81 29.353M62.143 23.08c3.099 5.095 8.955 10.446.986 22.015"
          ></path>
          <circle
            cx={45.018}
            cy={27.793}
            r={5.75}
            fill="red"
            fillOpacity="0.996"
          ></circle>
          <path
            fill="red"
            d="M40.191 31.168s-9.906-.113-10.723.212c-1.025.406-5.374 6.862-5.374 6.862-.496 4.065 1.094 5.089 4.203 3.897l3.794-4.307h3.383l-3.383 5.947-2.563 9.843H19.89c-2.83 2.649-2.414 4.877.615 6.766H34.86c.863-2.398 1.408-5.307 2.666-7.074l6.767 12.713c3.64 2.71 6.678.991 6.766-3.28l-8.407-15.995c.583-1.719 1.603-3.394 3.076-5.024 1.334.675 2.565 1.868 3.793 3.076h6.767c1.921-1.47 3.434-3.005 0-5.331l-3.69-.103c-2.513-1.562-4.165-3.862-5.947-6.049z"
          ></path>
        </g>
      ) : (
        <g>
          <path
            fill="none"
            stroke="#00f"
            strokeWidth={4}
            d="M9.893 20.109c-7.107 9.459-8.41 19.194-.811 29.353"
          ></path>
          <path
            fill="none"
            stroke="#00f"
            strokeWidth={4}
            d="M18.006 23.986c-3.099 5.095-8.955 10.446-.986 22.015"
          ></path>
          <path
            fill="none"
            stroke="#00f"
            strokeWidth={4}
            d="M70.257 19.203c7.106 9.459 8.41 19.194.81 29.353"
          ></path>
          <path
            fill="none"
            stroke="#00f"
            strokeWidth={4}
            d="M62.143 23.08c3.099 5.095 8.955 10.446.986 22.015"
          ></path>
          <circle
            cx={40.582}
            cy={12.763}
            r={6.615}
            fill="#00f"
            fillOpacity="0.996"
            stroke="none"
            strokeDasharray="none"
            strokeDashoffset="0"
            strokeLinecap="round"
            strokeMiterlimit="4"
            strokeWidth={2.284}
          ></circle>
          <path
            fill="#00f"
            stroke="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity="1"
            strokeWidth={0.265}
            d="M27.321 47.078V28.362c3.275-9.102 22.714-10.35 26.48 0l.167 19.097-4.88.035-.363-13.468c-.372-.385-.737-.827-1.236 0l.04 28.585c-.243 1.612-.997 2.998-3.056 3.803l-7.084-.075c-3.189-.428-2.951-1.999-3.58-3.28V34.2c-.344-.546-.7-1.016-1.267-.074l-.075 13.124z"
          ></path>
          <path
            fill="none"
            stroke="#666"
            strokeDasharray="none"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeMiterlimit="4"
            strokeOpacity="1"
            strokeWidth={3.704}
            d="M32.132 54.39c-13.195 1.997-15.296 4.786-16.79 7.618 6.511 13.34 47.011 9.544 50.424-.01-1.963-5.49-9.646-6.248-16.315-7.845"
          ></path>
        </g>
      )}
    </svg>
  );
}
