import React, { useState } from "react";
// import PropTypes from "prop-types";

const Hover = ({ children, style, disabled }) => {
  const [cursor, setCursor] = useState("default");
  const [filter, setFilter] = useState("brightness(100%)");

  const handleHover = () => {
    if (!disabled) {
      setCursor("pointer");
      setFilter("brightness(130%)");
    }
  };
  const handleNoHover = () => {
    if (!disabled) {
      setCursor("default");
      setFilter("brightness(100%)");
    }
  };

  return React.cloneElement(children, {
    style: { ...style, cursor, filter },
    onMouseOut: handleNoHover,
    onMouseOver: handleHover
  });
};

export default Hover;

// Hover.propTypes = {};
