import { useState, useContext, useEffect, useMemo } from "react";
import { SMContext } from "context/smContext";
import {
  Typography,
  Button,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  TableRow,
  TableCell,
  MenuItem,
  TextField,
  Paper,
} from "@mui/material";
import { Assignment, DynamicFeed } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import Space10 from "components/Space10";
import DivExist from "components/DivExist";
import { printPdf } from "actions/generalActions";
import moment from "moment-timezone";
import ModalSM from "components/ModalSM";
import { mgFindQuery } from "actions/mongoApiActions";
import DivInline from "components/DivInline";
import { cursor } from "asset/string/string";
import SingleReportModal from "containers/testReport/SingleReportModal";
import ThemeContainer from "components/ThemeContainer";
import General from "@ecoenghk/general";
import MapTopBtn from "containers/home/MapTopBtn";
import { CSVLink, CSVDownload } from "react-csv";

const gs = new General();

export default function GroupReportModal({ btnSize, disabled }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, lightObjAll, mapObjAll } = state;
  const [open, setOpen] = useState(false);
  const [openSingleReport, setOpenSingleReport] = useState(false);
  const [groupReportObj, setGroupReportObj] = useState({});

  const [reportMonth, setReportMonth] = useState(moment().month() + 1);
  const [reportYear, setReportYear] = useState(moment().year());
  const activeMapObj = mapObjAll[activeMapID];
  const { reportObjBySerial, location, reportDate, mapName } =
    groupReportObj || {};

  const chooseOpenReport = (reportObj) => {
    console.log(reportObj);
    dispatch({
      type: "SET_ACTIVE_REPORT",
      payload: reportObj,
    });
    dispatch({
      type: "SET_ACTIVE_SERIAL",
      payload: reportObj.serial,
    });
    setOpenSingleReport(true);
  };
  const handlePrint = () => {
    var content = document.getElementById("groupReportBody").innerHTML;
    printPdf("Skymon", "Testing Report", content);
  };
  useEffect(() => {
    if (open) {
      createGroupReportByYrMon();
    }
  }, [open]);

  async function createGroupReportByYrMon() {
    const lightArrInMap = Object.keys(activeMapObj?.lightObj).filter(
      (s) => lightObjAll[s]?.type?.batVI === true
    );
    if (lightArrInMap.length > 0) {
      const query = {
        serial: { $in: lightArrInMap },
        scheduleYear: reportYear,
        scheduleMonth: reportMonth,
      };
      let reportArray = await mgFindQuery("report", query);

      reportArray = reportArray.filter(
        (obj) => obj.reportType !== "check_manual"
      );
      console.log({ reportArray });
      if (reportArray?.length > 0) {
        let reportObjBySerial = {};
        reportArray.forEach((obj) => {
          const { serial, schedule_timeStamp } = obj;
          if (reportObjBySerial.hasOwnProperty(serial)) {
            const lastTSP = reportObjBySerial[serial].schedule_timeStamp;
            if (schedule_timeStamp > lastTSP) reportObjBySerial[serial] = obj;
          } else {
            reportObjBySerial[serial] = obj;
          }
        });
        console.log({ reportObjBySerial });
        let groupReportObj = {
          mapID: activeMapID,
          mapName: activeMapObj.mapName,
          reportDate: `${reportYear}-${reportMonth}`,
          reportObjBySerial,
        };
        console.log({ groupReportObj });
        setGroupReportObj(groupReportObj);
      }
    }
  }
  const reportObjArr = useMemo(() => {
    let arrHead = [
      ["Emergency Lighting Test Report"],
      ["Report Date", reportDate],
      ["Group", mapName],
      ["Group ID", activeMapID],
      ["Location", location ? location : "-"],
      ["Total number of light", Object.keys(reportObjBySerial || {}).length],
    ];
    let arr = Object.values(reportObjBySerial || {}).map((obj) => {
      const lightObj = lightObjAll[obj.serial];
      let data = [
        lightObj.description,
        obj.serial,
        lightObj.batteryHour,
        obj.schedule_timeString,
        obj.reportType,
        `${obj.time_cutOff}min`,
        `>${obj.minV}V`,
        obj.resultData?.testMinV ? `${obj.resultData?.testMinV}V` : "",
        obj.resultData?.testMinV > obj.minV ? "Y" : "N",
        `>${obj.minI}A`,
        obj.resultData?.testMinI ? `${obj.resultData?.testMinI}A` : "",
        obj.resultData?.testMinI > obj.minI ? "Y" : "N",
        ">0A",
        obj.resultData?.rechargeIAvg ? `${obj.resultData?.rechargeIAvg}A` : "",
        obj.resultData?.rechargeIAvg > 0 ? "Y" : "N",
        obj.result,
      ];
      return data;
    });
    arr = arr.sort((a, b) => a[0].localeCompare(b[0]));
    arr.unshift([
      "",
      "",
      "",
      "",
      "",
      "",
      "Require",
      "Result",
      "Accept",
      "Require",
      "Result",
      "Accept",
      "Require",
      "Result",
      "Accept",
      "",
    ]);
    arr.unshift([
      "Name",
      "Serial no.",
      "Battery hour",
      "Test time",
      "Type of test[1]",
      "Discharge time",
      "Minimum battery voltage[2]",
      "",
      "",
      "Discharge current[3]",
      "",
      "",
      "Test end charge current[4]",
      "",
      "",
      "Overall Result",
    ]);
    arr = arrHead.concat(arr);
    return arr;
  }, [reportObjBySerial, lightObjAll]);

  return (
    <>
      <MapTopBtn
        tooltip="Group test report"
        btnSize={btnSize}
        btnClick={() => {
          if (!disabled) setOpen(true);
        }}
        disabled={disabled}
      >
        <DynamicFeed />
      </MapTopBtn>

      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        modalTitle={`Group Test Report - ${activeMapObj?.mapName}`}
        modalIcon={<DynamicFeed />}
        minHeight="95vh"
        minWidth="95vw"
        maxHeight="95vh"
        maxWidth="95vw"
        disableBottomClose
      >
        <DivInline justifyContent="space-between" alignItems="center">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ marginRight: "2vw" }}>Report Month</Typography>
            <Typography>Year</Typography>
            <TextField
              select
              size="small"
              value={reportYear}
              onChange={(e) => setReportYear(e.target.value)}
              style={{ minWidth: 100 }}
            >
              {gs
                .newArrayBetween(
                  moment().subtract(2, "years").year(),
                  moment().year()
                )
                .map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}
                  </MenuItem>
                ))}
            </TextField>
            <Typography>Month</Typography>
            <TextField
              select
              size="small"
              value={reportMonth}
              onChange={(e) => setReportMonth(e.target.value)}
              style={{ minWidth: 100 }}
            >
              {gs.newArrayBetween(1, 12).map((m) => (
                <MenuItem key={m} value={m}>
                  {m}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <Button variant="outlined" onClick={() => createGroupReportByYrMon()}>
            Get report
          </Button>
          <Space10 />
          <Button variant="outlined" onClick={handlePrint}>
            Print
          </Button>
          <Button variant="outlined">
            <CSVLink
              data={reportObjArr}
              filename={`${mapName}_${reportDate}.csv`}
              style={{ color: "#fff" }}
            >
              Export
            </CSVLink>
          </Button>
        </DivInline>

        <Divider10 />
        <DivExist show={Object.keys(reportObjBySerial || {}).length > 0}>
          <div
            id="groupReportBody"
            style={{ background: "#fff", padding: "1vh" }}
          >
            <ThemeContainer background="light">
              <Paper>
                <Typography>Emergency Lighting Test Report</Typography>
                <Divider10 />
                <Table size="small">
                  <TableBody>
                    <TableRow>
                      <TableCell>Report Date</TableCell>
                      <TableCell>{reportDate}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Group</TableCell>
                      <TableCell>{mapName}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Group ID</TableCell>
                      <TableCell>{activeMapID}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Location</TableCell>
                      <TableCell>{location ? location : "-"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total number of light</TableCell>
                      <TableCell>
                        {Object.keys(reportObjBySerial || {}).length}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

                <Divider10 />

                <table
                  style={{
                    margin: "auto",
                    width: "99%",
                    border: "1px solid grey",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead>
                    <tr>
                      <td style={tStyle}>Name</td>
                      <td style={tStyle}>Serial no.</td>
                      <td style={tStyle}>Battery hour</td>
                      <td style={tStyle}>Test time</td>
                      <td style={tStyle}>Type of test[1]</td>
                      <td style={tStyle}>Discharge time</td>
                      <td style={tStyle} colSpan="3">
                        Minimum battery voltage[2]
                      </td>
                      <td style={tStyle} colSpan="3">
                        Discharge current[3]
                      </td>
                      <td style={tStyle} colSpan="3">
                        Test end charge current[4]
                      </td>
                      <td style={tStyle}>Overall Result</td>
                      <td style={tStyle} className="noPrint">
                        Device report
                      </td>
                    </tr>
                    <tr>
                      <td style={tStyle} colSpan="5"></td>
                      <td style={tStyle}>Require</td>
                      <td style={tStyle}>Result</td>
                      <td style={tStyle}>Accept</td>
                      <td style={tStyle}>Require</td>
                      <td style={tStyle}>Result</td>
                      <td style={tStyle}>Accept</td>
                      <td style={tStyle}>Require</td>
                      <td style={tStyle}>Result</td>
                      <td style={tStyle}>Acceptable</td>
                      <td style={tStyle}></td>
                      <td className="noPrint"></td>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(reportObjBySerial || {})
                      .sort((a, b) =>
                        lightObjAll[a]?.description.localeCompare(
                          lightObjAll[b]?.description
                        )
                      )
                      .map((serial, key) => {
                        const lightObj = lightObjAll[serial];
                        const serialName = lightObj?.description;
                        const batteryHour = lightObj?.batteryHour;
                        const reportObj = reportObjBySerial[serial] || {};
                        const {
                          reportType,
                          minI,
                          minV,
                          schedule_timeString,
                          schedule_timeStamp,
                          time_cutOff,
                          resultData,
                          result,
                        } = reportObj || {};
                        const resultDisplay = result ? result : "No result";
                        let resultV,
                          resultI,
                          resultVColor,
                          resultIColor,
                          resultEndI,
                          resultEndIColor;
                        if (
                          resultData?.testMinV &&
                          resultData?.testMinV > minV
                        ) {
                          resultV = "Y";
                          resultVColor = "green";
                        } else {
                          resultV = "N";
                          resultVColor = "red";
                        }
                        if (
                          resultData?.testMinI &&
                          resultData?.testMinI > minI
                        ) {
                          resultI = "Y";
                          resultIColor = "green";
                        } else {
                          resultI = "N";
                          resultIColor = "red";
                        }
                        if (
                          resultData?.rechargeIAvg &&
                          resultData?.rechargeIAvg < 0
                        ) {
                          resultEndI = "Y";
                          resultEndIColor = "green";
                        } else {
                          resultEndI = "N";
                          resultEndIColor = "red";
                        }
                        //   const overallResult = resultV==="Y"&&resultI==="Y"&&resultEndI==="Y"  ? "Y" : "N";
                        //   const reportTypeStringObj = {
                        //     check_manual: "manual",
                        //     check_1month: "monthly",
                        //     check_6month: "6-month",
                        //     check_12month: "annual",
                        //     check_36month: "3-year"
                        //   };
                        const schDate =
                          moment(schedule_timeStamp).format("YYYY-MM-DD");
                        const schTime =
                          moment(schedule_timeStamp).format("HH:mm");
                        return (
                          <tr key={key}>
                            <td style={tStyle}>{serialName}</td>
                            <td style={tStyle}>{serial}</td>
                            <td style={tStyle}>{batteryHour}</td>
                            <td style={tStyle}>
                              {schDate}
                              <br />
                              {schTime}
                            </td>
                            <td style={tStyle}>{reportType}</td>
                            <td style={tStyle}>
                              {time_cutOff ? time_cutOff + "min" : ""}
                            </td>
                            <td style={tStyle}>
                              {minV ? ">" + minV.toFixed(1) + "V" : ""}
                            </td>
                            <td style={tStyle}>
                              {resultData?.testMinV
                                ? resultData?.testMinV + "V"
                                : ""}
                            </td>
                            <td style={{ ...tStyle, color: resultVColor }}>
                              {resultV}
                            </td>
                            <td style={tStyle}>
                              {minI ? ">" + minI.toFixed(2) + "A" : ""}
                            </td>
                            <td style={tStyle}>
                              {resultData?.testMinI
                                ? Math.abs(resultData?.testMinI) + "A"
                                : ""}
                            </td>
                            <td style={{ ...tStyle, color: resultIColor }}>
                              {resultI}
                            </td>
                            <td style={tStyle}>
                              {resultData?.rechargeIAvg ? ">0A" : ""}
                            </td>
                            <td style={tStyle}>
                              {resultData?.rechargeIAvg
                                ? Math.abs(resultData?.rechargeIAvg) + "A"
                                : ""}
                            </td>
                            <td style={{ ...tStyle, color: resultEndIColor }}>
                              {resultEndI}
                            </td>
                            <td
                              style={{
                                ...tStyle,
                                color: result === "OK" ? "green" : "red",
                              }}
                            >
                              {resultDisplay}
                            </td>
                            <td style={tStyle} className="noPrint">
                              {result ? (
                                <IconButton
                                  size="small"
                                  variant="outlined"
                                  onClick={() => chooseOpenReport(reportObj)}
                                >
                                  <Assignment />
                                </IconButton>
                              ) : null}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <Divider10 />
                <Typography variant="body2">Remarks:</Typography>
                <Typography variant="caption" display="block">
                  1. There are three types of testing according to the FSD
                  PPA104A Edition5 Appendix II clause 14 requirements:
                </Typography>
                <Typography variant="caption" display="block">
                  -- Monthly discharge test with discharge duration less than
                  1/4 of rated duration (1 minute)
                </Typography>

                <Typography variant="caption" display="block">
                  -- Yearly discharge test with discharge full rated duration
                  (3hr, 2hr, 1hr)
                </Typography>
                <Typography variant="caption" display="block">
                  2. According to FSD PPA104A Appendix I clause23, the on-load
                  volage of each cell after test shall be not less than 2.01V
                  for lead acid and 1.25V for nickel-cadmium.
                </Typography>
                <Typography variant="caption" display="block">
                  For other types of battery, advices from the manufacturer of
                  the battery shall be sought and that shall be acceptable to
                  the Director of FSD.
                </Typography>
                <Typography variant="caption" display="block">
                  3. To ensure the battery is discharging current to the light.
                </Typography>
                <Typography variant="caption" display="block">
                  4. To ensure the battery is being recharged after testing.
                </Typography>
                <Divider10 />
                <Typography align="right">
                  By Skymon Smart Lighting System
                </Typography>
              </Paper>
            </ThemeContainer>
          </div>
        </DivExist>
        {/* <DivExist show={Object.keys(reportObjBySerial || {}).length > 0}>
        <Divider10 />
        <Button variant="contained" size="small" onClick={printPdf}>
          <Print />
          print pdf
        </Button>
      </DivExist> */}
      </ModalSM>
      <SingleReportModal
        open={openSingleReport}
        onClose={() => {
          dispatch({
            type: "SET_ACTIVE_REPORT",
            payload: {},
          });
          dispatch({
            type: "SET_ACTIVE_SERIAL",
            payload: "",
          });
          setOpenSingleReport(false);
        }}
      />
    </>
  );
}

const tStyle = {
  border: "1px solid grey",
  borderCollapse: "collapse",
  paddingLeft: 3,
  fontSize: "small",
};
