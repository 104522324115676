import { Group, Path } from "react-konva";

/**
 * Renders an On/Off icon using the react-konva library.
 *
 * @param {Object} props - The properties object.
 * @param {number} props.x - The x-coordinate for the icon.
 * @param {number} props.y - The y-coordinate for the icon.
 * @param {number} props.size - The size of the icon.
 * @param {"on" | "off"} props.onoff - The state of the icon, either "on" or "off".
 * @returns {JSX.Element} The rendered On/Off icon.
 */
export default function OnOffIcon({ x, y, size, onoff }) {
    const scale = size / 79.375;
    const innerColor = onoff === "on" ? "#229954" : "#cb4335";
    const mediumColor = onoff === "on" ? "#27ae60" : "#ec7063";
    const outerColor = onoff === "on" ? "#a9dfbf" : "#f5b7b1";

    return (
        <Group x={x} y={y} scaleX={scale} scaleY={scale}>
            <Group fill="none" strokeLinecap="round">
                <Path
                    stroke={outerColor}
                    strokeWidth={15}
                    data="M55.569 14.059c11.881 6.873 17.829 20.636 14.649 33.898-3.18 13.262-14.738 22.905-28.47 23.749-13.731.845-26.405-7.307-31.215-20.078-4.81-12.771.206-28.134 11.138-36.41"
                ></Path>
                <Path
                    stroke={mediumColor}
                    strokeWidth={9}
                    data="M55.569 14.059c11.881 6.873 17.829 20.636 14.649 33.898-3.18 13.262-14.738 22.905-28.47 23.749-13.731.845-26.405-7.307-31.215-20.078-4.81-12.771.206-28.134 11.138-36.41"
                ></Path>
                <Path
                    stroke={innerColor}
                    strokeWidth={4}
                    data="M55.569 14.059c11.881 6.873 17.829 20.636 14.649 33.898-3.18 13.262-14.738 22.905-28.47 23.749-13.731.845-26.405-7.307-31.215-20.078-4.81-12.771.206-28.134 11.138-36.41"
                ></Path>
                <Path stroke={outerColor} strokeWidth={15} data="M38.569 7.356v34.173"></Path>
                <Path stroke={mediumColor} strokeWidth={9} data="M38.569 7.356v34.173"></Path>
                <Path stroke={innerColor} strokeWidth={4} data="M38.569 7.356v34.173"></Path>
            </Group>
        </Group>
    );
}
