import { Circle, Group, Path } from "react-konva";

export default function Daylight({
  x,
  y,
  size,
  luxLv,
  connectStatus,
  disabledConnectStatus,
  disabledSensor,
}) {
  const scale = size / 79.375;
  const opacity = luxLv / 10;
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      {!disabledConnectStatus ? (
        <Circle x={-10} radius={7} fill={connectColor} perfectDrawEnabled={false} />
      ) : (
        <></>
      )}
      <Circle
        x={39.688}
        y={39.688}
        radius={28}
        fill="#918a6f"
        stroke="#483737"
        strokeWidth={4}
        perfectDrawEnabled={false}
      />
      <Circle
        x={39.688}
        y={39.688}
        radius={28}
        fill="#ffcc00"
        opacity={opacity || 0}
        perfectDrawEnabled={false}
      />
      <Path
        lineCap="round"
        stroke="#ffcc00"
        strokeWidth={3.5}
        data="M40.119 7.168v-4.57M41.043 77.112v-4.57M72.231 41.275h4.57M2.381 41.275h4.57M11.377 65.029l3.766-2.587M67.266 65.029L63.5 62.442M11.377 14.288l3.766 2.587M67.266 14.288L63.5 16.875"
        perfectDrawEnabled={false}
      />
    </Group>
  );
}
