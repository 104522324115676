global.ip = "";
global.socket = null;
global.smCloudServerIP = "https://www.skymonhk.com";

/*

npm version patch
sh uploadServer.sh

//copy the zip file to compute engine: skymon-localserver folder "downloadFile"




*/
