import { useReducer, useEffect } from "react";
import { smReducer, initialState } from "./smReducer";
import { SMContext } from "./smContext";

export const SMProvider = ({ children }) => {
  const [state, dispatch] = useReducer(smReducer, initialState);
  useEffect(() => {
    // console.log("pinned state", state);
  }, [state]);
  return (
    <SMContext.Provider value={[state, dispatch]}>
      {children}
    </SMContext.Provider>
  );
};
