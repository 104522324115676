import { useState, useContext, Fragment } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Tooltip, Checkbox } from "@mui/material";
import {
  ArrowForward,
  Lock,
  LockOpen,
  Repeat,
  ArrowBack,
} from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import Space from "components/Space";
import DivInline from "components/DivInline";
import LightIcon from "components/LightIcon";
import DivCol from "components/DivCol";
import { saveZcLockLevel, updateZcProperty } from "actions/zcActions";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import LockIcon from "components/LockIcon";
import ZoneControlIcon from "components/ZoneControlIcon";
import ModalSM from "components/ModalSM";

export default function ChangeLockLvLightSwModal({ zoneControlObj }) {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [releaseOnDelayEnd, setReleaseOnDelayEnd] = useState(0);
  const [lockLevel, setLockLevel] = useState(5);
  const handleOpen = () => {
    setOpen(true);
    setReleaseOnDelayEnd(zoneControlObj.releaseOnDelayEnd1);
    setLockLevel(zoneControlObj.lockLevelSet2 || 5);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {
    const lockLevelSet1 = lockLevel;
    const lockLevelSet2 = lockLevel;
    const releaseOnDelayEnd1 = releaseOnDelayEnd;
    const releaseOnDelayEnd2 = 0;
    console.log(
      lockLevelSet1,
      lockLevelSet2,
      releaseOnDelayEnd1,
      releaseOnDelayEnd2
    );
    await saveZcLockLevel(
      zoneControlObj.zoneControlID,
      zoneControlObj,
      [1, 2],
      [lockLevelSet1, lockLevelSet2],
      [releaseOnDelayEnd1, releaseOnDelayEnd2]
    );
    handleClose();
  };

  return (
    <>
      <Tooltip title="Change priority level">
        <Button onClick={handleOpen} variant="outlined" size="small">
          <ZoneControlIcon width={2} sizeUnit="vw" type="zcSwitch" status={1} />
          <LockIcon
            lockLevel={zoneControlObj.lockLevelSet2 || 5}
            size="1.2em"
          />
          <Repeat />
          <ZoneControlIcon width={2} sizeUnit="vw" type="zcSwitch" status={0} />
          <LockIcon
            lockLevel={
              zoneControlObj.releaseOnDelayEnd1 === 1
                ? 5
                : zoneControlObj.lockLevelSet2
            }
            size="1.2em"
          />
        </Button>
      </Tooltip>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle="Change priority level"
        onSave={handleSave}
      >
        <DivInline justifyContent="space-between">
          {[5, 4, 3, 2, 1].map((n) => {
            let variant = n === lockLevel ? "contained" : "outlined";
            return (
              <Button key={n} variant={variant} onClick={() => setLockLevel(n)}>
                <LockIcon lockLevel={n} size="1.2em" />
              </Button>
            );
          })}
        </DivInline>
        <DivInline sx={{ marginTop: "1vh" }} justifyContent="space-around">
          <DivCol>
            <ArrowBack />
            <Typography>Low priority</Typography>
          </DivCol>
          <DivCol>
            <ArrowForward />
            <Typography>High priority</Typography>
          </DivCol>
        </DivInline>
        <Divider10 />
        <SpaceBetweenDiv
          title="Release light to lowest priority on action end"
          data={
            <Checkbox
              checked={releaseOnDelayEnd === 1 ? true : false}
              onChange={(e) => setReleaseOnDelayEnd(e.target.checked ? 1 : 0)}
            />
          }
        />
      </ModalSM>
    </>
  );
}
