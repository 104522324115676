import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import {
    List, ListItem, ListItemText, ListItemAvatar, Avatar,
    Button,
    IconButton,
    FormControlLabel,
    Radio,
    RadioGroup,
    Tooltip,
} from "@mui/material";
import { Settings, Delete, CompareArrows, Edit, Forum, ArrowForwardIos } from "@mui/icons-material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import DivSpaceBetween from "components/DivSpaceBetween";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import ModalSM from "components/ModalSM";
import DivCol from "components/DivCol";
import {
    alertWindow,
    confirmWindow,
    openSnackbar,
    promptWindow,
} from "actions/screenActions";
import AdminContainer from "components/AdminContainer";
import MapTopBtn from "containers/home/MapTopBtn";
import { deviceInfoOfObj } from "actions/generalActions";
import moment from "moment";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DeviceIcon from "components/DeviceIcon";

export default function MapDeviceMessageModal({ btnSize }) {
    const [state, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const {
        activeMapID,
        mapObjAll,
        lightObjAll,
        gatewayObjAll,
        sensorObjAll,
        daliCtlObjAll,
        zoneControlObjAll,
        mapDeviceMessageArray
    } = state;
    const activeMapObj = mapObjAll[activeMapID] || {};

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <MapTopBtn
                tooltip="Device Message"
                btnSize={btnSize}
                btnClick={() => {
                    setOpen(true);
                }}
            >
                <Forum />
            </MapTopBtn>

            <ModalSM
                modalTitle={`Map Device Messages - [${activeMapObj.mapName}]`}
                modalIcon={<Forum />}
                open={open}
                onClose={handleClose}
                height="90vh"
                width="90vw"
                disableBottomClose
            >

                <SpaceBetweenDiv title="Map ID" data={activeMapID} />
                <SpaceBetweenDiv title="Map Name" data={activeMapObj.mapName} />
                <Divider10 />
                <SpaceBetweenButton
                    hideComponent={mapDeviceMessageArray.length === 0}
                    title="Clear All Messages"
                    btnSize="small"
                    btnContent={<Delete />}
                    onBtnClick={() => {
                        confirmWindow(dispatch, "Are you sure to clear all messages?", () => {
                            dispatch({
                                type: "SET_GENERAL_STATE",
                                payload: {
                                    key: "mapDeviceMessageArray",
                                    value: [],
                                }
                            });
                        });
                    }} />
                <Divider10 />
                <List sx={{ width: "70vw" }}>
                    {
                        mapDeviceMessageArray.map((msg, index) => {
                            const deviceObj = lightObjAll[msg.deviceID] || gatewayObjAll[msg.deviceID] || sensorObjAll[msg.deviceID] || daliCtlObjAll[msg.deviceID] || zoneControlObjAll[msg.deviceID] || {};
                            const deviceInfo = deviceInfoOfObj(deviceObj);
                            const { deviceType } = deviceInfo;
                            const secondaryMsg = `${deviceType} [${msg.deviceID}] - ${moment(msg.timeStamp).format("YYYY-MM-DD HH:mm:ss")}`;
                            return (
                                <ListItem key={index}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            {<DeviceIcon width={2.5} sizeUnit="vw" deviceType={deviceType} deviceStyle={deviceObj.style || deviceObj.type} />}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={msg.message} secondary={secondaryMsg} />
                                </ListItem>
                            );
                        })
                    }
                </List>
            </ModalSM>
        </>
    );
}
