import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import { Typography, Box, Slider } from "@mui/material";
import DivInline from "components/DivInline";
import { FastForward, SwipeRightAlt } from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import LightIcon from "components/LightIcon";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import ZoneControlIcon from "components/ZoneControlIcon";
import {
  updateZcProperty,
  zoneControlDisconnectLight,
} from "actions/zcActions";
import DivExist from "components/DivExist";
import { deviceModalPos } from "actions/mapActions";
import General from "@ecoenghk/general";
import DivCol from "components/DivCol";
import ZcMapModalHeader from "./ZcMapModalHeader";
const gs = new General();

export default function ZcSwitchLightModal({
  x,
  y,
  open,
  handleClose,
  layerProps,
  selectedLight,
  zoneControlID,
  zoneControlObj,
}) {
  const [pwm1, setPwm1] = useState(0);
  const [pwm2, setPwm2] = useState(0);
  useEffect(() => {
    setPwm1(serialSetting1.pwm);
    setPwm2(serialSetting2.pwm);
  }, [open]);
  const { lightObjAll, dispatch } = layerProps;
  const lightObj = lightObjAll[selectedLight];
  const { setting1, setting2, type } = zoneControlObj || {};
  const serialSetting1 = setting1?.[selectedLight] || {};
  const serialSetting2 = setting2?.[selectedLight] || {};
  const onoffcontrolA1 = serialSetting1.onoffcontrolA;

  const onoffcontrolA2 = serialSetting2.onoffcontrolA;

  let pwmMarks = [];
  for (let i = 20; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  const updateSetting = async (settingNum, settingField, value) => {
    let newSetting = { ...zoneControlObj[`setting${settingNum}`] };
    let newSerialSetting = { ...newSetting[selectedLight] };
    newSerialSetting = { ...newSerialSetting, [settingField]: value };
    newSetting = { ...newSetting, [selectedLight]: newSerialSetting };
    await updateZcProperty(zoneControlID, {
      [`setting${settingNum}`]: newSetting,
    });
  };
  const handleDisconnect = async () => {
    confirmWindow(dispatch, `Confirm remove this light?`, async () => {
      showBigProgress(dispatch);
      await zoneControlDisconnectLight(zoneControlObj, selectedLight, lightObj);
      handleClose();
      hideBigProgress(dispatch);
    });
  };
  const modalWidth = window.innerWidth * 0.35;
  const modalHeight = window.innerHeight * 0.38;
  const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
  return (
    <MapModal
      x={pos.x}
      y={pos.y}
      open={open}
      handleCloseModal={handleClose}
      disableCloseButton
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <ZcMapModalHeader
        zoneControlObj={zoneControlObj}
        controlledDeviceObj={lightObj}
        handleClose={handleClose}
        layerProps={layerProps}
      />
      <Divider10 />
      <DivInline>
        <div>
          <DivInline style={{ width: 260 }} justifyContent="space-between">
            <ZoneControlIcon
              type={type}
              width={3}
              sizeUnit="vw"
              zoneControlObj={zoneControlObj}
              status={0}
            />
            <FastForward style={{ fontSize: "1.5em" }} />
            <Box
              sx={{
                outline: onoffcontrolA1 === 0 ? "1px solid red" : "",
                cursor: "pointer",
              }}
              onClick={() => updateSetting(1, "onoffcontrolA", 0)}
            >
              <LightBulb onoff="on" width={3} sizeUnit="vw" />
            </Box>
            <Box
              sx={{
                outline: onoffcontrolA1 === 1 ? "1px solid red" : "",
                cursor: "pointer",
              }}
              onClick={() => updateSetting(1, "onoffcontrolA", 1)}
            >
              <LightBulb onoff="off" width={3} sizeUnit="vw" />
            </Box>
            <DivExist show={lightObj?.type?.pwm}>
              <Slider
                sx={{ width: 100 }}
                size="small"
                value={pwm1 || 0}
                onChangeCommitted={(e, val) => {
                  setPwm1(Number(val));
                  updateSetting(1, "pwm", Number(val));
                }}
                onChange={(e) => setPwm1(Number(e.target.value))}
                marks={pwmMarks}
                max={100}
                min={0}
                step={10}
              />
            </DivExist>
          </DivInline>
          <Divider10 />
          <DivInline style={{ width: 260 }} justifyContent="space-between">
            <ZoneControlIcon
              // iconType={iconType2}
              type={type}
              width={3}
              sizeUnit="vw"
              zoneControlObj={zoneControlObj}
              status={1}
            />
            <FastForward style={{ fontSize: "1.5em" }} />
            <Box
              sx={{
                outline: onoffcontrolA2 === 0 ? "1px solid red" : "",
                cursor: "pointer",
              }}
              onClick={() => updateSetting(2, "onoffcontrolA", 0)}
            >
              <LightBulb onoff="on" width={3} sizeUnit="vw" />
            </Box>
            <Box
              sx={{
                outline: onoffcontrolA2 === 1 ? "1px solid red" : "",
                cursor: "pointer",
              }}
              onClick={() => updateSetting(2, "onoffcontrolA", 1)}
            >
              <LightBulb onoff="off" width={3} sizeUnit="vw" />
            </Box>
            <DivExist show={lightObj?.type?.pwm}>
              <Slider
                sx={{ width: 100 }}
                size="small"
                value={pwm2 || 0}
                onChangeCommitted={(e, val) => {
                  setPwm2(Number(val));
                  updateSetting(2, "pwm", Number(val));
                }}
                onChange={(e) => setPwm2(Number(e.target.value))}
                marks={pwmMarks}
                max={100}
                min={0}
                step={10}
              />
            </DivExist>
          </DivInline>

          <Divider10 />
        </div>
      </DivInline>
      <IconButtonClose onBtnClick={handleClose} />
      <IconButtonDelete onBtnClick={handleDisconnect} />
    </MapModal>
  );
}
