import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";

import BatteryCharge from "asset/svgComp/BatteryCharge";

export default function CurrentWithPolarity({
  variant,
  serial,
  style,
  darkLight,
  iconSize,
}) {
  const [state] = useContext(SMContext);
  const { lightObjAll } = state;
  const { reversePolarityI, current } = lightObjAll[serial] || {};
  let currentWithPolarity = "";
  let icon;
  if (reversePolarityI) {
    const currentP = current * -1;
    currentWithPolarity = currentP?.toFixed(2);
    icon = (
      <BatteryCharge
        darkLight={darkLight}
        status={currentP < 0 ? "discharge" : "charge"}
        width={iconSize || 30}
      />
    );
  } else {
    currentWithPolarity = current?.toFixed(2);
    icon = (
      <BatteryCharge
        darkLight={darkLight}
        status={current < 0 ? "charge" : "discharge"}
        width={iconSize || 30}
      />
    );
  }
  if (current === 0) currentWithPolarity = "0";
  return (
    <Typography
      style={{
        ...style,
        display: "flex",
        alignItems: "center",
      }}
      variant={variant ? variant : "body1"}
    >
      {currentWithPolarity}A{current === 0 ? "" : icon}
    </Typography>
  );
}
