import React from "react";

export default function PasteIcon({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g fill="none" strokeWidth="5.889">
        <path
          stroke="#d45500"
          fill="#fff"
          d="M57.182 35.939l.122-10.692L38.226 7.623 8.753 7.74v67.44h23.97"
        ></path>
        <path stroke="#d45500" d="M35.414 8.68l.122 20.209h20.057"></path>
        <g stroke="maroon">
          <path d="M37.37 54.502l11.007 10.927M37.37 54.502l11.007 10.927M34.802 55.736l13.575-12.924M68.067 77.765l-.122-23.381H38.96"></path>
        </g>
      </g>
    </svg>
  );
}
