import React, { useContext, Fragment } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import { Lock } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import DivSpaceBetween from "components/DivSpaceBetween";
import ChangeLockLvScenebtnModal from "./ChangeLockLvScenebtnModal";
import AdminContainer from "components/AdminContainer";
import { updateZcProperty } from "actions/zcActions";
import DeviceIdAddGwLine from "components/DeviceIdAddGwLine";
import DeviceActionLine from "components/DeviceActionLine";
import GroupActionConfirmOneDevice from "components/GroupActionConfirmOneDevice";
import GroupActionConfirmOneGroup from "components/GroupActionConfirmOneGroup";
export default function ControlDevicesZcSceneButtonPanel() {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeZoneControlID,
    mapObjAll,
    lightObjAll,
    userObj,
    activeMapID,
    zoneControlObjAll,
  } = state;
  const zoneControlObj = zoneControlObjAll[activeZoneControlID];
  const activeMapObj = mapObjAll[activeMapID];
  const { setting1, lockLevelSet1, releaseOnDelayEnd1, GAS_setting1 } = zoneControlObj || {};
  const lightArrayAll = Object.keys(setting1 || {});
  const lightArrayInMap = lightArrayAll.filter((s) =>
    Object.keys(activeMapObj.lightObj || {}).includes(s)
  );
  const lightArrayNotInMap = lightArrayAll.filter(
    (s) => !Object.keys(activeMapObj.lightObj || {}).includes(s)
  );
  return (
    <>
      <DivInline justifyContent="center" alignItems="center">
        <Typography variant="h6" align="center">
          Control devices
        </Typography>
        <GroupActionConfirmOneGroup groupDeviceObj={zoneControlObj} settingNumArray={[1]} />
      </DivInline>
      <Divider10 />
      <DivSpaceBetween>
        <Typography>
          <Lock />
          Priority Level
        </Typography>
        <ChangeLockLvScenebtnModal
          zoneControlObj={zoneControlObj}
          existingLv={lockLevelSet1}
          existingRelease={releaseOnDelayEnd1}
        />
      </DivSpaceBetween>
      <Divider10 />
      <DivCol style={{ width: "100%" }} alignItems="flex-start">
        {lightArrayInMap.map((s, key) => {
          const lightObj = lightObjAll?.[s];
          return (
            <Fragment key={key}>
              <LightRow
                zoneControlObj={zoneControlObj}
                serial={s}
                lightObj={lightObj}
                set1={setting1?.[s]}
              />
            </Fragment>
          );
        })}
      </DivCol>
      <Divider10 />

      <DivExist show={userObj.level < 1 && lightArrayNotInMap.length > 0}>
        <Divider10 />
        <Typography>Lights not in this map:</Typography>
        {lightArrayNotInMap.map((s, key) => (
          <Typography key={key}>{s}</Typography>
        ))}
      </DivExist>
    </>
  );
}

function LightRow({ zoneControlObj, serial, lightObj, set1 }) {
  const [state] = useContext(SMContext);
  const { zoneControlID } = zoneControlObj;
  if (!lightObj) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="caption" onClick={() => {
        if (state.userObj.level > 0) return;
        alert(JSON.stringify(set1));
      }}>{lightObj.description}</Typography>
      <DivInline>
        <DeviceIdAddGwLine deviceID={serial} address={lightObj.dtkAdd} gatewayID={set1.gatewayID} />
        {lightObj.gatewayID !== set1.gatewayID && <Typography variant="caption" color="error">Gateway not match!</Typography>}
        <AdminContainer>
          <DivInline>
            <DivExist show={lightObj.gatewayID !== set1.gatewayID}>
              <Typography
                variant="caption"
                sx={{
                  color: "cyan",
                  cursor: "pointer",
                  outline: "1px solid cyan",
                  marginLeft: "1vw",
                  padding: "0 0.6vw",
                }}
                onClick={() => {
                  updateZcProperty(zoneControlID, {
                    [`setting1.${serial}.gatewayID`]: lightObj.gatewayID,
                  });
                }}
              >
                fix
              </Typography>
            </DivExist>
          </DivInline>
        </AdminContainer>
        <GroupActionConfirmOneDevice deviceObj={zoneControlObj} controlledDeviceObj={lightObj} settingNumArray={[1]} />
      </DivInline>

      <DeviceActionLine mainDeviceObj={zoneControlObj} deviceSetting={set1} deviceObj={lightObj} buttonNo={1} />
      <Divider10 space={3} light />
    </Box>
  );
}
