import { useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import DivExist from "components/DivExist";
import { Paper, Typography } from "@mui/material";
import IconButtonBack from "components/IconButtonBack";
import ThemeContainer from "components/ThemeContainer";
import LightIcon from "components/LightIcon";
import LightControlPanel from "./LightControlPanel";
import TestInProgressPanel from "./TestInProgressPanel";
import LightDataReportPanel from "./LightDataReportPanel";
import LightInfoPanel from "./LightInfoPanel";
import DivInline from "components/DivInline";
import AirconPanel from "./AirconPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import LightInfoSuperAdmin from "./LightInfoSuperAdmin";
import { queryOneLightSetting, updateSerialProperty } from "actions/lightActions";
import { deviceErrorMsg } from "actions/generalDeviceActions";
import { removeDuplicates } from "actions/generalActions";
import { useNavigate } from "react-router-dom";
import { useSocketListenToWeb } from "hooks/socketHooks";
const boxH = "88vh";
const boxW = ["36vw", "36vw", "26vw"];
const boxSx = {
    padding: "0.5vw",
    margin: "1vh 0.5vw 1vh 0.5vw",
    border: "0.5px solid grey",
    borderRadius: "1vw",
    overflowY: "scroll",
    height: boxH,
};
export default function LightDetailPage() {
    const [state, dispatch] = useContext(SMContext);
    const navigate = useNavigate();
    useSocketListenToWeb(navigate);
    const {
        activeSerial,
        lightObjAll,
        gatewayObjAll,
        daliCtlObjAll,
        userObj
    } = state;
    let lightObj = lightObjAll[activeSerial];
    const { description, masterSerial, result, gatewayID, daliCtlID, mapID } = lightObj || {};
    const daliCtlObj = daliCtlObjAll[daliCtlID];
    const handleClose = () => {
        dispatch({ type: "CLOSED_LIGHT_DETAIL_MODAL" });
        navigate("/MapPage");
    };
    useEffect(() => {
        const errorMsgObj = deviceErrorMsg(lightObj, "light", state);
        if (errorMsgObj?.[1]) queryOneLightSetting(lightObj, 0x03a2); //channel not match
        const newMapID = removeDuplicates(mapID);
        if (newMapID?.length !== mapID?.length) {
            updateSerialProperty(activeSerial, lightObj, { mapID: newMapID });
        }
        if (lightObj?.type?.batVI && !lightObj?.batteryHour) {
            updateSerialProperty(activeSerial, lightObj, { batteryHour: 2 });
        }
    }, []);

    return (
        <ThemeContainer background="dark">
            <Paper>
                <DivInline>
                    <IconButtonBack onBtnClick={handleClose} />
                    <DeviceMongoModal deviceObj={lightObj}>
                        <LightIcon
                            lightObj={lightObj}
                            width={3.5}
                            sizeUnit="vw"
                            gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
                            disabledConnectStatus={masterSerial}
                            daliCtlObj={daliCtlObj}
                        />
                    </DeviceMongoModal>
                    <Typography sx={{ marginLeft: "1vw" }}>{`${description} [${activeSerial}]`}</Typography>
                </DivInline>
                <DivInline hide={lightObj?.enableAirconControl === 1}>
                    {/* /////////////Information///////////////////////////////Information///////////////////// */}
                    <div style={{ ...boxSx, width: boxW[0] }}>
                        <LightInfoPanel />
                        <DivExist show={userObj.level === 0 && !masterSerial}>
                            <LightInfoSuperAdmin handleClose={handleClose} />
                        </DivExist>
                    </div>
                    {/* /////////////Data & Report & Log/////////////////////Data & Report & Log/////////////////// */}
                    <div style={{ ...boxSx, width: boxW[1] }}>
                        <DivExist show={!masterSerial}>
                            <LightDataReportPanel />
                        </DivExist>
                    </div>
                    {/* ///////////////////Control Panel////////////////////Control Buttons//////////////////Control Buttons/////////////////*/}
                    <div style={{ ...boxSx, width: boxW[2], overflowY: "auto" }}>
                        <DivExist show={userObj.level < 2 && !masterSerial}>
                            {result === "started" || result === "init" ? (
                                <TestInProgressPanel />
                            ) : (
                                <LightControlPanel />
                            )}
                        </DivExist>
                    </div>
                </DivInline>
                <DivExist show={lightObj?.enableAirconControl === 1}>
                    <AirconPanel />
                </DivExist>
            </Paper>
        </ThemeContainer>
    )
}