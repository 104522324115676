import React, { useState } from "react";
import { Button, Checkbox, Typography } from "@mui/material";
import ModalSM from "components/ModalSM";
import LockIcon from "components/LockIcon";
import { Repeat, ArrowBack, ArrowForward } from "@mui/icons-material";
import Lightswitch from "asset/svgComp/Lightswitch";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import Divider10 from "components/Divider10";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import { saveSensorLockLevel } from "actions/sensorActions";

export default function ChangeLockLvLightSwModal({ swNo, sensorObj }) {
  const [open, setOpen] = useState(false);
  const [lockLevel, setLockLevel] = useState(5);
  const [release, setRelease] = useState(0);
  const handleOpen = () => {
    setOpen(true);
    setLockLevel(sensorObj[`lockLevelSet${swNo * 2}`] || 5);
    setRelease(sensorObj[`releaseOnDelayEnd${swNo * 2 - 1}`] || 0);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {
    await saveSensorLockLevel(
      sensorObj.sensorID,
      sensorObj,
      [swNo * 2 - 1, swNo * 2],
      [lockLevel, lockLevel],
      [release, 0]
    );
    handleClose();
  };
  return (
    <>
      <Button variant="outlined" onClick={handleOpen}>
        <Lightswitch
          status={swNo === 1 ? 1 : 0}
          status2={swNo === 2 ? 1 : 0}
          status3={swNo === 3 ? 1 : 0}
          status4={swNo === 4 ? 1 : 0}
          width={3}
          sizeUnit="vw"
          disabledConnectStatus
          gang={sensorObj.gang}
        />
        <LockIcon
          lockLevel={sensorObj[`lockLevelSet${swNo * 2}`]}
          size="1.2rem"
        />
        <Repeat />
        <Lightswitch
          status={0}
          status2={0}
          status3={0}
          status4={0}
          width={3}
          sizeUnit="vw"
          disabledConnectStatus
          gang={sensorObj.gang}
        />
        <LockIcon
          lockLevel={
            sensorObj[`releaseOnDelayEnd${swNo * 2 - 1}`] === 1
              ? 5
              : sensorObj[`lockLevelSet${swNo * 2}`]
          }
          size="1.2rem"
        />
      </Button>
      <ModalSM
        open={open}
        onClose={handleClose}
        modalTitle="Change priority level"
        onSave={handleSave}
      >
        <DivInline justifyContent="space-between">
          {[5, 4, 3, 2, 1].map((n) => {
            let variant = n === lockLevel ? "contained" : "outlined";
            return (
              <Button key={n} variant={variant} onClick={() => setLockLevel(n)}>
                <LockIcon lockLevel={n} size="2rem" />
              </Button>
            );
          })}
        </DivInline>
        <DivInline sx={{ marginTop: "1vh" }} justifyContent="space-around">
          <DivCol>
            <ArrowBack />
            <Typography>Low priority</Typography>
          </DivCol>
          <DivCol>
            <ArrowForward />
            <Typography>High priority</Typography>
          </DivCol>
        </DivInline>
        <Divider10 />
        <SpaceBetweenDiv
          title="Release light to lowest priority on switch off"
          data={
            <Checkbox
              checked={release === 1 ? true : false}
              onChange={(e) => setRelease(e.target.checked ? 1 : 0)}
            />
          }
        />
      </ModalSM>
    </>
  );
}
