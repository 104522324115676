import { Typography } from "@mui/material";
import DialogInput from "./DialogInput";
import DivInline from "./DivInline";
import DivExist from "./DivExist";
import IconButtonRefresh from "./IconButtonRefresh";

/**
 *
 * @param {Object} param0
 * @param {string} param0.title
 * @param {string} param0.dialogTitle
 * @param {string} param0.data
 * @param {function(string)} param0.handleSave
 * @param {boolean} param0.autoSelect
 * @param {boolean} param0.disableEditIcon
 * @param {number} param0.iconSize
 * @param {boolean} param0.showValueUnit
 * @param {boolean} param0.showValue
 * @param {object} param0.sx
 * @param {function} param0.handleRefresh
 * @param {boolean} param0.hideComponent
 * @returns
 */
export default function SpaceBetweenDialogInput({
  title,
  dialogTitle,
  data,
  handleSave,
  autoSelect,
  disableEditIcon,
  iconSize,
  showValueUnit,
  showValue,
  sx,
  handleRefresh,
  hideComponent,
}) {
  if (hideComponent) return null;
  else {
    return (
      <DivInline
        justifyContent="space-between"
        style={{ marginBottom: 5, ...sx }}
      >
        <DivInline>
          <Typography>{title}</Typography>
          <DivExist show={handleRefresh ? true : false}>
            <IconButtonRefresh onBtnClick={handleRefresh} />
          </DivExist>
        </DivInline>
        <DivInline>
          <DialogInput
            title={dialogTitle || title}
            handleSave={handleSave}
            autoSelect={autoSelect}
            showValue
            initialVal={data}
            disableEditIcon={disableEditIcon}
            iconSize={iconSize}
            showValueUnit={showValueUnit}
          />
        </DivInline>
      </DivInline>
    );
  }
}
