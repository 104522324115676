import { useContext, useState, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import ZoneControlIcon from "components/ZoneControlIcon";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import ButtonSM from "components/ButtonSM";
import { executeZoneControl } from "actions/zcActions";
export default function ZcDetailSceneBtnControlPanel() {
    const [state, dispatch] = useContext(SMContext);
    const { activeZoneControlID, zoneControlObjAll } = state;
    const zcObj = zoneControlObjAll[activeZoneControlID];
    return (
        <div>
            <DivInline justifyContent="center">
                <Typography variant="h6" display="block" align="center">
                    Manual Control
                </Typography>
            </DivInline>
            <Divider10 />
            <DivInline justifyContent="center">
                <ButtonSM onClick={() => executeZoneControl(zcObj, 1)} tooltip="Execute scene button">
                    <ZoneControlIcon type="zcSceneButton"
                        width={5} sizeUnit="vw"
                        status={0}
                    />
                </ButtonSM>
            </DivInline>
            <Divider10 />
        </div>
    )
}