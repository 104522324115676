import { Group, Path } from "react-konva";
import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";

export default function Panellight({
  x,
  y,
  size,
  statusL,
  lightLv,
  disabledStatus,
  typeBatVI,
  shadEnabled,
}) {
  const scale = size / 79.375;
  if (!typeBatVI) statusL = 0;
  const lightColor = lightColorAt(disabledStatus, statusL, lightLv);
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false} >
      <Path
        fill="#666"
        data="M19.932 2.86l16.988-.085 10.429 8.326-17.409-.252z"
        perfectDrawEnabled={false}
      />
      <Path
        fill="gray"
        data="M32.295 11.101H34.986000000000004V43.816H32.295z"
        perfectDrawEnabled={false}
      />
      <Path fill="#8080ff" data="M1.424 43.662H48.503V51.92H1.424z"
        perfectDrawEnabled={false} />
      <Path
        fill="#8080ff"
        data="M76.883 65.019L49.171 44.026l-.028 8.007 27.738 21.8z"
        perfectDrawEnabled={false}
      />
      <Path
        fill="#216778"
        data="M8.785 55.639l39.087-.255.462-2.253-46.829.098L29.78 75 31 72.33z"
        perfectDrawEnabled={false}
      />
      <Path
        fill="#216778"
        data="M48.334 53.13L76.63 75.25 29.779 75l1.219-2.67 38.691.498-21.818-17.444z"
        perfectDrawEnabled={false}
      />
      <Path
        fill={lightColor}
        stroke="#000"
        strokeWidth={0.265}
        data="M8.785 55.639l39.087-.255L69.69 72.828l-38.691-.498z"
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={2}
        shadowOffset={{ x: 10, y: 10 }}
        shadowOpacity={0.5}
        perfectDrawEnabled={false}
      />

      {!disabledStatus && typeBatVI && (
        <ChargeIcon y={10} x={-25} statusL={statusL} />
      )}
    </Group>
  );
}
