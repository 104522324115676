import { useState, Fragment } from "react";
import { Line, Rect, Circle } from "react-konva";
import DivExist from "components/DivExist";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { sensorConnectLight } from "actions/sensorActions";
import SenMotionLightModal from "../modal/SenMotionLightModal";
import SenDaylightLightModal from "../modal/SenDaylightLightModal";
import SenLightSwLightModal from "../modal/SenLightSwLightModal";
import { darkColorArr, sensorStringsOfType } from "actions/generalActions";
import SenDaylightMotionLightModal from "../modal/SenDaylightMotionLightModal";
import General from "@ecoenghk/general";
import SenSceneButtonLightModal from "../modal/SenSceneButtonLightModal";
import ButtonNo from "./ButtonNo";
import SenDimControllerLightModal from "../modal/SenDimControllerLightModal";
import ChooseGangModal from "../modal/ChooseGangModal";
const gs = new General();
export default function MapSensorLightGroup({
  layerProps,
  sensorID,
  x,
  y,
  sensorObj,
}) {
  const {
    iconSize,
    mapScale,
    lightObjAll,
    dispatch,
    mapObjAll,
    activeMapID,
    enablePan,
    layerScale,
  } = layerProps;
  const lightMapObj = mapObjAll[activeMapID]?.lightObj;
  const [selectedLight, setSelectedLight] = useState("");
  const [controlledDeviceX, setControlledDeviceX] = useState(0);
  const [controlledDeviceY, setControlledDeviceY] = useState(0);
  const [editModalX, setEditModalX] = useState(0);
  const [editModalY, setEditModalY] = useState(0);
  const [modalLocation, setModalLocation] = useState("topleft"); //topleft, topright, bottomleft, bottomright
  const [showEditModal, setShowEditModal] = useState(false);
  const [chooseGangSerial, setChooseGangSerial] = useState("");
  const { type, serialMap, gang } = sensorObj || {};
  const sensorTypeName = sensorStringsOfType(type)?.sensorTypeName;
  function handleCloseModal() {
    setShowEditModal(false);
    setSelectedLight("");
  }
  const handleClick = (e, serial, isUnderSensor) => {
    e.evt.preventDefault();
    if (isUnderSensor) {
      handleOpenLightModal(serial);
    } else {
      handleConnectLight(serial);
    }
  };
  const handleOpenLightModal = (s) => {
    setControlledDeviceX(lightMapObj[s]?.x);
    setControlledDeviceY(lightMapObj[s]?.y);
    setSelectedLight(s);
    setShowEditModal(true);
  };
  const handleConnectLight = async (s) => {
    const lightObj = lightObjAll[s];
    if (!lightObj.gatewayID) {
      alertWindow(dispatch, "Please connect gateway to light first.");
      return;
    }
    if (!sensorObj.gatewayID) {
      alertWindow(dispatch, `Please connect gateway to this ${sensorTypeName} first.`);
      return;
    }
    if (gang > 1 && (type === "scenebutton" || type === "lightswitch")) {
      setControlledDeviceX(lightMapObj[s]?.x);
      setControlledDeviceY(lightMapObj[s]?.y);
      setChooseGangSerial(s);
    } else {
      confirmWindow(dispatch, `Connect ${s} to this ${sensorTypeName}?`, async () => {
        await sensorConnectLight(sensorObj, lightObj, 1);
      });
    }
  };
  const unitSize = iconSize * mapScale.x;

  return (
    <>
      <>
        {Object.keys(lightMapObj || {}).map((s) => {
          const netX = lightMapObj?.[s]?.x * mapScale.x;
          const netY = lightMapObj?.[s]?.y * mapScale.y;
          const serialArray = Object.values(serialMap || {}) || [];
          const isUnderSensor = serialArray?.includes(s);
          const lightObj = lightObjAll[s];
          const lightType = lightObj?.type;
          let canConnect = true;
          if (!lightType?.A || lightObj.masterSerial) canConnect = false;
          if (type === "dimcontroller") {
            if (!lightType?.pwm) canConnect = false;
          }
          return (
            <Fragment key={s}>
              <DivExist show={lightType?.A}>
                <Rect
                  x={netX}
                  y={netY}
                  width={unitSize}
                  height={unitSize}
                  fill={isUnderSensor ? "#F1948A" : "#52BE80"}
                  opacity={0.5}
                  onMouseOver={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "pointer" : "move";
                  }}
                  onMouseLeave={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "grab" : "default";
                  }}
                  onTap={(e) => handleClick(e, s, isUnderSensor)}
                  onClick={(e) => handleClick(e, s, isUnderSensor)}
                  stroke={selectedLight === s ? "#2C3E50" : ""}
                  strokeWidth={6 / layerScale}
                />
              </DivExist>
              <DivExist show={!canConnect}>
                <Rect
                  x={netX}
                  y={netY}
                  width={iconSize * mapScale.x}
                  height={iconSize * mapScale.y}
                  fill="grey"
                  opacity={0.3}
                />
                <Line
                  x={netX}
                  y={netY}
                  points={[0, 0, iconSize * mapScale.x, iconSize * mapScale.y]}
                  stroke="red"
                  strokeWidth={2 / layerScale}
                />
              </DivExist>
              <DivExist
                show={isUnderSensor && type === "scenebutton"}
              >
                {gs.newArrayBetween(1, 4).map((n) => (
                  <ButtonNo
                    show={sensorObj?.[`setting${n}`]?.[s] ? true : false}
                    layerProps={layerProps}
                    x={netX}
                    y={netY}
                    buttonNo={n}
                    key={n}
                  />
                ))}
              </DivExist>
              <DivExist
                show={isUnderSensor && type === "lightswitch"}
              >
                {gs.newArrayBetween(1, 4).map((n, i) => (
                  <ButtonNo
                    show={sensorObj?.[`setting${n * 2 - 1}`]?.[s] ? true : false}
                    layerProps={layerProps}
                    x={netX}
                    y={netY}
                    buttonNo={n}
                    key={n}
                  />

                ))}
              </DivExist>
              <DivExist show={chooseGangSerial === s}>
                <Circle
                  radius={iconSize * mapScale.x}
                  x={netX + (iconSize * mapScale.x) / 2}
                  y={netY + (iconSize * mapScale.y) / 2}
                  stroke={darkColorArr[0]}
                  strokeWidth={2 / layerScale}
                />
              </DivExist>
            </Fragment>
          );
        })}
      </>
      <ChooseGangModal
        open={chooseGangSerial ? true : false}
        onClose={() => setChooseGangSerial("")}
        x={controlledDeviceX}
        y={controlledDeviceY}
        connectDeviceID={chooseGangSerial}
        connectDeviceObj={lightObjAll[chooseGangSerial]}
        sensorObj={sensorObj || {}}
        layerProps={layerProps}
        onConnect={async (swNo) => {
          await sensorConnectLight(sensorObj, lightObjAll[chooseGangSerial], swNo);
          setChooseGangSerial("");
        }}
      />
      <SenMotionLightModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        open={showEditModal && type === "motion"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
      />
      <SenDaylightLightModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        open={showEditModal && type === "daylight"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
      />
      <SenLightSwLightModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        modalLocation={modalLocation}
        open={showEditModal && type === "lightswitch"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
      />
      <SenSceneButtonLightModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        modalLocation={modalLocation}
        open={showEditModal && type === "scenebutton"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
      />
      <SenDimControllerLightModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        modalLocation={modalLocation}
        open={showEditModal && type === "dimcontroller"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
      />
      <SenDaylightMotionLightModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        modalLocation={modalLocation}
        open={showEditModal && type === "daylightmotion"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedLight={selectedLight}
        sensorID={sensorID}
        sensorObj={sensorObj}
      />
    </>
  );
}

