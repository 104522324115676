import React, { useContext } from "react";
import { SMContext } from "../context/smContext";
import {
  BatteryFull,
  Power,
  Highlight,
  BlurLinear,
  Brightness5,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import DivExist from "./DivExist";
import { Tooltip } from "@mui/material";

const LightFunction = ({ serial, fontSizeWithUnit, lightType }) => {
  const [state] = useContext(SMContext);
  const { lightObjAll } = state;
  const type = lightType ? lightType : lightObjAll?.[serial]?.type;
  const iconStyle = { fontSize: fontSizeWithUnit ?? "" };

  return (
    <span>
      <DivExist show={type?.A}>
        <Tooltip title="Light switch function">
          <Highlight style={iconStyle} />
        </Tooltip>
      </DivExist>
      <DivExist show={type?.lux}>
        <Tooltip title="Measure light level function">
          <Brightness5 style={iconStyle} />
        </Tooltip>
      </DivExist>
      <DivExist show={type?.pwm}>
        <Tooltip title="Light dimming function">
          <BlurLinear style={iconStyle} />
        </Tooltip>
      </DivExist>
      <DivExist show={type?.L}>
        <Tooltip title="Power cut function">
          <Power style={iconStyle} />
        </Tooltip>
      </DivExist>
      <DivExist show={type?.batVI}>
        <Tooltip title="Emergency battery analysis function">
          <BatteryFull style={iconStyle} />
        </Tooltip>
      </DivExist>
    </span>
  );
};

export default LightFunction;

LightFunction.propTypes = {
  serial: PropTypes.string,
  type: PropTypes.object,
  fontSizeWithUnit: PropTypes.string,
};
