import { useState } from "react";
import ModalSM from "./ModalSM";
import { Button, Typography, IconButton } from "@mui/material";
import DivExist from "./DivExist";
import DivInline from "./DivInline";
import General from "@ecoenghk/general";
import { DataObject } from "@mui/icons-material";
const gs = new General();

/**
 * @param {object} param0
 * @param {boolean} param0.show
 * @param {object} param0.jsonData
 * @param {"outlined" | "contained"} param0.btnVariant
 * @param {string} param0.btnContent
 * @param {boolean} param0.iconButton
 * @param {string} param0.btnColor
 */
export default function JsonModal({ show, jsonData, btnVariant, btnContent, iconButton, btnColor }) {
  const [open, setOpen] = useState(false);
  const handleOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  }
  return (
    <>
      <DivExist show={show}>
        {
          iconButton ?
            <IconButton onClick={handleOpen} color={btnColor || "inherit"}>
              <DataObject />
            </IconButton>
            :
            <Button
              onClick={handleOpen}
              variant={btnVariant || "outlined"}
              size="small"
              color={btnColor || "inherit"}
            >
              {btnContent || "open"}
            </Button>
        }

        <ModalSM
          open={open}
          onClose={() => setOpen(false)}
          backgroundColor="#212F3C"
          height="80vh"
          width="80vw"
        >
          {Object.keys(jsonData || {})
            .sort((a, b) => a.localeCompare(b))
            .map((key) => {
              const value = jsonData[key];
              const dataType = typeof value;
              const valueIsJson = !Array.isArray(value) && dataType === "object" && !gs.isEmptyJson(value);
              const valueIsArray = Array.isArray(value);
              const valueIsString = dataType === "string";
              const valueIsNumber = dataType === "number";
              const valueIsBoolean = dataType === "boolean";
              return (
                <DivInline justifyContent="space-between" key={key}>
                  <Typography variant="body2" sx={{ width: "20vw" }}>
                    {key}
                  </Typography>
                  <DivExist show={valueIsNumber}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      {value}
                    </Typography>
                  </DivExist>
                  <DivExist show={valueIsString}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      {`"${value}"`}
                    </Typography>
                  </DivExist>
                  <DivExist show={valueIsBoolean}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      {(value || false).toString()}
                    </Typography>
                  </DivExist>
                  <DivExist show={valueIsArray}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      {JSON.stringify(value)}
                    </Typography>
                  </DivExist>
                  <DivExist show={valueIsJson}>
                    <Typography variant="body2" sx={{ width: "60vw" }}>
                      value is json
                    </Typography>
                    <JsonModal show jsonData={value} />
                  </DivExist>
                </DivInline>
              );
            })}
        </ModalSM>
      </DivExist>
    </>
  );
}
