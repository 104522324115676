import React from "react";
import { Tooltip } from "@mui/material";
import Motion from "asset/svgComp/Motion";
import Lightswitch from "asset/svgComp/Lightswitch";
import Daylight from "asset/svgComp/Daylight";
import FireAlarm from "asset/svgComp/FireAlarm";
import SensorGeneral from "asset/svgComp/SensorGeneral";
import DaylightMotion from "asset/svgComp/DaylightMotion";
import SceneButton from "asset/svgComp/SceneButton";
import { deviceConnected } from "actions/generalActions";
import DimController from "asset/svgComp/DimController";

/**
 * @description if use sensorObj,also add gatewayConnected. And the following props will be ignored: status, status2, status3, status4, luxLv, type, connectStatus, disabledConnectStatus
 * @param {number} param0.width
 * @param {"vw"|"px"} param0.sizeUnit
 * @param {Object} param0.sensorObj
 * @param {boolean} param0.gatewayConnected
 * @param {0|1} param0.status
 * @param {0|1} param0.status2
 * @param {0|1} param0.status3
 * @param {0|1} param0.status4
 * @param {boolean} param0.disabledSensor
 * @param {number} param0.luxLv
 * @param {"motion"|"lightswitch"|"daylight"|"daylightmotion"|"scenebutton"|"fire"} param0.type
 * @param {boolean} param0.disabledStatus
 * @param {boolean} param0.disabledConnectStatus
 * @param {string} param0.tooltip
 * @param {string} param0.backgroundColor
 * @param {boolean} param0.connectStatus
 * @param {boolean} param0.disabledConnectStatus
 * @param {number} param0.gang
 * @returns
 */
export default function SensorIcon({
  width,
  sizeUnit,
  sensorObj,
  gatewayConnected,
  status,
  status2,
  status3,
  status4,
  disabledSensor,
  luxLv,
  lux,
  type,
  backgroundColor,
  tooltip,
  connectStatus,
  disabledConnectStatus,
  disabledStatus,
  disabledStatus2,
  disabledStatus3,
  disabledStatus4,
  gang,
}) {
  const typeUse = sensorObj?.type || type;
  const statusUse = sensorObj?.currentStatus ?? status;
  const statusUse2 = sensorObj?.currentStatus2 ?? status2;
  const statusUse3 = sensorObj?.currentStatus3 ?? status3;
  const statusUse4 = sensorObj?.currentStatus4 ?? status4;
  const disabledSensorUse = sensorObj?.disabledSensor || disabledSensor;
  const gangUse = sensorObj?.gang || gang;
  const luxLvUse = sensorObj?.luxLv ?? luxLv;
  const luxUse = sensorObj?.lux ?? lux;
  const timeStampUse = sensorObj?.timeStamp;
  let connectStatusUse;
  if (connectStatus) {
    connectStatusUse = true;
  } else {
    if (!timeStampUse) {
      connectStatusUse = false;
    } else {
      connectStatusUse = deviceConnected(
        sensorObj?.zigbeeConnected,
        timeStampUse,
        gatewayConnected
      );
    }
  }

  return (
    <div>
      <Tooltip title={tooltip || ``}>
        <div>
          {typeUse === "motion" ? (
            <Motion
              width={width}
              sizeUnit={sizeUnit || "px"}
              status={statusUse || 0}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              backgroundColor={backgroundColor}
              disabledSensor={disabledSensorUse}
            />
          ) : typeUse === "lightswitch" ? (
            <Lightswitch
              width={width}
              sizeUnit={sizeUnit || "px"}
              status={statusUse || 0}
              status2={statusUse2 || 0}
              status3={statusUse3 || 0}
              status4={statusUse4 || 0}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              backgroundColor={backgroundColor}
              gang={gangUse}
              disabledSensor={disabledSensorUse}
              disabledStatus={disabledStatus}
              disabledStatus2={disabledStatus2}
              disabledStatus3={disabledStatus3}
              disabledStatus4={disabledStatus4}
            />
          ) : typeUse === "fire" ? (
            <FireAlarm
              width={width}
              sizeUnit={sizeUnit || "px"}
              status={statusUse || 0}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              disabledSensor={disabledSensorUse}
            />
          ) : typeUse === "daylight" ? (
            <Daylight
              width={width}
              sizeUnit={sizeUnit || "px"}
              // luxLv={luxLvUse || 0}
              luxLv={luxLvUse}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              disabledSensor={disabledSensorUse}
            />
          ) : typeUse === "daylightmotion" ? (
            <DaylightMotion
              width={width}
              sizeUnit={sizeUnit || "px"}
              // luxLv={luxLvUse || 0}
              luxLv={statusUse}
              motionStatus={statusUse2}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              disabledSensor={disabledSensorUse}
            />
          ) : typeUse === "scenebutton" ? (
            <SceneButton
              width={width}
              sizeUnit={sizeUnit || "px"}
              status={statusUse || 0}
              status2={statusUse2 || 0}
              status3={statusUse3 || 0}
              status4={statusUse4 || 0}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              disabledSensor={disabledSensorUse}
              gang={gangUse}
            />
          ) : typeUse === "dimcontroller" ? (
            <DimController width={width}
              sizeUnit={sizeUnit || "px"}
              status={statusUse || 0}
              status2={statusUse2 || 0}
              status3={statusUse3 || 0}
              connectStatus={connectStatusUse}
              disabledConnectStatus={disabledConnectStatus}
              disabledSensor={disabledSensorUse}
            // gang={gangUse}
            />
          ) : (
            <SensorGeneral width={width} sizeUnit={sizeUnit || "px"} />
          )}
        </div>
      </Tooltip>
    </div>
  );
}
