import React, { useContext } from "react";
import { SMContext } from "../context/smContext";
import Snackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SnackbarMsg() {
  const [state, dispatch] = useContext(SMContext);
  const { snackbarMsg } = state;
  const { title, status, severity, time } = snackbarMsg;
  const handleClose = () => {
    dispatch({
      type: "CLOSED_SNACKBAR",
    });
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={status}
      autoHideDuration={time || 2500}
      onClose={handleClose}
      action={
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          size="large"
        >
          <Close />
        </IconButton>
      }
    >
      <Alert onClose={handleClose} severity={severity || "info"}>
        {title}
      </Alert>
    </Snackbar>
  );
}
