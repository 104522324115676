import React from "react";

export default function ChargeIcon({ statusL, x, y }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      x={x}
      y={y}
      width="60"
      height="60"
    >
      <path
        fill={statusL === 1 ? "red" : "green"}
        fillOpacity={0.996}
        strokeWidth={5.373}
        d="M0.773 6.281H15.802999999999999V36.798H0.773z"
      />
      <path
        fill="#917c6f"
        fillOpacity={0.996}
        strokeLinecap="round"
        strokeWidth={4.139}
        d="M4.113 3.24H12.463000000000001V6.081H4.113z"
      ></path>
      <path
        fill="#fff"
        fillOpacity={0.996}
        strokeLinecap="round"
        strokeWidth={5.373}
        d="M1.916 7.246H14.404V10.41H1.916z"
      ></path>
      <path
        fill="#fff"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity={1}
        strokeWidth={0.384}
        d="M9.479 14.579l-1.084 6.43 3.157-.817-4.377 9.466.353-6.937-3.463 1.654c1.822-3.256 3.616-6.527 5.414-9.796z"
      ></path>
      {statusL === 1 && (
        <>
          <path
            fill="red"
            strokeLinecap="butt"
            strokeLinejoin="miter"
            strokeOpacity={1}
            strokeWidth={0.635}
            d="M20.238 29.097V14.45l6.814 7.061z"
          />
        </>
      )}
    </svg>
  );
}
