import { useContext, useState } from "react";
import {
  Box,
  Button,
  Tooltip,
  Typography,
  TextField,
  Select,
  MenuItem,
  ButtonGroup,
} from "@mui/material";
import DivInline from "components/DivInline";
import { DataObject, ManageSearch, Search } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import ModalSM from "components/ModalSM";
import Divider10 from "components/Divider10";
import {
  mgFindAll,
  mgDeleteMany,
  mgFindOne,
  mgFindQuery,
} from "actions/mongoApiActions";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import { alertWindow } from "actions/screenActions";
import { mgPost } from "actions/mongoApiActions";

import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
import JsonModal from "components/JsonModal";
import MapTopBtn from "./MapTopBtn";
import JsonModalContainer from "components/JsonModalContainer";
import ButtonSM from "components/ButtonSM";
const gs = new General();

export default function SearchModal({ chooseMap, btnSize, isMapBtn }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll } = state;
  const [searchText, setSearchText] = useState("");
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [searchMapID, setSearchMapID] = useState("");

  const handleSearchMongo = async (field) => {
    const collArr = ["serial", "sensor", "daliCtl", "gateway"];
    let fieldArr = [field, field, field, field];
    if (field === "deviceID") fieldArr = ["serial", "sensorID", "daliCtlID", "gatewayID"];
    let arr = [];
    await gs.asyncForEach(collArr, async (coll, i) => {
      let arr1 = await mgFindQuery(coll, { [fieldArr[i]]: searchText });
      arr1 = arr1.map((obj) => {
        return { ...obj, deviceType: collArr[i] };
      });
      arr = [...arr, ...arr1];
    });
    setSearchResult(arr);
  };
  const handleSearchFS = async (field) => {
    let fieldArr = [field, field, field, field];
    if (field === "zigbeeAdd")
      fieldArr = ["zigbeeAdd", "zigbeeAdd", "zigbeeAdd", "zigbeeAdd"];
    if (field === "deviceID")
      fieldArr = ["serial", "sensorID", "daliCtlID", "gatewayID"];
    const collArr = ["serial", "sensor", "daliCtl", "gateway"];
    let arr = [];
    await gs.asyncForEach(collArr, async (coll, i) => {
      let arr1 = await mgPost("getDocsWhereFS", {
        fsCollPath: `SM_${coll}`,
        resultType: "array",
        whereField: fieldArr[i],
        compareOperator: "==",
        compareVal: searchText,
      });
      arr1 = arr1.map((obj) => {
        return { ...obj, deviceType: collArr[i] };
      });
      arr = [...arr, ...arr1];
    });
    setSearchResult(arr);
  };
  const handleSearchMongoMap = async () => {
    let arr = await mgFindQuery("map", { mapID: searchMapID });
    arr = arr.map((obj) => {
      return { ...obj, deviceType: "map" };
    });
    setSearchResult(arr);
  };
  const handleSearchFSMap = async () => {
    const arr = await mgPost("getDocsWhereFS", {
      fsCollPath: "SM_map",
      resultType: "array",
      whereField: "mapID",
      compareOperator: "==",
      compareVal: searchMapID,
    });
    arr = arr.map((obj) => {
      return { ...obj, deviceType: "map" };
    });
    setSearchResult(arr);
  };
  return (
    <>
      {isMapBtn ? (
        <MapTopBtn
          btnClick={() => setOpenSearchModal(true)}
          tooltip="Search device in server database"
          btnSize={btnSize}
        >
          <ManageSearch />
        </MapTopBtn>
      ) : (
        <Tooltip
          title={<Typography>Search device in server database</Typography>}
          placement="bottom"
        >
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            onClick={() => setOpenSearchModal(true)}
          >
            <Search />
          </Button>
        </Tooltip>
      )}

      <ModalSM
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        modalTitle="Search device in server database"
        modalIcon={<Search />}
        width="95vw"
        height="90vh"
      >
        <DivInline>
          <Typography>[Mongo] Search value</Typography>
          <TextField
            sx={{ width: "12vw" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
          />

          <ButtonGroup size="small" variant="outlined">
            <Button onClick={() => handleSearchMongo("dtkAdd")} >By dtkAdd</Button>
            <Button onClick={() => handleSearchMongo("deviceID")} >By deviceID </Button>
            <Button onClick={() => handleSearchMongo("wifiApMacAddress")} >By wifiApMacAddress</Button>
          </ButtonGroup>
          <Button
            size="small"
            sx={{ marginLeft: "4vw" }}
            variant="outlined"
            onClick={() => handleSearchMongo(searchField)}
          >
            By other field
          </Button>
          <TextField
            size="small"
            label="Other field"
            sx={{ width: "10vw", marginLeft: "1vw" }}
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            autoFocus
          />
        </DivInline>
        <Divider10 />
        <DivInline>
          <Typography>[Firestore] Search value</Typography>
          <TextField
            size="small"
            sx={{ width: "12vw" }}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            autoFocus
          />
          <ButtonGroup size="small" variant="outlined">
            <Button onClick={() => handleSearchFS("dtkAdd")} >By dtkAdd </Button>
            <Button onClick={() => handleSearchFS("deviceID")} >By deviceID</Button>
            <Button onClick={() => handleSearchFS("wifiApMacAddress")} >By wifiApMacAddress</Button>
          </ButtonGroup>
          <Button
            size="small"
            sx={{ marginLeft: "4vw" }}
            variant="outlined"
            onClick={() => handleSearchFS(searchField)}
          >
            By other field
          </Button>
          <TextField
            size="small"
            label="Other field"
            sx={{ width: "10vw", marginLeft: "1vw" }}
            value={searchField}
            onChange={(e) => setSearchField(e.target.value)}
            autoFocus
          />
        </DivInline>
        <Divider10 />
        <DivInline>
          <Typography>Search map</Typography>
          <TextField
            label="mapID"
            sx={{ width: "14vw" }}
            value={searchMapID}
            onChange={(e) => setSearchMapID(e.target.value)}
            autoFocus
          />
          <Button variant="outlined" onClick={() => handleSearchMongoMap()}>
            Search mongo
          </Button>
          <Button variant="outlined" onClick={() => handleSearchFSMap()}>
            Search firestore
          </Button>
        </DivInline>
        <Divider10 />
        <DivInline>
          {(searchResult || []).map((obj, key) => (
            <DeviceCard
              obj={obj}
              key={key}
              chooseMap={(mid) => {
                setOpenSearchModal(false);
                chooseMap(mid);
              }}
            />
          ))}
          <IconButtonDelete onBtnClick={() => setSearchResult([])} />
        </DivInline>
      </ModalSM>
    </>
  );
}

function DeviceCard({ obj, chooseMap }) {
  const { deviceType } = obj;
  if (deviceType === "serial")
    return <LightCard obj={obj} chooseMap={chooseMap} />;
  if (deviceType === "sensor")
    return <SensorCard obj={obj} chooseMap={chooseMap} />;
  if (deviceType === "daliCtl")
    return <DaliCtlCard obj={obj} chooseMap={chooseMap} />;
  if (deviceType === "gateway")
    return <GatewayCard obj={obj} chooseMap={chooseMap} />;
  if (deviceType === "map") {
    return <MapCard obj={obj} chooseMap={chooseMap} />;
  }
}

function LightCard({ obj, chooseMap }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll } = state;
  const { serial, mapID } = obj;
  return (
    <Box sx={{ border: "1px solid #fff" }}>
      <JsonModalContainer jsonData={obj}>
        <ButtonSM onClick={() => { }} >{`Serial: ${serial} {...}`}</ButtonSM>
      </JsonModalContainer>
      <Typography display="block" variant="caption">
        Description: {obj.description}
      </Typography>
      <Typography display="block" variant="caption">
        Style: {obj.style}
      </Typography>
      <Typography display="block" variant="caption">
        Type: {JSON.stringify(obj.type || {})}
      </Typography>
      <Typography variant="caption" display={obj.zigbeeAdd ? "block" : "none"}>
        Zigbee Address: {obj.zigbeeAdd}
      </Typography>
      <Typography display="block" variant="caption">DTK Address: {obj.dtkAdd}</Typography>
      <Typography display="block" variant="caption">DTK Channel: {obj.dtkChannel}</Typography>
      <Typography display="block" variant="caption">Mesh255 Channel: {obj.meshChannel}</Typography>
      <Typography display="block" variant="caption">
        wifiApMacAddress: {obj.wifiApMacAddress}
      </Typography>
      <Typography display="block" variant="caption">
        Gateway ID: {obj.gatewayID}
      </Typography>
      <Typography display="block" variant="caption">
        Server ID: {obj.serverID}
      </Typography>
      <Typography display="block" variant="caption">
        In Map:
      </Typography>
      <DivInline>
        {(mapID || []).map((mid, key) => (
          <Box
            sx={{
              cursor: "pointer",
              outline: "1px solid #fff",
              margin: "0.5vw",
              "&:hover": { outlineColor: "yellow", color: "yellow" },
            }}
            onClick={() => chooseMap(mid)}
            key={key}
          >
            <Typography variant="body2">{`${mid}`}</Typography>
            <Typography variant="caption">{mapObjAll[mid]?.mapName}</Typography>
          </Box>

        ))}
      </DivInline>
    </Box>
  );
}
function SensorCard({ obj, chooseMap }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll } = state;
  const { sensorID, mapID } = obj;
  return (
    <Box sx={{ border: "1px solid #fff" }}>
      <Typography display="block">
        Sensor ID: {sensorID}
        <JsonModal jsonData={obj} btnContent={<DataObject />} show />
      </Typography>
      <Typography display="block" variant="caption">
        Sensor Name: {obj.sensorName}
      </Typography>
      <Typography display="block" variant="caption">
        Type: {obj.type}
      </Typography>
      <Typography variant="caption" display={obj.zigbeeAdd ? "block" : "none"}>
        Zigbee Address: {obj.zigbeeAdd}
      </Typography>
      <Typography display="block" variant="caption">DTK Address: {obj.dtkAdd}</Typography>
      <Typography display="block" variant="caption">DTK Channel: {obj.dtkChannel}</Typography>
      <Typography display="block" variant="caption">Mesh255 Channel: {obj.meshChannel}</Typography>
      <Typography display="block" variant="caption">
        wifiApMacAddress: {obj.wifiApMacAddress}
      </Typography>
      <Typography display="block" variant="caption">
        Gateway ID: {obj.gatewayID}
      </Typography>
      <Typography display="block" variant="caption">
        Server ID: {obj.serverID}
      </Typography>
      <Typography display="block" variant="caption">
        In Map:
      </Typography>
      <DivInline>
        {(mapID || []).map((mid) => (
          <Box
            sx={{
              cursor: "pointer",
              outline: "1px solid #fff",
              margin: "0.5vw",
              "&:hover": { outlineColor: "yellow", color: "yellow" },
            }}
            onClick={() => chooseMap(mid)}
            key={mid}
          >
            <Typography variant="body2">{`${mid}`}</Typography>
            <Typography variant="caption">
              {mapObjAll?.[mid]?.mapName}
            </Typography>
          </Box>
        ))}
      </DivInline>
    </Box>
  );
}
function DaliCtlCard({ obj, chooseMap }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll } = state;
  const { daliCtlID, mapID } = obj;
  return (
    <Box sx={{ border: "1px solid #fff" }}>
      <Typography display="block">
        DaliCtl ID: {daliCtlID}
        <JsonModal jsonData={obj} btnContent={<DataObject />} show />
      </Typography>
      <Typography display="block" variant="caption">
        Description: {obj.description}
      </Typography>
      <Typography display="block" variant="caption">
        Dali Type: {obj.daliType}
      </Typography>
      <Typography variant="caption" display={obj.zigbeeAdd ? "block" : "none"}>
        Zigbee Address: {obj.zigbeeAdd}
      </Typography>
      <Typography display="block" variant="caption">DTK Address: {obj.dtkAdd}</Typography>
      <Typography display="block" variant="caption">DTK Channel: {obj.dtkChannel}</Typography>
      <Typography display="block" variant="caption">Mesh255 Channel: {obj.meshChannel}</Typography>
      <Typography display="block" variant="caption">
        wifiApMacAddress: {obj.wifiApMacAddress}
      </Typography>
      <Typography display="block" variant="caption">
        Gateway ID: {obj.gatewayID}
      </Typography>
      <Typography display="block" variant="caption">
        Server ID: {obj.serverID}
      </Typography>
      <Typography display="block" variant="caption">
        In Map:
      </Typography>
      <DivInline>
        {(mapID || []).map((mid, key) => (
          <Box
            sx={{
              cursor: "pointer",
              outline: "1px solid #fff",
              margin: "0.5vw",
              "&:hover": { outlineColor: "yellow", color: "yellow" },
            }}
            onClick={() => chooseMap(mid)}
            key={key}
          >
            <Typography variant="body2">{`${mid}`}</Typography>
            <Typography variant="caption">{mapObjAll[mid]?.mapName}</Typography>
          </Box>
        ))}
      </DivInline>
    </Box>
  );
}
function GatewayCard({ obj, chooseMap }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll } = state;
  const { gatewayID, description, zigbeeAdd, zigbeeChannel, mapID, serverID } =
    obj;
  return (
    <Box sx={{ border: "1px solid #fff" }}>
      <Typography display="block">
        Gateway ID: {gatewayID}
        <JsonModal jsonData={obj} btnContent={<DataObject />} show />
      </Typography>
      <Typography display="block" variant="caption">
        Description: {description}
      </Typography>
      <Typography variant="caption" display={obj.zigbeeAdd ? "block" : "none"}>
        Zigbee Address: {zigbeeAdd}
      </Typography>
      <Typography display="block" variant="caption">DTK Address: {obj.dtkAdd}</Typography>
      <Typography display="block" variant="caption">DTK Channel: {obj.dtkChannel}</Typography>
      <Typography display="block" variant="caption">Mesh255 Channel: {obj.meshChannel}</Typography>
      <Typography display="block" variant="caption">
        wifiApMacAddress: {obj.wifiApMacAddress}
      </Typography>
      <Typography display="block" variant="caption">
        Server ID: {serverID}
      </Typography>
      <Typography display="block" variant="caption">
        In Map:
      </Typography>
      <DivInline>
        {(mapID || []).map((mid, key) => (
          <Box
            sx={{
              cursor: "pointer",
              outline: "1px solid #fff",
              margin: "0.5vw",
              "&:hover": { outlineColor: "yellow", color: "yellow" },
            }}
            onClick={() => chooseMap(mid)}
            key={key}
          >
            <Typography variant="body2">{`${mid}`}</Typography>
            <Typography variant="caption">
              {mapObjAll?.[mid]?.mapName}
            </Typography>
          </Box>
        ))}
      </DivInline>
    </Box>
  );
}
function MapCard({ obj, chooseMap }) {
  const [state, dispatch] = useContext(SMContext);
  const { mapObjAll } = state;
  const { mapID, mapName, mapUrl, mapFileName } = obj;
  let bgUrl = mapFileName ? `${global.ip}/img/${mapFileName}` : mapUrl;
  if (state.enableFS) bgUrl = mapUrl;
  return (
    <Box sx={{ border: "1px solid #fff", padding: "1vh" }}>
      <Typography display="block">
        Map ID: {mapID}
        <JsonModal jsonData={obj} btnContent={<DataObject />} show />
      </Typography>
      <Typography display="block" variant="caption">
        Map Name: {mapName}
      </Typography>
      <Typography display="block" variant="caption">
        Server ID: {obj.serverID}
      </Typography>
      <Typography display="block" variant="caption">
        Button size: {obj.buttonSize}
      </Typography>
      <Box
        onClick={() => chooseMap(mapID)}
        sx={{
          outline: "2px solid #fff",
          cursor: "pointer",
          "&:hover": { outlineColor: "yellow" },
          width: "15vw",
          height: "20vh",
          backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9) ), url(${bgUrl})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Box>
    </Box>
  );
}
