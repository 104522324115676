import { Typography, Tooltip } from "@mui/material";
import DivInline from "./DivInline";
import Space from "./Space";
import { AlternateEmail, Router, WifiChannel } from "@mui/icons-material";
import DeviceIcon from "./DeviceIcon";
import { deviceInfoOfObj } from "actions/generalActions";
import DivExist from "./DivExist";

export default function DeviceIdAddGwLine({ deviceID, address, gatewayID, withIconAndName = false, deviceObj }) {
    const { deviceName, deviceType } = deviceInfoOfObj(deviceObj);
    let deviceStyle = "";
    if (deviceType == "light") {
        deviceStyle = deviceObj.style;
    } else if (deviceType == "sensor") {
        deviceStyle = deviceObj.type;
    }
    return (
        <>
            {
                withIconAndName &&
                <DivInline>
                    <DeviceIcon deviceType={deviceType} deviceStyle={deviceStyle} width={2.5} />
                    <Typography variant="caption" sx={{ marginLeft: "1vw" }}>{deviceName}</Typography>
                </DivInline>
            }
            <DivInline>
                <Typography variant="caption">{`\u{01f194}${deviceID}`}</Typography>
                <Space width={10} />
                <AlternateEmail sx={{ fontSize: "1rem" }} />
                <Tooltip title="Address">
                    <Typography variant="caption">{address || "-"}</Typography>
                </Tooltip>
                <Space width={10} />
                <Router sx={{ fontSize: "1rem" }} />
                <Tooltip title="Gateway ID"><Typography variant="caption">{gatewayID}</Typography></Tooltip>
                <Space width={10} />
                <DivExist show={deviceObj?.meshChannel > 0 || deviceObj?.meshChannel === 0}>
                    <WifiChannel sx={{ fontSize: "1rem" }} />
                    <Tooltip title="Mesh Channel"><Typography variant="caption">{deviceObj?.meshChannel}</Typography></Tooltip>
                    <Space width={10} />
                </DivExist>
            </DivInline>

        </>
    )
}