import Gateway from "asset/svgComp/Gateway";

export default function GatewayIcon({
  // gatewayID,
  width,
  // height,
  connectStatus,
  // style,
  disabledConnectStatus,
  sizeUnit,
  // backgroundColor,
}) {
  return (
    <div>
      <Gateway
        width={width}
        sizeUnit={sizeUnit}
        connectStatus={connectStatus}
        disabledConnectStatus={disabledConnectStatus}
      />
    </div>
  );
}
