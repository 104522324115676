export default function FireAlarm({
  width,
  height,
  sizeUnit,
  status,
  connectStatus,
  disabledConnectStatus,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g>
        <path
          fill="#fff"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="7"
          d="M0 0.196H79.375V79.571H0z"
        ></path>
        <path
          fill={status === 1 ? "#f00" : "#b3b3b3"}
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.844"
          d="M38.053 63.564c-3.791-.694-7.413-1.745-10.087-4.78-1.355-2.11-2.844-4.036-3.294-7.37-.021-2.476-.032-4.957 1.235-7.967 1.111-2.116 1.85-4.134 4.94-6.772.306 1.81.424 3.712 1.442 5.178.739 1.21 1.784 2.125 3.088 2.789-.498-2.793-1.719-4.987-1.03-8.764.78-2.205 1.594-4.43 3.295-7.17a339.46 339.46 0 014.117-5.378c.661 2.185 1.084 4.524 2.265 6.373.947 2.466 1.993 4.867 3.5 6.972 1.614 2.62 3.153 5.315 4.94 7.768.942 1.577 1.682 3.22 2.06 4.979.157 2.221-.246 4.334-1.03 6.374-.878 1.675-2.093 3.286-3.912 4.78-.944 1.125-2.651 1.807-4.529 2.39-1.452.625-4.05.696-7 .598z"
        ></path>
        <path
          fill={status === 1 ? "#ff0" : "#666"}
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth="0.383"
          d="M38.856 63.695c-1.73-.314-3.381-.79-4.601-2.164-.618-.955-1.297-1.826-1.503-3.335-.01-1.121-.014-2.244.564-3.606.507-.958.844-1.871 2.254-3.065.139.82.193 1.68.657 2.343.337.548.814.962 1.409 1.263-.228-1.264-.784-2.257-.47-3.967.355-.998.727-2.005 1.503-3.245a153.78 153.78 0 011.878-2.435c.301.99.494 2.048 1.033 2.885.432 1.116.909 2.203 1.596 3.156.736 1.186 1.438 2.405 2.254 3.515.43.714.767 1.458.939 2.254.072 1.006-.112 1.962-.47 2.885a6.892 6.892 0 01-1.784 2.164c-.43.509-1.21.817-2.066 1.081-.662.283-1.847.316-3.193.27z"
        ></path>
        <ellipse
          cx="39.958"
          cy="39.982"
          fill="none"
          stroke={status === 1 ? "#d40000" : "#808080"}
          strokeDasharray="none"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth="10"
          rx="34.021"
          ry="32.66"
        ></ellipse>
        <path
          stroke={status === 1 ? "#d35f5f" : "#e6e6e6"}
          fill="none"
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeMiterlimit="4"
          strokeWidth="10"
          d="M39.8 72.414a34.02 32.66 0 01-29.464-16.33 34.02 32.66 0 010-32.66A34.02 32.66 0 0139.8 7.094"
        ></path>
      </g>
      {!disabledConnectStatus && (
        <circle r="6" cx="7" cy="7" fill={connectStatus ? "green" : "red"} />
      )}
    </svg>
  );
}
