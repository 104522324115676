import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Slider, Tooltip, Checkbox } from "@mui/material";
import { Layers } from "@mui/icons-material";
import { cursor, sensorStyleMenu, zoneCtlDefault } from "asset/string/string";
import DivInline from "components/DivInline";
import SensorIcon from "components/SensorIcon";
import ZoneControlIcon from "components/ZoneControlIcon";
import Divider10 from "components/Divider10";
import { saveSensorLockLevel } from "actions/sensorActions";
import { saveZcLockLevel } from "actions/zcActions";
import DivExist from "components/DivExist";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import MapTopBtn from "containers/home/MapTopBtn";
const gs = new General();
const marks = [
  { value: 1, label: "1:HIGH priority" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5:LOW priority" },
];
export default function LockLevelSettingModal({ btnSize, disabled }) {
  const [state] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const { activeMapID, sensorObjAll, zoneControlObjAll, mapObjAll } = state;
  const mapObj = mapObjAll[activeMapID] || {};
  const { zoneControlObj, sensorObj, mapName } = mapObj || {};
  const sensorArray = Object.keys(sensorObj || {}).sort((a, b) =>
    sensorObjAll[a]?.sensorName?.localeCompare(sensorObjAll[b]?.sensorName)
  );
  const zoneControlArray = Object.keys(zoneControlObj || {}).sort((a, b) =>
    zoneControlObjAll[a]?.zoneControlName?.localeCompare(
      zoneControlObjAll[b]?.zoneControlName
    )
  );
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <MapTopBtn
        tooltip="Control Priority Setting"
        btnSize={btnSize}
        disabled={disabled}
        btnClick={handleOpen}
      >
        <Layers />
      </MapTopBtn>
      {/* <Tooltip title={<Typography>Control Priority Setting</Typography>}>
        <Layers
          sx={{
            margin: "5px",
            width: btnSize,
            height: btnSize,
            cursor: disabled ? cursor.notAllowed : cursor.pointer,
            color: disabled ? "grey" : "",
          }}
          onClick={handleOpen}
        />
      </Tooltip> */}
      <ModalSM
        modalTitle={`Control Priority Setting- [${mapName}]`}
        modalIcon={<Layers />}
        open={open}
        onClose={handleClose}
        width="97vw"
        height="96vh"
      >
        <div>
          <Typography variant="h6">
            Sensors/Physical Buttons, Switches
          </Typography>
          <Divider10 />
          {sensorArray.map((s) => (
            <SensorLockSettingRow key={s} sensorID={s} />
          ))}
          <DivExist show={sensorArray.length <= 0}>
            <Typography>No sensor/switch in this map</Typography>
          </DivExist>
          <Divider10 />

          <Typography variant="h6">Vitural Zone Control</Typography>
          <Divider10 />
          {zoneControlArray.map((s) => (
            <ZcLockSettingRow key={s} zoneControlID={s} />
          ))}
          <DivExist show={zoneControlArray.length <= 0}>
            <Typography>No vitural zone control item in this map</Typography>
          </DivExist>
        </div>
      </ModalSM>
    </>
  );
}

function SliderRow({ sensorID, buttonNo }) {
  const [state, dispatch] = useContext(SMContext);
  const { sensorObjAll } = state;
  const sensorObj = sensorObjAll[sensorID];
  const { type, gang } = sensorObj || {};
  let typeName = sensorStyleMenu[type];
  let lockLv = 5;
  let lockLvOff = 0;
  if (type === "motion" || type === "daylight" || type === "daylightmotion") {
    lockLv = sensorObj.lockLevelSet1;
  } else if (type === "scenebutton") {
    lockLv = sensorObj[`lockLevelSet${buttonNo}`];
    if (gang > 1) typeName += `[Button${buttonNo}]`;
  } else if (type === "lightswitch") {
    lockLv = sensorObj[`lockLevelSet${buttonNo * 2}`];
    lockLvOff = sensorObj[`lockLevelSet${buttonNo * 2 - 1}`];
    if (gang > 1) typeName += ` [Button${buttonNo}]`;
  }
  return (
    <DivInline>
      <DivInline sx={{ width: "14vw" }}>
        <SensorIcon
          status={buttonNo === 1 ? 1 : 0}
          status2={buttonNo === 2 ? 1 : 0}
          status3={buttonNo === 3 ? 1 : 0}
          status4={buttonNo === 4 ? 1 : 0}
          type={type}
          width={3.2}
          sizeUnit="vw"
          backgroundColor="#fff"
          disabledConnectStatus
          gang={gang}
        />
        <Typography sx={{ marginLeft: "0.5vw" }} variant="caption">
          {typeName}
        </Typography>
      </DivInline>
      <Slider
        sx={{ width: "36vw", marginLeft: "5vw", marginRight: "4vw" }}
        color="secondary"
        value={lockLv}
        onChange={async (evt, newVal) => {
          await saveSensorLockLevel(
            sensorID,
            sensorObj,
            [buttonNo],
            [newVal],
            [sensorObj[`releaseOnDelayEnd${buttonNo}`]]
          );
        }}
        aria-labelledby="discrete-slider"
        marks={marks}
        max={5}
        min={1}
        step={1}
      />
      <DivExist
        show={
          type === "lightswitch" ||
          type === "daylightmotion" ||
          type === "motion" ||
          type === "scenebutton"
        }
      >
        <Typography variant="caption" sx={{ marginLeft: "1vw", width: "10vw" }}>
          Release light priority level on switch off
        </Typography>
        <Checkbox
          onChange={(e) => {
            const releaseVal = e.target.checked ? 1 : 0;
            saveSensorLockLevel(
              sensorID,
              sensorObj,
              [buttonNo],
              [lockLv],
              [releaseVal]
            );
          }}
          checked={sensorObj[`releaseOnDelayEnd${buttonNo}`] ? true : false}
        />
      </DivExist>
    </DivInline>
  );
}

// lock level 5 is same as 100 //not use 100 anymore
function SensorLockSettingRow({ sensorID }) {
  const [state, dispatch] = useContext(SMContext);
  const { sensorObjAll, socket } = state;
  const sensorObj = sensorObjAll[sensorID];
  const { type, sensorName, gang } = sensorObj || {};

  return (
    <>
      <DivInline>
        <div style={{ width: "22vw" }}>
          <Typography
            sx={{ wordBreak: "break-word" }}
            variant={sensorName?.length > 24 ? "caption" : "body2"}
          >
            {sensorName}
          </Typography>
          <Typography display="block" variant="caption">
            {sensorID}
          </Typography>
        </div>
        <div>
          {gs.newArrayBetween(1, gang || 1).map((n) => (
            <SliderRow key={n} sensorID={sensorID} buttonNo={n} />
          ))}
        </div>
      </DivInline>
      <Divider10 space={5} />
    </>
  );
}

function ZcLockSettingRow({ zoneControlID }) {
  const [state, dispatch] = useContext(SMContext);
  const { zoneControlObjAll, serverObj, socket } = state;
  const zoneControlObj = zoneControlObjAll[zoneControlID] || {};
  const { type, lockLevelSet1, lockLevelSet2, zoneControlName } =
    zoneControlObj;
  const lockLvSetUse = type === "timer" ? lockLevelSet2 : lockLevelSet1;

  return (
    <>
      <DivInline>
        <div style={{ width: "22vw" }}>
          <Typography
            variant={zoneControlName?.length > 24 ? "caption" : "body2"}
          >
            {zoneControlName}
          </Typography>
          <Typography variant="caption">{zoneControlID}</Typography>
        </div>

        <div>
          <DivInline>
            <div style={{ width: "14vw", display: "flex" }}>
              <ZoneControlIcon
                // iconType={iconType1}
                type={type}
                width={3.2}
                sizeUnit="vw"
                backgroundColor="#fff"
                tooltip={zoneControlName}
              />
              <Typography sx={{ marginLeft: "0.5vw" }} variant="caption">
                {zoneCtlDefault[type]?.typeName}
              </Typography>
            </div>

            <Slider
              style={{ width: "36vw", marginLeft: "5vw", marginRight: "4vw" }}
              color="secondary"
              value={lockLvSetUse}
              onChange={async (evt, newVal) => {
                if (type === "timer" && lockLevelSet1 === 0)
                  await saveZcLockLevel(
                    zoneControlID,
                    zoneControlObj,
                    [2],
                    [newVal]
                  );
                if (type === "timer" && lockLevelSet1 !== 0)
                  await saveZcLockLevel(
                    zoneControlID,
                    zoneControlObj,
                    [1, 2],
                    [newVal, newVal]
                  );
                if (type === "zcSceneButton")
                  await saveZcLockLevel(
                    zoneControlID,
                    zoneControlObj,
                    [1],
                    [newVal]
                  );
              }}
              marks={marks}
              max={5}
              min={1}
              step={1}
            />
            <DivExist show={type === "timer"}>
              <Typography
                variant="caption"
                sx={{ marginLeft: "1vw", width: "10vw" }}
              >
                Set light to lowest priority level on timer end
              </Typography>
              <Checkbox
                onChange={(e) => {
                  const newLv = e.target.checked ? 0 : lockLevelSet2;
                  console.log(newLv);
                  saveZcLockLevel(zoneControlID, zoneControlObj, [1], [newLv]);
                }}
                checked={lockLevelSet1 === 0}
              />
            </DivExist>
          </DivInline>
        </div>
      </DivInline>
      <Divider10 space={5} />
    </>
  );
}
