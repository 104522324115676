import React from "react";
import { Ellipse, Circle, Group, Path } from "react-konva";

export default function GearChecking({ x, y, size }) {
  const scale = size / 52.9;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill="gray"
        data="M29.089 25.976l2.526 2.303.594-.817L47.585 40.46l-3.491 3.714-15.302-13 .668-.594-2.451-2.154z"
      ></Path>
      <Circle
        x={16.985}
        y={17.877}
        radius={13.045}
        stroke="#8e4399"
        strokeWidth={3.11}
      ></Circle>
      <Path
        fill="#00aac5"
        data="M5.453 28.224l-2.487.742-.087 3.315 4.275 1.003c-.049 1.117.788 2.168 1.134 2.836l-2.137 3.359 2.006 2.268 3.883-2.007c1.221.814 2.132 1.008 3.01 1.135l.916 3.926h3.359l.96-3.883c1.118-.263 2.192-.57 2.966-1.178l3.577 1.963 2.268-2.224-2.05-3.447c.542-.902.998-1.933 1.308-3.184l-1.046-.96.567-.785-1.222-1.178c-1.564 1.206-3.309 2.195-5.453 2.705-.928 1.066-2.053 1.74-3.446 1.876-1.5-.059-2.614-.656-3.533-1.527-3.35-.244-6.233-1.953-8.768-4.755zM8.42 19.368l-1.615 1.483c.148 1.685 1.484 3.272 3.01 4.842 1.459 1.304 3.02 2.143 4.711 2.4.806-.698 1.684-1.276 2.836-1.396 1.134.058 2.094.407 2.879 1.047 2.08-.591 3.662-1.752 4.93-3.272.909-1.412 1.876-2.788 2.224-4.537l-.567-.523c-1.442.731-2.862 1.466-3.621 2.312-.791-.601-1.502-1.269-2.966-1.31l-1.047-3.969h-3.272l-.916 4.057c-1.111-.038-1.944.623-2.835 1.134z"
      ></Path>
      <Path
        fill="#37c8ab"
        data="M30.07 9.917c1.279 2.542 2.6 5.074 2.61 7.909-.224 2.687-.97 4.765-1.787 6.76l1.508-.96c.574.529 1.235.841 2.01.868l.64 2.65 2.24.047.73-2.697c.619-.179 1.161-.13 1.966-.868l2.422 1.508 1.645-1.554-1.463-2.422c.422-.604.803-1.216.732-1.919l2.879-.731-.092-2.102-2.605-.64c-.13-.7-.333-1.401-.96-2.102l1.417-2.24-1.6-1.507-2.375 1.37c-.582-.276-.535-.678-2.011-.776l-.731-2.605h-2.24l-.548 2.376c-.716.083-1.432.431-2.148.914z"
      ></Path>
      <Ellipse
        x={36.256}
        y={17.403}
        fill="#fff"
        radiusX={2.8}
        radiusY={2.8}
      ></Ellipse>
    </Group>
  );
}
