import DivCol from "./DivCol";
import moment from "moment";
import { Typography, Tooltip } from "@mui/material";
import Divider10 from "./Divider10";
import { AlternateEmail } from "@mui/icons-material";
import DivInline from "./DivInline";
import ButtonSM from "./ButtonSM";
import Gateway from "asset/svgComp/Gateway";
export default function CardGateway({ gatewayObj, handleSave, disableClick, iconSize = 7, iconSizeUnit = "vh", tooltip = "Add to map" }) {
    const { gatewayID, description, wifiApMacAddress, createTimeString, gatewayAliveTimeStamp } = gatewayObj || {};
    const defaultClick = () => { }
    return (
        <ButtonSM tooltip={tooltip} onClick={handleSave || defaultClick} disabled={disableClick}>
            <DivCol sx={{ outline: "1px solid #fff", padding: "5px", margin: "5px" }}>
                <Typography variant="body2">{`\u{01f194}${gatewayID}`}</Typography>
                <Gateway width={iconSize} sizeUnit={iconSizeUnit} disabledConnectStatus />
                <Typography variant="body2">{description || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Wifi Ap Mac</Typography>
                <Typography variant="body2">{wifiApMacAddress || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Create time</Typography>
                <Typography variant="body2">{createTimeString || "-"}</Typography>
                <Divider10 space={5} />
                <Typography variant="caption">Last update time</Typography>
                <Typography variant="body2">{moment(gatewayAliveTimeStamp).format("YYYY-MM-DD HH:mm:ss") || "-"}</Typography>
            </DivCol>
        </ButtonSM>
    )
}