import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import Divider10 from "components/Divider10";
import {
  // updateZcAllTimeSetting, 
  updateZcProperty
} from "actions/zcActions";
import General from "@ecoenghk/general";
import AdminContainer from "components/AdminContainer";
import ServerConsoleLogFilter from "containers/server/ServerConsoleLogFilter";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
const gs = new General();

export default function ZcSuperAdminPanel() {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeZoneControlID,
    zoneControlObjAll,
  } = state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  return (
    <AdminContainer>
      <Typography variant="h6" align="center">
        Super admin
      </Typography>
      <Divider10 />
      <SpaceBetweenSwitch
        title="Enable group action"
        data={zcObj?.enableGroupAction ? true : false}
        onChange={async (e) => {
          let val = e.target.checked;
          await updateZcProperty(activeZoneControlID, { enableGroupAction: val });
        }}
      />
      <Divider10 />
      {/* <SpaceBetweenButton
        title="Update all time setting to gateway"
        btnContent={<ArrowForwardIos />}
        onBtnClick={async () => await updateZcAllTimeSetting(zcObj)}
        marginBottom={"0.5vh"}
      /> */}
      <ServerConsoleLogFilter filterString={activeZoneControlID} />
    </AdminContainer>
  );
}
