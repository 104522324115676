import { Group, Path, Ellipse } from "react-konva";
import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";

export default function Tube({
  x,
  y,
  size,
  statusL,
  lightLv,
  disabledStatus,
  typeBatVI,
  shadEnabled,
}) {
  const scale = size / 79.375;
  if (!typeBatVI) statusL = 0;
  const lightColor = lightColorAt(disabledStatus, statusL, lightLv);
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      <Ellipse
        x={40}
        y={40}
        fill={lightColor}
        radiusX={45}
        radiusY={25}
        rotation={-45}
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={2}
        shadowOffset={{ x: 5, y: 5 }}
        shadowOpacity={0.5}
        perfectDrawEnabled={false}
      />
      <Path
        stroke="#22b3fd"
        strokeWidth={2.784}
        data="M6.629 63.206l8.41 8.938 56.025-55.84-8.41-8.937z"
        shadowEnabled={false}
        perfectDrawEnabled={false}
      />
      <Path
        stroke="#918a6f"
        strokeWidth={2.784}
        data="M2.255 67.522l4.374-4.316 8.41 8.938-4.494 4.607z"
        perfectDrawEnabled={false}
      />
      <Path
        stroke="#918a6f"
        strokeWidth={2.784}
        data="M6.19 76.906l1.672-1.622"
        perfectDrawEnabled={false}
      />
      <Path
        stroke="#918a6f"
        strokeWidth={2.784}
        data="M1.865 72.3l1.673-1.622"
        perfectDrawEnabled={false}
      />
      <Path
        stroke="#918a6f"
        strokeWidth={2.784}
        data="M75.436 11.989l-4.374 4.316-8.409-8.938 4.494-4.608z"
        perfectDrawEnabled={false}
      />
      <Path
        stroke="#918a6f"
        strokeWidth={2.784}
        data="M75.826 7.21l-1.673 1.622"
        perfectDrawEnabled={false}
      />
      {(!disabledStatus && typeBatVI) ? (
        <ChargeIcon y={15} x={-15} statusL={statusL} />
      )
        :
        null

      }
    </Group>
  );
}
