import { useContext, useRef } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { useState } from "react";
import { Typography, Tooltip, Button, Box } from "@mui/material";
import { Details } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import moment from "moment";
import { ViewportList } from "react-viewport-list";
import IconButtonBack from "components/IconButtonBack";
import { hideBigProgress, showBigProgress } from "actions/screenActions";
import { LightHistoryItem } from "./LightHistoryItem";
import { fetchLightHistory } from "actions/lightActions";
import AdminContainer from "components/AdminContainer";

const w = window.innerWidth;
const h = window.innerHeight;
export default function DetailHistoryModal() {
  const [state, dispatch] = useContext(SMContext);
  const ref = useRef(null);
  const { activeSerial, lightObjAll } = state;
  const [open, setOpen] = useState(false);
  const [openJson, setOpenJson] = useState(false);
  const [data, setData] = useState([]);
  const [showRegularUpload, setShowRegularUpload] = useState(false);
  const [json, setJson] = useState({});
  async function fetchData(hr) {
    setData([]);
    showBigProgress(dispatch);
    let arr = await fetchLightHistory(activeSerial, moment().subtract(hr, "hours").valueOf(), moment().valueOf());
    arr = arr
      .filter((obj) => obj.uploadProtocol !== "gw_zigbeeConnected")
      .sort((a, b) => b.timeStamp - a.timeStamp);
    console.log(arr);
    setData(arr);
    hideBigProgress(dispatch);
  }
  let tableData = [...data];
  if (!showRegularUpload) {
    tableData = tableData.filter(
      (obj) =>
        obj.uploadProtocol !== "p0p0" &&
        obj.uploadProtocol !== "d2d2" &&
        obj.uploadProtocol !== "d3d3" &&
        obj.uploadProtocol !== "regular_serial"
    );
  }
  const lightObj = lightObjAll[activeSerial] || {};
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        <Tooltip title="More history">
          <Details />
        </Tooltip>
      </Button>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        width="90vw"
        height="95vh"
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography sx={{ marginRight: "2vw" }}>History</Typography>
          <Button size="small" variant="outlined" onClick={() => fetchData(1)}>
            1 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(2)}>
            2 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(4)}>
            4 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(8)}>
            8 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(12)}>
            12 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => fetchData(24)}>
            24 hour
          </Button>
          <Button size="small" variant="outlined" onClick={() => setData([])}>
            Clear
          </Button>
          <AdminContainer>
            <Button
              size="small"
              variant="outlined"
              onClick={() => setShowRegularUpload((s) => !s)}
            >
              {showRegularUpload ? "Hide" : "Show"} regular
            </Button>
          </AdminContainer>
        </DivInline>
        <Divider10 />
        <div className="scroll-container" ref={ref}>
          <ViewportList viewportRef={ref} items={tableData}>
            {(d, index) => {
              const m = moment(d.timeStamp);
              const dataDate = m.format("YYYY-MM-DD");
              const dataTime = m.format("HH:mm:ss");
              let uploadProtocolStr = d.uploadProtocol;
              if (typeof d.uploadProtocol === 'number') {
                uploadProtocolStr = d.uploadProtocol.toString(16).padStart(4, "0");
              }
              return (
                <Box key={index} sx={{ display: "flex" }}>
                  <Typography variant="caption" sx={{ width: 0.1 * w }}>
                    {index}
                  </Typography>
                  <Typography variant="caption" sx={{ width: 0.15 * w }}>
                    {dataDate}
                  </Typography>
                  <Typography variant="caption" sx={{ width: 0.15 * w }}>
                    {dataTime}
                  </Typography>
                  <Typography variant="caption" sx={{ width: 0.1 * w }}>
                    {uploadProtocolStr}
                  </Typography>
                  <Box
                    sx={{ width: 0.55 * w, cursor: "pointer", display: "flex" }}
                    onClick={() => {
                      if (state.userObj.level < 1) {
                        setOpenJson(true);
                        setJson(d);
                      }
                    }}
                  >
                    <LightHistoryItem data={d} iconOnly lightObj={lightObj} />
                    <Typography sx={{ marginLeft: "1vw" }} variant="caption">
                      <LightHistoryItem data={d} messageOnly lightObj={lightObj} />
                    </Typography>
                  </Box>
                </Box>
              );
            }}
          </ViewportList>
        </div>

      </ModalSM>
      {/* <TimeDataModal
        open={openJson}
        json={json}
        handleClose={() => setOpenJson(false)}
      /> */}
    </>
  );
}

// function TimeDataModal({ open, json, handleClose }) {
//   delete json["_id"];
//   const m = moment(json.timeStamp);
//   return (
//     <>
//       <ModalSM open={open} onClose={handleClose}>
//         <Box sx={{ padding: "0 2vw 0 2vw" }}>
//           <DivInline>
//             <IconButtonBack onBtnClick={handleClose} />
//             <Typography>{`[${json.uploadProtocol}] ${m.format(
//               "YYYY-MM-DD,HH:mm"
//             )}`}</Typography>
//           </DivInline>
//           <Divider10 />
//           {Object.keys(json || {})
//             .sort((a, b) => a.localeCompare(b))
//             .map((field) => (
//               <DivInline justifyContent="space-between">
//                 <Typography>{field}</Typography>
//                 <Typography>{json[field]}</Typography>
//               </DivInline>
//             ))}
//         </Box>
//       </ModalSM>
//     </>
//   );
// }
