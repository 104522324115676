export default function Daylight({
  width,
  height,
  sizeUnit,
  luxLv,
  connectStatus,
  disabledConnectStatus,
  disabledSensor,
}) {
  const opacity = luxLv / 10;
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      {!disabledConnectStatus && (
        <circle r="6" cx="7" cy="7" fill={connectColor} />
      )}
      <g strokeLinecap="round">
        <circle
          cx="39.688"
          cy="39.688"
          r="28"
          fill="#918a6f"
          stroke="483737"
          strokeWidth="4"
        ></circle>
        <circle
          cx="39.688"
          cy="39.688"
          r="28"
          fill="#ffcc00"
          opacity={opacity || 0}
        ></circle>
        <g fill="none" stroke="#ffcc00" strokeWidth="3.5">
          <path d="M40.119 7.168v-4.57M41.043 77.112v-4.57M72.231 41.275h4.57M2.381 41.275h4.57M11.377 65.029l3.766-2.587M67.266 65.029L63.5 62.442M11.377 14.288l3.766 2.587M67.266 14.288L63.5 16.875"></path>
        </g>
      </g>
    </svg>
  );
}
