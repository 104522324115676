import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Tooltip, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import { weekDayIsoMenu } from "asset/string/string";
import General from "@ecoenghk/general";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment from "moment";
import { deleteZcTimeSetting, updateZcTimeSetting } from "actions/zcActions";
import { confirmWindow } from "actions/screenActions";
import ModalSM from "components/ModalSM";
import SmallButton from "components/SmallButton";
import Divider10 from "components/Divider10";
import AdminContainer from "components/AdminContainer";
import DeviceMongoModal from "components/DeviceMongoModal";
const gs = new General();

export default function EditTimeSettingModal({
  open,
  onClose,
  timeTableObj1,
  timeTableObj2,
  timeTableObj3
}) {
  const [state, dispatch] = useContext(SMContext);
  const { activeZoneControlID, zoneControlObjAll, timeTableObjAll } = state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  const [momentS, setMomentS] = useState(moment());
  const [momentE, setMomentE] = useState(moment());
  const [momentI, setMomentI] = useState(moment());
  const [weekArrS, setWeekArrS] = useState([]);
  const [weekArrE, setWeekArrE] = useState([]);
  const [weekArrI, setWeekArrI] = useState([]);
  const [excludeHoliday, setExcludeHoliday] = useState(false);
  const [onlyHoliday, setOnlyHoliday] = useState(false);
  const [cronStrS, setCronStrS] = useState("");
  const [cronStrE, setCronStrE] = useState("");
  const [cronStrI, setCronStrI] = useState("");
  useEffect(() => {
    const sHr = timeTableObj2?.hour?.[0];
    const sMin = timeTableObj2?.minute?.[0];
    const eHr = timeTableObj1?.hour?.[0];
    const eMin = timeTableObj1?.minute?.[0];
    setMomentS(moment().hour(sHr ?? 0).minute(sMin ?? 0));
    setMomentE(moment().hour(eHr ?? 0).minute(eMin ?? 0));
    setWeekArrS(timeTableObj2?.isoWeekday);
    setWeekArrE(timeTableObj1?.isoWeekday);
    setExcludeHoliday(timeTableObj1?.excludeHoliday);
    setOnlyHoliday(timeTableObj1?.onlyHoliday);
    if (zcObj.enableSetting3) {
      const iHr = timeTableObj3?.hour?.[0];
      const iMin = timeTableObj3?.minute?.[0];
      setMomentI(moment().hour(iHr ?? 0).minute(iMin ?? 0));
      setWeekArrI(timeTableObj3?.isoWeekday);
    }
  }, [
    open,
    timeTableObj1?.hour,
    timeTableObj1?.minute,
    timeTableObj1?.isoWeekday,
    timeTableObj2?.hour,
    timeTableObj2?.minute,
    timeTableObj2?.isoWeekday,
    timeTableObj3?.hour,
    timeTableObj3?.minute,
    timeTableObj3?.isoWeekday,
    timeTableObj1?.excludeHoliday,
    timeTableObj1?.onlyHoliday,
    zcObj.enableSetting3

  ]);
  useEffect(() => {
    const hrS = momentS.hour();
    const minS = momentS.minute();
    const cronS = `${minS} ${hrS} * * ${weekArrS?.[0] === 100 ? "*" : weekArrS?.join(",")
      }`;
    const hrE = momentE.hour();
    const minE = momentE.minute();
    const cronE = `${minE} ${hrE} * * ${weekArrE?.[0] === 100 ? "*" : weekArrE?.join(",")
      }`;
    setCronStrS(cronS);
    setCronStrE(cronE);
    if (zcObj.enableSetting3) {
      const hrI = momentI.hour();
      const minI = momentI.minute();
      const cronI = `${minI} ${hrI} * * ${weekArrI?.[0] === 100 ? "*" : weekArrI?.join(",")
        }`;
      setCronStrI(cronI);
    }
  }, [momentS, momentE, momentI, weekArrS, weekArrE, weekArrI, zcObj.enableSetting3]);

  const handleSave = async () => {
    let updateObjE = {
      ...timeTableObj1,
      hour: [momentE.hour()],
      minute: [momentE.minute()],
      isoWeekday: weekArrE,
      excludeHoliday: excludeHoliday || false,
      onlyHoliday: onlyHoliday || false,
      cron: cronStrE,
    };
    let updateObjS = {
      ...timeTableObj2,
      hour: [momentS.hour()],
      minute: [momentS.minute()],
      isoWeekday: weekArrS,
      excludeHoliday: excludeHoliday || false,
      onlyHoliday: onlyHoliday || false,
      cron: cronStrS,
    };
    delete updateObjE._id;
    delete updateObjS._id;
    let updateObjArray = [updateObjE, updateObjS];
    let updateObjI = null;
    if (zcObj.enableSetting3) {
      updateObjI = {
        ...timeTableObj3,
        hour: [momentI.hour()],
        minute: [momentI.minute()],
        isoWeekday: weekArrI,
        cron: cronStrI,
      };
      updateObjArray.push(updateObjI);
    }
    console.log(updateObjArray);
    await updateZcTimeSetting(
      activeZoneControlID,
      zcObj,
      updateObjE,
      updateObjS,
      updateObjI
    );
    onClose();
  };
  const handleDelete = async () => {
    confirmWindow(dispatch, `Confirm delete this time setting?`, async () => {
      await deleteZcTimeSetting(
        activeZoneControlID,
        zcObj,
        timeTableObj1.timeTableID,
        timeTableObj2.timeTableID,
        timeTableObj3?.timeTableID
      );
      onClose();
    });
  };
  return (
    <ModalSM
      width="75vw"
      height="90vh"
      open={open}
      onClose={onClose}
      onSave={handleSave}
      onDelete={handleDelete}
      modalTitle={`Change time setting for ${zcObj.zoneControlName} [${zcObj?.zoneControlID}]`}
    >
      <DivInline justifyContent="center">

        <TimeSettingInput title="Timer Start" timeTableObj={timeTableObj2} moment={momentS} weekArr={weekArrS} setMoment={setMomentS} setWeekArr={setWeekArrS} />
        {/* <DivCol>
          <Tooltip title={timeTableObj2.timeTableID || ""}>
            <Typography>Timer Start</Typography>
          </Tooltip>
          <AdminContainer>
            <DeviceMongoModal deviceObj={timeTableObjAll[timeTableObj2.timeTableID]}>
              <Typography variant="caption">{timeTableObj2.timeTableID}</Typography>
            </DeviceMongoModal>
          </AdminContainer>
          <TimePicker
            sx={{ margin: "2vh 0" }}
            label="Start time"
            value={momentS}
            onChange={(newValue) => setMomentS(newValue)}
            ampm={false}
          />

          <DivInline sx={{ marginTop: "1vh" }}>
            {Object.keys(weekDayIsoMenu).map((i) => {
              return (
                <Wbutton
                  key={i}
                  content={weekDayIsoMenu[i]}
                  selected={weekArrS?.includes(Number(i))}
                  onClick={() => {
                    let newArr = [];
                    if (!weekArrS?.includes(Number(i))) {
                      newArr = [...weekArrS, Number(i)];
                    } else {
                      newArr = weekArrS.filter((j) => j !== Number(i));
                    }
                    newArr = newArr.sort((a, b) => a - b);
                    setWeekArrS(newArr);
                  }}
                />
              );
            })}
          </DivInline>
          <Wbutton
            content="All week"
            width="6vw"
            selected={gs.arrayEquals(weekArrS || [], [1, 2, 3, 4, 5, 6, 7])}
            onClick={() => {
              setWeekArrS([1, 2, 3, 4, 5, 6, 7]);
            }}
          />
        </DivCol> */}
        <ArrowForward />
        {zcObj.enableSetting3 && (
          <TimeSettingInput title="Timer Interval" timeTableObj={timeTableObj3} moment={momentI} weekArr={weekArrI} setMoment={setMomentI} setWeekArr={setWeekArrI} />
        )}
        <TimeSettingInput title="Timer End" timeTableObj={timeTableObj1} moment={momentE} weekArr={weekArrE} setMoment={setMomentE} setWeekArr={setWeekArrE} />
        {/* <DivCol>
          <Tooltip title={timeTableObj1.timeTableID || ""}>
            <Typography>Timer End</Typography>
          </Tooltip>
          <AdminContainer>
            <DeviceMongoModal deviceObj={timeTableObjAll[timeTableObj1.timeTableID]}>
              <Typography variant="caption">{timeTableObj1.timeTableID}</Typography>
            </DeviceMongoModal>
          </AdminContainer>
          <AdminContainer>
            <div>{cronStrE}</div>
            <Button
              size="small"
              variant="outlined"
              onClick={() => manualExecuteTimeTable(timeTableObj1)}
            >
              Manual execute
            </Button>
          </AdminContainer>
          <TimePicker
            sx={{ margin: "2vh 0" }}
            label="End time"
            value={momentE}
            onChange={(newValue) => setMomentE(newValue)}
            ampm={false}
          />
          <DivInline sx={{ marginTop: "1vh" }}>
            {Object.keys(weekDayIsoMenu).map((i) => {
              return (
                <Wbutton
                  key={i}
                  content={weekDayIsoMenu[i]}
                  selected={weekArrE?.includes(Number(i))}
                  onClick={() => {
                    let newArr = [];
                    if (!weekArrE?.includes(Number(i))) {
                      newArr = [...weekArrE, Number(i)];
                    } else {
                      newArr = weekArrE?.filter((j) => j !== Number(i));
                    }
                    newArr = newArr.sort((a, b) => a - b);
                    setWeekArrE(newArr);
                  }}
                />
              );
            })}
          </DivInline>
          <Wbutton
            content="All week"
            width="6vw"
            selected={gs.arrayEquals(weekArrE || [], [1, 2, 3, 4, 5, 6, 7])}
            onClick={() => {
              setWeekArrE([1, 2, 3, 4, 5, 6, 7]);
            }}
          />
        </DivCol> */}
      </DivInline>
      <Divider10 />
      <DivInline justifyContent="center" sx={{ marginTop: "1vh" }}>
        <Button
          size="small"
          sx={{ marginRight: "8vw" }}
          variant={excludeHoliday ? "contained" : "outlined"}
          onClick={() => {
            let newStatus = !excludeHoliday;
            setExcludeHoliday(newStatus);
            if (newStatus) setOnlyHoliday(!newStatus);
          }}
        >
          Exclude public holiday
        </Button>
        <Button
          size="small"
          variant={onlyHoliday ? "contained" : "outlined"}
          onClick={() => {
            let newStatus = !onlyHoliday;
            setOnlyHoliday(newStatus);
            if (newStatus) setExcludeHoliday(!newStatus);
          }}
        >
          Only public holiday
        </Button>
      </DivInline>
    </ModalSM>
  );
}

function Wbutton({ content, onClick, selected, width = "2.5vw" }) {
  const color = selected ? "rgb(159,220,249)" : "rgb(140,140,140)";
  return (
    <SmallButton
      textColor={color}
      borderColor={color}
      height="3vh"
      width={width}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }}
      btnContent={content}
    />
  );
}
function TimeSettingInput({ title, timeTableObj, moment, weekArr, setMoment, setWeekArr }) {
  const [state, dispatch] = useContext(SMContext);
  const { timeTableObjAll } = state;
  return (
    <DivCol>
      <Tooltip title={timeTableObj?.timeTableID || ""}>
        <Typography>{title}</Typography>
      </Tooltip>
      <AdminContainer>
        <DeviceMongoModal deviceObj={timeTableObjAll[timeTableObj?.timeTableID]}>
          <Typography variant="caption">{timeTableObj?.timeTableID}</Typography>
        </DeviceMongoModal>
      </AdminContainer>
      <TimePicker
        sx={{ margin: "2vh 0" }}
        label="Time"
        value={moment}
        onChange={(newValue) => setMoment(newValue)}
        ampm={false}
      />

      <DivInline sx={{ marginTop: "1vh" }}>
        {Object.keys(weekDayIsoMenu).map((i) => {
          return (
            <Wbutton
              key={i}
              content={weekDayIsoMenu[i]}
              selected={(weekArr || [])?.includes(Number(i))}
              onClick={() => {
                let newArr = [];
                if (!(weekArr || [])?.includes(Number(i))) {
                  newArr = [...(weekArr || []), Number(i)];
                } else {
                  newArr = (weekArr || []).filter((j) => j !== Number(i));
                }
                newArr = newArr.sort((a, b) => a - b);
                setWeekArr(newArr);
              }}
            />
          );
        })}
      </DivInline>
      <Wbutton
        content="All week"
        width="6vw"
        selected={gs.arrayEquals(weekArr || [], [1, 2, 3, 4, 5, 6, 7])}
        onClick={() => {
          setWeekArr([1, 2, 3, 4, 5, 6, 7]);
        }}
      />
    </DivCol>
  )
}