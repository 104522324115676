import { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { List, Close } from "@mui/icons-material";
import DivInline from "./DivInline";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {("asc"|"desc")} [props.order]
 * @param {object} props.menuObj
 * @param {boolean} [props.orderByKey]
 * @param {boolean} [props.orderByValue]
 * @param {boolean} [props.showValue]
 * @param {*} props.initialVal
 * @param {function} props.handleSave
 * @param {boolean} [props.hideValInMenu]
 * @param {JSX.Element} [props.children]
 *
 * @description
 * Usage outside:
 *  <DialogSelect title="Select Project"
 *   menuObj={{ S00: "aaa", S01: "bbb", S02: "ccc" }}
 *   handleSave={item => outsideFunction(item, outsideParameters)}
 *  />
 *
 */
export default function DialogSelect({
  title,
  menuObj,
  orderByKey,
  orderByValue,
  order,
  initialVal,
  handleSave,
  children,
  hideValInMenu,
}) {
  const [open, setOpen] = useState(false);
  const [tempData, setTempData] = useState("-");
  const closeDialog = () => setOpen(false);
  useEffect(() => {
    setTempData(initialVal);
  }, [initialVal]);
  let fieldArray = Object.keys(menuObj || {});
  if (orderByKey) {
    if (order === "desc") {
      fieldArray = fieldArray.sort((a, b) => b.localeCompare(a));
    } else {
      fieldArray = fieldArray.sort((a, b) => a.localeCompare(b));
    }
  }
  if (orderByValue) {
    if (order === "desc") {
      fieldArray = fieldArray.sort((a, b) =>
        menuObj[b].localeCompare(menuObj[a])
      );
    } else {
      fieldArray = fieldArray.sort((a, b) =>
        menuObj[a].localeCompare(menuObj[b])
      );
    }
  }
  return (
    <>
      <List onClick={() => setOpen(!open)} style={{ cursor: "pointer" }} />
      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            select
            fullWidth
            variant="outlined"
            value={tempData}
            onChange={(evt) => {
              setTempData(evt.target.value);
              handleSave(evt.target.value);
              closeDialog();
            }}
          >
            {/* <MenuItem value="-">-</MenuItem> */}
            {fieldArray.map((field) => (
              <MenuItem key={field} value={field}>
                <DivInline>
                  <Typography>{`${menuObj[field]} `}</Typography>
                  {!hideValInMenu && <Typography>[{field}]</Typography>}
                </DivInline>
              </MenuItem>
            ))}
            {children}
          </TextField>
        </DialogContent>
        <DialogActions>
          <IconButton onClick={closeDialog} size="large">
            <Close />
          </IconButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

// Usage outside:
// HANDLE SAVE FUNCTION ARGUMENT SUPPLY EDITED DATA
// <DialogSelect
//   title="Select Project"
//   menuObj={{ S00: "aaa", S01: "bbb", S02: "ccc" }}
//   handleSave={item => outsideFunction(item, outsideParameters)}
// />;
