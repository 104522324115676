import { Group, Path } from "react-konva";

export default function Document({ x, y, size }) {
  const scale = size / 79.375;

  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      <Path
        strokeWidth={3.566}
        fill="#ffe300"
        stroke="navy"
        data="M16.542 3.446l30.853.171s1.695-.175 2.427.172c.995.47 2.253 2.396 2.253 2.396l12.307 13.18s.697 1.565.867 2.396c.206 1.008.173 3.081.173 3.081l-.347 47.926s-.654 1.385-1.213 1.883c-.576.513-2.08 1.027-2.08 1.027l-45.586-.171s-1.27-.545-1.733-1.027c-.677-.703-1.387-2.568-1.387-2.568l.173-65.556s.177-1.543.694-2.054c.646-.638 2.6-.856 2.6-.856z"
        perfectDrawEnabled={false}
      ></Path>
      <Path
        strokeWidth={3.566}
        stroke="navy"
        data="M49.094 3.975V21.15l13.51-.142 1.274-1.713L50.531 4.472z"
        perfectDrawEnabled={false}
      ></Path>
      <Path
        strokeWidth={3.566}
        stroke="#333"
        lineCap="round"
        data="M22.645 17.518l17.161-.154M22.793 28.531l32.45-.154M22.941 38.67l32.45-.154M23.089 50.413l32.45-.154M23.015 60.697l22.036-.154"
        perfectDrawEnabled={false}
      ></Path>
    </Group>
  );
}
