import { useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import {
  mapRemoveManyDevices
} from "actions/mapActions";
import { SelectAll } from "@mui/icons-material";
import IconButtonClose from "components/IconButtonClose";
import DivExist from "components/DivExist";
import ThemeContainer from "components/ThemeContainer";
import { titleText } from "asset/string/color";
import CopyMultiDeviceToMapModal from "containers/map/modal/CopyMultiDeviceToMapModal";
import {
  confirmWindow,
  showBigProgress,
  hideBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import { deleteZoneControl } from "actions/zcActions";
const gs = new General();

export default function ShiftSelectedDeviceControl() {
  const [state, dispatch] = useContext(SMContext);
  const {
    activeMapID,
    shiftSelectedArray,
    zoneControlObjAll,
    mapObjAll,
  } = state;
  const selectedLightArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "light")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedSensorArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "sensor")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedDaliCtlArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "daliCtl")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedGatewayArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "gateway")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const selectedZoneControlArray = useMemo(() => {
    let arr = shiftSelectedArray
      .filter((obj) => obj.deviceType === "zoneControl")
      .map((obj) => obj.deviceID);
    return arr;
  }, [shiftSelectedArray]);
  const removeAllDevice = async () => {
    showBigProgress(dispatch);
    const selectedArrayObj = { gatewayArray: selectedGatewayArray, lightArray: selectedLightArray, sensorArray: selectedSensorArray, daliCtlArray: selectedDaliCtlArray, zoneControlArray: selectedZoneControlArray };
    await mapRemoveManyDevices(mapObjAll[activeMapID], selectedArrayObj);
    hideBigProgress(dispatch);
    goBack();
  };

  function goBack() {
    unselectGateway();
    dispatch({ type: "CLEARED_SHIFT_SELECT" });
    dispatch({ type: "ALL_UNSELECTED" });
  }
  function unselectSensor() {
    dispatch({ type: "SET_SELECTED_SENSOR", payload: "" });
    dispatch({
      type: "SET_MAP_LAYER",
      payload: {
        normal: true,
        sensorSelect: false,
      },
    });
  }
  function unselectGateway() {
    dispatch({ type: "SET_SELECTED_GATEWAY", payload: "" });
    dispatch({
      type: "SET_MAP_LAYER",
      payload: {
        normal: true,
        gatewaySelect: false,
      },
    });
  }
  return (
    <ThemeContainer background="light">
      <SelectAll />
      <Typography align="center" style={{ color: titleText }}>
        Select multiple items
      </Typography>
      <hr color="lightgrey" size={1} width="90%" />
      <DivExist show={selectedGatewayArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedGatewayArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          gateways
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedLightArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedLightArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          lights
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedSensorArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedSensorArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          sensors/switches
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedDaliCtlArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedDaliCtlArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          dali controllers
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={selectedZoneControlArray.length > 0}>
        <Typography align="center" color="secondary">
          {selectedZoneControlArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          Vitural button / timer
        </Typography>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist
        show={
          selectedLightArray.length > 0 ||
          selectedGatewayArray.length > 0 ||
          selectedSensorArray.length > 0 ||
          selectedDaliCtlArray.length > 0 ||
          selectedZoneControlArray.length > 0
        }
      >
        <Button
          variant="outlined"
          size="small"
          sx={{ marginBottom: "1vh" }}
          onClick={() => {
            confirmWindow(
              dispatch,
              "Confirm remove selected devices from this map?",
              async () => {
                await removeAllDevice();
              }
            );
          }}
        >
          Remove from this map
        </Button>
        <CopyMultiDeviceToMapModal
          selectedLightArray={selectedLightArray}
          selectedSensorArray={selectedSensorArray}
          selectedDaliCtlArray={selectedDaliCtlArray}
          selectedGatewayArray={selectedGatewayArray}
          selectedZoneControlArray={selectedZoneControlArray}
          goBack={goBack}
        />
      </DivExist>


      {/* <DivExist show={selectedZoneControlArray.length > 0}>
        <hr color="lightgrey" size={3} width="90%" />
        <Typography align="center" color="secondary">
          {selectedZoneControlArray.length}
        </Typography>
        <Typography align="center" variant="body2">
          Vitural button / timer
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ marginBottom: "1vh" }}
          onClick={() => {
            confirmWindow(
              dispatch,
              "Confirm delete selected vitural button / timer?",
              async () => {
                showBigProgress(dispatch);
                await gs.asyncForEach(selectedZoneControlArray, async (z) => {
                  await deleteZoneControl(activeMapID, z, zoneControlObjAll[z]);
                  await gs.waitFor(500);
                });
                hideBigProgress(dispatch);
                goBack();
              }
            );
          }}
        >
          Delete selected Vitural button / timer
        </Button>
        <hr color="lightgrey" size={3} width="90%" />
      </DivExist> */}

      <Button onClick={() => { }}></Button>
      <IconButtonClose onBtnClick={goBack} />
    </ThemeContainer>
  );
}