import React from "react";

export default function Gateway({
  width,
  height,
  sizeUnit,
  disabledConnectStatus,
  connectStatus,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <path
        fill="#ccc"
        stroke="teal"
        strokeWidth="4.671"
        d="M6.334 50.848c.78-2.375 1.498-4.807 4.756-4.796h58.901c2.627-.013 4.562 1.546 5.488 5.165l-.366 17.708c-.318 2.855-1.684 4.409-3.841 4.98l-59.998-.369c-2.96-.1-4.593-1.982-5.122-5.349z"
      ></path>
      <ellipse
        cx="17.267"
        cy="59.823"
        fill="#00aad4"
        fillOpacity="0.996"
        rx="3.489"
        ry="3.518"
      ></ellipse>
      <ellipse
        cx="26.971"
        cy="59.779"
        fill="#5fd35f"
        fillOpacity="0.996"
        rx="3.489"
        ry="3.518"
      ></ellipse>
      <ellipse
        cx="36.899"
        cy="59.886"
        fill="#d35f5f"
        fillOpacity="0.996"
        rx="3.489"
        ry="3.518"
      ></ellipse>
      <path
        fill="teal"
        d="M45.071 45.236l-.102-15.747c1.672-4.257 5.989-4.37 7.349 0v15.953z"
      ></path>
      <path
        fill="#d4aa00"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={0.265}
        d="M38.165 28.714c1.438-8.36 8.673-8.899 10.89-9 2.025.106 9.157 1.258 10.135 9 .29 3.811 5.133 3.967 5.899.227.24-4.935-4.808-15.094-16.35-15.074-11.532.594-16.07 8.51-16.246 14.771.804 5.032 6.244 3.236 5.672.076z"
      ></path>
      <path
        fill="#decd87"
        fillOpacity="1"
        stroke="none"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={0.265}
        d="M21.491 28.964c1.036 3.573 5.15 3.102 5.873 0 .015-11.2 10.476-19.411 21.463-19.396 10.972.259 20.664 6.802 21.188 19.38.72 3.245 4.975 3.606 5.893-.13-.613-15.343-13.06-24.909-27.233-24.99-9.866.392-25.916 5.558-27.184 25.136z"
      ></path>
      {!disabledConnectStatus && (
        <circle r="6" cx="7" cy="7" fill={connectStatus ? "green" : "red"} />
      )}
    </svg>
  );
}
