import { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import { alertWindow } from "actions/screenActions";
import DivCol from "components/DivCol";
import IconButtonDelete from "components/IconButtonDelete";
import { mgFindQuery, mgUpdateOneUpsert } from "actions/mongoApiActions";
import { mapAddDevice } from "actions/mapActions";
import CardDaliCtl from "components/CardDaliCtl";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import IconButtonBack from "components/IconButtonBack";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import MongoLogo from "asset/svgComp/MongoLogo";
import FirestoreLogo from "asset/iconPng/firestore_logo.png";
import General from "@ecoenghk/general";
const gs = new General();

export default function AddDaliCtlToMapPanel({ handleClose, targetX, targetY, state, dispatch, modalSize }) {
  const { activeMapID, mapObjAll } = state;
  const [input, setInput] = useState("");
  const [source, setSource] = useState("");
  const [daliCtlObjArray, setDaliCtlObjArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const mapDaliCtlObj = mapObjAll[activeMapID]?.daliCtlObj || {};
  const searchDb = async () => {
    const query = {
      $or: [
        { dtkAdd: input },
        { daliCtlID: input },
        { wifiApMacAddress: input },
      ]
    }
    const objArray = await mgFindQuery("daliCtl", query);
    setSource("mongo");
    if (!objArray || objArray.length === 0) {
      console.log(`${input} not found in mongo, try fs`)
      objArray = await getDocsWhereFS("SM_daliCtl", "array", "daliCtlID", "==", input);
      setSource("fs");
      if (!objArray || objArray.length === 0) {
        console.log(`${input} not found in fs daliCtlID, try dtkAdd`)
        objArray = await getDocsWhereFS("SM_daliCtl", "array", "dtkAdd", "==", input);
        if (!objArray || objArray.length === 0) {
          console.log(`${input} not found in fs dtkAdd, try wifiApMacAddress`)
          objArray = await getDocsWhereFS("SM_daliCtl", "array", "wifiApMacAddress", "==", input);
          if (!objArray || objArray.length === 0) {
            console.log(`${input} not found in fs wifiApMacAddress, search fail`)
            setErrorMsg(`ID/address/mac ${input} not found`);
            setDaliCtlObjArray([]);
          }
        }
      }
    }
    setDaliCtlObjArray(objArray);
  };
  const handleSave = async (daliCtlObj) => {
    const { daliCtlID } = daliCtlObj || {};
    if (!daliCtlID) {
      alertWindow(dispatch, "Invalid dali controller ID, add fail");
      return;
    }
    if (source === "fs") {
      alertWindow(dispatch, "Dali controller is not in local server, download from firestore first");
      await mgUpdateOneUpsert("daliCtl", { daliCtlID }, daliCtlObj);
      await gs.waitFor(2000);
    }
    await mapAddDevice(activeMapID, daliCtlID, "daliCtl", daliCtlObj, targetX, targetY);
    handleClose();
  };
  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add Dali controller to map</Typography>
      </DivInline>
      <Divider10 space={5} />
      <SpaceBetweenInput
        title="ID / address / wifiApMacAddress"
        titleVariant="body2"
        data={input}
        width="40%"
        onInput={(evt) => {
          setInput(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Button size="small" fullWidth onClick={searchDb} variant="outlined" sx={{ margin: "1vw 0" }} disabled={!input}> Search</Button >
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">{errorMsg}</Typography>
      )
      }
      <Divider10 space={5} />
      <DivInline justifyContent="center">
        {
          daliCtlObjArray.map((daliCtlObj, key) => {
            const { daliCtlID } = daliCtlObj || {};
            const isInMap = mapDaliCtlObj[daliCtlID] && daliCtlObj?.mapID?.includes(activeMapID);
            return (
              <DivCol key={key}>
                <CardDaliCtl
                  iconSize={modalSize === "small" ? 3 : 7}
                  iconSizeUnit="vh"
                  daliCtlObj={daliCtlObj}
                  handleSave={() => handleSave(daliCtlObj)}
                  disableClick={isInMap ? true : false}
                />
                <DivExist show={isInMap}>
                  <Typography color="error">Already in map</Typography>
                </DivExist>
              </DivCol>
            )
          })
        }
      </DivInline>
      <Divider10 space={5} />
      <DivExist show={daliCtlObjArray.length > 0 || errorMsg}>
        <DivInline justifyContent="center">
          <IconButtonDelete
            onBtnClick={() => {
              setDaliCtlObjArray([]);
              setInput("");
              setErrorMsg("");
              setSource("");
            }} />
        </DivInline>
      </DivExist>
      <DivExist show={daliCtlObjArray.length > 0}>
        <Typography variant="body2">Source:</Typography>
        {
          source === "mongo" ?
            <MongoLogo width={1.5} sizeUnit="vw" /> :
            <img src={FirestoreLogo} alt="firestore" style={{ width: "1.5vw" }} />
        }
      </DivExist>
    </>
  );
}
