import ModalSM from "components/ModalSM";
import { Button, Typography } from "@mui/material";
import { useState, useMemo, useContext } from "react";
import InputField from "./InputField";
import Checkbox from '@mui/material/Checkbox';
import { SMContext } from "context/smContext";
import { serverSendUDPbroadcast } from "actions/serverActions";
const commandTemplate = [
  {
    commandName: "ping",
    struct: `{commandName:"ping"}`,
  },
  {
    commandName: "getGatewayID",
    struct: `{commandName:"getGatewayID"}`,
  },
  {
    commandName: "serverTakeOverControl",
    struct: `{commandName:"serverTakeOverControl"}`,
  },
  {
    commandName: "serverReleaseControl",
    struct: `{commandName:"serverReleaseControl"}`,
  },
  {
    commandName: "getServerIP",
    struct: `{commandName:"getServerIP"}`,
  },
  {
    commandName: "setServerIP",
    struct: `{commandName:"setServerIP", serverIP:"192.168.1.1"}`,
  },
  {
    commandName: "excute_linux_command",
    struct: `{commandName:"excute_linux_command", command:"ls -l"}`,
  }
];

export default function GwUDPbroadcastModal({ children, disableOpen }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeGatewayID } = state;
  const [open, setOpen] = useState(false);
  const [json, setJson] = useState(`{commandName:""}`);
  const [broadcast, setBroadcast] = useState(false);
  const [gatewayID, setGatewayID] = useState("");
  const jsonStr = useMemo(() => {
    if (!broadcast) {
      return json.replace(`}`, `, targetID:"${gatewayID}"}`)
    } else {
      return json
    }
  }, [json, broadcast, gatewayID]);
  return (
    <>
      <div onClick={() => { if (!disableOpen) setOpen(true) }}>
        {children}
      </div>
      <ModalSM
        modalTitle={"UDP Broadcast"}
        open={open} onClose={() => setOpen(false)}
      >
        <div className="scroll-container" style={{ height: "200px", overflowY: "auto", display: "flex", flexDirection: "row", gap: "5px", flexWrap: "wrap" }}>
          <Typography>command template</Typography>
          {
            commandTemplate.map((item, index) => {
              return (
                <Button key={index}
                  onClick={() => {
                    setJson(item.struct);
                  }}
                >{item.commandName}</Button>
              )
            })
          }
        </div>
        <InputField
          label="JSON"
          value={json}
          multiline
          onInput={(v) => setJson(v)}
          keyPressEnter
        />
        <Typography>
          {jsonStr}
        </Typography>
        <BroadcastCheckBox label="Broadcast" checked={broadcast} onChange={() => setBroadcast(!broadcast)} value={gatewayID} onValueChange={(v) => setGatewayID(v)} />
        <Button
          variant="contained"
          onClick={() => {
            serverSendUDPbroadcast(jsonStr)
          }}
        >Send</Button>
      </ModalSM>
    </>
  )
}

function BroadcastCheckBox({ label, checked, onChange, value, onValueChange }) {
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "5px" }}>
      <Checkbox checked={checked} onChange={onChange} />
      <Typography>{label}</Typography>
      <InputField
        value={value}
        onInput={(v) => onValueChange(v)}
        label="Gateway ID"
        placeholder="GW123456"
        disabled={checked}
        sx={{ width: "150px" }}
      />
    </div>
  )
}