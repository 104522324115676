import { useContext, useState, useRef } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Box } from "@mui/material";
import DivInline from "components/DivInline";
import { ViewportList } from "react-viewport-list";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { fetchServerLog } from "actions/serverActions";

const w = window.innerWidth;
const h = window.innerHeight;
export default function ServerLog() {
  const [state, dispatch] = useContext(SMContext);
  const ref = useRef(null);
  const [log, setLog] = useState([]);
  const [startM, setStartM] = useState(moment().subtract(3, "hours"));
  const [endM, setEndM] = useState(moment());
  const fetchLatestLog = async () => {
    const startTsp = startM.valueOf();
    const endTsp = endM.valueOf();
    if (startTsp >= endTsp)
      return alert("Start time must be earlier than end time");
    let arr = await fetchServerLog(startTsp, endTsp);
    arr = arr.reverse();
    arr = [{ logType: "logType" }, ...arr];
    setLog(arr);
  };

  return (
    <div>
      <DivInline justifyContent="space-between">
        <DivInline>
          <Button onClick={fetchLatestLog} size="small" variant="outlined">
            Get server log (mongo)
          </Button>
          <DateTimePicker
            label="Start time"
            value={startM}
            onChange={(m) => setStartM(m)}
            ampm={false}
          />
          <DateTimePicker
            label="End time"
            value={endM}
            onChange={(m) => setEndM(m)}
            ampm={false}
          />
        </DivInline>
      </DivInline>
      <div className="scroll-container" ref={ref}>
        <ViewportList viewportRef={ref} items={log}>
          {(obj, index) => {
            const { timeStamp, logType, logData } = obj;
            const m = moment(timeStamp);
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: index === 0 ? "default" : "pointer",
                  borderBottom: "1px solid grey",
                  "&:hover": { background: index === 0 ? "" : "#1B2631" },
                }}
                onClick={() => {
                  if (index > 0) console.log(logData);
                }}
              >
                <Typography variant="caption" sx={{ width: 0.1 * w }}>
                  {index === 0 ? "date" : m.format("YYYY-MM-DD")}
                </Typography>
                <Typography variant="caption" sx={{ width: 0.1 * w }}>
                  {index === 0 ? "time" : m.format("HH:mm:ss")}
                </Typography>
                <Typography variant="caption" sx={{ width: 0.2 * w }}>
                  {logType}
                </Typography>
              </Box>
            );
          }}
        </ViewportList>
      </div>
    </div>
  );
}
