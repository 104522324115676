import { Line } from "react-konva";
import {
  daliCtlColorDark,
  gatewayColorDark,
  gatewayColorLight,
  lightColorDark,
  sensorColorDark,
} from "actions/generalActions";
import DivExist from "components/DivExist";
export default function LinesDaliCtlSelected({ layerProps }) {
  const {
    selectedID,
    gatewayObjAll,
    lightObjAll,
    sensorObjAll,
    daliCtlObjAll,
    layerScale,
    mapObjAll,
    activeMapID,
    selectedDeviceX,
    selectedDeviceY,
    mapScale,
    mapLayer,
  } = layerProps;
  const daliCtlObj = daliCtlObjAll[selectedID];
  const { lightObj, sensorObj, gatewayID } = daliCtlObj || {};
  const lightArray = Object.keys(lightObj || []);
  const sensorArray = Object.keys(sensorObj || []);
  const mapGwObj = mapObjAll[activeMapID]?.gatewayObj;
  const mapLightObj = mapObjAll[activeMapID]?.lightObj;
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj;
  return (
    <>
      {(lightArray || [])
        ?.filter((s) => lightObjAll[s]?.mapID?.includes(activeMapID))
        ?.map((s, key) => {
          const netX = parseInt(mapLightObj[s]?.x * mapScale.x);
          const netY = parseInt(mapLightObj[s]?.y * mapScale.y);
          return (
            <Line
              key={key}
              points={[selectedDeviceX, selectedDeviceY, netX, netY]}
              stroke={lightColorDark}
              strokeWidth={1.5 / layerScale}
            />
          );
        })}
      {(sensorArray || [])
        ?.filter((s) => sensorObjAll[s]?.mapID?.includes(activeMapID))
        ?.map((s, key) => {
          const netX = parseInt(mapSensorObj[s]?.x * mapScale.x);
          const netY = parseInt(mapSensorObj[s]?.y * mapScale.y);
          return (
            <Line
              key={key}
              points={[selectedDeviceX, selectedDeviceY, netX, netY]}
              stroke={sensorColorDark}
              strokeWidth={1.5 / layerScale}
            />
          );
        })}
      <DivExist show={gatewayID ? true : false}>
        <Line
          points={[
            selectedDeviceX,
            selectedDeviceY,
            mapGwObj?.[gatewayID]?.x * mapScale.x,
            mapGwObj?.[gatewayID]?.y * mapScale.y,
          ]}
          stroke={gatewayColorLight}
          strokeWidth={1 / layerScale || 1}
        />
      </DivExist>
    </>
  );
}
