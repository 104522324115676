import React from "react";

/**
 * @param {object} param0
 * @param {number} param0.width
 * @param {number} param0.height
 * @param {string} param0.sizeUnit
 * @param {"charge" | "discharge"} param0.status
 * @param {"dark" | "light"} param0.darkLight
 * @returns
 */
export default function BatteryCharge({
  width,
  height,
  sizeUnit,
  status,
  darkLight,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      {status === "charge" ? (
        <path
          fill="#4a0"
          d="M35.335 3.406L35.48 14.3l-9.587.151 14.962 12.861L55.09 14.603l-8.28-.454-.436-10.742z"
        ></path>
      ) : (
        <path
          fill="#ff2a2a"
          d="M35.335 27.312l.145-10.894-9.587-.151L40.855 3.406 55.09 16.115l-8.28.454-.436 10.742z"
        ></path>
      )}

      <path
        fill="none"
        stroke={darkLight === "dark" ? "#666" : "#fff"}
        strokeWidth={4}
        d="M29.018 28.189H8.913c-2.7.1-4.087 2.038-5.066 4.547v24.942c.134 3.826 2.67 4.05 5.196 4.287h52.353c3.734.136 5.244-1.899 5.196-5.456l-.52-24.942c.31-2.513-1.06-3.447-3.637-3.248H51.96"
      ></path>

      <path
        fill={status === "charge" ? "#4a0" : "#ff2a2a"}
        d="M13.897 44.869l22.272-10.615 6.34 11.776 16.745-.332-22.434 11.61-6.34-11.444z"
      ></path>
      <path
        fill="none"
        stroke={darkLight === "dark" ? "#666" : "#fff"}
        strokeWidth={4}
        d="M67.501 38.192h6.236c1.44-.018 2.338.653 2.338 2.468l.13 8.704c-.032 1.853-.868 2.579-2.209 2.598l-6.495-.13"
      ></path>
    </svg>
  );
}
