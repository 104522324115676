import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { QueryFuncIcon } from "components/toolbox/QueryFuncIcon";

export default function SocketToGW() {
  const [state, dispatch] = useContext(SMContext);
  const [filterStr, setFilterStr] = useState("");
  const { socket, response_socketList } = state;

  const _response_socketList = useMemo(() => {
    if (!response_socketList) return [];
    const _filterStr = filterStr.split(" ").filter(str => str.length > 0);
    const _response_data = response_socketList.filter(item => {
      const { userSocketID, userType, isConnected, ipAddress } = item;
      return _filterStr.every(str => userSocketID.includes(str) || userType.includes(str) || isConnected.toString().includes(str) || ipAddress.includes(str));
    });
    return _response_data;
  }, [response_socketList, filterStr]);
  const webSocketCount = useMemo(() => {
    return _response_socketList ? _response_socketList.filter(item => item.userType === "webUser").length : 0;
  }, [_response_socketList]);
  const gwSocketCount = useMemo(() => {
    return _response_socketList ? _response_socketList.filter(item => item.userType === "gateway").length : 0;
  }, [_response_socketList]);
  const unknownSocketCount = useMemo(() => {
    return _response_socketList ? _response_socketList.filter(item => item.userType === "unknown").length : 0;
  }, [_response_socketList]);
  const handlePing = (gatewayID) => {
    socket.emit("fromWeb", { type: "toGW_socket", topic: "control_single_gateway", gatewayID, payload: { commandCode: 0x0083 } });
  }
  const handleReConnect = (gatewayID) => {
    socket.emit("fromWeb", { type: "toGW_socket", topic: "control_single_gateway", gatewayID, payload: { commandCode: 0x0082 } });
  }
  const ReConnectAll = () => {
    _response_socketList.forEach(item => {
      handleReConnect(item.userSocketID);
    });
  }
  return (
    <div>
      <Typography>Socket to GW</Typography>
      <DivInline>
        <QueryFuncIcon onClick={() => {
          socket.emit("fromWeb", { type: "fromWeb", type: "getSocketList" });
        }} listenStateKey="response_socketList" label="Socket to GW" />
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Typography>web: {webSocketCount}</Typography>
          <Typography>gw: {gwSocketCount}</Typography>
          <Typography>unknown: {unknownSocketCount}</Typography>
        </div>
      </DivInline>
      <DivInline>
        <TextField label="Filter" sx={{ width: "100%" }} value={filterStr} onChange={(e) => setFilterStr(e.target.value)} />
        <Button variant="contained" color="primary" onClick={() => setFilterStr("")}>X</Button>
      </DivInline>
      <TableContainer component={Paper} sx={{ height: "300px", overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow sx={{ position: 'sticky', top: 0, backgroundColor: 'black', zIndex: 1 }}>
              <TableCell>User Socket ID</TableCell>
              <TableCell>User Type</TableCell>
              <TableCell>Is Connected</TableCell>
              <TableCell>IP</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_response_socketList && _response_socketList.map((item) => (
              <TableRow key={item.userSocketID}>
                <TableCell>{item.userSocketID}</TableCell>
                <TableCell sx={{ color: item.userType === "unknown" ? "red" : "white" }}>{item.userType}
                  {item.userType === "gateway" && (item?.gatewayID ? `(${item.gatewayID})` : "")}
                </TableCell>
                <TableCell sx={{ color: item.isConnected ? "green" : "red" }}>{item.isConnected.toString()}</TableCell>
                <TableCell>{item.ipAddress}</TableCell>
                <TableCell>
                  {item.userType === "gateway" && <>
                    <Button variant="contained" color="primary" onClick={() => handlePing(item.gatewayID)}>Ping</Button>
                    <Button variant="contained" color="primary" onClick={() => handleReConnect(item.gatewayID)}>ReConnect</Button>
                  </>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}