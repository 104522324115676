import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import DivExist from "components/DivExist";
import Divider10 from "components/Divider10";
import { Typography, Box, Slider, Button, ButtonGroup, Checkbox, IconButton, Tooltip } from "@mui/material";
import DivInline from "components/DivInline";
import { FastForward, CopyAll } from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import LightIcon from "components/LightIcon";
import { sensorDisconnectLight, updateSensorProperty, copyLightSettingInOneSensor } from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import SenNearFieldModal from "./SenNearFieldModal";
import NearFieldIcon from "asset/svgComp/NearFieldIcon";
import { deviceModalPos } from "actions/mapActions";
import {
  confirmWindow,
  hideBigProgress,
  showBigProgress,
} from "actions/screenActions";
import General from "@ecoenghk/general";
import SensorIcon from "components/SensorIcon";
const gs = new General();

export default function SenDaylightLightModal({
  x,
  y,
  open,
  handleClose,
  layerProps,
  selectedLight,
  sensorID,
  sensorObj,
}) {
  const [pwmArr, setPwmArr] = useState([]);
  const [onDisabledAction, setOnDisabledAction] = useState(false);
  const [activeNearFieldDeviceObj, setActiveNearFieldDeviceObj] = useState({});
  const { lightObjAll, dispatch, daliCtlObjAll, userObj } = layerProps;
  const lightObj = lightObjAll[selectedLight];
  const isDim = lightObj?.type?.pwm;
  const hasNearField = sensorObj?.nearFieldSetting?.[`${selectedLight}_1_0`] ? true : false;
  useEffect(() => {
    let arrayPwm = [];
    for (let i = 1; i <= 11; i++) {
      const serialSetting = sensorObj?.[`setting${i}`]?.[selectedLight];
      const pwm = serialSetting?.pwm || 0;
      arrayPwm = [...arrayPwm, pwm];
    }
    if (sensorObj?.setting12?.[selectedLight]) {
      setOnDisabledAction(true);
      const pwm = sensorObj?.setting12?.[selectedLight]?.pwm || 0;
      arrayPwm = [...arrayPwm, pwm];
    } else {
      setOnDisabledAction(false);
    }
    setPwmArr(arrayPwm);
  }, [open]);
  const updateLightSetting = async (index, newPwm) => {
    let newPwm_arr = [...pwmArr];
    newPwm_arr[index] = newPwm;
    setPwmArr(newPwm_arr);
  };
  const handleSave = async () => {
    let updateObj = {};
    for (let i = 1; i <= 11; i++) {
      let setting = sensorObj?.[`setting${i}`];
      let serialSetting = setting?.[selectedLight];
      serialSetting.pwm = pwmArr[i - 1];
      setting = { ...setting, [selectedLight]: serialSetting };
      updateObj[`setting${i}`] = setting;
    }
    if (onDisabledAction) {
      let setting = sensorObj?.setting12 || {};
      const newSerialSettingObj = { ...sensorObj.setting11[selectedLight], pwm: pwmArr[11] || 0 };
      setting = { ...setting, [selectedLight]: newSerialSettingObj };
      updateObj[`setting12`] = setting;
    } else {
      let setting = sensorObj?.setting12;
      if (setting) {
        if (setting?.[selectedLight]) delete setting[selectedLight];
        updateObj[`setting12`] = setting;
      }
    }
    console.log(updateObj);
    const did = sensorObj.daliCtlID;
    const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
    await updateSensorProperty(sensorID, sensorObj, gid, updateObj);
    handleClose();
  };
  const handleDisconnect = async () => {
    confirmWindow(
      dispatch,
      `Confirm disconnect light [${selectedLight}]?`,
      async () => {
        showBigProgress(dispatch);
        const did = sensorObj.daliCtlID;
        const gid = did ? daliCtlObjAll[did]?.gatewayID : sensorObj.gatewayID;
        await sensorDisconnectLight(sensorObj, gid, lightObj);
        hideBigProgress(dispatch);
        handleClose();
      }
    );
  };
  const handleChangeWorkMode = (newMode) => {
    let newArrPwm = [];
    if (newMode === "near") {
      if (isDim) newArrPwm = [90, 90, 80, 70, 60, 40, 30, 10, 0, 0, 0];
      else newArrPwm = [100, 100, 100, 100, 100, 100, 0, 0, 0, 0, 0];
    } else if (newMode === "middle") {
      if (isDim) newArrPwm = [90, 90, 90, 90, 90, 80, 70, 60, 50, 40, 30];
      else newArrPwm = [100, 100, 100, 100, 100, 100, 100, 100, 0, 0, 0];
    } else if (newMode === "far") {
      if (isDim) newArrPwm = [90, 90, 90, 80, 80, 80, 80, 70, 70, 70, 70];
      else newArrPwm = [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 0];
    }
    setPwmArr(newArrPwm);
  };
  const handleCopyLightSetting = async () => {
    confirmWindow(
      dispatch,
      "Confirm copy setting to other lights?",
      async () => {
        await copyLightSettingInOneSensor(sensorObj, selectedLight);
      }
    );
  };
  const modalWidth = window.innerWidth * 0.48;
  const modalHeight = window.innerHeight * 0.52;
  const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
  return (
    <>
      <MapModal
        x={pos.x}
        y={pos.y}
        open={open}
        handleCloseModal={handleClose}
        disableCloseButton
        width={modalWidth}
        height={modalHeight}
        layerProps={layerProps}
      >
        <DivInline>
          <LightIcon
            lightStyle={lightObj?.style}
            width={3}
            sizeUnit="vw"
            disabledConnectStatus
            disabledStatus
          />
          <Typography variant="caption">{`${lightObj?.description} [${selectedLight}]`}</Typography>
          <Typography variant="caption">Default settings:</Typography>
          <ButtonGroup variant="outlined" size="small">
            <Button onClick={() => handleChangeWorkMode("near")}>
              Near window
            </Button>
            <Button onClick={() => handleChangeWorkMode("middle")}>
              4-8 from window
            </Button>
            <Button onClick={() => handleChangeWorkMode("far")}>
              {`>8m from window`}
            </Button>
          </ButtonGroup>
        </DivInline>
        <Divider10 space={5} />

        <div>
          {gs.newArrayBetween(0, 10).map((n) => {
            return (
              <ControlRow
                key={n}
                settingNum={n + 1}
                title={n === 10 ? `Lv10. >1000lux` : `Lv${n}.  ${n * 100}-${n * 100 + 99}lux`}
                isDim={isDim}
                pwm={pwmArr[n]}
                updateLightSetting={updateLightSetting}
              />
            );
          })}
          <DivInline>
            <Typography variant="caption">On sensor disable action</Typography>
            <Checkbox checked={onDisabledAction || false} onChange={(e) => setOnDisabledAction(e.target.checked)} />
          </DivInline>
          <DivExist show={onDisabledAction}>
            <ControlRow
              settingNum={12}
              title="On sensor disable"
              isDim={isDim}
              pwm={pwmArr[11] || 0}
              updateLightSetting={updateLightSetting}
            />
            <Divider10 space={5} />
          </DivExist>
          <Divider10 space={5} />
        </div>

        <IconButtonClose
          tooltip="Close dialog without saving"
          onBtnClick={handleClose}
        />
        <IconButtonDone
          tooltip="Save settings and close dialog"
          onBtnClick={handleSave}
        />
        <IconButtonDelete
          tooltip="Disconnect light from sensor"
          onBtnClick={handleDisconnect}
        />
        <IconButton onClick={handleCopyLightSetting} size="small">
          <Tooltip title="Copy setting to other lights">
            <CopyAll />
          </Tooltip>
        </IconButton>
        {
          userObj?.level === 0 &&
          <Tooltip title="Save near field action to sensor">
            <IconButton
              onClick={() => {
                setActiveNearFieldDeviceObj(lightObj)
              }}
            >
              <NearFieldIcon width={2} sizeUnit="vw" color={hasNearField ? "red" : "#fff"} />
            </IconButton>
          </Tooltip>
        }
      </MapModal>
      <SenNearFieldModal
        x={pos.x}
        y={pos.y}
        width={modalWidth}
        height={modalHeight}
        open={!gs.isEmptyJson(activeNearFieldDeviceObj) ? true : false}
        handleClose={() => setActiveNearFieldDeviceObj({})}
        layerProps={layerProps}
        sensorObj={sensorObj}
        deviceObj={activeNearFieldDeviceObj}
        buttonNo={1}
      />
    </>
  );
}

function ControlRow({ settingNum, title, isDim, pwm, updateLightSetting }) {
  const [latestPwm, setLatestPwm] = useState(100);
  let pwmMarks = [];
  for (let i = 0; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  return (
    <DivInline
      style={{ width: "42vw", height: "6vh" }}
      justifyContent="space-between"
    >
      <DivInline>
        <Typography sx={{ width: "11vw" }} variant="caption">{title}</Typography>
        <SensorIcon type="daylight" luxLv={settingNum} disabledConnectStatus width={2.2} sizeUnit="vw" />
      </DivInline>
      <FastForward style={{ fontSize: "0.8rem" }} />
      <Box
        sx={{
          outline: pwm > 0 ? "1px solid red" : "",
          cursor: "pointer",
        }}
        onClick={() => {
          let newPwm = pwm > 0 ? pwm : latestPwm > 0 ? latestPwm : 100;
          updateLightSetting(settingNum - 1, newPwm);
        }}
      >
        <LightBulb onoff="on" width={2.2} sizeUnit="vw" />
      </Box>
      <Box
        sx={{
          outline: pwm === 0 ? "1px solid red" : "",
          cursor: "pointer",
        }}
        onClick={() => {
          setLatestPwm(pwm);
          updateLightSetting(settingNum - 1, 0);
        }}
      >
        <LightBulb onoff="off" width={2.2} sizeUnit="vw" />
      </Box>

      <Box sx={{ width: "13vw" }}>
        <DivExist show={isDim}>
          <Slider
            sx={{
              width: "13vw",
              "& .MuiSlider-markLabel": {
                fontSize: "11px",
              }
            }}
            size="small"
            value={pwm || 0}
            onChangeCommitted={(e, val) => updateLightSetting(settingNum - 1, Number(val))}
            marks={pwmMarks}
            max={100}
            min={0}
            step={10}
          />
        </DivExist>
      </Box>
    </DivInline>
  )
}