import { useState, useContext } from "react";
// import { Clear } from "@mui/icons-material";
import { Typography, Button, TextField, LinearProgress } from "@mui/material";
import { SMContext } from "context/smContext";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import axios from "axios";
import ModalSM from "components/ModalSM";
import IconButtonDelete from "components/IconButtonDelete";
import moment from "moment";
export default function UploadImageModal() {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [tempImg, setTempImg] = useState("");
  const [loadedPercent, setLoadedPercent] = useState(0);
  const { activeMapID, mapObjAll } = state;
  const mapObj = mapObjAll[activeMapID];
  const clearImage = () => {
    setTempImg("");
  };

  const getImage = (evt) => {
    const file = evt.target.files[0];
    if (file?.size > 8 * 1024 * 1024) {
      alert("File size exceed limit 8MB");
    } else if (file?.type?.includes("jpeg") || file?.type?.includes("png")) {
      // console.log(file);
      setTempImg(file);
    } else {
      alert("Map background must be JPEG or PNG files");
    }
  };
  const handleSave = async () => {
    if (tempImg) {
      const currentMapNo = mapObj?.mapNo || 0;
      let newMapNo = currentMapNo + 1;
      if (newMapNo > 5) newMapNo = 0;
      let mapFileNameSet = `${activeMapID}_${newMapNo}`;
      let imageFormObj = new FormData();
      let ext = "";
      if (tempImg.type.includes("jpeg")) ext = ".jpg";
      if (tempImg.type.includes("png")) ext = ".png";
      mapFileNameSet = mapFileNameSet + ext;
      imageFormObj.append("mapBg", tempImg, mapFileNameSet);
      const config = {
        onUploadProgress: (progressEvent) => {
          console.log(`${progressEvent.loaded} of ${progressEvent.total}`);
          setLoadedPercent((progressEvent.loaded / progressEvent.total) * 100);
        },
      };

      const res = await axios.post(
        `${global.ip}/api/uploadMap`,
        imageFormObj,
        config
      );

      console.log(res.data);
      const mapID = res?.data?.result?.mapID;
      const mapFileName = res?.data?.result?.mapFileName;
      const newMapObj = { ...mapObj, mapFileName: mapFileName, mapNo: newMapNo };
      if (mapID) {
        dispatch({
          type: "UPDATED_MAP_OBJ",
          payload: newMapObj,
        });
        setLoadedPercent(0);
        handleClose();
      } else {
        alert("upload failed");
        setLoadedPercent(0);
      }
    } else {
      alert("please choose file");
      setLoadedPercent(0);
    }
  };
  const handleClose = () => {
    setOpen(false);
    clearImage();
    setLoadedPercent(0);
  };
  return (
    <>
      <Button variant="outlined" onClick={() => setOpen(true)}>
        Upload
      </Button>
      <ModalSM
        modalTitle="Upload image"
        open={open}
        onClose={handleClose}
        minWidth="80vw"
        minHeight="70vh"
        disableBottomClose
      >
        <TextField
          fullWidth
          type="file"
          placeholder="Upload background"
          onChange={getImage}
          accept="image/*"
          required
          margin="normal"
          encType="multipart/form-data"
          style={{ width: "40vw" }}
        />
        <DivInline>
          <DivInline
            justifyContent="center"
            alignItems="center"
            style={{
              height: "32vh",
              width: "43vw",
              border: "1px solid white",
              margin: 20,
            }}
            hide={!tempImg}
          >
            <img
              src={tempImg ? URL.createObjectURL(tempImg) : ""}
              style={{ height: "30vh", width: "40vw" }}
              alt=""
            />
          </DivInline>
          <Button variant="outlined" onClick={handleSave} disabled={!tempImg}>
            Upload
          </Button>
          <IconButtonDelete
            onBtnClick={clearImage}
            tooltip="delete temporary image"
            disabled={!tempImg}
          />
        </DivInline>
        <DivExist show={loadedPercent > 0}>
          <Typography>Uploading: </Typography>
          <LinearProgress
            variant="determinate"
            value={loadedPercent}
            color="secondary"
            style={{ width: "40%" }}
          />
        </DivExist>
      </ModalSM>
    </>
  );
}
