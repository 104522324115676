import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box } from "@mui/material";
import { Add, Forward } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import SpaceBetweenCheckbox from "components/SpaceBetweenCheckbox";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import DivInline from "components/DivInline";
import { fetchTimeTableByZc, manualExecuteTimeTable } from "actions/timetableActions";
import General from "@ecoenghk/general";
import { addZcTimeSetting, updateZcProperty, enablingTimerSetting3, disablingTimerSetting3 } from "actions/zcActions";
import DivCol from "components/DivCol";
import AdminContainer from "components/AdminContainer";
import EditTimeSettingModal from "./EditTimeSettingModal";
import ButtonSM from "components/ButtonSM";
const gs = new General();

export default function ZcPanelTimeSetting() {
  const [state, dispatch] = useContext(SMContext);
  const { activeZoneControlID, zoneControlObjAll, timeTableObjAll } = state;
  const zcObj = zoneControlObjAll[activeZoneControlID];
  // timeTableID1 - setting1 - Timer end
  // timeTableID2 - setting2 - Timer start
  // timeTableID3 - setting3 - Timer intermediate
  const [openEdit, setOpenEdit] = useState(false);
  const [editTobj1, setEditTobj1] = useState({});
  const [editTobj2, setEditTobj2] = useState({});
  const [editTobj3, setEditTobj3] = useState({});
  const { tcEnable, timeTableID1, timeTableID2, timeTableID3 } = zcObj || {};
  useEffect(() => {
    if (timeTableID1 && timeTableID2) {
      fetchTimeTable();
    }
  }, [timeTableObjAll]);
  const fetchTimeTable = async () => {
    const objAll = await fetchTimeTableByZc(activeZoneControlID);
    dispatch({
      type: "FETCHED_MANY_TIMETABLE_OBJ",
      payload: objAll,
    });
  };
  const handleManualExecute = async (settingNum) => {
    const tid = zcObj?.[`timeTableID${settingNum}`]?.[0];
    if (!tid) return;
    const tObj = timeTableObjAll?.[tid];
    await manualExecuteTimeTable(tObj);
  }
  return (
    <div>
      <SpaceBetweenCheckbox
        title="Enable timer"
        checkboxColor="secondary"
        data={tcEnable || false}
        onCheck={async (e) => {
          const updateObj = { tcEnable: Boolean(e.target.checked) };
          await updateZcProperty(activeZoneControlID, updateObj);
        }}
      />
      <AdminContainer>
        <DivExist show={zcObj?.enableGroupAction}>
          <SpaceBetweenCheckbox
            title="Enable intermediate action"
            checkboxColor="secondary"
            data={zcObj.enableSetting3 || false}
            onCheck={async (e) => {
              if (!zcObj.enableSetting3) enablingTimerSetting3(zcObj, timeTableObjAll);
              else disablingTimerSetting3(zcObj);
            }}
          />
        </DivExist>
      </AdminContainer>
      <SpaceBetweenButton
        title="Add new time"
        btnContent={<Add />}
        onBtnClick={async () => addZcTimeSetting(activeZoneControlID, zcObj)}
      />
      <Divider10 space={5} />
      <DivInline justifyContent="space-around">
        <ButtonSM
          sx={{ width: "6vw" }} margin="0.5vh"
          tooltip="Manual execute start action"
          onClick={() => handleManualExecute(2)}>Start</ButtonSM>
        <Forward style={{ fontSize: "2em" }} />
        <DivExist show={zcObj?.enableSetting3}>
          <ButtonSM
            sx={{ width: "6vw" }} margin="0.5vh"
            tooltip="Manual execute immediate action"
            onClick={() => handleManualExecute(3)}>Intermediate</ButtonSM>
          <Forward style={{ fontSize: "2em" }} />
        </DivExist>
        <ButtonSM
          sx={{ width: "6vw" }} margin="0.5vh"
          tooltip="Manual execute end action"
          onClick={() => handleManualExecute(1)}>End</ButtonSM>
      </DivInline>

      <Divider10 space={5} />
      <DivExist show={tcEnable}>
        {(timeTableID1 || []).map((tid1, key) => {
          const tid2 = timeTableID2?.[key];
          const tid3 = timeTableID3?.[key];
          const tobj1 = timeTableObjAll?.[tid1];
          const tobj2 = timeTableObjAll?.[tid2];
          const tobj3 = timeTableObjAll?.[tid3];
          return (
            <Box
              key={key}
              onClick={() => {
                setOpenEdit(true);
                setEditTobj1(tobj1);
                setEditTobj2(tobj2);
                setEditTobj3(tobj3);
              }}
              sx={{
                margin: "10px 5px 10px 5px",
                padding: "0.5vh",
                border: "0.5px solid transparent",
                "&:hover": { borderColor: "#fff" },
              }}
            >
              <DivInline justifyContent="space-around">
                <TimeBox singleTimeTableID={tid2} timeTableObj={tobj2} />
                <Forward style={{ fontSize: "2em" }} />
                <DivExist show={zcObj?.enableSetting3}>
                  <TimeBox singleTimeTableID={tid3} timeTableObj={tobj3} />
                  <Forward style={{ fontSize: "2em" }} />
                </DivExist>
                <TimeBox singleTimeTableID={tid1} timeTableObj={tobj1} />
              </DivInline>
            </Box>
          );
        })}
      </DivExist>
      <EditTimeSettingModal
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          setEditTobj1({});
          setEditTobj2({});
          setEditTobj3({});
        }}
        timeTableObj1={editTobj1}
        timeTableObj2={editTobj2}
        timeTableObj3={editTobj3}
      />
    </div>
  );
}

function TimeBox({ singleTimeTableID, timeTableObj }) {
  const add0 = (n) => {
    const s = n < 10 ? "0" + n : n;
    return s;
  };
  const weekdayList = (arr) => {
    let str = "";
    (arr || []).forEach((n) => {
      const s = gs.weekDayStringOf(n);
      str = str + s + " ";
    });
    return str;
  };
  const weekday = timeTableObj?.isoWeekday;
  return (
    <DivCol
      sx={{
        border: "1px solid rgb(159,220,249)",
        borderRadius: "0.5vw",
        padding: "0.5vw",
        cursor: "pointer",
      }}
    >
      <Typography variant="caption">{singleTimeTableID}</Typography>
      <Typography>{`${add0(timeTableObj?.hour[0])}:${add0(
        timeTableObj?.minute[0]
      )}`}</Typography>
      <Typography variant="caption">
        {weekdayList(weekday)}
      </Typography>
      <DivExist show={timeTableObj?.excludeHoliday}>
        <Typography variant="caption">Exclude holiday</Typography>
      </DivExist>
      <DivExist show={timeTableObj?.onlyHoliday}>
        <Typography variant="caption">Only holiday</Typography>
      </DivExist>
    </DivCol>
  )
}