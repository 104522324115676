import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import { Typography, Box, Slider, IconButton, Tooltip } from "@mui/material";
import DivInline from "components/DivInline";
import { CopyAll } from "@mui/icons-material";
import LightBulb from "asset/svgComp/LightBulb";
import IconButtonDelete from "components/IconButtonDelete";
import IconButtonClose from "components/IconButtonClose";
import IconButtonDone from "components/IconButtonDone";
import { hideBigProgress, showBigProgress } from "actions/screenActions";
import {
  zoneControlDisconnectLight,
  zoneControlDisconnectAircon,
  updateZcTimerDeviceSetting,
  copyLightSettingInOneZc
} from "actions/zcActions";
import DivExist from "components/DivExist";
import { confirmWindow } from "actions/screenActions";
import { deviceModalPos } from "actions/mapActions";
import General from "@ecoenghk/general";
import ZcMapModalHeader from "./ZcMapModalHeader";
const gs = new General();

export default function ZcTimerLightModal({
  x,
  y,
  open,
  handleClose,
  layerProps,
  selectedLight,
  zoneControlObj,
}) {
  const [serialSetting1, setSerialSetting1] = useState({});
  const [serialSetting2, setSerialSetting2] = useState({});
  const [serialSetting3, setSerialSetting3] = useState({});
  const { lightObjAll, dispatch } = layerProps;
  useEffect(() => {
    const { setting1, setting2, setting3 } = zoneControlObj;
    setSerialSetting1(setting1[selectedLight]);
    setSerialSetting2(setting2[selectedLight]);
    setSerialSetting3(setting3?.[selectedLight] || {});
  }, [open, zoneControlObj, selectedLight]);

  const lightObj = lightObjAll[selectedLight];
  const { zoneControlName, enableSetting3 } = zoneControlObj || {};

  const updateSetting = async (settingNum, updateObj) => {
    if (settingNum === 1) {
      const newSet = { ...serialSetting1, ...updateObj };
      setSerialSetting1(newSet);
    }
    if (settingNum === 2) {
      const newSet = { ...serialSetting2, ...updateObj };
      setSerialSetting2(newSet);
    }
    if (settingNum === 3) {
      const newSet = { ...serialSetting3, ...updateObj };
      setSerialSetting3(newSet);
    }
  };
  const handleSave = async () => {
    await updateZcTimerDeviceSetting(zoneControlObj, selectedLight, lightObj.gatewayID, serialSetting1, serialSetting2, serialSetting3);
    handleClose();
  };
  const handleCopyLightSetting = async () => {
    await copyLightSettingInOneZc(zoneControlObj, selectedLight);
  }
  const handleDisconnect = async () => {
    const deviceName = lightObj?.style === "airconControl" ? "aircon" : "light";
    confirmWindow(dispatch, `Confirm remove this ${deviceName}?`, async () => {
      showBigProgress(dispatch);
      if (lightObj?.style === "airconControl")
        await zoneControlDisconnectAircon(
          zoneControlObj,
          selectedLight,
          lightObj
        );
      else
        await zoneControlDisconnectLight(
          zoneControlObj,
          selectedLight,
          lightObj
        );
      handleClose();
      hideBigProgress(dispatch);
    });
  };
  const modalWidth = window.innerWidth * 0.35;
  const modalHeight = window.innerHeight * 0.4;
  const pos = deviceModalPos(x, y, modalWidth, modalHeight, layerProps);
  return (
    <MapModal
      x={pos.x}
      y={pos.y}
      open={open}
      handleCloseModal={handleClose}
      disableCloseButton
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <ZcMapModalHeader zoneControlObj={zoneControlObj} controlledDeviceObj={lightObj} handleClose={handleClose} layerProps={layerProps} />
      <Divider10 />
      <>
        <DivInline justifyContent="center">
          <Typography sx={{ width: "4vw" }} variant="caption">
            Start
          </Typography>
          <LightSettingRow
            settingNum={2}
            updateSetting={updateSetting}
            serialSetting={serialSetting2}
            onoffField="onoffcontrolA"
            pwmField="pwm"
            enablePwm={lightObj?.type?.pwm}
          />
        </DivInline>
        <Divider10 />
        {enableSetting3 && (
          <>
            <Divider10 />
            <DivInline justifyContent="center">
              <Typography sx={{ width: "4vw" }} variant="caption">
                Inter action
              </Typography>
              <LightSettingRow
                settingNum={3}
                updateSetting={updateSetting}
                serialSetting={serialSetting3}
                onoffField="onoffcontrolA"
                pwmField="pwm"
                enablePwm={lightObj?.type?.pwm}
              />
            </DivInline>
          </>
        )}
        <Divider10 />
        <DivInline justifyContent="center">
          <Typography sx={{ width: "4vw" }} variant="caption">
            End
          </Typography>
          <LightSettingRow
            settingNum={1}
            updateSetting={updateSetting}
            serialSetting={serialSetting1}
            onoffField="onoffcontrolA"
            pwmField="pwm"
            enablePwm={lightObj?.type?.pwm}
          />
        </DivInline>
      </>
      <Divider10 />

      <IconButtonClose
        tooltip="Close dialog without saving"
        onBtnClick={handleClose}
      />
      <IconButtonDone
        tooltip="Save settings and close dialog"
        onBtnClick={handleSave}
      />
      <IconButtonDelete
        tooltip={`Disconnect device from timer ${zoneControlName}`}
        onBtnClick={handleDisconnect}
      />
      <IconButton onClick={handleCopyLightSetting} size="small">
        <Tooltip title="Copy setting to other lights">
          <CopyAll />
        </Tooltip>
      </IconButton>
    </MapModal>
  );
}

function LightSettingRow({
  settingNum,
  updateSetting,
  serialSetting,
  onoffField,
  pwmField,
  enablePwm,
}) {
  const [latestPwm, setLatestPwm] = useState(10);
  let pwmMarks = [];
  for (let i = 0; i <= 100; i += 20) {
    const obj = { value: i, label: `${i}` };
    pwmMarks = [...pwmMarks, obj];
  }
  return (
    <>
      <DivInline style={{ width: "95%" }} justifyContent="space-around">
        <Box
          sx={{
            outline: serialSetting?.[onoffField] === 0 ? "1px solid red" : "",
          }}
          onClick={() => {
            let newPwm = serialSetting?.[onoffField] === 1 ? latestPwm : serialSetting?.[pwmField];
            if (!enablePwm) newPwm = 100;
            const updateObj = {
              [onoffField]: 0,
              [pwmField]: newPwm,
            };
            updateSetting(settingNum, updateObj);
          }}
        >
          <LightBulb onoff="on" width={2.5} sizeUnit="vw" />
        </Box>
        <Box
          sx={{
            outline: serialSetting?.[onoffField] === 1 ? "1px solid red" : "",
          }}
          onClick={() => {
            setLatestPwm(serialSetting?.[pwmField]);
            updateSetting(settingNum, { [onoffField]: 1, [pwmField]: 0 });
          }}
        >
          <LightBulb onoff="off" width={2.5} sizeUnit="vw" />
        </Box>
        <DivExist show={enablePwm}>
          <Slider
            sx={{ width: "50%" }}
            size="small"
            value={serialSetting?.[pwmField] || 0}
            onChangeCommitted={(e, val) => {
              const newA = Number(val) > 0 ? 0 : 1;
              setLatestPwm(serialSetting?.[pwmField]);
              updateSetting(settingNum, {
                [onoffField]: newA,
                [pwmField]: Number(val),
              });
            }}
            marks={pwmMarks}
            max={100}
            min={0}
            step={10}
          />
        </DivExist>
      </DivInline>
    </>
  );
}
