import { Group, Path, Rect } from "react-konva";

/**
 *
 * @param {object} props
 * @param {"resume"|"cut"} props.cutResume
 * @param {number} props.x
 * @param {number} props.y
 * @param {number} props.size
 * @param {boolean} props.disabled
 * @returns
 */
export default function PowerPlug({ x, y, size, cutResume, disabled }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        fill="none"
        stroke="#000"
        strokeWidth={5}
        data="M42.491 60.757l.043 18.747"
      ></Path>
      <Path
        fill="#0fc"
        stroke="#08a"
        strokeWidth={4}
        data="M24.937 31.025s-4.16-1.43-4.452-4.57c-.346-3.728 3.98-4.682 3.98-4.682l35.47-.117c1.806.491 3.517 1.195 4.096 4.448-.785 3.935-2.576 4.18-4.264 4.804"
      ></Path>
      <Path
        fill={cutResume === "resume" ? "#4a0" : "pink"}
        stroke="#2c89a0"
        strokeWidth={4}
        data="M24.937 31.025s.027 9.993 1.23 14.459c.96 3.569 2.666 7.22 5.443 9.659 2.941 2.581 6.977 4.502 10.888 4.39 4.529-.13 9.123-2.559 12.293-5.795 2.513-2.565 3.643-6.323 4.39-9.835.855-4.007.586-12.995.586-12.995z"
      ></Path>
      {cutResume === "resume" && (
        <Path
          fill="#fd5"
          data="M47.154 36.924l-8.643.058-2.784 7.889h4.409l-1.045 9.397 9.977-9.107-4.002-.174z"
        ></Path>
      )}

      <Path
        fill="#fff"
        stroke="#08a"
        strokeWidth={3}
        data="M36.257 20.913l-7.016.045.045-10.999c.77-2.372 2.155-2.638 3.305-2.638 1.445-.066 2.654.246 3.485 2.457zM54.699 20.789l-7.016.045.045-10.999c.77-2.372 2.155-2.638 3.305-2.638 1.445-.066 2.654.246 3.485 2.457z"
      ></Path>
      {cutResume === "cut" && (
        <Path
          fill="none"
          stroke="red"
          strokeWidth={5.5}
          data="M73.166 9.485L13.097 67.972"
        ></Path>
      )}
      {disabled && (
        <Rect width={79.375} height={79.375} fill="grey" opacity={0.6} />
      )}
    </Group>
  );
}
