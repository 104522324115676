export const showBigProgress = (dispatch) => {
  dispatch({ type: "SHOW_BIG_PROGRESS" });
};
export const hideBigProgress = (dispatch) => {
  dispatch({ type: "HIDE_BIG_PROGRESS" });
};
/**
 *
 * @param {*} dispatch
 * @param {string} title
 * @param {"info"|"success"|"warning"|"error"} severity
 * @param {2000|number} timeMs
 */
export const openSnackbar = (dispatch, title, severity, timeMs) => {
  dispatch({
    type: "OPENED_SNACKBAR",
    payload: {
      title: title || "",
      severity: severity || "info",
      time: timeMs || 2000,
    },
  });
};

/**
 * @param {dispatch} dispatch
 * @param {string} title
 * @param {function} onConfirm
 */
export const confirmWindow = (
  dispatch,
  title,
  onConfirm,
  onCancel,
  callback
) => {
  dispatch({
    type: "WINDOW_CONFIRM",
    payload: {
      status: true,
      title: title,
      onConfirm: onConfirm,
      onCancel: onCancel,
      callback: callback,
    },
  });
};

/**
 * @param {dispatch} dispatch
 * @param {string} title
 * @param {function} onConfirm
 */
export const promptWindow = (dispatch, title, onConfirm, defaultValue) => {
  dispatch({
    type: "WINDOW_PROMPT",
    payload: {
      status: true,
      title: title,
      onConfirm: onConfirm,
      defaultValue: defaultValue,
    },
  });
};
/**
 * @param {dispatch} dispatch
 * @param {string} title
 */
export const alertWindow = (dispatch, title) => {
  dispatch({
    type: "WINDOW_ALERT",
    payload: {
      status: true,
      windowAlertTitle: title,
    },
  });
};
