import { mgFindAll, mgFindOne } from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchOneZc = async (dispatch, zoneControlID) => {
  const obj = await mgFindOne("zoneControl", "zoneControlID", zoneControlID);
  dispatch({
    type: "UPDATED_ZONE_CONTROL_OBJ",
    payload: obj,
  });
};
export const fetchAllZcs = async () => {
  const data = await mgFindAll("zoneControl");
  let objAll = {};
  data.forEach((obj) => {
    const s = obj.zoneControlID;
    objAll[s] = obj;
  });
  return objAll;
};
export const updateZcsFsToLs = async () => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "zoneControl",
  };

  global.socket.emit("fromWeb", payload);
};
export const updateSingleZcFsToLs = async (zoneControlID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleZoneControl",
    zoneControlID,
  };
  global.socket.emit("fromWeb", payload);
};
export const executeZoneControl = async (zoneControlObj, settingNum) => {
  const payload = {
    type: "execute_zoneControl",
    zoneControlID: zoneControlObj.zoneControlID,
    settingNum,
  };
  let msg = `[COMMAND OUT] executeZoneControl zoneControlID:${zoneControlObj.zoneControlID} settingNum:${settingNum}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
};
export const updateZcProperty = async (zoneControlID, updateObj) => {
  const jsonData = {
    zoneControlID,
    updateObj,
    type: "updateZoneControlProperty",
  };
  console.log(`[COMMAND OUT] updateZoneControlProperty ${zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const zoneControlConnectLight = async (
  zoneControlObj,
  serial,
  lightObj
) => {
  const { zoneControlID } = zoneControlObj;
  const type = "zoneControlConnectLight";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    serial,
    lightObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} ConnectLight - ${serial}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const zoneControlConnectAircon = async (
  zoneControlObj,
  serial,
  lightObj
) => {
  const { zoneControlID } = zoneControlObj;
  const type = "zoneControlConnectAircon";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    serial,
    lightObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} Connect aircon - ${serial}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const zoneControlConnectSensor = async (zoneControlObj, sensorObj) => {
  const { zoneControlID } = zoneControlObj;
  const type = "zoneControlConnectSensor";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    sensorID: sensorObj.sensorID,
    sensorObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} ConnectSensor - ${sensorObj.sensorID}`
  );
  global.socket.emit("fromWeb", jsonData);
};
// export const zoneControlConnectDaliLight = async (
//   zoneControlObj,
//   serial,
//   lightObj,
//   daliCtlObj
// ) => {
//   const { zoneControlID } = zoneControlObj;
//   const type = "zoneControlConnectDaliLight";
//   const jsonData = {
//     type,
//     zoneControlID,
//     zoneControlObj,
//     serial,
//     lightObj,
//     daliCtlObj,
//   };
//   console.log(
//     `[COMMAND OUT] zoneControl ${zoneControlID} ConnectDaliLight - ${serial}`
//   );
//   global.socket.emit("fromWeb", jsonData);
// };
export const zoneControlDisconnectLight = async (
  zoneControlObj,
  serial,
  lightObj
) => {
  const { zoneControlID } = zoneControlObj;
  const type = "zoneControlDisconnectLight";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    serial,
    lightObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} disconnectLight - ${serial}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const zoneControlDisconnectAircon = async (
  zoneControlObj,
  serial,
  lightObj
) => {
  const { zoneControlID } = zoneControlObj;
  const type = "zoneControlDisconnectAircon";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    serial,
    lightObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} disconnect aircon - ${serial}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const zoneControlDisconnectSensor = async (
  zoneControlObj,
  sensorObj
) => {
  const { zoneControlID } = zoneControlObj;
  const type = "zoneControlDisconnectSensor";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    sensorID: sensorObj.sensorID,
    sensorObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} disconnectSensor - ${sensorObj.sensorID}`
  );
  global.socket.emit("fromWeb", jsonData);
};
// export const zoneControlDisconnectDaliLight = async (
//   zoneControlObj,
//   serial,
//   lightObj,
//   daliCtlObj
// ) => {
//   const { zoneControlID } = zoneControlObj;
//   const type = "zoneControlDisconnectDaliLight";
//   const jsonData = {
//     type,
//     zoneControlID,
//     zoneControlObj,
//     serial,
//     lightObj,
//     daliCtlObj,
//   };
//   console.log(
//     `[COMMAND OUT] zoneControl ${zoneControlID} disconnectDaliLight - ${serial}`
//   );
//   global.socket.emit("fromWeb", jsonData);
// };
export const zoneControlDisconnectAllLights = async (
  zoneControlID,
  zoneControlObj
) => {
  const type = "zoneControlDisconnectAllLights";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
  };
  console.log(
    `[COMMAND OUT] zoneControl ${zoneControlID} disconnect all lights`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const createZoneControl = async (
  mapID,
  zoneControlType,
  zoneControlName,
  x,
  y
) => {
  const type = "createZoneControl";
  const jsonData = {
    type,
    zoneControlType,
    mapID,
    zoneControlName,
    x,
    y
  };
  console.log(
    `[COMMAND OUT] create zone control - ${zoneControlType} - ${zoneControlName}`
  );
  global.socket.emit("fromWeb", jsonData);
};
export const deleteZoneControl = async (
  mapID,
  zoneControlID,
  zoneControlObj
) => {
  const type = "deleteZoneControl";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    mapID,
  };
  console.log(`[COMMAND OUT] ${type}`, jsonData);
  global.socket.emit("fromWeb", jsonData);
};
export const addZcTimeSetting = async (zoneControlID, zoneControlObj) => {
  const type = "addZcTimeSetting";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
  };
  console.log(`[COMMAND OUT] addZcTimeSetting ${zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const deleteZcTimeSetting = async (
  zoneControlID,
  zoneControlObj,
  timeTableID1,
  timeTableID2,
  timeTableID3
) => {
  const type = "deleteZcTimeSetting";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    timeTableID1,
    timeTableID2,
    timeTableID3
  };
  console.log(`[COMMAND OUT] deleteZcTimeSetting ${zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
};
export const updateZcTimeSetting = async (
  zoneControlID,
  zoneControlObj,
  timeTableObj1,
  timeTableObj2,
  timeTableObj3
) => {
  const type = "updateZcTimeSetting";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    timeTableObj1,
    timeTableObj2,
    timeTableObj3
  };
  console.log(
    `[COMMAND OUT] updateZcTimeSetting ${zoneControlID}`, jsonData
  );
  global.socket.emit("fromWeb", jsonData);
};
export const enablingTimerSetting3 = async (zoneControlObj, timeTableObjAll) => {
  const jsonData = {
    type: "enablingTimerSetting3",
    zoneControlObj,
    timeTableObjAll
  }
  console.log(`[COMMAND OUT] enablingTimerSetting3 ${zoneControlObj.zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
}
export const disablingTimerSetting3 = async (zoneControlObj) => {
  const jsonData = {
    type: "disablingTimerSetting3",
    zoneControlObj
  }
  console.log(`[COMMAND OUT] disablingTimerSetting3 ${zoneControlObj.zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
}
export const saveZcLockLevel = async (
  zoneControlID,
  zoneControlObj,
  lockLevelNoArray,
  lockLevelArray,
  releaseOnDelayEnd
) => {
  const type = "saveZcLockLevel";
  const jsonData = {
    type,
    zoneControlID,
    zoneControlObj,
    releaseOnDelayEnd,
    lockLevelNoArray,
    lockLevelArray,
  };
  console.log(`[COMMAND OUT] saveZcLockLevel ${zoneControlID} `);
  global.socket.emit("fromWeb", jsonData);
};
export const copyLightSettingInOneZc = async (zoneControlObj, serial) => {
  const payload = {
    type: "copyLightSettingInOneZc",
    zoneControlObj,
    serial,
  };
  console.log(
    `[COMMAND OUT] copyLightSettingInOneZc ${zoneControlObj.zoneControlID} source light ${serial}`
  );
  global.socket.emit("fromWeb", payload);
};
export const transferSettingToZoneControl = async (
  sourceID,
  targetID,
  zoneControlObjAll
) => {
  const sourceObj = zoneControlObjAll[sourceID];
  const targetObj = zoneControlObjAll[targetID];
  const payload = {
    type: "transferSettingToZoneControl",
    sourceObj,
    targetObj,
  };
  console.log(
    `[COMMAND OUT] transferSettingToZoneControl ${sourceID} to ${targetID}`
  );
  global.socket.emit("fromWeb", payload);
};
export const updateZcTimerDeviceSetting = async (zoneControlObj, deviceID, deviceGatewayID, serialSetting1, serialSetting2, serialSetting3) => {
  // let timeTableObjArray1 = [];
  // (zoneControlObj.timeTableID1 || []).forEach((tid, i) => {
  //   const tObj = timeTableObjAll[tid];
  //   timeTableObjArray1.push(tObj);
  // });
  // let timeTableObjArray2 = [];
  // (zoneControlObj.timeTableID2 || []).forEach((tid, i) => {
  //   const tObj = timeTableObjAll[tid];
  //   timeTableObjArray2.push(tObj);
  // });
  const jsonData = {
    type: "updateZcTimerDeviceSetting",
    zoneControlID: zoneControlObj.zoneControlID,
    gatewayID: deviceGatewayID,
    deviceID,
    serialSetting1,
    serialSetting2,
    serialSetting3,
  };
  console.log(`[COMMAND OUT] updateZcTimerDeviceSetting ${zoneControlObj.zoneControlID} - ${deviceID}`);
  global.socket.emit("fromWeb", jsonData);
}
export const updateZcAllTimeSetting = async (zoneControlObj) => {
  const jsonData = {
    type: "updateZcAllTimeSetting",
    zoneControlObj
  }
  console.log(`[COMMAND OUT] updateZcAllTimeSetting ${zoneControlObj.zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
}
export const zoneControlUpdateOneLightSetting = async (zoneControlObj, lightObj, settingObj) => {
  const jsonData = {
    type: "zoneControlUpdateOneLightSetting",
    zoneControlObj,
    lightObj,
    settingObj //{setting1:{[serial]:{commandCode:0xxxx, dtkAdd:xxxx,...}}, setting2:{...}}
  }
  console.log(`[COMMAND OUT] zoneControlUpdateOneLightSetting ${zoneControlObj.zoneControlID} - serial: ${lightObj.serial} - settingObj: ${JSON.stringify(settingObj)}`);
  global.socket.emit("fromWeb", jsonData);
}
//to be deleted
export const zoneControlUpdateAllLightSetting = async (zoneControlObj, settingObj) => {
  const jsonData = {
    type: "zoneControlUpdateAllLightSetting",
    zoneControlObj,
    settingObj  // {setting1:{[serial]:{commandCode:0xxxx, dtkAdd:xxxx,...},...}, setting2:{...}}
  }
  console.log(`[COMMAND OUT] zoneControlUpdateAllLightSetting ${zoneControlObj.zoneControlID}`);
  global.socket.emit("fromWeb", jsonData);
}
export const updateZoneControlDelayTime = async (zoneControlObj, delaySec, delaySec2) => {
  const jsonData = {
    type: "updateZoneControlDelayTime",
    zoneControlObj,
    delaySec,
    delaySec2
  }
  console.log(`[COMMAND OUT] updateZoneControlDelayTime ${zoneControlObj.zoneControlID} delaySec: ${delaySec} delaySec2: ${delaySec2}`);
  global.socket.emit("fromWeb", jsonData);
}