import DivExist from "components/DivExist";
import LayerNormal from "./LayerNormal";
import LayerSerialNo from "./LayerSerialNo";
import LayerDeviceName from "./LayerDeviceName";
import LayerLightLock from "./LayerLightLock";
import LayerDataTime from "./LayerDataTime";
import LayerGatewaySelect from "./LayerGatewaySelect";
import LayerBatTest from "./LayerBatTest";
import LayerRssi255Web from "./LayerRssi255Web";
import LayerEspWeb from "./LayerEspWeb";
import Layer433Channel from "./Layer433Channel";
export default function LayerAll({ layerProps }) {
  const { mapLayer } = layerProps;

  return (
    <>
      {/* <Text
        x={80}
        y={50}
        fontSize={15}
        text={layerProps.layerScale.toFixed(2)}
        fill="red"
      /> */}
      <DivExist show={mapLayer.normal}>
        <LayerNormal layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.serialNo}>
        <LayerSerialNo layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.deviceName}>
        <LayerDeviceName layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.lockLevel}>
        <LayerLightLock layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.dataTime}>
        <LayerDataTime layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.gatewaySelect}>
        <LayerGatewaySelect layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.rssi255Web}>
        <LayerRssi255Web layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.espWeb}>
        <LayerEspWeb layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.batTest}>
        <LayerBatTest layerProps={layerProps} />
      </DivExist>
      <DivExist show={mapLayer.channel}>
        <Layer433Channel layerProps={layerProps} />
      </DivExist>
    </>
  );
}
