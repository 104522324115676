import { Circle, Group, Path } from "react-konva";

export default function PasteIcon({ x, y, size, shadEnabled }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Path
        stroke="#d45500"
        fill="#fff"
        strokeWidth={6}
        data="M57.182 35.939l.122-10.692L38.226 7.623 8.753 7.74v67.44h23.97"
      ></Path>
      <Path
        stroke="#d45500"
        strokeWidth={6}
        data="M35.414 8.68l.122 20.209h20.057"
      ></Path>
      <Path
        stroke="maroon"
        strokeWidth={6}
        data="M37.37 54.502l11.007 10.927M37.37 54.502l11.007 10.927M34.802 55.736l13.575-12.924M68.067 77.765l-.122-23.381H38.96"
      ></Path>
    </Group>
  );
}
