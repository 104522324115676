import { mapModeStr } from "asset/string/string";
import LayerLight from "./LayerLight";
import LayerGateway from "./LayerGateway";
import LayerSensor from "./LayerSensor";
import LayerDeviceToolbar from "./LayerDeviceToolbar";
import DeviceServer from "../device/DeviceServer";
import LayerZoneControl from "./LayerZoneControl";
import LayerDaliCtl from "./LayerDaliCtl";
import MapSensorLightGroup from "../device/MapSensorLightGroup";
import MapSensorSensorGroup from "../device/MapSensorSensorGroup";
import MapSensorOtherDeviceGroup from "../device/MapSensorOtherDeviceGroup";
import MapZcLightGroup from "../device/MapZcLightGroup";
import MapZcOtherDeviceGroup from "../device/MapZcOtherDeviceGroup";
import MapZcSensorGroup from "../device/MapZcSensorGroup";
import MapSlaveLightGroup from "../device/MapSlaveLightGroup";
import LayerMapDeviceMessage from "./LayerMapDeviceMessage";
export default function LayerNormal({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    serverObj,
    mapScale,
    sensorObjAll,
    zoneControlObjAll,
    mapLayer,
  } = layerProps;
  const serverID = serverObj?.serverID;
  const serverMapObj = mapObjAll[activeMapID]?.serverObj?.[serverID];
  return (
    <>
      <LayerGateway layerProps={layerProps} />
      <LayerDaliCtl layerProps={layerProps} />
      <LayerLight layerProps={layerProps} />
      <LayerSensor layerProps={layerProps} />
      <LayerZoneControl layerProps={layerProps} />
      <DeviceServer
        layerProps={layerProps}
        x={serverMapObj?.x * mapScale.x || 100}
        y={serverMapObj?.y * mapScale.y || 150}
      />
      {
        !mapLayer.gatewaySelect && layerProps.selectedID && layerProps.selectedDeviceType === "light" ? (
          <MapSlaveLightGroup layerProps={layerProps} serial={layerProps.selectedID} />
        ) : null
      }
      {!mapLayer.gatewaySelect &&
        layerProps.selectedID &&
        layerProps.selectedDeviceType === "sensor" ? (
        <>
          <MapSensorLightGroup
            sensorID={layerProps.selectedID}
            x={layerProps.selectedDeviceX}
            y={layerProps.selectedDeviceY}
            sensorObj={sensorObjAll[layerProps.selectedID]}
            layerProps={layerProps}
          />
          <MapSensorSensorGroup
            sensorID={layerProps.selectedID}
            x={layerProps.selectedDeviceX}
            y={layerProps.selectedDeviceY}
            sensorObj={sensorObjAll[layerProps.selectedID]}
            layerProps={layerProps}
          />
          <MapSensorOtherDeviceGroup layerProps={layerProps} />
        </>
      ) : null}
      {!mapLayer.gatewaySelect &&
        layerProps.selectedID &&
        layerProps.selectedDeviceType === "zoneControl" ? (
        <>
          <MapZcOtherDeviceGroup layerProps={layerProps} />
          <MapZcLightGroup
            zoneControlID={layerProps.selectedID}
            zoneControlObj={zoneControlObjAll[layerProps.selectedID]}
            layerProps={layerProps}
          />
          <MapZcSensorGroup layerProps={layerProps} />
        </>
      ) : null}
      <LayerDeviceToolbar layerProps={layerProps} />

      <LayerMapDeviceMessage layerProps={layerProps} />

    </>
  );
}
