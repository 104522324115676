export default function TestFinish({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
      <path
        fill="none"
        stroke="#08a"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={6}
        d="M74.22 5.24v69.635"
      ></path>
      <path
        fill="none"
        stroke="#08a"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={6}
        d="M5.24 5.13v69.636"
      ></path>
      <path
        fill="none"
        stroke="#08a"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={6}
        d="M5.24 11.788l69.416-.655"
      ></path>
      <path
        fill="none"
        stroke="#08a"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeOpacity="1"
        strokeWidth={6}
        d="M3.274 40.057l69.418-.655"
      ></path>
      <path
        fill="#0a8"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="3.969"
        d="M16.146 13.745H27.497V25.533H16.146z"
      ></path>
      <path
        fill="#0a8"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="3.969"
        d="M27.997 25.885H39.348V37.673H27.997z"
      ></path>
      <path
        fill="#0a8"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="3.969"
        d="M39.362 14.099H50.713V25.887H39.362z"
      ></path>
      <path
        fill="#0a8"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="3.969"
        d="M51.358 25.674H62.708999999999996V37.462H51.358z"
      ></path>
      <path
        fill="#0a8"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="3.78"
        d="M61.881 13.678H72.18V25.466H61.881z"
      ></path>
      <path
        fill="#0a8"
        stroke="none"
        strokeLinecap="round"
        strokeWidth="3.78"
        d="M7.057 25.885H17.356V37.673H7.057z"
      ></path>
    </svg>
  );
}
