import { Text, Label, Tag } from "react-konva";

export default function KonvaLabel({
  x,
  y,
  tagColor = "darkgreen",
  strokeColor = "",
  pointerDirection = "up",
  text,
  textColor,
  fontSize = 12,
  scale = 1,
  align = "left",
}) {
  return (
    <Label x={x} y={y}>
      <Tag
        fill={tagColor}
        pointerDirection={pointerDirection}
        pointerWidth={3 / scale}
        pointerHeight={3 / scale}
        lineJoin="round"
        shadowColor="black"
        shadowBlur={4 / scale}
        shadowOffsetX={4 / scale}
        shadowOffsetY={4 / scale}
        shadowOpacity={0.5}
        strokeWidth={2 / scale}
        stroke={strokeColor}
      />

      <Text
        text={text}
        fill={textColor || "white"}
        padding={2 / scale}
        fontSize={fontSize / scale}
        align={align}
      />
    </Label>
  );
}
