import { Line } from "react-konva";
import {
  lightColorLight,
  sensorColorLight,
  zoneControlColorLight,
  daliCtlColorLight,
  gatewayColorLight,
} from "actions/generalActions";
export default function LinesSensorSelected({ layerProps }) {
  const {
    selectedID,
    sensorObjAll,
    layerScale,
    mapObjAll,
    activeMapID,
    selectedDeviceX,
    selectedDeviceY,
    mapScale,
    mapLayer,
  } = layerProps;
  const sensorObj = sensorObjAll[selectedID];
  const {
    serialMap,
    sensorMap,
    daliCtlID,
    gatewayID,
    zoneControlArray,
    sensorArray,
  } = sensorObj || {};
  //zoneControlArray:  zoneControls controlling this sensor
  //sensorArray:  sensors controlling  this sensor
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj;
  const mapLightObj = mapObjAll[activeMapID]?.lightObj;
  const mapZcObj = mapObjAll[activeMapID]?.zoneControlObj;
  const mapDaliCtlPos = mapObjAll[activeMapID]?.daliCtlObj?.[daliCtlID];
  const mapGatewayPos = mapObjAll[activeMapID]?.gatewayObj?.[gatewayID];
  const deviceX = selectedDeviceX || 0;
  const deviceY = selectedDeviceY || 0;
  return (
    <>
      {Object.values(serialMap || {}).map((s, key) => (
        <Line
          key={key}
          points={[
            deviceX,
            deviceY,
            (mapLightObj?.[s]?.x * mapScale.x) || 0,
            (mapLightObj?.[s]?.y * mapScale.y) || 0,
          ]}
          stroke={lightColorLight}
          strokeWidth={2.5 / layerScale}
        />
      ))}
      {Object.values(sensorMap || {}).map((s, key) => (
        <Line
          key={key}
          points={[
            deviceX,
            deviceY,
            (mapSensorObj?.[s]?.x * mapScale.x) || 0,
            (mapSensorObj?.[s]?.y * mapScale.y) || 0,
          ]}
          stroke={sensorColorLight}
          strokeWidth={2.5 / layerScale}
        />
      ))}
      {/* zoneControlArray: zoneControls controlling this sensor */}
      {(zoneControlArray || []).map((z, key) => (
        <Line
          key={key}
          points={[
            deviceX,
            deviceY,
            (mapZcObj?.[z]?.x * mapScale.x) || 0,
            (mapZcObj?.[z]?.y * mapScale.y) || 0,
          ]}
          stroke={zoneControlColorLight}
          strokeWidth={1 / layerScale}
        />
      ))}
      {/* sensorArray: sensors controlling this sensor */}
      {(sensorArray || []).map((s, key) => (
        <Line
          key={key}
          points={[
            deviceX,
            deviceY,
            (mapSensorObj?.[s]?.x * mapScale.x) || 0,
            (mapSensorObj?.[s]?.y * mapScale.y) || 0,
          ]}
          stroke={sensorColorLight}
          strokeWidth={1 / layerScale}
        />
      ))}
      {(daliCtlID ? true : false) && (
        <Line
          points={[
            deviceX,
            deviceY,
            (mapDaliCtlPos?.x * mapScale.x) || 0,
            (mapDaliCtlPos?.y * mapScale.y) || 0,
          ]}
          stroke={daliCtlColorLight}
          strokeWidth={1 / layerScale}
        />
      )}
      {(gatewayID && !daliCtlID ? true : false) && (
        <Line
          points={[
            deviceX,
            deviceY,
            (mapGatewayPos?.x * mapScale.x) || 0,
            (mapGatewayPos?.y * mapScale.y) || 0,
          ]}
          stroke={gatewayColorLight}
          strokeWidth={1 / layerScale}
        />
      )}
    </>
  );
}
