import { useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import { useAutoBackToRoot } from "hooks/generalHooks";
import MapContainerLeft from "./MapContainerLeft";
import MapContainerBottom from "./MapContainerBottom";
import MapContainer from "./MapContainer";
import MapContainerRight from "./MapContainerRight";
import {
  useFetchDaliCtlInMap,
  useFetchGatewayInMap,
  useFetchSensorInMap,
  useFetchSerialInMap,
  useFetchTimeTableInMap,
  useFetchZoneControlInMap,
} from "hooks/mapHooks";
import { useSocketListenToWeb } from "hooks/socketHooks";
import { isTablet, isDesktop } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import { updateMapProperty } from "actions/mapActions";

export default function MapPage() {
  const [state, dispatch] = useContext(SMContext);
  const { activeMapID, mapObjAll, serverObj } = state;
  let navigate = useNavigate();
  // const location = useLocation();
  // const source = location.state?.source;
  useAutoBackToRoot();
  useFetchSerialInMap(activeMapID);
  useFetchGatewayInMap(activeMapID);
  useFetchSensorInMap(activeMapID);
  useFetchDaliCtlInMap(activeMapID);
  useFetchZoneControlInMap(activeMapID);
  useFetchTimeTableInMap(activeMapID);
  useSocketListenToWeb(navigate);
  // useEffect(() => {
  //   let mapObj = { ...mapObjAll[activeMapID] };
  //   let updateObj = {};
  //   if (!mapObj.serverObj) {
  //     updateObj.serverObj = {
  //       [serverObj.serverID]: { x: 50, y: 500 }
  //     };
  //   }
  //   if (!mapObj.lightObj) updateObj.lightObj = {};
  //   if (!mapObj.zoneControlObj) updateObj.zoneControlObj = {};
  //   if (!mapObj.sensorObj) updateObj.sensorObj = {};
  //   if (!mapObj.daliCtlObj) updateObj.daliCtlObj = {};
  //   if (!mapObj.iconSize) updateObj.iconSize = 22;
  //   if (!mapObj.buttonSize) updateObj.buttonSize = 20;
  //   if (!mapObj.mapSizeInM) updateObj.mapSizeInM = { x: 10, y: 5 };
  //   if (!mapObj.mapFileName) updateObj.mapFileName = "samplePlan.jpg";
  //   updateMapProperty(activeMapID, updateObj);
  // }, [mapObjAll, activeMapID, serverObj])
  return (
    <>
      <div
        style={{
          backgroundColor: "whitesmoke",
          height: isTablet ? "90vh" : "100vh",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex" }}>
          <MapContainerLeft vw={5} vh={isDesktop ? 85 : 75} vhToolbar={5} />
          <MapContainer vw={80} vhMap={isDesktop ? 80 : 75} vhToolbar={5} />
          <MapContainerRight vw={14} vh={isDesktop ? 85 : 75} />
        </div>
        <MapContainerBottom vw={100} vh={isDesktop ? 15 : 8} />
      </div>
    </>
  );
}
