import { useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import SkymonLogo from "asset/svgComp/SkymonLogo";
import { Typography, Box } from "@mui/material";
import DivInline from "components/DivInline";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import { isTablet, isDesktop } from "react-device-detect";
import ButtonSM from "components/ButtonSM";
import {
  getGatewayButtonContent,
  getLightButtonContent,
  getSensorButtonContent,
  getDaliCtlButtonContent,
  getZoneControlButtonContent
} from "actions/toolbarActions";
import DivExist from "components/DivExist";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

export default function MapContainerBottom({ vw, vh }) {
  const [state, dispatch] = useContext(SMContext);
  const navigate = useNavigate();
  const { activeMapID, mapObjAll, selectedID, selectedDeviceType, selectedDeviceObj } = state;
  const { mapName } = mapObjAll[activeMapID] || {};
  const btnContentArray = useMemo(() => {
    let arr = [];
    if (selectedID) {
      switch (selectedDeviceType) {
        case "light":
          arr = getLightButtonContent(selectedDeviceObj, state, dispatch, 1, 4, navigate);
          break;
        case "sensor":
          arr = getSensorButtonContent(selectedDeviceObj, state, dispatch, 1, 4, navigate);
          break;
        case "gateway":
          arr = getGatewayButtonContent(selectedDeviceObj, state, dispatch, 1, 4, navigate);
          break;
        case "daliCtl":
          arr = getDaliCtlButtonContent(selectedDeviceObj, state, dispatch, 1, 4, navigate);
          break;
        case "zoneControl":
          arr = getZoneControlButtonContent(selectedDeviceObj, state, dispatch, 1, 4, navigate);
          break;
        default:
          arr = [];
      }
    }
    return arr;
  }, [selectedID, selectedDeviceType])

  return (
    <div
      style={{
        width: `${vw}vw`,
        height: `${vh}vh`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <>
        <DivInline>
          <Box sx={{ width: "27vh", height: "9vh" }} onClick={() => console.log(state)}>
            <SkylightLogo width={16} height={5} sizeUnit="vw" background="light" />
          </Box>
          <Typography
            sx={{ color: "darkgrey", marginLeft: "1vw", marginRight: "1vw" }}
          >
            powered by
          </Typography>
          <SkymonLogo width={8} sizeUnit="vh" background="light" />
        </DivInline>
        <DivExist show={!selectedID}>
          <Typography
            variant={"h2"}
            sx={{ color: "darkgrey", marginRight: "0.5vw" }}
          >
            {mapName}
          </Typography>
        </DivExist>
        <DivExist show={selectedID}>
          <DivInline justifyContent="flex-end">
            {
              btnContentArray.map((obj, key) => (
                <ButtonSM
                  key={key}
                  sx={{ width: "4vw", height: "4vw" }}
                  onClick={obj.onClick}
                  onTap={obj.onClick}
                  tooltip={obj.tooltip}
                  mouseOverBorder={red[500]}
                  margin="0.5vw"

                >
                  {obj.svgIcon}
                </ButtonSM>
              ))
            }
          </DivInline>
        </DivExist>


      </>
    </div>
  );
}
