import { useContext, useEffect } from "react";
import { SMContext } from "context/smContext";
import SensorIcon from "components/SensorIcon";
import SensorInfoPanel from "./SensorInfoPanel";
import SensorDataLogPanel from "./SensorDataLogPanel";
import ControlDevicesMotionPanel from "./ControlDevicesMotionPanel";
import SensorStatusPanel from "./SensorStatusPanel";
import SensorDetailMotionDelayPanel from "./SensorDetailMotionDelayPanel";
import ControlDevicesLightSwPanel from "./ControlDevicesLightSwPanel";
import ControlDevicesDaylightPanel from "containers/sensor/ControlDevicesDaylightPanel";
import DivInline from "components/DivInline";
import ControlDevicesSceneButton from "./ControlDevicesSceneButton";
import ControlDevicesDaylightMotionPanel from "./ControlDevicesDaylightMotionPanel";
import DivExist from "components/DivExist";
import SensorDetailSceneBtnDelayPanel from "./SensorDetailSceneBtnDelayPanel";
import DeviceMongoModal from "components/DeviceMongoModal";
import ManualControlPanelLightSwitch from "./ManualControlPanelLightSwitch";
import ManualControlPanelSceneButton from "./ManualControlPanelSceneButton";
import Divider10 from "components/Divider10";
// import ButtonSM from "components/ButtonSM";
// import NearFieldIcon from "asset/svgComp/NearFieldIcon";
// import { Delete } from "@mui/icons-material";
import {
    // sensorDeleteAllNearFieldSetting, 
    queryOneSensorSetting,
    updateSensorProperty
} from "actions/sensorActions";
// import { confirmWindow } from "actions/screenActions";
import ControlDevicesDimControllerPanel from "./ControlDevicesDimControllerPanel";
import SensorChangeDimStepPanel from "./SensorChangeDimStepPanel";
import { deviceErrorMsg } from "actions/generalDeviceActions";
import { removeDuplicates } from "actions/generalActions";
import { useNavigate } from "react-router-dom";
import { useSocketListenToWeb } from "hooks/socketHooks";
import IconButtonBack from "components/IconButtonBack";
import { Paper, Typography } from "@mui/material";
import ThemeContainer from "components/ThemeContainer";

const boxH = "88vh";
const boxW = ["36vw", "36vw", "26vw"];
const boxSx = {
    padding: "0.5vw",
    margin: "1vh 0.5vw 1vh 0.5vw",
    border: "0.5px solid grey",
    borderRadius: "1vw",
    overflowY: "auto",
    height: boxH,
};
export default function SensorDetailPage() {
    const [state, dispatch] = useContext(SMContext);
    const navigate = useNavigate();
    useSocketListenToWeb(navigate);
    const { activeSensorID, sensorObjAll, gatewayObjAll } = state;
    const sensorObj = sensorObjAll[activeSensorID];
    const { sensorName, type, gatewayID, mapID } = sensorObj || {};
    const handleClose = () => {
        dispatch({ type: "CLOSED_SENSOR_DETAIL_MODAL" });
        navigate("/MapPage");
    };
    useEffect(() => {
        const errorMsgObj = deviceErrorMsg(sensorObj, "sensor", state);
        if (errorMsgObj[1]) queryOneSensorSetting(sensorObj, 0x07a2);
        const newMapID = removeDuplicates(mapID);
        if (newMapID.length !== mapID.length) {
            updateSensorProperty(activeSensorID, sensorObj, gatewayID, { mapID: newMapID });
        }
    }
        , [])
    return (
        <ThemeContainer background="dark">
            <Paper>
                <DivInline>
                    <IconButtonBack onBtnClick={handleClose} />
                    <DeviceMongoModal deviceObj={sensorObj}>
                        <SensorIcon
                            sensorObj={sensorObj}
                            width={3.5}
                            sizeUnit="vw"
                            margin={0}
                            gatewayConnected={gatewayObjAll[gatewayID]?.gatewayConnected}
                            backgroundColor="white"
                        />
                    </DeviceMongoModal>
                    <Typography sx={{ marginLeft: "1vw" }}>{`${sensorName} [${activeSensorID}]`}</Typography>
                </DivInline>
                <DivInline>
                    <div style={{ ...boxSx, width: boxW[0] }}>
                        <SensorInfoPanel />
                    </div>
                    <div style={{ ...boxSx, width: boxW[1] }}>
                        <SensorStatusPanel />
                        <DivExist show={type === "motion" || type === "daylightmotion"}>
                            <SensorDetailMotionDelayPanel />
                        </DivExist>
                        <DivExist show={type === "scenebutton"}>
                            <SensorDetailSceneBtnDelayPanel />
                            <ManualControlPanelSceneButton />
                        </DivExist>
                        <DivExist show={type === "lightswitch"}>
                            <ManualControlPanelLightSwitch />
                        </DivExist>
                        <DivExist show={type === 'dimcontroller'}>
                            <SensorChangeDimStepPanel />
                        </DivExist>
                        <SensorDataLogPanel />
                    </div>
                    <div style={{ ...boxSx, width: boxW[2] }}>
                        <ControlDevicesMotionPanel show={type === "motion"} />
                        <ControlDevicesDaylightPanel show={type === "daylight"} />
                        <ControlDevicesDaylightMotionPanel show={type === "daylightmotion"} />
                        <ControlDevicesLightSwPanel
                            show={type === "lightswitch" || type === "fire"}
                        />
                        <ControlDevicesSceneButton show={type === "scenebutton"} />
                        <ControlDevicesDimControllerPanel show={type === 'dimcontroller'} />
                        <Divider10 />
                        {/* <ButtonSM
                            tooltip="Delete all near field settings"
                            onClick={() => {
                                confirmWindow(dispatch, "Delete all near field settings?", () => {
                                    sensorDeleteAllNearFieldSetting(sensorObj);
                                });
                            }} >
                            <NearFieldIcon width={2} sizeUnit={'vw'} />
                            <Delete />
                        </ButtonSM> */}
                    </div>
                </DivInline>
            </Paper>
        </ThemeContainer>
    )
}