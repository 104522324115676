import { IconButton, Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function IconButtonClose({ onBtnClick, tooltip = "", color = "" }) {
  const handleAction = () => {
    onBtnClick();
  };

  return (
    <IconButton onClick={() => handleAction()} size="large" color={color}>
      <Tooltip title={tooltip}>
        <Close />
      </Tooltip>
    </IconButton>
  );
}
