import { useState, useContext } from "react";
import { Typography, Button } from "@mui/material";
import { SMContext } from "context/smContext";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import { mapAddDevice } from "actions/mapActions";
import { mgFindQuery, mgUpdateOneUpsert } from "actions/mongoApiActions";
import General from "@ecoenghk/general";
import IconButtonDelete from "components/IconButtonDelete";
import { daliAddLight } from "actions/daliCtlActions";
import { alertWindow } from "actions/screenActions";
import CardLight from "components/CardLight";
import DivCol from "components/DivCol";
import DivExist from "components/DivExist";
import IconButtonBack from "components/IconButtonBack";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import MongoLogo from "asset/svgComp/MongoLogo";
import FirestoreLogo from "asset/iconPng/firestore_logo.png";
const gs = new General();
export default function AddLightToMapPanel({ handleClose, targetX, targetY, state, dispatch, modalSize }) {
  const { activeMapID, mapObjAll, daliCtlObjAll } = state;
  const [input, setInput] = useState("");
  const [source, setSource] = useState("");
  const [lightObjArray, setLightObjArray] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const mapLightObj = mapObjAll[activeMapID]?.lightObj || {};
  const searchDb = async () => {
    const query = {
      $or: [
        { dtkAdd: input },
        { serial: input },
        { wifiApMacAddress: input },
      ]
    }
    let objArray = await mgFindQuery("serial", query);
    setSource("mongo");
    if (!objArray || objArray.length === 0) {
      console.log(`${input} not found in mongo, try fs`)
      objArray = await getDocsWhereFS("SM_serial", "array", "serial", "==", input);
      setSource("fs");
      if (!objArray || objArray.length === 0) {
        console.log(`${input} not found in fs serial, try dtkAdd`)
        objArray = await getDocsWhereFS("SM_serial", "array", "dtkAdd", "==", input);
        if (!objArray || objArray.length === 0) {
          console.log(`${input} not found in fs dtkAdd, try wifiApMacAddress`)
          objArray = await getDocsWhereFS("SM_serial", "array", "wifiApMacAddress", "==", input);
          if (!objArray || objArray.length === 0) {
            console.log(`${input} not found in fs wifiApMacAddress, search fail`)
            setErrorMsg(`ID/address/mac ${input} not found`);
            setLightObjArray([]);
          }
        }
      }
    }
    setLightObjArray(objArray);
  };
  const handleSave = async (lightObj) => {
    const { serial, daliCtlID } = lightObj || {};
    if (!serial) {
      alertWindow(dispatch, "Invalid light serial, add fail");
      return;
    }
    if (daliCtlID) {
      const daliCtlObj = daliCtlObjAll[daliCtlID];
      if (!daliCtlObj) {
        alertWindow(
          dispatch,
          "Light is under a dali controller that is not in this map, add fail"
        );
        return;
      }
    }
    if (source === "fs") {
      alertWindow(dispatch, "Light is not in local server, download from firestore first");
      await mgUpdateOneUpsert("serial", { serial }, lightObj);
      await gs.waitFor(2000);
    }
    await mapAddDevice(activeMapID, serial, "light", lightObj, targetX, targetY);
    handleClose();
  };
  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add Light to map</Typography>
      </DivInline>
      <Divider10 />
      <SpaceBetweenInput
        title="ID / address / wifiApMacAddress"
        titleVariant="body2"
        data={input}
        width="40%"
        onInput={(evt) => {
          setInput(evt.target.value);
          setErrorMsg("");
        }}
      />
      <Button fullWidth onClick={searchDb} variant="outlined" sx={{ margin: "1vw 0" }} disabled={!input} size="small"> Search</Button >
      {errorMsg && (
        <Typography sx={{ color: "red" }} align="right">
          {errorMsg}
        </Typography>
      )
      }

      <Divider10 space={5} />
      <DivInline justifyContent="center">
        {
          lightObjArray.map((lightObj, key) => {
            const { serial } = lightObj || {};
            const isInMap = mapLightObj[serial] && lightObj?.mapID?.includes(activeMapID);
            return (
              <DivCol key={key}>
                <CardLight
                  iconSize={modalSize === "small" ? 3 : 7}
                  iconSizeUnit="vh"
                  lightObj={lightObj}
                  handleSave={() => handleSave(lightObj)}
                  disableClick={isInMap ? true : false}
                />
                <DivExist show={isInMap}>
                  <Typography color="error">Already in map</Typography>
                </DivExist>

              </DivCol>
            )
          })
        }
      </DivInline>
      <Divider10 space={5} />
      <DivExist show={lightObjArray.length > 0 || errorMsg}>
        <DivInline justifyContent="center">
          <IconButtonDelete
            onBtnClick={() => {
              setLightObjArray([]);
              setInput("");
              setErrorMsg("");
              setSource("");
            }} />
        </DivInline>
      </DivExist>
      <DivExist show={lightObjArray.length > 0}>
        <Typography variant="body2">Source:</Typography>
        {
          source === "mongo" ?
            <MongoLogo width={1.5} sizeUnit="vw" /> :
            <img src={FirestoreLogo} alt="firestore" style={{ width: "1.5vw" }} />
        }
      </DivExist>
    </>
  );
}
