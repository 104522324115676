import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Box, Typography } from "@mui/material";
import DaliCtlIcon from "components/DaliCtlIcon";
import { gatewayConnected } from "actions/generalActions";
import moment from "moment";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryDaliCtlOneLightSetting } from "actions/daliCtlActions";
import DivExist from "components/DivExist";
import { deviceErrorMsg } from "actions/generalDeviceActions";
import WarningSign from "asset/svgComp/WarningSign";
import DivCol from "components/DivCol";

export default function DaliCtlStatusPanel() {
    const [state] = useContext(SMContext);
    const {
        daliCtlObjAll,
        activeDaliCtlID,
        gatewayObjAll
    } = state;
    const daliCtlObj = daliCtlObjAll[activeDaliCtlID];
    const gwConnected = gatewayConnected(gatewayObjAll[daliCtlObj?.gatewayID]);
    const errorMsgObj = deviceErrorMsg(daliCtlObj, "daliCtl", state);
    return (
        <>
            <Box sx={{ height: "20vh", width: "100%" }}>
                <Typography variant="h6" display="block" align="center">Status</Typography>
                <Divider10 />
                <DivInline justifyContent="center">
                    <DaliCtlIcon daliCtlObj={daliCtlObj} gatewayConnected={gwConnected} width={8} sizeUnit="vh" />
                </DivInline>
                <DivInline>
                    <Typography variant="caption">Bypass {daliCtlObj?.bypass ? "Yes" : "-"}</Typography>
                    <IconButtonRefresh onBtnClick={() => queryDaliCtlOneLightSetting(daliCtlObj, 0, 0x0b23)} />
                </DivInline>
                <DivExist show={!daliCtlObj?.powerOnOffStatus}>
                    <Typography variant="caption" color="error">Power off All slave lights</Typography>
                </DivExist>
                <Typography variant="caption" align="right" display="block">{moment(daliCtlObj?.timeStamp).format("YYYY-MM-DD HH:mm:ss")}</Typography>
            </Box>
            <DivExist show={Object.keys(errorMsgObj || {}).length > 0}>
                <DivInline>
                    <WarningSign width={1.5} sizeUnit="vw" />
                    <DivCol alignItems="flex-start" sx={{ marginLeft: "0.5vw" }}>
                        {(Object.keys(errorMsgObj || {})).map(code => (
                            <Typography key={code} color="error" variant="caption" display="block">{errorMsgObj[code]}</Typography>
                        ))}
                    </DivCol>
                </DivInline>
            </DivExist>
        </>
    )
}