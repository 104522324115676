import React from "react";
import { Group, Circle, Rect } from "react-konva";
import Downlight from "asset/mapComp/Downlight";
import Tube from "asset/mapComp/Tube";
import Panellight from "asset/mapComp/Panellight";
import Exit from "asset/mapComp/Exit";
import Owl from "asset/mapComp/Owl";
import moment from "moment";
import Bypass from "asset/mapComp/Bypass";
import AcRemote from "asset/mapComp/AcRemote";
import LightBulb from "asset/mapComp/LightBulb";
import { lightColorDark } from "actions/generalActions";
import TubeHalf from "asset/mapComp/TubeHalf";

export default function MapIconLight({
  x = 0,
  y = 0,
  lightStyle,
  iconSize,
  mapScale,
  onClick = () => { },
  onTap = () => { },
  onMouseOver,
  onMouseLeave,
  onDblTap = () => { },
  outline,
  shadEnabled,
  onoffstatusL,
  type,
  lightLv,
  connectStatus,
  timeStamp,
  scale,
  bypass,
  iconBackground = "transparent",
  disabledConnectStatus = false,
}) {
  let size = iconSize * mapScale.x;
  if (shadEnabled) size = size * 1.1;
  const latestDataTimeFromNow = moment().diff(moment(timeStamp), "minutes");
  let connectStatusUse;
  if (!timeStamp) {
    connectStatusUse = false;
  } else {
    if (timeStamp && latestDataTimeFromNow >= 30) {
      connectStatusUse = false;
    } else if (timeStamp && latestDataTimeFromNow < 30 && connectStatus) {
      connectStatusUse = true;
    }
  }
  return (
    <Group
      x={x}
      y={y}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onTap={onTap}
      onDblTap={onDblTap}
    >
      <Rect
        x={lightStyle === "tubeHalf" ? -size * 0.2 : -size * 0.15}
        y={-size * 0.15}
        width={lightStyle === "tubeHalf" ? size * 0.54 : size * 1.3}
        height={size * 1.3}
        fill={iconBackground}
        stroke={outline ? lightColorDark : "transparent"}
        strokeWidth={2 / scale}
        cornerRadius={5 / scale}
      />
      {lightStyle === "tube" ? (
        <Tube
          size={size}
          statusL={onoffstatusL}
          lightLv={lightLv}
          typeBatVI={type?.batVI}
          shadEnabled={shadEnabled}
        />
      ) : lightStyle === "tubeHalf" ? (
        <TubeHalf
          size={size}
          statusL={onoffstatusL}
          lightLv={lightLv}
          typeBatVI={type?.batVI}
          shadEnabled={shadEnabled}
        />
      ) : lightStyle === "downlight" ? (
        <Downlight
          size={size}
          statusL={onoffstatusL}
          lightLv={lightLv}
          typeBatVI={type?.batVI}
          shadEnabled={shadEnabled}
        />
      ) : lightStyle === "panellight" ? (
        <Panellight
          size={size}
          statusL={onoffstatusL}
          lightLv={lightLv}
          typeBatVI={type?.batVI}
          shadEnabled={shadEnabled}
        />
      ) : lightStyle === "exit" ? (
        <Exit
          size={size}
          statusL={onoffstatusL}
          shadEnabled={shadEnabled}
        // outline={outline}
        />
      ) : lightStyle === "owl" ? (
        <Owl
          size={size}
          statusL={onoffstatusL}
          shadEnabled={shadEnabled}
        // outline={outline}
        />
      ) : lightStyle === "airconControl" ? (
        <AcRemote
          size={size}
          shadEnabled={shadEnabled}
          outline={outline}
        />
      ) : (
        <LightBulb size={size} />
      )}
      {
        !disabledConnectStatus ? <Circle radius={size * 0.1} fill={connectStatusUse ? "green" : "red"} /> : null
      }
      {bypass ? (
        <Bypass size={size * 0.6} x={size * 0.7} y={size * 0.6} />
      ) : null}
    </Group>
  );
}
