import IconButton from "@mui/material/IconButton";
import { Save } from "@mui/icons-material";

const IconButtonSave = ({ onBtnClick, disabled }) => {
  const handleAction = () => {
    onBtnClick();
  };

  return (
    <IconButton onClick={() => handleAction()} disabled={disabled} size="large">
      <Save />
    </IconButton>
  );
};

export default IconButtonSave;
