import React from "react";
import { Typography, TextField, InputAdornment } from "@mui/material";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {*} props.data
 * @param {string} props.width
 * @param {function(event)} props.onInput
 * @param {function(string)} props.onInputValue
 * @param {("standard"|"outlined"|"filled")} props.variant
 * @param {boolean} props.autoSelect
 * @param {string} props.unit
 * @param {("body1"|"body2"|"h6")} props.titleVariant
 * @returns
 */
export default function SpaceBetweenInput({
  title,
  data,
  width,
  onInput,
  onInputValue,
  variant,
  autoSelect,
  unit,
  titleVariant = "body1"
}) {
  const handleFocus = (e) => {
    if (autoSelect) {
      e.target.select();
    }
  };
  const inputProps = {
    endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
  };
  const handleChange = async (event) => {
    if (onInput) await onInput(event);
    if (onInputValue) await onInputValue(event.target.value);
  }
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant={titleVariant}>{title}</Typography>
      <TextField
        value={data}
        onChange={handleChange}
        style={{ width: width ? width : "50%" }}
        inputProps={{ style: { textAlign: "right" } }}
        onFocus={handleFocus}
        variant={variant ? variant : "standard"}
        InputProps={unit ? inputProps : {}}
      />
    </div>
  );
};
