export default function Intermediate({ width, height, sizeUnit, background }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  background = background || "#fff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <path fill={background} d="M0 0.301H79.076V79.34700000000001H0z"></path>
      <path
        fill="#ff7f2a"
        d="M35.557.667h8.777l.172 22.716 8.26.172-12.39 22.027-13.08-22.2h8.605z"
      ></path>
      <path fill="#ff7f2a" d="M3.098 58.682H75.547V69.868H3.098z"></path>
      <g fill="#00f">
        <path d="M1.87 52.567l.822-1.346.673-.748h5.608l.673.748.449.972.15 24.227-.599.598-.897.524-5.31.074-.897-.897-.598-1.196zM35.525 52.797l.823-1.346.673-.748h5.608l.673.748.448.972.15 24.227-.598.598-.898.524-5.309.074-.897-.897-.598-1.196zM69.04 52.567l.823-1.346.673-.748h5.608l.673.748.448.972.15 24.227-.598.598-.898.524-5.309.075-.897-.898-.598-1.196z"></path>
      </g>
    </svg>
  );
}
