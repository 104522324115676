export default function ZcGeneral({ width, height, sizeUnit, status }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <circle
        cx="33.4"
        cy="20.284"
        r="16"
        fill="none"
        stroke="#f60"
        strokeWidth="4"
      ></circle>
      <ellipse
        cx="33.615"
        cy="20.005"
        fill="none"
        stroke="#37c8ab"
        strokeWidth="3.4"
        rx="8.889"
        ry="8.889"
      ></ellipse>
      <path
        fill="#fff"
        stroke="#006680"
        strokeWidth="3.5"
        d="M29.985 18.86c-.817.605-.192 29.287-.192 29.287l-3.731-7.443c-.49-1.032-1.243-1.872-2.956-2.015-1.242.17-2.06.412-2.62.94-.436.516-.805.716-1.074 1.23-.496 1.01-.33 2.033-.232 2.772l9.646 23.442c1.666 2.29 3.608 4.44 6.393 6.17 2.08 1.225 4.289 1.535 6.506 1.794 2.436-.003 4.797-.194 6.954-.897 2.882-1.018 5.052-2.545 6.954-4.263 1.53-1.653 3.035-3.371 4.262-5.832.615-1.406.908-3.26 1.01-5.384l-.224-14.245c-.103-.716-.49-1.36-1.165-1.933-.73-.639-1.661-.906-2.676-1.022-1.034-.139-2.03.255-3.017.779-.297-1.042-.429-2.114-1.217-3.066-.748-1.04-1.742-1.671-2.92-1.995-.786-.159-1.447-.026-2.092.146l-2.044.973c-.014-1.078-.104-2.126-.73-2.969-.572-.904-1.541-1.36-2.482-1.849-1.38-.389-2.96-.173-4.575.146l-.03-13.48c-.219-.951-.67-1.826-1.537-2.56-.7-.496-1.443-.876-2.305-.94-1.581-.017-3.397 1.11-3.906 2.214"
      ></path>
      <g fill="none" stroke="#006680" strokeLinecap="round" strokeWidth="3.5">
        <path d="M53.121 51.946l-.033-9.92M45.375 48.632l-.033-9.92M37.758 45.472l-.033-9.92"></path>
      </g>
      <g fill="#006680">
        <path d="M28.086 48.55c1.3 2.766 2.35 6.043 3.224 8.88l.3-9.727zM29.921 18.722l.194.907.97.759.468-.662.03-.291zM33.502 16.815l.66.027.009-1.896-.247-.037-.312.202z"></path>
      </g>
    </svg>
  );
}
