import { useState } from "react";
import { Box, Button, Typography, Tooltip, CircularProgress } from "@mui/material";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import Divider10 from "components/Divider10";
import SensorIcon from "components/SensorIcon";
import IconButtonRefresh from "components/IconButtonRefresh";
import {
    sensorQueryNearFieldSetting,
    sensorDeleteNearFieldSetting,
    sensorSaveNearFieldSetting,
    sensorExecuteNearFieldLightSetting,
    updateSensorProperty,
} from "actions/sensorActions";
import IconButtonDelete from "components/IconButtonDelete";
import DivExist from "components/DivExist";
import IconButtonAdd from "components/IconButtonAdd";
import { Done } from "@mui/icons-material";
import DeviceIcon from "components/DeviceIcon";
import ZoneControlIcon from "components/ZoneControlIcon";
import ButtonSM from "components/ButtonSM";
const gs = new General();

export default function SensorNearFieldPanel({ sensorObj, state, dispatch, settingNumArray, buttonNo }) {
    const [fetchingDeviceMac, setFetchingDeviceMac] = useState(null);
    const { sensorID, nearFieldSetting, type } = sensorObj || {};
    const { lightObjAll, sensorObjAll, daliCtlObjAll } = state;
    let deviceMacBySettingNum = [];
    settingNumArray.forEach(n => {
        const setting = sensorObj[`setting${n}`];
        let macArray = [];
        Object.keys(setting || {}).forEach(s => {
            const m = setting[s]?.wifiApMacAddress;
            if (m && !macArray.includes(m)) macArray.push(m);
        });
        deviceMacBySettingNum[`setting${n}`] = macArray;
    });
    // let hasNearFieldSetting = !gs.isEmptyJson(nearFieldSetting) ? true : false;
    let title = `Near Field Setting devices`;
    if (type === 'motion') {
    } else if (type === "scenebutton") {
        title += ` - Button ${buttonNo}`;
    } else if (type === "lightswitch") {
        title += ` - Switch ${buttonNo}`;
    }


    return (
        <Box>
            <Divider10 />

            {
                settingNumArray.map(n => {
                    const macArray = nearFieldSetting?.[`setting${n}`] || [];
                    return (
                        <Box key={n}>
                            <DivExist show={type !== "daylight"}>
                                <Typography variant="caption" display="block">{`Setting ${n}`}</Typography>
                            </DivExist>
                            <DivInline>
                                <DivExist show={type !== "lightswitch"}>
                                    <SensorIcon
                                        width={3}
                                        sizeUnit='vw'
                                        type={sensorObj.type}
                                        status={buttonNo === 1 ? 1 : 0}
                                        status2={buttonNo === 2 ? 1 : 0}
                                        status3={buttonNo === 3 ? 1 : 0}
                                        status4={buttonNo === 4 ? 1 : 0}
                                        disabledConnectStatus
                                        gang={sensorObj.gang}
                                    />
                                    <Typography sx={{ marginLeft: "1vw" }} variant="body2">{title}</Typography>
                                </DivExist>
                                <DivExist show={type === "lightswitch"}>
                                    <SensorIcon
                                        width={3}
                                        sizeUnit='vw'
                                        type={sensorObj.type}
                                        status={n % 2 === 1 ? 0 : 1}
                                        status2={n % 2 === 0 ? 0 : 1}
                                        status3={n % 2 === 1 ? 0 : 1}
                                        status4={n % 2 === 1 ? 0 : 1}
                                        disabledConnectStatus
                                        gang={sensorObj.gang}
                                        disabledStatus={buttonNo === 1 ? 1 : 0}
                                        disabledStatus2={buttonNo === 2 ? 1 : 0}
                                        disabledStatus3={buttonNo === 3 ? 1 : 0}
                                        disabledStatus4={buttonNo === 4 ? 1 : 0}
                                    />
                                    <Typography variant="body2">{`${title} - ${n % 2 === 1 ? "Off" : "On"}`}</Typography>
                                </DivExist>
                                <DivExist show={type !== 'daylight'}>
                                    <ButtonSM
                                        tooltip="manual execute near field setting to all lights"
                                        onClick={() => {
                                            sensorExecuteNearFieldLightSetting(sensorObj, n);
                                        }}>
                                        <ZoneControlIcon tooltip="Manual ExecuteNear Field Setting" width={1.3} sizeUnit="vw" />
                                    </ButtonSM>
                                </DivExist>

                            </DivInline>
                            <DivExist show={type === 'daylight'}>
                                <DivInline>
                                    <Typography variant="caption" display="block">Manual execute all lights</Typography>
                                    <DaylightManualBtnGroup
                                        sensorObj={sensorObj}
                                        wifiApMacAddressArray={null}
                                    />
                                </DivInline>
                            </DivExist>
                            <Divider10 />
                            {
                                (deviceMacBySettingNum[`setting${n}`] || []).map(m => {
                                    const serial = Object.keys(lightObjAll).find(k => lightObjAll[k].wifiApMacAddress === m);
                                    const sensorID = Object.keys(sensorObjAll).find(k => sensorObjAll[k].wifiApMacAddress === m);
                                    const daliCtlID = Object.keys(daliCtlObjAll).find(k => daliCtlObjAll[k].wifiApMacAddress === m);
                                    let deviceID, deviceObj, deviceType, wifiApMacAddressArray;
                                    if (daliCtlID) {
                                        deviceID = daliCtlID;
                                        deviceObj = daliCtlObjAll[daliCtlID];
                                        wifiApMacAddressArray = daliCtlObjAll[daliCtlID].wifiApMacAddressArray;
                                        deviceType = "daliCtl";
                                    } else if (sensorID) {
                                        deviceID = sensorID;
                                        deviceObj = sensorObjAll[sensorID];
                                        wifiApMacAddressArray = sensorObjAll[sensorID].wifiApMacAddressArray;
                                        deviceType = "sensor";
                                    } else {
                                        deviceID = serial;
                                        deviceObj = lightObjAll[serial];
                                        wifiApMacAddressArray = lightObjAll[serial].wifiApMacAddressArray;
                                        deviceType = "light";
                                    }
                                    return (
                                        <Box key={m}>
                                            <DivInline>
                                                <Box sx={{ width: "3vw" }}>
                                                    <DeviceIcon width={2} sizeUnit="vw" deviceType={deviceType} deviceStyle={deviceObj.style || deviceObj.type} gang={deviceObj.gang} />
                                                </Box>
                                                <Typography variant="body2" sx={{ width: "10vw" }}>{`${deviceID}@${deviceObj.dtkAdd}`}</Typography>
                                                <Typography variant="body2" sx={{ width: "10vw" }}>mac {m}</Typography>
                                                <Box sx={{ width: "10vw" }}>
                                                    <DivExist show={fetchingDeviceMac === m}>
                                                        <CircularProgress size="2vw" />
                                                    </DivExist>
                                                    <DivExist show={!fetchingDeviceMac || fetchingDeviceMac !== m}>
                                                        <DivExist show={macArray.includes(m)}>
                                                            <DivInline>
                                                                <Done />
                                                                <IconButtonDelete tooltip="Delete near field setting" onBtnClick={async () => {
                                                                    await sensorDeleteNearFieldSetting(sensorObj, wifiApMacAddressArray, n);
                                                                }} />
                                                                <DivExist show={type !== "daylight"}>
                                                                    <ButtonSM
                                                                        tooltip="manual execute near field setting to one light"
                                                                        onClick={() => {
                                                                            sensorExecuteNearFieldLightSetting(sensorObj, n, wifiApMacAddressArray);
                                                                        }}>
                                                                        <ZoneControlIcon tooltip="Manual ExecuteNear Field Setting" width={1.3} sizeUnit="vw" />
                                                                    </ButtonSM>
                                                                </DivExist>
                                                            </DivInline>
                                                        </DivExist>
                                                        <DivExist show={!macArray.includes(m)}>
                                                            <IconButtonAdd tooltip="Add near field setting" onBtnClick={async () => {
                                                                await sensorSaveNearFieldSetting(sensorObj, wifiApMacAddressArray, n);
                                                            }} />
                                                        </DivExist>
                                                    </DivExist>
                                                </Box>

                                                <Box sx={{ width: "5vw" }}>
                                                    <IconButtonRefresh tooltip="Check stored near field setting" onBtnClick={async () => {
                                                        let newNearFieldSettingMacArray = macArray.filter(mac => mac !== m);
                                                        setFetchingDeviceMac(m);
                                                        const newNearFieldSetting = { ...nearFieldSetting, [`setting${n}`]: newNearFieldSettingMacArray };
                                                        await updateSensorProperty(sensorObj.sensorID, sensorObj, sensorObj.gatewayID, { nearFieldSetting: newNearFieldSetting });
                                                        await gs.waitFor(500);
                                                        await sensorQueryNearFieldSetting(sensorObj, wifiApMacAddressArray, n);
                                                        await gs.waitFor(1000);
                                                        setFetchingDeviceMac(null);
                                                    }} />
                                                </Box>
                                            </DivInline>
                                            <DivExist show={type === "daylight" && macArray.includes(m)}>
                                                <DaylightManualBtnGroup
                                                    sensorObj={sensorObj}
                                                    wifiApMacAddressArray={wifiApMacAddressArray}
                                                />
                                            </DivExist>
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                    )
                })
            }


        </Box>
    )
}

function DaylightManualBtnGroup({ sensorObj, wifiApMacAddressArray }) {
    return (
        <DivInline>
            {
                gs.newArrayBetween(1, 11).map(m => {
                    const l = m - 1;
                    return (
                        <Box key={m}>
                            <DivInline>
                                <Typography variant="caption" display="block">{m}</Typography>
                                <SensorIcon
                                    type="daylight"
                                    status={m - 1}
                                    disabledConnectStatus
                                    luxLv={l}
                                />
                            </DivInline>
                            <ButtonSM
                                tooltip={`manual execute setting${m} lightLv${l}`}
                                onClick={() => {
                                    sensorExecuteNearFieldLightSetting(sensorObj, m, wifiApMacAddressArray);
                                }}>
                                <ZoneControlIcon width={1.3} sizeUnit="vw" />
                            </ButtonSM>
                        </Box>
                    )
                })
            }
        </DivInline>
    )
}