import DivExist from "components/DivExist";
import DeviceDaliCtl from "../device/DeviceDaliCtl";
import DeviceUnknown from "../device/DeviceUnknown";
import LinesDaliCtlSelected from "../device/LinesDaliCtlSelected";

export default function LayerDaliCtl({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    mapScale,
    daliCtlObjAll,
    selectedID,
    selectedDeviceType,
  } = layerProps;
  const activeMapObj = mapObjAll[activeMapID] || {};
  const mapDaliCtlObj = activeMapObj?.daliCtlObj;
  const daliCtlArray = Object.keys(mapDaliCtlObj || {});

  return (
    <DivExist show={daliCtlArray.length > 0}>
      {daliCtlArray.map((d, key) => {
        const x = mapDaliCtlObj[d]?.x * mapScale.x || 0;
        const y = mapDaliCtlObj[d]?.y * mapScale.y || 0;
        if (daliCtlObjAll?.[d]) {
          return (
            <DeviceDaliCtl
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              daliCtlID={d}
              daliCtlObj={daliCtlObjAll?.[d]}
            />
          );
        } else {
          return (
            <DeviceUnknown
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              deviceID={d}
              deviceType="daliCtl"
              text="D"
            />
          );
        }
      })}
      <DivExist
        show={selectedID && selectedDeviceType === "daliCtl" ? true : false}
      >
        <LinesDaliCtlSelected layerProps={layerProps} />
      </DivExist>
    </DivExist>
  );
}
