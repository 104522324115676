export default function QuestionMark({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <circle cx={39.041} cy={39.448} r={38.5} fill="#25b7d3"></circle>
      <path
        fill="#fff"
        d="M22.199 25.177c.643-1.677 1.254-3.413 2.301-4.873 1.06-1.478 2.454-2.721 3.926-3.79 1.322-.96 2.8-1.726 4.331-2.301a18.421 18.421 0 014.602-1.083c1.572-.16 3.166-.022 4.738.135 1.321.133 2.659.278 3.925.677 1.617.51 3.193 1.223 4.602 2.166a14.41 14.41 0 013.114 2.842 11.52 11.52 0 011.624 2.708 14.7 14.7 0 011.083 3.79c.131.938.055 1.896 0 2.842-.045.771-.074 1.554-.27 2.301-.238.9-.569 1.798-1.084 2.572-1.749 2.63-4.366 4.568-6.632 6.768-1.146 1.112-2.431 2.08-3.52 3.249-.394.422-.778.862-1.083 1.353a9.019 9.019 0 00-.947 2.03c-.234.743-.215 1.547-.406 2.302-.14.553-.242 1.138-.542 1.624a3.664 3.664 0 01-1.218 1.218c-.687.408-1.503.629-2.3.677-.549.033-1.11-.081-1.625-.27-.494-.183-.996-.427-1.354-.813-.411-.444-.612-1.053-.812-1.624a7.519 7.519 0 01-.406-1.895c-.063-.811.014-1.632.135-2.437.14-.92.364-1.831.677-2.707.32-.893.698-1.779 1.218-2.572.527-.802 1.22-1.484 1.895-2.165 1.575-1.59 3.382-2.931 5.009-4.467.696-.657 1.444-1.274 2.03-2.03.545-.704 1.05-1.465 1.354-2.302a6.778 6.778 0 00.406-2.436c-.019-.876-.184-1.772-.542-2.572a6.076 6.076 0 00-1.489-2.03c-.814-.727-1.793-1.323-2.842-1.625-1.042-.3-2.17-.239-3.249-.135-.97.093-1.954.278-2.842.677-.503.225-.915.615-1.354.947-.511.387-1.094.713-1.489 1.218-.521.669-.74 1.526-1.083 2.302-.572 1.295-.968 2.67-1.624 3.925-.348.665-.634 1.423-1.218 1.895-.6.484-1.4.717-2.166.812-.81.101-1.69.064-2.436-.27-.554-.25-.928-.787-1.354-1.219-.337-.341-.73-.655-.947-1.083-.188-.37-.194-.81-.271-1.218-.11-.583-.312-1.168-.27-1.76.032-.47.237-.913.405-1.353z"
      ></path>
      <circle cx={38.819} cy={60.882} r={5.25} fill="#fff"></circle>
    </svg>
  );
}
