import DivExist from "./DivExist";

/**
 *
 * @param {Object} props.style
 * @param {boolean} props.hide
 * @param {JSX.Element} props.children
 * @returns
 */
export default function DivSpaceBetween(props) {
  return (
    <DivExist show={!props.hide}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          ...props.style,
        }}
      >
        {props.children}
      </div>
    </DivExist>
  );
}
