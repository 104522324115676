import { useState, useEffect } from "react";
import {
  gatewayColorDark,
  lightColorDark,
  sensorColorDark,
  zoneControlColorDark,
} from "actions/generalActions";
import KonvaLabel from "../device/KonvaLabel";

export default function LayerDeviceName({ layerProps }) {
  const {
    layerRef,
    lightObjAll,
    sensorObjAll,
    gatewayObjAll,
    zoneControlObjAll,
    mapObjAll,
    activeMapID,
    mapScale,
    iconSize,
  } = layerProps;
  const [scale, setScale] = useState(2);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const activeMapObj = mapObjAll[activeMapID];
  const mapLightObj = activeMapObj?.lightObj;
  const mapSensorObj = activeMapObj?.sensorObj;
  const mapGatewayObj = activeMapObj?.gatewayObj;
  const mapZcObj = activeMapObj?.zoneControlObj;
  const lightArray = Object.keys(mapLightObj || {}).map((s) => lightObjAll[s]);
  const sensorArray = Object.keys(mapSensorObj || {}).map(
    (s) => sensorObjAll[s]
  );
  const gatewayArray = Object.keys(mapGatewayObj || {}).map(
    (s) => gatewayObjAll[s]
  );
  const zcArray = Object.keys(mapZcObj || {}).map((z) => zoneControlObjAll[z]);
  return (
    <>
      {lightArray.map((lightObj, key) => (
        <KonvaLabel
          key={key}
          x={mapLightObj[lightObj?.serial]?.x * mapScale.x || 0}
          y={(mapLightObj[lightObj?.serial]?.y + iconSize) * mapScale.y || 0}
          text={lightObj?.description}
          fontSize={12 / scale} //keep font size fix
          tagColor={lightColorDark}
        />
      ))}
      {sensorArray.map((sensorObj, key) => (
        <KonvaLabel
          key={key}
          x={mapSensorObj[sensorObj?.sensorID]?.x * mapScale.x || 0}
          y={
            (mapSensorObj[sensorObj?.sensorID]?.y + iconSize) * mapScale.y || 0
          }
          text={sensorObj?.sensorName}
          fontSize={12 / scale} //keep font size fix
          tagColor={sensorColorDark}
        />
      ))}
      {gatewayArray.map((obj, key) => (
        <KonvaLabel
          key={key}
          x={mapGatewayObj[obj?.gatewayID]?.x * mapScale.x || 0}
          y={(mapGatewayObj[obj?.gatewayID]?.y + iconSize) * mapScale.y || 0}
          text={obj?.description}
          fontSize={12 / scale} //keep font size fix
          tagColor={gatewayColorDark}
        />
      ))}
      {zcArray.map((obj, key) => (
        <KonvaLabel
          key={key}
          x={mapZcObj[obj?.zoneControlID]?.x * mapScale.x || 0}
          y={(mapZcObj[obj?.zoneControlID]?.y + iconSize) * mapScale.y || 0}
          text={obj?.zoneControlName}
          fontSize={12 / scale} //keep font size fix
          tagColor={zoneControlColorDark}
        />
      ))}
    </>
  );
}
