import { useContext } from "react";
import { SMContext } from "context/smContext";
import IconButtonRefresh from "components/IconButtonRefresh";
import { queryGatewayToDeviceComAllow, updateGatewayToDeviceComAllow } from "actions/gatewayActions";
import { deviceInfoOfObj } from "actions/generalActions";
import { changeSensorOneSetting, queryOneSensorSetting, querySensorComType } from "actions/sensorActions";
import { changeOneLightSetting, queryOneLightSetting, queryLightComType } from "actions/lightActions";
import { changeDaliCtlOneSetting, queryDaliCtlOneSetting, queryDaliCtlComType } from "actions/daliCtlActions";
import DivExist from "components/DivExist";
import InterfaceMesh255Panel from "./InterfaceMesh255Panel";
import InterfaceDtkPanel from "./InterfaceDtkPanel";
import DivInline from "components/DivInline";
import { Typography, Checkbox, Box, Tooltip } from "@mui/material";
import DivCol from "components/DivCol";
import Divider10 from "components/Divider10";
import General from "@ecoenghk/general";
import InterfaceEspPanel from "./InterfaceEspPanel";
const gs = new General();

export default function DeviceInterfaceTtyPanel({ deviceObj }) {
    const [state, dispatch] = useContext(SMContext);
    const { gatewayID, dtkAdd } = deviceObj || {};
    const deviceInfo = deviceInfoOfObj(deviceObj);
    const { deviceID, deviceType } = deviceInfo;
    const { dtkChannel, meshChannel } = deviceObj || {};
    const interfaceType = dtkChannel ? "DTK" : meshChannel >= 0 ? "Mesh255" : "";

    return (
        <>
            <DivInline justifyContent="space-around">
                <DivCol>
                    <Checkbox
                        checked={!deviceObj.disableDtk || false}
                        onChange={async (e) => {
                            let ttyVal = e.target.checked ? 0 : 1;
                            const updateObj = { disableTtyS3: ttyVal, disableTtyS2: ttyVal, disableDtk: ttyVal };
                            await updateGatewayToDeviceComAllow(gatewayID, deviceID, deviceType, updateObj);
                            if (deviceType === "light") {
                                await changeOneLightSetting(deviceObj, 0x023d, { disableDtk: ttyVal });
                            } else if (deviceType === "sensor") {
                                await changeSensorOneSetting(deviceObj, 0x0641, { disableDtk: ttyVal });
                            } else if (deviceType === "daliCtl") {
                                await changeDaliCtlOneSetting(deviceObj, 0x0a45, { disableDtk: ttyVal });
                            }
                        }}
                    />
                    <Tooltip title={`disableTtyS2:${deviceObj.disableTtyS2 || 0} | disableTtyS3:${deviceObj.disableTtyS3 || 0} | disableDtk:${deviceObj.disableDtk || 0}`}>
                        <Typography>{interfaceType}</Typography>
                    </Tooltip>
                </DivCol>
                <DivCol>
                    <Checkbox
                        checked={!deviceObj.disableEspNow || false}
                        onChange={async (e) => {
                            let enableTtyS7 = e.target.checked ? 1 : 0;
                            const disableEspNow = enableTtyS7 === 1 ? 0 : 1;
                            await updateGatewayToDeviceComAllow(gatewayID, deviceID, deviceType, { enableTtyS7, disableEspNow });
                            const updateObj = { disableEspNow };
                            if (deviceType === "light") {
                                await changeOneLightSetting(deviceObj, 0x229, updateObj);
                            } else if (deviceType === "sensor") {
                                await changeSensorOneSetting(deviceObj, 0x0626, updateObj);
                            } else if (deviceType === "daliCtl") {
                                await changeDaliCtlOneSetting(deviceObj, 0x0a23, updateObj);
                            }
                        }}
                    />
                    <Tooltip title={`enableTtyS7:${deviceObj.enableTtyS7 || 0} | disableEspNow:${deviceObj.disableEspNow || 0}`}>
                        <Typography>ESP</Typography>
                    </Tooltip>
                </DivCol>
                <IconButtonRefresh onBtnClick={async () => {
                    queryGatewayToDeviceComAllow(gatewayID, deviceObj);
                    if (deviceType === "light") {
                        await queryOneLightSetting(deviceObj, 0x0316);
                        await gs.waitFor(500);
                        await queryOneLightSetting(deviceObj, 0x0325);
                    } else if (deviceType === "sensor") {
                        await queryOneSensorSetting(deviceObj, 0x071c);
                        await gs.waitFor(500);
                        await queryOneSensorSetting(deviceObj, 0x072f);
                    } else if (deviceType === "daliCtl") {
                        await queryDaliCtlOneSetting(deviceObj, 0x0b11);
                        await gs.waitFor(500);
                        await queryDaliCtlOneSetting(deviceObj, 0x0b24);

                    }
                }} />
            </DivInline>
            <Divider10 />
            <Typography>ESP interface</Typography>
            <DivInline>
                <Box sx={{ width: "2vw" }}></Box>
                <Box sx={{ width: "100%" }}>
                    <InterfaceEspPanel deviceType={deviceType} deviceObj={deviceObj} />
                </Box>
            </DivInline>
            <Divider10 />
            <DivInline>
                <Typography >{interfaceType} interface</Typography>
                <IconButtonRefresh onBtnClick={
                    async () => {
                        if (deviceType === "light") await queryLightComType(deviceObj);
                        else if (deviceType === "sensor") await querySensorComType(deviceObj);
                        else if (deviceType === "daliCtl") await queryDaliCtlComType(deviceObj);
                    }}
                />
            </DivInline>
            <DivExist show={interfaceType === "Mesh255"}>
                <DivInline>
                    <Box sx={{ width: "2vw" }}></Box>
                    <Box sx={{ width: "100%" }}>
                        <InterfaceMesh255Panel
                            deviceID={deviceID}
                            deviceObj={deviceObj}
                            deviceType={deviceType}
                        />
                    </Box>
                </DivInline>
            </DivExist>
            <DivExist show={interfaceType === "DTK"}>
                <Box sx={{ width: "2vw" }}></Box>
                <Box sx={{ width: "100%" }}>
                    <InterfaceDtkPanel
                        deviceID={deviceID}
                        deviceObj={deviceObj}
                        deviceType={deviceType}
                    />
                </Box>
            </DivExist>

        </>
    )
}