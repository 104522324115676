import { useState, useEffect, useMemo, Fragment } from "react";
import KonvaLabel from "../device/KonvaLabel";
import { lightColorDark, lightColorLight } from "actions/generalActions";
import { batTestTimeInfo } from "actions/timetableActions";

export default function LayerBatTest({ layerProps }) {
    const { layerRef, activeMapID, lightObjAll, mapObjAll, mapScale, iconSize, timeTableArrAll } = layerProps;
    const [scale, setScale] = useState(2);
    useEffect(() => {
        const layer = layerRef.current;
        const layerScale = layer.scaleX();
        setScale(layerScale);
    }, []);

    const activeMapObj = mapObjAll[activeMapID];

    const mapLightObj = activeMapObj?.lightObj || {};
    const mapLightArray = Object.keys(mapLightObj).filter(s => lightObjAll[s]?.type?.batVI);
    const batTestTimeBySerial = useMemo(() => {
        let obj = {};
        mapLightArray.forEach(s => {
            obj[s] = batTestTimeInfo(timeTableArrAll, s, "MM", "YY");
        });
        return obj;
    }, [timeTableArrAll, mapLightArray]);
    return (
        <>
            {
                mapLightArray.map(s => {
                    const lightObj = lightObjAll[s] || {};
                    const batTestTime = batTestTimeBySerial[s];
                    return (
                        <Fragment key={s}>
                            <KonvaLabel
                                x={mapLightObj[s]?.x * mapScale.x || 0}
                                y={(mapLightObj[s]?.y + iconSize) * mapScale.y || 0}
                                text={`${lightObj.voltage || "-"}V\n${lightObj.current || "-"}A`}
                                fontSize={12 / scale} //keep font size fix
                                tagColor={lightColorDark}
                            />
                            <KonvaLabel
                                x={(mapLightObj[s]?.x + (1.1 * iconSize)) * mapScale.x || 0}
                                y={(mapLightObj[s]?.y + (0.3 * iconSize)) * mapScale.y || 0}
                                text={`Next test:\n${batTestTime.latestTestTsr || "-"}`}
                                fontSize={12 / scale}
                                tagColor={lightColorDark}
                                pointerDirection="left"
                            />
                        </Fragment>
                    )
                })
            }
        </>
    );
}
