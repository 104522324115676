import { useContext, useState, useEffect } from "react";
import { Typography, Button, Paper, Box, TextField } from "@mui/material";
import { Email, VpnKey } from "@mui/icons-material";
import { SMContext } from "context/smContext";
import { useNavigate } from "react-router-dom";
import DivCol from "components/DivCol";
import DivInline from "components/DivInline";
import SkymonLogo from "asset/svgComp/SkymonLogo";
import Footer from "components/Footer";
import SkylightLogo from "asset/svgComp/SkylightLogo";
import DivExist from "components/DivExist";
import General from "@ecoenghk/general";
import packageJson from "../../../package.json";
import { alertWindow } from "actions/screenActions";
import FirebaseSM from "@ecoenghk/firebaseweb";
const fs = new FirebaseSM();
const gs = new General();

export default function LoginFsPage() {
  const [state, dispatch] = useContext(SMContext);
  const { storedEmail } = state;
  const navigate = useNavigate();
  const [email, setEmail] = useState(storedEmail || "");
  const [password, setPassword] = useState("");
  const [clickCount, setClickCount] = useState(0);
  fs.authChange(
    async (user) => {
      console.log("LOGGED IN", user.email, user.uid);
      if (user.uid) {
        const userObj = await fs.getDocFS(`SM_user/${user.uid}`);
        console.log(userObj);

        dispatch({
          type: "FETCHED_USER",
          payload: { ...userObj, enableFS: true, fs: fs },
        });
        navigate("/HomePage");
      }
    },
    async () => {
      console.log("LOGGED OUT");
    }
  );

  const keyPressed = (evt) => {
    if (evt.key === "Enter" || evt.which === "13") {
      handleLogin();
    }
  };
  useEffect(() => {
    if (clickCount >= 3) {
      setEmail("admin@skymon.com.hk");
      setPassword("123456");
    }
  }, [clickCount]);
  const handleLogin = async () => {
    await fs.signInFB(email, password);
    // navigate("/HomePage");
  };

  return (
    <Paper sx={{ background: "transparent" }}>
      <DivInline justifyContent="center">
        <Box
          sx={{
            marginTop: "4vh",
            width: "50vw",
            height: "85vh",
          }}
        >
          <DivCol>
            <SkylightLogo width={30} height={10} sizeUnit="vw" />
            <Typography variant="body2" sx={{ margin: "3vh" }}>
              Computerized Lighting Management System
            </Typography>
            <DivInline>
              <TextField
                style={{ width: "40vw" }}
                name="email"
                label="Email"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(evt) => setEmail(evt.target.value)}
                margin="normal"
                onFocus={(e) => e.target.select()}
              />
              <Email
                onClick={() => {
                  setClickCount((c) => c + 1);
                }}
              />
            </DivInline>
            <DivInline>
              <TextField
                style={{ width: "40vw" }}
                label="Password"
                type="password"
                placeholder="password"
                value={password}
                onChange={(evt) => setPassword(evt.target.value)}
                margin="normal"
                onKeyPress={keyPressed}
                onFocus={(e) => e.target.select()}
              />
              <VpnKey style={{ color: "grey" }} />
            </DivInline>
            {/* <DivInline>
              <TextField
                style={{ width: "40vw" }}
                label={serverName ? `server IP [${serverName}]` : "server IP"}
                placeholder="server express IP"
                value={ip}
                onChange={(evt) => setIp(evt.target.value)}
                margin="normal"
                onKeyPress={keyPressed}
                onFocus={(e) => e.target.select()}
              />
              <Http />
            </DivInline> */}
            <Button
              variant="outlined"
              onClick={handleLogin}
              sx={{ marginTop: "4vh", marginBottom: "6vh" }}
              color="inherit"
            >
              Log in
            </Button>
            <Typography>Cloud version</Typography>

            <DivInline
              justifyContent="center"
              alignItems="center"
              style={{ margin: "6vh" }}
            >
              <Typography variant="caption" sx={{ marginRight: "1vw" }}>
                Powered by
              </Typography>
              <SkymonLogo
                background="dark"
                width={6}
                sizeUnit="vw"
                onClick={() => setClickCount((c) => c - 1)}
              />
            </DivInline>
          </DivCol>
        </Box>
      </DivInline>

      <Footer />
    </Paper>
  );
}
