import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button } from "@mui/material";
import Divide10 from "components/Divider10";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import { doManualTest, fetchOneLight } from "actions/lightActions";
import General from "@ecoenghk/general";
import { alertWindow } from "actions/screenActions";
const gs = new General();

export default function ManualCheckStartModal({ disableManualCheck }) {
  const [state, dispatch] = useContext(SMContext);
  const { activeSerial, lightObjAll } = state;
  const [open, setOpen] = useState(false);
  const [time_pretest, setTime_pretest] = useState(1);
  const [time_cutOff, setTime_cutOff] = useState(2);
  const [time_recharge, setTime_recharge] = useState(1);
  const [time_interval, setTime_interval] = useState(13);
  const handleStartTest = async () => {
    const lightObj = lightObjAll[activeSerial];
    if (!lightObj?.minVRequired || !lightObj?.minIRequired) {
      alertWindow(dispatch, "Minimum voltage and current requirements not found, no action");
      return;
    }
    await doManualTest(
      activeSerial,
      lightObj,
      time_pretest,
      time_cutOff,
      time_recharge,
      time_interval
    );
    await gs.waitFor(2000);

    // await fetchOneLight(dispatch, activeSerial);

    closeModal();
  };
  function chkNum(input) {
    if (Number.isInteger(input) && input > 0) {
      return true;
    } else {
      return false;
    }
  }
  const disableBtn =
    chkNum(time_pretest) && chkNum(time_cutOff) && chkNum(time_recharge)
      ? false
      : true;

  function closeModal() {
    setOpen(false);
  }
  return (
    <>
      <Button
        style={{ width: "40%", height: "10vh" }}
        onClick={() => setOpen(true)}
        size="large"
        variant="outlined"
        disabled={disableManualCheck}
      >
        Manual Test
      </Button>
      <ModalSM open={open} onClose={closeModal}>
        <Typography>Manual Test Start</Typography>
        <Divider10 />
        <SpaceBetweenInput
          title="Time before power cut [min]"
          data={time_pretest || 0}
          onInput={(evt) => setTime_pretest(Number(evt.target.value))}
          width="20%"
          autoSelect
        />
        <SpaceBetweenInput
          title="Time of battery discharge [min]"
          data={time_cutOff || 0}
          onInput={(evt) => setTime_cutOff(Number(evt.target.value))}
          width="20%"
          autoSelect
        />
        <SpaceBetweenInput
          title="Time battery recharge after power cut [min]"
          data={time_recharge || 0}
          onInput={(evt) => setTime_recharge(Number(evt.target.value))}
          width="20%"
          autoSelect
        />
        <SpaceBetweenInput
          title="Data upload time interval [second]"
          data={time_interval || 0}
          onInput={(evt) => setTime_interval(Number(evt.target.value))}
          width="20%"
          autoSelect
        />
        <Divide10 />
        <Button
          fullWidth
          onClick={handleStartTest}
          variant="outlined"
          disabled={disableBtn}
        >
          Start Test
        </Button>
        <Divider10 />
      </ModalSM>
    </>
  );
}
