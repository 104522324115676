import React from 'react';
import DivInline from 'components/DivInline';
import { SwipeRightAlt } from '@mui/icons-material';
import DivCol from 'components/DivCol';
import SensorIcon from 'components/SensorIcon';
import DeviceIcon from 'components/DeviceIcon';
import SenLightSettingCheckModal from 'containers/map/modal/SenLightSettingCheckModal';
import { Typography } from '@mui/material';
import IconButtonBack from 'components/IconButtonBack';


export default function SensorMapModalHeader({ sensorObj, deviceObj, handleClose, layerProps }) {
    const deviceID = deviceObj.serial || deviceObj.sensorID || deviceObj.daliCtlID;
    const deviceType = deviceObj.serial ? "light" : deviceObj.sensorID ? "sensor" : deviceObj.daliCtlID ? "daliCtl" : "";
    return (
        <DivInline>
            <IconButtonBack
                tooltip="Close dialog without saving"
                onBtnClick={handleClose}
            />
            <DivCol>
                <SensorIcon
                    type={sensorObj.type}
                    width={2.5}
                    sizeUnit="vw"
                    disabledConnectStatus
                    gang={sensorObj.gang}
                />
                <Typography variant="caption">{sensorObj.sensorID}</Typography>
            </DivCol>
            <SwipeRightAlt sx={{ fontSize: "2rem", margin: "0 2vw" }} />
            <SenLightSettingCheckModal sensorObj={sensorObj} lightObj={deviceObj} settingNumArray={[1]} layerProps={layerProps}>
                <DivCol>
                    <DeviceIcon
                        deviceType={deviceType}
                        deviceStyle={deviceObj.style || deviceObj.type}
                        width={2.5}
                        sizeUnit="vw"
                        gang={deviceObj.gang}
                    />
                    <Typography variant="caption">{deviceID}</Typography>
                </DivCol>
            </SenLightSettingCheckModal>
        </DivInline>
    )
}