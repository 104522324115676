import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMemo } from "react";
/**
 *
 * @param {object} props
 * @param {("light"|"dark")} [props.background] - default "light"
 * @param {JSX.Element} props.children
 * @returns
 */
export default function ThemeContainer({ children, background }) {
  const theme = useMemo(() =>
    createTheme({
      palette: {
        mode: background || "light",
      },
    })
  );
  return (
    <>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </>
  );
}
