import { Line, Group, Path, Ellipse, Rect } from "react-konva";
import { lightColorAt } from "actions/generalActions";
import ChargeIcon from "./ChargeIcon";

export default function TubeHalf({
    x,
    y,
    size,
    statusL,
    lightLv,
    disabledStatus,
    typeBatVI,
    shadEnabled,
    backgroundColor,
}) {
    const scale = size / 80;
    if (!typeBatVI) statusL = 0;
    const lightColor = lightColorAt(disabledStatus, statusL, lightLv);
    return (
        <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
            <Group>
                {
                    backgroundColor ? (
                        <Rect
                            x={0}
                            y={0}
                            width={40}
                            height={80}
                            fill={backgroundColor}
                        />
                    ) : null
                }

                <Ellipse
                    x={20}
                    y={40}
                    fill={lightColor}
                    radiusX={14}
                    radiusY={40}
                    rotation={0}
                    shadowEnabled={shadEnabled}
                    shadowColor="black"
                    shadowBlur={2}
                    shadowOffset={{ x: 5, y: 5 }}
                    shadowOpacity={0.5}
                    perfectDrawEnabled={false}
                />
                <Path
                    stroke="#22b3fd"
                    strokeLinejoin="round"
                    strokeWidth={2.285}
                    data="M14.987 70.672l11.115.243-.218-60.823-11.115-.243z"
                ></Path>
                <Path
                    stroke="#918a6f"
                    strokeLinejoin="round"
                    strokeWidth={2.323}
                    data="M14.976 75.397l.01-4.725 11.116.243.1 4.948z"
                ></Path>
                <Rect
                    x={14.8}
                    y={5}
                    stroke="#918a6f"
                    strokeWidth={2.323}
                    width={11.1}
                    height={5}
                />
                <Line stroke="#918a6f" strokeWidth={2.266} points={[17, 2.6, 17, 4]} />
                <Line stroke="#918a6f" strokeWidth={2.266} points={[24, 2.6, 24, 4]} />
                <Path
                    stroke="#918a6f"
                    strokeLinecap="round"
                    strokeWidth={2.266}
                    data="M23.525 78.326l.023-1.792M17.803 78.196l.023-1.792"
                ></Path>
            </Group>
            {(!disabledStatus && typeBatVI) ? (
                <ChargeIcon y={15} x={-15} statusL={statusL} />
            )
                :
                null

            }
        </Group>
    );
}
