import { useContext } from "react";
import Typography from "@mui/material/Typography";
import { SMContext } from "context/smContext";
import packageJson from "../../package.json";
import SkymonLogo from "asset/svgComp/SkymonLogo";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";

export default function Footer({ textColor = "#fff", serverVer = "" }) {
  const [state] = useContext(SMContext);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: textColor,
        marginTop: "0.5vh",
      }}
    >
      <DivInline style={{ marginLeft: "1vw" }}>
        <SkymonLogo background="dark" width={3.8} sizeUnit="vw" />
        <Typography sx={{ marginLeft: "0.5vw" }} variant="caption">
          Skymon Systems Limited 2024
        </Typography>
      </DivInline>
      <DivCol sx={{ marginRight: "2vw" }} alignItems="flex-end">
        <Typography variant="caption">
          SKYLIGHT webapp v{packageJson.version}
        </Typography>
        <Typography variant="caption" >
          Server v{serverVer || state.serverObj?.serVersion}
        </Typography>
      </DivCol>
    </div>
  );
}
