import {
  mgDeleteNestedJsonKey,
  mgFindAll,
  mgFindByMatchAndRange,
  mgFindOne,
  mgUpdateNestedJsonKey,
  mgUpdateOneUpsert,
} from "./mongoApiActions";
import General from "@ecoenghk/general";
const gs = new General();

export const fetchOneDaliCtl = async (dispatch, daliCtlID) => {
  const obj = await mgFindOne("daliCtl", "daliCtlID", daliCtlID);
  dispatch({
    type: "UPDATE_DALICTL_OBJ",
    payload: obj,
  });
};
export const fetchAllDaliCtls = async () => {
  const data = await mgFindAll("daliCtl");
  console.log(data);
  let objAll = {};
  data.forEach((obj) => {
    const d = obj.daliCtlID;
    objAll[d] = obj;
  });
  return objAll;
};
export const updateDaliCtlsFsToLs = async (socket) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "daliCtl",
  };
  await socket.emit("fromWeb", payload);
};
export const updateSingleDaliCtlFsToLs = async (socket, daliCtlID) => {
  const payload = {
    type: "updateServerDataFromFB",
    updateType: "singleDaliCtl",
    daliCtlID,
  };
  await socket.emit("fromWeb", payload);
  console.log("emit socket", payload);
};
export const updateDaliCtlProperty = async (
  daliCtlID,
  updateObj,
  updateSetting
) => {
  let payload = {
    type: "updateDaliCtlProperty",
    daliCtlID,
    updateObj,
    updateSetting: updateSetting || false,
  };
  console.log(`[COMMAND OUT] updateDaliCtlProperty ${daliCtlID}`);
  global.socket.emit("fromWeb", payload);
};
export const daliInitialise = async (daliCtlObj) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a03
  };
  console.log(`[COMMAND OUT] daliInitialise ${daliCtlObj.daliCtlID}`);
  global.socket.emit("fromWeb", payload);
};
export const daliScanShortAdd = async (daliCtlObj, dispatch) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a05
  };
  console.log(`[COMMAND OUT] dali${daliCtlObj.daliCtlID} Scan ShortAdd`);
  global.socket.emit("fromWeb", payload);
  dispatch({
    type: "UPDATED_DALICTL_PROPERTY",
    payload: {
      daliCtlID: daliCtlObj.daliCtlID,
      updateObj: { scanning: true },
    },
  });
};
export const daliBroadcastLight = async (daliCtlObj, arcLv) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    arcLv,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a07
  };
  console.log(`[COMMAND OUT] daliBroadcastLight ${daliCtlObj.daliCtlID} arcLv ${arcLv}`);
  global.socket.emit("fromWeb", payload);
};
export const daliControlMultiLight = async (
  daliCtlObj,
  lockLevel,
  releaseOnDelayEnd,
  lightLvAry,
  delayedAction,
  D1LightLvAry,
  delayedAction2,
  D2LightLvAry
) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    lockLevel,
    releaseOnDelayEnd,
    lightLvAry,
    dtkAdd: daliCtlObj.dtkAdd,
    // commandCode: 0x0a19
    commandCode: 0x0a39
  };
  if (delayedAction) {
    // payload.commandCode = 0x0a1a;
    payload.commandCode = 0x0a3a;
    payload.delayedAction = delayedAction;
    payload.D1LightLvAry = D1LightLvAry;
  }
  if (delayedAction2) {
    // payload.commandCode = 0x0a1b;
    payload.commandCode = 0x0a3b;
    payload.delayedAction = delayedAction;
    payload.D1LightLvAry = D1LightLvAry;
    payload.delayedAction2 = delayedAction2;
    payload.D2LightLvAry = D2LightLvAry;
  }

  console.log(`[COMMAND OUT] daliBroadcastLight ${daliCtlObj.daliCtlID} arcLv ${lightLvAry}`);
  global.socket.emit("fromWeb", payload);
};
export const cancelDaliScan = async (daliCtlObj, dispatch) => {
  let payload = {
    type: "control_dali_ctl",
    controlMode: "BB",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a12
  };
  console.log(`[COMMAND OUT] cancelDaliScan ${daliCtlObj.daliCtlID}`);
  global.socket.emit("fromWeb", payload);
  dispatch({
    type: "UPDATED_DALICTL_PROPERTY",
    payload: {
      daliCtlID: daliCtlObj.daliCtlID,
      updateObj: { scanning: false },
    },
  });
};
export const daliChangeFadeTime = async (daliCtlObj, shortAdd, fadeTime) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    fadeTime,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a0a
  };
  console.log(
    `[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} add ${shortAdd} change fadeTime to code ${fadeTime}`
  );
  global.socket.emit("fromWeb", payload);
};
export const daliChangeShortAdd = async (daliCtlObj, currentAdd, newAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    currentAdd,
    newAdd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a06
  };
  console.log(
    `[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} change shortAdd from ${currentAdd} to ${newAdd}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const clearDaliReply = async (daliCtlID) => {
  await updateDaliCtlProperty(daliCtlID, { daliReply: {} }, false);
};
export const daliDeleteLight = async (daliCtlObj, serial, shortAdd) => {
  let payload = {
    daliCtlID: daliCtlObj?.daliCtlID,
    daliCtlObj,
    shortAdd,
    serial,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a1e
  };
  console.log(
    `[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} delete Light shortAdd ${shortAdd}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const daliDeleteSensor = async (
  daliCtlObj,
  sensorID,
  shortAdd,
  instance
) => {
  let payload = {
    daliCtlID: daliCtlObj?.daliCtlID,
    shortAdd,
    sensorID,
    instance,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a1f
  };
  console.log(
    `[COMMAND OUT] ${daliCtlObj.daliCtlID} dali delete Sensor shortAdd ${shortAdd} instance ${instance}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const daliUpdateSensor = async (
  socket,
  daliCtlObj,
  sensorID,
  shortAdd,
  instance,
  sensorType
) => {
  const payload = {
    type: "daliUpdateSensor",
    daliCtlID: daliCtlObj?.daliCtlID,
    daliCtlObj,
    shortAdd,
    sensorID,
    instance,
    sensorType,
  };
  await socket.emit("fromWeb", payload);
  console.log("emit socket", payload);
};
export const daliAddLight = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "daliAddLight",
    daliCtlID: daliCtlObj.daliCtlID,
    daliCtlObj,
    shortAdd,
  };
  console.log(
    `[COMMAND OUT] ${daliCtlObj.daliCtlID} daliAddLight shortAdd ${shortAdd} `
  );
  await global.socket.emit("fromWeb", payload);
};
export const daliAddSensor = async (
  daliCtlObj,
  shortAdd,
  instance,
  sensorType
) => {
  let payload = {
    type: "daliAddSensor",
    daliCtlID: daliCtlObj.daliCtlID,
    daliCtlObj,
    shortAdd,
    instance,
    sensorType,
  };
  await global.socket.emit("fromWeb", payload);
  console.log(
    `[COMMAND OUT]  ${daliCtlObj.daliCtlID} daliAddSensor ${sensorType} shortAdd ${shortAdd} instance ${instance} `
  );
};
export const daliDeleteAllLights = async (daliCtlObj) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a1c
  };
  console.log(`[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} deleteAllLights`);
  global.socket.emit("fromWeb", payload);
};
export const daliDeleteAllSensors = async (daliCtlObj) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a1d
  };
  console.log(
    `[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} daliDeleteAllSensors`
  );
  await global.socket.emit("fromWeb", payload);
};
export const daliReset = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a0c
  };
  console.log(`[COMMAND OUT] daliReset ${daliCtlObj?.daliCtlID} shortAdd ${shortAdd}`);
  await global.socket.emit("fromWeb", payload);
};
export const releaseDaliLockLevelAll = async (daliCtlObj) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a0f
  };
  console.log(
    `[COMMAND OUT] releaseDaliLockLevelAll ${daliCtlObj?.daliCtlID} `
  );
  await global.socket.emit("fromWeb", payload);
};
export const daliCtlCheckConnection = async (daliCtlObj) => {
  const payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj.daliCtlID,
    gatewayID: daliCtlObj.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a01,
    disconnectIcon: true
  };
  console.log(`[COMMAND OUT] daliCtlCheckConnection ${daliCtlObj.daliCtlID} [${daliCtlObj.dtkAdd}]`);
  global.socket.emit("fromWeb", payload);
};
export const daliChangeMinLv = async (daliCtlObj, shortAdd, minLv) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    minLv: Number(minLv),
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: shortAdd === 100 ? 0x0a17 : 0x0a15
  };
  let msg = `[COMMAND OUT] dali ${daliCtlObj?.daliCtlID} -`;
  if (shortAdd === 100) msg += " broadcast";
  else msg += ` #${shortAdd}`;
  msg += `change minLv ${minLv}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
};
export const daliChangeMaxLv = async (daliCtlObj, shortAdd, maxLv) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    maxLv: Number(maxLv),
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: shortAdd === 100 ? 0x0a18 : 0x0a16
  };
  let msg = `[COMMAND OUT] dali ${daliCtlObj?.daliCtlID}`;
  if (shortAdd === 100) msg += " broadcast";
  else msg += ` #${shortAdd}`;
  msg += `change maxLv ${maxLv}`;
  console.log(msg);
  global.socket.emit("fromWeb", payload);
};
export const daliCtlQueryDtkInfo = async (daliCtlObj) => {
  const payload = {
    type: "control_dali_ctl",
    commandCode: 0x0b80,
    gatewayID: daliCtlObj.gatewayID,
    daliCtlID: daliCtlObj.daliCtlID,
    dtkAdd: daliCtlObj.dtkAdd,
  };
  console.log(
    `[COMMAND OUT] daliCtlQueryDtkInfo ${daliCtlObj.daliCtlID} [${daliCtlObj.dtkAdd}]`
  );
  global.socket.emit("fromWeb", payload);
};
export const daliCtlChangeDtkInfo = async (daliCtlObj, dtkInfo) => {
  const payload = {
    type: "control_dali_ctl",
    commandCode: 0x0a80,
    gatewayID: daliCtlObj.gatewayID,
    daliCtlID: daliCtlObj.daliCtlID,
    dtkAdd: daliCtlObj.dtkAdd,
    dtkType: dtkInfo.dtkType,
    dtkPanID: dtkInfo.dtkPanID,
    dtkChannel: dtkInfo.dtkChannel,
    dtkTransferMode: dtkInfo.dtkTransferMode,
    definedAdd: dtkInfo.definedAdd,
    loraParameter: dtkInfo.loraParameter,
  };
  console.log(
    `[COMMAND OUT] daliCtlChangeDtkInfo ${daliCtlObj.daliCtlID} [${daliCtlObj.dtkAdd}] ${JSON.stringify(payload)}`
  );
  global.socket.emit("fromWeb", payload);
}
export const daliCtlDeleteDtk = async (daliCtlObj) => {
  const payload = {
    type: "daliCtlDeleteDtk",
    daliCtlID: daliCtlObj.daliCtlID,
  };
  console.log(
    `[COMMAND OUT] daliCtlDeleteDtk ${daliCtlObj.daliCtlID} [${daliCtlObj.dtkAdd}]`
  );
  global.socket.emit("fromWeb", payload);
};
export const changeDaliCtlOneSetting = async (
  daliCtlObj,
  commandCode,
  valueObj
) => {
  const { daliCtlID, gatewayID, dtkAdd } = daliCtlObj || {};
  let payload = {
    type: "control_dali_ctl",
    daliCtlID,
    gatewayID,
    dtkAdd,
    commandCode,
    ...valueObj,
  };
  console.log(
    `[COMMAND OUT] changeDaliCtlOneSetting ${daliCtlID} commandCode:0x${commandCode.toString(16)} valueObj:${JSON.stringify(valueObj)}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const queryDaliCtlOneSetting = async (daliCtlObj, commandCode, queryPayload) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode,
    ...(queryPayload || {})

  };
  console.log(
    `[COMMAND OUT] queryDaliCtlOneSetting ${daliCtlObj?.daliCtlID} commandCode ${commandCode} - ${JSON.stringify(queryPayload)}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const queryDaliCtlOneLightSetting = async (daliCtlObj, shortAdd, commandCode) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj?.dtkAdd,
    shortAdd,
    commandCode,
  };
  console.log(
    `[COMMAND OUT] queryDaliCtlOneLightSetting ${daliCtlObj?.daliCtlID} shortAdd ${shortAdd} commandCode ${commandCode}`
  );
  await global.socket.emit("fromWeb", payload);
}
export const daliCtlConvertToLight = async (daliCtlObj) => {
  const payload = {
    type: "control_single_light",
    commandCode: 0x0231,
    gatewayID: daliCtlObj.gatewayID,
    daliCtlID: daliCtlObj.daliCtlID,
    dtkAdd: daliCtlObj.dtkAdd,
    enableDali: 0
  };
  console.log(`[COMMAND OUT] [0231] daliCtl ${daliCtlObj.daliCtlID} Convert To Light (changeEnableDali to 0)`
  );
  global.socket.emit("fromWeb", payload);
};
export const daliCtlChangeOtaSsid = async (daliCtlObj, otaSsid) => {
  const commandCode = 0x0a2d;
  const payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode,
    otaSsid,
  };
  console.log(
    `[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} change otaSsid 0x0a2d to ${otaSsid}`
  );
  global.socket.emit("fromWeb", payload);
}
export const daliCtlChangeOtaPassword = async (daliCtlObj, otaPassword) => {
  const commandCode = 0x0a2e;
  const payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode,
    otaPassword,
  };
  console.log(
    `[COMMAND OUT] daliCtl ${daliCtlObj?.daliCtlID} change otaPassword 0x0a2e to ${otaPassword}`
  );
  global.socket.emit("fromWeb", payload);
}
export const daliCtlUpdateFirmware = async (daliCtlObj) => {
  const commandCode = 0x0a2b;
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode,
    disconnectIcon: true
  };
  console.log(
    `[COMMAND OUT] daliCtlUpdateFirmware ${daliCtlObj?.daliCtlID} commandCode 0x${commandCode.toString(16)}`
  );
  global.socket.emit("fromWeb", payload);
}
export const queryDaliCtlComType = async (daliCtlObj) => {
  const payload = {
    type: "queryDaliCtlComType",
    gatewayID: daliCtlObj.gatewayID,
    daliCtlID: daliCtlObj.daliCtlID,
    dtkAdd: daliCtlObj.dtkAdd,
  };
  console.log(
    `[COMMAND OUT] queryDaliCtlComType ${daliCtlObj.daliCtlID} [${daliCtlObj.dtkAdd}]`
  );
  global.socket.emit("fromWeb", payload);
}
export const restartDaliCtl = async (deviceID, daliCtlObj) => {
  const { gatewayID, dtkAdd } = daliCtlObj;
  const payload = {
    type: "control_dali_ctl",
    gatewayID,
    commandCode: 0x0a31,
    dtkAdd,
    disconnectIcon: true
  };
  console.log(`[COMMAND OUT] restartDaliCtl - ${deviceID}`);
  global.socket.emit("fromWeb", payload);
}
export const fetchDaliCtlHistory = async (deviceID, startTsp, endTsp) => {
  const arr = await mgFindByMatchAndRange(
    "deviceLogs",
    "deviceID",
    deviceID,
    "timeStamp",
    endTsp,
    startTsp
  );
  return arr;
}
export const daliMultiLightToSingleLightCommand = (multiLightCommandObj, daliCtlObj, shortAdd) => {
  if (!daliCtlObj) return { result: "daliCtlObj null" };
  if (!multiLightCommandObj) return { result: "multiLightCommandObj null" };
  const { dtkAdd, lockLevel, releaseOnDelayEnd, delaySec = 0, delaySec2 = 0, commandCode, lightLvAry = [], D1LightLvAry = [], D2LightLvAry = [] } = multiLightCommandObj || {};
  const { addList } = daliCtlObj || {};
  const shortAddIndex = (addList || []).indexOf(shortAdd);
  if (shortAddIndex < 0) return { result: "shortAddIndex<0" };
  const pwm = lightLvAry[shortAddIndex];
  const delayedPwm = D1LightLvAry ? (D1LightLvAry[shortAddIndex] || 0) : 0;
  const delayedPwm2 = D2LightLvAry ? (D2LightLvAry[shortAddIndex] || 0) : 0;
  const singleCmdObj = { commandCode: 0x0a09, dtkAdd, shortAdd, pwm, lockLevel, releaseOnDelayEnd, delaySec, delaySec2, delayedPwm, delayedPwm2 };
  return singleCmdObj;
}
export const daliMultiLightToSingleLightCommandArray = (multiLightCommandObj, daliCtlObj) => {
  if (!daliCtlObj) return { result: "daliCtlObj null" };
  if (!multiLightCommandObj) return { result: "multiLightCommandObj null" };
  const { dtkAdd, lockLevel, releaseOnDelayEnd, delaySec = 0, delaySec2 = 0, commandCode, lightLvAry = [], D1LightLvAry = [], D2LightLvAry = [] } = multiLightCommandObj || {};
  const { addList } = daliCtlObj || {};
  console.log({ addList, lightLvAry, D1LightLvAry, D2LightLvAry });
}
export const dtkCmdIncludeDaliLight = (cmdStr, daliCtlObj, lightObj) => {
  if (!cmdStr) return false;
  if (!daliCtlObj) return false;
  if (!lightObj) return false;
  const { addList } = daliCtlObj;
  const { shortAdd, serial } = lightObj;
  const shortAddIndex = addList.indexOf(shortAdd);
  if (shortAddIndex < 0) return false;
  const cmdJson = gs.dtKStrToJson(cmdStr);
  let included = false;
  if (cmdJson.lightLvAry?.[shortAddIndex] >= 0 && cmdJson.lightLvAry?.[shortAddIndex] <= 100) included = true;
  if (cmdJson.D1LightLvAry?.[shortAddIndex] >= 0 && cmdJson.D1LightLvAry?.[shortAddIndex] <= 100) included = true;
  if (cmdJson.D2LightLvAry?.[shortAddIndex] >= 0 && cmdJson.D2LightLvAry?.[shortAddIndex] <= 100) included = true;
  return included;
}
export const convertDaliCmdOneSetting = (setting) => {
  if (!setting && Object.keys(setting || {}).length === 0) return null;
  let mergedCmdByDaliCtlID = {};
  let lightLvAryByDaliCtlID = {}; //for 0x0a19 or 0x0a39
  let D1LightLvAryByDaliCtlID = {}; //for 0x0a1a or 0x0a3a
  let D2LightLvAryByDaliCtlID = {}; //for 0x0a1b or 0x0a3b
  let daliCtlArr = [];
  let isOnOffDim = false;
  Object.keys(setting || {})
    .filter(deviceID => setting[deviceID].daliCtlID)
    .forEach(deviceID => {
      const deviceSetting = setting[deviceID];
      let daliAddListIndex = deviceSetting.daliAddListIndex;
      if (!daliCtlArr.includes(deviceSetting.daliCtlID)) {
        daliCtlArr.push(deviceSetting.daliCtlID);
        let arr = [], arr1 = [], arr2 = [];
        let cmdObj = {
          lockLevel: deviceSetting.lockLevel,
          releaseOnDelayEnd: deviceSetting.releaseOnDelayEnd,
          daliCtlID: deviceSetting.daliCtlID,
          dtkAdd: deviceSetting.dtkAdd,
          gatewayID: deviceSetting.gatewayID,
          settingNum: deviceSetting.settingNum,
        }
        if (deviceSetting.commandCode === 0x0a09) { //control single light
          isOnOffDim = true;
          arr[daliAddListIndex] = deviceSetting.pwm;
          arr1[daliAddListIndex] = deviceSetting.delayedPwm;
          arr2[daliAddListIndex] = deviceSetting.delayedPwm2;
          lightLvAryByDaliCtlID[deviceSetting.daliCtlID] = arr;
          D1LightLvAryByDaliCtlID[deviceSetting.daliCtlID] = arr1;
          D2LightLvAryByDaliCtlID[deviceSetting.daliCtlID] = arr2;
          cmdObj = { ...cmdObj, commandCode: 0x0a39 };
          if (deviceSetting.delaySec > 0) cmdObj = { ...cmdObj, commandCode: 0x0a3a, delaySec: deviceSetting.delaySec }
          if (deviceSetting.delaySec2 > 0) cmdObj = { ...cmdObj, commandCode: 0x0a3b, delaySec2: deviceSetting.delaySec2 }
        }
        if (deviceSetting.commandCode === 0x0a35) { // single light step dim
          cmdObj = { ...cmdObj, commandCode: 0x0a33, shortAddAry: [deviceSetting.shortAdd], dimStepSize: deviceSetting.dimStepSize, stepUp: deviceSetting.stepUp };
        }
        if (deviceSetting.commandCode === 0x0a36) { //single light toggle
          cmdObj = { ...cmdObj, commandCode: 0x0a34, shortAddAry: [deviceSetting.shortAdd] };
        }
        mergedCmdByDaliCtlID[deviceSetting.daliCtlID] = cmdObj;
      } else {
        if (deviceSetting.commandCode === 0x0a09) {
          lightLvAryByDaliCtlID[deviceSetting.daliCtlID][daliAddListIndex] = deviceSetting.pwm;
          D1LightLvAryByDaliCtlID[deviceSetting.daliCtlID][daliAddListIndex] = deviceSetting.delayedPwm;
          D2LightLvAryByDaliCtlID[deviceSetting.daliCtlID][daliAddListIndex] = deviceSetting.delayedPwm2;
        }
        if (deviceSetting.commandCode === 0x0a35 || deviceSetting.commandCode === 0x0a36) {
          mergedCmdByDaliCtlID[deviceSetting.daliCtlID].shortAddAry.push(deviceSetting.shortAdd);
        }
      }
    });
  if (isOnOffDim) {
    Object.keys(lightLvAryByDaliCtlID).forEach(daliCtlID => {
      let lightLvAry = lightLvAryByDaliCtlID[daliCtlID];
      let D1LightLvAry = D1LightLvAryByDaliCtlID[daliCtlID];
      let D2LightLvAry = D2LightLvAryByDaliCtlID[daliCtlID];
      for (let j = 0; j < lightLvAry.length; j++) {
        if (lightLvAry[j] == undefined || lightLvAry[j] === null) lightLvAry[j] = 101;
        if (D1LightLvAry[j] == undefined || D1LightLvAry[j] === null) D1LightLvAry[j] = 101;
        if (D2LightLvAry[j] == undefined || D2LightLvAry[j] === null) D2LightLvAry[j] = 101;
      }
      mergedCmdByDaliCtlID[daliCtlID].lightLvAry = lightLvAry;
      if (mergedCmdByDaliCtlID[daliCtlID].commandCode === 0x0a1a || mergedCmdByDaliCtlID[daliCtlID].commandCode === 0x0a3a) {
        mergedCmdByDaliCtlID[daliCtlID].D1LightLvAry = D1LightLvAry;
      }
      if (mergedCmdByDaliCtlID[daliCtlID].commandCode === 0x0a1b || mergedCmdByDaliCtlID[daliCtlID].commandCode === 0x0a3b) {
        mergedCmdByDaliCtlID[daliCtlID].D1LightLvAry = D1LightLvAry;
        mergedCmdByDaliCtlID[daliCtlID].D2LightLvAry = D2LightLvAry;
      }
    });
  }
  return mergedCmdByDaliCtlID; //{[daliCtlID]:{...mergedCmdObj}, [daliCtlID]:{...mergedCmdObj}}
}
/////////Query//////////////Query///////////////Query///////////
export const daliGeneralDaliQuery = async (daliCtlObj, shortAdd, daliCmd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    daliCmd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0a04
  };
  console.log(`[COMMAND OUT] daliGeneralQuery ${daliCtlObj?.daliCtlID} add ${shortAdd} cmd ${daliCmd}`);
  global.socket.emit("fromWeb", payload);
};
export const daliQueryFadeTime = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0b06
  };
  console.log(
    `[COMMAND OUT] daliQueryFadeTime ${daliCtlObj?.daliCtlID} add ${shortAdd}`
  );
  await global.socket.emit("fromWeb", payload);
};
export const daliQueryMinLv = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0b08
  };
  await global.socket.emit("fromWeb", payload);
  console.log(`[COMMAND OUT] daliQueryMinLv daliCtlID ${daliCtlObj?.daliCtlID} shortAdd ${shortAdd}`);
};
export const daliQueryMaxLv = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: 0x0b09
  };
  await global.socket.emit("fromWeb", payload);
  console.log(`[COMMAND OUT] daliQueryMaxLv daliCtlID ${daliCtlObj?.daliCtlID} shortAdd ${shortAdd}`);
};
/**
 * 
 * @param {object} daliCtlObj 
 * @param {number} shortAdd  99 for all lights
 */
export const daliQueryLightLevel = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: shortAdd === 99 ? 0x0b02 : 0x0b04
  };
  let msg = `[COMMAND OUT] daliQueryLightLevel daliCtlID ${daliCtlObj?.daliCtlID} - `;
  if (shortAdd !== 99) {
    payload.shortAdd = shortAdd;
    msg += ` shortAdd ${shortAdd}`;
  } else {
    msg += ` all lights`;
  }
  await global.socket.emit("fromWeb", payload);
  console.log(msg);
};
/**
 * 
 * @param {object} daliCtlObj 
 * @param {number} shortAdd  99 for all lights
 */
export const daliQueryLightLockLevel = async (daliCtlObj, shortAdd) => {
  let payload = {
    type: "control_dali_ctl",
    daliCtlID: daliCtlObj?.daliCtlID,
    gatewayID: daliCtlObj?.gatewayID,
    shortAdd,
    dtkAdd: daliCtlObj.dtkAdd,
    commandCode: shortAdd === 99 ? 0x0b03 : 0x0b05
  };
  let msg = `[COMMAND OUT] daliQueryLightLockLevel daliCtlID ${daliCtlObj?.daliCtlID} - `;
  if (shortAdd !== 99) {
    payload.shortAdd = shortAdd;
    msg += ` shortAdd ${shortAdd}`;
  } else {
    msg += ` all lights`;
  }
  await global.socket.emit("fromWeb", payload);
  console.log(msg);
}

