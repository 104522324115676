export default function Magnifier({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      {" "}
      <circle
        cx="32.483"
        cy="30.698"
        r="24.99"
        fill="#80e5ff"
        stroke="#f60"
        strokeLinecap="round"
        strokeWidth="9.804"
      ></circle>
      <path
        fill="#a00"
        d="M70.005 79.423l8.353-7.872-21.977-23.678-1.985 3.12-1.701 2.126-3.97 3.403z"
      ></path>
      <path
        fill="#fff"
        d="M16.539 31c-1.168-1.385.213-3.646.709-5.388.44-1.546 1.084-3.064 1.985-4.395.917-1.357 2.138-2.504 3.403-3.545 1.215-1 2.56-1.854 3.97-2.552.991-.491 2.035-.912 3.12-1.135.834-.17 1.923-.717 2.551-.141 1.081.99.369 2.947.142 4.395-.217 1.388-.888 2.789-1.418 3.97-.53 1.182-1.054 2.32-1.701 3.12-.647.798-1.069.961-1.985 1.7s-2.345 2.016-3.545 2.695-2.312 1.205-3.545 1.418c-1.232.213-2.893.798-3.686-.142z"
      ></path>
    </svg>
  );
}
