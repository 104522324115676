import { Group, Path, Rect, Ellipse } from "react-konva";

export default function DegreeCelsius({ x, y, size, color = "grey" }) {
  const scale = size / 79.375;
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Ellipse
        x={15}
        y={20}
        stroke={color}
        strokeWidth={5}
        radiusX={9}
        radiusY={9}
      ></Ellipse>
      <Path
        x={-65}
        y={-40}
        stroke={color}
        strokeWidth={3}
        scaleX={2.5}
        scaleY={2.5}
        data="M52.992 31.091a9.072 9.645 0 01-12.431-.77 9.072 9.645 0 010-13.237 9.072 9.645 0 0112.431-.77"
      ></Path>
    </Group>
  );
}
