import { useState } from "react";
import { MoreHoriz } from "@mui/icons-material";
import { Button, TextField } from "@mui/material";
import ModalSM from "components/ModalSM";

export default function ModalRemarks({
  currentRemarks,
  handleSave,
  closeOnSave,
}) {
  const [open, setOpen] = useState(false);
  const [remarks, setRemarks] = useState(0);
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={() => {
          setOpen(true);
          setRemarks(currentRemarks);
        }}
      >
        <MoreHoriz />
      </Button>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        onSave={() => {
          handleSave(remarks);
          if (closeOnSave) setOpen(false);
        }}
        modalTitle="Remarks"
      >
        <TextField
          multiline
          fullWidth
          value={remarks}
          onChange={(e) => {
            setRemarks(e.target.value);
          }}
        />
      </ModalSM>
    </>
  );
}
