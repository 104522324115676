import { Typography } from "@mui/material";
import { useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { QueryFuncIcon } from "components/toolbox/QueryFuncIcon";
import { useState, useEffect } from "react";
export default function PingPong() {
  const [state, dispatch] = useContext(SMContext);
  const [pingTime, setPingTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const { socket, response_ping } = state;
  useEffect(() => {
    if (response_ping === "pong") {
      const _endTime = Date.now();
      setEndTime(_endTime);
      setPingTime(_endTime - startTime);
    }
  }, [response_ping]);

  return (
    <div>
      <Typography>Socket</Typography>
      <Typography>ID: {socket.id}</Typography>
      <DivInline>
        <Typography>Status:</Typography>
        <Typography sx={{ color: socket.connected ? "green" : "red" }}>{socket.connected ? "Connected" : "Disconnected"}</Typography>
        <QueryFuncIcon onClick={() => {
          socket.emit("fromWeb", { type: "ping" });
          setStartTime(Date.now());
        }} listenStateKey="response_ping" label="Ping" />
        <Typography>Time: {startTime ? pingTime : 0}ms</Typography>
      </DivInline>
    </div>
  )
}