import { ArrowForwardIos } from '@mui/icons-material';
import ModalSM from 'components/ModalSM';
import SpaceBetweenButton from 'components/SpaceBetweenButton';
import React, { useState, useContext, useEffect } from 'react';
import { SMContext } from "context/smContext";
import General from '@ecoenghk/general';
import { Box, TextField, Typography } from '@mui/material';
import Divider10 from 'components/Divider10';
import DivInline from 'components/DivInline';
import IconButtonDelete from 'components/IconButtonDelete';
import { executeRedisCommand, updateGatewayProperty } from 'actions/gatewayActions';
import IconButtonDone from 'components/IconButtonDone';
const gs = new General();
const defaultArray = ["keys *", "hlen bq:serialPortQueue:jobs"];
export default function RedisCommandModal({ gatewayObj }) {
    const [state, dispatch] = useContext(SMContext);
    // const { activeGatewayID, gatewayObjAll } = state;
    const [open, setOpen] = useState(false);
    const [command, setCommand] = useState("");
    const [commandArray, setCommandArray] = useState([]);
    const [result, setResult] = useState({});
    const [resultType, setResultType] = useState("string");
    const handleOpen = () => {
        setCommand("");
        setResult({});
        setResultType("");
        setCommandArray(defaultArray);
        setOpen(true);

    }
    const keyPressed = (evt) => {
        if (evt.key === "Enter" || evt.which === "13") handleExecute();
    };
    const handleExecute = () => {
        if (command === "") return;
        if (!commandArray.includes(command)) {
            const newCommandArray = [...commandArray, command];
            setCommandArray(newCommandArray);
        }
        executeRedisCommand(gatewayObj.gatewayID, command);
    }
    useEffect(() => {
        const resultStr = gatewayObj.executeRedisResult;
        if (gs.isEmptyJson(resultStr)) {
            setResult("");
            setResultType("");
            return;
        }
        if (resultStr) {
            if (resultStr?.substring(0, 1) === "{") {
                setResultType("json");
                setResult(JSON.parse(resultStr));
            } else {
                const arr = resultStr.split(/(\s+)/).filter(item => item.trim() !== "");
                if (arr.length === 1) {
                    setResultType("string");
                    setResult(resultStr);
                } else {
                    setResultType("array");
                    setResult(arr);
                }

            }
        } else {
            setResult("");
            setResultType("");
        }
    }, [gatewayObj.executeRedisResult])
    return (
        <>
            <SpaceBetweenButton title="Redis command" btnContent={<ArrowForwardIos />} onBtnClick={handleOpen} />
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle={`Redis command - ${gatewayObj.gatewayID}`}
                width="95vw"
                height="97vh"
                disableBottomClose
            >
                <DivInline>
                    <TextField
                        sx={{ width: "70vw" }}
                        value={command}
                        onChange={(e) => setCommand(e.target.value)}
                        onKeyDown={keyPressed}
                        fullWidth
                        label="Command" />
                    <IconButtonDone onBtnClick={handleExecute} />
                    <IconButtonDelete onBtnClick={() => {
                        setCommand("");
                        setResult({});
                        setResultType("");
                        updateGatewayProperty(gatewayObj, { executeRedisResult: "" }, false);
                    }} />
                </DivInline>
                <Divider10 />
                <Box sx={{ width: "95%", height: "75%", outline: "1px solid red", overflow: "auto" }}>
                    {
                        resultType === "json" &&
                        <div>
                            {
                                Object.keys(result).map((key, index) => (
                                    <div key={index}>
                                        <Typography>{`${key} : ${JSON.stringify(result[key])}`}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        resultType === "array" &&
                        <div>
                            {
                                result.map((item, index) => (
                                    <div key={index}>
                                        <Typography>{`${index}. ${item}`}</Typography>
                                    </div>
                                ))
                            }
                        </div>
                    }
                    {
                        resultType === "string" &&
                        <Typography>{result || ""}</Typography>
                    }
                </Box>
                {/* <Typography variant="caption">{gatewayObj.executeRedisResult}</Typography> */}
                <DivInline>
                    {
                        commandArray.map((item, index) => (
                            <Box
                                onClick={() => {
                                    setCommand(item);
                                    executeRedisCommand(gatewayObj.gatewayID, item);
                                }}
                                key={index}
                                sx={{ border: "1px solid #fff", padding: "2px", margin: "4px", cursor: "pointer" }}>
                                <Typography>{item}</Typography>
                            </Box>
                        ))
                    }
                </DivInline>
            </ModalSM>
        </>
    )
}