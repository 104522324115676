import { Typography, Switch } from "@mui/material";
import DivExist from "./DivExist";
import IconButtonRefresh from "./IconButtonRefresh";
import DivInline from "./DivInline";

/**
 *
 * @param {object} param0
 * @param {string} param0.title
 * @param {string} param0.titleVariant
 * @param {string} param0.titleColor
 * @param {boolean} param0.data
 * @param {function(event)} param0.onChange
 * @param {string} param0.color
 * @param {"small"|"medium"|"large"} param0.switchSize
 * @param {boolean} param0.hideComponent
 * @param {function} param0.handleRefresh
 * @param {object} param0.sx
 * @returns
 */
export default function SpaceBetweenSwitch({
  title,
  titleVariant,
  titleColor,
  data,
  onChange,
  color,
  switchSize,
  hideComponent,
  handleRefresh,
  sx,
}) {
  return (
    <>
      {!hideComponent && (
        <DivInline
          justifyContent="space-between"
          style={{ marginBottom: 5, ...sx }}
        >
          <DivInline>
            <Typography
              variant={titleVariant ? titleVariant : "body1"}
              sx={{ color: titleColor || "#fff" }}
            >
              {title}
            </Typography>
            <DivExist show={handleRefresh ? true : false}>
              <IconButtonRefresh onBtnClick={handleRefresh} />
            </DivExist>
          </DivInline>
          <Switch
            checked={data || false}
            onChange={onChange}
            value="data"
            color={color ? color : "primary"}
            size={switchSize ? switchSize : "medium"}
          />
        </DivInline>
      )}
    </>
  );
}
