import { useState, useEffect } from "react";
import KonvaLabel from "../device/KonvaLabel";
import { lightColorDark } from "actions/generalActions";

export default function LayerLightLock({ layerProps }) {
  const { layerRef, activeMapID, lightObjAll, mapObjAll, mapScale, iconSize } =
    layerProps;
  const [scale, setScale] = useState(2);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const activeMapObj = mapObjAll[activeMapID];

  const mapLightObj = activeMapObj?.lightObj || {};
  const mapLightArray = Object.keys(mapLightObj);

  return (
    <>
      {mapLightArray.map((s) => {
        let lockLv = "-";
        if (lightObjAll[s]?.lockLevel === 100) {
          lockLv = 5;
        } else {
          lockLv = lightObjAll[s]?.lockLevel;
        }
        return (
          <KonvaLabel
            key={s}
            x={mapLightObj[s]?.x * mapScale.x || 0}
            y={(mapLightObj[s]?.y + iconSize) * mapScale.y || 0}
            text={`Lv${lockLv}`}
            fontSize={12 / scale} //keep font size fix
            tagColor={lightColorDark}
          />
        );
      })}
    </>
  );
}
