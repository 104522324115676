import React from "react";

export default function RubbishBin({ width, height, sizeUnit, color = "#736428" }) {
    const w = width || height || 20;
    const h = height || width || 20;
    const unit = sizeUnit || "px";
    return (
        <svg
            viewBox="0 0 80 80"
            width={w + unit}
            height={h + unit}
        >
            <path
                fill={color}
                strokeWidth="0.205"
                d="M13.095 72.562c.107 1.984 2.216 3.55 4.78 3.55h44.657c2.564 0 4.673-1.566 4.78-3.55l3.19-52.143H9.904zM50.306 33.37a1.943 1.505 0 011.943-1.505h3.108A1.943 1.505 0 0157.3 33.37v29.791a1.943 1.505 0 01-1.943 1.505h-3.108a1.943 1.505 0 01-1.943-1.505zm-13.599 0a1.943 1.505 0 011.943-1.505h3.108A1.943 1.505 0 0143.7 33.37v29.791a1.943 1.505 0 01-1.942 1.505H38.65a1.943 1.505 0 01-1.943-1.505zm-13.599 0a1.943 1.505 0 011.943-1.505h3.108a1.943 1.505 0 011.943 1.505v29.791a1.943 1.505 0 01-1.943 1.505H25.05a1.943 1.505 0 01-1.943-1.505zM72.626 6.284H52.024V3.267a1.002.776 0 00-1.002-.776H29.387a1.002.776 0 00-1.001.776v3.017H7.783c-1.658 0-3.002 1.04-3.002 2.325v7.304h70.847V8.61c0-1.284-1.344-2.325-3.002-2.325z"
            ></path>
        </svg>

    )
}