import DivExist from "components/DivExist"
import { Circle, Text } from "react-konva";
import { darkColorArr } from "actions/generalActions";

export default function ButtonNo({ x, y, show, layerProps, buttonNo = 1 }) {
    const { mapScale, iconSize } = layerProps;
    const unitSize = iconSize * mapScale.x;
    const i = buttonNo - 1;
    return (
        <DivExist show={show}>
            <Circle
                radius={unitSize * 0.28}
                x={x - 0.4 * unitSize}
                y={y + i * unitSize * 0.6}
                fill={darkColorArr[i]}
            />
            <Text
                text={buttonNo}
                x={x - 0.55 * unitSize}
                y={y - 0.2 * unitSize + i * unitSize * 0.6}
                fontSize={0.5 * unitSize}
                fill="#fff"
            />
        </DivExist>

    )
}