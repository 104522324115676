import { useContext } from "react";
import { SMContext } from "context/smContext";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import { queryDeviceTtyInterface } from "actions/gatewayActions";
import { queryOneLightSetting, changeOneLightSetting } from "actions/lightActions";
import { queryOneSensorSetting, changeSensorOneSetting } from "actions/sensorActions";
import { queryDaliCtlOneSetting, changeDaliCtlOneSetting } from "actions/daliCtlActions";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";
import DivExist from "components/DivExist";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
export default function InterfaceEspPanel({ ttyObj, deviceType, deviceObj }) {
    const [state, dispatch] = useContext(SMContext);
    const wifiApMacAddress = deviceType === "gateway" ? ttyObj?.macAddress : deviceObj?.wifiApMacAddress
    return (
        <>
            <SpaceBetweenDiv title="Type" data="ESP" hideComponent={deviceType !== "gateway"} />
            <SpaceBetweenDiv
                title="Wifi AP mac address"
                data={wifiApMacAddress}
                handleRefresh={async () => {
                    if (deviceType === "light") {
                        await queryOneLightSetting(deviceObj, 0x031c)
                    } else if (deviceType === "sensor") {
                        await queryOneSensorSetting(deviceObj, 0x0720)
                    } else if (deviceType === "daliCtl") {
                        await queryDaliCtlOneSetting(deviceObj, 0x0b16)
                    } else if (deviceType === "gateway") {
                        queryDeviceTtyInterface(deviceObj.gatewayID);
                    }
                }}
            />
            <SpaceBetweenDialogInput
                hideComponent={deviceType === "gateway"}
                showValue
                title="EspNow channel"
                data={deviceObj.espNowChannel}
                handleSave={async (val) => {
                    const updateObj = { espNowChannel: val }
                    if (deviceType === "light") {
                        await changeOneLightSetting(deviceObj, 0x023e, updateObj)
                    } else if (deviceType === "sensor") {
                        await changeSensorOneSetting(deviceObj, 0x0642, updateObj)
                    } else if (deviceType === "daliCtl") {
                        await changeDaliCtlOneSetting(deviceObj, 0x0a46, updateObj)
                    }
                }}
                handleRefresh={async () => {
                    if (deviceType === "light") {
                        await queryOneLightSetting(deviceObj, 0x0326)
                    } else if (deviceType === "sensor") {
                        await queryOneSensorSetting(deviceObj, 0x0730)
                    } else if (deviceType === "daliCtl") {
                        await queryDaliCtlOneSetting(deviceObj, 0x0b25)
                    }
                }}
            />
            <SpaceBetweenSwitch
                hideComponent={deviceType === "gateway"}
                data={deviceObj.enableEspOut ? true : false}
                title="Enable ESP out"
                onChange={async (e) => {
                    let val = e.target.checked ? 1 : 0;
                    const updateObj = { enableEspOut: val };
                    if (deviceType === "light") {
                        await changeOneLightSetting(deviceObj, 0x022d, updateObj);
                    } else if (deviceType === "sensor") {
                        await changeSensorOneSetting(deviceObj, 0x0627, updateObj);
                    } else if (deviceType === "daliCtl") {
                        await changeDaliCtlOneSetting(deviceObj, 0x0a24, updateObj);
                    }
                }}
                handleRefresh={async () => {
                    if (deviceType === "light") {
                        await queryOneLightSetting(deviceObj, 0x317);

                    } else if (deviceType === "sensor") {
                        await queryOneSensorSetting(deviceObj, 0x071e);

                    } else if (deviceType === "daliCtl") {
                        await queryDaliCtlOneSetting(deviceObj, 0x0b12);

                    }
                }}
            />
            <DivExist show={deviceType === "gateway"}>
                <SpaceBetweenDiv title="Version" data={ttyObj?.version} />

            </DivExist>

        </>

    )
}
