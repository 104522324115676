import React from "react";

export default function CopyIcon({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <g stroke="teal" strokeWidth="6">
        <path
          fill="#fff"
          d="M8.378 22.234l-.249 35.357 43.076.249V3.809H27.302z"
        ></path>
        <path fill="none" d="M27.301 3.808V22.98l-18.55.093"></path>
        <path
          fill="#fff"
          d="M26.045 38.058l-.249 35.357 43.076.249V19.633H44.969z"
        ></path>
        <path fill="none" d="M44.968 19.632v19.172l-18.55.093"></path>
      </g>
    </svg>
  );
}
