import { Fragment, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Button, Select, MenuItem, Box } from "@mui/material";
import { titleText, dataText, data2Text } from "asset/string/color";
import DivExist from "components/DivExist";
import DivInline from "components/DivInline";
import General from "@ecoenghk/general";
import DeviceMongoModal from "components/DeviceMongoModal";
import DeviceIcon from "components/DeviceIcon";
import { deviceInfoOfObj } from "actions/generalActions";
import IconButtonDelete from "components/IconButtonDelete";
import Divider10 from "components/Divider10";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButtonDone from "components/IconButtonDone";
import IconButtonClose from "components/IconButtonClose";
import ThemeContainer from "components/ThemeContainer";
import DivCol from "components/DivCol";
import { cursor } from "asset/string/string";
const gs = new General();
export default function Rssi255WebInfo() {
    const [state, dispatch] = useContext(SMContext);
    const {
        gatewayObjAll,
        lightObjAll,
        sensorObjAll,
        daliCtlObjAll,
        mapObjAll,
        activeMapID,
        rssi255WebGatewayID,
        rssi255WebDeviceID,
        rssi255WebHideReceiveStrength,
        rssi255WebHideSendStrength
    } = state;
    const activeMapObj = mapObjAll[activeMapID];

    return (
        <>
            <ThemeContainer background="light">

                <Typography align="center" style={{ color: titleText }}>
                    255 Strength Web
                </Typography>
                <hr color="lightgrey" size={1} width="90%" />
                <DivExist show={rssi255WebGatewayID}>
                    <Typography align="center" variant="caption" sx={{ color: titleText }}>
                        Selected Gateway
                    </Typography>
                    <Typography align="center" sx={{ color: dataText }}>{rssi255WebGatewayID}</Typography>
                    <IconButtonClose onBtnClick={() => {
                        dispatch({
                            type: "SET_GENERAL_STATE",
                            payload: { key: "rssi255WebGatewayID", value: "" }
                        });
                    }} />
                    <hr color="lightgrey" size={1} width="90%" />
                </DivExist>
                <DivExist show={rssi255WebDeviceID}>
                    <Typography align="center" variant="caption" sx={{ color: titleText }}>Selected Device</Typography>
                    <Typography align="center" sx={{ color: dataText }}>{rssi255WebDeviceID}</Typography>
                    <IconButtonClose onBtnClick={() => {
                        dispatch({
                            type: "SET_GENERAL_STATE",
                            payload: { key: "rssi255WebDeviceID", value: "" }
                        });
                    }} />
                    <hr color="lightgrey" size={1} width="90%" />
                </DivExist>
                <DivInline>
                    <Typography>Send strength</Typography>
                    {
                        rssi255WebHideSendStrength ?
                            <VisibilityOff sx={{ cursor: "pointer" }} onClick={() => {
                                dispatch({
                                    type: "SET_GENERAL_STATE",
                                    payload: { key: "rssi255WebHideSendStrength", value: false }
                                });
                            }} /> :
                            <Visibility sx={{ cursor: "pointer" }} onClick={() => {
                                dispatch({
                                    type: "SET_GENERAL_STATE",
                                    payload: { key: "rssi255WebHideSendStrength", value: true }
                                });
                            }} />
                    }
                </DivInline>

                <hr color="lightgrey" size={1} width="90%" />
                <DivInline>
                    <Typography>Receive strength</Typography>
                    {
                        rssi255WebHideReceiveStrength ?
                            <VisibilityOff sx={{ cursor: "pointer" }} onClick={() => {
                                dispatch({
                                    type: "SET_GENERAL_STATE",
                                    payload: { key: "rssi255WebHideReceiveStrength", value: false }
                                });
                            }} /> :
                            <Visibility sx={{ cursor: "pointer" }} onClick={() => {
                                dispatch({
                                    type: "SET_GENERAL_STATE",
                                    payload: { key: "rssi255WebHideReceiveStrength", value: true }
                                });
                            }} />
                    }
                </DivInline>

                <hr color="lightgrey" size={1} width="90%" />
            </ThemeContainer>
        </>
    )
}