import React, { useState } from "react";
import { Typography, Box, Tab, Button, TextField, IconButton } from "@mui/material";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import Divider10 from "components/Divider10";
import DivInline from "components/DivInline";
import { alertWindow } from "actions/screenActions";
import { createZoneControl } from "actions/zcActions";
import { mapAddDevice } from "actions/mapActions";
import IconButtonDelete from "components/IconButtonDelete";
import DivExist from "components/DivExist";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import IconButtonBack from "components/IconButtonBack";
import ZoneControlIcon from "components/ZoneControlIcon";
import { mgFindOne, mgUpdateOneUpsert } from "actions/mongoApiActions";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Search } from "@mui/icons-material";
import DivCol from "components/DivCol";
import { getDocFS } from "actions/firebaseMgActions";
import MongoLogo from "asset/svgComp/MongoLogo";
import FirestoreLogo from "asset/iconPng/firestore_logo.png";
import General from "@ecoenghk/general";
import CardZoneControl from "components/CardZoneControl";
const gs = new General();
export default function AddZcToMapPanel({
  handleClose,
  targetX,
  targetY,
  state,
  dispatch,
  modalSize,
  deviceType,
  nameOfDevice,
}) {

  const { activeMapID } = state;
  const [name, setName] = useState("");
  const [selectedDeviceType, setSelectedDeviceType] = useState(""); //zcSceneButton, timer, zcSwitch
  const [addMode, setAddMode] = useState("create");
  const [input, setInput] = useState("");
  const [source, setSource] = useState("");
  const [importObj, setImportObj] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const deviceTypeUse = deviceType || selectedDeviceType;
  const searchDb = async () => {
    let obj = await mgFindOne("zoneControl", "zoneControlID", input);
    setSource("mongo");
    if (obj.result === "fail" || !obj || gs.isEmptyJson(obj)) {
      console.log(`${input} not found in mongo`);
      obj = await getDocFS(`SM_zoneControl/${input}`);
      setSource("fs");
      console.log("fs", obj);
      if (!obj || gs.isEmptyJson(obj) || obj.result === "fail") {
        setErrorMsg(`${input} not found in fs`);
        setImportObj(null);
      }
    }
    setImportObj(obj);
  }
  const handleSave = async () => {
    if (addMode === "create") {
      await createZoneControl(activeMapID, deviceTypeUse, name, targetX, targetY);
    } else {
      const { zoneControlID } = importObj;
      if (!zoneControlID) {
        alertWindow(dispatch, "Invalid zone control ID, add fail");
        return;
      }
      if (source === "fs") {
        alertWindow(dispatch, "Zone control is not in local server, download from firestore first");
        await mgUpdateOneUpsert("zoneControl", { zoneControlID }, importObj);
        await gs.waitFor(2000);
      }
      await mapAddDevice(activeMapID, zoneControlID, "zoneControl", importObj, targetX, targetY);
      handleClose();
    }
  };

  return (
    <>
      <DivInline hide={modalSize === "small"}>
        <IconButtonBack onBtnClick={handleClose} />
        <Typography variant="h6">Add {nameOfDevice}</Typography>
      </DivInline>
      <TabContext value={addMode || "create"}>
        <TabList onChange={(evt, newTab) => setAddMode(newTab)}>
          <Tab label="Create" value="create" />
          <Tab label="Import" value="import" />
        </TabList>
        <TabPanel value="create">
          <DivExist show={!deviceType}>
            <Divider10 space={5} />
            <SpaceBetweenSelect
              title="Device Type"
              data={selectedDeviceType || ""}
              onChangeValue={(value) => setSelectedDeviceType(value)}
              menuObj={{
                timer: "Vitural Timer",
                zcSceneButton: "Vitural Button",
                zcSwitch: "VituralSwitch",
              }}
            />
          </DivExist>
          <Divider10 space={5} />
          <SpaceBetweenInput
            data={name}
            title="Name"
            onInput={(e) => setName(e.target.value)}
          />
          <Divider10 space={5} />
          <DivExist show={deviceTypeUse}>
            <DivInline justifyContent="center">
              <ZoneControlIcon type={deviceTypeUse} width={4} sizeUnit={'vw'} />
            </DivInline>
            <Divider10 space={5} />
          </DivExist>
          <Button variant="outlined" fullWidth onClick={handleSave} disabled={!name || !deviceTypeUse}>Create</Button>

        </TabPanel>
        <TabPanel value="import">
          <Box sx={{ width: "100%" }}>
            <DivInline justifyContent="space-between" sx={{ width: "100%" }}>
              <Typography>Zone control ID</Typography>
              <DivInline alignItems="center">
                <TextField
                  value={input || ""}
                  onChange={(e) => setInput(e.target.value)}

                />
                <IconButton onClick={searchDb}>
                  <Search />
                </IconButton>
              </DivInline>
            </DivInline>
            <Divider10 space={5} />
            <DivExist show={importObj}>
              <CardZoneControl zoneControlObj={importObj} handleSave={handleSave} disableClick={!importObj || gs.isEmptyJson(importObj)} />
            </DivExist>
            <Divider10 space={5} />
            <DivExist show={errorMsg}>
              <Typography>{errorMsg}</Typography>
            </DivExist>
            <DivExist show={importObj && errorMsg}>
              <DivInline justifyContent="center">
                <IconButtonDelete onBtnClick={() => {
                  setInput("");
                  setImportObj(null);
                  setErrorMsg("");
                }} />
              </DivInline>
            </DivExist>
            <DivExist show={importObj && !gs.isEmptyJson(importObj)}>
              <Typography variant="body2">Source:</Typography>
              {
                source === "mongo" ?
                  <MongoLogo width={1.5} sizeUnit="vw" /> :
                  <img src={FirestoreLogo} alt="firestore" style={{ width: "1.5vw" }} />
              }
            </DivExist>
          </Box>
        </TabPanel>
      </TabContext>
    </>
  );
}
