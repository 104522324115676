import { useContext, useMemo, useState, useEffect } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";

const eachSlide = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundSize: "cover",
  height: "50vh",
  width: "80vw",
};
export default function MapPanel({ mapID, mapObj, chooseMap }) {
  const [state] = useContext(SMContext);
  const { mapName, mapUrl, mapFileName } = mapObj || {};
  let bgUrl = mapFileName ? `${global.ip}/img/${mapFileName}` : mapUrl;
  if (state.enableFS) bgUrl = mapUrl;
  return (
    <div
      style={{
        ...eachSlide,
        backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9) ), url(${bgUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        padding: 10,
        color: "white",
        cursor: "pointer",
      }}
      onClick={() => chooseMap(mapID)}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography variant="h2" display="block" gutterBottom>
          {mapName}
        </Typography>
        <Typography variant="caption" display="block">
          {mapID}
        </Typography>
      </div>
    </div>
  );
}
