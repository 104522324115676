import { Group, Rect } from "react-konva";
import Clock from "asset/mapComp/Clock";
import ZcSwitch from "asset/mapComp/ZcSwitch";
import ZcGeneral from "asset/mapComp/ZcGeneral";
import ZcSceneButton from "asset/mapComp/ZcSceneButton";

export default function MapIconZoneControl({
  x = 0,
  y = 0,
  type,
  iconSize,
  mapScale,
  strokeColor,
  shadEnabled,
  scale,
  iconBackground = "transparent",
  zoneControlObj,
}) {
  let size = iconSize * mapScale.x;
  if (shadEnabled) size = size * 1.2;
  return (
    <Group
      x={x}
      y={y}
    // onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}
    >
      <Rect
        stroke={strokeColor}
        strokeWidth={2 / scale}
        cornerRadius={iconSize * 0.1}
        width={size * 1.2}
        height={size * 1.2}
        fill={iconBackground}
      />
      {type === "timer" ? (
        <Clock size={size} shadEnabled={shadEnabled} />
      ) : type === "zcSwitch" ? (
        <ZcSwitch
          size={size}
          status={zoneControlObj?.activeSetting === 2 ? 1 : 0}
          shadEnabled={shadEnabled}
        />
      ) : type === "zcSceneButton" ? (
        <ZcSceneButton
          size={size}
          shadEnabled={shadEnabled}
          plateColor={zoneControlObj?.plateColor || "#cccccc"}
        />
      ) : (
        <ZcGeneral size={size} />
      )}
    </Group>
  );
}
