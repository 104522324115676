import { Rect, Circle, Group, Path } from "react-konva";

export default function DaylightMotion({
  x,
  y,
  size,
  shadEnabled,
  luxLv,
  motionStatus,
  connectStatus,
  disabledConnectStatus,
  disabledSensor,
}) {
  const opacity = luxLv / 10 || 0;
  const scale = size / 79.375;
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale}>
      <Rect x={0} y={0} width={79.375} height={79.375} fill="#fff" />
      {!disabledConnectStatus && (
        <Circle x={0} radius={7} fill={connectColor} />
      )}
      <Circle
        x={62.207}
        y={17.972}
        radius={15.378}
        fill="#fff"
        strokeLinecap="round"
        strokeWidth={1.089}
      ></Circle>
      <Group
        strokeLinecap="round"
        transform="translate(-8.707 -.291) scale(1.1122)"
      >
        <Circle x={62.207} y={17.972} radius={15.378} fill="#918a6f"></Circle>
        <Circle
          x={62.207}
          y={17.972}
          radius={15.378}
          fill="#ffcc00"
          opacity={opacity}
        ></Circle>
        <Group>
          <Path
            stroke="#918a6f"
            lineCap="round"
            data="M62.904 37.221v-2.35M43.019 18.788h2.35M47.646 31.006l1.937-1.33M76.392 31.006l-1.937-1.33M47.646 4.907l1.937 1.331M76.392 4.907l-1.937 1.331"
          ></Path>
          <Path
            stroke="#ffcc00"
            lineCap="round"
            opacity={opacity}
            data="M62.904 37.221v-2.35M43.019 18.788h2.35M47.646 31.006l1.937-1.33M76.392 31.006l-1.937-1.33M47.646 4.907l1.937 1.331M76.392 4.907l-1.937 1.331"
          ></Path>
        </Group>
      </Group>
      <Path
        stroke="#502d16"
        lineCap="round"
        strokeWidth={3.44}
        data="M23.18 1.524H77.947V60.393H23.18z"
      ></Path>
      {motionStatus === 0 && (
        <Group offsetX={-18} offsetY={22} scaleX={1.2} scaleY={1.2}>
          <Path
            stroke="#00f"
            strokeWidth={3.02}
            data="M-12.037 59.429c-2.34 3.846-6.76 7.887-.744 16.621M21.287 58.745c2.34 3.846 6.76 7.887.744 16.621"
          ></Path>
          <Circle
            x={5.008}
            y={50.955}
            radius={4.994}
            fill="#00f"
            fillOpacity={0.996}
          ></Circle>
          <Path
            fill="#00f"
            data="M-5.004 76.863V62.732c2.472-6.872 17.15-7.814 19.992 0l.127 14.418-3.686.026-.273-10.168c-.28-.29-.557-.624-.933 0l.03 21.582c-.183 1.218-.753 2.264-2.308 2.872l-5.348-.057C.19 91.082.37 89.896-.105 88.928V67.141c-.26-.412-.529-.767-.957-.056l-.057 9.908z"
          ></Path>
          <Path
            stroke="#666"
            strokeWidth={2.797}
            data="M-1.371 82.384c-9.963 1.507-11.548 3.613-12.677 5.75 4.916 10.072 35.493 7.207 38.07-.006-1.483-4.146-7.283-4.718-12.318-5.924"
          ></Path>
        </Group>
      )}
      {motionStatus === 1 && (
        <Group offsetX={76} offsetY={23} scaleX={1.1} scaleY={1.1}>
          <Path
            stroke="red"
            strokeWidth={4}
            data="M82.833 58.536c-3.099 5.095-8.955 10.446-.986 22.015M126.97 57.63c3.099 5.095 8.955 10.446.986 22.015"
          ></Path>
          <Circle
            x={109.85}
            y={62.343}
            radius={5.75}
            fill="red"
            fillOpacity={0.996}
          ></Circle>
          <Path
            fill="red"
            data="M105.02 65.718s-9.906-.113-10.723.212c-1.025.406-5.374 6.862-5.374 6.862-.496 4.065 1.094 5.089 4.203 3.897l3.794-4.307h3.383L96.92 78.33l-2.564 9.843H84.72c-2.83 2.649-2.414 4.877.615 6.766h14.354c.863-2.398 1.408-5.307 2.666-7.074l6.767 12.713c3.64 2.71 6.678.991 6.766-3.28l-8.407-15.995c.583-1.719 1.603-3.394 3.076-5.024 1.334.675 2.565 1.868 3.793 3.076h6.767c1.921-1.47 3.434-3.005 0-5.331l-3.69-.103c-2.513-1.562-4.165-3.862-5.947-6.049z"
          ></Path>
        </Group>
      )}
    </Group>
  );
}
