import React, { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import moment from "moment";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import SystemStart from "asset/svgComp/SystemStart";
import QuestionMark from "asset/svgComp/QuestionMark";
import ZcGeneral from "asset/svgComp/ZcGeneral";
import SensorIcon from "components/SensorIcon";
import { Info, QueryStats } from "@mui/icons-material";
import Gateway from "asset/svgComp/Gateway";
import General from "@ecoenghk/general";
import TimeHistoryDataModal from "components/TimeHistoryDataModal";
const gs = new General();
export default function SensorHistoryItem({
  data,
  sensorObj,
  iconOnly,
  messageOnly,
}) {
  const [state] = useContext(SMContext);
  const { type, gang } = sensorObj || {};
  const [openJson, setOpenJson] = useState(false);
  const { currentStatus, currentStatus2, currentStatus3, currentStatus4 } = data;
  let uploadProtocol = data.uploadProtocol;
  let timeImg = <QuestionMark />;
  let timeMessage = gs.dtkActionCmdToMsg(data);
  if ("senderMacAddress" in data) timeMessage += ` (esp)`;
  //general command to sensor
  if (uploadProtocol >= 0x0601 && uploadProtocol <= 0x06ff) timeImg = <ZcGeneral />;
  //general query to sensor
  if (uploadProtocol >= 0x0701 && uploadProtocol <= 0x07bf) timeImg = <QueryStats sx={{ color: "#117A65" }} />;
  //general sensor report properties
  if (uploadProtocol >= 0x0901 && uploadProtocol <= 0x09ff) timeImg = <Info sx={{ color: "#2E86C1" }} />;
  switch (uploadProtocol) {
    case 0x0109:
      timeMessage = "Gateway acknowledged command";
      timeImg = <Gateway disabledConnectStatus />;
      break;
    case 0x0803:
      timeImg = <SensorIcon type={"motion"} status={data.motionStatus} width={25} disabledConnectStatus />;
      break;
    case 0x0804:
      timeImg = <SensorIcon
        type={"daylight"}
        luxLv={data.luxLv}
        lux={data.lux}
        width={25}
        disabledConnectStatus
      />
      break;
    case 0x0805:
      timeImg = <SensorIcon
        type={"lightswitch"}
        status={currentStatus}
        status2={currentStatus2}
        status3={currentStatus3}
        status4={currentStatus4}
        width={25}
        disabledConnectStatus
        gang={gang}
      />
      break;
    case 0x080c:
    case 0x0808:
      timeImg = <SystemStart />;
      break;
    case 0x080a:
      timeImg =
        <SensorIcon
          type={"scenebutton"}
          status={currentStatus}
          status2={currentStatus2}
          status3={currentStatus3}
          status4={currentStatus4}
          width={25}
          disabledConnectStatus
          gang={gang}
        />
  }
  const m = moment(data.timeStamp);
  const dataDate = m.format("YYYY-MM-DD");
  const dataTime = m.format("HH:mm:ss");
  if (iconOnly) return <div>{timeImg}</div>;
  else if (messageOnly) return <div>{timeMessage}</div>;
  else
    return (
      <>
        <TimelineItem onClick={() => {
          if (state.userObj.level < 1) setOpenJson(true);
        }}>
          <TimelineOppositeContent>
            <Typography variant="caption" color="textSecondary" >
              {dataDate}
              <br />
              {dataTime}
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot>{timeImg}</TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Typography variant="caption">{timeMessage}</Typography>
          </TimelineContent>
        </TimelineItem>
        <TimeHistoryDataModal open={openJson} json={data} handleClose={() => setOpenJson(false)} timeImg={timeImg} />
      </>
    );
}
