import { useState } from "react";
import { Group, Rect } from "react-konva";
import { executeZoneControl } from "actions/zcActions";
import moment from "moment";

export default function ZcDeviceSwitch({
  layerProps,
  zoneControlObj,
  handleSelectDevice,
  handleShiftSelectDevice,
  selected,
  onMouseOver,
  onMouseLeave,
}) {
  const [touchTime, setTouchTime] = useState(0);
  const { iconSize, mapScale } = layerProps;
  const { activeSetting, setting1, setting2 } =
    zoneControlObj || {};

  const executeSwitching = async () => {
    let newActiveSettingNum, settingObj;
    if (activeSetting === 1) {
      newActiveSettingNum = 2;
      settingObj = setting2;
    } else {
      newActiveSettingNum = 1;
      settingObj = setting1;
    }
    await executeZoneControl(zoneControlObj, newActiveSettingNum);
  };
  const handleClick = async (e) => {
    if (e.evt.shiftKey) {
      handleShiftSelectDevice(e);
    } else if (e.evt.button === 0) {
      await executeSwitching();
    } else if (e.evt.button === 2) {
      //right click
      handleSelectDevice(e);
    }
  }
  return (
    <Group>
      <Rect
        width={iconSize * mapScale.x * 1.2}
        height={iconSize * mapScale.x * 1.2}
        onClick={handleClick}
        onTap={executeSwitching}
        onDblTap={(e) => handleSelectDevice(e)}
        onTouchStart={() => setTouchTime(moment().valueOf())}
        onTouchEnd={(e) => {
          const newT = moment().valueOf();
          const duration = newT - touchTime;
          if (duration < 1000) {
            executeSwitching();
          } else {
            handleSelectDevice(e);
          }
        }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    </Group>
  );
}
