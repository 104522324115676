import { Box, Button } from '@mui/material';
import { getDocsWhereFS } from 'actions/firebaseMgActions';
import ModalSM from 'components/ModalSM';
import React, { useState } from 'react';
import DivInline from 'components/DivInline';
import Divider10 from 'components/Divider10';
import DivExist from 'components/DivExist';
import { mgInsertMany, mgUpdateOneUpsert } from 'actions/mongoApiActions';

export default function ImportReportFromFsModal({ serial }) {


    const [open, setOpen] = useState(false);
    const [fsReportArray, setFsReportArray] = useState([]);
    const [activeReportObj, setActiveReportObj] = useState({});
    const [data, setData] = useState([]);
    const handleOpen = async () => {
        setOpen(true);
    }
    const fetchFsReport = async () => {
        let arr = await getDocsWhereFS(`SM_report`, "array", "serial", "==", serial);
        arr = arr.sort((a, b) => b.schedule_timeStamp - a.schedule_timeStamp)
        setFsReportArray(arr);
    }
    const fetchSerialLog = async (reportObj) => {
        console.log(`fetchSerialLog ${reportObj.reportID}`)
        let arr = await getDocsWhereFS(`SM_logs/${serial}/dataLogs`, "array", "reportID", "==", reportObj.reportID);
        console.log(arr);
        arr = arr.map(d => {
            let obj = { ...d, serial: serial };
            return obj;
        }).sort((a, b) => (a.timeStamp - b.timeStamp));
        console.log(arr);
        setData(arr);
        setActiveReportObj(reportObj);
    }
    const importDataToServer = async () => {
        await mgUpdateOneUpsert("report", { reportID: activeReportObj.reportID }, activeReportObj)
        await mgInsertMany("serialLogs", data);
    }
    return (
        <>
            <Button variant="outlined" onClick={handleOpen}>Import Report from FS</Button>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                modalTitle='Import Report from FS'
                width="90vw"
                height="95vh"
            >
                <DivInline>
                    <Box sx={{ width: "40vw", height: "80vh", margin: "1vw", outline: "1px solid grey", overflow: 'auto' }}>

                        <Button variant="outlined" onClick={fetchFsReport}>Fetch Report from FS</Button>
                        {
                            fsReportArray.map((report, key) => (
                                <Box key={key}>
                                    <Button variant="outlined" onClick={async () => await fetchSerialLog(report)}>Fetch data</Button>
                                    <Box>ReportID {report.reportID}</Box>
                                    <Box>Time {report.schedule_timeString}</Box>
                                    <Box>Result {report.result}</Box>
                                    <Divider10 />
                                </Box>
                            ))
                        }

                    </Box>
                    <Box sx={{ width: "40vw", height: "80vh", margin: "1vw", outline: "1px solid grey", overflow: 'auto' }}>
                        <DivExist show={data.length === 0}>No data</DivExist>
                        <DivExist show={data.length > 0}>
                            <Button variant="outlined" onClick={() => { setData([]); setActiveReportObj({}); }}>Clear</Button>
                            <Button variant="outlined" onClick={importDataToServer}>Import to server</Button>
                        </DivExist>
                        {
                            (data || []).map((d, key) => {
                                let msg = `${d.timeString} ${d.uploadProtocol}`;
                                if (d.uploadProtocol === "d5d5" || d.uploadProtocol === "d7d7") msg += ` ${d.result}`;
                                if (d.uploadProtocol === 'd6d6') msg += ` ${d.voltage}V,  ${d.current}A`;
                                return (
                                    <div key={key}>{msg}</div>
                                )
                            })
                        }
                    </Box>
                </DivInline>
            </ModalSM>
        </>
    )
}
