import { useState, useEffect } from "react";
import MapModal from "../device/MapModal";
import Divider10 from "components/Divider10";
import { Typography, Button } from "@mui/material";
import DivInline from "components/DivInline";
import { Forward } from "@mui/icons-material";
import ZoneControlIcon from "components/ZoneControlIcon";
import DivExist from "components/DivExist";
import DivCol from "components/DivCol";
import General from "@ecoenghk/general";
import { fetchTimeTableByZc } from "actions/timetableActions";
import moment from "moment";
import IconButtonBack from "components/IconButtonBack";
const gs = new General();

export default function ZcTimerSettingModal({
  x,
  y,
  mapLocation,
  open,
  handleClose,
  layerProps,
  zoneControlID,
  zoneControlObj,
  scale,
}) {
  const [timeTableObjAll, setTimeTableAll] = useState({});
  const { dispatch, iconSize } = layerProps;
  const { zoneControlName, timeTableID1, timeTableID2, type } =
    zoneControlObj || {};

  useEffect(() => {
    if (open && timeTableID1?.length > 0 && timeTableID2?.length > 0) {
      fetchTimeTable();
    }
  }, [open]);
  const fetchTimeTable = async () => {
    const obj = await fetchTimeTableByZc(zoneControlID);

    setTimeTableAll(obj);
  };
  const modalWidth = window.innerWidth * 0.4;
  const modalHeight = window.innerHeight * 0.38;

  return (
    <MapModal
      x={mapLocation.includes("right") ? x - modalWidth / scale - iconSize : x}
      y={mapLocation.includes("bottom") ? y - modalHeight / scale : y}
      open={open}
      handleCloseModal={handleClose}
      disableCloseButton
      width={modalWidth}
      height={modalHeight}
      layerProps={layerProps}
    >
      <div style={{ overflow: "auto" }}>
        <DivInline>
          <IconButtonBack onBtnClick={handleClose} />
          <ZoneControlIcon
            type={type}
            width={3}
            sizeUnit="vw"
            zoneControlObj={zoneControlObj}
          />
          <Typography sx={{ marginLeft: "1vw" }}>{zoneControlName}</Typography>
        </DivInline>
        <Divider10 />
        <DivExist show={timeTableID1?.length > 0}>
          {timeTableID1?.map((td1, key) => {
            const td2 = timeTableID2[key];
            const tdObj1 = timeTableObjAll[td1];
            const tdObj2 = timeTableObjAll[td2];
            return (
              <DivInline key={key} justifyContent="space-between">
                <Typography variant="caption">Start</Typography>
                <DivCol
                  style={{
                    outline: "1px solid #fff",
                    minWidth: "14vw",
                    margin: "1vw",
                    padding: "0.15vw",
                    borderRadius: "0.5vw",
                  }}
                >
                  <Typography>
                    {moment()
                      .hour(tdObj2?.hour[0] || 0)
                      .minute(tdObj2?.minute[0] || 0)
                      .format("HH:mm")}
                  </Typography>
                  <DivExist show={tdObj2?.isoWeekday[0] === 100}>
                    <Typography variant="caption">All week</Typography>
                  </DivExist>
                  <DivExist show={tdObj2?.isoWeekday[0] !== 100}>
                    <DivInline>
                      {tdObj2?.isoWeekday.map((w) => (
                        <Typography
                          variant="caption"
                          key={w}
                          sx={{ margin: "0.2vw" }}
                        >
                          {/* {gs.weekDayShortStringOf(w)} */}

                          {gs.weekDayStringOf(w)}
                        </Typography>
                      ))}
                    </DivInline>
                  </DivExist>
                  <DivExist show={tdObj2?.excludeHoliday}>
                    <Typography variant="caption">Exclude holiday</Typography>
                  </DivExist>
                  <DivExist show={tdObj2?.onlyHoliday}>
                    <Typography variant="caption">Only holiday</Typography>
                  </DivExist>
                </DivCol>
                <Forward />
                <Typography variant="caption">End</Typography>
                <DivCol
                  style={{
                    outline: "1px solid #fff",
                    margin: "1vw",
                    padding: "0.2vw",
                    borderRadius: "0.5vw",
                    minWidth: "14vw",
                  }}
                >
                  <Typography>
                    {moment()
                      .hour(tdObj1?.hour[0] || 0)
                      .minute(tdObj1?.minute[0] || 0)
                      .format("HH:mm")}
                  </Typography>
                  <DivExist show={tdObj1?.isoWeekday[0] === 100}>
                    <Typography variant="caption">All week</Typography>
                  </DivExist>
                  <DivExist show={tdObj1?.isoWeekday[0] !== 100}>
                    <DivInline>
                      {tdObj1?.isoWeekday.map((w) => (
                        <Typography
                          variant="caption"
                          key={w}
                          sx={{ margin: "0.2vw" }}
                        >
                          {/* {gs.weekDayShortStringOf(w)} */}
                          {gs.weekDayStringOf(w)}
                        </Typography>
                      ))}
                    </DivInline>
                  </DivExist>
                  <DivExist show={tdObj1?.excludeHoliday}>
                    <Typography variant="caption">Exclude holiday</Typography>
                  </DivExist>
                  <DivExist show={tdObj1?.onlyHoliday}>
                    <Typography variant="caption">Only holiday</Typography>
                  </DivExist>
                </DivCol>
              </DivInline>
            );
          })}
        </DivExist>
        <DivExist show={timeTableID1?.length === 0}>
          <Typography>No time setting set.</Typography>
        </DivExist>
        <Divider10 />
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() =>
            dispatch({
              type: "OPENED_ZC_DETAIL_MODAL",
              payload: zoneControlID,
            })
          }
        >
          Change
        </Button>
      </div>
    </MapModal>
  );
}
