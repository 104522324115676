import DivExist from "components/DivExist";
import DeviceSensor from "../device/DeviceSensor";
import DeviceUnknown from "../device/DeviceUnknown";
import LinesSensorSelected from "../device/LinesSensorSelected";
export default function LayerSensor({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    sensorObjAll,
    mapScale,
    selectedID,
    selectedDeviceType,
  } = layerProps;
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj;
  const sensorArray = Object.keys(mapSensorObj || {});

  return (
    <DivExist show={sensorArray.length > 0}>
      {sensorArray.map((s, key) => {
        const x = mapSensorObj[s]?.x * mapScale.x || 0;
        const y = mapSensorObj[s]?.y * mapScale.y || 0;
        if (sensorObjAll?.[s]) {
          return (
            <DeviceSensor
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              sensorID={s}
              sensorObj={sensorObjAll[s]}
            />
          );
        } else {
          return (
            <DeviceUnknown
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              deviceID={s}
              deviceType="sensor"
              text="S"
            />
          );
        }
      })}
      <DivExist
        show={selectedID && selectedDeviceType === "sensor" ? true : false}
      >
        <LinesSensorSelected layerProps={layerProps} />
      </DivExist>
    </DivExist>
  );
}
