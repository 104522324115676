import { useContext } from "react";
import { SMContext } from "context/smContext";
import {
  gatewayQueryDtkInfo,
  gwRestartDtkModule,
  gwQueryRemoteDtkModuleInfo,
  gwRestartRemoteDtkModule,
} from "actions/gatewayActions";
import EditDtkInfoModal from "containers/serial/EditDtkInfoModal";
import SpaceBetweenButton from "components/SpaceBetweenButton";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenDialogInput from "components/SpaceBetweenDialogInput";
import Refresh from "@mui/icons-material/Refresh";
import TestByteModal from "./TestByteModal";
import { lightQueryDtkInfo, queryOneLightSetting } from "actions/lightActions";
import { queryOneSensorSetting, sensorQueryDtkInfo } from "actions/sensorActions";
import { daliCtlQueryDtkInfo, queryDaliCtlOneSetting } from "actions/daliCtlActions";
import DivExist from "components/DivExist";
import SpaceBetweenSwitch from "components/SpaceBetweenSwitch";

export const dtkTypeOf = (code) => {
  if (code === 0) return "-";
  else if (code === 1) return "coordinator";
  else if (code === 2) return "router";
  else if (code === 3) return "endpoint";
};

export default function InterfaceDtkPanel({ ttyS_no, deviceID, deviceObj, deviceType }) {
  let ttyObj;
  if (deviceType === "gateway") ttyObj = deviceObj?.[ttyS_no];
  else ttyObj = deviceObj;
  return (
    <>
      <SpaceBetweenDiv title="type" data="DTK" hideComponent={deviceType !== "gateway"} />
      <SpaceBetweenButton
        title="Query DTK info"
        btnContent={<Refresh />}
        variant="outlined"
        btnSize="small"
        onBtnClick={async () => {
          if (deviceType === "gateway") await gatewayQueryDtkInfo(deviceID, ttyS_no);
          else if (deviceType === "light") await lightQueryDtkInfo(deviceObj);
          else if (deviceType === "sensor") await sensorQueryDtkInfo(deviceObj);
          else if (deviceType === "daliCtl") await daliCtlQueryDtkInfo(deviceObj);
        }}
      />
      <SpaceBetweenDiv
        title="DTK Address"
        data={ttyObj?.dtkAdd}
      />
      <SpaceBetweenDiv
        title="DTK type"
        data={dtkTypeOf(ttyObj?.dtkType)}
      />
      <SpaceBetweenDiv
        title="DTK Internal Address"
        data={ttyObj?.dtkInternalAdd}
      />
      <SpaceBetweenDiv
        title="DTK channel (HEX)"
        data={`0x${ttyObj?.dtkChannel?.toString(16)}`}
      />
      <SpaceBetweenDiv
        title="DTK lora parameter"
        data={ttyObj?.loraParameter}
      />
      <SpaceBetweenDiv
        title="DTK PAN ID"
        data={ttyObj?.dtkPanID?.toString(16)}
      />
      <SpaceBetweenDiv
        title="DTK transfer mode"
        data={ttyObj?.dtkTransferMode}
      />
      <SpaceBetweenDiv
        title="Edit DTK info"
        data={
          <EditDtkInfoModal
            deviceObj={deviceObj}
            deviceType={deviceType}
            ttyS_no={ttyS_no}
          />
        }
      />
      <SpaceBetweenButton
        title="Restart DTK"
        btnContent="Restart"
        onBtnClick={() => {
          if (deviceType === "gateway") gwRestartDtkModule(deviceObj, ttyS_no);
          else if (deviceType === "light") queryOneLightSetting(deviceObj, 0x0287);
          else if (deviceType === "sensor") queryOneSensorSetting(deviceObj, 0x0687);
          else if (deviceType === "daliCtl") queryDaliCtlOneSetting(deviceObj, 0x0a87);
        }}
      />
      <DivExist show={deviceType === "gateway"}>
        <SpaceBetweenDialogInput
          title="Remote query DTK info"
          data=""
          handleSave={(val) => gwQueryRemoteDtkModuleInfo(deviceObj, val, ttyS_no)}
          sx={{ marginTop: "1vh" }}
        />
        <SpaceBetweenDialogInput
          title="Remote restart DTK"
          data=""
          handleSave={(val) => gwRestartRemoteDtkModule(deviceObj, val, ttyS_no)}
          sx={{ marginTop: "1vh" }}
        />
        <TestByteModal gatewayID={deviceID} ttyS_no={ttyS_no} type={ttyObj?.ttyS_type} />
      </DivExist>
    </>
  );
}
