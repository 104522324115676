import { useState, useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography, TextField, Button, Box } from "@mui/material";
import DivInline from "components/DivInline";
import { fetchTimeTableBySerial } from "actions/timetableActions";
import moment from "moment-timezone";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import General from "@ecoenghk/general";
import { DateTimePicker } from "@mui/x-date-pickers";
import { saveTestTimeTable } from "actions/timetableActions";
import {
    confirmWindow,
    hideBigProgress,
    showBigProgress,
} from "actions/screenActions";
import IconButtonAdd from "components/IconButtonAdd";
const gs = new General();

export default function Add12MonthBatTestModal({ serial, iconColor }) {
    const [state, dispatch] = useContext(SMContext);
    const { lightObjAll } = state;
    const [open, setOpen] = useState(false);
    const [firstTestTsp, setFirstTestTsp] = useState(null);
    const { batteryHour } = lightObjAll[serial] || {};
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }

    const handleSave = async () => {
        const effectAfterMoment = moment(firstTestTsp).subtract(1, "days");
        const monthTestMoment = moment(firstTestTsp);
        const annualTestMoment = moment(firstTestTsp).add(11, "month");
        const annualTestMonth = annualTestMoment.month() + 1;
        const annualTestDate = annualTestMoment.date();
        const annualTestHour = annualTestMoment.hour();
        const annualTestMin = annualTestMoment.minute();
        let newMonthTestMonthArr = [];
        for (let m = 1; m <= 12; m++) {
            if (m !== annualTestMonth) newMonthTestMonthArr.push(m);
        }
        let newMonthTimeTableObj = {
            reportType: "check_1month",
            serial,
            year: [100],
            month: newMonthTestMonthArr,
            date: [monthTestMoment.date()],
            hour: [monthTestMoment.hour()],
            minute: [monthTestMoment.minute()],
            effectiveAfterTsp: effectAfterMoment.valueOf(),
        };
        let newAnnualTimeTableObj = {
            reportType: "check_12month",
            serial,
            year: [100],
            month: [annualTestMonth],
            date: [annualTestDate],
            hour: [annualTestHour],
            minute: [annualTestMin],
            effectiveAfterTsp: effectAfterMoment.valueOf(),
        };

        await saveTestTimeTable(serial, batteryHour, newMonthTimeTableObj, newAnnualTimeTableObj);
        await gs.waitFor(1000);
        const arr = await fetchTimeTableBySerial(serial);
        if (arr.length > 0) {
            dispatch({
                type: "UPDATE_MULTI_TIMETABLE_OBJ",
                payload: {
                    timeTableObjArray: arr,
                },
            });
        }
        handleClose();
    }
    return (
        <>
            <IconButtonAdd onBtnClick={handleOpen} color={iconColor} />
            <ModalSM
                open={open}
                onClose={handleClose}
                modalTitle={`Add 12 month battery test schedule for [${serial}]`}
                width="90vw"
                height="60vh"
                onSave={handleSave}
            >
                <DivInline>
                    <Typography>First test date</Typography>
                    <DateTimePicker
                        sx={{ width: "30vw", margin: "1vw" }}
                        value={moment(firstTestTsp)}
                        onChange={(m) => {
                            let selectedMoment = m;
                            if (selectedMoment.date() > 28) {
                                alert("Cannot set date greater than 28");
                                selectedMoment = selectedMoment.date(28);
                            }
                            setFirstTestTsp(selectedMoment.valueOf());
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </DivInline>
                <Divider10 />
                <DivInline hide={!firstTestTsp} >
                    {
                        firstTestTsp && gs.newArrayBetween(0, 11).map((month) => {
                            const monthTsp = moment(firstTestTsp).add(month, "month").valueOf();
                            const monthDate = moment(monthTsp).format("YYYY-MM-DD");
                            const monthTime = moment(monthTsp).format("HH:mm:ss");
                            const backgroundColor = (month + 1) % 12 === 0 ? "red" : "blue";
                            return (
                                <Box key={month}
                                    sx={{
                                        outline: "1px solid",
                                        margin: "0.2vw",
                                        width: "8vw",
                                        backgroundColor,
                                        padding: "0.2vw"
                                    }}>
                                    <Typography display="block" variant="caption" align="right">{month + 1}</Typography>
                                    <Typography display="block" variant="body2">{monthDate}</Typography>
                                    <Typography display="block" variant="body2">{monthTime}</Typography>
                                    <Divider10 />
                                    <Typography variant="body2">{(month + 1) % 12 === 0 ? "Annual test" : "Monthly test"}</Typography>
                                </Box>
                            )
                        })
                    }
                </DivInline>
                <Divider10 />

            </ModalSM>
        </>
    )
}