import { Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ThemeContainer from "components/ThemeContainer";
import DivInline from "components/DivInline";
import IconButtonBack from "components/IconButtonBack";
import { useSocketListenToWeb } from "hooks/socketHooks";
//tool
import PingPong from "components/toolbox/PingPong";
import SocketToGW from "components/toolbox/SocketToGW";
export default function ToolboxPage() {
  const navigate = useNavigate();
  const handleClose = async () => {
    navigate("/HomePage");
  };
  const tools = [
    // {tool: <div>tool1</div>, title: "tool1"},
    {tool: <PingPong />, title: "Ping Pong"},
    {tool: <div>tool3</div>, title: "tool3"},
    {tool: <div>tool4</div>, title: "tool4"},
    {tool: <div>tool5</div>, title: "tool5"},
    {tool: <SocketToGW />, title: "Socket to GW", space: 1},
  ]
  useSocketListenToWeb(navigate);
  return (
    <ThemeContainer background="dark">
    <Paper>
        <DivInline>
            <IconButtonBack onBtnClick={handleClose} />
            <Typography>if you don't know what it is, you don't need it.</Typography>
            <Typography>- morgan freeman</Typography>
        </DivInline>
        <ToolboxPanel>
        {tools.length > 0 && tools.map((tool, index) => (
          <ToolContainer key={index} tool={tool.tool} title={tool.title} />
        ))}
        </ToolboxPanel>
      </Paper>
    </ThemeContainer>
  );
}

function ToolContainer({tool, title, space = 3}) {
  return (
    <Paper variant="outlined" sx={{
      width: `calc(${(100 / space)-3}% - 16px)`,
      minWidth: 250,
      flex: '1 1 auto',
      padding: 2,
      margin: 1,
      backgroundColor: 'rgba(255, 255, 255, 0.02)'
    }}>
      <Typography variant="h6" gutterBottom>{title}</Typography>
      {tool}
    </Paper>
  )
}

function ToolboxPanel({children}) {
  return (
    <Paper variant="outlined" sx={{
      display: 'flex',
      flexWrap: 'wrap',
      gap: 2,
      padding: 2,
      justifyContent: 'flex-start',
      backgroundColor: 'rgba(255, 255, 255, 0.05)'
    }}>
      {children}
    </Paper>
  )
}