import { useEffect, useContext } from "react";
import { SMContext } from "../context/smContext";
import { useNavigate } from "react-router-dom";
import { mgFindAll } from "actions/mongoApiActions";

export const useAutoBackToRoot = () => {
  const [state, dispatch] = useContext(SMContext);
  let navigate = useNavigate();
  const uid = state.userObj.uid;
  useEffect(() => {
    if (!uid) {
      navigate("/");
    }
  }, [uid]);
};

export const useFetchServerObj = () => {
  const [state, dispatch] = useContext(SMContext);
  useEffect(() => {
    const fetchData = async () => {
      const arr = await mgFindAll("server");
      const obj = arr[0];
      dispatch({
        type: "FETCHED_SERVER_OBJ",
        payload: obj,
      });
    };
    if (!state.enableFS) fetchData();
  }, []);
};
