import { useContext } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import DivExist from "components/DivExist";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import CurrentWithPolarity from "components/CurrentWithPolarity";
import moment from "moment";
import PowerPlug from "asset/svgComp/PowerPlug";
import DivInline from "components/DivInline";
import DivCol from "components/DivCol";
import {
    queryOneLightSetting,
    requestLightCurrentData,
} from "actions/lightActions";
import IconButtonRefresh from "components/IconButtonRefresh";
import numeral from "numeral";
import WarningSign from "asset/svgComp/WarningSign";
import { deviceErrorMsg } from "actions/generalDeviceActions";
export default function LightStatusPanel({ }) {
    const [state, dispatch] = useContext(SMContext);
    const { activeSerial, lightObjAll, userObj } = state;
    let lightObj = lightObjAll[activeSerial];
    const { type, timeStamp, onoffstatusL, lightLv, voltage, current, bypass } = lightObj || {};
    const errorMsgObj = deviceErrorMsg(lightObj, "light", state);
    let flowDirection = current > 0 ? "[discharging]" : "[charging]";
    if (lightObj?.reversePolarityI) flowDirection = flowDirection === "[discharging]" ? "[charging]" : "[discharging]";
    if (current === 0) flowDirection = "";
    return (
        <>
            <DivInline justifyContent="center">
                <PowerPlug cutResume="resume" width={3} sizeUnit="vw" />
                <Typography variant="h6">Latest data</Typography>
                <IconButtonRefresh size="small" hide={userObj.level >= 2} onBtnClick={async () =>
                    await requestLightCurrentData(activeSerial, lightObj, false)
                } />
            </DivInline>
            <div style={{ width: "100%" }}>
                <DivExist show={type?.batVI} >
                    <SpaceBetweenDiv title="Battery voltage" data={numeral(voltage).format("0.00") + "V"} dataVariant="body2" />
                    <SpaceBetweenDiv
                        title={`Battery current ${flowDirection}`}
                        data={
                            <CurrentWithPolarity
                                darkLight="light"
                                serial={activeSerial}
                                variant="body2"
                            />
                        }
                        dataVariant="body2"
                    />
                </DivExist>
                <DivExist show={type?.L}>
                    <SpaceBetweenDiv
                        title="Power status"
                        data={onoffstatusL ? "cut[1]" : "normal[0]"}
                        dataVariant="body2"
                    />
                </DivExist>
                <DivExist show={type?.A}>
                    <SpaceBetweenDiv
                        title="Light status"
                        data={lightLv > 0 ? "on[0]" : "off[1]"}
                        dataVariant="body2"
                    />
                    <SpaceBetweenDiv
                        title="Bypass status"
                        data={bypass ? "Bypass input detected" : "normal"}
                        dataVariant="body2"
                        handleRefresh={() => queryOneLightSetting(lightObj, 0x0324)}
                    />
                </DivExist>
                <DivExist show={type?.pwm}>
                    <SpaceBetweenDiv
                        title="Light level"
                        data={`${lightLv}%`}
                        dataVariant="body2"
                    />
                </DivExist>
                {/* <DivExist show={type?.lux}>
                    <SpaceBetweenDiv
                        title="Measured lux level"
                        data={lux + "lux"}
                        dataVariant="body2"
                    />
                </DivExist> */}
                <SpaceBetweenDiv
                    title="Latest data time"
                    data={moment(timeStamp).format("YYYY-MM-DD, HH:mm:ss")}
                    dataVariant="body2"
                />
            </div>
            <DivExist show={Object.keys(errorMsgObj || {}).length > 0}>
                <DivInline>
                    <WarningSign width={1.5} sizeUnit="vw" />
                    <DivCol alignItems="flex-start" sx={{ marginLeft: "0.5vw" }}>
                        {(Object.keys(errorMsgObj || {})).map(code => (
                            <Typography key={code} color="error" variant="caption" display="block">{errorMsgObj[code]}</Typography>
                        ))}
                    </DivCol>
                </DivInline>
            </DivExist>
        </>
    )
}