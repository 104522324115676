import { Fragment, useMemo, useState, useEffect } from "react";
import { Text, Line, Rect, Group } from "react-konva";
import MapIconLight from "../device/MapIconLight";
import DeviceGateway from "../device/DeviceGateway";
import { deviceConnected } from "actions/generalActions";
import MapIconSensor from "../device/MapIconSensor";
import DaliCtl from "asset/mapComp/DaliCtl";
import Gateway from "asset/mapComp/Gateway";

export default function LayerRssi255Web({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    lightObjAll,
    gatewayObjAll,
    sensorObjAll,
    daliCtlObjAll,
    mapScale,
    layerRef,
    iconSize,
    rssi255WebGatewayID,
    rssi255WebDeviceID,
    rssi255WebHideReceiveStrength,
    rssi255WebHideSendStrength,
    dispatch
  } = layerProps;
  const activeMapObj = mapObjAll[activeMapID];
  const mapLightObj = activeMapObj?.lightObj;
  const mapGatewayObj = activeMapObj?.gatewayObj;
  const mapSensorObj = activeMapObj?.sensorObj;
  const mapDaliCtlObj = activeMapObj?.daliCtlObj;
  const coordinateObj = {
    ...mapLightObj,
    ...mapGatewayObj,
    ...mapSensorObj,
    ...mapDaliCtlObj,
  };
  const [scale, setScale] = useState(2);
  useEffect(() => {
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }, []);
  const deviceMeshMap = useMemo(() => {
    let map = {};
    Object.keys(lightObjAll).forEach((s) => {
      const obj = lightObjAll[s];
      const connected = deviceConnected(
        obj.zigbeeConnected,
        obj.timeStamp,
        true
      );
      if (obj.mesh255_endpoint_addr && connected) {
        const routeAdd = obj?.mesh255_route_addr?.replace(/^0+/, "");
        const endAdd = obj?.mesh255_endpoint_addr?.replace(/^0+/, "");
        map[s] = {
          mesh255_distance: obj.mesh255_distance,
          mesh255_endpoint_addr: endAdd,
          mesh255_receive_strength: obj.mesh255_receive_strength,
          mesh255_route_addr: routeAdd,
          mesh255_send_strength: obj.mesh255_send_strength,
          dtkAdd: obj.dtkAdd,
          deviceConnected: connected,
          deviceType: "light",
          deviceObj: obj,
        };
      }
    });
    Object.keys(sensorObjAll).forEach((s) => {
      const obj = sensorObjAll[s];
      const connected = deviceConnected(
        obj.zigbeeConnected,
        obj.timeStamp,
        true
      );
      if (obj.mesh255_endpoint_addr && connected) {
        const routeAdd = obj?.mesh255_route_addr?.replace(/^0+/, "");
        const endAdd = obj?.mesh255_endpoint_addr?.replace(/^0+/, "");
        map[s] = {
          mesh255_distance: obj.mesh255_distance,
          mesh255_endpoint_addr: endAdd,
          mesh255_receive_strength: obj.mesh255_receive_strength,
          mesh255_route_addr: routeAdd,
          mesh255_send_strength: obj.mesh255_send_strength,
          dtkAdd: obj.dtkAdd,
          deviceConnected: connected,
          deviceType: "sensor",
          deviceObj: obj,
        };
      }
    });
    Object.keys(daliCtlObjAll).forEach((s) => {
      const obj = daliCtlObjAll[s];
      const connected = deviceConnected(
        obj.zigbeeConnected,
        obj.timeStamp,
        true
      );
      if (obj.mesh255_endpoint_addr && connected) {
        const routeAdd = obj?.mesh255_route_addr?.replace(/^0+/, "");
        const endAdd = obj?.mesh255_endpoint_addr?.replace(/^0+/, "");
        map[s] = {
          mesh255_distance: obj.mesh255_distance,
          mesh255_endpoint_addr: endAdd,
          mesh255_receive_strength: obj.mesh255_receive_strength,
          mesh255_route_addr: routeAdd,
          mesh255_send_strength: obj.mesh255_send_strength,
          dtkAdd: obj.dtkAdd,
          deviceConnected: connected,
          deviceType: "daliCtl",
          deviceObj: obj,
        };
      }
    });
    return map;
  }, [lightObjAll, sensorObjAll, daliCtlObjAll]);
  const addrDeviceIDMap = useMemo(() => {
    let map = {};
    Object.keys(lightObjAll).forEach((s) => {
      const obj = lightObjAll[s];
      if (obj.mesh255_endpoint_addr) {
        const endAdd = obj?.mesh255_endpoint_addr?.replace(/^0+/, "");
        map[endAdd] = s;
      }
    });
    Object.keys(sensorObjAll).forEach((s) => {
      const obj = sensorObjAll[s];
      if (obj.mesh255_endpoint_addr) {
        const endAdd = obj?.mesh255_endpoint_addr?.replace(/^0+/, "");
        map[endAdd] = s;
      }
    });
    Object.keys(daliCtlObjAll).forEach((s) => {
      const obj = daliCtlObjAll[s];
      if (obj.mesh255_endpoint_addr) {
        const endAdd = obj?.mesh255_endpoint_addr?.replace(/^0+/, "");
        map[endAdd] = s;
      }
    });
    return map;
  }, [lightObjAll, sensorObjAll, daliCtlObjAll]);

  const gatewayArray = useMemo(
    () => Object.keys(mapGatewayObj || {}),
    [mapGatewayObj]
  );

  return (
    <>
      {/* <Rect
        fill="red"
        width={100}
        height={100}
        x={100}
        y={200}
        onClick={() => console.log(deviceMeshMap)}
      /> */}
      {(gatewayArray || []).map((g, key) => {
        return (
          <Group
            key={key}
            x={Number(mapGatewayObj?.[g]?.x * mapScale.x)}
            y={Number(mapGatewayObj?.[g]?.y * mapScale.y)}
            onClick={(e) => {
              dispatch({
                type: "SET_GENERAL_STATE",
                payload: { key: "rssi255WebGatewayID", value: g }
              });
            }}
          >

            <Gateway outline={rssi255WebGatewayID === g} connectStatus={gatewayObjAll?.[g]?.gatewayConnected} size={iconSize * mapScale.x} shadEnabled={false} />
          </Group>
        );
      })}
      {Object.keys(deviceMeshMap || {}).map((s) => {
        const devieCoord = coordinateObj[s];
        const meshObj = deviceMeshMap[s];
        const deviceObj = meshObj?.deviceObj || {};
        const { gatewayID } = deviceObj || {};

        const gwCoord = coordinateObj[gatewayID] || {};
        if (!gatewayID) return null;
        if (!gwCoord) return null;
        if (rssi255WebGatewayID && gatewayID !== rssi255WebGatewayID) return null;
        const deviceSelected = rssi255WebDeviceID === s ? true : false;
        const lineW = (rssi255WebDeviceID && deviceSelected) ? (4 / scale) : (2 / scale);
        const routerSerial = addrDeviceIDMap[meshObj?.mesh255_route_addr];
        const routerCoord = coordinateObj[routerSerial] || {};
        const deviceX = Number(devieCoord?.x * mapScale.x) || 0;
        const deviceY = Number(devieCoord?.y * mapScale.y) || 0;
        let targetX = Number(gwCoord?.x * mapScale.x) || 0;
        let targetY = Number(gwCoord?.y * mapScale.y) || 0;
        if (meshObj?.mesh255_distance > 1) {
          targetX = Number(routerCoord?.x * mapScale.x) || 0;
          targetY = Number(routerCoord?.y * mapScale.y) || 0;
        }
        let textRotation =
          ((Math.atan((targetY - deviceY) / (targetX - deviceX)) * 180) / Math.PI) || 0;
        return (
          <Fragment key={s}>
            <Group x={deviceX} y={deviceY}>
              {
                (rssi255WebDeviceID && deviceSelected) ?
                  <Rect fill="yellow" width={iconSize * mapScale.x * 1.2} height={iconSize * mapScale.y * 1.2} />
                  : null
              }
              <DeviceIcon
                deviceObj={deviceObj}
                deviceType={meshObj?.deviceType}
                deviceConnected={meshObj?.deviceConnected}
                layerProps={layerProps}
                onClick={() => {
                  dispatch({
                    type: "SET_GENERAL_STATE",
                    payload: { key: "rssi255WebDeviceID", value: s }
                  });
                }}
              />
              <Group y={iconSize * mapScale.y * 1.4} >
                <Rect fill={deviceSelected ? "yellow" : "white"} width={70 / scale} height={40 / scale} />
                <Text
                  text={`end:${meshObj?.mesh255_endpoint_addr}\njump:${meshObj?.mesh255_distance}\nroute:${meshObj?.mesh255_route_addr}`}
                  fontSize={12 / scale}
                />
              </Group>
              <Line
                points={[0, 0, targetX - deviceX, targetY - deviceY]}
                stroke={deviceSelected ? "#ec407a" : "pink"}
                strokeWidth={lineW}
              />
              {
                !rssi255WebHideReceiveStrength &&
                <Group
                  x={(targetX - deviceX) * 0.6}
                  y={(targetY - deviceY) * 0.6}
                  rotation={textRotation}
                >
                  <Rect fill={deviceSelected ? "yellow" : "white"} width={50 / scale} height={16 / scale} />
                  <Text
                    text={`R:${meshObj?.mesh255_receive_strength}`}
                    fontSize={12 / scale}
                    fill="green"
                  />
                </Group>
              }
              {
                !rssi255WebHideSendStrength &&
                <Group
                  x={(targetX - deviceX) * 0.3}
                  y={(targetY - deviceY) * 0.3}
                  rotation={textRotation}
                >
                  <Rect fill={deviceSelected ? "yellow" : "white"} width={50 / scale} height={16 / scale} />
                  <Text
                    text={`S:${meshObj?.mesh255_send_strength}`}
                    fontSize={12 / scale}
                    fill="blue"
                  />
                </Group>
              }

            </Group>
          </Fragment>
        );
      })}
    </>
  );
}

function DeviceIcon({ deviceObj, deviceType, deviceConnected, layerProps, onClick }) {
  const { iconSize, mapScale, scale } = layerProps;
  const { timeStamp } = deviceObj;
  if (deviceType === "light")
    return (
      <MapIconLight
        x={0}
        y={0}
        lightStyle={deviceObj?.style}
        iconSize={iconSize}
        mapScale={mapScale}
        scale={scale}
        connectStatus={deviceConnected}
        timeStamp={timeStamp}
        shadEnabled={false}
        onClick={onClick}
      />
    );
  if (deviceType === "sensor")
    return (
      <MapIconSensor
        x={0}
        y={0}
        layerProps={layerProps}
        type={deviceObj?.type}
        scale={scale}
        connectStatus={deviceConnected}
        timeStamp={timeStamp}
        gang={deviceObj?.gang}
        shadEnabled={false}
        onClick={onClick}
      />
    );
  if (deviceType === "daliCtl")
    return (
      <Group onClick={onClick}>
        <DaliCtl connectStatus={deviceConnected} size={iconSize * mapScale.x} />
      </Group>
    );
  return null;
}
