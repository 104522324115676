import React from "react";
import { Group, Path } from "react-konva";

export default function ZcSwitch({ x, y, size, shadEnabled, status }) {
  const scale = size / 79.375;
  const knobPos = status === 1 ? -58 : -88;

  return (
    <Group x={x} y={y} scaleY={scale * 1.4} scaleX={scale * 1.2} listening={false}>
      <Path
        // fill={status === 1 ? "#ffaaaa" : "#44aa00"}
        fill={status === 1 ? "#ffaaaa" : "#5D6D7E"}
        stroke="#ff6600"
        dash={[8, 8]}
        strokeWidth={6.635}
        data="M19.548 21.022l41.188-.282c18.684 2.092 21.208 31.574.448 35.252H19.399c-18.896-5.071-17.977-31.253.15-34.97z"
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={4}
        shadowOffset={{ x: 8, y: 8 }}
        shadowOpacity={0.3}
        perfectDrawEnabled={false}
      ></Path>
      <Path
        fill="#803300"
        data="M10.325 32.288c2.867-4.513 7.316-6.344 9.198-6.387l41.392-.492c3.524.244 7.126 2.885 10.181 6.793-1.04-2.007-2.003-4.052-3.983-5.612-1.819-1.474-3.944-2.4-6.381-2.765l-40.801.153c-2.656.705-4.63 1.767-6.005 3.143-1.87 1.512-2.782 3.325-3.601 5.167z"
        perfectDrawEnabled={false}
      ></Path>
      <Group x={knobPos} y={14}>
        <Path
          fill="#e6e6e6"
          data="M107.263 35.521h13.204c4.265-1.878 10.387-5.39 10.466-13.841-.533-7.524-6.718-12.07-10.661-12.184l-12.618.097c-5.94.398-11.62 4.956-11.542 13.646.4 6.962 6.354 12.19 11.15 12.282z"
          perfectDrawEnabled={false}
        ></Path>
        <Path
          fill="#666"
          data="M96.144 23.666c1.258 6.429 4.551 10.798 11.119 11.855h13.204c6.612-3.03 10.306-7.196 10.421-12.755-.283 3.622-.798 8.567-3.938 11.84-2.333 2.056-6.393 4.45-8.91 4.74l-10.554-.258c-2.816-.437-5.722-1.546-7.919-3.777-4.32-4.01-3.41-7.855-3.423-11.645z"
          perfectDrawEnabled={false}
        ></Path>
        <Path
          stroke="#b3b3b3"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth={3.8}
          data="M106.234 16.756v10.925"
          perfectDrawEnabled={false}
        ></Path>
        <Path
          stroke="#b3b3b3"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth={3.8}
          data="M113.802 16.412v10.925"
          perfectDrawEnabled={false}
        ></Path>
        <Path
          stroke="#b3b3b3"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeMiterlimit="4"
          strokeWidth={3.8}
          data="M121.485 16.412v10.925"
          perfectDrawEnabled={false}
        ></Path>
      </Group>
    </Group>
  );
}
