import { useState, useContext, useMemo } from "react";
import { SMContext } from "context/smContext";
import { Typography, Box, Button, TextField } from "@mui/material";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import DivExist from "components/DivExist";
import IconButtonDelete from "components/IconButtonDelete";
import General from "@ecoenghk/general";
import {
    daliGeneralDaliQuery,
    clearDaliReply,
} from "actions/daliCtlActions";
import ModalSM from "components/ModalSM";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import { ArrowForwardIos } from "@mui/icons-material";
import { daliCmdJsonArray } from "asset/string/string";
const gs = new General();

export default function GeneralDaliQueryModal({ daliCtlObj, shortAdd }) {
    const [, dispatch] = useContext(SMContext);
    const [open, setOpen] = useState(false);
    const [selectedShortAdd, setSelectedShortAdd] = useState(null);
    const [daliCmd, setDaliCmd] = useState(0);
    const { daliReply, daliCtlID } = daliCtlObj || {};
    const daliRepDec = daliReply?.reply || 0;
    const daliRepHex = "0x" + daliRepDec.toString(16);
    const daliRepBin = "0b" + daliRepDec.toString(2);
    const daliCmdHex = "0x" + daliReply?.daliCmd?.toString(16);
    const handleDaliGeneralQuery = async () => {
        const add = Number(shortAdd) >= 0 ? Number(shortAdd) : Number(selectedShortAdd);
        await daliGeneralDaliQuery(daliCtlObj, add, Number(daliCmd));
    };
    const handleClearReply = () => {
        clearDaliReply(daliCtlID);
        dispatch({
            type: "CLEARED_DALI_REPLY",
            payload: daliCtlID,
        });
    };
    const shortAddMenu = useMemo(() => {
        let m = {};
        gs.newArrayBetween(0, 63).forEach(n => {
            m[n] = n.toString();
        });
        return m;
    }, []);
    return (
        <>
            <Button onClick={() => setOpen(true)}>
                <ArrowForwardIos />
            </Button>
            <ModalSM
                open={open}
                onClose={() => setOpen(false)}
                width="50vw"
                height="90vh"
                modalTitle={`Dali command code? [Short add: ${shortAdd}]`}
                disableBottomClose
            >
                <DivExist show={shortAdd >= 0 ? false : true}>
                    <SpaceBetweenSelect
                        title="Short address"
                        data={selectedShortAdd || 0}
                        onChangeValue={val => setSelectedShortAdd(val)}
                        menuObj={shortAddMenu}
                    />
                </DivExist>
                <DivInline>
                    <TextField
                        size="small"
                        sx={{ width: "80%" }}
                        value={daliCmd}
                        onChange={(e) => setDaliCmd(e.target.value)}
                    />
                    <Button onClick={handleDaliGeneralQuery}>
                        <ArrowForwardIos />
                    </Button>
                </DivInline>
                <DivExist show={daliReply?.daliCmd && daliRepDec}>
                    <DivInline>
                        <Box sx={{ width: "70%", outline: "1px solid #fff" }}>
                            <Typography>
                                Dali command {daliReply?.daliCmd} / {daliCmdHex}
                            </Typography>
                            <Typography>
                                Reply {daliRepDec} / {daliRepHex} / {daliRepBin}
                            </Typography>
                        </Box>
                        <IconButtonDelete onBtnClick={handleClearReply} />
                    </DivInline>
                </DivExist>
                <Divider10 />
                {daliCmdJsonArray.map((obj, k) => (
                    <Typography
                        key={k}
                        display="block"
                        variant="caption"
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            handleClearReply();
                            setDaliCmd(obj.cmd);
                        }}
                    >
                        {obj.cmd} - {obj.name}
                    </Typography>
                ))}
            </ModalSM>
        </>
    );
}