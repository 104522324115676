import { Line } from "react-konva";
import {
  sensorColorLight,
  zoneControlColorLight,
  daliCtlColorLight,
  gatewayColorLight,
  lightColorLight,
  lightColorDark
} from "actions/generalActions";
export default function LinesLightSelected({ layerProps }) {
  const {
    selectedID,
    lightObjAll,
    layerScale,
    mapObjAll,
    activeMapID,
    selectedDeviceX,
    selectedDeviceY,
    mapScale,
    mapLayer,
  } = layerProps;
  const lightObj = lightObjAll[selectedID];
  const { sensorArray, zoneControlArray, daliCtlID, gatewayID } =
    lightObj || {};
  const mapLightObj = mapObjAll[activeMapID]?.lightObj;
  const mapSensorObj = mapObjAll[activeMapID]?.sensorObj;
  const mapZcObj = mapObjAll[activeMapID]?.zoneControlObj;
  const mapDaliCtlPos = mapObjAll[activeMapID]?.daliCtlObj?.[daliCtlID];
  const mapGatewayPos = mapObjAll[activeMapID]?.gatewayObj?.[gatewayID];
  return (
    <>
      {(sensorArray || []).map((s, key) => (
        <Line
          key={key}
          points={[
            selectedDeviceX || 0,
            selectedDeviceY || 0,
            (mapSensorObj?.[s]?.x * mapScale.x) || 0,
            (mapSensorObj?.[s]?.y * mapScale.y) || 0,
          ]}
          stroke={sensorColorLight}
          strokeWidth={1 / layerScale}
        />
      ))}
      {(zoneControlArray || []).map((z, key) => (
        <Line
          key={key}
          points={[
            selectedDeviceX || 0,
            selectedDeviceY || 0,
            (mapZcObj?.[z]?.x * mapScale.x) || 0,
            (mapZcObj?.[z]?.y * mapScale.y) || 0,
          ]}
          stroke={zoneControlColorLight}
          strokeWidth={1 / layerScale}
        />
      ))}
      {(daliCtlID ? true : false) && (
        <Line
          points={[
            selectedDeviceX || 0,
            selectedDeviceY || 0,
            (mapDaliCtlPos?.x * mapScale.x) || 0,
            (mapDaliCtlPos?.y * mapScale.y) || 0,
          ]}
          stroke={daliCtlColorLight}
          strokeWidth={1 / layerScale}
        />
      )}
      {(gatewayID && !daliCtlID ? true : false) && (
        <Line
          points={[
            selectedDeviceX || 0,
            selectedDeviceY || 0,
            (mapGatewayPos?.x * mapScale.x) || 0,
            (mapGatewayPos?.y * mapScale.y) || 0,
          ]}
          stroke={gatewayColorLight}
          strokeWidth={1 / layerScale}
        />
      )}
      {
        (lightObj?.slaveLightArray || []).map(slv =>
          <Line
            key={slv}
            points={[
              selectedDeviceX || 0,
              selectedDeviceY || 0,
              (mapLightObj?.[slv]?.x * mapScale.x) || 0,
              (mapLightObj?.[slv]?.y * mapScale.y) || 0,
            ]}
            stroke={lightColorLight}
            strokeWidth={1.5 / layerScale}
          />
        )
      }
      {
        lightObj?.masterSerial && (
          <Line
            points={[
              selectedDeviceX || 0,
              selectedDeviceY || 0,
              (mapLightObj?.[lightObj.masterSerial]?.x * mapScale.x) || 0,
              (mapLightObj?.[lightObj.masterSerial]?.y * mapScale.y) || 0,
            ]}
            stroke={lightColorDark}
            strokeWidth={1 / layerScale}
          />
        )
      }
    </>
  );
}
