import IconButtonBack from "components/IconButtonBack";
import { Typography, Box } from "@mui/material";
import ModalSM from "components/ModalSM";
import DivInline from "components/DivInline";
import Divider10 from "components/Divider10";
import moment from "moment";
export default function TimeHistoryDataModal({ open, json, handleClose, timeImg }) {
    delete json["_id"];
    const m = moment(json.timeStamp);
    if (!open) return null;
    return (
        <ModalSM width="85vw" height="80vh" open={open} onClose={handleClose}>
            <Box sx={{ padding: "0 2vw 0 2vw" }}>
                <DivInline alignItems="center">
                    <IconButtonBack onBtnClick={handleClose} />
                    {timeImg}
                    <Typography sx={{ marginLeft: "1vw" }}>{`[${json.commandCode.toString(16).padStart(4, "0")}]`}</Typography>
                    <Typography sx={{ marginLeft: "1vw" }}>{m.format("YYYY-MM-DD,HH:mm")}</Typography>
                </DivInline>
                <Divider10 />
                {Object.keys(json || {})
                    .sort((a, b) => a.localeCompare(b))
                    .map((field) => {
                        let value;
                        if (typeof json[field] === "object") value = JSON.stringify(json[field]);
                        if (typeof json[field] === "number") value = json[field].toString();
                        if (typeof json[field] === "string" || json[field] === undefined) value = json[field];
                        if (typeof json[field] === "boolean") value = json[field].toString();
                        if (Array.isArray(json[field])) value = JSON.stringify(json[field]);
                        if (field === "commandCode" || field === "uploadProtocol") value = json[field].toString(16).padStart(4, "0");
                        return (
                            <DivInline key={field} justifyContent="space-between" sx={{ width: "78vw" }}>
                                <Typography sx={{ width: "30%" }}>{field}</Typography>

                                <Typography sx={{ width: "70%", wordWrap: "break-word" }} >{value}</Typography>

                            </DivInline>
                        )
                    }
                    )}
            </Box>
        </ModalSM>
    );
}

