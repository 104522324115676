export default function BasicTable({
  data,
  borderColor,
  fontSize,
  tableWidth,
}) {
  const tStyle = {
    border: `1px solid ${borderColor ? borderColor : "grey"}`,
    borderCollapse: "collapse",
    paddingLeft: 3,
    fontSize: fontSize ? fontSize : "small",
    textAlign: "left",
  };
  return (
    <table
      style={{
        margin: "auto",
        width: tableWidth ? tableWidth : "100%",
        border: `1px solid ${borderColor ? borderColor : "grey"}`,
        borderCollapse: "collapse",
      }}
    >
      <tbody>
        {(data || []).map((row, key) => (
          <tr key={key}>
            {row.map((d, keyTd) => (
              <td style={tStyle} key={keyTd}>
                {d}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
