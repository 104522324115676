import { useContext, useRef, useState, useMemo, useEffect } from "react";
import {
    Typography,
    Box,
} from "@mui/material";
import DivInline from "components/DivInline";
import { SMContext } from "context/smContext";
import InputField from "components/InputField";
import Divider10 from "components/Divider10";
import { mgFindAll, mgFindOne } from "actions/mongoApiActions";
import { uploadMultiDevicesToFS } from "actions/serverActions";
import { ViewportList } from "react-viewport-list";
import {
    updateSingleGatewayFsToLs,
} from "actions/gatewayActions";
import General from "@ecoenghk/general";
import { alertWindow, confirmWindow, showBigProgress, hideBigProgress } from "actions/screenActions";
import IconButtonDelete from "components/IconButtonDelete";
import { isMatch } from "actions/generalActions";
import { getDocsWhereFS } from "actions/firebaseMgActions";
import JsonModal from "components/JsonModal";
import ModalSM from "components/ModalSM";
const gs = new General();
export default function ServerSettingGatewayPanel() {
    const [state, dispatch] = useContext(SMContext);
    const { serverID, serverObj } = state;
    const [allGatewaysLs, setAllGatewaysLs] = useState([]);
    const [allGatewaysFs, setAllGatewaysFs] = useState([]);
    const [allGatewayIDs, setAllGatewayIDs] = useState([]);
    const [gatewayObjAll, setGatewayObjAll] = useState({});
    const [searchText, setSearchText] = useState("");
    const [importID, setImportID] = useState("");
    const [activeObj, setActiveObj] = useState(null);
    const gatewayRef = useRef(null);
    useEffect(() => {
        handleFetchAllGateway();

    }, []);
    const handleFetchAllGateway = async () => {
        showBigProgress(dispatch);
        const arrLs = await mgFindAll("gateway");
        setAllGatewaysLs(arrLs);
        const arrFs = await getDocsWhereFS("SM_gateway", "array");
        setAllGatewaysFs(arrFs);
        const gidLs = arrLs.map((obj) => obj.gatewayID);
        const gidFs = arrFs.map((obj) => obj.gatewayID);
        let gidAll = [...gidLs, ...gidFs];
        gidAll = Array.from(new Set(gidAll));
        let objAll = {};
        gidAll.forEach(gid => {
            const lsObj = arrLs.find(obj => obj.gatewayID === gid);
            const fsObj = arrFs.find(obj => obj.gatewayID === gid);
            objAll[gid] = { ls: lsObj || {}, fs: fsObj || {} };
        })
        setAllGatewayIDs(gidAll);
        setGatewayObjAll(objAll);
        hideBigProgress(dispatch);

    };
    const allGatewayIDsUse = useMemo(() => {
        if (!allGatewayIDs || allGatewayIDs?.length === 0) return [];
        let arr = (allGatewayIDs || [])
            .sort((a, b) => a?.localeCompare(b))
        if (searchText !== "" && arr.length > 0) {
            arr = arr.filter(
                (gid) => {
                    if (isMatch(gid, searchText)) return true;
                    const objLs = allGatewaysLs.find((obj) => obj.gatewayID === gid);
                    const objFs = allGatewaysFs.find((obj) => obj.gatewayID === gid);
                    if (objLs || objFs) {
                        return isMatch(objLs?.description, searchText) ||
                            isMatch(objFs?.description, searchText);
                    }
                    return false;
                }

            );
        }
        return arr;
    }, [allGatewayIDs, searchText, allGatewaysLs, allGatewaysFs]);
    const handleFsToLs = async (gatewayID) => {
        confirmWindow(dispatch, `confirm to transfer gateway ${gatewayID} from FS to Server`, async () => {
            await updateSingleGatewayFsToLs(gatewayID);
            await gs.waitFor(1000);
            const newObj = await mgFindOne("gateway", "gatewayID", gatewayID);
            if (newObj) {
                const index = allGatewaysLs.findIndex((obj) => obj.gatewayID === gatewayID);
                let newAllGatewaysLs = [...allGatewaysLs];
                if (index !== -1) {
                    newAllGatewaysLs[index] = newObj;
                } else {
                    newAllGatewaysLs = [newObj, ...allGatewaysLs];
                }
                setAllGatewaysLs(newAllGatewaysLs);
                alertWindow(dispatch, `updated gateway ${gatewayID} - FS to Server`);
            } else {
                alertWindow(dispatch, `gateway ${gatewayID} not found in Server, transfer failed`);
            }
        });
    };
    const handleLsToFs = async (gatewayID) => {
        confirmWindow(dispatch, `confirm to transfer gateway ${gatewayID} from Server to FS`, async () => {
            await uploadMultiDevicesToFS("gateway", "SM_gateway", "gatewayID", [gatewayID]);
            const arrFs = await getDocsWhereFS("SM_gateway", "array");
            setAllGatewaysFs(arrFs);
            let gidAll = [...allGatewayIDs];
            if (gidAll.includes(gatewayID)) gidAll.push(gatewayID);
            setAllGatewayIDs(gidAll);
        });
    };
    return (<>
        <DivInline >
            <Typography variant="h6">Gateway</Typography>
            <InputField
                label="Search gatewayID/description"
                value={searchText}
                onInput={(v) => setSearchText(v)}
                keyPressEnter
                sx={{ width: "30vw", marginLeft: "1vw" }}
            />
            <IconButtonDelete onBtnClick={() => setSearchText("")} />
            <JsonModal show={true} jsonData={{ a: 12, b: 13, c: "dsjksjd", s: "sdjsakdjas" }} />

            {/* <Button
                variant="outlined"
                onClick={async () => {
                    await updateGatewaysFsToLs(socket);
                    await gs.waitFor(1200);
                    await handleFetchAllGateway();
                }}
            >
                All gateways FS ▶︎ Server
            </Button> */}
            {/* <DivInline>
                <TextField
                    label="import one gateway from FS (gatewayID)"
                    value={importID}
                    onChange={(e) => setImportID(e.target.value)}
                    size="small"
                    sx={{ minWidth: "30vw" }}
                />
                <Button
                    variant="outlined"
                    size="small"
                    onClick={async () => {
                        let fsObj = await mgPost("getDocFS", {
                            docPath: `SM_gateway/${importID}`,
                        });
                        if (fsObj.result !== "fail") {
                            fsObj = { ...fsObj, serverID: serverID };
                            await mgUpdateOneUpsert(
                                "gateway",
                                { gatewayID: importID },
                                fsObj
                            );
                            await mgPost("updateDocFS", {
                                docPath: `SM_gateway/${importID}`,
                                updateObj: fsObj,
                            });
                        } else {
                            alertWindow(dispatch, "No such gateway in FS");
                        }
                    }}
                >
                    import
                </Button>
            </DivInline> */}
        </DivInline>
        <Divider10 />
        <DivInline>
            <Box sx={{ width: "10vw" }}>gatewayID</Box>
            <Box sx={{ width: "25vw" }}>description-Server</Box>
            <Box sx={{ width: "15vw" }}></Box>
            <Box sx={{ width: "15vw" }}></Box>
            <Box sx={{ width: "25vw" }}>description-FS</Box>

        </DivInline>
        <Divider10 />
        <div className="scroll-container" ref={gatewayRef}>
            <ViewportList viewportRef={gatewayRef} items={allGatewayIDsUse}>
                {(gatewayID, index) => {
                    const objLs = gatewayObjAll[gatewayID]?.ls;
                    const objFs = gatewayObjAll[gatewayID]?.fs;
                    return (
                        <Box
                            key={index}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                borderBottom: "1px solid grey",
                                "&:hover": { background: "#1B2631" },
                            }}
                        >
                            <Box sx={{ width: "10vw" }}>{gatewayID}</Box>
                            <Box sx={{ width: "25vw" }}>
                                <Typography variant="caption" display="block" onClick={() => setActiveObj(objLs)}>{objLs?.description}</Typography>
                            </Box>
                            <Box sx={{ width: "15vw" }}>
                                <button onClick={() => handleLsToFs(gatewayID)}>
                                    Server⏩FS
                                </button>
                            </Box>
                            <Box sx={{ width: "15vw" }}>
                                <button onClick={async () => handleFsToLs(gatewayID)}  >
                                    Server⏪FS
                                </button>
                            </Box>
                            <Box sx={{ width: "25vw" }}>
                                <Typography variant="caption" display="block" onClick={() => setActiveObj(objFs)}>{objFs?.description}</Typography>
                            </Box>

                        </Box>

                    );
                }}
            </ViewportList>
        </div>
        {
            activeObj &&
            <Box sx={{ position: "absolute", top: "20vh", left: "20vw", backgroundColor: "darkgrey" }}>

                {Object.keys(activeObj || {}).map((key) => {
                    return (
                        <DivInline key={key}>
                            <Box sx={{ width: "20vw" }}>{key}</Box>
                            <Box sx={{ width: "20vw" }}>{JSON.stringify(activeObj[key])}</Box>
                        </DivInline>)
                })}
                <Divider10 />
                <button onClick={() => setActiveObj(null)}>close</button>
            </Box>

        }

    </>);
}