export default function TestStart({ width, height, sizeUnit }) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg viewBox="0 0 79.375 79.375" width={w + unit} height={h + unit}>
      <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
      <circle
        cx="39.565"
        cy="39.565"
        r="34.525"
        fill="none"
        stroke="#002b11"
        strokeWidth="5.949"
      ></circle>
      <path
        fill="#333"
        stroke="#333"
        strokeWidth="22.677"
        d="M195.54 110.56L52.5 191.491l1.429-164.34z"
        transform="matrix(.23565 0 0 .24153 15.21 13.214)"
      ></path>
    </svg>
  );
}
