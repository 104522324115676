import { useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { useState } from "react";
import { Typography, Tooltip, Button } from "@mui/material";
import { Edit } from "@mui/icons-material";
import Divider10 from "components/Divider10";
import ModalSM from "components/ModalSM";
import moment from "moment";
import SpaceBetweenInput from "components/SpaceBetweenInput";
import SpaceBetweenDiv from "components/SpaceBetweenDiv";
import SpaceBetweenSelect from "components/SpaceBetweenSelect";
import { dtkChannelMenu, dtkLoraParameterMenu } from "asset/string/string";
import { confirmWindow } from "actions/screenActions";
import IconButtonBack from "components/IconButtonBack";
import IconButtonRefresh from "components/IconButtonRefresh";
import { lightChangeDtkInfo, lightQueryDtkInfo } from "actions/lightActions";
import { sensorChangeDtkInfo, sensorQueryDtkInfo } from "actions/sensorActions";
import { daliCtlChangeDtkInfo, daliCtlQueryDtkInfo } from "actions/daliCtlActions";
import {
  gatewayChangeDtkInfo,
  gatewayQueryDtkInfo,
} from "actions/gatewayActions";

export default function EditDtkInfoModal({
  deviceObj,
  deviceType,
  ttyS_no = "",
}) {
  const [state, dispatch] = useContext(SMContext);
  const [open, setOpen] = useState(false);
  const [dtkInfo, setDtkInfo] = useState({});
  // const comInterface = deviceObj[`comInterface${interfaceNo}`];
  const editInfo = (updateObj) => {
    const newInfo = { ...dtkInfo, ...updateObj };
    setDtkInfo(newInfo);
  };
  const handleSave = async () => {
    confirmWindow(dispatch, "Confirm change dtk info?", async () => {
      if (deviceType === "light") lightChangeDtkInfo(deviceObj, dtkInfo);
      else if (deviceType === "daliCtl") daliCtlChangeDtkInfo(deviceObj, dtkInfo);
      else if (deviceType === "sensor") sensorChangeDtkInfo(deviceObj, dtkInfo);
      else if (deviceType === "gateway") gatewayChangeDtkInfo(deviceObj, dtkInfo, ttyS_no);
    });
  };
  return (
    <>
      <Button
        variant="outlined"
        size="small"
        onClick={() => {
          setOpen(true);
          if (deviceType === "light" || deviceType === "sensor" || deviceType === "daliCtl")
            setDtkInfo({
              dtkAdd: deviceObj.dtkAdd,
              dtkPanID: deviceObj.dtkPanID,
              dtkChannel: deviceObj.dtkChannel,
              dtkType: deviceObj.dtkType,
              loraParameter: deviceObj.loraParameter,
              dtkTransferMode: deviceObj.dtkTransferMode,
            });
          else if (deviceType === "gateway")
            setDtkInfo({
              dtkAdd: deviceObj[ttyS_no].dtkAdd,
              dtkPanID: deviceObj[ttyS_no].dtkPanID,
              dtkChannel: deviceObj[ttyS_no].dtkChannel,
              dtkType: deviceObj[ttyS_no].dtkType,
              loraParameter: deviceObj[ttyS_no].loraParameter,
              dtkTransferMode: deviceObj[ttyS_no].dtkTransferMode,
            });
        }}
      >
        <Tooltip title="Edit DTK Info">
          <Edit />
        </Tooltip>
      </Button>
      <ModalSM
        open={open}
        onClose={() => setOpen(false)}
        width="70vw"
        height="80vh"
        onSave={handleSave}
      >
        <DivInline>
          <IconButtonBack onBtnClick={() => setOpen(false)} />
          <Typography>Edit DTK Info</Typography>
          <IconButtonRefresh
            onBtnClick={() => {
              if (deviceType === "light") lightQueryDtkInfo(deviceObj);
              else if (deviceType === "daliCtl") daliCtlQueryDtkInfo(deviceObj);
              else if (deviceType === "sensor") sensorQueryDtkInfo(deviceObj);
              else if (deviceType === "gateway") gatewayQueryDtkInfo(deviceObj, ttyS_no);
            }}
          />
        </DivInline>
        <Divider10 />
        <SpaceBetweenDiv
          title="Internal address"
          data={dtkInfo.dtkInternalAdd}
        />
        <SpaceBetweenInput
          title="Address (0001-ff00)"
          data={dtkInfo.dtkAdd || ""}
          onInput={(e) => editInfo({ dtkAdd: e.target.value })}
        />
        <SpaceBetweenInput
          title="Pan ID (0001-ff00)"
          data={dtkInfo.dtkPanID || ""}
          onInput={async (e) => editInfo({ dtkPanID: e.target.value })}
        />
        <SpaceBetweenSelect
          title="Channel"
          data={dtkInfo.dtkChannel || 0}
          menuObj={dtkChannelMenu}
          onChange={(e) => {
            editInfo({ dtkChannel: Number(e.target.value) });
          }}
        />
        <SpaceBetweenSelect
          title="Type"
          data={dtkInfo.dtkType || 0}
          menuObj={{
            0: "-",
            1: "1: coordinator",
            2: "2: router",
            3: "3: endpoint",
          }}
          onChange={(e) => {
            editInfo({ dtkType: Number(e.target.value) });
          }}
        />
        <SpaceBetweenSelect
          title="Lora parameter"
          data={dtkInfo.loraParameter || 0}
          menuObj={dtkLoraParameterMenu}
          onChange={(e) => {
            editInfo({ loraParameter: Number(e.target.value) });
          }}
        />
        <SpaceBetweenSelect
          title="Transfer mode"
          data={dtkInfo.dtkTransferMode || 0}
          menuObj={{
            0: "-",
            1: "透明傳輸",
            2: "透明傳輸+短地址",
            3: "透明傳輸+自定義地址",
            4: "透明傳輸+MAC地址",
          }}
          onChange={(e) =>
            editInfo({ dtkTransferMode: Number(e.target.value) })
          }
        />
      </ModalSM>
    </>
  );
}
