import { SMContext } from "context/smContext";
import { Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography } from "@mui/material";
import { updateGatewayProperty, queryGatewayOneSetting, controlSingleGateway } from "actions/gatewayActions";
import ListSortButton from "components/ListSortButton";
import DivInline from "components/DivInline";
import ModalSM from "components/ModalSM";
import { useState, useMemo, useContext } from "react";
import IconButtonBack from "components/IconButtonBack";
import { promptWindow } from "actions/screenActions";
export default function WifiScanResultModal({ gatewayObj }) {
    const [state, dispatch] = useContext(SMContext);
    const { gatewayID, wifiScanResult } = gatewayObj;
    const [open, setOpen] = useState(false);
    const [hideEspNow, setHideEspNow] = useState(false);
    const handleClose = () => {
        setOpen(false);
        updateGatewayProperty(gatewayObj, { wifiScanResult: {} }, false);
    }
    const [sortOrder, setSortOrder] = useState("asc");
    const [sortBy, setSortBy] = useState("ssid");
    const onAsc = (field) => {
        setSortOrder("asc");
        setSortBy(field);
    }
    const onDesc = (field) => {
        setSortOrder("desc");
        setSortBy(field);
    }
    const resultList = useMemo(() => {
        return Object.keys(wifiScanResult)
            .sort((a, b) => {
                if (sortOrder === "asc") {
                    return wifiScanResult[a][sortBy].localeCompare(wifiScanResult[b][sortBy]);
                }
                return wifiScanResult[b][sortBy].localeCompare(wifiScanResult[a][sortBy]);
            })
            .map((m) => wifiScanResult[m])
            .filter(m => !hideEspNow || !m.ssid.includes("ESPNOW"));
    }, [wifiScanResult, sortOrder, sortBy, hideEspNow]);
    const handleCheckLightConnection = async (ssid) => {
        promptWindow(dispatch, "Wifi password?", async (password) => {
            controlSingleGateway(gatewayID, 0x0031, { ssid, password });
        });
    }
    return <>
        <Button variant="outlined" size="small" onClick={() => setOpen(true)}>Result</Button>
        <ModalSM open={open} onClose={handleClose} width={"70vw"} height={"85vh"}>
            <DivInline>
                <IconButtonBack onBtnClick={handleClose} />
                <Typography sx={{ marginRight: "1vw" }}>Wifi Network Scan</Typography>
                <Button variant="outlined" size="small"
                    onClick={() => queryGatewayOneSetting(gatewayObj.gatewayID, 0x0030, "queryWifiScanResult")}
                >scan</Button>
                <Button variant="outlined" size="small"
                    onClick={() => setHideEspNow(!hideEspNow)}
                >{hideEspNow ? "show" : "hide"} esp-now</Button>
            </DivInline>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell><ListSortButton title="SSID" field="ssid" sortBy={sortBy} sortOrder={sortOrder} onAsc={() => onAsc("ssid")} onDesc={() => onDesc("ssid")} /></TableCell>
                            <TableCell><ListSortButton title="Channel" field="channel" sortBy={sortBy} sortOrder={sortOrder} onAsc={() => onAsc("channel")} onDesc={() => onDesc("channel")} /></TableCell>
                            <TableCell><ListSortButton title="Frequency" field="frequency" sortBy={sortBy} sortOrder={sortOrder} onAsc={() => onAsc("frequency")} onDesc={() => onDesc("frequency")} /></TableCell>
                            <TableCell><ListSortButton title="RSSI" field="signalLevel" sortBy={sortBy} sortOrder={sortOrder} onAsc={() => onAsc("signalLevel")} onDesc={() => onDesc("signalLevel")} /></TableCell>
                            <TableCell><ListSortButton title="BSSID" field="bssid" sortBy={sortBy} sortOrder={sortOrder} onAsc={() => onAsc("bssid")} onDesc={() => onDesc("bssid")} /></TableCell>
                            <TableCell>Flags</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            resultList.map((m, key) => {
                                const { ssid, channel, frequency, signalLevel, bssid, flags } = m;
                                let flagsArray = flags.split("]");
                                flagsArray = flagsArray.map(m => m.substring(1));
                                return <TableRow key={key}>
                                    <TableCell>{ssid}</TableCell>
                                    <TableCell>{channel}</TableCell>
                                    <TableCell>{frequency}</TableCell>
                                    <TableCell>{signalLevel}</TableCell>
                                    <TableCell>{bssid}</TableCell>
                                    <TableCell>{flagsArray.map(m => <Typography variant="caption" display="block" key={m}>{m}</Typography>)}</TableCell>
                                    <TableCell><Button variant="outlined" size="small" onClick={() => handleCheckLightConnection(ssid)}>connect</Button></TableCell>
                                </TableRow>
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </ModalSM>
    </>
}