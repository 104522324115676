import { useContext, Fragment } from "react";
import { SMContext } from "context/smContext";
import { Typography } from "@mui/material";
import { Map } from "@mui/icons-material";
import SensorIcon from "components/SensorIcon";
import DivExist from "components/DivExist";
import DivSpaceBetween from "components/DivSpaceBetween";
import { lightStyleMenu, sensorStyleMenu } from "asset/string/string";
import { titleText, dataText } from "asset/string/color";
import GatewayIcon from "components/GatewayIcon";
import LightIcon from "components/LightIcon";
import DaliCtl from "asset/svgComp/DaliCtl";
import DeviceMongoModal from "components/DeviceMongoModal";

export default function LightStatisticInfo() {
  const [state] = useContext(SMContext);
  const {
    activeMapID,
    mapObjAll,
    lightObjAll,
  } = state;
  const lightArray = Object.keys(mapObjAll?.[activeMapID]?.lightObj || {});
  const qtyBatVI = lightArray.filter(
    (s) => lightObjAll[s]?.type?.batVI
  )?.length;
  const qtyPassTest = lightArray.filter(
    (s) => lightObjAll[s]?.reportObj?.result === "OK"
  )?.length;
  const passRate = ((qtyPassTest / qtyBatVI) * 100).toFixed(0);

  const gwArray = Object.keys(mapObjAll?.[activeMapID]?.gatewayObj || {});
  const daliCtlArray = Object.keys(mapObjAll?.[activeMapID]?.daliCtlObj || {});
  const sensorQty = Object.keys(mapObjAll?.[activeMapID]?.sensorObj || {})?.length;



  return (
    <>
      <Map sx={{ color: titleText }} />
      <Typography
        style={{ color: titleText }}
        display="block"
        align="center"
        variant="caption"
      >
        Map ID
      </Typography>
      <DeviceMongoModal deviceObj={mapObjAll?.[activeMapID]}>
        <Typography
          display="block"
          align="center"
          variant="caption"
          style={{ color: dataText }}
        >
          {activeMapID}
        </Typography>
      </DeviceMongoModal>
      <hr color="lightgrey" size={1} width="90%" />
      <Typography style={{ color: titleText }} variant="caption">
        Light:
      </Typography>
      <LightRow lightStyle="tube" />
      <LightRow lightStyle="tubeHalf" />
      <LightRow lightStyle="owl" />
      <LightRow lightStyle="exit" />
      <LightRow lightStyle="downlight" />
      <LightRow lightStyle="panellight" />

      <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
        <Typography style={{ color: titleText }} variant="caption">
          Total
        </Typography>
        <Typography style={{ color: dataText }}>{lightArray.length}</Typography>
      </DivSpaceBetween>

      <hr color="lightgrey" size={1} width="90%" />
      {/* <DivExist show={qtyBatVI > 0}>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Battery light
          </Typography>
          <Typography style={{ color: dataText }}>{qtyBatVI}</Typography>
        </DivSpaceBetween>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Passed latest test
          </Typography>
          <Typography style={{ color: dataText }}>{qtyPassTest}</Typography>
        </DivSpaceBetween>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption">
            Pass rate
          </Typography>
          <Typography style={{ color: dataText }}>{passRate}%</Typography>
        </DivSpaceBetween>
      </DivExist> 
      <hr color="lightgrey" size={1} width="90%" />*/}
      <Typography style={{ color: titleText }} variant="caption">
        Gateway:
      </Typography>
      <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
        <GatewayIcon width={2.3} sizeUnit="vw" disabledConnectStatus />
        <Typography style={{ color: dataText }}>{gwArray.length}</Typography>
      </DivSpaceBetween>

      <hr color="lightgrey" size={1} width="90%" />
      <DivExist show={sensorQty > 0}>
        <Typography style={{ color: titleText }} variant="caption">
          Sensor:
        </Typography>

        <SensorRow type="motion" />
        <SensorRow type="daylight" />
        <SensorRow type="daylightmotion" />
        {[1, 2, 3, 4].map((gang) => (
          <Fragment key={gang}>
            <SensorRow type="lightswitch" gang={gang} />
            <SensorRow type="scenebutton" gang={gang} />
            <SensorRow type="dimcontroller" gang={gang} />
          </Fragment>
        ))}
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <Typography style={{ color: titleText }} variant="caption"> Total </Typography>
          <Typography style={{ color: dataText }}>{sensorQty}</Typography>
        </DivSpaceBetween>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
      <DivExist show={daliCtlArray.length > 0}>
        <Typography style={{ color: titleText }} variant="caption">
          Dali controller:
        </Typography>
        <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
          <DaliCtl width={2.3} sizeUnit="vw" disabledConnectStatus />
          <Typography style={{ color: dataText }}>
            {daliCtlArray.length}
          </Typography>
        </DivSpaceBetween>
        <hr color="lightgrey" size={1} width="90%" />
      </DivExist>
    </>
  );
}
function LightRow({ lightStyle }) {
  const [state] = useContext(SMContext);
  const { activeMapID, mapObjAll, lightObjAll } = state;
  const lightArray = Object.keys(mapObjAll?.[activeMapID]?.lightObj || {})
    .filter((s) => lightObjAll?.[s]?.style === lightStyle);

  return (
    <DivExist show={lightArray.length > 0}>
      <DivSpaceBetween
        style={{
          margin: 3,
          width: "90%",
        }}
      >
        <LightIcon
          width={lightStyle === "tubeHalf" ? 1.8 : 2}
          sizeUnit="vw"
          lightStyle={lightStyle}
          tooltip={lightStyleMenu[lightStyle]}
          disabledConnectStatus
          disabledStatus
        />
        <Typography style={{ color: dataText }}>
          {lightArray.length}
        </Typography>
      </DivSpaceBetween>
    </DivExist>
  );
};
function SensorRow({ type, gang }) {
  const [state] = useContext(SMContext);
  const { activeMapID, mapObjAll, sensorObjAll } = state;
  let sensorArray = Object.keys(mapObjAll?.[activeMapID]?.sensorObj || {})
    .filter(
      (id) => sensorObjAll?.[id]?.type === type
    );
  if (type === "lightswitch" || type === "scenebutton" || type === "dimcontroller") {
    sensorArray = sensorArray.filter(
      (id) => sensorObjAll?.[id]?.gang === gang
    )
  }
  if (sensorArray.length === 0) {
    return null;
  }
  return (
    <DivSpaceBetween style={{ margin: 3, width: "90%" }}>
      <SensorIcon
        width={2}
        sizeUnit="vw"
        type={type}
        tooltip={sensorStyleMenu[type]}
        disabledConnectStatus
        disabledStatus
        gang={gang}
      />
      <Typography style={{ color: dataText }}>
        {sensorArray.length}
      </Typography>
    </DivSpaceBetween>
  );
};