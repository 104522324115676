import React, { useState, useEffect, useRef } from "react";
import { Group, Text, Rect, Image } from "react-konva";
import useImage from "use-image";
import Konva from "konva";
import KonvaLabel from "containers/map/device/KonvaLabel";
export default function KonvaFaButton({
  x,
  y,
  mapScale,
  enablePan,
  stageScale,
  btnSize = 20,
  onBtnClick,
  iconImg,
  iconSvg,
  fa_code,
  disabled,
  stroke,
  tooltip,
  tagColor,
}) {
  const [shadOff, setShadOff] = useState({ x: 3, y: 3 });
  const [btnBG, setBtnBG] = useState("lightGrey");
  const [showLabel, setShowLabel] = useState(false);
  const btnRef = useRef(null);
  const iconColor = disabled ? "#c0c0c0" : "#404040";

  const mouseDown = () => {
    if (!disabled) {
      setShadOff({ x: 1, y: 1 });
    }
  };
  const mouseUp = () => {
    if (!disabled) {
      setShadOff({ x: 3, y: 3 });
    }
  };
  const handleButtonClick = () => {
    if (!disabled) {
      onBtnClick();
    }
  };
  const btnWidth = btnSize * mapScale.x * 1.15;
  const btnHeight = btnSize * mapScale.y * 1.15;
  const iconPadding = btnSize * mapScale.x * 0.15;
  return (
    <Group
      x={x * mapScale.x}
      y={(y || 0) * mapScale.y}
      onClick={handleButtonClick}
      onTap={handleButtonClick}
      onMouseDown={mouseDown}
      onMouseUp={mouseUp}
      onMouseOver={(e) => {
        const container = e.target.getStage().container();
        if (!disabled) {
          setBtnBG("yellow");
          setShowLabel(true);
          container.style.cursor = "pointer";
          const btn = btnRef.current;
          btn.to({
            scaleX: 1.3,
            scaleY: 1.3,
            duration: 0.1,
            // zIndex: 2,
          });
        } else {
          container.style.cursor = "not-allowed";
        }
      }}
      onMouseOut={(e) => {
        const container = e.target.getStage().container();
        setBtnBG("lightgrey");
        setShowLabel(false);
        container.style.cursor = enablePan ? "grab" : "default";
        const btn = btnRef.current;
        btn.to({
          scaleX: 1,
          scaleY: 1,
          duration: 0.1,
          // zIndex: 0,
        });
      }}
      ref={btnRef}
    >
      <Rect
        width={btnWidth}
        height={btnHeight}
        fill={btnBG}
        stroke={stroke}
        shadowColor="black"
        shadowBlur={2}
        shadowOffset={shadOff?.x ? shadOff : { x: 0, y: 0 }}
        shadowOpacity={0.5}
      />

      {fa_code ? (
        <Text
          x={((btnSize / 5) * mapScale.x) / stageScale}
          y={((btnSize / 5) * mapScale.y) / stageScale}
          fontSize={(btnSize * 0.8 * mapScale.x) / stageScale}
          fontFamily="FontAwesome"
          text={fa_code}
          fill={iconColor}
        />
      ) : iconImg ? (
        <IconImage
          imgIcon={iconImg}
          mapScale={mapScale}
          disabled={disabled}
          imgSize={btnSize * 0.8}
          stageScale={stageScale}
        />
      ) : (
        <Group x={iconPadding} y={iconPadding}>{iconSvg}</Group>
      )}
      {tooltip && showLabel && (
        <KonvaLabel
          x={btnWidth / 2}
          y={btnHeight * 1.2}
          text={tooltip}
          tagColor={tagColor}
          fontSize={12}
          scale={stageScale}
        />
      )}
    </Group>
  );
}

const IconImage = ({ imgIcon, mapScale, disabled, imgSize, stageScale }) => {
  const [image] = useImage(imgIcon);
  const imageRef = useRef();
  const rgb = disabled
    ? {
      green: 192,
      red: 192,
      blue: 192,
    }
    : {
      green: 64,
      red: 64,
      blue: 64,
    };
  useEffect(() => {
    if (image && imageRef && imageRef.current) {
      imageRef.current.cache();
      //   since this update is not handled by "react-konva" and we are using Konva methods directly
      // we have to redraw layer manually
      imageRef.current.getLayer().batchDraw();
    }
  }, [image, rgb]);
  return (
    <Image
      ref={imageRef}
      image={image}
      x={((imgSize || 20) * mapScale.x) / stageScale / 8}
      y={((imgSize || 20) * mapScale.y) / stageScale / 8}
      width={((imgSize || 20) * mapScale.x) / stageScale}
      height={((imgSize || 20) * mapScale.y) / stageScale}
      filters={[Konva.Filters.RGB]}
      green={rgb.green}
      red={rgb.red}
      blue={rgb.blue}
    //   filters={[Konva.Filters.Blur]}
    //   blurRadius={10}
    />
  );
};
