import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import DivInline from "components/DivInline";
import { Typography, Checkbox, Button } from "@mui/material";
import Divider10 from "components/Divider10";
import moment from "moment";
import { mgFindAll, mgFindByMatchAndRange } from "actions/mongoApiActions";
import ModalSM from "components/ModalSM";
import SystemStart from "asset/svgComp/SystemStart";
// import GatewayTrafficModal from "containers/gateway/GatewayTrafficModal";
export default function DataHistoryPanel() {
  const [state, dispatch] = useContext(SMContext);
  const [gwLog, setGwLog] = useState([]);
  // const [hideGwCheck, setHideGwCheck] = useState(false);
  const { activeGatewayID, gatewayObjAll } = state;
  // const gatewayObj = gatewayObjAll[activeGatewayID];
  useEffect(() => {
    fetchGatewayLog();
    async function fetchGatewayLog() {
      let arr = [];
      arr = await mgFindByMatchAndRange(
        "gwLog",
        "gatewayID",
        activeGatewayID,
        "timeStamp",
        moment().valueOf(),
        moment().subtract(4, "hours").valueOf()
      );
      arr = arr.sort((a, b) => b.timeStamp - a.timeStamp);
      // console.log(arr);
      setGwLog(arr);
    }
  }, []);

  return (
    <div style={{ height: "40vh", overflow: "auto" }}>
      <DivInline justifyContent="center">
        <Typography variant="h6" align="center" onClick={() => console.log(gwLog)}>
          History
        </Typography>
        {/* <Button onClick={() => {
          dispatch({ type: "OPENED_GATEWAY_ADVANCE_HISTORY_MODAL", payload: activeGatewayID });
        }}>
          Traffic
        </Button> */}
        {/* <GatewayTrafficModal /> */}
        {/* <Typography style={{ marginLeft: 50 }} variant="caption">
          hide gwCheck
        </Typography> */}
        {/* <Checkbox
          onChange={(e) => setHideGwCheck(e.target.checked)}
          checked={hideGwCheck}
        /> */}
      </DivInline>
      <Divider10 />

      <table style={{ width: "100%" }}>
        <tbody style={{ width: "100%" }}>
          <tr style={{ width: "100%" }}>
            <Tdata data="date" />
            <Tdata data="time" />
            <Tdata data="event" />
          </tr>
          {gwLog.map((log, key) => (
            <tr key={key}>
              <Tdata data={moment(log.timeStamp).format("YYYY-MM-DD")} />
              <Tdata data={moment(log.timeStamp).format("HH:mm:ss")} />
              <td>
                <HistoryObj obj={log} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Divider10 />
    </div>
  );
}

function Tdata({ data, style, width }) {
  return (
    <td style={{ width: width }}>
      <Typography align="center" style={style} variant="caption">
        {data}
      </Typography>
    </td>
  );
}

function HistoryObj({ obj }) {
  const [open, setOpen] = useState(false);
  const { commandCode, uploadProtocol } = obj;
  let timeMessage = "";
  let timeImg = null;
  switch (commandCode) {
    case 0x0101:
      timeMessage = `Gateway start`;
      timeImg = <SystemStart />;
      break;
    default:
      timeMessage = `Unknown command ${commandCode || uploadProtocol}`;
      break;
  }

  return (
    <>
      <Typography
        variant="caption"
        sx={{ cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        {timeMessage}
      </Typography>
      <ModalSM open={open} onClose={() => setOpen(false)}>
        {Object.entries(obj)
          .sort((a, b) => a[0]?.localeCompare(b[0]))
          .map((pair, key) => (
            <DivInline key={key} justifyContent="space-between">
              <Typography>{pair[0]}</Typography>
              <Typography>{String(pair[1])}</Typography>
            </DivInline>
          ))}
      </ModalSM>
    </>
  );
}
