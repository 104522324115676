import { useState } from "react";
import { Group, Rect } from "react-konva";
import moment from "moment";
import ZcTimerSettingModal from "../modal/ZcTimerSettingModal";

export default function ZcDeviceTimer({
  mapLocation,
  layerProps,
  zoneControlObj,
  handleSelectDevice,
  handleShiftSelectDevice,
  selected,
  onMouseOver,
  onMouseLeave,
}) {
  const [scale, setScale] = useState(1);
  const [touchTime, setTouchTime] = useState(0);
  const [openTimerModal, setOpenTimerModal] = useState(false);
  const { layerRef, iconSize, mapScale } = layerProps;
  const { zoneControlID } = zoneControlObj || {};
  const handleClick = async (e) => {
    if (e.evt.shiftKey) {
      handleShiftSelectDevice(e);
    } else if (e.evt.button === 0) {
      setOpenTimerModal(true);
    } else if (e.evt.button === 2) {
      //right click
      handleSelectDevice(e);
    }
    const layer = layerRef.current;
    const layerScale = layer.scaleX();
    setScale(layerScale);
  }
  return (
    <Group>
      <Rect
        width={iconSize * mapScale.x * 1.2}
        height={iconSize * mapScale.x * 1.2}
        onClick={handleClick}
        onTap={() => setOpenTimerModal(true)}
        onDblTap={(e) => handleSelectDevice(e)}
        onTouchStart={() => setTouchTime(moment().valueOf())}
        onTouchEnd={(e) => {
          const newT = moment().valueOf();
          const duration = newT - touchTime;
          if (duration < 1000) {
            setOpenTimerModal(true);
          } else {
            handleSelectDevice(e);
          }
          const layer = layerRef.current;
          const layerScale = layer.scaleX();
          setScale(layerScale);
        }}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        perfectDrawEnabled={false}
      />
      <ZcTimerSettingModal
        open={openTimerModal}
        mapLocation={mapLocation}
        x={0}
        y={0}
        layerProps={layerProps}
        handleClose={() => setOpenTimerModal(false)}
        zoneControlID={zoneControlID}
        zoneControlObj={zoneControlObj}
        scale={scale}
      />
    </Group>
  );
}
