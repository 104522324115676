import React from "react";

export default function ZcSceneButton({
  width,
  height,
  sizeUnit,
  status,
  plateColor,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <path
        fill={plateColor || "#ccc"}
        stroke="#6c5d53"
        strokeWidth={7}
        strokeDasharray="5,5"
        d="M16.135 8.792l55.972.089c3.558.53 5.2 2.797 5.448 6.327v56.637c-.434 2.847-2.235 4.687-5.145 5.71l-57.214-.112c-3.661-.499-4.74-2.95-5.167-5.896L9.962 14c1.005-2.771 2.48-5.08 6.173-5.208z"
      ></path>
      <path
        fill={status === 1 ? "red" : "#0ff"}
        d="M64.489 43.135a20.213 20.611 0 01-20.213 20.611 20.213 20.611 0 01-20.213-20.611 20.213 20.611 0 0120.213-20.611 20.213 20.611 0 0120.213 20.611z"
      ></path>
      <path
        fill="#666"
        d="M60.765 42.975a16.646 16.974 0 01-16.646 16.974 16.646 16.974 0 01-16.646-16.974 16.646 16.974 0 0116.646-16.974 16.646 16.974 0 0116.646 16.974z"
      ></path>
      {/* {!disabledConnectStatus && (
        <circle r="6" cx="6" cy="7" fill={connectStatus ? "green" : "red"} />
      )} */}
    </svg>
  );
}
