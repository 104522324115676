import { useState, Fragment } from "react";
import { Line, Rect, Circle } from "react-konva";
import DivExist from "components/DivExist";
import { alertWindow, confirmWindow } from "actions/screenActions";
import { sensorConnectSensor } from "actions/sensorActions";
import SenMotionSensorModal from "../modal/SenMotionSensorModal";
import SenSceneButtonSensorModal from "../modal/SenSceneButtonSensorModal";
import ChooseGangModal from "../modal/ChooseGangModal";
import General from "@ecoenghk/general";
import { darkColorArr } from "actions/generalActions";
import ButtonNo from "./ButtonNo";
import SenDaylightSensorModal from "../modal/SenDaylightSensorModal";
const gs = new General();
export default function MapSensorSensorGroup({
  layerProps,
  sensorID,
  x,
  y,
  sensorObj,
}) {

  const {
    iconSize,
    mapScale,
    sensorObjAll,
    dispatch,
    mapObjAll,
    activeMapID,
    enablePan,
    layerScale,
  } = layerProps;
  const sensorMapObj = mapObjAll[activeMapID]?.sensorObj;
  const [selectedSensor, setSelectedSensor] = useState("");
  const [controlledDeviceX, setControlledDeviceX] = useState(0);
  const [controlledDeviceY, setControlledDeviceY] = useState(0);
  const [modalLocation, setModalLocation] = useState("topleft"); //topleft, topright, bottomleft, bottomright
  const [showEditModal, setShowEditModal] = useState(false);
  const [chooseGangDeviceID, setChooseGangDeviceID] = useState("");
  const { type, sensorMap, gang } = sensorObj || {};

  function handleCloseModal() {
    setShowEditModal(false);
    setSelectedSensor("");
  }
  const handleClick = (e, sensorIDconnect, isUnderSensor) => {
    e.evt.preventDefault();
    if (isUnderSensor) {
      handleOpenSensorModal(sensorIDconnect);
    } else {
      handleConnectSensor(sensorIDconnect);
    }
  };
  const handleOpenSensorModal = (s) => {
    setControlledDeviceX(sensorMapObj[s]?.x);
    setControlledDeviceY(sensorMapObj[s]?.y);
    setSelectedSensor(s);
    setShowEditModal(true);
  };
  const handleConnectSensor = async (sensorIDconnect) => {
    const controlledSensorObj = sensorObjAll[sensorIDconnect];
    if (!controlledSensorObj.gatewayID) {
      alertWindow(dispatch, "Please connect gateway to sensor being controlled first.");
      return;
    }
    if (!sensorObj.gatewayID) {
      alertWindow(dispatch, "Please connect gateway to controllingsensor first.");
      return;
    }
    if (gang > 1 && (type === 'scenebutton' || type === 'lightswitch')) {
      setControlledDeviceX(sensorMapObj[sensorIDconnect]?.x);
      setControlledDeviceY(sensorMapObj[sensorIDconnect]?.y);
      setChooseGangDeviceID(sensorIDconnect);
    } else {
      confirmWindow(
        dispatch,
        `Connect ${sensorIDconnect} to this sensor?`,
        async () => {
          const sensorObjConnect = sensorObjAll[sensorIDconnect];
          await sensorConnectSensor(sensorObj, sensorObjConnect);
        }
      );
    }
  };
  const unitSize = iconSize * mapScale.x;

  return (
    <>
      <>
        {Object.keys(sensorMapObj).map((s, key) => {
          const netX = sensorMapObj?.[s]?.x * mapScale.x;
          const netY = sensorMapObj?.[s]?.y * mapScale.y;
          const sensorArray = Object.values(sensorMap || {}) || [];
          const isUnderSensor = sensorArray.includes(s);
          // const lineColor = "#1e90ff";
          const controlledSensorObj = sensorObjAll[s];
          const controlledType = controlledSensorObj?.type;
          const sensorIsDali = controlledSensorObj?.daliCtlID ? true : false;
          const selectedSensorMutualControlling = Object.values(controlledSensorObj?.sensorMap || {}).includes(sensorID);
          if (sensorID === s) return null;
          if (sensorIsDali || selectedSensorMutualControlling || type === "dimcontroller" || type === controlledType)
            return <UnConnectableFrame key={key} x={netX} y={netY} layerProps={layerProps} />;
          if (sensorID !== s) {
            return (
              <Fragment key={key}>
                <Rect
                  x={netX}
                  y={netY}
                  width={unitSize}
                  height={unitSize}
                  fill={isUnderSensor ? "#F1948A" : "#52BE80"}
                  opacity={0.5}
                  onMouseOver={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "pointer" : "move";
                  }}
                  onMouseLeave={(e) => {
                    const container = e.target.getStage().container();
                    container.style.cursor = enablePan ? "grab" : "default";
                  }}
                  onTap={(e) => handleClick(e, s, isUnderSensor)}
                  onClick={(e) => handleClick(e, s, isUnderSensor)}
                  stroke={selectedSensor === s ? "#2C3E50" : ""}
                  strokeWidth={6 / layerScale}
                />
                <DivExist show={isUnderSensor && type === "scenebutton"}>
                  {gs.newArrayBetween(1, 4).map((n) => (
                    <ButtonNo
                      show={sensorObj?.[`setting${n}`]?.[s] ? true : false}
                      layerProps={layerProps}
                      x={netX}
                      y={netY}
                      buttonNo={n}
                      key={n}
                    />
                  ))}
                </DivExist>
                <DivExist show={isUnderSensor && type === "lightswitch"}>
                  {gs.newArrayBetween(1, 4).map((n) => (
                    <ButtonNo
                      show={sensorObj?.[`setting${n * 2 - 1}`]?.[s] ? true : false}
                      layerProps={layerProps}
                      x={netX}
                      y={netY}
                      buttonNo={n}
                      key={n}
                    />
                  ))}
                </DivExist>
                <DivExist show={chooseGangDeviceID === s}>
                  <Circle
                    radius={iconSize * mapScale.x}
                    x={netX + (iconSize * mapScale.x) / 2}
                    y={netY + (iconSize * mapScale.y) / 2}
                    stroke={darkColorArr[0]}
                    strokeWidth={2 / layerScale}
                  />
                </DivExist>
              </Fragment>
            )
          }
          else return null;
        }
        )}
      </>
      <ChooseGangModal
        open={chooseGangDeviceID ? true : false}
        onClose={() => setChooseGangDeviceID("")}
        x={controlledDeviceX}
        y={controlledDeviceY}
        connectDeviceID={chooseGangDeviceID}
        connectDeviceObj={sensorObjAll[chooseGangDeviceID]}
        sensorObj={sensorObj || {}}
        layerProps={layerProps}
        onConnect={(swNo) => {
          sensorConnectSensor(sensorObj, sensorObjAll[chooseGangDeviceID], swNo);
        }}
      />
      <SenMotionSensorModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        open={showEditModal && type === "motion"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedSensor={selectedSensor}
        sensorID={sensorID}
        sensorObj={sensorObj || {}}
      />
      <SenSceneButtonSensorModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        open={showEditModal && type === "scenebutton"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedSensor={selectedSensor}
        sensorID={sensorID}
        sensorObj={sensorObj || {}}
      />
      <SenDaylightSensorModal
        x={controlledDeviceX}
        y={controlledDeviceY}
        open={showEditModal && type === "daylight"}
        handleClose={handleCloseModal}
        layerProps={layerProps}
        selectedSensor={selectedSensor}
        sensorID={sensorID}
        sensorObj={sensorObj || {}}
      />
    </>
  );
}

function UnConnectableFrame({ x, y, layerProps }) {
  const { iconSize, mapScale, layerScale } = layerProps;
  return (
    <>
      <Rect
        x={x}
        y={y}
        width={iconSize * mapScale.x}
        height={iconSize * mapScale.y}
        fill="grey"
        opacity={0.3}
      />
      <Line
        x={x}
        y={y}
        points={[
          0,
          0,
          iconSize * mapScale.x,
          iconSize * mapScale.y,
        ]}
        stroke="red"
        strokeWidth={2 / layerScale}
      />
    </>
  )
}