import DivExist from "components/DivExist";
import DeviceZoneControl from "../device/DeviceZoneControl";
import DeviceUnknown from "../device/DeviceUnknown";
import LinesZoneControlSelected from "../device/LinesZoneControlSelected";
export default function LayerZoneControl({ layerProps }) {
  const {
    activeMapID,
    mapObjAll,
    mapScale,
    zoneControlObjAll,
    selectedDeviceType,
    selectedID,
  } = layerProps;
  const mapZoneControlObj = mapObjAll[activeMapID]?.zoneControlObj;
  const zoneControlArray = Object.keys(mapZoneControlObj || {});
  return (
    <DivExist show={zoneControlArray?.length > 0}>
      {zoneControlArray.map((z, key) => {
        const x = mapZoneControlObj[z]?.x * mapScale.x || 0;
        const y = mapZoneControlObj[z]?.y * mapScale.y || 0;
        const zoneControlObj = zoneControlObjAll[z];
        if (zoneControlObj) {
          return (
            <DeviceZoneControl
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              zoneControlID={z}
              zoneControlObj={zoneControlObj}
            />
          );
        } else {
          return (
            <DeviceUnknown
              key={key}
              layerProps={layerProps}
              x={x}
              y={y}
              deviceID={z}
              deviceType="zoneControl"
              text="Z"
            />
          );
        }
      })}
      <DivExist
        show={selectedID && selectedDeviceType === "zoneControl" ? true : false}
      >
        <LinesZoneControlSelected layerProps={layerProps} />
      </DivExist>
    </DivExist>
  );
}
