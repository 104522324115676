import React from "react";

export default function SceneButton({
  width,
  height,
  sizeUnit,
  status,
  status2,
  status3,
  status4,
  connectStatus,
  disabledConnectStatus,
  gang,
  disabledSensor,
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  let connectColor = "red";
  if (connectStatus && !disabledSensor) {
    connectColor = "green";
  } else if (connectStatus && disabledSensor) {
    connectColor = "purple";
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      <path
        fill="#ccc"
        stroke="#6c5d53"
        strokeWidth={2.6}
        d="M16.135 8.792l55.972.089c3.558.53 5.2 2.797 5.448 6.327v56.637c-.434 2.847-2.235 4.687-5.145 5.71l-57.214-.112c-3.661-.499-4.74-2.95-5.167-5.896L9.962 14c1.005-2.771 2.48-5.08 6.173-5.208z"
      ></path>
      {gang <= 3 && (
        <>
          <circle
            cx={gang === 1 ? 44 : 23}
            cy={42}
            fill="#666"
            fillOpacity={0.996}
            stroke={status === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            r={8.58}
          />
          {gang > 1 && (
            <circle
              cx={gang === 2 ? 64 : 44}
              cy={42}
              fill="#666"
              fillOpacity={0.996}
              stroke={status2 === 1 ? "red" : "#0ff"}
              strokeLinecap="round"
              strokeWidth={3.5}
              r={8.58}
            />
          )}
          {gang === 3 && (
            <circle
              cx={64}
              cy={42}
              fill="#666"
              fillOpacity={0.996}
              stroke={status3 === 1 ? "red" : "#0ff"}
              strokeLinecap="round"
              strokeWidth={3.5}
              r={8.58}
            />
          )}
        </>
      )}
      {gang === 4 && (
        <>
          <circle
            cx={28}
            cy={28}
            fill="#666"
            fillOpacity={0.996}
            stroke={status === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            r={8.58}
          />
          <circle
            cx={59}
            cy={28}
            fill="#666"
            fillOpacity={0.996}
            stroke={status2 === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            r={8.58}
          />
          <circle
            cx={28}
            cy={59}
            fill="#666"
            fillOpacity={0.996}
            stroke={status3 === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            r={8.58}
          />
          <circle
            cx={59}
            cy={59}
            fill="#666"
            fillOpacity={0.996}
            stroke={status4 === 1 ? "red" : "#0ff"}
            strokeLinecap="round"
            strokeWidth={3.5}
            r={8.58}
          />
        </>
      )}
      {!disabledConnectStatus && (
        <circle r="6" cx="6" cy="7" fill={connectColor} />
      )}
    </svg>
  );
}
