import React from "react";

export default function Bypass({ x = 0, y = 0, sizeUnit = "vw", width = 3 }) {
  return (
    <svg
      viewBox="0 0 79.375 79.375"
      width={width + sizeUnit}
      height={width + sizeUnit}
      x={x}
      y={y}
    >
      <path
        fill="none"
        stroke="#000"
        strokeWidth="5"
        d="M42.491 60.757l.043 18.747"
      ></path>
      <path
        fill="#08a"
        stroke="#08a"
        strokeWidth="4"
        d="M24.937 31.025s-4.16-1.43-4.452-4.57c-.346-3.728 3.98-4.682 3.98-4.682l35.47-.117c1.806.491 3.517 1.195 4.096 4.448-.785 3.935-2.576 4.18-4.264 4.804"
      ></path>
      <path
        fill="#fff"
        stroke="#2c89a0"
        strokeWidth={5}
        d="M24.937 31.025s.027 9.993 1.23 14.459c.96 3.569 2.666 7.22 5.443 9.659 2.941 2.581 6.977 4.502 10.888 4.39 4.529-.13 9.123-2.559 12.293-5.795 2.513-2.565 3.643-6.323 4.39-9.835.855-4.007.586-12.995.586-12.995z"
      ></path>
      <path
        fill="#08a"
        d="M47.154 36.924l-8.643.058-2.784 7.889h4.409l-1.045 9.397 9.977-9.107-4.002-.174z"
      ></path>

      <path
        fill="#08a"
        stroke="#08a"
        strokeWidth={3}
        d="M36.257 20.913l-7.016.045.045-10.999c.77-2.372 2.155-2.638 3.305-2.638 1.445-.066 2.654.246 3.485 2.457zM54.699 20.789l-7.016.045.045-10.999c.77-2.372 2.155-2.638 3.305-2.638 1.445-.066 2.654.246 3.485 2.457z"
      ></path>
    </svg>
  );
}
