import { Ellipse, Group, Path } from "react-konva";
import ChargeIcon from "./ChargeIcon";

export default function Owl({
  x,
  y,
  size,
  statusL,
  // connectStatus,
  shadEnabled,
  // outline,
}) {
  const scale = size / 79.375;
  const lightColor = statusL === 0 ? "lightgrey" : "yellow";

  return (
    <Group x={x} y={y} scaleY={scale} scaleX={scale} listening={false}>
      {/* <Rect
        x={-10}
        y={-10}
        width={100}
        height={100}
        fill="#fff"
        opacity={0.5}
        cornerRadius={5}
        stroke={outline ? lightColorDark : ""}
        perfectDrawEnabled={false}
      /> */}
      <Path
        stroke="#f60"
        strokeWidth={3.7}
        data="M5.857 44.106c-.003-3.166.863-5.67 5.509-5.297l59.04.097c2.603-.176 4.036 1.013 3.758 4.2l.11 27.079c.096 3.153-1.53 4.422-4.42 4.31l-59.464-.512c-2.464-.345-4.418-1.383-4.453-5.024z"
        shadowEnabled={shadEnabled}
        shadowColor="black"
        shadowBlur={2}
        shadowOffset={{ x: 10, y: 10 }}
        shadowOpacity={0.5}
        perfectDrawEnabled={false}
      />
      <Ellipse
        x={21.349}
        y={22.448}
        fill={lightColor}
        radiusX={13.389}
        radiusY={13.389}
        stroke="#f60"
        strokeWidth={3.223}
        perfectDrawEnabled={false}
      />
      <Ellipse x={17.073} y={68.031} fill="#0a4" radiusX={2.5} radiusY={2.5} perfectDrawEnabled={false} />
      <Ellipse x={30.203} y={68.031} fill="red" radiusX={2.5} radiusY={2.5} perfectDrawEnabled={false} />
      <Ellipse x={23.571} y={68.031} fill="#0a4" radiusX={2.5} radiusY={2.5} perfectDrawEnabled={false} />
      <Ellipse
        x={58.785}
        y={22.448}
        fill={lightColor}
        radiusX={13.389}
        radiusY={13.389}
        stroke="#f60"
        strokeWidth={3.223}
        perfectDrawEnabled={false}
      />
      <ChargeIcon y={10} x={-30} statusL={statusL} />
    </Group>
  );
}
