import { useState, useEffect, useContext } from "react";
import { SMContext } from "context/smContext";
import { Workspaces } from "@mui/icons-material";
import { Typography, Tooltip, Box } from "@mui/material";
import Hover from "./Hover";
import { gatewayGroupActionCheck } from "actions/gatewayActions";
import General from "@ecoenghk/general";
import { convertDaliCmdOneSetting, dtkCmdIncludeDaliLight } from "actions/daliCtlActions";
import ModalSM from "./ModalSM";
import Divider10 from "./Divider10";
const gs = new General();
export default function GroupActionConfirmOneDevice({ deviceObj, controlledDeviceObj, settingNumArray, size = "1rem" }) {
    const [state, dispatch] = useContext(SMContext);
    const { daliCtlObjAll, userObj } = state;
    const [confirmed, setConfirmed] = useState(true);
    const [msg, setMsg] = useState("");
    const [openDetail, setOpenDetail] = useState(false);
    const [groupCmdObj, setGroupCmdObj] = useState({});
    const { enableGroupAction } = deviceObj || {};
    let groupID, groupDeviceType;
    if (deviceObj.zoneControlID) {
        groupID = deviceObj.zoneControlID.replace("zc_", "");
        groupDeviceType = "zoneControl";
    } else {
        groupID = deviceObj.sensorID;
        groupDeviceType = "sensor";
    }
    const controlledDeviceID = controlledDeviceObj?.serial || controlledDeviceObj?.sensorID;
    const controlledDeviceDaliCtlID = controlledDeviceObj?.daliCtlID;
    useEffect(() => {
        let allConfirmed = true;
        let tempMsg = `Setting`;
        let gpCmdObjBySettingNum = {};
        settingNumArray.forEach((settingNum, key) => {
            let setConfirm;
            const GAS_set = deviceObj[`GAS_setting${settingNum}`];
            let gasSettingObj;
            let cmdObjFromSettingObj;
            let cmdStrFromSettingObj;
            if (controlledDeviceDaliCtlID) {
                const daliCtlObj = daliCtlObjAll[controlledDeviceDaliCtlID];
                gasSettingObj = GAS_set?.[controlledDeviceDaliCtlID];
                let commandHasDaliLight = dtkCmdIncludeDaliLight(gasSettingObj?.command, daliCtlObj, controlledDeviceObj);
                setConfirm = gasSettingObj?.confirmed && commandHasDaliLight;
                const mergedCmdFromSetting = convertDaliCmdOneSetting(deviceObj[`setting${settingNum}`]);
                cmdObjFromSettingObj = mergedCmdFromSetting?.[controlledDeviceDaliCtlID];

            } else {
                gasSettingObj = GAS_set?.[controlledDeviceID];
                setConfirm = gasSettingObj?.confirmed || false;
                cmdObjFromSettingObj = deviceObj[`setting${settingNum}`]?.[controlledDeviceID];
            }

            tempMsg += `${settingNum}:${setConfirm?.toString()}`;
            const cmdStrSO = gs.jsonCommandToDtk(cmdObjFromSettingObj);
            const cmdStrGAS = gasSettingObj?.command;
            if (cmdStrSO !== cmdStrGAS) {
                setConfirm = false;
                tempMsg += " (cmd in database not match cmd in device)";
            }
            tempMsg += ", ";
            if (setConfirm === false || setConfirm === undefined) allConfirmed = false;
            gpCmdObjBySettingNum[settingNum] = { gasSettingObj, cmdObjFromSettingObj, cmdStrSO, cmdStrGAS };
        });
        setConfirmed(allConfirmed);
        setMsg(tempMsg);
        setGroupCmdObj(gpCmdObjBySettingNum);
    }, [settingNumArray, deviceObj, controlledDeviceObj]);
    const setConfirmToFalse = async () => {
        let newDeviceObj = { ...deviceObj };
        settingNumArray.forEach(settingNum => {
            let GAS_set = newDeviceObj[`GAS_setting${settingNum}`];
            if (GAS_set) {
                if (controlledDeviceDaliCtlID) {
                    if (GAS_set[controlledDeviceDaliCtlID]) GAS_set[controlledDeviceDaliCtlID].confirmed = false;
                } else {
                    if (GAS_set[controlledDeviceID]) GAS_set[controlledDeviceID].confirmed = false;
                }
                newDeviceObj[`GAS_setting${settingNum}`] = GAS_set;
            }
        });
        if (groupDeviceType === "zoneControl") dispatch({ type: "UPDATED_ZONE_CONTROL_OBJ", payload: newDeviceObj });
        else dispatch({ type: "UPDATE_SENSOR_OBJ", payload: newDeviceObj });
    };
    const testSettingNum = (settingNumArray || [])?.length > 1 ? 99 : settingNumArray[0];
    if (!enableGroupAction) return null;
    return (
        <>

            <Hover>
                <Tooltip title={msg}>
                    <Workspaces
                        color={confirmed ? "success" : "error"}
                        sx={{ fontSize: size }}
                        onClick={async (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            await gs.waitFor(500);
                            setConfirmToFalse();
                            await gatewayGroupActionCheck(controlledDeviceObj.gatewayID, groupID, controlledDeviceID, testSettingNum);
                        }}
                        onContextMenu={(e) => {
                            e.preventDefault();
                            if (userObj.level === 0) setOpenDetail(true);
                        }}
                    />
                </Tooltip>
            </Hover>
            {
                userObj.level === 0 &&
                <>
                    <ModalSM
                        open={openDetail}
                        onClose={() => setOpenDetail(false)}
                        disableBottomClose
                        width="80vw"
                        height="70vh"
                        modalTitle={`Group action detail of Group ${groupID} -> device ${controlledDeviceID}`}>
                        {
                            settingNumArray.map((settingNum, key) => {
                                const gc = groupCmdObj[settingNum];
                                let cmdObjSO = { ...gc?.cmdObjFromSettingObj };
                                delete cmdObjSO.serial;
                                delete cmdObjSO.zoneControlID;
                                const cmdObjGAS = gc?.cmdStrGAS ? gs.dtKStrToJson(gc?.cmdStrGAS) : {};
                                return (
                                    <Box key={key} >
                                        <Typography variant="h6">Setting {settingNum}</Typography>
                                        <Typography>From setting object</Typography>
                                        <Typography>{gc?.cmdStrSO}</Typography>
                                        <Typography variant="caption">{`${JSON.stringify(cmdObjSO)}`}</Typography>
                                        <Divider10 />
                                        <Typography>From GAS setting object</Typography>
                                        <Typography>{`${gc?.cmdStrGAS}`}</Typography>
                                        <Typography display="block" variant="caption">{`${JSON.stringify(cmdObjGAS)}`}</Typography>
                                        <Typography display="block" variant="caption">{`GAS:${JSON.stringify(gc?.gasSettingObj)}`}</Typography>
                                        <Divider10 />
                                    </Box>
                                )
                            })
                        }
                    </ModalSM>
                </>
            }
        </>
    )
}
