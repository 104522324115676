import DivCol from "./DivCol";
import IconButtonSave from "./IconButtonSave";
import moment from "moment";
import SensorIcon from "./SensorIcon";
import DivExist from "./DivExist";
import Divider10 from "./Divider10";
import { AlternateEmail } from "@mui/icons-material";
import DivInline from "./DivInline";
import { Typography, Tooltip } from "@mui/material";
import ButtonSM from "./ButtonSM";
export default function CardSensor({ sensorObj, handleSave, iconSize = 7, iconSizeUnit = "vh", disableClick = false }) {
    const { sensorID, sensorName, dtkAdd, wifiApMacAddress, createTimeString, timeStamp } = sensorObj || {};
    const defaultClick = () => { }
    return (
        <ButtonSM tooltip="Add to map" onClick={handleSave || defaultClick} disabled={disableClick}>
            <DivCol sx={{ outline: "1px solid #fff", padding: "5px", margin: "5px" }}>
                <Typography variant="body2">{`\u{01f194}${sensorID}`}</Typography>
                <DivInline>
                    <AlternateEmail sx={{ fontSize: "1rem" }} />
                    <Tooltip title="Address">
                        <Typography variant="caption">{dtkAdd || "-"}</Typography>
                    </Tooltip>
                </DivInline>
                <SensorIcon
                    type={sensorObj?.type}
                    width={iconSize}
                    sizeUnit={iconSizeUnit}
                    disabledConnectStatus
                    gang={sensorObj?.gang}
                />
                <Typography variant="body2">{sensorName || "-"}</Typography>
                <Divider10 />
                <Typography variant="caption">Wifi Ap Mac</Typography>
                <Typography variant="body2">{wifiApMacAddress || "-"}</Typography>
                <Divider10 />
                <Typography variant="caption">Create Time</Typography>
                <Typography variant="body2">{createTimeString || "-"}</Typography>
                <Divider10 />
                <Typography variant="caption">Last update time</Typography>
                <Typography variant="body2">{moment(timeStamp).format("YYYY-MM-DD HH:mm:ss") || "-"}</Typography>
            </DivCol>
        </ButtonSM>
    );
}
