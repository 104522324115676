import React from "react";
import ChargeIcon from "./ChargeIcon";

export default function Exit({
  width,
  height,
  sizeUnit,
  disabledStatus,
  statusL,
  connectStatus,
  disabledConnectStatus,
  backgroundColor
}) {
  const w = width || height || 20;
  const h = height || width || 20;
  const unit = sizeUnit || "px";
  const lightColor = disabledStatus || statusL === 0 ? "none" : "yellow";
  return (
    <svg
      viewBox="0 0 79.375 79.375"
      width={w + unit}
      height={h + unit}
    >
      {
        backgroundColor && <rect width={79.375} height={79.375} fill={backgroundColor} />
      }
      <g>
        <path
          fill={lightColor}
          fillOpacity="0.996"
          stroke="teal"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth="2"
          d="M0.179 0.179H79.425V79.425H0.179z"
        ></path>
        <text
          xmlSpace="preserve"
          style={{
            lineHeight: "1.25",
            InkscapeFontSpecification: "'Apple SD Gothic Neo, Bold'",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
          }}
          x="1.84"
          y="77.243"
          fill="green"
          fillOpacity="1"
          stroke="none"
          strokeWidth="0.919"
          fontFamily="Apple SD Gothic Neo"
          fontSize="36.772"
          fontStretch="normal"
          fontStyle="normal"
          fontVariant="normal"
          fontWeight="bold"
        >
          <tspan
            x="1.84"
            y="77.243"
            style={{
              InkscapeFontSpecification: "'Apple SD Gothic Neo, Bold'",
              fontVariantLigatures: "normal",
              fontVariantCaps: "normal",
              fontVariantNumeric: "normal",
              fontVariantEastAsian: "normal",
            }}
            fill="green"
            strokeWidth="0.919"
            fontFamily="Apple SD Gothic Neo"
            fontSize="36.772"
            fontStretch="normal"
            fontStyle="normal"
            fontVariant="normal"
            fontWeight="bold"
          >
            EXIT
          </tspan>
        </text>
        <path
          fill="none"
          stroke="green"
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth={3.734}
          d="M26.08 46.258l-.1-5.18"
        ></path>
        <path
          fill="none"
          stroke="green"
          strokeDasharray="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeMiterlimit="4"
          strokeOpacity="1"
          strokeWidth={3.5}
          d="M26.093 34.391V3.899l30.692-.2v42.289"
        ></path>
        <circle
          cx={47.388}
          cy={12.897}
          r={4.299}
          fill="green"
          fillOpacity="0.996"
          stroke="none"
          strokeDasharray="none"
          strokeLinecap="round"
          strokeLinejoin="miter"
          strokeMiterlimit="7.8"
          strokeOpacity="1"
          strokeWidth={2.646}
        ></circle>
        <path
          fill="green"
          stroke="none"
          strokeLinecap="butt"
          strokeLinejoin="miter"
          strokeOpacity="1"
          strokeWidth={0.265}
          d="M42.889 17.096H33.79l-4.299 8.097c-.51 1.012 1.584 3.347 3.4 1.5l2.699-5.798h3.099s-2.554 4.546-3.4 6.998c-.877 2.546-1.699 7.898-1.699 7.898s-4.78.104-8.03.172c-1.1.023-1.372 3.07-1.067 3.626l11.897-.1 2.999-5.698 8.098 12.597h3.499s-8-15.43-7.698-16.396c.385-1.234 3.299-6.998 3.299-6.998s3.344 4.09 4.599 4.599c.624.253 3.699-.1 3.699-.1v-3.6s-1.952.13-2.3 0c-.574-.212-5.798-5.398-5.798-5.398-1.1-.885-2.7-1.451-3.9-1.4z"
        ></path>
      </g>
      {!disabledConnectStatus && (
        <circle r={6} cx={10} cy={10} fill={connectStatus ? "green" : "red"} />
      )}
      {!disabledStatus && <ChargeIcon y={20} x={0} statusL={statusL} />}
    </svg>
  );
}
